import {
	UserId_Some,
	UserEntity_Some,
	UserEntityPatch_Some,
	UserCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const USER_MUTATE_ID = '@app/USER_MUTATE_ID';
export const USER_SET = '@app/USER_SET';
export const USER_TOGGLE = '@app/USER_TOGGLE';
export const USER_SELECT = '@app/USER_SELECT';
export const USER_DESELECT = '@app/USER_DESELECT';
export const USER_UPSERT = '@app/USER_UPSERT';
export const USER_PATCH = '@app/USER_PATCH';
export const USER_CHANGE = '@app/USER_CHANGE';
export const USER_APPLY_CHANGES = '@app/USER_APPLY_CHANGES';
export const USER_CANCEL_CHANGES = '@app/USER_CANCEL_CHANGES';
export const USER_CACHE = '@app/USER_CACHE';
export const USER_UNCACHE = '@app/USER_UNCACHE';
export const USER_SETSTATE = '@app/USER_SETSTATE';
export const USER_PATCHSTATE = '@app/USER_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface UserAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface UserAction_MutateId extends EntityAction_MutateId {
	type: typeof USER_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active user
 *
 * @interface UserAction_Set
 * @extends {EntityAction_Set}
 */
export interface UserAction_Set extends EntityAction_Set {
	type: typeof USER_SET;
	payload?: EntityId;
}

/**
 * Toggle active user
 *
 * @interface UserAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface UserAction_Toggle extends EntityAction_Toggle {
	type: typeof USER_TOGGLE;
	payload?: EntityId;
}

/**
 * Select user entities
 *
 * @interface UserAction_Select
 * @extends {EntityAction_Select}
 */
interface UserAction_Select extends EntityAction_Select {
	type: typeof USER_SELECT;
	payload: UserId_Some;
}

/**
 * Deselect user entities
 *
 * @interface UserAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface UserAction_Deselect extends EntityAction_Deselect {
	type: typeof USER_DESELECT;
	payload: UserId_Some;
}

/**
 * Upsert user entities
 *
 * @interface UserAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface UserAction_Upsert extends EntityAction_Upsert {
	type: typeof USER_UPSERT;
	payload: UserEntity_Some;
}

/**
 * Patch user entities
 *
 * @interface UserAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface UserAction_Patch extends EntityAction_Patch {
	type: typeof USER_PATCH;
	payload: UserEntityPatch_Some;
}

/**
 * Change user entities
 *
 * @interface UserAction_Change
 * @extends {EntityAction_Change}
 */
interface UserAction_Change extends EntityAction_Change {
	type: typeof USER_CHANGE;
	payload: UserEntityPatch_Some;
}

/**
 * Apply changes to user entities
 *
 * @interface UserAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface UserAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof USER_APPLY_CHANGES;
	payload: UserId_Some;
}

/**
 * Cancel changes to user entities
 *
 * @interface UserAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface UserAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof USER_CANCEL_CHANGES;
	payload: UserId_Some;
}

/**
 * Cache user entities
 *
 * @interface UserAction_Cache
 * @extends {EntityAction_Cache}
 */
interface UserAction_Cache extends EntityAction_Cache {
	type: typeof USER_CACHE;
	payload: UserId_Some;
}

/**
 * Uncache user entities
 *
 * @interface UserAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface UserAction_Uncache extends EntityAction_Uncache {
	type: typeof USER_UNCACHE;
	payload: UserId_Some;
}

/**
 * Set user collection / entity state
 *
 * @interface UserAction_SetState
 * @extends {EntityAction_SetState}
 */
interface UserAction_SetState extends EntityAction_SetState {
	type: typeof USER_SETSTATE;
}

/**
 * PAtch user collection / entity state
 *
 * @interface UserAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface UserAction_PatchState extends EntityAction_PatchState {
	type: typeof USER_PATCHSTATE;
}

/**
 * Export user action types
 *
 * @export
 */
export type UserActionTypes =
	| UserAction_MutateId
	| UserAction_MutateId
	| UserAction_Set
	| UserAction_Toggle
	| UserAction_Select
	| UserAction_Deselect
	| UserAction_Upsert
	| UserAction_Patch
	| UserAction_Change
	| UserAction_ApplyChanges
	| UserAction_CancelChanges
	| UserAction_Cache
	| UserAction_Uncache
	| UserAction_SetState
	| UserAction_PatchState;

/**
 * User actions helper interface
 *
 * @export
 * @interface IUserActions
 * @extends {EntityActions}
 */
export interface IUserActions extends EntityActions {
	//customAction(ids: UserId_Some): UserAction_CustomAction;
}

/**
 * User actions helper
 *
 * @export
 * @class UserActions
 * @implements {EntityBaseActions}
 */
export class UserActions implements IUserActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): UserAction_MutateId {
		return { type: USER_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): UserAction_Set {
		return { type: USER_SET, payload: id };
	}
	toggle(id?: EntityId): UserAction_Toggle {
		return { type: USER_TOGGLE, payload: id };
	}
	select(ids: UserId_Some): UserAction_Select {
		return {
			type: USER_SELECT,
			payload: ids
		};
	}
	deselect(ids: UserId_Some): UserAction_Deselect {
		return {
			type: USER_DESELECT,
			payload: ids
		};
	}
	upsert(entities: UserEntity_Some): UserAction_Upsert {
		return {
			type: USER_UPSERT,
			payload: entities
		};
	}
	patch(entities: UserEntityPatch_Some): UserAction_Patch {
		return {
			type: USER_PATCH,
			payload: entities
		};
	}
	change(entities: UserEntityPatch_Some): UserAction_Change {
		return {
			type: USER_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: UserId_Some): UserAction_ApplyChanges {
		return {
			type: USER_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: UserId_Some): UserAction_CancelChanges {
		return {
			type: USER_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: UserId_Some): UserAction_Cache {
		return {
			type: USER_CACHE,
			payload: ids
		};
	}
	uncache(ids: UserId_Some): UserAction_Uncache {
		return {
			type: USER_UNCACHE,
			payload: ids
		};
	}
	setState(state: UserCollectionState): UserAction_SetState {
		return {
			type: USER_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<UserCollectionState>): UserAction_PatchState {
		return {
			type: USER_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the user actions helper
 *
 * @export
 */
export const userActions = new UserActions();
