import {
	DataCollection,
	DataCollectionState,
	DataId,
	DataEntity_Some,
	DataEntityPatch_Some,
	DataId_Some,
	dataCollectionDefault,
	dataCollectionMutateIdOpts
} from '../collections/Data';
import * as actions from '../actions/Data';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Data reducer
 *
 * @export
 * @param {DataCollection} [state=dataCollectionDefault]
 * @param {DataActionTypes} action
 * @returns
 */
export function dataReducer(
	state: DataCollection = dataCollectionDefault,
	action: actions.DataActionTypes
) {
	switch (action.type) {
		case actions.DATA_MUTATE_ID:
			return collectionReducerActions.mutateId<DataCollection>(
				state,
				action.payload,
				dataCollectionMutateIdOpts
			);
		case actions.DATA_SET:
			return collectionReducerActions.set<DataCollection, DataId>(
				state,
				action.payload
			);
		case actions.DATA_TOGGLE:
			return collectionReducerActions.toggle<DataCollection, DataId>(
				state,
				action.payload
			);
		case actions.DATA_SELECT:
			return collectionReducerActions.select<DataCollection, DataId_Some>(
				state,
				action.payload
			);
		case actions.DATA_DESELECT:
			return collectionReducerActions.deselect<DataCollection, DataId_Some>(
				state,
				action.payload
			);
		case actions.DATA_UPSERT:
			return collectionReducerActions.upsert<DataCollection, DataEntity_Some>(
				state,
				action.payload
			);
		case actions.DATA_PATCH:
			return collectionReducerActions.patch<
				DataCollection,
				DataEntityPatch_Some
			>(state, action.payload);
		case actions.DATA_CHANGE:
			return collectionReducerActions.change<
				DataCollection,
				DataEntityPatch_Some
			>(state, action.payload);
		case actions.DATA_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<DataCollection, DataId_Some>(
				state,
				action.payload
			);
		case actions.DATA_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				DataCollection,
				DataId_Some
			>(state, action.payload);
		case actions.DATA_CACHE:
			return collectionReducerActions.cache<DataCollection, DataId_Some>(
				state,
				action.payload
			);
		case actions.DATA_UNCACHE:
			return collectionReducerActions.uncache<DataCollection, DataId_Some>(
				state,
				action.payload
			);
		case actions.DATA_SETSTATE:
			return collectionReducerActions.setState<
				DataCollection,
				DataCollectionState
			>(state, action.payload.state);
		case actions.DATA_PATCHSTATE:
			return collectionReducerActions.patchState<
				DataCollection,
				DataCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
