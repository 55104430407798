// React stuff
import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';
import { useLeadsQuery } from '../hooks/leads';
import { Service, LeadEntities } from '../../store';

//UI framework
import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonContent,
	IonButtons,
	IonButton,
	IonIcon,
	IonLabel,
	IonSelect,
	IonSelectOption,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonList,
	IonItemDivider,
	IonItem,
	IonLoading
} from '@ionic/react';
import * as icons from 'ionicons/icons';

// App UI
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { Selector } from '../../../app/ui/components/Selector';
import { SearchBar } from '../../../app/ui/components';

// Custome lead ui
import { LeadListItem } from '../components';
import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';
import { LeadGridCard } from './../components/Lead';
import { useInterval } from '../../../app/store';

// Component
export enum ViewLayout {
	List,
	Grid
}

/*
	To do:
		- Add reload feature
*/
const LeadsView: React.FC = () => {
	// View ui state control
	const [active, setActive] = useState(true);
	const [leads, setLeads] = useState<LeadEntities>([]);

	// Layout option
	const [layout, setLayout] = useState<ViewLayout>(ViewLayout.Grid);

	// Handle grid/table flop
	const toggleLayout = () => {
		layout === ViewLayout.List
			? setLayout(ViewLayout.Grid)
			: setLayout(ViewLayout.List);
	};

	// Control entering to prevent background caching
	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	// Tap into context
	const ctx = useCtx({});
	const {
		config: [config],
		lead: { activeContext, activeEvent, activeService, leadHelper }
	} = ctx;

	// Open hook connection
	const leadsQuery = useLeadsQuery(leads);

	// Load/reload leads on conext, event or service update
	useEffect(() => {
		// Load in leads in active context and event
		if (activeContext && activeEvent)
			setLeads(
				leadHelper.allByServiceIdAndEventIds(
					activeService?.id || '',
					activeContext.eventIds
				)
			);
	}, [activeContext, activeEvent, activeService]);

	const read = (next: any) => {
		let modFrom =
			leadsQuery.modifiedFrom !== '' ? leadsQuery.modifiedFrom : undefined;
		leadHelper.read(
			ctx,
			{
				modifiedFrom: modFrom,
				eventIds: activeContext?.eventIds ?? []
			},
			next
		);
	};

	useInterval(
		(next: any, active: boolean) => {
			if (active) {
				read(next);
				return;
			}
			next();
		},
		60000,
		active
	);

	//Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	// Return nothing if service or event not selected
	if (!activeService) return <ServiceNotFoundCard />;
	if (!activeContext || !activeEvent) return <>No Event selected</>;

	return (
		<IonContent>
			<IonLoading
				isOpen={leadsQuery.isLoading}
				message={leadsQuery.loadingStatus}
			></IonLoading>
			<IonGrid>
				<IonRow>
					<IonCol size="12">
						<PageTitle title="Leads" displayEventSelector={true} />
					</IonCol>
				</IonRow>

				{/* Display Controls */}
				<IonRow>
					<IonCol size="6">
						<Selector
							key="SortLeadsBy"
							name="SortLeadsBy"
							items={leadsQuery.sortItems}
							value={leadsQuery.sortLeadsBy.value}
							direction={leadsQuery.sortLeadsBy.direction}
							toggleSortDirection={leadsQuery.toggleSortDirection}
							onSelect={leadsQuery.onSortLeadsByChange}
						></Selector>
					</IonCol>

					<IonCol size="6">
						<IonButtons style={{ justifyContent: 'flex-end' }}>
							<IonLabel>Page Size</IonLabel>
							<IonSelect
								interface="popover"
								name="pageSizeSelect"
								multiple={false}
								value={leadsQuery.paginationLimit}
								placeholder="Select One"
								onIonChange={e => {
									leadsQuery.setPaginationLimit(e.detail.value);
								}}
							>
								<IonSelectOption value={15}>15</IonSelectOption>
								<IonSelectOption value={25}>25</IonSelectOption>
								<IonSelectOption value={50}>50</IonSelectOption>
								<IonSelectOption value={100}>100</IonSelectOption>
							</IonSelect>

							{leadsQuery.showPagination && (
								<>
									<IonButton
										onClick={() =>
											leadsQuery.setPaginationCurrent(
												leadsQuery.paginationCurrent - 1
											)
										}
										disabled={leadsQuery.paginationCurrent === 1}
									>
										<IonIcon slot="icon-only" icon={icons.arrowBack} />
									</IonButton>
									<IonLabel>
										{leadsQuery.paginationCurrent} of{' '}
										{leadsQuery.paginationTotal}
									</IonLabel>
									<IonButton
										onClick={() =>
											leadsQuery.setPaginationCurrent(
												leadsQuery.paginationCurrent + 1
											)
										}
										disabled={
											leadsQuery.paginationCurrent ===
											leadsQuery.paginationTotal
										}
									>
										<IonIcon slot="icon-only" icon={icons.arrowForward} />
									</IonButton>
								</>
							)}

							{layout === ViewLayout.Grid && (
								<IonButton onClick={toggleLayout}>
									<IonIcon slot="icon-only" icon={icons.listOutline} />
								</IonButton>
							)}
							{layout === ViewLayout.List && (
								<IonButton onClick={toggleLayout}>
									<IonIcon slot="icon-only" icon={icons.appsOutline} />
								</IonButton>
							)}
							<IonButton onClick={leadsQuery.onExportLeads}>
								<IonIcon slot="icon-only" icon={icons.downloadOutline} />
							</IonButton>
						</IonButtons>
					</IonCol>
				</IonRow>

				{/* Search Controls */}
				<IonRow>
					<IonCol size="12">
						<SearchBar onChange={leadsQuery.onSetKeywordChange} />
					</IonCol>
				</IonRow>

				{/* Display */}
				{layout === ViewLayout.List && (
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardContent>
									<IonList>
										{leadsQuery.currentPaginationLeads?.map((lead, index) => {
											let newSortLead = leadsQuery.isNewSortLead(index);

											return newSortLead ? (
												<>
													<IonItemDivider key={'divider' + lead.lead.id}>
														<IonLabel>{newSortLead}</IonLabel>
													</IonItemDivider>
													<LeadListItem
														key={`lead-item-${index}`}
														lead={lead.lead}
													/>
												</>
											) : (
												<LeadListItem
													key={`lead-item-${index}`}
													lead={lead.lead}
												/>
											);
										})}
									</IonList>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				)}

				{layout === ViewLayout.Grid && (
					<IonRow>
						{leadsQuery.currentPaginationLeads?.map((lead, index) => {
							let newSortLead = leadsQuery.isNewSortLead(index);

							return newSortLead ? (
								<>
									<IonCol size="12">
										<br />
										<IonItemDivider
											key={'divider' + lead.lead.id}
											className="sort-lead-divider"
										>
											<IonLabel>{newSortLead}</IonLabel>
										</IonItemDivider>
									</IonCol>
									<IonCol size="12" sizeMd="4">
										<LeadGridCard key={`lead-card-${index}`} lead={lead.lead} />
									</IonCol>
								</>
							) : (
								<IonCol size="12" sizeMd="4">
									<LeadGridCard key={`lead-card-${index}`} lead={lead.lead} />
								</IonCol>
							);
						})}
					</IonRow>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default LeadsView;
