import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getQualifierValueCollection,
	QualifierValueId,
	QualifierValueIds,
	QualifierValueId_Some,
	QualifierValueEntity,
	QualifierValueEntities,
	QualifierValueEntity_Some,
	QualifierValueEntityPatch_Some,
	QualifierValueCollection,
	QualifierValueCollectionState,
	IQualifierValueActions,
	qualifierValueActions,
	QualifierValueActionTypes
} from '..';
import {
	readQualifierValues,
	writeQualifierValues,
	ReadQualifierValuesRequest,
	WriteQualifierValuesRequest,
	QualifierValueApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive, ObjectStatus } from '../models';

/**
 * QualifierValue helper interface
 *
 * @export
 * @interface IQualifierValueHelper
 * @extends {IEntityHelper}
 */
export interface IQualifierValueHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * QualifierValue helper options interface
 *
 * @export
 * @interface QualifierValueHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface QualifierValueHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const qualifierValueHelperOpts: QualifierValueHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * QualifierValue helper
 *
 * @export
 * @class QualifierValueHelper
 * @extends {EntityHelper<QualifierValueCollection, QualifierValueActionTypes, QualifierValueActions, QualifierValueEntity, QualifierValueEntities, QualifierValueEntity_Some, QualifierValueEntityPatch_Some, QualifierValueId, QualifierValueIds, QualifierValueId_Some, QualifierValueCollectionState, QualifierValueHelperOpts>}
 * @implements {IQualifierValueHelper}
 */
export class QualifierValueHelper
	extends EntityHelper<
		QualifierValueCollection,
		QualifierValueActionTypes,
		IQualifierValueActions,
		QualifierValueEntity,
		QualifierValueEntities,
		QualifierValueEntity_Some,
		QualifierValueEntityPatch_Some,
		QualifierValueId,
		QualifierValueIds,
		QualifierValueId_Some,
		QualifierValueCollectionState,
		QualifierValueHelperOpts
	>
	implements IQualifierValueHelper {
	constructor() {
		super(
			useSelector(getQualifierValueCollection),
			qualifierValueActions,
			useDispatch(),
			qualifierValueHelperOpts
		);
		this.collection = useSelector(getQualifierValueCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: QualifierValueApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadQualifierValuesRequest> = {},
		callback?: any
	): Promise<QualifierValueEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom ||
		//	this.lastSuccess(QualifierValueApiOperation.readQualifierValues);

		let request: ReadQualifierValuesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: QualifierValueEntities = await readQualifierValues(
			ctx,
			request
		)
			.then((entities: QualifierValueEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteQualifierValuesRequest> = {},
		callback?: any
	): Promise<QualifierValueEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		if (!params.qualifierValues) return [];
		let request: WriteQualifierValuesRequest = {
			...params,
			...{
				qualifierValues: params.qualifierValues,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: QualifierValueEntities = await writeQualifierValues(
			ctx,
			request
		)
			.then((entities: QualifierValueEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let qualifierValue = this.get(id);
		if (!qualifierValue) return false;

		qualifierValue.i_.status = ObjectStatus.Deleted;

		let qualifierValues = await this.write(ctx, {
			qualifierValues: [qualifierValue]
		});

		return qualifierValues.length > 0;
	}

	allByQualifierId(qualifierId: string): QualifierValueEntities {
		return this.all()
			.filter(qualifierValue => qualifierValue.qualifierId === qualifierId)
			.filter(isObjectStatusActive);
	}
}
