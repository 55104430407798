import { AuthHelper } from '..';
import { AuthEntity } from '../collections';
export interface AuthHook {
	authHelper: AuthHelper;
	activeAuth: AuthEntity | undefined;
}

export const useAuth = (): AuthHook => {
	let authHelper = new AuthHelper();
	return {
		authHelper,
		activeAuth: authHelper.active()
	};
};
