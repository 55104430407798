import { translations } from './translations';

export interface Locale {
	[localeCode: string]: any;
}
export interface Locales extends Locale {
	en: any;
	ar?: any;
	bn?: any;
	de?: any;
	es?: any;
	fr: any;
	hi?: any;
	it?: any;
	ja?: any;
	nl?: any;
	pt?: any;
	ru?: any;
	zh?: any;
}

export type Localize<Keys> = { [key in keyof Locales]: Keys };

export const localize = (locale: string, value: Locales | string): any =>
	!value
		? value
		: typeof value === 'string'
		? value in translations
			? translations[value][locale] || translations[value][0]
			: value
		: isLocalized(value)
		? value[locale] || value[0]
		: value;

export const isLocalized = (value: any): value is Locales =>
	(value as Locales).en !== undefined;

export * from './translations';
