import React, { useEffect, useState } from 'react';

//DATA
import { LeadEntity, EventContactEntity } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonContent
} from '@ionic/react';
import { LeadQualifierList, LeadNoteList } from './../containers/Lead';
import { LeadContactCard } from './../components/Lead';
import { useInterval } from '../../../app/store';

export interface LeadProps extends LeadEntity {
	id: string;
}

const LeadView: React.FC = () => {
	const ctx = useCtx<{}>({}); // TO DO: create and set (leadListConfig)
	const activeLead = ctx.lead.lead.active();
	const activeContact: EventContactEntity | undefined = !activeLead
		? undefined
		: ctx.lead.eventContact.get(activeLead.contactId);

	const {
		config: [config]
	} = ctx;

	useEffect(() => {
		if (activeLead) {
			ctx.lead.lead.readActiveData(ctx);
		}
	}, [activeLead]);

	return !activeLead ? (
		<IonContent>
			<IonLabel>No active lead</IonLabel>
		</IonContent>
	) : (
		<IonContent>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/lead-service/leads" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol size="12" sizeMd="8">
						<LeadContactCard contact={activeContact} />
						<LeadQualifierList lead={activeLead} />
					</IonCol>
					<IonCol size="12" sizeMd="4">
						<LeadNoteList lead={activeLead} />
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default LeadView;
