import { useRequest } from 'redux-query-react';
import {
	useContact,
	useDevice,
	useLead,
	useNote,
	useQualifier,
	useQualifierValue,
	useResponse,
	useService
} from './';

export const useApiData = () => {
	const { contactHelper } = useContact();
	const { leadHelper } = useLead();
	const { qualifierHelper } = useQualifier();
	const { qualifierValueHelper } = useQualifierValue();
	const { responseHelper } = useResponse();
	const { serviceHelper } = useService();

	let headers = {
		Authorization:
			'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16SXdOMEkwTWpVMU0wSTBORGN4UkRkRk16bENNakk0TWpOR01EUTVSRVU0TXpneE1VVTNRUSJ9.eyJpc3MiOiJodHRwczovL2F1dGguY29uZXhzeXMuY29tLyIsInN1YiI6Ikd2Z3dqT0t6QVU4QjBJZmVrbEpVeUwxb0VqdzBvbVB2QGNsaWVudHMiLCJhdWQiOiJodHRwczovL2FwaS5jb25leHN5cy5jb20vbGVhZC1wb3J0YWwiLCJpYXQiOjE1OTI4Mjk1NTEsImV4cCI6MTU5MjkxNTk1MSwiYXpwIjoiR3Znd2pPS3pBVThCMElmZWtsSlV5TDFvRWp3MG9tUHYiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.A-U88JkDlpMNCFVEmDjxb78eG0TzviZqccrZvDhzdaeoOclM6CbzP16vRpFr4Il541T9tbskeaJWA-_Js2jg7SOfgV71-unMsr7yeVHXibiOBCAid4Lh_bhoJCY3z925E21OELsXdqiCMTa7NPNf_UlU22rEvBMMJuEvYWrJaBFmkgyt3IQCXlv8HnJ17fhjO-pgNvffs0MgSkV2L77UiVBPXLMi4r03UABRTV7I6X4hh9qzCMR3Z6tbjiqqT_sbSKTuxVhk4dHA4YjeRnNEluq-ETi4xg8FeyXFAipTmGF5HRGXefHkYmPVkJEsIKY4WOSRMy8cvLYpz1f2ApFwSQ',
		'Content-Type': 'application/json',
		'x-request-token':
			'bG9jYWxlfGVuLUNBfHRpbWV6b25lfEFtZXJpY2EvRWRtb250b258Y291bnRyeXxDQXxsYXR8bG9ufGlwfDE5Mi4xNjguMS4xfHVzZXJJZHx1c2VyLnN5czoxNTg1OTUwNzQ5NjE4OmhrQlR2azViNlNRZFdFSzR0YUR3eTl8ZGV2aWNlSWR8ZGV2aWNlLnN5czoxNTg2MjIyNTQ1NzEzOjV3V011VGZKTGh1djJyZzJGYkJTa2J8c2VydmljZUlkfHNlcnZpY2UubGVhZDoxNTcxMzczMjYzNjkxOjRNeEZNdVVLWGdpOGh0em51NVhTRzV8ZXZlbnRJZHN8ZXZlbnQuc3lzOjE1NzEzNzE3NTEyNDM6MjdLczVwQjlXNmhYZ3g3S3R6N2NvNnxleHBpcmVzfDIwMjAtMDctMTdUMTI6NDU6NTQuODc1Wl4wfDBeXl4kMHwxfDJ8M3w0fDV8NnxLfDd8THw4fDl8QXxCfEN8RHxFfEZ8R3xASF18SXxKXQ=='
	};

	let modifiedFrom = '2020'; //new Date().toISOString();
};

const entitiesById = (entities: any[]): any => {
	return entities.reduce((obj: any, entity: any) => {
		obj[entity.id] = entity;
		return obj;
	}, {}) as any;
};
