import _ from 'lodash';
import { config } from '../config';
import { configureStore, Action } from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';
import qs from 'qs';
//import { jwtMiddleware } from './lib/auth';
import { saveToLocalStorage, loadFromLocalStorage } from './lib/local-storage';

import { initialState } from '../config/state';

import rootReducer, { RootState } from './reducers';

export * from './actions';
export * from './classes';
export * from './constants';
export * from './models';

export * from '../config/state';

// Reset state if the local storage version is incorrect or if resetLS (reset Local Storage) is in the request querystring
const reset =
	localStorage.getItem('version') !== config.version ||
	qs.parse(window.location.search, {
		ignoreQueryPrefix: true
	}).resetLS === 'true'
		? true
		: false;

if (reset) console.log('Resetting local storage');

// init preload state as a blend of initial state and local storage (unless reset)
const preloadedState = _.merge(
	initialState,
	reset ? {} : loadFromLocalStorage() || {}
);

// update local storage to reflect the preload state.  important for resets
if (reset) saveToLocalStorage(_.pick(preloadedState, config.localStorage));

// init the store and merge the persisted local storage state with  the initial (default) state
const store = configureStore({
	reducer: rootReducer,
	preloadedState,
	middleware: [thunk]
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

// persist the configured state paths to local storage whenever the state is mututated
store.subscribe(() =>
	saveToLocalStorage(_.pick(store.getState(), config.localStorage))
);

// return the default initialized store instance
export default store;
