import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';

import { RouteView } from '../components';

import { RouteEntity, useRoute } from '../../store';

export const MainRoutes: React.FC = () => {
	const { routeHelper } = useRoute();
	let routes = routeHelper.all();

	const routeList = routes.map((route: RouteEntity) => {
		return route.redirect ? (
			<Route
				key={route.id}
				path={route.path || route.id}
				exact={true}
				render={() => {
					routeHelper.set(route.id);
					return <Redirect to={route.redirect || ''} />;
				}}
			/>
		) : route.view ? (
			<Route
				key={route.id}
				path={route.path || route.id}
				exact={false}
				render={(props: any) => {
					return <RouteView route={route} viewProps={props} />;
				}}
			/>
		) : (
			<Route key={route.id} />
		);
	});

	return <IonRouterOutlet id="main">{routeList}</IonRouterOutlet>;
};
