import React, { useState } from 'react';

// UI
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonButton,
	IonToolbar,
	IonIcon,
	IonButtons
} from '@ionic/react';
import * as icons from 'ionicons/icons';

// Configs
import { useLocaleConfig } from '../../../../app/store';
import { newOrderCardConfig } from '../../../../config/lead/components/NewOrderCard';

// Style
import '../../styles/card.css';

// Props
export interface NewOrderCardProps {
	title?: string;
	instructions?: string;
	newOrderButtonText?: string;
}

export const NewOrderCard: React.FC<NewOrderCardProps> = props => {
	const [showInstructions, setShowInstructions] = useState(false);

	const {
		config: [config],
		userHelper
	} = useLocaleConfig<NewOrderCardProps>(newOrderCardConfig);

	const newOrderButtonClicked = (e: any) => {
		window.open('http://order.conexsys.com');
	};

	return (
		<IonCard className="conexsys-card">
			<IonCardHeader>
				<IonToolbar>
					{config.title && <IonCardTitle>{config.title}</IonCardTitle>}
					{config.instructions && (
						<IonButtons slot="end">
							{showInstructions ? (
								<IonButton
									slot="end"
									onClick={() => setShowInstructions(false)}
								>
									<IonIcon
										slot="icon-only"
										icon={icons.closeCircleOutline}
									></IonIcon>
								</IonButton>
							) : (
								<IonButton slot="end" onClick={() => setShowInstructions(true)}>
									<IonIcon
										slot="icon-only"
										icon={icons.informationCircleOutline}
									></IonIcon>
								</IonButton>
							)}
						</IonButtons>
					)}
				</IonToolbar>
			</IonCardHeader>

			<IonCardContent>
				<p hidden={!showInstructions}>{config.instructions}</p>
				<IonButton color="light" expand="full" onClick={newOrderButtonClicked}>
					{config.newOrderButtonText}
				</IonButton>
			</IonCardContent>
		</IonCard>
	);
};
