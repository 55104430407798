import {
	ServiceId_Some,
	ServiceEntity_Some,
	ServiceEntityPatch_Some,
	ServiceCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const SERVICE_MUTATE_ID = '@lead/SERVICE_MUTATE_ID';
export const SERVICE_SET = '@lead/SERVICE_SET';
export const SERVICE_TOGGLE = '@lead/SERVICE_TOGGLE';
export const SERVICE_SELECT = '@lead/SERVICE_SELECT';
export const SERVICE_DESELECT = '@lead/SERVICE_DESELECT';
export const SERVICE_UPSERT = '@lead/SERVICE_UPSERT';
export const SERVICE_PATCH = '@lead/SERVICE_PATCH';
export const SERVICE_CHANGE = '@lead/SERVICE_CHANGE';
export const SERVICE_APPLY_CHANGES = '@lead/SERVICE_APPLY_CHANGES';
export const SERVICE_CANCEL_CHANGES = '@lead/SERVICE_CANCEL_CHANGES';
export const SERVICE_CACHE = '@lead/SERVICE_CACHE';
export const SERVICE_UNCACHE = '@lead/SERVICE_UNCACHE';
export const SERVICE_SETSTATE = '@lead/SERVICE_SETSTATE';
export const SERVICE_PATCHSTATE = '@lead/SERVICE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ServiceAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ServiceAction_MutateId extends EntityAction_MutateId {
	type: typeof SERVICE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active service
 *
 * @interface ServiceAction_Set
 * @extends {EntityAction_Set}
 */
export interface ServiceAction_Set extends EntityAction_Set {
	type: typeof SERVICE_SET;
	payload?: EntityId;
}

/**
 * Toggle active service
 *
 * @interface ServiceAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ServiceAction_Toggle extends EntityAction_Toggle {
	type: typeof SERVICE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select service entities
 *
 * @interface ServiceAction_Select
 * @extends {EntityAction_Select}
 */
interface ServiceAction_Select extends EntityAction_Select {
	type: typeof SERVICE_SELECT;
	payload: ServiceId_Some;
}

/**
 * Deselect service entities
 *
 * @interface ServiceAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ServiceAction_Deselect extends EntityAction_Deselect {
	type: typeof SERVICE_DESELECT;
	payload: ServiceId_Some;
}

/**
 * Upsert service entities
 *
 * @interface ServiceAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ServiceAction_Upsert extends EntityAction_Upsert {
	type: typeof SERVICE_UPSERT;
	payload: ServiceEntity_Some;
}

/**
 * Patch service entities
 *
 * @interface ServiceAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ServiceAction_Patch extends EntityAction_Patch {
	type: typeof SERVICE_PATCH;
	payload: ServiceEntityPatch_Some;
}

/**
 * Change service entities
 *
 * @interface ServiceAction_Change
 * @extends {EntityAction_Change}
 */
interface ServiceAction_Change extends EntityAction_Change {
	type: typeof SERVICE_CHANGE;
	payload: ServiceEntityPatch_Some;
}

/**
 * Apply changes to service entities
 *
 * @interface ServiceAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ServiceAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof SERVICE_APPLY_CHANGES;
	payload: ServiceId_Some;
}

/**
 * Cancel changes to service entities
 *
 * @interface ServiceAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ServiceAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof SERVICE_CANCEL_CHANGES;
	payload: ServiceId_Some;
}

/**
 * Cache service entities
 *
 * @interface ServiceAction_Cache
 * @extends {EntityAction_Cache}
 */
interface ServiceAction_Cache extends EntityAction_Cache {
	type: typeof SERVICE_CACHE;
	payload: ServiceId_Some;
}

/**
 * Uncache service entities
 *
 * @interface ServiceAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface ServiceAction_Uncache extends EntityAction_Uncache {
	type: typeof SERVICE_UNCACHE;
	payload: ServiceId_Some;
}

/**
 * Set service collection / entity state
 *
 * @interface ServiceAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ServiceAction_SetState extends EntityAction_SetState {
	type: typeof SERVICE_SETSTATE;
}

/**
 * PAtch service collection / entity state
 *
 * @interface ServiceAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ServiceAction_PatchState extends EntityAction_PatchState {
	type: typeof SERVICE_PATCHSTATE;
}

/**
 * Export service action types
 *
 * @export
 */
export type ServiceActionTypes =
	| ServiceAction_MutateId
	| ServiceAction_MutateId
	| ServiceAction_Set
	| ServiceAction_Toggle
	| ServiceAction_Select
	| ServiceAction_Deselect
	| ServiceAction_Upsert
	| ServiceAction_Patch
	| ServiceAction_Change
	| ServiceAction_ApplyChanges
	| ServiceAction_CancelChanges
	| ServiceAction_Cache
	| ServiceAction_Uncache
	| ServiceAction_SetState
	| ServiceAction_PatchState;

/**
 * Service actions helper interface
 *
 * @export
 * @interface IServiceActions
 * @extends {EntityActions}
 */
export interface IServiceActions extends EntityActions {
	//customAction(ids: ServiceId_Some): ServiceAction_CustomAction;
}

/**
 * Service actions helper
 *
 * @export
 * @class ServiceActions
 * @implements {EntityBaseActions}
 */
export class ServiceActions implements IServiceActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ServiceAction_MutateId {
		return { type: SERVICE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ServiceAction_Set {
		return { type: SERVICE_SET, payload: id };
	}
	toggle(id?: EntityId): ServiceAction_Toggle {
		return { type: SERVICE_TOGGLE, payload: id };
	}
	select(ids: ServiceId_Some): ServiceAction_Select {
		return {
			type: SERVICE_SELECT,
			payload: ids
		};
	}
	deselect(ids: ServiceId_Some): ServiceAction_Deselect {
		return {
			type: SERVICE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ServiceEntity_Some): ServiceAction_Upsert {
		return {
			type: SERVICE_UPSERT,
			payload: entities
		};
	}
	patch(entities: ServiceEntityPatch_Some): ServiceAction_Patch {
		return {
			type: SERVICE_PATCH,
			payload: entities
		};
	}
	change(entities: ServiceEntityPatch_Some): ServiceAction_Change {
		return {
			type: SERVICE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ServiceId_Some): ServiceAction_ApplyChanges {
		return {
			type: SERVICE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ServiceId_Some): ServiceAction_CancelChanges {
		return {
			type: SERVICE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: ServiceId_Some): ServiceAction_Cache {
		return {
			type: SERVICE_CACHE,
			payload: ids
		};
	}
	uncache(ids: ServiceId_Some): ServiceAction_Uncache {
		return {
			type: SERVICE_UNCACHE,
			payload: ids
		};
	}
	setState(state: ServiceCollectionState): ServiceAction_SetState {
		return {
			type: SERVICE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ServiceCollectionState>
	): ServiceAction_PatchState {
		return {
			type: SERVICE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the service actions helper
 *
 * @export
 */
export const serviceActions = new ServiceActions();
