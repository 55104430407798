import React from 'react';
import {
	IonIcon,
	IonButton,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonText,
	IonCardSubtitle,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol
} from '@ionic/react';

import * as icons from 'ionicons/icons';
import _ from 'lodash';

import './UserControl.css';

import { UserAvatar } from '../../components';

import { useAuth, useLocaleConfig } from '../../../store';

import { userControlConfig } from '../../../../config/app/components/UserControl';

export interface UserControlConfigProps {
	signIn: string;
	manageUser: string;
	addUser: string;
	signoutAll: string;
}

export interface UserControlProps {
	finish: any;
}

export const UserControl: React.FC<UserControlProps> = props => {
	const {
		config: [config],
		activeUser,
		userHelper
	} = useLocaleConfig<UserControlConfigProps>(userControlConfig);

	const { authHelper, activeAuth } = useAuth();

	let users = userHelper.all();

	const finish = () => {
		if (_.isFunction(props.finish)) props.finish();
	};

	const login = () => {
		if (activeAuth) {
			authHelper.loginWithRedirect();
		}
	};

	const addUser = () => {
		if (activeAuth) {
			// login with a new auth session, will logout the user first automaticallly
			authHelper.newLoginWithRedirect();
		}
	};

	const signoutAll = () => {
		if (activeAuth) {
			// signout all user accounts from the app user collection
			userHelper.signout(userHelper.collection.allIds);
			// logout the auth session for the current user
			authHelper.logout();
		}
	};

	return (
		<IonContent>
			<IonList>
				{users.length === 0 ? (
					<IonItem
						key="sign-out-all-users"
						lines="none"
						className="ion-align-items-center"
					>
						<IonGrid>
							<IonRow>
								<IonCol className="ion-align-self-start"></IonCol>
								<IonCol className="ion-align-self-center">
									<IonButton
										class="text-transform-none"
										color="medium"
										fill="outline"
										onClick={() => {
											login();
										}}
									>
										{config.signIn}
									</IonButton>
								</IonCol>
								<IonCol className="ion-align-self-end"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
				) : (
					<>
						{activeUser && (
							<IonItem key="active-user" lines="full">
								<IonGrid>
									<IonRow>
										<IonCol className="ion-align-self-start"></IonCol>
										<IonCol className="ion-align-self-center">
											<UserAvatar user={activeUser} />
										</IonCol>
										<IonCol className="ion-align-self-end"></IonCol>
									</IonRow>
									<IonRow>
										<IonCol className="ion-text-center">
											<IonCardTitle>{activeUser.name}</IonCardTitle>
											<IonCardSubtitle>{activeUser.email}</IonCardSubtitle>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol className="ion-text-center">
											<IonButton
												class="text-transform-none"
												color="medium"
												fill="outline"
												routerLink={'/account'}
												onClick={() => finish()}
											>
												{config.manageUser}
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonItem>
						)}
						{users.map(user => {
							return (
								<>
									{activeUser && activeUser.id !== user.id && (
										<IonItem
											lines="full"
											button
											key={user.id}
											onClick={() => {
												userHelper.set(user.id);
												finish();
											}}
										>
											<UserAvatar slot="start" user={user} />
											<IonLabel
												color={
													activeUser && activeUser.id !== user.id
														? 'primary'
														: 'secondary'
												}
											>
												<h2>
													<IonText color="dark">{user.name}</IonText>
												</h2>
												<h3>
													<IonText color="medium">{user.email}</IonText>
												</h3>
											</IonLabel>
										</IonItem>
									)}
								</>
							);
						})}
						<IonItem
							key="add-user"
							lines="full"
							button
							onClick={() => {
								addUser();
								finish();
							}}
						>
							<IonIcon slot="start" icon={icons.personAdd} />
							<IonLabel color="dark">{config.addUser}</IonLabel>
						</IonItem>
						<IonItem
							key="sign-out-all-users"
							lines="none"
							className="ion-align-items-center"
						>
							<IonGrid>
								<IonRow>
									<IonCol className="ion-align-self-start"></IonCol>
									<IonCol className="ion-align-self-center">
										<IonButton
											class="text-transform-none"
											color="medium"
											fill="outline"
											onClick={() => {
												signoutAll();
												finish();
											}}
										>
											{config.signoutAll}
										</IonButton>
									</IonCol>
									<IonCol className="ion-align-self-end"></IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					</>
				)}
			</IonList>
		</IonContent>
	);
};
