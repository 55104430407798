import React, { useState } from 'react';
import { IonSearchbar } from '@ionic/react';

import { searchBarConfig } from '../../../../config/app/components/SearchBar';

import { useLocaleConfig } from '../../../store';

export interface SearchBarConfigProps {
	placeholder: string;
}

export interface SearchBarProps {
	onChange: (value: string | undefined) => void;
	value?: string;
	placeholder?: string;
}

export const SearchBar: React.FC<SearchBarProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<SearchBarConfigProps>(searchBarConfig);

	const [value, setValue] = useState(props.value || '');

	const onChange = (e: any) => {
		setValue(e.detail?.value);
		props.onChange(e.detail?.value);
	};

	return (
		<IonSearchbar
			placeholder={props.placeholder || config.placeholder}
			value={value}
			onIonChange={onChange}
		/>
	);
};
