import React, { useState, useRef } from 'react';
import { useBarcode } from '@createnextapp/react-barcode';

export interface BarcodeProps {
	data?: string;
	size?: number;
	width?: string;
	fontSize?: number;
	displayValue?: boolean;
}

export const Barcode: React.FC<BarcodeProps> = props => {
	const { inputRef } = useBarcode({
		value: props.data || '',
		options: {
			width: props.size || 2,
			fontSize: props.fontSize || 25,
			displayValue: props.displayValue || true
		}
	});

	return <img width={props.width || 'auto'} ref={inputRef} />;
};
