import React, { useState } from 'react';
import {
	IonCardHeader,
	IonCardTitle,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonLabel,
	IonInput,
	IonButton,
	IonContent,
	IonSelect,
	IonSelectOption,
	IonPopover,
	IonIcon,
	IonListHeader
} from '@ionic/react';
import { Redirect } from 'react-router';

import _ from 'lodash';
import { ellipsisHorizontal, trashOutline } from 'ionicons/icons';
import {
	QualifierValueEntity,
	QualifierValueEntities,
	QualifierEntity,
	QualifierValueType,
	ServiceEntity,
	ObjectType,
	Value
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import { newEntity } from '../../../../app/utils';

export interface InputField {
	errorMessage?: string;
	required?: boolean;
}

export interface InputFormFields {
	[name: string]: InputField;
}

export interface InputForm {
	fields: InputFormFields;
	valid: boolean;
}

export interface AddEditQualifierValueInputForm extends InputForm {
	fields: {
		name: InputField;
		type: InputField;
		value: InputField;
	};
}

export interface AddEditQualifierValueProps {
	service: ServiceEntity;
	qualifier: QualifierEntity;
	qualifierValue?: QualifierValueEntity;
	finish: any;
}

export const AddEditQualifierValue: React.FC<AddEditQualifierValueProps> = props => {
	const ctx = useCtx<AddEditQualifierValueProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	const qualifierValues: QualifierValueEntities = ctx.lead.qualifierValue.allByQualifierId(
		props.qualifier.id
	);

	// create a new entity if one does not exist and track it in the component state
	const [qualifierValue, setQualifierValue] = useState<QualifierValueEntity>(
		props.qualifierValue || {
			...newEntity(ctx, ObjectType.QualifierValue),
			qualifierId: props.qualifier.id,
			name: '',
			value: ('' as unknown) as Value,
			position:
				(qualifierValues.length
					? Math.max.apply(
							Math,
							qualifierValues.map(function (o) {
								return o.position || 0;
							})
					  ) || 0
					: 0) + 1,
			type: QualifierValueType.Selection
		}
	);

	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});
	const [inputForm, setInputForm] = useState<AddEditQualifierValueInputForm>();

	const finish = () => {
		props.finish();
	};

	/*if (!qualifierHelper.get(props.qualifierId)) {
		finish();
		return <></>;
	}*/

	const existingQualifierValue = ctx.lead.qualifierValue.get(qualifierValue.id);
	/*const mutatedQualifierValue = ctx.lead.qualifierValue.mutated([
		qualifierValue.id
	])[0];*/

	const validateForm = () => {
		setInputForm({
			fields: {
				name: {
					required: true,
					errorMessage: !qualifierValue.name
						? 'Name is required'
						: qualifierValues.filter(
								value =>
									value.id !== qualifierValue.id &&
									value.name.toLowerCase().trim() ===
										qualifierValue.name.toLowerCase().trim()
						  ).length > 0
						? 'Duplicate qualifier name'
						: undefined
				},
				type: {
					required: true,
					errorMessage: !qualifierValue.type ? 'Type is required' : undefined
				},
				value: {
					required: true,
					errorMessage: !qualifierValue.value
						? 'Value is required'
						: qualifierValues.filter(
								val =>
									val.id !== qualifierValue.id &&
									val.value.toString().toLowerCase().trim() ===
										qualifierValue.value.toString().toLowerCase().trim()
						  ).length > 0
						? 'Duplicate qualifier value'
						: undefined
				}
			},
			valid: false
		});
		return true;
	};

	const onChange = (e: any) => {
		let targetValue =
			!('checked' in e.target) || e.target.checked ? e.target.value : '';

		let targetName: string = e.target.name;

		//Validation
		let isValid = validateForm();

		//Save Changes
		if (isValid) {
			let change: QualifierValueEntity = _.set(
				_.cloneDeep(qualifierValue),
				e.target.name,
				targetValue
			);

			// if the name is changed and it was previously the same as the value. Keep the value set to the same as the new name targetValue
			if (
				targetName === 'name' &&
				qualifierValue.name === qualifierValue.value.toString()
			)
				change.value = targetValue;

			//ctx.lead.qualifierValue.change(change);
			setQualifierValue(change);
		}
	};
	const onSave = (e: any) => {
		//ctx.lead.qualifierValue.applyChanges(qualifierValue.id);
		ctx.lead.qualifierValue.write(ctx, {
			serviceId: props.service.id,
			qualifierValues: [qualifierValue]
		});
		finish();
	};
	const onDelete = (e: any) => {
		//ctx.lead.qualifierValue.cancelChanges(qualifierValue.id);
		ctx.lead.qualifierValue.writeDelete(ctx, qualifierValue.id);
		finish();
	};
	const onCancel = (e: any) => {
		//ctx.lead.qualifierValue.cancelChanges(qualifierValue.id);
		finish();
	};

	return (
		<IonContent>
			<IonRow>
				<IonCol>
					<IonCardHeader>
						<IonCardTitle>Qualifier Selection</IonCardTitle>
					</IonCardHeader>
				</IonCol>
				<IonCol class="text-right">
					{existingQualifierValue && (
						<IonButton
							fill="clear"
							onClick={e =>
								setShowPopover({ open: true, event: e.nativeEvent })
							}
						>
							<IonIcon icon={ellipsisHorizontal} />
						</IonButton>
					)}
					<IonPopover
						isOpen={showPopover.open}
						event={showPopover.event}
						onDidDismiss={e =>
							setShowPopover({ open: false, event: undefined })
						}
					>
						<IonList>
							<IonListHeader>Options</IonListHeader>
							<IonItem button onClick={onDelete}>
								<IonIcon icon={trashOutline} />
								Delete Value
							</IonItem>
						</IonList>
					</IonPopover>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonList>
						<IonItem
							color={inputForm?.fields.name.errorMessage ? 'danger' : ''}
						>
							<IonLabel position="floating">
								Display Name {inputForm?.fields.name.errorMessage}
							</IonLabel>
							<IonInput
								placeholder="Enter Value Name"
								name="name"
								value={qualifierValue.name}
								onIonChange={onChange}
								required
							></IonInput>
						</IonItem>
						<IonItem
							hidden={true}
							color={inputForm?.fields.type.errorMessage ? 'danger' : ''}
						>
							<IonLabel position="floating">Value Type</IonLabel>
							<IonSelect
								name="type"
								value={qualifierValue.type}
								onIonChange={onChange}
								placeholder="Select One"
							>
								<IonSelectOption value="selection">Selection</IonSelectOption>
							</IonSelect>
						</IonItem>
						{qualifierValue.type != QualifierValueType.DataEntry && (
							<IonItem
								color={inputForm?.fields.value.errorMessage ? 'danger' : ''}
							>
								<IonLabel position="floating">Export Value</IonLabel>
								<IonInput
									name="value"
									value={qualifierValue.value.toString()}
									onIonChange={onChange}
									placeholder="Enter Value"
								></IonInput>
							</IonItem>
						)}
					</IonList>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonButton color="light" onClick={onCancel}>
						Cancel
					</IonButton>
				</IonCol>
				<IonCol class="text-right">
					<IonButton
						//disabled={mutatedQualifierValue ? false : true}
						onClick={onSave}
					>
						Save
					</IonButton>
				</IonCol>
			</IonRow>
		</IonContent>
	);
};
