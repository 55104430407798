import { TicketEntities } from '../../lead/store/collections/Ticket';

export interface TicketConfig {
	entities: TicketEntities;
	activeId?: string;
}

export const ticketConfig: TicketConfig = {
	entities: []
};
