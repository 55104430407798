import React, { useEffect } from 'react';
import { IonApp, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './ui/styles/variables.css';

/* CSS config */
import './ui/styles/main.css';

import { AppData, MainMenu, MainRoutes } from './ui/components';

import { useUser } from './store';

import { useApiData } from '../lead/store/init';

import { ToastProvider } from '@agney/ir-toast';
import { Chat, ChatType } from './ui/components/Chat';
import { config as Config } from '../config';
import * as utils from './utils';
import AppProvider from './AppContext';

const App: React.FC = () => {
	const { activeUser } = useUser();

	// get the theme in order of presidence,  active user settings, default user settings, first theme in app config
	const theme =
		activeUser?.settings?.theme ||
		Object.keys(Config.app.themes)[0] ||
		Config.app.user.settings.default.theme ||
		'';

	useApiData();

	useEffect(() => {
		document.title = Config.name;
	}, []);

	return (
		<AppProvider>
			<IonApp className={Config.app.themes[theme]?.className || ''}>
				<ToastProvider value={{ color: 'primary', duration: 5000 }}>
					<AppData>
						<IonReactRouter>
							<IonSplitPane contentId="main">
								<MainMenu />
								<MainRoutes />
							</IonSplitPane>
						</IonReactRouter>
					</AppData>
				</ToastProvider>
				<Chat
					type={ChatType.TawkTo}
					chatId="6089c08c62662a09efc312ef"
					chatKey="1f4d1g9ii"
				></Chat>
			</IonApp>
		</AppProvider>
	);
};

export default App;
