import { useEffect, useState } from 'react';

export interface UseClipboardProps {
	updateFrequency?: number;
	readTries?: number;
	onReadError?: any;
}

const CONFIG = {
	updateFrequency: 1000,
	readTries: 3
};

export type SetClipboard = (
	text: string,
	onCopy?: (text: string) => void,
	onError?: (error: any) => void
) => void;

export interface ClipboardState {
	read: boolean;
	write: boolean;
}
export type UseClipboard = [string, SetClipboard, ClipboardState];

const useClipboard = (props: UseClipboardProps): UseClipboard => {
	const [clipboard, setClipboardText] = useState('');
	const [readTries, setReadTries] = useState(0);
	const [readGranted, setReadGranted] = useState(false);
	const [writeGranted, setWriteGranted] = useState(false);

	const setClipboard: SetClipboard = (
		text: string,
		onCopy?: (text: string) => void,
		onError?: (error: any) => void
	) => {
		if (navigator.permissions && navigator.clipboard) {
			navigator.permissions
				.query({ name: 'clipboard-write' as PermissionName })
				.then(({ state }) => {
					if (['granted', 'prompt'].includes(state)) {
						setWriteGranted(true);
						navigator.clipboard.writeText(text).then(() => {
							setClipboardText(text);
							setReadTries(props.readTries || CONFIG.readTries);
							if (onCopy) onCopy(text);
						}, onError || (() => {}));
					} else {
						setWriteGranted(false);
						if (onError)
							onError({
								message: 'Clipboard write access denied.'
							});
					}
				});
		}
	};

	useEffect(() => {
		let readIntervalId: any;
		if (navigator.permissions && navigator.clipboard) {
			navigator.permissions
				.query({ name: 'clipboard-read' as PermissionName })
				.then(({ state }) => {
					readIntervalId = setInterval(() => {
						if (['granted'].includes(state)) {
							setReadGranted(true);

							navigator.clipboard.readText().then(text => {
								setClipboardText(text);
							}, props.onReadError || (() => {}));
						} else {
							setReadGranted(false);
							if (readTries > 0) {
								setReadTries(readTries - 1);
							} else {
								setClipboardText('');
							}
							if (props.onReadError)
								props.onReadError({
									message: 'Clipboard read access denied'
								});
						}
					}, props.updateFrequency || CONFIG.updateFrequency);
				});
		}
		return () => {
			if (navigator.clipboard) {
				clearInterval(readIntervalId);
			}
		};
	}, [readTries]);

	return [clipboard, setClipboard, { read: readGranted, write: writeGranted }];
};

export default useClipboard;
