import {
	ApiCollection,
	ApiCollectionState,
	ApiId,
	ApiEntity_Some,
	ApiEntityPatch_Some,
	ApiId_Some,
	apiCollectionDefault,
	apiCollectionMutateIdOpts
} from '../collections/Api';
import * as actions from '../actions/Api';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Api reducer
 *
 * @export
 * @param {ApiCollection} [state=apiCollectionDefault]
 * @param {ApiActionTypes} action
 * @returns
 */
export function apiReducer(
	state: ApiCollection = apiCollectionDefault,
	action: actions.ApiActionTypes
) {
	switch (action.type) {
		case actions.API_MUTATE_ID:
			return collectionReducerActions.mutateId<ApiCollection>(
				state,
				action.payload,
				apiCollectionMutateIdOpts
			);
		case actions.API_SET:
			return collectionReducerActions.set<ApiCollection, ApiId>(
				state,
				action.payload
			);
		case actions.API_TOGGLE:
			return collectionReducerActions.toggle<ApiCollection, ApiId>(
				state,
				action.payload
			);
		case actions.API_SELECT:
			return collectionReducerActions.select<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_DESELECT:
			return collectionReducerActions.deselect<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_UPSERT:
			return collectionReducerActions.upsert<ApiCollection, ApiEntity_Some>(
				state,
				action.payload
			);
		case actions.API_PATCH:
			return collectionReducerActions.patch<ApiCollection, ApiEntityPatch_Some>(
				state,
				action.payload
			);
		case actions.API_CHANGE:
			return collectionReducerActions.change<
				ApiCollection,
				ApiEntityPatch_Some
			>(state, action.payload);
		case actions.API_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_CACHE:
			return collectionReducerActions.cache<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_UNCACHE:
			return collectionReducerActions.uncache<ApiCollection, ApiId_Some>(
				state,
				action.payload
			);
		case actions.API_SETSTATE:
			return collectionReducerActions.setState<
				ApiCollection,
				ApiCollectionState
			>(state, action.payload.state);
		case actions.API_PATCHSTATE:
			return collectionReducerActions.patchState<
				ApiCollection,
				ApiCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
