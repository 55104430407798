// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * USER: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { UserHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { UserEntity, UserEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum UserApiOperation {
	getUserById = 'getUserById',
	readUsers = 'readUsers',
	readUsersAll = 'readUsersAll',
	writeUsers = 'writeUsers'
}

export interface GetUserByIdRequest {
	id: string;
}

export interface ReadUsersRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	serviceIds?: string[];
}

export interface ReadUsersAllRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	serviceIds?: string[];
	email?: string;
	username?: string;
}

export interface WriteUsersRequest {
	userId: string;
	users: UserEntities;
}

/**
 * Get user by id
 */
export async function getUserById(
	ctx: UseCtx<any>,
	requestParameters: GetUserByIdRequest
): Promise<UserEntity | undefined> {
	const operationId = UserApiOperation.getUserById,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<UserEntity, GetUserByIdRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entity: UserEntity | undefined = response.response.data;
	if (entity) {
		// patch entities states with an api success data for the operation
		setEntitiesApiOperationState<UserEntities, UserHelper>(
			ctx.lead.user,
			[entity],
			operationId,
			requestId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);

		// upsert the entities to the store
		ctx.lead.user.upsert(entity);
	}

	// return the entities to the caller for direct reference
	return entity;
}

/**
 * Read users
 */
export async function readUsers(
	ctx: UseCtx<any>,
	requestParameters: ReadUsersRequest
): Promise<UserEntities> {
	const operationId = UserApiOperation.readUsers,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<UserEntities, ReadUsersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: UserEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<UserEntities, UserHelper>(
		ctx.lead.user,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.user.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read users
 */
export async function readUsersAll(
	ctx: UseCtx<any>,
	requestParameters: ReadUsersAllRequest
): Promise<UserEntities> {
	const operationId = UserApiOperation.readUsersAll,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<UserEntities, ReadUsersAllRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: UserEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<UserEntities, UserHelper>(
		ctx.lead.user,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.user.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Users
 */
export async function writeUsers<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteUsersRequest
): Promise<UserEntities> {
	const operationId = UserApiOperation.writeUsers,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.users.forEach(
		(entity: UserEntity, i: number, a: UserEntities) => {
			a[i] = modifyEntity<UserEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<UserEntities, WriteUsersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'users'
	);

	let entities: UserEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<UserEntities, UserHelper>(
		ctx.lead.user,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.user.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
