import { EventContactEntities } from '../../lead/store/collections/EventContact';

export interface EventContactConfig {
	entities: EventContactEntities;
	activeId?: string;
}

export const eventContactConfig: EventContactConfig = {
	entities: []
};
