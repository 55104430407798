import {
	EventQuestionCollection,
	EventQuestionCollectionState,
	EventQuestionId,
	EventQuestionEntity_Some,
	EventQuestionEntityPatch_Some,
	EventQuestionId_Some,
	eventQuestionCollectionDefault,
	eventQuestionCollectionMutateIdOpts
} from '../collections/EventQuestion';
import * as actions from '../actions/EventQuestion';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * EventQuestion reducer
 *
 * @export
 * @param {EventQuestionCollection} [state=eventquestionCollectionDefault]
 * @param {EventQuestionActionTypes} action
 * @returns
 */
export function eventQuestionReducer(
	state: EventQuestionCollection = eventQuestionCollectionDefault,
	action: actions.EventQuestionActionTypes
) {
	switch (action.type) {
		case actions.EVENTQUESTION_MUTATE_ID:
			return collectionReducerActions.mutateId<EventQuestionCollection>(
				state,
				action.payload,
				eventQuestionCollectionMutateIdOpts
			);
		case actions.EVENTQUESTION_SET:
			return collectionReducerActions.set<
				EventQuestionCollection,
				EventQuestionId
			>(state, action.payload);
		case actions.EVENTQUESTION_TOGGLE:
			return collectionReducerActions.toggle<
				EventQuestionCollection,
				EventQuestionId
			>(state, action.payload);
		case actions.EVENTQUESTION_SELECT:
			return collectionReducerActions.select<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_DESELECT:
			return collectionReducerActions.deselect<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_UPSERT:
			return collectionReducerActions.upsert<
				EventQuestionCollection,
				EventQuestionEntity_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_PATCH:
			return collectionReducerActions.patch<
				EventQuestionCollection,
				EventQuestionEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_CHANGE:
			return collectionReducerActions.change<
				EventQuestionCollection,
				EventQuestionEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_CACHE:
			return collectionReducerActions.cache<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_UNCACHE:
			return collectionReducerActions.uncache<
				EventQuestionCollection,
				EventQuestionId_Some
			>(state, action.payload);
		case actions.EVENTQUESTION_SETSTATE:
			return collectionReducerActions.setState<
				EventQuestionCollection,
				EventQuestionCollectionState
			>(state, action.payload.state);
		case actions.EVENTQUESTION_PATCHSTATE:
			return collectionReducerActions.patchState<
				EventQuestionCollection,
				EventQuestionCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
