// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Data type
 * @export
 * @enum {string}
 */
export enum DataResultStatusType {
	Queued = 'queued',
	Processing = 'processing',
	Error = 'error',
	Success = 'success',
	Undo = 'undo',
	Undone = 'undone'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesDataResultStatusType = [
	'queued',
	'processing',
	'error',
	'success',
	'undo',
	'undone'
];
