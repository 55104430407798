import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getEventCollection,
	EventId,
	EventIds,
	EventId_Some,
	EventEntity,
	EventEntities,
	EventEntity_Some,
	EventEntityPatch_Some,
	EventCollection,
	EventCollectionState,
	IEventActions,
	eventActions,
	EventActionTypes
} from '..';
import {
	readEvents,
	readEventsAll,
	writeEvents,
	ReadEventsRequest,
	ReadEventsAllRequest,
	WriteEventsRequest,
	EventApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { ContextEntities, ServiceEntities } from '../collections';
import moment from 'moment';
import { isObjectStatusActive } from '../models';

/**
 * Event helper interface
 *
 * @export
 * @interface IEventHelper
 * @extends {IEntityHelper}
 */
export interface IEventHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Event helper options interface
 *
 * @export
 * @interface EventHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface EventHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const eventHelperOpts: EventHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

export interface EventEntityFilter {
	keyWords?: string;
}

/**
 * Event helper
 *
 * @export
 * @class EventHelper
 * @extends {EntityHelper<EventCollection, EventActionTypes, EventActions, EventEntity, EventEntities, EventEntity_Some, EventEntityPatch_Some, EventId, EventIds, EventId_Some, EventCollectionState, EventHelperOpts>}
 * @implements {IEventHelper}
 */
export class EventHelper
	extends EntityHelper<
		EventCollection,
		EventActionTypes,
		IEventActions,
		EventEntity,
		EventEntities,
		EventEntity_Some,
		EventEntityPatch_Some,
		EventId,
		EventIds,
		EventId_Some,
		EventCollectionState,
		EventHelperOpts
	>
	implements IEventHelper {
	constructor() {
		super(
			useSelector(getEventCollection),
			eventActions,
			useDispatch(),
			eventHelperOpts
		);
		this.collection = useSelector(getEventCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: EventApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadEventsRequest> = {},
		callback?: any
	): Promise<EventEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(EventApiOperation.readEvents);

		let request: ReadEventsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: EventEntities = await readEvents(ctx, request)
			.then((entities: EventEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadEventsAllRequest> = {},
		callback?: any
	): Promise<EventEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadEventsAllRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};

		let entities: EventEntities = await readEventsAll(ctx, request)
			.then((entities: EventEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				console.log(e);
				if (callback) callback(e);
				return [];
			});

		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteEventsRequest> = {},
		callback?: any
	): Promise<EventEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.events) return [];
		let request: WriteEventsRequest = {
			...params,
			...{
				events: params.events,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: EventEntities = await writeEvents(ctx, request)
			.then((entities: EventEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByFilter(ctx: UseCtx<any>, filter: EventEntityFilter): EventEntities {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(event => {
				let match = 0,
					matches = 0,
					keywords = filter.keyWords ?? '';

				if (!event) {
					return false;
				}

				for (let keyword of keywords.toLowerCase().split(' ')) {
					if (keyword === '') break;
					match++;
					if (
						(event.name &&
							event.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(event.code &&
							event.code?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(event.id && event.id?.toLowerCase().trim().indexOf(keyword) > -1)
					) {
						matches++;
					}
				}
				return match === matches;
			});
	}

	allByContexts(
		contexts: ContextEntities,
		services?: ServiceEntities
	): EventEntities {
		return contexts.length > 0
			? (services ? this.allByServices(services) : this.all())
					.filter(event => {
						for (let context of contexts) {
							if (context.eventIds.includes(event.id)) return true;
						}
						return false;
					})
					.filter(isObjectStatusActive)
			: [];
	}

	allByServices(services: ServiceEntities): EventEntities {
		return services.length > 0
			? this.all()
					.filter(event => {
						for (let service of services) {
							if (service.eventIds?.includes(event.id)) return true;
						}
						return false;
					})
					.filter(isObjectStatusActive)
			: [];
	}

	setServiceDefault(ctx: UseCtx<any>, events?: EventEntities) {
		let activeService = ctx.lead.service.active();
		// if there is no active service. don't try to set the active event
		if (!activeService) return;
		let activeEvent = this.active();
		// if there is a current active event and it's within the active service. then leave it as it. no need for the default.
		if (activeEvent && activeService.eventIds?.includes(activeEvent.id)) return;

		// filter all service events and sort them descending by their modified date and set the latest modified (first) event as the active event
		this.set(
			(events || this.all())
				.filter(event => activeService?.eventIds?.includes(event.id))
				.filter(isObjectStatusActive)
				.sort((a: EventEntity | any, b: EventEntity | any) =>
					a.i_.modified.dt &&
					b.i_.modified.dt &&
					moment(a.i_.modified.dt).isAfter(moment(b.i_.modified.dt))
						? -1
						: 1
				)[0]?.id
		);
	}

	async readActiveData(ctx: UseCtx<any>, callback?: any) {
		let eventId: string = ctx.lead.event.active()?.id || '';
		if (eventId !== '') {
			ctx.lead.eventQuestion.readEventQuestions(ctx, { eventId }, () => {
				console.log('Event questions loaded: ' + eventId);
			});
			ctx.lead.eventAnswer.readEventAnswers(ctx, { eventId }, () => {
				console.log('Event answers loaded: ' + eventId);
			});

			if (callback) callback();
		}
	}
}
