// React
import React, { useEffect, useState } from 'react';

// UI
import * as icons from 'ionicons/icons';

//DATA
import {
	LeadEntity,
	LeadEntities,
	EventContactEntity,
	isObjectStatusActive,
	NoteEntity,
	DataFields,
	readEventContactAnswers
} from '../../store';
import { useCtx } from '../../../config/hooks';

//LIB
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';

// Selector
import {
	SelectorItem,
	SelectorItems
} from '../../../app/ui/components/Selector';
import { useInterval } from '../../../app/store';

// Sort Options
export enum SortType {
	Ascending = 'ascending',
	Descending = 'descending'
}

export interface Sort {
	value: any;
	direction: SortType;
}

// Lead with contact information
type LeadObject = { lead: LeadEntity; contact?: EventContactEntity };

export const useLeadsQuery = (leads: LeadEntities) => {
	// Hook into context
	const ctx = useCtx({});
	const {
		lead: {
			activeContext,
			activeService,
			eventContactHelper,
			noteHelper,
			userHelper,
			responseHelper,
			qualifierHelper,
			qualifierValueHelper
		}
	} = ctx;

	//  Leads state
	const [queryLeads, setQueryLeads] = useState<LeadObject[]>();
	const [currentPaginationLeads, setCurrentPaginationLeads] = useState<
		LeadObject[]
	>();
	const [currentServiceId, setCurrentServiceId] = useState('');
	const [modifiedFrom, setModifiedFrom] = useState('');

	// query status
	const [isLoading, setIsLoading] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState('');

	// Keyword state
	const [keyword, setKeyword] = useState<string | undefined>();

	// Sort options
	const [sortLeadsBy, setSortLeadsBy] = useState<Sort>({
		value: 'DateScanned',
		direction: SortType.Ascending
	});

	const sortItems: SelectorItems = [
		{
			icon: icons.calendarOutline,
			text: 'Date Scanned',
			value: 'DateScanned'
		},
		{
			icon: icons.personOutline,
			text: 'Last Name',
			value: 'LastName'
		},
		{
			icon: icons.businessOutline,
			text: 'Company',
			value: 'Company'
		}
	];

	// Sort function
	const onSortLeadsByChange = (item: SelectorItem) => {
		setSortLeadsBy({ ...sortLeadsBy, value: item.value || item.text });
	};

	const toggleSortDirection = () => {
		sortLeadsBy.direction === SortType.Ascending
			? setSortLeadsBy({ ...sortLeadsBy, direction: SortType.Descending })
			: setSortLeadsBy({ ...sortLeadsBy, direction: SortType.Ascending });
	};

	// Keyword search function
	const onSetKeywordChange = (value: string | undefined) => {
		if (value) {
			setKeyword(value.trim());
		} else {
			setKeyword('');
		}
	};

	// Pagination
	const [showPagination, setShowPagination] = useState(false);
	const [paginationLimit, setPaginationLimit] = useState(15);
	const [paginationCurrent, setPaginationCurrent] = useState(1);
	const [paginationTotal, setPaginationTotal] = useState(2);

	// Pagination functions
	useEffect(() => {
		if (!queryLeads) {
			setShowPagination(false);
			setCurrentPaginationLeads([]);
		} else {
			if (queryLeads.length > paginationLimit) {
				setCurrentPaginationLeads(
					queryLeads.slice(
						paginationLimit * (paginationCurrent - 1),
						paginationLimit * paginationCurrent
					)
				);
				setShowPagination(true);
				setPaginationTotal(Math.ceil(queryLeads.length / paginationLimit));
			} else {
				setCurrentPaginationLeads(queryLeads);
				setShowPagination(false);
			}
		}
	}, [paginationCurrent, paginationLimit, queryLeads]);

	// Query conext leads
	function query() {
		// Regex for keyword
		let keywordRegex = new RegExp(keyword || '', 'i');
		/* 
            map -> return lead and contact
            sort -> return sorted array
            filter -> filter down based on keyowrd
        */
		let results = leads
			.map(lead => ({
				lead,
				contact: eventContactHelper.get(lead.contactId)
			}))
			.sort((a: LeadObject, b: LeadObject) =>
				(
					sortLeadsBy.value === 'LastName'
						? eventContactHelper.getLastName(a.contact) >
						  eventContactHelper.getLastName(b.contact)
						: sortLeadsBy.value === 'Company'
						? eventContactHelper.getOrganizationName(a.contact) >
						  eventContactHelper.getOrganizationName(b.contact)
						: moment(a.lead.i_?.created?.dt).isAfter(
								moment(b.lead.i_?.created?.dt)
						  )
				)
					? sortLeadsBy.direction === SortType.Ascending
						? 1
						: -1
					: sortLeadsBy.direction === SortType.Ascending
					? -1
					: 1
			)
			.filter(({ lead, contact }) => {
				// Reread
				if (lead.i_?.modified.dt && lead.i_.modified.dt > modifiedFrom) {
					setModifiedFrom(lead.i_.modified.dt);
				}
				// Return true is blank
				if (keyword === '') return true;

				// Get values
				let name = eventContactHelper.getName(contact),
					organization = eventContactHelper.getOrganizationName(contact);

				// Check against regex
				if (
					name.match(keywordRegex) ||
					organization.match(keywordRegex) ||
					contact?.email?.match(keywordRegex) ||
					contact?.city?.match(keywordRegex) ||
					contact?.postal?.match(keywordRegex) ||
					contact?.country?.match(keywordRegex)
				)
					return true;

				return false;
			});

		setQueryLeads(results);
	}
	// Run query on lead changes
	useEffect(() => {
		// If any data changes, run the query
		query();
	}, [leads, sortLeadsBy, keyword]);

	useEffect(() => {
		if (
			activeService &&
			currentServiceId !== '' &&
			activeService.id !== currentServiceId
		)
			setModifiedFrom('');

		setCurrentServiceId(activeService?.id || '');
	}, [activeService]);

	const [startExport, setStartExport] = useState(false);
	const [goExport, setGoExport] = useState(false);
	const [exportItems, setExportItems] = useState<LeadObject[]>([]);
	const [exportLoadingItems, setExportLoadingItems] = useState<LeadObject[]>(
		[]
	);

	const leadDataFields: DataFields =
		ctx.lead.context.active()?.leadDataFields || [];
	const eventQuestions = ctx.lead.eventQuestion.allActive(ctx);

	useEffect(() => {
		if (startExport) {
			exportDataLoad();
			exporting();
		}
	}, [startExport]);

	useEffect(() => {
		if (goExport) {
			setStartExport(false);

			if (
				!goExport ||
				exportItems.length !==
					leads
						.map(lead => ({
							lead,
							contact: eventContactHelper.get(lead.contactId)
						}))
						.sort((a: LeadObject, b: LeadObject) =>
							(
								sortLeadsBy.value === 'LastName'
									? eventContactHelper.getLastName(a.contact) >
									  eventContactHelper.getLastName(b.contact)
									: sortLeadsBy.value === 'Company'
									? eventContactHelper.getOrganizationName(a.contact) >
									  eventContactHelper.getOrganizationName(b.contact)
									: moment(a.lead.i_?.created?.dt).isAfter(
											moment(b.lead.i_?.created?.dt)
									  )
							)
								? sortLeadsBy.direction === SortType.Ascending
									? 1
									: -1
								: sortLeadsBy.direction === SortType.Ascending
								? -1
								: 1
						)?.length
			) {
				return;
			}

			setGoExport(false);

			onGoExportLeads();
		}
	}, [goExport]);

	useInterval(
		(next: any, startExport: boolean) => {
			if (startExport) {
				exporting();
			}
			next();
		},
		2000,
		startExport
	);

	const onExportLeads = async () => {
		setStartExport(true);
		setIsLoading(true);
		setLoadingStatus('Exporting leads...');
	};

	const exportDataLoad = async () => {
		let userId = ctx.app.user.active()?.userId || '';
		if (leadDataFields.length > 0) {
			let missingAnswerItems: LeadObject[] | undefined = leads
				.map(lead => ({
					lead,
					contact: eventContactHelper.get(lead.contactId)
				}))
				.sort((a: LeadObject, b: LeadObject) =>
					(
						sortLeadsBy.value === 'LastName'
							? eventContactHelper.getLastName(a.contact) >
							  eventContactHelper.getLastName(b.contact)
							: sortLeadsBy.value === 'Company'
							? eventContactHelper.getOrganizationName(a.contact) >
							  eventContactHelper.getOrganizationName(b.contact)
							: moment(a.lead.i_?.created?.dt).isAfter(
									moment(b.lead.i_?.created?.dt)
							  )
					)
						? sortLeadsBy.direction === SortType.Ascending
							? 1
							: -1
						: sortLeadsBy.direction === SortType.Ascending
						? -1
						: 1
				)
				?.filter(
					item =>
						item.contact &&
						!item.contact.answers &&
						!exportLoadingItems.find(item2 => item2.lead.id === item.lead.id)
				);
			if (missingAnswerItems) {
				for (let item of missingAnswerItems) {
					await new Promise(resolve => setTimeout(resolve, 200));
					if (item.contact) {
						let contactId = item.contact.id,
							eventId = item.contact.eventId;
						readEventContactAnswers(ctx, { userId, contactId, eventId });
					}
				}
			}
		}
	};

	const exporting = async () => {
		let stillLoadingItems: LeadObject[] =
			leads
				.map(lead => ({
					lead,
					contact: eventContactHelper.get(lead.contactId)
				}))
				.sort((a: LeadObject, b: LeadObject) =>
					(
						sortLeadsBy.value === 'LastName'
							? eventContactHelper.getLastName(a.contact) >
							  eventContactHelper.getLastName(b.contact)
							: sortLeadsBy.value === 'Company'
							? eventContactHelper.getOrganizationName(a.contact) >
							  eventContactHelper.getOrganizationName(b.contact)
							: moment(a.lead.i_?.created?.dt).isAfter(
									moment(b.lead.i_?.created?.dt)
							  )
					)
						? sortLeadsBy.direction === SortType.Ascending
							? 1
							: -1
						: sortLeadsBy.direction === SortType.Ascending
						? -1
						: 1
				)
				?.filter(
					item =>
						!item.contact ||
						(leadDataFields.length > 0 && !item.contact?.answers)
				) ?? [];

		setExportLoadingItems(stillLoadingItems);

		if (stillLoadingItems.length !== 0) {
			console.log('stillLoadingItems');
			setExportLoadingItems(stillLoadingItems);
			setExportItems([]);
			setLoadingStatus('Preparing ' + stillLoadingItems.length + ' items...');
			setGoExport(false);
		} else {
			setExportLoadingItems([]);
			setExportItems(
				leads
					.map(lead => ({
						lead,
						contact: eventContactHelper.get(lead.contactId)
					}))
					.sort((a: LeadObject, b: LeadObject) =>
						(
							sortLeadsBy.value === 'LastName'
								? eventContactHelper.getLastName(a.contact) >
								  eventContactHelper.getLastName(b.contact)
								: sortLeadsBy.value === 'Company'
								? eventContactHelper.getOrganizationName(a.contact) >
								  eventContactHelper.getOrganizationName(b.contact)
								: moment(a.lead.i_?.created?.dt).isAfter(
										moment(b.lead.i_?.created?.dt)
								  )
						)
							? sortLeadsBy.direction === SortType.Ascending
								? 1
								: -1
							: sortLeadsBy.direction === SortType.Ascending
							? -1
							: 1
					) ?? []
			);
			setGoExport(true);
		}
	};

	const onGoExportLeads = async () => {
		setLoadingStatus('Generating File...');

		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet('Leads');
		let cols = [
			{ header: 'Id', key: 'code' },
			{ header: 'First Name', key: 'firstName' },
			{ header: 'Last Name', key: 'lastName' },
			{ header: 'Designation', key: 'designation' },
			{ header: 'Title', key: 'title' },
			{ header: 'Company', key: 'organization' },
			{ header: 'Address1', key: 'address1' },
			{ header: 'Address2', key: 'address2' },
			{ header: 'City', key: 'city' },
			{ header: 'State/Prov', key: 'subdivision' },
			{ header: 'Postal/Zip', key: 'postal' },
			{ header: 'Country', key: 'country' },
			{ header: 'Email', key: 'email' },
			{ header: 'Phone', key: 'phone' }
		];

		let dataFields: DataFields = [];

		for (let f = 0; f < leadDataFields.length; f++) {
			let field = leadDataFields[f];
			dataFields.push(field);
			let question = eventQuestions.filter(q => field.id === q.id)[0];
			cols.push({
				header:
					field.name ||
					question?.name ||
					question?.text ||
					field.id ||
					field.key ||
					'Field ' + f + 1,
				key: 'field' + f + 1
			});
		}

		cols.push({ header: 'Date Scanned', key: 'dateScanned' });
		cols.push({ header: 'Scanned By', key: 'scannedBy' });
		cols.push({ header: 'Notes', key: 'notes' });

		let mainColCount = cols.length;
		let allQualifiers = !activeService
			? []
			: qualifierHelper.allByServiceIdAndEventIds(
					activeService.id,
					activeContext!.eventIds
			  );
		allQualifiers.forEach(qualifier => {
			cols.push({ header: qualifier.name, key: qualifier.id });
		});
		worksheet.columns = cols;
		worksheet.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		leads
			.map(lead => ({
				lead,
				contact: eventContactHelper.get(lead.contactId)
			}))
			.sort((a: LeadObject, b: LeadObject) =>
				(
					sortLeadsBy.value === 'LastName'
						? eventContactHelper.getLastName(a.contact) >
						  eventContactHelper.getLastName(b.contact)
						: sortLeadsBy.value === 'Company'
						? eventContactHelper.getOrganizationName(a.contact) >
						  eventContactHelper.getOrganizationName(b.contact)
						: moment(a.lead.i_?.created?.dt).isAfter(
								moment(b.lead.i_?.created?.dt)
						  )
				)
					? sortLeadsBy.direction === SortType.Ascending
						? 1
						: -1
					: sortLeadsBy.direction === SortType.Ascending
					? -1
					: 1
			)
			?.forEach((item, index) => {
				let lead = item.lead,
					contact = item.contact;

				if (!contact) return;

				let fieldData: any = {};

				for (let f = 0; f < dataFields.length; f++) {
					let field = dataFields[f];
					let question = eventQuestions.filter(q => field.id === q.id)[0];

					let answerString = question
						? !contact.answers
							? 'loading...'
							: contact.answers
									.filter(a => a.questionId === question.id)
									.filter(isObjectStatusActive)
									.map((contactAnswer): string | undefined => {
										if (contactAnswer.text && contactAnswer.text !== '') {
											return contactAnswer.text;
										} else if (contactAnswer.answerId) {
											return ctx.lead.eventAnswer.get(contactAnswer.answerId)
												?.value;
										} else {
											return;
										}
									})
									.filter(s => s && s !== '')
									.join(', ') || ''
						: '';

					fieldData['field' + f + 1] = answerString;
				}

				let newRow = worksheet.addRow(
					{
						...contact,
						...fieldData,
						scannedBy: lead.i_.created?.by
							? userHelper.get(lead.i_.created?.by)?.name
							: '',
						dateScanned: moment(lead.i_.created?.dt).format('YYYY-MM-DD hh:mm'),
						notes:
							noteHelper.allByLead(lead)?.length > 0
								? noteHelper.allByLead(lead).map((note: NoteEntity) => {
										return note.value;
								  })
								: ''
					},
					''
				);

				let leadResponses = responseHelper.allByLead(lead);
				allQualifiers.forEach((qualifier, index) => {
					let cell = newRow.getCell(mainColCount + 1 + index);
					let qualifierValueIds: string[] = leadResponses
						.filter(
							response =>
								response.qualifierId === qualifier.id &&
								response.value === 'true'
						)
						.map(value => value.qualifierValueId ?? '');
					let qualifierValues = qualifierValueHelper
						.gets(qualifierValueIds)
						.filter(isObjectStatusActive);

					if (cell && qualifierValues) {
						let columnValue = '';
						qualifierValues.forEach((qualifierValue, index) => {
							columnValue =
								(columnValue === '' ? columnValue : columnValue + ',') +
								qualifierValue.name;
						});
						cell.value = columnValue;
					}
				});

				let notesVlaues = '';
				noteHelper.allByLead(lead).forEach((note, index) => {
					notesVlaues =
						(notesVlaues === '' ? notesVlaues : notesVlaues + ',') + note.value;
				});
				let notesCell = newRow.getCell(mainColCount);
				if (notesCell) {
					notesCell.value = notesVlaues;
				}
			});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], { type: fileType });

		saveAs(
			blob,
			(activeContext!.code || '') +
				'Leads-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		setStartExport(false);
		setIsLoading(false);
		setLoadingStatus('');
	};

	// Is a new sort items
	const isNewSortLead = (index: number) => {
		if (currentPaginationLeads) {
			let lead = currentPaginationLeads[index].lead,
				contact = currentPaginationLeads[index].contact;
			let newSortValue = '';

			switch (sortLeadsBy.value) {
				case 'LastName':
					let conactName = eventContactHelper.getLastName(contact);

					if (index > 0) {
						let prevContact = currentPaginationLeads[index - 1].contact,
							prevName = eventContactHelper.getLastName(prevContact);
						if (
							(conactName?.charAt(0).toUpperCase() ?? '') !=
							(prevName?.charAt(0).toUpperCase() ?? '')
						) {
							newSortValue = conactName?.charAt(0).toUpperCase() ?? '';
						}
					} else {
						newSortValue = conactName?.charAt(0).toUpperCase() ?? '';
					}
					break;

				case 'Company':
					let organizationName = eventContactHelper.getOrganizationName(
						contact
					);

					if (index > 0) {
						let prevContact = currentPaginationLeads[index - 1].contact,
							prevOrganzition = eventContactHelper.getOrganizationName(
								prevContact
							);
						if (
							(organizationName?.charAt(0).toUpperCase() ?? '') !=
							(prevOrganzition?.charAt(0).toUpperCase() ?? '')
						) {
							newSortValue = organizationName?.charAt(0).toUpperCase() ?? '';
						}
					} else {
						newSortValue = organizationName?.charAt(0).toUpperCase() ?? '';
					}
					break;

				default:
					let leadDate = moment(lead.i_.created?.dt).format(
						'dddd Do MMMM, YYYY'
					);
					if (index > 0) {
						let prevLeadDate = moment(
							currentPaginationLeads[index - 1].lead.i_.created?.dt
						).format('dddd Do MMMM, YYYY');
						if ((leadDate ?? '') != (prevLeadDate ?? '')) {
							newSortValue = leadDate ?? '';
						}
					} else {
						newSortValue = leadDate ?? '';
					}
					break;
			}

			// Return if new sort divider
			return newSortValue != '' ? newSortValue : false;
		} else {
			return false;
		}
	};

	// Return object
	return {
		isLoading,
		loadingStatus,
		onExportLeads,
		sortItems,
		sortLeadsBy,
		onSortLeadsByChange,
		toggleSortDirection,
		isNewSortLead,
		keyword,
		onSetKeywordChange,
		queryLeads,
		showPagination,
		setPaginationCurrent,
		paginationCurrent,
		paginationTotal,
		paginationLimit,
		setPaginationLimit,
		currentPaginationLeads,
		modifiedFrom
	};
};
