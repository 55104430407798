import React, { useEffect, useState } from 'react';

//Data
import { UserEntity, UserEntities, UserEntityFilter } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonList,
	IonItem,
	IonSearchbar,
	IonToolbar,
	IonTitle,
	IonContent,
	IonSelect,
	IonButtons,
	IonSelectOption,
	IonButton,
	IonIcon
} from '@ionic/react';
import { Avatar } from '../../../app/ui/components';
import * as icons from 'ionicons/icons';

/*********************************************************
 * AdminUsersView
 *********************************************************/
const AdminUsersView: React.FC = () => {
	const ctx = useCtx<{}>({});

	const {
		config: [config],
		app: { activeUser },
		lead: { userHelper, activeContext }
	} = ctx;

	const [userFilter, setUserFilter] = useState<UserEntityFilter>({
		keyWords: '',
		contextIds: [activeContext?.id || '']
	});

	/*********************************************************
	 * State Objects
	 *********************************************************/
	//Pagination
	const [paginationLimit, setPaginationLimit] = useState(15);
	const [paginationCurrent, setPaginationCurrent] = useState(1);

	/*********************************************************
	 * Static Objects
	 *********************************************************/
	const users: UserEntities = userHelper.allByFilter(ctx, userFilter);
	const userListItems = users
		.sort((a: UserEntity, b: UserEntity) =>
			(a.email ?? '').toLocaleLowerCase() > (b.email ?? '').toLocaleLowerCase()
				? 1
				: -1
		)
		.slice(
			(paginationCurrent - 1) * paginationLimit,
			(paginationCurrent - 1) * paginationLimit + paginationLimit
		)
		.map((user: UserEntity) => {
			return (
				<IonItem
					routerLink="/lead-service/user"
					key={user.id}
					button
					onClick={() => {
						userHelper.set(user.id);
					}}
				>
					<IonGrid>
						<IonRow>
							<IonCol size="5" sizeMd="5">
								<IonLabel>
									<div>
										<div
											style={{
												display: 'inline-block',
												paddingRight: '20px'
											}}
										>
											<Avatar
												//src={currentUser?.picture}
												name={user.name}
											/>
										</div>
										<div
											style={{
												display: 'inline-block'
											}}
										>
											<h2>
												{user.firstName || user.lastName
													? user.firstName + ' ' + user.lastName
													: ''}
											</h2>
											<h2>{user.email}</h2>
										</div>
									</div>
								</IonLabel>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	/*********************************************************
	 * Action Methods
	 *********************************************************/
	const resetFilter = () => {
		setUserFilter(prevFilter => ({
			...prevFilter,
			keyWords: '',
			contextIds: [activeContext?.id || '']
		}));
	};

	const onSerach = (value: string | undefined) => {
		setUserFilter(prevFilter => ({
			...prevFilter,
			contextIds: [activeContext?.id || ''],
			keyWords: value ? value : ''
		}));
	};

	/*********************************************************
	 * useEffect
	 *********************************************************/
	useEffect(() => {
		setPaginationLimit(15);
		setPaginationCurrent(1);

		resetFilter();
	}, [activeContext]);

	return (
		<IonContent>
			<IonToolbar>
				<IonTitle>Admin: Users</IonTitle>

				<IonButtons class="ion-float-right">
					<IonLabel>Page Size</IonLabel>
					<IonSelect
						interface="popover"
						name="pageSizeSelect"
						multiple={false}
						value={paginationLimit}
						placeholder="Select One"
						onIonChange={e => {
							setPaginationLimit(e.detail.value);
						}}
					>
						<IonSelectOption value={15}>15</IonSelectOption>
						<IonSelectOption value={25}>25</IonSelectOption>
						<IonSelectOption value={50}>50</IonSelectOption>
						<IonSelectOption value={100}>100</IonSelectOption>
					</IonSelect>
					<IonButton
						onClick={() => setPaginationCurrent(paginationCurrent - 1)}
						disabled={paginationCurrent === 1}
					>
						<IonIcon slot="icon-only" icon={icons.arrowBack} />
					</IonButton>
					<IonLabel>
						{paginationCurrent} of{' '}
						{Math.ceil((users.length ?? 1) / paginationLimit)}
					</IonLabel>
					<IonButton
						onClick={() => setPaginationCurrent(paginationCurrent + 1)}
						disabled={
							paginationCurrent ===
							Math.ceil((users.length ?? 1) / paginationLimit)
						}
					>
						<IonIcon slot="icon-only" icon={icons.arrowForward} />
					</IonButton>
				</IonButtons>
				<IonSearchbar
					value={userFilter.keyWords}
					onIonChange={e => onSerach(e.detail.value)}
				></IonSearchbar>
			</IonToolbar>
			<IonList>{userListItems}</IonList>
		</IonContent>
	);
};

export default AdminUsersView;
