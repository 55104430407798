import { toTitleCase } from '../../../app/utils';
import React, { useState } from 'react';
import { EntityList, EntityEdit } from '../components';
import { ObjectType, ObjectAny } from '../../store';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonFab,
	IonFabButton,
	IonIcon,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonFabList,
	IonContent
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { useCtx } from '../../../config/hooks';
import { appApis } from '../../../app/api';
import config from '../../../config';
import {
	SchemasObject,
	SchemaObject
} from '../../../app/store/models/OpenApi3';
import _ from 'lodash';

export interface EntityAdminViewConfig {}

export interface EntityAdminViewProps {
	types: ObjectType[];
}

const EntityAdminView: React.FC<any | EntityAdminViewProps> = props => {
	const ctx = useCtx();
	const [editEntity, setEditEntity] = useState<ObjectAny | undefined>(
		undefined
	);
	const [editEntityType, setEditEntityType] = useState<ObjectType | undefined>(
		undefined
	);
	const [editSchema, setEditSchema] = useState<SchemaObject | undefined>(
		undefined
	);

	let schemas: SchemasObject =
		appApis[config.entityApiId]?.schema.components?.schemas || {};

	const startEdit = (entity: ObjectAny) => {
		console.log('BAZZINGA');

		let typeSchema: SchemaObject | undefined =
			schemas[
				Object.keys(schemas).filter(
					(key: string) =>
						_.get(schemas[key], 'x-.object.type') === entity.i_.type
				)[0]
			];

		if (!typeSchema) {
			throw 'Invalid api schema type: ' + entity.i_.type;
		} else {
			setEditEntity(entity);
			setEditEntityType(entity.i_.type);
			setEditSchema(typeSchema);
		}
	};

	const startAdd = (type: ObjectType) => {
		console.log('HERE WE GO: ' + type);
		setEditEntity(undefined);
		setEditEntityType(type);
	};

	const finishEdit = () => {
		console.log('BLAMMO');
		setEditEntity(undefined);
		setEditEntityType(undefined);
	};

	return !Array.isArray(props.types) || props.types.length === 0 ? (
		<IonContent>
			<IonLabel>Missing view types</IonLabel>
		</IonContent>
	) : (
		<IonContent>
			<IonGrid>
				<IonRow hidden={editEntityType !== undefined}>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Admin:{' '}
									{props.types
										.map((type: string) =>
											toTitleCase(type.split('.')[0], true)
										)
										.join(' / ')}
								</IonCardTitle>
								<IonFab vertical="top" horizontal="end">
									<IonFabButton
										size="small"
										onClick={
											props.types.length === 1
												? () => startAdd(props.types[0])
												: undefined
										}
									>
										<IonIcon icon={add} />
									</IonFabButton>
									{props.types.length > 1 && (
										<IonFabList>
											{props.types.map((type: ObjectType) => (
												<IonFabButton
													key={'fab-add-' + type}
													onClick={() => startAdd(type)}
													title={type}
												>
													<IonIcon icon={add} />
												</IonFabButton>
											))}
										</IonFabList>
									)}
								</IonFab>
							</IonCardHeader>
							<IonCardContent>
								<EntityList types={props.types} edit={startEdit} />
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					{editEntityType && editSchema && (
						<IonCol>
							<EntityEdit
								type={editEntityType}
								schema={editSchema}
								entity={editEntity}
								finish={finishEdit}
							/>
						</IonCol>
					)}
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default EntityAdminView;
