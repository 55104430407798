import { DeviceHelper } from '..';
import { DeviceEntity } from '../collections';

export interface DeviceHook {
	deviceHelper: DeviceHelper;
	activeDevice: DeviceEntity | undefined;
}

export const useDevice = (): DeviceHook => {
	let deviceHelper = new DeviceHelper();
	return {
		deviceHelper,
		activeDevice: deviceHelper.active()
	};
};
