import React, { useEffect, useState } from 'react';

//Data
import {
	QualifierEntity,
	QualifierEntities,
	ServiceEntity,
	EventEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonReorderGroup,
	IonIcon,
	useIonViewWillEnter,
	useIonViewWillLeave
} from '@ionic/react';
import { QualifierListItem } from '../../components/Qualifier/QualifierListItem';
import { SearchBar } from '../../../../app/ui/components/Search/SearchBar';
import { add, clipboardOutline } from 'ionicons/icons';
import { useInterval } from '../../../../app/store';

export interface QualifierListConfigProps {
	service: ServiceEntity;
	event: EventEntity;
	edit: (qualifier: QualifierEntity) => void;
}

export const QualifierList: React.FC<QualifierListConfigProps> = props => {
	const [searchText, setSearchText] = useState('');
	const [active, setActive] = useState(true);
	const [currentServiceId, setCurrentServiceId] = useState('');
	const [modifiedFrom, setModifiedFrom] = useState('');

	const ctx = useCtx<QualifierListConfigProps>({}); // TO DO: create and set (qualifierListConfig)
	const {
		config: [config],
		lead: { activeService }
	} = ctx;

	useEffect(() => {
		if (!activeService || activeService.id !== currentServiceId)
			setModifiedFrom('');

		setCurrentServiceId(activeService?.id || '');
	}, [activeService]);

	const read = (next: any) => {
		let modFrom = modifiedFrom !== '' ? modifiedFrom : undefined;
		ctx.lead.qualifier.read(
			ctx,
			{
				modifiedFrom: modFrom
			},
			next
		);
		ctx.lead.qualifierValue.read(
			ctx,
			{
				modifiedFrom: modFrom
			},
			next
		);
	};

	useInterval(
		(next: any, active: boolean) => {
			if (active) {
				read(next);
				return;
			}
			next();
		},
		60000,
		active
	);

	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	// Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	const sortQualifiers = (a: QualifierEntity, b: QualifierEntity) => {
		return (a.position as number) > (b.position as number)
			? 1
			: (b.position as number) > (a.position as number)
			? -1
			: 0;
	};

	const qualifiers: QualifierEntities = ctx.lead.qualifier
		.allByServiceIdAndEventIds(props.service.id, [props.event.id])
		.filter(qualifier => {
			let match = 0,
				matches = 0,
				keywords = searchText ?? '';
			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (qualifier.name?.toLowerCase().trim().indexOf(keyword) > -1) {
					matches++;
				}
			}
			return match === matches;
		});

	const qualifierListItems = qualifiers
		.sort(sortQualifiers)
		.map((qualifier: QualifierEntity) => {
			return (
				<QualifierListItem
					key={qualifier.id}
					qualifier={qualifier}
					edit={props.edit}
				/>
			);
		});

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	return qualifierListItems.length <= 0 ? (
		<IonLabel class="ion-text-center">
			<br />
			<h1>
				<IonIcon icon={clipboardOutline} size="large" />
			</h1>
			<h2>No qualifiers found.</h2>
			<p>
				Add your custom qualifiers by selecting the "<IonIcon icon={add} />"
				above.
			</p>
		</IonLabel>
	) : (
		<IonGrid>
			<IonRow>
				<IonCol>
					<SearchBar onChange={onSerach} />
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonReorderGroup>{qualifierListItems}</IonReorderGroup>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
