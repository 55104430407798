import { Localize, translations } from '../../locales';
import { UserAccountCardConfigProps } from '../../../app/ui/containers/User/UserAccountCard';

export type UserAccountCardConfig = Localize<UserAccountCardConfigProps>;

export const userAccountCardConfig: UserAccountCardConfig = {
	en: {
		signIn: translations.signIn.en,
		profile: {
			title: 'Account Profile'
		}
	},
	fr: {
		signIn: translations.signIn.fr,
		profile: {
			title: 'Profil du compte'
		}
	}
};
