import React, { useEffect, useState } from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonCardSubtitle,
	IonLoading
} from '@ionic/react';

import { useLocaleConfig } from '../../../../app/store';
import { useCtx } from '../../../../config/hooks';
import { useHistory } from 'react-router';
import { useToast } from '@agney/ir-toast';
import { ServiceEntity } from '../../../store';
import qs from 'qs';
import _ from 'lodash';

export interface ActivateServiceCardProps {
	title?: string;
	instructions?: string;
	codePlaceholder?: string;
	activateOrderButtonText?: string;
}

export const ActivateServiceCard: React.FC<ActivateServiceCardProps> = props => {
	const [initialized, setInitialized] = useState(false);
	const [serviceId, setServiceId] = useState('');
	const [contextId, setContextId] = useState('');
	const [goActivate, setGoActivate] = useState(false);
	const [activating, setActivating] = useState(false);
	const [activationStatus, setActivationStatus] = useState('');
	const {
		config: [config]
	} = useLocaleConfig<ActivateServiceCardProps>();

	const ctx = useCtx<{}>({});

	const history = useHistory();

	const Toast = useToast();

	useEffect(() => {
		init();
		if (!activating && goActivate) {
			setGoActivate(false);
			activate();
		}
	}, [goActivate]);

	const init = async () => {
		if (!initialized) {
			let serviceId = qs.parse(window.location.search, {
				ignoreQueryPrefix: true
			}).serviceId;
			let contextId = qs.parse(window.location.search, {
				ignoreQueryPrefix: true
			}).contextId;
			if (typeof serviceId === 'string') {
				setServiceId(serviceId);
			}
			if (typeof contextId === 'string') {
				setContextId(contextId);
			}
			if (serviceId !== '' && contextId !== '') setGoActivate(true);
			setInitialized(true);
		}
	};

	const onError = (message: string) => {
		Toast.error(message);
		setActivating(false);
	};

	const onSuccess = (result: ServiceEntity) => {
		Toast.success('Order successfully activated!');

		ctx.lead.service.set(result.id);
		ctx.lead.context.set(contextId);

		setActivating(false);
		setServiceId('');
		setContextId('');
		history.push('/main');
	};

	const activate = async () => {
		setActivating(true);
		ctx.lead.service.activate(ctx, serviceId, contextId, onError, onSuccess);
	};

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>Activate Service</IonCardTitle>
				<IonCardSubtitle></IonCardSubtitle>
			</IonCardHeader>
			{!activating ? (
				<IonCardContent>
					<IonGrid>
						<IonRow>
							<IonCol>Activation Issue. Please try again</IonCol>
						</IonRow>
					</IonGrid>
				</IonCardContent>
			) : (
				<IonCardContent>
					<IonLoading
						isOpen={true}
						message={`<h2>Activating</h2>${activationStatus}`}
						duration={10000}
					/>
				</IonCardContent>
			)}
		</IonCard>
	);
};
