import { combineReducers } from 'redux';

import { contactReducer } from './Contact';
import { eventAnswerReducer } from './EventAnswer';
import { eventContactReducer } from './EventContact';
import { eventQuestionReducer } from './EventQuestion';
import { contextReducer } from './Context';
import { deviceReducer } from './Device';
import { eventReducer } from './Event';
import { leadReducer } from './Lead';
import { noteReducer } from './Note';
import { orderReducer } from './Order';
import { qualifierReducer } from './Qualifier';
import { qualifierValueReducer } from './QualifierValue';
import { responseReducer } from './Response';
import { serviceReducer } from './Service';
import { ticketReducer } from './Ticket';
import { userReducer } from './User';
import { licenseReducer } from './License';
import { dataReducer } from './Data';

export const reducers = combineReducers({
	contact: contactReducer,
	eventAnswer: eventAnswerReducer,
	eventContact: eventContactReducer,
	eventQuestion: eventQuestionReducer,
	context: contextReducer,
	device: deviceReducer,
	event: eventReducer,
	lead: leadReducer,
	note: noteReducer,
	order: orderReducer,
	qualifier: qualifierReducer,
	qualifierValue: qualifierValueReducer,
	response: responseReducer,
	service: serviceReducer,
	ticket: ticketReducer,
	user: userReducer,
	license: licenseReducer,
	data: dataReducer
});

export * from './Contact';
export * from './EventAnswer';
export * from './EventContact';
export * from './EventQuestion';
export * from './Context';
export * from './Device';
export * from './Event';
export * from './Lead';
export * from './Note';
export * from './Order';
export * from './Qualifier';
export * from './QualifierValue';
export * from './Response';
export * from './Service';
export * from './Ticket';
export * from './User';
export * from './License';
export * from './Data';
