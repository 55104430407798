import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { authConfig } from '../../../config/app/Auth';
import { Auth } from '../models';

/**
 * Auth entity interface
 *
 * @export
 * @interface AuthEntity
 * @extends {storage.Entity}
 * @extends {Auth}
 */
export interface AuthEntity extends storage.Entity, Auth {}

/**
 * Auth entities array
 *
 * @export
 */
export type AuthEntities = AuthEntity[];

/**
 * Some auth entities: one entity or an array of entities
 *
 * @export
 */
export type AuthEntity_Some = AuthEntity | AuthEntities;

/**
 * Auth entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface AuthEntityPatch
 * @extends {storage.EntityPatch<AuthEntity>}
 */
export type AuthEntityPatch = storage.EntityPatcher<AuthEntity>;

/**
 * Auth entity patches array
 *
 * @export
 */
export type AuthEntitiesPatch = AuthEntityPatch[];

/**
 * Some auth entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type AuthEntityPatch_Some = AuthEntityPatch | AuthEntitiesPatch;

/**
 * Auth entity id
 *
 * @export
 */
export type AuthId = storage.EntityId;

/**
 * Auth entity ids
 *
 * @export
 */
export type AuthIds = storage.EntityIds;

/**
 * Some auth entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type AuthId_Some = AuthId | AuthIds;

/**
 * Auth entities object by id
 *
 * @export
 * @interface AuthEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface AuthEntitiesObject extends storage.EntitiesObject {
	[id: string]: AuthEntity;
}

/**
 * Auth entity state
 *
 * @export
 * @interface AuthEntityState
 * @extends {storage.EntityState}
 */
export interface AuthEntityState extends storage.EntityState {}

/**
 * Auth store collection
 *
 * @export
 * @interface AuthStoreCollection
 * @extends {storage.Store}
 */
export interface AuthStoreCollection extends storage.Store {
	auth: AuthCollection;
}

/**
 * Auth collection properties
 *
 * @export
 * @interface AuthCollection
 * @extends {Collection}
 */
export interface AuthCollection extends storage.Collection {
	byIds: AuthEntitiesObject;
	mutation: AuthEntitiesObject;
	cache: AuthEntitiesObject;
	state: AuthCollectionState;
}

/**
 * Auth collection state
 *
 * @export
 * @interface AuthCollectionState
 * @extends {storage.CollectionState}
 */
export interface AuthCollectionState extends storage.CollectionState {}

// custom auth collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const authCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const authCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const authCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const authCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: authCollectionIdStringProps,
	idArrayProps: authCollectionIdArrayProps,
	idEntityProps: authCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Auth collection default values, initialize collection with auth entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const authCollectionDefault: AuthCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as AuthCollection),
		...{
			activeId: authConfig.activeId || authConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	authConfig.entities
);

/**
 * Auth store collection default values
 *
 * @export
 * @constant
 */
export const authStoreCollectionDefault: AuthStoreCollection = {
	auth: authCollectionDefault
};

/**
 * Auth store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getAuthCollection = (state: any) => state.app.auth;
