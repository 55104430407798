import {
	EventAnswerCollection,
	EventAnswerCollectionState,
	EventAnswerId,
	EventAnswerEntity_Some,
	EventAnswerEntityPatch_Some,
	EventAnswerId_Some,
	eventAnswerCollectionDefault,
	eventAnswerCollectionMutateIdOpts
} from '../collections/EventAnswer';
import * as actions from '../actions/EventAnswer';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * EventAnswer reducer
 *
 * @export
 * @param {EventAnswerCollection} [state=eventanswerCollectionDefault]
 * @param {EventAnswerActionTypes} action
 * @returns
 */
export function eventAnswerReducer(
	state: EventAnswerCollection = eventAnswerCollectionDefault,
	action: actions.EventAnswerActionTypes
) {
	switch (action.type) {
		case actions.EVENTANSWER_MUTATE_ID:
			return collectionReducerActions.mutateId<EventAnswerCollection>(
				state,
				action.payload,
				eventAnswerCollectionMutateIdOpts
			);
		case actions.EVENTANSWER_SET:
			return collectionReducerActions.set<EventAnswerCollection, EventAnswerId>(
				state,
				action.payload
			);
		case actions.EVENTANSWER_TOGGLE:
			return collectionReducerActions.toggle<
				EventAnswerCollection,
				EventAnswerId
			>(state, action.payload);
		case actions.EVENTANSWER_SELECT:
			return collectionReducerActions.select<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_DESELECT:
			return collectionReducerActions.deselect<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_UPSERT:
			return collectionReducerActions.upsert<
				EventAnswerCollection,
				EventAnswerEntity_Some
			>(state, action.payload);
		case actions.EVENTANSWER_PATCH:
			return collectionReducerActions.patch<
				EventAnswerCollection,
				EventAnswerEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTANSWER_CHANGE:
			return collectionReducerActions.change<
				EventAnswerCollection,
				EventAnswerEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTANSWER_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_CACHE:
			return collectionReducerActions.cache<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_UNCACHE:
			return collectionReducerActions.uncache<
				EventAnswerCollection,
				EventAnswerId_Some
			>(state, action.payload);
		case actions.EVENTANSWER_SETSTATE:
			return collectionReducerActions.setState<
				EventAnswerCollection,
				EventAnswerCollectionState
			>(state, action.payload.state);
		case actions.EVENTANSWER_PATCHSTATE:
			return collectionReducerActions.patchState<
				EventAnswerCollection,
				EventAnswerCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
