import React, { useEffect } from 'react';

//DATA
import {
	ServiceEntity,
	ContextEntities,
	isObjectStatusActive,
	LicenseEntities,
	LicenseServiceType
} from '../../store';
import { useCtx } from '../../../config/hooks';

import { Barcode } from '../../../app/ui/components/Barcode';

import uuid from '../../../app/utils/uuid';

import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';
import moment from 'moment';

const AdminSignoutSheetView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const {
		lead: {
			activeService,
			contextHelper,
			activeEvent,
			contactHelper,
			licenseHelper
		}
	} = ctx;
	const currentService: ServiceEntity | undefined = activeService;
	if (!currentService || !activeEvent) return <ServiceNotFoundCard />;

	const contexts: ContextEntities = activeEvent
		? contextHelper.allByEventId(activeEvent.id)
		: [];

	const licenses: LicenseEntities = licenseHelper.allByContexts(contexts, [
		currentService
	]);

	const leadOrders: { [key: string]: number } = {
		Opticon: licenses
			.filter(l => l.serviceType == LicenseServiceType.Opticon)
			.reduce((sum, current) => sum + current.quantity, 0),
		LeadApp: licenses
			.filter(l => l.serviceType == LicenseServiceType.LeadApp)
			.reduce((sum, current) => sum + current.quantity, 0),
		LeadApi: licenses
			.filter(l => l.serviceType == LicenseServiceType.LeadApi)
			.reduce((sum, current) => sum + current.quantity, 0),
		Sst: licenses
			.filter(l => l.serviceType == LicenseServiceType.Sst)
			.reduce((sum, current) => sum + current.quantity, 0)
	};

	const mainContact = contactHelper.get_Organization(
		activeService?.contactIds ? activeService.contactIds[0] ?? '' : ''
	);

	let eventDays: string[] = [];
	let startDate = moment(activeEvent?.start).clone(),
		endDate = moment(activeEvent?.end).clone();

	while (startDate.isSameOrBefore(endDate)) {
		eventDays.push(startDate.format('M/D/YYYY'));
		startDate.add(1, 'days');
		if (eventDays.length >= 10) {
			break;
		}
	}

	return (
		<div style={{ margin: '20px' }}>
			<div style={{ margin: '10px' }}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						display: 'inline-block'
					}}
				>
					<h2>{currentService.name}</h2>
					<br />
					{activeEvent.name}
					<br />
					Sign-Out Sheet
				</div>
			</div>
			<div style={{ margin: '10px' }}>
				<div style={{ width: '30%', display: 'inline-block' }}>
					<strong>Mailing Address</strong>
					<br />
					{mainContact?.address1} {mainContact?.address2}
					<br />
					{mainContact?.city} {mainContact?.subdivision} {mainContact?.postal}
					<br />
					{mainContact?.contactEmail}
					<br />
				</div>
				<div style={{ width: '30%', display: 'inline-block' }}></div>
				<div style={{ width: '30%', display: 'inline-block' }}>
					<strong>Licenses</strong>
					<br />
					<div hidden={leadOrders.Opticon <= 0}>
						Opticon: {leadOrders.Opticon}
					</div>
					<div hidden={leadOrders.LeadApp <= 0}>
						Lead App: {leadOrders.LeadApp}
					</div>
					<div hidden={leadOrders.LeadApi <= 0}>
						Lead Api: {leadOrders.LeadApi}
					</div>
					<div hidden={leadOrders.Sst <= 0}>SST: {leadOrders.Sst}</div>
				</div>
			</div>
			<div style={{ margin: '10px' }}>
				<div style={{ width: '30%', display: 'inline-block' }}>
					<Barcode
						key={currentService?.id}
						data={'S%' + uuid().compress(currentService?.id || '')}
						width="300px"
						size={4}
					/>
				</div>
			</div>
			<div style={{ margin: '10px' }}>
				<div style={{ width: '30%', display: 'inline-block' }}></div>
			</div>

			{eventDays.map(day => {
				return (
					<div key={day} style={{ margin: '25px 15px' }}>
						<div style={{ width: '50%', display: 'inline-block' }}>
							{'   '}
							{moment(day).format('MM/DD/YYYY')} _____________________________
						</div>
						<div style={{ width: '50%', display: 'inline-block' }}>
							{'   '}
							Returned ______________________________
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default AdminSignoutSheetView;
