import { LeadEntities } from '../../lead/store/collections/Lead';

export interface LeadConfig {
	entities: LeadEntities;
	activeId?: string;
}

export const leadConfig: LeadConfig = {
	entities: []
};
