import React from 'react';
import {
	IonContent,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol
} from '@ionic/react';
import _ from 'lodash';

import { UserAvatar, UserControl } from '../../components';

import { useLocaleConfig } from '../../../store';

import { userAccountCardConfig } from '../../../../config/app/components/UserAccountCard';

export interface UserAccountCardConfigProps {
	signIn: string;
	profile: {
		title: string;
	};
}

export interface UserAccountCardProps {
	finish: any;
}

export const UserAccountCard: React.FC<UserAccountCardProps> = props => {
	const {
		config: [config],
		activeUser
	} = useLocaleConfig<UserAccountCardConfigProps>(userAccountCardConfig);

	const finish = () => {
		if (_.isFunction(props.finish)) props.finish();
	};

	return (
		<IonContent>
			<IonGrid>
				{!activeUser ? (
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>{config.signIn}</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<UserControl finish={() => {}} />
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				) : (
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>{config.profile.title}</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<IonGrid>
										<IonRow>
											<IonCol>
												<UserAvatar user={activeUser} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonCardTitle>{activeUser.name}</IonCardTitle>
												<IonCardSubtitle>{activeUser.email}</IonCardSubtitle>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				)}
			</IonGrid>
		</IonContent>
	);
};
