import React, { useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonSelect,
	IonSelectOption,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonContent
} from '@ionic/react';
import {
	SummaryReport,
	TrafficReport,
	QualifierReport,
	LocationReport,
	RepresentativeReport
} from '../../../lead/ui/containers/Report';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { ServiceNotFoundCard } from './../components/Service/ServiceNotFoundCard';

const ReportsView: React.FC = () => {
	const [currentReport, setCurrentReport] = useState('Summary');
	const {
		config: [config],
		lead: { activeService, activeEvent }
	} = useCtx<{}>({});

	if (!activeService) return <ServiceNotFoundCard />;

	const onReportChanged = (value: string) => {
		setCurrentReport(value);
		displayReport();
	};
	let displayReport = () => {
		if (currentReport == 'Traffic') {
			return <TrafficReport service={activeService} event={activeEvent} />;
		} else if (currentReport == 'Qualifiers') {
			return <QualifierReport service={activeService} event={activeEvent} />;
		} else if (currentReport == 'Location') {
			return <LocationReport service={activeService} event={activeEvent} />;
		} else if (currentReport == 'Representative') {
			return (
				<RepresentativeReport service={activeService} event={activeEvent} />
			);
		} else {
			return (
				<SummaryReport
					service={activeService}
					event={activeEvent}
					goToReport={onReportChanged}
				/>
			);
		}
	};

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol>
						<PageTitle title="Reports" displayEventSelector={true} />
					</IonCol>
				</IonRow>
				{activeEvent && (
					<IonRow>
						<IonCol>
							<IonGrid>
								<IonRow>
									<IonCol size="12">
										<IonCard>
											<IonCardContent>
												<IonItem>
													<IonLabel position="fixed">Report Name: </IonLabel>
													<IonSelect
														interface="popover"
														name="activeReportSelect"
														multiple={false}
														cancelText="Cancel"
														okText="Okay"
														value={currentReport}
														placeholder="Select One"
														onIonChange={e => onReportChanged(e.detail.value)}
													>
														<IonSelectOption value="Summary">
															Summary
														</IonSelectOption>
														<IonSelectOption value="Traffic">
															Traffic
														</IonSelectOption>
														<IonSelectOption value="Qualifiers">
															Qualifiers
														</IonSelectOption>
														<IonSelectOption value="Location">
															Location
														</IonSelectOption>
														<IonSelectOption value="Representative">
															Representative
														</IonSelectOption>
													</IonSelect>
												</IonItem>
											</IonCardContent>
										</IonCard>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="12">{displayReport()}</IonCol>
								</IonRow>
							</IonGrid>
						</IonCol>
					</IonRow>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default ReportsView;
