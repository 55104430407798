import React from 'react';
import { IonCard, IonCardContent, IonText } from '@ionic/react';

import {
	LeadEntity,
	useContact,
	ContactEntity,
	isContactIndividualEntity,
	isContactOrganizationEntity
} from '../../../store';

export interface LeadCardProps extends LeadEntity {
	id: string;
}

function viewLead(id: string) {
	window.location.href = '/lead/' + id;
}

export const LeadCard: React.FC<LeadCardProps> = lead => {
	const { contactHelper } = useContact();
	const contact: ContactEntity | undefined = contactHelper.get(lead.contactId);

	return (
		<IonCard>
			<IonCardContent onClick={() => viewLead(lead.id)}>
				<IonText color="primary">
					<h1>{contactHelper.getName(contact, 'n/a')}</h1>
				</IonText>
			</IonCardContent>
		</IonCard>
	);
};
