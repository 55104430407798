import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { deviceConfig } from '../../../config/lead/Device';
import { Device } from '../models';

/**
 * Device entity interface
 *
 * @export
 * @interface DeviceEntity
 * @extends {storage.Entity}
 */
export interface DeviceEntity extends storage.Entity, Device {}

/**
 * Device entities array
 *
 * @export
 */
export type DeviceEntities = DeviceEntity[];

/**
 * Some device entities: one entity or an array of entities
 *
 * @export
 */
export type DeviceEntity_Some = DeviceEntity | DeviceEntities;

/**
 * Device entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface DeviceEntityPatch
 * @extends {storage.EntityPatch<DeviceEntity>}
 */
export type DeviceEntityPatch = storage.EntityPatcher<DeviceEntity>;

/**
 * Device entity patches array
 *
 * @export
 */
export type DeviceEntitiesPatch = DeviceEntityPatch[];

/**
 * Some device entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type DeviceEntityPatch_Some = DeviceEntityPatch | DeviceEntitiesPatch;

/**
 * Device entity id
 *
 * @export
 */
export type DeviceId = storage.EntityId;

/**
 * Device entity ids
 *
 * @export
 */
export type DeviceIds = storage.EntityIds;

/**
 * Some device entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type DeviceId_Some = DeviceId | DeviceIds;

/**
 * Device entities object by id
 *
 * @export
 * @interface DeviceEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface DeviceEntitiesObject extends storage.EntitiesObject {
	[id: string]: DeviceEntity;
}

/**
 * Device entity state
 *
 * @export
 * @interface DeviceEntityState
 * @extends {storage.EntityState}
 */
export interface DeviceEntityState extends storage.EntityState {}

/**
 * Device store collection
 *
 * @export
 * @interface DeviceStoreCollection
 * @extends {storage.Store}
 */
export interface DeviceStoreCollection extends storage.Store {
	device: DeviceCollection;
}

/**
 * Device collection properties
 *
 * @export
 * @interface DeviceCollection
 * @extends {Collection}
 */
export interface DeviceCollection extends storage.Collection {
	byIds: DeviceEntitiesObject;
	mutation: DeviceEntitiesObject;
	cache: DeviceEntitiesObject;
	state: DeviceCollectionState;
}

/**
 * Device collection state
 *
 * @export
 * @interface DeviceCollectionState
 * @extends {storage.CollectionState}
 */
export interface DeviceCollectionState extends storage.CollectionState {}

// custom device collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const deviceCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const deviceCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const deviceCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const deviceCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: deviceCollectionIdStringProps,
	idArrayProps: deviceCollectionIdArrayProps,
	idEntityProps: deviceCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Device collection default values, initialize collection with device entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const deviceCollectionDefault: DeviceCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as DeviceCollection),
		...{
			activeId: deviceConfig.activeId || deviceConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	deviceConfig.entities
);

/**
 * Device store collection default values
 *
 * @export
 * @constant
 */
export const deviceStoreCollectionDefault: DeviceStoreCollection = {
	device: deviceCollectionDefault
};

/**
 * Device store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getDeviceCollection = (state: any) => state.lead.device;
