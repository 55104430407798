import { ContextHelper } from '..';
import { ContextEntity } from '../collections';

export interface ContextHook {
	contextHelper: ContextHelper;
	activeContext: ContextEntity | undefined;
}

export const useContext = (): ContextHook => {
	let contextHelper = new ContextHelper();
	return {
		contextHelper,
		activeContext: contextHelper.active()
	};
};
