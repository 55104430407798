import { IonButton, IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useState } from 'react';

export interface FileUploaderProps {
	onSubmit: (file: any) => void;
	accept?: string;
	submitButtonText?: string;
	noFileMessage?: string;
}

export const FileUploader: React.FC<FileUploaderProps> = props => {
	const [selectedFile, setSelectedFile] = useState<any>();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event: any) => {
		event.preventDefault();
		setSelectedFile(event.target.files);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		if (props.onSubmit !== undefined && selectedFile) {
			props.onSubmit(selectedFile);
		}
	};

	return (
		<IonCard>
			<IonGrid>
				<IonRow>
					<IonCol>
						<input
							type="file"
							name="file"
							accept={props.accept}
							onChange={e => changeHandler(e)}
						/>
					</IonCol>
					<IonCol>
						{isFilePicked ? (
							<div>
								<p>Filename: {selectedFile[0]?.name}</p>
								<p>Filetype: {selectedFile[0]?.type}</p>
								<p>Size in bytes: {selectedFile[0]?.size}</p>
							</div>
						) : (
							<p>{props.noFileMessage || ''}</p>
						)}
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol></IonCol>
					<IonCol>
						<IonButton hidden={!isFilePicked} onClick={handleSubmission}>
							{props.submitButtonText ?? 'Submit'}
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonCard>
	);
};
