import { DataEntities } from '../../lead/store/collections/Data';

export interface DataConfig {
	entities: DataEntities;
	activeId?: string;
}

export const dataConfig: DataConfig = {
	entities: []
};
