import { Localize, translations } from '../../locales';
import { ServiceListItemConfigProps } from '../../../lead/ui/components/Service/ServiceListItem';

export type ServiceListItemConfig = Localize<ServiceListItemConfigProps>;

export const serviceListItemConfig: ServiceListItemConfig = {
	en: {
		label: {
			information: 'Information',
			totalLeads: 'Total Leads',
			devicesUsed: 'Licenses Used',
			devicesSeparator: ' of '
		},
		buttonText: {
			viewService: 'View'
		}
	},
	fr: {
		label: {
			information: 'Informations',
			totalLeads: 'Total des pistes',
			devicesUsed: 'Appareils utilisés',
			devicesSeparator: '/'
		},
		buttonText: {
			viewService: 'Vue'
		}
	}
};
