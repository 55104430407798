import { ResponseEntities } from '../../lead/store/collections/Response';

export interface ResponseConfig {
	entities: ResponseEntities;
	activeId?: string;
}

export const responseConfig: ResponseConfig = {
	entities: []
};
