import {
	ServiceCollection,
	ServiceCollectionState,
	ServiceId,
	ServiceEntity_Some,
	ServiceEntityPatch_Some,
	ServiceId_Some,
	serviceCollectionDefault,
	serviceCollectionMutateIdOpts
} from '../collections/Service';
import * as actions from '../actions/Service';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Service reducer
 *
 * @export
 * @param {ServiceCollection} [state=serviceCollectionDefault]
 * @param {ServiceActionTypes} action
 * @returns
 */
export function serviceReducer(
	state: ServiceCollection = serviceCollectionDefault,
	action: actions.ServiceActionTypes
) {
	switch (action.type) {
		case actions.SERVICE_MUTATE_ID:
			return collectionReducerActions.mutateId<ServiceCollection>(
				state,
				action.payload,
				serviceCollectionMutateIdOpts
			);
		case actions.SERVICE_SET:
			return collectionReducerActions.set<ServiceCollection, ServiceId>(
				state,
				action.payload
			);
		case actions.SERVICE_TOGGLE:
			return collectionReducerActions.toggle<ServiceCollection, ServiceId>(
				state,
				action.payload
			);
		case actions.SERVICE_SELECT:
			return collectionReducerActions.select<ServiceCollection, ServiceId_Some>(
				state,
				action.payload
			);
		case actions.SERVICE_DESELECT:
			return collectionReducerActions.deselect<
				ServiceCollection,
				ServiceId_Some
			>(state, action.payload);
		case actions.SERVICE_UPSERT:
			return collectionReducerActions.upsert<
				ServiceCollection,
				ServiceEntity_Some
			>(state, action.payload);
		case actions.SERVICE_PATCH:
			return collectionReducerActions.patch<
				ServiceCollection,
				ServiceEntityPatch_Some
			>(state, action.payload);
		case actions.SERVICE_CHANGE:
			return collectionReducerActions.change<
				ServiceCollection,
				ServiceEntityPatch_Some
			>(state, action.payload);
		case actions.SERVICE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ServiceCollection,
				ServiceId_Some
			>(state, action.payload);
		case actions.SERVICE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ServiceCollection,
				ServiceId_Some
			>(state, action.payload);
		case actions.SERVICE_CACHE:
			return collectionReducerActions.cache<ServiceCollection, ServiceId_Some>(
				state,
				action.payload
			);
		case actions.SERVICE_UNCACHE:
			return collectionReducerActions.uncache<
				ServiceCollection,
				ServiceId_Some
			>(state, action.payload);
		case actions.SERVICE_SETSTATE:
			return collectionReducerActions.setState<
				ServiceCollection,
				ServiceCollectionState
			>(state, action.payload.state);
		case actions.SERVICE_PATCHSTATE:
			return collectionReducerActions.patchState<
				ServiceCollection,
				ServiceCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
