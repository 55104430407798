import React, { useEffect, useState, useRef, RefObject } from 'react';

//Data
import {
	OrdersCnxLeads,
	isObjectStatusActive,
	LicenseEntities,
	isEventContactOrganizationEntity,
	ServiceEntities,
	LicenseServiceType,
	ServiceEntity,
	ContactOrganizationEntity
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { ImportOrders } from '../../ui/containers/Service/ImportOrders';
import { ImportExhibitors } from '../../ui/containers/Service/ImportExhibitors';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonIcon,
	IonCard,
	IonCardContent,
	IonModal,
	IonContent,
	IonBadge,
	IonFooter,
	IonSelect,
	IonSelectOption,
	IonLoading
} from '@ionic/react';
import { SearchBar } from '../../../app/ui/components/Search/SearchBar';
import AdminSignoutListView from './AdminSignoutListView';
import { SendBatchEmails } from '../containers/Service/SendBatchEmails';
import * as icons from 'ionicons/icons';

import { AdminServiceFilter, AdminServiceList } from '../containers/Service';

// Selector
import {
	SelectorItem,
	SelectorItems
} from '../../../app/ui/components/Selector';

// Sort Options
enum SortType {
	Ascending = 'ascending',
	Descending = 'descending'
}
export enum FilterConditionType {
	Any = 'any',
	Contains = 'contains',
	DoesNotContain = 'doesnotcontain'
}
interface Sort {
	value: any;
	direction: SortType;
}
export type ServiceFilterObject = {
	licenseTypes?: LicenseServiceType[];
	activeDevices?: FilterConditionType;
};

export type AdminServiceObject = {
	service: ServiceEntity;
	contact?: ContactOrganizationEntity;
	licenses?: LicenseEntities;
};

/*********************************************************
 * AdminServicesView
 *********************************************************/
const AdminServicesView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			serviceHelper,
			activeContext,
			contactHelper,
			licenseHelper,
			orderHelper,
			eventContactHelper,
			leadHelper
		}
	} = ctx;

	/*********************************************************
	 * State Objects
	 *********************************************************/
	//import models
	const [showNewOrdersModal, setShowNewOrdersModal] = useState(false);
	const [showExhibitorsModal, setShowExhibitorsModal] = useState(false);
	const [cnxOrders, setCnxOrders] = useState<OrdersCnxLeads>([]);
	//filter and sort
	const [keyword, setKeyword] = useState<string | undefined>();
	const [filter, setFilter] = useState<ServiceFilterObject | undefined>({
		licenseTypes: [],
		activeDevices: FilterConditionType.Any
	});
	const [showFilterModal, setShowFilterModal] = useState(false);
	//sorting
	const [sortServicesBy, setSortServicesBy] = useState<Sort>({
		value: 'Company',
		direction: SortType.Ascending
	});
	const [selectedServices, setSelectedServices] = useState<string[]>([]);
	//Pagination
	const [paginationLimit, setPaginationLimit] = useState(15);
	const [paginationCurrent, setPaginationCurrent] = useState(1);
	const [paginationTotal, setPaginationTotal] = useState(2);
	//emails
	const [showSendEmailsModal, setShowSendEmailsModal] = useState(false);
	const [batchmailToSend, setBatchmailToSend] = useState<string>('');
	//signoutsheet
	const [renderSignoutSheet, setRenderSignoutSheet] = useState(false);
	const signoutListViewRef = useRef<HTMLDivElement>(null);
	//exporting flag
	const [isExporting, setIsExporting] = useState(false);
	//Loading
	const [loadingPopup, setLoadingPopup] = useState<{
		display: boolean;
		message: string;
	}>();

	/*********************************************************
	 * Static Objects
	 *********************************************************/
	const sortItems: SelectorItems = [
		{
			icon: icons.businessOutline,
			text: 'Company',
			value: 'Company'
		}
	];
	const allServices: ServiceEntities = serviceHelper.allByContextIds([
		activeContext?.id || ''
	]);

	const filteredServices: AdminServiceObject[] = allServices
		?.map((service: ServiceEntity) => {
			return {
				service,
				contact: contactHelper.get_Organization(
					service.contactIds?.length ? service.contactIds[0] : ''
				),
				licenses: licenseHelper.allByContexts(
					activeContext ? [activeContext] : [],
					[service]
				)
			};
		})
		.sort((a: AdminServiceObject, b: AdminServiceObject) =>
			(
				sortServicesBy.value === 'Company'
					? (a.service?.name ?? '').toLocaleLowerCase() >
					  (b.service?.name ?? '').toLocaleLowerCase()
					: true
			)
				? sortServicesBy.direction === SortType.Ascending
					? 1
					: -1
				: sortServicesBy.direction === SortType.Ascending
				? -1
				: 1
		)
		.filter(({ service, contact, licenses }) => {
			let keywordRegex = new RegExp(keyword || '', 'i');

			//Check filter
			if (filter) {
				//License based Service Type Filter
				if (
					(filter?.licenseTypes?.length ?? 0) > 0 &&
					licenses?.filter(license => {
						//Service Type Filter
						if (
							license.serviceType &&
							!filter?.licenseTypes?.includes(license.serviceType)
						) {
							return false;
						}

						return true;
					}).length <= 0
				) {
					return false;
				}
				//License based activeDevices Filter
				if (
					filter?.activeDevices &&
					filter?.activeDevices != FilterConditionType.Any
				) {
					//Active Device Filter for lead app licenses
					let hasLicensesWithUsage =
						licenses?.filter(license => {
							return (license.deviceIds?.length ?? 0) > 0;
						}).length > 0;

					if (
						(!hasLicensesWithUsage &&
							filter.activeDevices === FilterConditionType.Contains) ||
						(hasLicensesWithUsage &&
							filter.activeDevices === FilterConditionType.DoesNotContain)
					) {
						return false;
					}
				}
			}

			// Return true is blank
			if (keyword === '') return true;

			// Get values
			let name = contact?.contactFirstName + ' ' + contact?.contactLastName,
				organization = service.name;

			// Check against regex
			if (
				name.match(keywordRegex) ||
				organization?.match(keywordRegex) ||
				contact?.email?.match(keywordRegex) ||
				contact?.city?.match(keywordRegex) ||
				contact?.postal?.match(keywordRegex) ||
				contact?.country?.match(keywordRegex)
			) {
				return true;
			}

			return false;
		});

	const eventExhibitors = eventContactHelper
		.all()
		.filter(isObjectStatusActive)
		.filter(ec => activeContext?.eventIds.includes(ec.eventId))
		.filter(isEventContactOrganizationEntity)
		.filter(ec => allServices?.findIndex(s => s.id === ec.serviceId) || 0 < 0)
		.filter(
			ec =>
				(allServices?.findIndex(
					s => s.name?.toLowerCase().trim() === ec.name?.toLowerCase().trim()
				) || 0) < 0
		);
	const activeOrders = orderHelper
		.all()
		.filter(isObjectStatusActive)
		.filter(o => o.contextId === activeContext?.id);

	const nonActiveEventOrders =
		cnxOrders?.filter(
			o => activeOrders.findIndex(ao => ao.code === o.code) < 0
		) ?? 0;

	/*********************************************************
	 * Action Methods
	 *********************************************************/
	const onSetKeywordChange = (value: string | undefined) => {
		if (value) {
			setKeyword(value.trim());
		} else {
			setKeyword('');
		}
	};
	const onSelectService = (serviceId: string) => {
		serviceHelper.set(serviceId);
	};
	const serviceCheckboxClicked = (val: string, checked: boolean) => {
		if (checked && !selectedServices.includes(val)) {
			let copySelectedServices = [...selectedServices];
			copySelectedServices.push(val);
			setSelectedServices(copySelectedServices);
		} else if (selectedServices.includes(val)) {
			let i = selectedServices.indexOf(val);
			if (i >= 0) {
				let copySelectedServices = [...selectedServices];
				copySelectedServices.splice(i, 1);
				setSelectedServices(copySelectedServices);
			}
		}
	};
	const onSelectAllClicked = (serviceIds: string[]) => {
		setSelectedServices(serviceIds);
	};
	// Sort function
	const onSortServicesByChange = (item: SelectorItem) => {
		setSortServicesBy({
			...sortServicesBy,
			value: item.value || item.text
		});
	};
	const toggleSortDirection = () => {
		sortServicesBy.direction === SortType.Ascending
			? setSortServicesBy({
					...sortServicesBy,
					direction: SortType.Descending
			  })
			: setSortServicesBy({
					...sortServicesBy,
					direction: SortType.Ascending
			  });
	};
	const handlePrint = (componentRef: RefObject<HTMLDivElement>) => {
		setRenderSignoutSheet(true);
		setLoadingPopup({ display: true, message: 'Generating...' });
		setTimeout(function () {
			var divContents = componentRef.current?.innerHTML;
			var a = window.open('', '', 'height=800, width=800');
			if (divContents && a) {
				a.document.write('<html>');
				a.document.write('<body>');
				a.document.write(divContents);
				a.document.write('</body></html>');
				a.document.close();
				a.print();
			}
			setLoadingPopup({ display: false, message: '' });
			setRenderSignoutSheet(false);
		}, 6000);
	};

	const exportServices = async () => {
		if (activeContext) {
			setLoadingPopup({ display: true, message: 'Exporting Companies...' });
			let services = serviceHelper.allByContextIds([activeContext.id]);
			// All data is now loaded
			for (let i = 0; (services?.length ?? 0) > i; i++) {
				let item = services![i];
				if (item && item) {
					await leadHelper.readAllByServiceNoContacts(ctx, {
						serviceId: item.id
					});
				}
			}

			setIsExporting(true);
		}
	};

	/*********************************************************
	 * useEffect
	 *********************************************************/
	// Pagination functions
	useEffect(() => {
		if (filteredServices && filteredServices.length > paginationLimit) {
			setPaginationTotal(Math.ceil(filteredServices.length / paginationLimit));
		} else {
			setPaginationCurrent(1);
			setPaginationTotal(1);
		}
	}, [filteredServices]);
	//set defaults
	useEffect(() => {
		setFilter({
			licenseTypes: [],
			activeDevices: FilterConditionType.Any
		});
		setSelectedServices([]);
		setPaginationLimit(15);
		setPaginationCurrent(1);
		setPaginationTotal(2);
		setShowNewOrdersModal(false);
		setShowExhibitorsModal(false);
		setRenderSignoutSheet(false);
		setLoadingPopup({ display: false, message: '' });

		if (activeContext) {
			let eventId = activeContext?.eventIds[0] || '';
			orderHelper.readAllNonActive(
				ctx,
				{
					userId: activeUser?.id,
					eventId: eventId
				},
				(orders: OrdersCnxLeads) => {
					if (orders && Array.isArray(orders)) {
						setCnxOrders(orders);
					} else {
						setCnxOrders([]);
					}
				}
			);

			eventContactHelper.readEventContactsOrganizations(ctx, {
				eventIds: activeContext.eventIds
			});
		}
	}, [activeContext]);

	// Export functions
	useEffect(() => {
		if (isExporting && activeContext) {
			serviceHelper.exportData(
				ctx,
				activeContext,
				serviceHelper.allByContextIds([activeContext.id])
			);
			setLoadingPopup({ display: false, message: '' });
			setIsExporting(false);
		}
	}, [isExporting]);

	return (
		<>
			{renderSignoutSheet ? (
				<div
					hidden={true}
					key="SignoutListTemplateDiv"
					ref={signoutListViewRef}
				>
					<AdminSignoutListView servicesToPrint={selectedServices} />
				</div>
			) : (
				<></>
			)}
			<IonLoading
				isOpen={loadingPopup?.display ?? false}
				onDidDismiss={() => setLoadingPopup({ display: false, message: '' })}
				message={loadingPopup?.message ?? 'Loading...'}
				duration={150000}
			/>
			<AdminServiceFilter
				showFilterModal={showFilterModal}
				filter={filter}
				onFilterChanged={(f: ServiceFilterObject) => {
					setFilter(f);
				}}
				onShowFilterModalChanged={(s: boolean) => {
					setShowFilterModal(s);
				}}
			></AdminServiceFilter>
			<IonModal
				isOpen={showSendEmailsModal}
				backdropDismiss={false}
				onDidDismiss={() => {
					setShowSendEmailsModal(false);
				}}
			>
				<IonToolbar>
					<IonTitle>Sending Emails</IonTitle>
					<IonButtons slot="end">
						<IonButton
							fill="clear"
							onClick={() => {
								setBatchmailToSend('');
								setSelectedServices([]);
								setShowSendEmailsModal(false);
							}}
						>
							<IonIcon src={icons.closeCircle}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<SendBatchEmails
					servicesIds={selectedServices}
					emailType={batchmailToSend}
				/>
			</IonModal>
			<IonModal
				isOpen={showNewOrdersModal}
				backdropDismiss={false}
				onDidDismiss={() => {
					setShowNewOrdersModal(false);
				}}
			>
				<IonToolbar>
					<IonToolbar>
						<IonTitle>New Orders</IonTitle>
						<IonButtons slot="end">
							<IonButton
								fill="clear"
								onClick={() => setShowNewOrdersModal(false)}
							>
								<IonIcon src={icons.closeCircle}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonToolbar>
				<IonContent>
					<ImportOrders cnxOrders={cnxOrders} />
				</IonContent>
			</IonModal>

			<IonModal
				isOpen={showExhibitorsModal}
				backdropDismiss={false}
				onDidDismiss={() => {
					setShowExhibitorsModal(false);
				}}
			>
				<IonToolbar>
					<IonTitle>Import Exhibitors</IonTitle>
					<IonButtons slot="end">
						<IonButton
							fill="clear"
							onClick={() => {
								setShowExhibitorsModal(false);
							}}
						>
							<IonIcon src={icons.closeCircle}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<ImportExhibitors eventExhibitors={eventExhibitors}></ImportExhibitors>
			</IonModal>
			<IonToolbar>
				<IonGrid>
					<IonRow>
						<IonCol size="12" sizeMd="4">
							<IonButtons>
								<IonCard>
									<IonCardContent>
										<IonLabel>Imports</IonLabel>
										<IonButton
											onClick={() => {
												setShowExhibitorsModal(true);
											}}
											title="Import Exhibitors"
										>
											<IonIcon icon={icons.businessOutline} />
											<IonBadge>{eventExhibitors.length}</IonBadge>
										</IonButton>
										<IonButton
											onClick={() => {
												setShowNewOrdersModal(true);
											}}
											title="Import Orders"
										>
											<IonIcon icon={icons.fileTrayFullOutline} />
											<IonBadge>{nonActiveEventOrders.length}</IonBadge>
										</IonButton>
									</IonCardContent>
								</IonCard>
							</IonButtons>
						</IonCol>
						<IonCol size="12" sizeMd="8">
							<IonButtons class="ion-float-right">
								<IonLabel>Page Size</IonLabel>
								<IonSelect
									interface="popover"
									name="pageSizeSelect"
									multiple={false}
									value={paginationLimit}
									placeholder="Select One"
									onIonChange={e => {
										setPaginationLimit(e.detail.value);
									}}
								>
									<IonSelectOption value={15}>15</IonSelectOption>
									<IonSelectOption value={25}>25</IonSelectOption>
									<IonSelectOption value={50}>50</IonSelectOption>
									<IonSelectOption value={100}>100</IonSelectOption>
								</IonSelect>
								<IonButton
									onClick={() => setPaginationCurrent(paginationCurrent - 1)}
									disabled={paginationCurrent === 1}
								>
									<IonIcon slot="icon-only" icon={icons.arrowBack} />
								</IonButton>
								<IonLabel>
									{paginationCurrent} of {paginationTotal}
								</IonLabel>
								<IonButton
									onClick={() => setPaginationCurrent(paginationCurrent + 1)}
									disabled={paginationCurrent === paginationTotal}
								>
									<IonIcon slot="icon-only" icon={icons.arrowForward} />
								</IonButton>

								<IonButton
									color={
										(filter?.licenseTypes?.length ?? 0) <= 0 &&
										(!filter?.activeDevices ||
											filter?.activeDevices == FilterConditionType.Any)
											? ''
											: 'primary'
									}
									onClick={() => {
										setShowFilterModal(true);
									}}
								>
									<IonIcon slot="icon-only" icon={icons.filterOutline} />
								</IonButton>
								<IonButton onClick={exportServices} title="Export Companies">
									<IonIcon slot="icon-only" icon={icons.downloadOutline} />
								</IonButton>
								<IonButton
									onClick={() => {
										handlePrint(signoutListViewRef);
									}}
									title="Print Signout List"
								>
									<IonIcon slot="icon-only" icon={icons.printOutline} />
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonToolbar>
			<IonToolbar>
				<IonGrid>
					<IonRow>
						<IonCol size="12">
							<SearchBar onChange={onSetKeywordChange} />
						</IonCol>
					</IonRow>
					<IonRow
						hidden={
							(filter?.licenseTypes?.length ?? 0) <= 0 &&
							(!filter?.activeDevices ||
								filter?.activeDevices == FilterConditionType.Any)
						}
					>
						<IonCol
							style={{
								backgroundColor: '#3880ff',
								color: '#fff'
							}}
							size="12"
						>
							<IonButton
								color="light"
								onClick={(e: any) => {
									e.persist();
									setFilter({
										licenseTypes: [],
										activeDevices: FilterConditionType.Any
									});
								}}
							>
								Clear
							</IonButton>
							<IonLabel
								style={{
									margin: '10px'
								}}
							>
								<b>Filter Applied:</b>
								<IonLabel
									style={{
										paddingLeft: '10px'
									}}
									hidden={(filter?.licenseTypes?.length ?? 0) <= 0}
								>
									License Types (
									{filter?.licenseTypes?.map((l, i) => {
										return (i > 0 ? ', ' : '') + l;
									})}
									)
								</IonLabel>

								<IonLabel
									style={{
										paddingLeft: '10px'
									}}
									hidden={filter?.activeDevices == FilterConditionType.Any}
								>
									{filter?.activeDevices == FilterConditionType.Contains
										? 'Has Device Activity'
										: 'No Device Activity'}
								</IonLabel>
							</IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonToolbar>
			<IonContent>
				<AdminServiceList
					allServiceObjectCount={allServices?.length}
					serviceObjects={filteredServices}
					selectedIds={selectedServices}
					currentPage={paginationCurrent}
					pageLimit={paginationLimit}
					onItemSelected={onSelectService}
					onCheckBoxClicked={serviceCheckboxClicked}
					onSelectAllClicked={onSelectAllClicked}
				></AdminServiceList>
			</IonContent>
			<IonFooter hidden={selectedServices.length <= 0}>
				<IonToolbar color="tertiary" style={{ padding: '10px' }}>
					<IonLabel>Companies Selected: {selectedServices.length}</IonLabel>
					<br />
					<IonButton
						color="light"
						onClick={() => {
							setBatchmailToSend('loginemail');
							setShowSendEmailsModal(true);
						}}
					>
						<IonIcon slot="start" src={icons.mailOpenOutline} />
						Send Login Email
					</IonButton>
					<IonButton
						color="light"
						onClick={() => {
							setBatchmailToSend('postshow');
							setShowSendEmailsModal(true);
						}}
					>
						<IonIcon slot="start" src={icons.mailOpenOutline} />
						Send Post Event Email
					</IonButton>
					<IonButton hidden={true} color="light">
						<IonIcon slot="start" src={icons.printOutline} />
						Print Signout Sheets
					</IonButton>
					<IonButton
						onClick={() => {
							handlePrint(signoutListViewRef);
						}}
						title="Print Signout List"
					>
						Print Signout List
						<IonIcon slot="icon-only" icon={icons.printOutline} />
					</IonButton>
				</IonToolbar>
			</IonFooter>
		</>
	);
};

export default AdminServicesView;
