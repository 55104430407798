import { OrderEntities } from '../../lead/store/collections/Order';

export interface OrderConfig {
	entities: OrderEntities;
	activeId?: string;
}

export const orderConfig: OrderConfig = {
	entities: []
};
