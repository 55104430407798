import React from 'react';
import { config, Config } from '../config';

export interface AppSettings {
	auth: {
		multiUser: boolean;
	};
}

export const defaultAppSettings: AppSettings = {
	auth: {
		multiUser: true
	}
};

export interface AppContext {
	config: Config;
	settings: AppSettings;
	appError?: AppError;
	error: (error: any) => void;
	errorClear: () => void;
}

export interface AppError {
	message: string;
}

export const AppContext = React.createContext<AppContext | null>(null);

const AppProvider: React.FC<React.ReactNode> = ({ children }) => {
	const [settings, setSettings] = React.useState<AppSettings>(
		defaultAppSettings
	);
	const [appError, setAppError] = React.useState<AppError | undefined>(
		undefined
	);

	const error = (error: any) => {
		setAppError({
			message:
				typeof error === 'string'
					? error
					: error?.message || 'Oops! Unknown Error'
		});
	};

	const errorClear = () => setAppError(undefined);

	return (
		<AppContext.Provider
			value={{ config, settings, appError, error, errorClear }}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;
