import React from 'react';

import appViews from '../app/ui/views';
import leadViews from '../lead/ui/views';

const views: { [app: string]: { [view: string]: React.FC } } = {
	app: appViews,
	lead: leadViews
};

export default views;
