import {
	QualifierCollection,
	QualifierCollectionState,
	QualifierId,
	QualifierEntity_Some,
	QualifierEntityPatch_Some,
	QualifierId_Some,
	qualifierCollectionDefault,
	qualifierCollectionMutateIdOpts
} from '../collections/Qualifier';
import * as actions from '../actions/Qualifier';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Qualifier reducer
 *
 * @export
 * @param {QualifierCollection} [state=qualifierCollectionDefault]
 * @param {QualifierActionTypes} action
 * @returns
 */
export function qualifierReducer(
	state: QualifierCollection = qualifierCollectionDefault,
	action: actions.QualifierActionTypes
) {
	switch (action.type) {
		case actions.QUALIFIER_MUTATE_ID:
			return collectionReducerActions.mutateId<QualifierCollection>(
				state,
				action.payload,
				qualifierCollectionMutateIdOpts
			);
		case actions.QUALIFIER_SET:
			return collectionReducerActions.set<QualifierCollection, QualifierId>(
				state,
				action.payload
			);
		case actions.QUALIFIER_TOGGLE:
			return collectionReducerActions.toggle<QualifierCollection, QualifierId>(
				state,
				action.payload
			);
		case actions.QUALIFIER_SELECT:
			return collectionReducerActions.select<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_DESELECT:
			return collectionReducerActions.deselect<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_UPSERT:
			return collectionReducerActions.upsert<
				QualifierCollection,
				QualifierEntity_Some
			>(state, action.payload);
		case actions.QUALIFIER_PATCH:
			return collectionReducerActions.patch<
				QualifierCollection,
				QualifierEntityPatch_Some
			>(state, action.payload);
		case actions.QUALIFIER_CHANGE:
			return collectionReducerActions.change<
				QualifierCollection,
				QualifierEntityPatch_Some
			>(state, action.payload);
		case actions.QUALIFIER_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_CACHE:
			return collectionReducerActions.cache<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_UNCACHE:
			return collectionReducerActions.uncache<
				QualifierCollection,
				QualifierId_Some
			>(state, action.payload);
		case actions.QUALIFIER_SETSTATE:
			return collectionReducerActions.setState<
				QualifierCollection,
				QualifierCollectionState
			>(state, action.payload.state);
		case actions.QUALIFIER_PATCHSTATE:
			return collectionReducerActions.patchState<
				QualifierCollection,
				QualifierCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
