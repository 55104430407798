import {
	NoteId_Some,
	NoteEntity_Some,
	NoteEntityPatch_Some,
	NoteCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const NOTE_MUTATE_ID = '@lead/NOTE_MUTATE_ID';
export const NOTE_SET = '@lead/NOTE_SET';
export const NOTE_TOGGLE = '@lead/NOTE_TOGGLE';
export const NOTE_SELECT = '@lead/NOTE_SELECT';
export const NOTE_DESELECT = '@lead/NOTE_DESELECT';
export const NOTE_UPSERT = '@lead/NOTE_UPSERT';
export const NOTE_PATCH = '@lead/NOTE_PATCH';
export const NOTE_CHANGE = '@lead/NOTE_CHANGE';
export const NOTE_APPLY_CHANGES = '@lead/NOTE_APPLY_CHANGES';
export const NOTE_CANCEL_CHANGES = '@lead/NOTE_CANCEL_CHANGES';
export const NOTE_CACHE = '@lead/NOTE_CACHE';
export const NOTE_UNCACHE = '@lead/NOTE_UNCACHE';
export const NOTE_SETSTATE = '@lead/NOTE_SETSTATE';
export const NOTE_PATCHSTATE = '@lead/NOTE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface NoteAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface NoteAction_MutateId extends EntityAction_MutateId {
	type: typeof NOTE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active note
 *
 * @interface NoteAction_Set
 * @extends {EntityAction_Set}
 */
export interface NoteAction_Set extends EntityAction_Set {
	type: typeof NOTE_SET;
	payload?: EntityId;
}

/**
 * Toggle active note
 *
 * @interface NoteAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface NoteAction_Toggle extends EntityAction_Toggle {
	type: typeof NOTE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select note entities
 *
 * @interface NoteAction_Select
 * @extends {EntityAction_Select}
 */
interface NoteAction_Select extends EntityAction_Select {
	type: typeof NOTE_SELECT;
	payload: NoteId_Some;
}

/**
 * Deselect note entities
 *
 * @interface NoteAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface NoteAction_Deselect extends EntityAction_Deselect {
	type: typeof NOTE_DESELECT;
	payload: NoteId_Some;
}

/**
 * Upsert note entities
 *
 * @interface NoteAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface NoteAction_Upsert extends EntityAction_Upsert {
	type: typeof NOTE_UPSERT;
	payload: NoteEntity_Some;
}

/**
 * Patch note entities
 *
 * @interface NoteAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface NoteAction_Patch extends EntityAction_Patch {
	type: typeof NOTE_PATCH;
	payload: NoteEntityPatch_Some;
}

/**
 * Change note entities
 *
 * @interface NoteAction_Change
 * @extends {EntityAction_Change}
 */
interface NoteAction_Change extends EntityAction_Change {
	type: typeof NOTE_CHANGE;
	payload: NoteEntityPatch_Some;
}

/**
 * Apply changes to note entities
 *
 * @interface NoteAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface NoteAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof NOTE_APPLY_CHANGES;
	payload: NoteId_Some;
}

/**
 * Cancel changes to note entities
 *
 * @interface NoteAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface NoteAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof NOTE_CANCEL_CHANGES;
	payload: NoteId_Some;
}

/**
 * Cache note entities
 *
 * @interface NoteAction_Cache
 * @extends {EntityAction_Cache}
 */
interface NoteAction_Cache extends EntityAction_Cache {
	type: typeof NOTE_CACHE;
	payload: NoteId_Some;
}

/**
 * Uncache note entities
 *
 * @interface NoteAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface NoteAction_Uncache extends EntityAction_Uncache {
	type: typeof NOTE_UNCACHE;
	payload: NoteId_Some;
}

/**
 * Set note collection / entity state
 *
 * @interface NoteAction_SetState
 * @extends {EntityAction_SetState}
 */
interface NoteAction_SetState extends EntityAction_SetState {
	type: typeof NOTE_SETSTATE;
}

/**
 * PAtch note collection / entity state
 *
 * @interface NoteAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface NoteAction_PatchState extends EntityAction_PatchState {
	type: typeof NOTE_PATCHSTATE;
}

/**
 * Export note action types
 *
 * @export
 */
export type NoteActionTypes =
	| NoteAction_MutateId
	| NoteAction_MutateId
	| NoteAction_Set
	| NoteAction_Toggle
	| NoteAction_Select
	| NoteAction_Deselect
	| NoteAction_Upsert
	| NoteAction_Patch
	| NoteAction_Change
	| NoteAction_ApplyChanges
	| NoteAction_CancelChanges
	| NoteAction_Cache
	| NoteAction_Uncache
	| NoteAction_SetState
	| NoteAction_PatchState;

/**
 * Note actions helper interface
 *
 * @export
 * @interface INoteActions
 * @extends {EntityActions}
 */
export interface INoteActions extends EntityActions {
	//customAction(ids: NoteId_Some): NoteAction_CustomAction;
}

/**
 * Note actions helper
 *
 * @export
 * @class NoteActions
 * @implements {EntityBaseActions}
 */
export class NoteActions implements INoteActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): NoteAction_MutateId {
		return { type: NOTE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): NoteAction_Set {
		return { type: NOTE_SET, payload: id };
	}
	toggle(id?: EntityId): NoteAction_Toggle {
		return { type: NOTE_TOGGLE, payload: id };
	}
	select(ids: NoteId_Some): NoteAction_Select {
		return {
			type: NOTE_SELECT,
			payload: ids
		};
	}
	deselect(ids: NoteId_Some): NoteAction_Deselect {
		return {
			type: NOTE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: NoteEntity_Some): NoteAction_Upsert {
		return {
			type: NOTE_UPSERT,
			payload: entities
		};
	}
	patch(entities: NoteEntityPatch_Some): NoteAction_Patch {
		return {
			type: NOTE_PATCH,
			payload: entities
		};
	}
	change(entities: NoteEntityPatch_Some): NoteAction_Change {
		return {
			type: NOTE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: NoteId_Some): NoteAction_ApplyChanges {
		return {
			type: NOTE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: NoteId_Some): NoteAction_CancelChanges {
		return {
			type: NOTE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: NoteId_Some): NoteAction_Cache {
		return {
			type: NOTE_CACHE,
			payload: ids
		};
	}
	uncache(ids: NoteId_Some): NoteAction_Uncache {
		return {
			type: NOTE_UNCACHE,
			payload: ids
		};
	}
	setState(state: NoteCollectionState): NoteAction_SetState {
		return {
			type: NOTE_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<NoteCollectionState>): NoteAction_PatchState {
		return {
			type: NOTE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the note actions helper
 *
 * @export
 */
export const noteActions = new NoteActions();
