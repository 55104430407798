import React from 'react';
import { IonList } from '@ionic/react';

import { EventListItem } from '../../components/Event';
import {
	EventEntities,
	ServiceEntity,
	ContextEntities,
	ContextEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';

export interface EventListCardProps {
	contexts: ContextEntities;
	service?: ServiceEntity;
}

export const EventList: React.FC<EventListCardProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: { leadHelper, licenseHelper, contextHelper, eventHelper }
	} = ctx;

	const contextListItems = props.contexts
		.sort((a: ContextEntity, b: ContextEntity) => {
			return moment(a.i_.created?.dt).isBefore(b.i_.created?.dt)
				? 1
				: moment(b.i_.created?.dt).isBefore(a.i_.created?.dt)
				? -1
				: 0;
		})
		.map((context: ContextEntity) => {
			let totalLeads,
				totalAppLicenses,
				totalAPILicenses,
				totalSSTLicenses,
				totalOpticonLicenses;

			if (props.service) {
				totalLeads = leadHelper.allByServiceIdAndEventIds(
					props.service?.id || '',
					context.eventIds || []
				).length;
				let licenses = licenseHelper.allByContexts([context], [props.service]);
				totalAppLicenses = licenses
					.filter(license => license.serviceType === 'lead-app')
					.reduce((sum, current) => sum + current.quantity, 0);
				totalAPILicenses = licenses
					.filter(license => license.serviceType === 'lead-api')
					.reduce((sum, current) => sum + current.quantity, 0);
				totalSSTLicenses = licenses
					.filter(license => license.serviceType === 'sst')
					.reduce((sum, current) => sum + current.quantity, 0);
				totalOpticonLicenses = licenses
					.filter(license => license.serviceType === 'opticon')
					.reduce((sum, current) => sum + current.quantity, 0);
			}

			let events: EventEntities = eventHelper.gets(context?.eventIds || []);
			return (
				<EventListItem
					key={'EventListItem_' + context.id}
					events={events}
					context={context}
					totalLeads={totalLeads}
					totalAppLicenses={totalAppLicenses}
					totalAPILicenses={totalAPILicenses}
					totalSSTLicenses={totalSSTLicenses}
					totalOpticonLicenses={totalOpticonLicenses}
					onContextSelected={selectedContextId => {
						contextHelper.set(selectedContextId);
						eventHelper.set(events[0].id);
					}}
				/>
			);
		});

	return <IonList>{contextListItems}</IonList>;
};
