import React, { useState } from 'react';
import _ from 'lodash';
import {
	IonMenu,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonMenuToggle,
	IonItemDivider,
	IonItem,
	IonLabel,
	IonIcon,
	IonText,
	IonRouterLink
} from '@ionic/react';

import { Component } from './';

import {
	MenuEntity,
	MenuEntities,
	MenuDisplayType,
	MenuDisplaySideType,
	iconKeys,
	Icon,
	componentKeys,
	useMenu,
	useRoute,
	useUser
} from '../../store';

// Configs
import { localize } from '../../../config/locales';
import { useCtx } from '../../../config/hooks';
import { config } from '../../../config';

import '../styles/menu.css';
export interface MenuConfigProps {
	disabled?: boolean;
	title?: string;
	type?: MenuDisplayType;
	side?: MenuDisplaySideType;
	maxEdgeStart?: number;
	swipeGesture?: boolean;
	autoHide?: boolean;
	security?: string[];
	tags?: string[];
	alwaysTags?: string[];
	allowedTags?: string[];
	ignoreTags?: string[];
	icons?: Pick<Icon, 'slot' | 'color' | 'size'>;
	className?: string;
	collapse?: boolean;
}

export interface MenuProps {
	contentId: string;
	instanceId: string;
	defaultTags?: string[];
	id?: string;
	customization?: any;
}

export const Menu: React.FC<MenuProps> = props => {
	// Get menu context & routes for active user
	const { menuHelper } = useMenu();
	const { locale, activeUser } = useUser();
	const { activeRoute } = useRoute();

	// get them current menu instance state for the instanceId. Default to disabled if there is no state
	let instance: MenuConfigProps = menuHelper.state().instances
		? (menuHelper.state().instances?.[props.instanceId] as MenuConfigProps) || {
				disabled: true
		  }
		: {
				disabled: true
		  };

	// default tags if the menu instance doesn't have any
	if (props.defaultTags && !instance.tags) instance.tags = props.defaultTags;

	const collapsible = instance.collapse && activeUser?.settings?.menu?.collapse;

	const [collapsed, setCollapsed] = useState(true);

	const onEngaged = () => {
		if (collapsible) {
			if (collapsed) setCollapsed(false);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};
	const onDisengaged = () => {
		if (collapsible) {
			if (!collapsed) setCollapsed(true);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};

	const toggleEngaged = () => {
		if (collapsible) {
			setCollapsed(!collapsed);
		} else if (collapsed) {
			setCollapsed(true);
		}
	};

	let items: MenuEntities;

	// return menu items which match the current menu instance state and the security state
	items = menuHelper.filter((item: MenuEntity) => {
		// if there are security scopes on the item, ensure the user has at least one of them
		if (item.security) {
			let authorized;
			if (activeUser?.security)
				item.security.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) authorized = true;
				});
			if (!authorized) return false;
		}
		if (item.tags) {
			// if there are ignore tags, ensure the item doesn't have one of them
			if (instance.ignoreTags) {
				let ignore;
				instance.ignoreTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) ignore = true;
				});
				if (ignore) return false;
			}
			// if there are allowed tags, ensure the item has at least one of them
			if (instance.allowedTags) {
				let allow;
				instance.allowedTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) allow = true;
				});
				if (!allow) return false;
			}
			// if there are always tags, ensure the item displays if it has at least one of them
			if (instance.alwaysTags) {
				let always;
				instance.alwaysTags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) always = true;
				});
				if (always) return true;
			}
			// if there are menu instance tags, ensure the item has at least one of them
			if (instance.tags) {
				let match;
				instance.tags.forEach(tag => {
					if (item.tags && item.tags?.indexOf(tag) > -1) match = true;
				});
				if (match) return true;
			}
			return false;
		}
		return true;
	});

	// Map out menu items
	const menuItems = items.map((item: MenuEntity) => {
		return item.divider ? (
			<IonItemDivider
				key={item.id}
				{..._.pick(item.divider, ['slot', 'sticky', 'color'])}
			>
				{item.divider.label && (
					<IonLabel>{localize(locale, item.divider.label)}</IonLabel>
				)}
			</IonItemDivider>
		) : (
			<IonItem
				color={activeRoute?.id === item.url ? 'light' : undefined}
				detail={false}
				key={item.id}
				{...(activeRoute?.id !== item.url
					? {
							routerLink: item.url,
							routerDirection: 'none'
					  }
					: {})}
			>
				{item.icon && (
					<IonIcon
						{..._.pick(
							{
								...item.icon,
								slot: item.icon.slot || instance.icons?.slot || 'start',
								color: item.icon.color || instance.icons?.color,
								size: item.icon.size || instance.icons?.size || 'large'
							},
							iconKeys
						)}
					/>
				)}
				{item.title && (
					<IonLabel className="menu--label">
						{localize(locale, item.title)}
					</IonLabel>
				)}
				{item.component &&
					(item.component.id != 'test' ? (
						<IonText>{item.component.id}</IonText>
					) : (
						<Component
							id={item.component.id}
							{..._.pick(item.component, componentKeys)}
						/>
					))}
			</IonItem>
		);
	});

	return (
		<IonMenu
			contentId={props.contentId}
			menuId={props.instanceId}
			id={props.id}
			side={instance.side || 'start'}
			type={instance.type}
			maxEdgeStart={instance.maxEdgeStart}
			swipeGesture={instance.swipeGesture}
			disabled={instance.disabled || menuItems.length === 0 ? true : false}
			className={[
				'menu',
				'menu-' + props.instanceId,
				...(collapsible && collapsed
					? ['menu--collapsed', 'menu-' + props.instanceId + '--collapsed']
					: []),
				...(instance.className ? [instance.className] : [])
			].join(' ')}
			onMouseOver={onEngaged}
			onMouseOut={onDisengaged}
			onMouseLeave={onDisengaged}
		>
			<IonHeader onTouchStart={toggleEngaged}>
				<IonToolbar className="menu-toolbar">
					<IonRouterLink routerLink="/main">
						<IonTitle className="menu-toolbar-title">
							<img
								className="menu-toolbar-title-logo-img"
								src={'assets/img/logo/MyLeadsportal.png'}
							/>
						</IonTitle>
					</IonRouterLink>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList className="menu-content-list">
					<IonMenuToggle autoHide={instance.autoHide}>
						{menuItems}
					</IonMenuToggle>
				</IonList>
			</IonContent>

			<div className="menu-footer">
				<img
					className="menu-footer-img"
					onClick={() => {
						window.open('http://conexsys.com', '_blank');
					}}
					src={'assets/img/logo/poweredbyConexsys_logo_color.png'}
				/>
				<p>VR {config.version}</p>
			</div>
		</IonMenu>
	);
};
