import React from 'react';
import { IonAvatar } from '@ionic/react';
import ReactAvatar from 'react-avatar';
import { UserEntity } from '../../store';

import { avatarConfig } from '../../../config/app/components/Avatar';

import { useLocaleConfig } from '../../store';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';

export interface AvatarConfigProps {
	src: string;
	round: boolean;
	maxInitials: number;
	textSizeRatio: number;
	textMarginRatio: number;
}

export interface AvatarProps {
	slot?: string;
	src?: string;
	name?: string;
	color?: string;
	round?: boolean;
	size?: string;
	style?: { [key: string]: any };
	onClick?(e: any): any;
}

export const Avatar: React.FC<AvatarProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<AvatarConfigProps>(avatarConfig);

	return (
		<IonAvatar
			style={props.style}
			slot={props.slot || ''}
			onClick={props.onClick ? props.onClick : (e: any) => {}}
		>
			{props.src ? (
				<ReactAvatar
					src={props.src}
					size={props.size || '100%'}
					round={props.round || config.round}
				/>
			) : props.name ? (
				<ReactAvatar
					name={props.name}
					maxInitials={config.maxInitials}
					textSizeRatio={config.textSizeRatio}
					textMarginRatio={config.textMarginRatio}
					size={props.size || '100%'}
					round={props.round || config.round}
				/>
			) : (
				<ReactAvatar
					src={config.src}
					size={props.size || '100%'}
					round={props.round || config.round}
				/>
			)}
		</IonAvatar>
	);
};

export interface UserAvatarProps {
	user?: UserEntity;
	slot?: string;
	size?: string;
	onClick?(e: any): any;
}

export const UserAvatar: React.FC<UserAvatarProps> = props => {
	return (
		<Avatar
			slot={props.slot}
			src={props.user?.picture}
			name={props.user?.name}
			size={props.size}
			onClick={props.onClick}
		/>
	);
};
