import React from 'react';
import { IonLabel, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';

import { ObjectAny } from '../../../store';
import { toTitleCase } from '../../../../app/utils';

export interface EntityListItemProps {
	entity: ObjectAny;
	edit: (entity: ObjectAny) => void;
	showEntityType?: boolean;
}

export const EntityListItem: React.FC<EntityListItemProps> = props => {
	let entity = props.entity as any;

	return (
		<IonItem
			key={props.entity.id}
			onClick={() => props.edit(props.entity)}
			button
		>
			<IonGrid>
				<IonRow>
					<IonCol size="2" sizeMd="2">
						<IonLabel>
							<h2>{entity.code}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="5" sizeMd="5">
						<IonLabel>
							<h2>{entity.name || entity.code || props.entity.id}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="1" sizeMd="1">
						<IonLabel>
							<h2>
								{props.showEntityType
									? toTitleCase(props.entity.i_.type.split('.')[0])
									: ''}
							</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="4" sizeMd="4">
						<h2>{props.entity.i_.created?.dt}</h2>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
