import React from 'react';

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonCardSubtitle,
	IonContent
} from '@ionic/react';

const OrdersView: React.FC = () => {
	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol size="8" sizeMd="8">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Order Details</IonCardTitle>
								<IonCardSubtitle>Services purchased</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="4" sizeMd="4">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Overview</IonCardTitle>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="8" sizeMd="8">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Invoices</IonCardTitle>
								<IonCardSubtitle>List of invoices</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default OrdersView;
