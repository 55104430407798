import React, { useState } from 'react';
import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonSelect,
	IonSelectOption
} from '@ionic/react';

import {
	User,
	LeadEntities,
	ContactEntity,
	EventEntity,
	ServiceEntity
} from '../../../store';

import { useCtx } from '../../../../config/hooks';
import { Chart } from '../../../../app/ui/components/Chart';

export interface LocationConfigProps {
	user?: User;
	service?: ServiceEntity;
	event?: EventEntity;
	title?: string;
	finish?: any;
}

export const LocationReport: React.FC<LocationConfigProps> = props => {
	const [currentReport, setCurrentReport] = useState('By Country');
	const [active, setActive] = useState(true);
	const ctx = useCtx<LocationConfigProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	let allLeads: LeadEntities = ctx.lead.lead.allByServiceIdAndEventIds(
		props.service?.id || '',
		[props.event?.id || '']
	);

	let countryList: string[] = [];
	allLeads.forEach(lead => {
		let contact: ContactEntity | undefined = ctx.lead.contact.get(
			lead.contactId
		);
		if (contact?.country && !countryList.includes(contact?.country)) {
			countryList.push(contact?.country);
		}
	});
	const reportIonSelectOptions = countryList.map(
		(name: string, index: number, leadList: string[]) => {
			return <IonSelectOption value={name}>{name}</IonSelectOption>;
		}
	);

	const onReportChanged = (value: string) => {
		setCurrentReport(value);
	};

	let locationData: { id: string; value: number }[] = [];
	let displayReport = () => {
		if (currentReport == 'By Country') {
			countryList.forEach(country => {
				let countryCount =
					allLeads.filter(lead => {
						let contact: ContactEntity | undefined = ctx.lead.contact.get(
							lead.contactId
						);
						return contact?.country == country;
					}).length | 0;

				locationData.push({ id: country, value: countryCount });
			});
		} else {
			allLeads.forEach(lead => {
				let contact: ContactEntity | undefined = ctx.lead.contact.get(
					lead.contactId
				);
				if (contact?.country !== currentReport) return;

				let i = locationData.findIndex(val => val.id == contact?.subdivision);
				if (i < 0 && contact?.subdivision) {
					locationData.push({ id: contact?.subdivision, value: 1 });
				} else {
					locationData[i].value++;
				}
			});
		}
	};

	displayReport();

	return (
		<IonCard>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol sizeMd="4" size="8">
							<IonSelect
								interface="popover"
								name="activeReportSelect"
								multiple={false}
								cancelText="Cancel"
								okText="Okay"
								value={currentReport}
								placeholder="Select One"
								onIonChange={e => onReportChanged(e.detail.value)}
							>
								<IonSelectOption value="By Country">By Country</IonSelectOption>
								{reportIonSelectOptions}
							</IonSelect>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeMd="12" size="12">
							<Chart
								data={locationData}
								selectedChartType="bar"
								height="300px"
								chartsAvailable={['bar', 'pie']}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
