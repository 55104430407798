import React, { useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonTitle,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonList,
	IonItem,
	IonSegment,
	IonSegmentButton,
	IonBadge
} from '@ionic/react';

import { DataEntity, DataType, DataResultStatusType } from '../../../store';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';

export interface DataDetailsConfigProps {
	data?: DataEntity;
}

export const DataDetails: React.FC<DataDetailsConfigProps> = props => {
	const [currentScanDetailsModalTab, setCurrentScanDetailsModalTab] = useState(
		'ScanResults'
	);
	const {
		config: [config],
		lead: { dataHelper, serviceHelper }
	} = useCtx<DataDetailsConfigProps>({});

	useEffect(() => {}, [props.data]);

	return (
		<IonCard>
			<IonCardContent>
				<IonCardHeader>
					<IonSegment
						value={currentScanDetailsModalTab}
						onIonChange={e =>
							setCurrentScanDetailsModalTab(e.detail.value || 'Leads')
						}
					>
						<IonSegmentButton value="ScanResults">
							Scan Results
						</IonSegmentButton>
						<IonSegmentButton value="FileData">File Data</IonSegmentButton>
					</IonSegment>
				</IonCardHeader>
				<IonGrid hidden={currentScanDetailsModalTab !== 'FileData'}>
					<IonRow>
						<IonCol>{props.data?.value}</IonCol>
					</IonRow>
				</IonGrid>
				<IonGrid hidden={currentScanDetailsModalTab !== 'ScanResults'}>
					{dataHelper
						.allBySourceDataId(props.data?.id || '', DataType.Upload)
						.map(d => {
							return (
								<>
									<IonRow>
										<IonCol>
											<IonTitle>
												{serviceHelper.get(d.serviceId || '')?.name}
											</IonTitle>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonList>
												{d.results?.map(r => {
													return (
														<IonItem>
															<IonCol size="6">
																<IonLabel>{r.refId}</IonLabel>
															</IonCol>
															<IonCol size="2">
																<IonBadge
																	color={
																		r.status === DataResultStatusType.Error
																			? 'danger'
																			: 'success'
																	}
																>
																	{r.status}
																</IonBadge>
															</IonCol>
															<IonCol size="4">
																<IonLabel>
																	{moment
																		.utc(r.dt)
																		.local()
																		.format('YYYY-MM-DD h:mm A')}{' '}
																</IonLabel>
															</IonCol>
														</IonItem>
													);
												})}
											</IonList>
										</IonCol>
									</IonRow>
								</>
							);
						})}
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
