// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Qualifier selection type when qualifier values exist
 * @export
 * @enum {string}
 */
export enum QualifierSelection {
	Single = 'single',
	Multiple = 'multiple'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesQualifierSelection = ['single', 'multiple'];
