// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Property formats for `number` values.
 * @export
 * @enum {string}
 */
export enum ValueFormatNumber {
	Number = 'number',
	Currency = 'currency',
	Percent = 'percent',
	GroupThousands = 'group-thousands',
	Bytes = 'bytes'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesValueFormatNumber = [
	'number',
	'currency',
	'percent',
	'group-thousands',
	'bytes'
];
