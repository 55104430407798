import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { eventContactConfig } from '../../../config/lead/EventContact';
import {
	EventContactIndividual,
	EventContactOrganization,
	ContactType
} from '../models';

/**
 * EventContactIndividual entity interface
 *
 * @export
 * @interface EventContactIndividualEntity
 * @extends {storage.Entity}
 */
export interface EventContactIndividualEntity
	extends storage.Entity,
		EventContactIndividual {
	eventId: string;
}

export const isEventContactIndividualEntity = (
	object: EventContactEntity
): object is EventContactIndividualEntity => {
	return (
		(object as EventContactIndividualEntity).type === ContactType.Individual
	);
};

/**
 * EventContactOrganization entity interface
 *
 * @export
 * @interface EventContactOrganizationEntity
 * @extends {storage.Entity}
 */
export interface EventContactOrganizationEntity
	extends storage.Entity,
		EventContactOrganization {
	eventId: string;
}

export const isEventContactOrganizationEntity = (
	object: EventContactEntity
): object is EventContactOrganizationEntity => {
	return (
		(object as EventContactOrganizationEntity).type === ContactType.Organization
	);
};

export const isEventContactOrganizationSponsorEntity = (
	object: EventContactEntity
): object is EventContactOrganizationEntity => {
	return (
		(object as EventContactOrganizationEntity).type ===
			ContactType.Organization &&
		(object.properties as any)?.myconexsys?.SponsorLevelId
	);
};

/**
 * EventContact entity type
 *
 * @export
 */
export type EventContactEntity =
	| EventContactIndividualEntity
	| EventContactOrganizationEntity;

/**
 * EventContact entities array
 *
 * @export
 */
export type EventContactEntities = EventContactEntity[];

/**
 * Some eventcontact entities: one entity or an array of entities
 *
 * @export
 */
export type EventContactEntity_Some = EventContactEntity | EventContactEntities;

/**
 * EventContact entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface EventContactEntityPatch
 * @extends {storage.EntityPatch<EventContactEntity>}
 */
export type EventContactEntityPatch = storage.EntityPatcher<EventContactEntity>;

/**
 * EventContact entity patches array
 *
 * @export
 */
export type EventContactEntitiesPatch = EventContactEntityPatch[];

/**
 * Some eventcontact entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type EventContactEntityPatch_Some =
	| EventContactEntityPatch
	| EventContactEntitiesPatch;

/**
 * EventContact entity id
 *
 * @export
 */
export type EventContactId = storage.EntityId;

/**
 * EventContact entity ids
 *
 * @export
 */
export type EventContactIds = storage.EntityIds;

/**
 * Some eventcontact entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type EventContactId_Some = EventContactId | EventContactIds;

/**
 * EventContact entities object by id
 *
 * @export
 * @interface EventContactEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface EventContactEntitiesObject extends storage.EntitiesObject {
	[id: string]: EventContactEntity;
}

/**
 * EventContact entity state
 *
 * @export
 * @interface EventContactEntityState
 * @extends {storage.EntityState}
 */
export interface EventContactEntityState extends storage.EntityState {}

/**
 * EventContact store collection
 *
 * @export
 * @interface EventContactStoreCollection
 * @extends {storage.Store}
 */
export interface EventContactStoreCollection extends storage.Store {
	eventContact: EventContactCollection;
}

/**
 * EventContact collection properties
 *
 * @export
 * @interface EventContactCollection
 * @extends {Collection}
 */
export interface EventContactCollection extends storage.Collection {
	byIds: EventContactEntitiesObject;
	mutation: EventContactEntitiesObject;
	cache: EventContactEntitiesObject;
	state: EventContactCollectionState;
}

/**
 * EventContact collection state
 *
 * @export
 * @interface EventContactCollectionState
 * @extends {storage.CollectionState}
 */
export interface EventContactCollectionState extends storage.CollectionState {}

// custom eventcontact collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const eventContactCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const eventContactCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const eventContactCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const eventContactCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: eventContactCollectionIdStringProps,
	idArrayProps: eventContactCollectionIdArrayProps,
	idEntityProps: eventContactCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * EventContact collection default values, initialize collection with eventcontact entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const eventContactCollectionDefault: EventContactCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as EventContactCollection),
		...{
			activeId:
				eventContactConfig.activeId || eventContactConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	eventContactConfig.entities
);

/**
 * EventContact store collection default values
 *
 * @export
 * @constant
 */
export const eventContactStoreCollectionDefault: EventContactStoreCollection = {
	eventContact: eventContactCollectionDefault
};

/**
 * EventContact store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getEventContactCollection = (state: any) =>
	state.lead.eventContact;
