import React, { useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonSegment,
	IonSegmentButton,
	IonTextarea,
	IonButton,
	IonLoading,
	IonIcon
} from '@ionic/react';

import {
	DataEntity,
	DataEntities,
	DataType,
	ObjectType,
	DataResultStatusType,
	LeadEntities,
	LeadEntity,
	DataResult,
	QualifierSelection
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';
import _ from 'lodash';
import { newEntity } from '../../../../app/utils';
import { FileUploader } from '../../../ui/containers/Utils/FileUploader';
import { attachOutline, documentTextOutline } from 'ionicons/icons';

export interface DataDownloaderConfigProps {
	downloadCompleted: () => void;
}

export const DataDownloader: React.FC<DataDownloaderConfigProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			leadHelper,
			dataHelper,
			contextHelper,
			qualifierValueHelper,
			qualifierHelper,
			responseHelper
		}
	} = ctx;
	const [currentTab, setCurrentTab] = useState('FileData');
	const [newDownload, setNewDownload] = useState<DataEntity>({
		...newEntity(ctx, ObjectType.Data),
		type: DataType.Download
	});
	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);

	//useEffect(() => {}, [props.data]);

	const newFileUploaded = (file: any) => {
		setNewDownload({
			...newEntity(ctx, ObjectType.Data),
			type: DataType.Download
		});
		//let fr = new FileReader();
		//console.log(fr.readAsText(event.target.files[0]));
		let reader = new FileReader();
		reader.onload = async e => {
			let text: string = e?.target?.result?.toString().trim() || '';
			if (text && text != '') {
				newDownload.value = text;
				startDownload();
			}
		};
		if (file && file.length > 0) reader.readAsText(file[0]);

		//let fr = new FileReader();
		//console.log(fr.readAsText(file));
	};

	const setScannerDownloadData = (val: string) => {
		if (!newDownload) {
			setNewDownload({
				...newEntity(ctx, ObjectType.Data),
				type: DataType.Download
			});
		}
		newDownload.value = val;
	};

	const downloadCompleted = () => {
		setNewDownload({
			...newEntity(ctx, ObjectType.Data),
			type: DataType.Download
		});
		setCurrentTab('FileData');
		if (props.downloadCompleted !== undefined) {
			props.downloadCompleted();
		}
	};

	const startDownload = async () => {
		if ((newDownload.value || '') !== '') {
			setWorkingStatus('Downloading Scanner...');
			let dataArray: DataEntities = dataHelper.parseOpticonDataFile(
				ctx,
				newDownload
			);

			await dataHelper.write(ctx, { datas: [newDownload] });
			for (let i = 0; i < dataArray.length; i++) {
				let dataScans: DataEntity = dataArray[i];
				if (dataScans) {
					let serviceId: string = dataScans.serviceId || '';
					let contextId: string = dataScans.contextId || '';
					let eventIds: string[] = contextHelper.get(contextId)?.eventIds || [];
					if (
						serviceId !== '' &&
						contextId !== '' &&
						eventIds !== [] &&
						dataScans.results
					) {
						//Get All Qualifiers and Values for Current Service
						await qualifierHelper.read(ctx, { serviceId: serviceId });
						await qualifierValueHelper.read(ctx, { serviceId: serviceId });
						await responseHelper.read(ctx, { serviceId: serviceId });

						let scanCodes: { code: string; date: string }[] = [];
						let currentLead: LeadEntity | undefined = undefined;
						for (let x = 0; x < dataScans.results.length; x++) {
							let result: DataResult = dataScans.results[x];
							if (result) {
								if (
									result.type === ObjectType.ContactRegistrationType &&
									result.refId
								) {
									scanCodes.push({ code: result.refId, date: result.dt || '' });
								}
								if (
									scanCodes.length === 5 ||
									x === dataScans.results.length - 1
								) {
									let leads: LeadEntities = await leadHelper.upsertLeadsByCode(
										ctx,
										{
											serviceId: serviceId,
											eventIds: eventIds,
											scans: scanCodes
										}
									);

									scanCodes = [];
									//Verify Results and Save qualifiers
									for (let y = 0; y < dataScans.results.length; y++) {
										let scan: DataResult = dataScans.results[y];
										if (
											scan.type === ObjectType.ContactRegistrationType &&
											scan.status !== DataResultStatusType.Success
										) {
											let lInx: number = leads.findIndex(
												l => l.scanCode === scan.refId
											);

											if (lInx >= 0) {
												//scan success
												scan.status = DataResultStatusType.Success;
												//Set Current Lead
												currentLead = leads[lInx];
											} else {
												//scan failed
												scan.status = DataResultStatusType.Error;
												currentLead = undefined;
											}
										} else if (
											scan.type === ObjectType.QualifierValue &&
											scan.status !== DataResultStatusType.Success &&
											currentLead !== undefined
										) {
											let qv =
												scan.refId && scan.refId != ''
													? qualifierValueHelper.get(scan.refId)
													: undefined;
											let q = qv
												? qualifierHelper.get(qv.qualifierId)
												: undefined;
											if (
												qv &&
												q &&
												q.selection == QualifierSelection.Multiple
											) {
												let leadResponse = responseHelper
													.allByQualifierValue(qv)
													.find(qualVal => qualVal.leadId === currentLead?.id);
												if (leadResponse) {
													await responseHelper.write(ctx, {
														serviceId: serviceId,
														responses: [
															_.set(
																_.cloneDeep(leadResponse),
																'qualifierValueId',
																qv.id
															)
														]
													});
												} else {
													await responseHelper.write(ctx, {
														serviceId: serviceId,
														responses: [
															{
																...newEntity(ctx, ObjectType.Response),
																serviceId: serviceId,
																leadId: currentLead.id,
																qualifierId: q.id,
																qualifierValueId: qv.id,
																value: 'true'
															}
														]
													});
												}
											}
											scan.status = DataResultStatusType.Success;
										} else {
											scan.status = DataResultStatusType.Success;
										}
									}
								}
							}
						}
						await dataHelper.write(ctx, { datas: [dataScans] });
					}
				}
			}
			setWorkingStatus(undefined);
			downloadCompleted();
		}
	};

	return (
		<>
			<IonCard>
				<IonCardContent>
					<IonCardHeader>
						<IonSegment
							value={currentTab}
							onIonChange={e => setCurrentTab(e.detail.value || 'Leads')}
						>
							<IonSegmentButton value="FileData">
								File Download <IonIcon src={attachOutline}></IonIcon>{' '}
							</IonSegmentButton>
							<IonSegmentButton value="TextData">
								Text Data <IonIcon src={documentTextOutline}></IonIcon>
							</IonSegmentButton>
						</IonSegment>
					</IonCardHeader>
					<IonGrid hidden={currentTab !== 'TextData'}>
						<IonRow>
							<IonGrid>
								<IonRow>
									<IonCol>
										<IonTextarea
											placeholder="Paste Text File Here"
											value={newDownload?.value || ''}
											onIonChange={e => setScannerDownloadData(e.detail.value!)}
											rows={10}
										></IonTextarea>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonButton onClick={startDownload}>Download</IonButton>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonRow>
					</IonGrid>
					<IonGrid hidden={currentTab !== 'FileData'}>
						Please select the file you would like to upload.
						<FileUploader
							onSubmit={newFileUploaded}
							accept=".txt"
							submitButtonText="Upload Scanner File"
							noFileMessage=""
						></FileUploader>
					</IonGrid>
				</IonCardContent>
			</IonCard>
			<IonLoading
				isOpen={workingStatus !== undefined}
				message={`<h2>${workingStatus}</h2>`}
			/>
		</>
	);
};
