// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Property formats for `boolean` values.
 * @export
 * @enum {string}
 */
export enum ValueFormatBoolean {
	Boolean = 'boolean',
	YesNo = 'yes-no',
	OnOff = 'on-off'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesValueFormatBoolean = ['boolean', 'yes-no', 'on-off'];
