import React from 'react';
import {
	IonCheckbox,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonList,
	IonRow
} from '@ionic/react';

import { AdminServiceListItem } from '../../components/Service';
import { AdminServiceObject } from '../../views/AdminServicesView';
import { int } from '@zxing/library/esm/customTypings';

export interface AdminServiceListProps {
	allServiceObjectCount?: int;
	serviceObjects: AdminServiceObject[];
	selectedIds: string[];
	currentPage?: int;
	pageLimit?: int;
	onItemSelected?: (selectedId: string) => void;
	onCheckBoxClicked?: (val: string, checked: boolean) => void;
	onSelectAllClicked?: (selectedIds: string[]) => void;
}

export const AdminServiceList: React.FC<AdminServiceListProps> = props => {
	//set default page size to max
	const currentPage = props.currentPage ?? 1;
	const pageLimit = props.pageLimit ?? props.serviceObjects.length;
	const startIndex = (currentPage - 1) * pageLimit;
	const serviceListItems = props.serviceObjects
		?.slice(startIndex, startIndex + pageLimit)
		.map((adminServiceObject: AdminServiceObject) => {
			return (
				<AdminServiceListItem
					isSelectable={true}
					serviceObject={adminServiceObject}
					key={adminServiceObject.service.id}
					onItemClicked={props.onItemSelected}
					onCheckBoxClicked={props.onCheckBoxClicked}
					checked={props.selectedIds.includes(adminServiceObject.service.id)}
				/>
			);
		});

	return (
		<IonList>
			<IonItem>
				<IonGrid style={{ padding: '0px' }}>
					<IonRow style={{ padding: '0px' }}>
						<IonCol size="9" style={{ padding: '0px' }}>
							<IonItem lines="none" style={{ padding: '0px' }}>
								<IonCheckbox
									onClick={e => {
										let checked = e.currentTarget.checked;
										let selected: string[] = [];
										if (checked && props.serviceObjects) {
											selected = props.serviceObjects
												?.slice(startIndex, startIndex + pageLimit)
												.map(e => e.service.id);
										}
										if (props.onSelectAllClicked) {
											props.onSelectAllClicked(selected);
										}
									}}
								></IonCheckbox>
								<IonLabel style={{ paddingLeft: '5px' }}>Select All</IonLabel>
							</IonItem>
						</IonCol>
						<IonCol size="3" class="ion-text-end">
							Showing{' '}
							{(props.serviceObjects?.length <= 0
								? 0
								: props.serviceObjects?.slice(
										startIndex,
										startIndex + pageLimit
								  ).length) +
								' of ' +
								(props.allServiceObjectCount ?? props.serviceObjects?.length)}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
			{serviceListItems}
		</IonList>
	);
};
