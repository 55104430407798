import {
	DeviceId_Some,
	DeviceEntity_Some,
	DeviceEntityPatch_Some,
	DeviceCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const DEVICE_MUTATE_ID = '@lead/DEVICE_MUTATE_ID';
export const DEVICE_SET = '@lead/DEVICE_SET';
export const DEVICE_TOGGLE = '@lead/DEVICE_TOGGLE';
export const DEVICE_SELECT = '@lead/DEVICE_SELECT';
export const DEVICE_DESELECT = '@lead/DEVICE_DESELECT';
export const DEVICE_UPSERT = '@lead/DEVICE_UPSERT';
export const DEVICE_PATCH = '@lead/DEVICE_PATCH';
export const DEVICE_CHANGE = '@lead/DEVICE_CHANGE';
export const DEVICE_APPLY_CHANGES = '@lead/DEVICE_APPLY_CHANGES';
export const DEVICE_CANCEL_CHANGES = '@lead/DEVICE_CANCEL_CHANGES';
export const DEVICE_CACHE = '@lead/DEVICE_CACHE';
export const DEVICE_UNCACHE = '@lead/DEVICE_UNCACHE';
export const DEVICE_SETSTATE = '@lead/DEVICE_SETSTATE';
export const DEVICE_PATCHSTATE = '@lead/DEVICE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface DeviceAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface DeviceAction_MutateId extends EntityAction_MutateId {
	type: typeof DEVICE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active device
 *
 * @interface DeviceAction_Set
 * @extends {EntityAction_Set}
 */
export interface DeviceAction_Set extends EntityAction_Set {
	type: typeof DEVICE_SET;
	payload?: EntityId;
}

/**
 * Toggle active device
 *
 * @interface DeviceAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface DeviceAction_Toggle extends EntityAction_Toggle {
	type: typeof DEVICE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select device entities
 *
 * @interface DeviceAction_Select
 * @extends {EntityAction_Select}
 */
interface DeviceAction_Select extends EntityAction_Select {
	type: typeof DEVICE_SELECT;
	payload: DeviceId_Some;
}

/**
 * Deselect device entities
 *
 * @interface DeviceAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface DeviceAction_Deselect extends EntityAction_Deselect {
	type: typeof DEVICE_DESELECT;
	payload: DeviceId_Some;
}

/**
 * Upsert device entities
 *
 * @interface DeviceAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface DeviceAction_Upsert extends EntityAction_Upsert {
	type: typeof DEVICE_UPSERT;
	payload: DeviceEntity_Some;
}

/**
 * Patch device entities
 *
 * @interface DeviceAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface DeviceAction_Patch extends EntityAction_Patch {
	type: typeof DEVICE_PATCH;
	payload: DeviceEntityPatch_Some;
}

/**
 * Change device entities
 *
 * @interface DeviceAction_Change
 * @extends {EntityAction_Change}
 */
interface DeviceAction_Change extends EntityAction_Change {
	type: typeof DEVICE_CHANGE;
	payload: DeviceEntityPatch_Some;
}

/**
 * Apply changes to device entities
 *
 * @interface DeviceAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface DeviceAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof DEVICE_APPLY_CHANGES;
	payload: DeviceId_Some;
}

/**
 * Cancel changes to device entities
 *
 * @interface DeviceAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface DeviceAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof DEVICE_CANCEL_CHANGES;
	payload: DeviceId_Some;
}

/**
 * Cache device entities
 *
 * @interface DeviceAction_Cache
 * @extends {EntityAction_Cache}
 */
interface DeviceAction_Cache extends EntityAction_Cache {
	type: typeof DEVICE_CACHE;
	payload: DeviceId_Some;
}

/**
 * Uncache device entities
 *
 * @interface DeviceAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface DeviceAction_Uncache extends EntityAction_Uncache {
	type: typeof DEVICE_UNCACHE;
	payload: DeviceId_Some;
}

/**
 * Set device collection / entity state
 *
 * @interface DeviceAction_SetState
 * @extends {EntityAction_SetState}
 */
interface DeviceAction_SetState extends EntityAction_SetState {
	type: typeof DEVICE_SETSTATE;
}

/**
 * PAtch device collection / entity state
 *
 * @interface DeviceAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface DeviceAction_PatchState extends EntityAction_PatchState {
	type: typeof DEVICE_PATCHSTATE;
}

/**
 * Export device action types
 *
 * @export
 */
export type DeviceActionTypes =
	| DeviceAction_MutateId
	| DeviceAction_MutateId
	| DeviceAction_Set
	| DeviceAction_Toggle
	| DeviceAction_Select
	| DeviceAction_Deselect
	| DeviceAction_Upsert
	| DeviceAction_Patch
	| DeviceAction_Change
	| DeviceAction_ApplyChanges
	| DeviceAction_CancelChanges
	| DeviceAction_Cache
	| DeviceAction_Uncache
	| DeviceAction_SetState
	| DeviceAction_PatchState;

/**
 * Device actions helper interface
 *
 * @export
 * @interface IDeviceActions
 * @extends {EntityActions}
 */
export interface IDeviceActions extends EntityActions {
	//customAction(ids: DeviceId_Some): DeviceAction_CustomAction;
}

/**
 * Device actions helper
 *
 * @export
 * @class DeviceActions
 * @implements {EntityBaseActions}
 */
export class DeviceActions implements IDeviceActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): DeviceAction_MutateId {
		return { type: DEVICE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): DeviceAction_Set {
		return { type: DEVICE_SET, payload: id };
	}
	toggle(id?: EntityId): DeviceAction_Toggle {
		return { type: DEVICE_TOGGLE, payload: id };
	}
	select(ids: DeviceId_Some): DeviceAction_Select {
		return {
			type: DEVICE_SELECT,
			payload: ids
		};
	}
	deselect(ids: DeviceId_Some): DeviceAction_Deselect {
		return {
			type: DEVICE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: DeviceEntity_Some): DeviceAction_Upsert {
		return {
			type: DEVICE_UPSERT,
			payload: entities
		};
	}
	patch(entities: DeviceEntityPatch_Some): DeviceAction_Patch {
		return {
			type: DEVICE_PATCH,
			payload: entities
		};
	}
	change(entities: DeviceEntityPatch_Some): DeviceAction_Change {
		return {
			type: DEVICE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: DeviceId_Some): DeviceAction_ApplyChanges {
		return {
			type: DEVICE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: DeviceId_Some): DeviceAction_CancelChanges {
		return {
			type: DEVICE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: DeviceId_Some): DeviceAction_Cache {
		return {
			type: DEVICE_CACHE,
			payload: ids
		};
	}
	uncache(ids: DeviceId_Some): DeviceAction_Uncache {
		return {
			type: DEVICE_UNCACHE,
			payload: ids
		};
	}
	setState(state: DeviceCollectionState): DeviceAction_SetState {
		return {
			type: DEVICE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<DeviceCollectionState>
	): DeviceAction_PatchState {
		return {
			type: DEVICE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the device actions helper
 *
 * @export
 */
export const deviceActions = new DeviceActions();
