import { EntityId, EntityIds, EntitiesObject } from './Entity';

/**
 * Collection interface
 * A collection of entities usually of the same object type
 * at a key within a store or store context
 *
 * @export
 * @interface Collection
 */
export interface Collection {
	byIds: EntitiesObject;
	allIds: EntityIds;
	selectedIds: EntityIds;
	activeId?: EntityId;
	mutation: EntitiesObject;
	cache: EntitiesObject;
	state: CollectionState;
}

/**
 * Collection state key/values and specific entity states byIds
 *
 * @export
 * @interface CollectionState
 * @extends {Object}
 */
export interface CollectionState extends Object {
	[key: string]: any;
}

// default collection id string, array and entity property names
export const collectionIdStringProps: string[] = ['activeId'];
export const collectionIdArrayProps: string[] = ['allIds', 'selectedIds'];
export const collectionIdEntityProps: string[] = ['byIds', 'mutation', 'cache'];
