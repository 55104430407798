import React, { useState } from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonButton,
	IonFab,
	IonFabButton,
	IonIcon,
	IonModal
} from '@ionic/react';

import { LeadEntity, NoteEntities, NoteEntity } from '../../../store';
import { AddEditLeadNote } from '../Lead';
import { add } from 'ionicons/icons';
import _ from 'lodash';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';

export interface LeadNoteListConfigProps {
	lead: LeadEntity;
}

export const LeadNoteList: React.FC<LeadNoteListConfigProps> = props => {
	const {
		config: [config],
		lead: { noteHelper }
	} = useCtx<LeadNoteListConfigProps>({});

	const [showLeadNoteModal, setShowLeadNoteModal] = useState(false);
	const [editLeadNote, setEditLeadNote] = useState<NoteEntity | undefined>(
		undefined
	);

	const notes: NoteEntities = noteHelper.allByLead(props.lead);

	const closeLeadNoteModal = () => {
		setEditLeadNote(undefined);
		setShowLeadNoteModal(false);
	};

	const addNote = () => {
		setEditLeadNote(undefined);
		setShowLeadNoteModal(true);
	};

	const editNote = (note: NoteEntity) => {
		setEditLeadNote(note);
		setShowLeadNoteModal(true);
	};

	const NoteList =
		notes.length > 0 ? (
			notes.map((note: NoteEntity) => {
				/*let user: UserEntity | undefined = ctx.lead.user.get(
					note.name
				);*/
				return (
					<IonItem>
						<IonLabel>
							<p>
								{moment(note.i_.modified.dt || note.i_.created.dt).format(
									'LLL'
								)}
								<br />
								{note.value}
							</p>
							<IonButton onClick={() => editNote(note)}>View</IonButton>
						</IonLabel>
					</IonItem>
				);
			})
		) : (
			<IonLabel>No notes for this lead record</IonLabel>
		);

	return (
		<>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Notes</IonCardTitle>
					<IonFab vertical="top" horizontal="end">
						<IonFabButton size="small" onClick={() => addNote()}>
							<IonIcon icon={add} />
						</IonFabButton>
					</IonFab>
				</IonCardHeader>
				<IonCardContent>{NoteList}</IonCardContent>
			</IonCard>
			<IonModal
				isOpen={showLeadNoteModal}
				backdropDismiss={false}
				onDidDismiss={() => closeLeadNoteModal()}
			>
				<AddEditLeadNote
					lead={props.lead}
					note={editLeadNote}
					finish={closeLeadNoteModal}
				/>
			</IonModal>
		</>
	);
};
