import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { eventQuestionConfig } from '../../../config/lead/EventQuestion';
import { EventQuestion } from '../models';

/**
 * EventQuestion entity interface
 *
 * @export
 * @interface EventQuestionEntity
 * @extends {storage.Entity}
 */
export interface EventQuestionEntity extends storage.Entity, EventQuestion {}

/**
 * EventQuestion entities array
 *
 * @export
 */
export type EventQuestionEntities = EventQuestionEntity[];

/**
 * Some eventquestion entities: one entity or an array of entities
 *
 * @export
 */
export type EventQuestionEntity_Some =
	| EventQuestionEntity
	| EventQuestionEntities;

/**
 * EventQuestion entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface EventQuestionEntityPatch
 * @extends {storage.EntityPatch<EventQuestionEntity>}
 */
export type EventQuestionEntityPatch = storage.EntityPatcher<
	EventQuestionEntity
>;

/**
 * EventQuestion entity patches array
 *
 * @export
 */
export type EventQuestionEntitiesPatch = EventQuestionEntityPatch[];

/**
 * Some eventquestion entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type EventQuestionEntityPatch_Some =
	| EventQuestionEntityPatch
	| EventQuestionEntitiesPatch;

/**
 * EventQuestion entity id
 *
 * @export
 */
export type EventQuestionId = storage.EntityId;

/**
 * EventQuestion entity ids
 *
 * @export
 */
export type EventQuestionIds = storage.EntityIds;

/**
 * Some eventquestion entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type EventQuestionId_Some = EventQuestionId | EventQuestionIds;

/**
 * EventQuestion entities object by id
 *
 * @export
 * @interface EventQuestionEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface EventQuestionEntitiesObject extends storage.EntitiesObject {
	[id: string]: EventQuestionEntity;
}

/**
 * EventQuestion entity state
 *
 * @export
 * @interface EventQuestionEntityState
 * @extends {storage.EntityState}
 */
export interface EventQuestionEntityState extends storage.EntityState {}

/**
 * EventQuestion store collection
 *
 * @export
 * @interface EventQuestionStoreCollection
 * @extends {storage.Store}
 */
export interface EventQuestionStoreCollection extends storage.Store {
	eventQuestion: EventQuestionCollection;
}

/**
 * EventQuestion collection properties
 *
 * @export
 * @interface EventQuestionCollection
 * @extends {Collection}
 */
export interface EventQuestionCollection extends storage.Collection {
	byIds: EventQuestionEntitiesObject;
	mutation: EventQuestionEntitiesObject;
	cache: EventQuestionEntitiesObject;
	state: EventQuestionCollectionState;
}

/**
 * EventQuestion collection state
 *
 * @export
 * @interface EventQuestionCollectionState
 * @extends {storage.CollectionState}
 */
export interface EventQuestionCollectionState extends storage.CollectionState {}

// custom eventquestion collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const eventQuestionCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const eventQuestionCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const eventQuestionCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const eventQuestionCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: eventQuestionCollectionIdStringProps,
	idArrayProps: eventQuestionCollectionIdArrayProps,
	idEntityProps: eventQuestionCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * EventQuestion collection default values, initialize collection with eventquestion entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const eventQuestionCollectionDefault: EventQuestionCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as EventQuestionCollection),
		...{
			activeId:
				eventQuestionConfig.activeId || eventQuestionConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	eventQuestionConfig.entities
);

/**
 * EventQuestion store collection default values
 *
 * @export
 * @constant
 */
export const eventQuestionStoreCollectionDefault: EventQuestionStoreCollection = {
	eventQuestion: eventQuestionCollectionDefault
};

/**
 * EventQuestion store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getEventQuestionCollection = (state: any) =>
	state.lead.eventQuestion;
