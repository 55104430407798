import React, { useEffect, useState } from 'react';

//Data
import { ServiceEntities, LeadEntities, ContextEntity } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonText,
	IonImg,
	IonList,
	IonItem,
	IonContent,
	IonLoading
} from '@ionic/react';

const ReverseLeadsView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		lead: {
			serviceHelper,
			contextHelper,
			contactHelper,
			eventHelper,
			leadHelper
		}
	} = ctx;

	/*********************************************************
	 * State Objects
	 *********************************************************/
	const [currentContactId, setCurrentContactId] = useState(
		viewProps.match?.params?.contactId ?? ''
	);
	const [currentContextId, setCurrentContextId] = useState(
		viewProps.match?.params?.contextId ?? ''
	);
	//Loading
	const [loadingPopup, setLoadingPopup] = useState<{
		display: boolean;
		message: string;
	}>();

	/*********************************************************
	 * Static Objects
	 *********************************************************/
	const leadScanns: LeadEntities = ctx.lead.lead
		.all()
		.filter(lead => lead.contactId === currentContactId);
	const leadServiceIds: string[] = leadScanns
		.map(lead => lead.serviceId)
		.filter((value, index, self) => self.indexOf(value) === index);
	console.log(leadScanns);
	const serviceList: ServiceEntities = serviceHelper.gets(leadServiceIds);

	const leadCompanyItems = serviceList.map((value, index, self) => {
		return <IonItem>{value.name}</IonItem>;
	});

	/*********************************************************
	 * useEffect
	 *********************************************************/
	useEffect(() => {
		console.log('currentContactId');
		console.log(currentContactId);
		console.log('currentContextId');
		console.log(currentContextId);
		if (currentContactId != '' && currentContextId != '') {
			contextHelper.read(
				ctx,
				{ ids: [currentContextId] },
				(ce: ContextEntity) => {
					console.log('ce');
					console.log(ce);
					contactHelper.readContactsById(
						ctx,
						{ ids: [currentContactId] },
						(c: any) => {
							console.log('c');
							console.log(c);

							//leadHelper.read(ctx, { cont });
						}
					);
				}
			);
		}
	}, []);

	return currentContactId === '' || currentContextId === '' ? (
		<IonContent>
			<IonText></IonText>
		</IonContent>
	) : (
		<IonContent>
			<IonLoading
				isOpen={loadingPopup?.display ?? false}
				onDidDismiss={() =>
					setLoadingPopup({
						display: false,
						message: ''
					})
				}
				message={loadingPopup?.message ?? 'Loading...'}
				duration={90000}
			/>
			<IonCard>
				<IonImg />
				<IonCardHeader>
					<IonCardTitle>Booths visited for</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonList>{leadCompanyItems}</IonList>
				</IonCardContent>
			</IonCard>
		</IonContent>
	);
};

export default ReverseLeadsView;
