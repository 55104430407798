export interface ThemesConfig {
	[id: string]: ThemeConfig;
}

export interface ThemeConfig {
	className: string;
}

export const themesConfig: ThemesConfig = {
	light: {
		className: ''
	},
	dark: {
		className: 'dark-theme'
	}
};
