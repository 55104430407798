import {
	OrderId_Some,
	OrderEntity_Some,
	OrderEntityPatch_Some,
	OrderCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const ORDER_MUTATE_ID = '@lead/ORDER_MUTATE_ID';
export const ORDER_SET = '@lead/ORDER_SET';
export const ORDER_TOGGLE = '@lead/ORDER_TOGGLE';
export const ORDER_SELECT = '@lead/ORDER_SELECT';
export const ORDER_DESELECT = '@lead/ORDER_DESELECT';
export const ORDER_UPSERT = '@lead/ORDER_UPSERT';
export const ORDER_PATCH = '@lead/ORDER_PATCH';
export const ORDER_CHANGE = '@lead/ORDER_CHANGE';
export const ORDER_APPLY_CHANGES = '@lead/ORDER_APPLY_CHANGES';
export const ORDER_CANCEL_CHANGES = '@lead/ORDER_CANCEL_CHANGES';
export const ORDER_CACHE = '@lead/ORDER_CACHE';
export const ORDER_UNCACHE = '@lead/ORDER_UNCACHE';
export const ORDER_SETSTATE = '@lead/ORDER_SETSTATE';
export const ORDER_PATCHSTATE = '@lead/ORDER_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface OrderAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface OrderAction_MutateId extends EntityAction_MutateId {
	type: typeof ORDER_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active order
 *
 * @interface OrderAction_Set
 * @extends {EntityAction_Set}
 */
export interface OrderAction_Set extends EntityAction_Set {
	type: typeof ORDER_SET;
	payload?: EntityId;
}

/**
 * Toggle active order
 *
 * @interface OrderAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface OrderAction_Toggle extends EntityAction_Toggle {
	type: typeof ORDER_TOGGLE;
	payload?: EntityId;
}

/**
 * Select order entities
 *
 * @interface OrderAction_Select
 * @extends {EntityAction_Select}
 */
interface OrderAction_Select extends EntityAction_Select {
	type: typeof ORDER_SELECT;
	payload: OrderId_Some;
}

/**
 * Deselect order entities
 *
 * @interface OrderAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface OrderAction_Deselect extends EntityAction_Deselect {
	type: typeof ORDER_DESELECT;
	payload: OrderId_Some;
}

/**
 * Upsert order entities
 *
 * @interface OrderAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface OrderAction_Upsert extends EntityAction_Upsert {
	type: typeof ORDER_UPSERT;
	payload: OrderEntity_Some;
}

/**
 * Patch order entities
 *
 * @interface OrderAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface OrderAction_Patch extends EntityAction_Patch {
	type: typeof ORDER_PATCH;
	payload: OrderEntityPatch_Some;
}

/**
 * Change order entities
 *
 * @interface OrderAction_Change
 * @extends {EntityAction_Change}
 */
interface OrderAction_Change extends EntityAction_Change {
	type: typeof ORDER_CHANGE;
	payload: OrderEntityPatch_Some;
}

/**
 * Apply changes to order entities
 *
 * @interface OrderAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface OrderAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof ORDER_APPLY_CHANGES;
	payload: OrderId_Some;
}

/**
 * Cancel changes to order entities
 *
 * @interface OrderAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface OrderAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof ORDER_CANCEL_CHANGES;
	payload: OrderId_Some;
}

/**
 * Cache order entities
 *
 * @interface OrderAction_Cache
 * @extends {EntityAction_Cache}
 */
interface OrderAction_Cache extends EntityAction_Cache {
	type: typeof ORDER_CACHE;
	payload: OrderId_Some;
}

/**
 * Uncache order entities
 *
 * @interface OrderAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface OrderAction_Uncache extends EntityAction_Uncache {
	type: typeof ORDER_UNCACHE;
	payload: OrderId_Some;
}

/**
 * Set order collection / entity state
 *
 * @interface OrderAction_SetState
 * @extends {EntityAction_SetState}
 */
interface OrderAction_SetState extends EntityAction_SetState {
	type: typeof ORDER_SETSTATE;
}

/**
 * PAtch order collection / entity state
 *
 * @interface OrderAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface OrderAction_PatchState extends EntityAction_PatchState {
	type: typeof ORDER_PATCHSTATE;
}

/**
 * Export order action types
 *
 * @export
 */
export type OrderActionTypes =
	| OrderAction_MutateId
	| OrderAction_MutateId
	| OrderAction_Set
	| OrderAction_Toggle
	| OrderAction_Select
	| OrderAction_Deselect
	| OrderAction_Upsert
	| OrderAction_Patch
	| OrderAction_Change
	| OrderAction_ApplyChanges
	| OrderAction_CancelChanges
	| OrderAction_Cache
	| OrderAction_Uncache
	| OrderAction_SetState
	| OrderAction_PatchState;

/**
 * Order actions helper interface
 *
 * @export
 * @interface IOrderActions
 * @extends {EntityActions}
 */
export interface IOrderActions extends EntityActions {
	//customAction(ids: OrderId_Some): OrderAction_CustomAction;
}

/**
 * Order actions helper
 *
 * @export
 * @class OrderActions
 * @implements {EntityBaseActions}
 */
export class OrderActions implements IOrderActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): OrderAction_MutateId {
		return { type: ORDER_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): OrderAction_Set {
		return { type: ORDER_SET, payload: id };
	}
	toggle(id?: EntityId): OrderAction_Toggle {
		return { type: ORDER_TOGGLE, payload: id };
	}
	select(ids: OrderId_Some): OrderAction_Select {
		return {
			type: ORDER_SELECT,
			payload: ids
		};
	}
	deselect(ids: OrderId_Some): OrderAction_Deselect {
		return {
			type: ORDER_DESELECT,
			payload: ids
		};
	}
	upsert(entities: OrderEntity_Some): OrderAction_Upsert {
		return {
			type: ORDER_UPSERT,
			payload: entities
		};
	}
	patch(entities: OrderEntityPatch_Some): OrderAction_Patch {
		return {
			type: ORDER_PATCH,
			payload: entities
		};
	}
	change(entities: OrderEntityPatch_Some): OrderAction_Change {
		return {
			type: ORDER_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: OrderId_Some): OrderAction_ApplyChanges {
		return {
			type: ORDER_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: OrderId_Some): OrderAction_CancelChanges {
		return {
			type: ORDER_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: OrderId_Some): OrderAction_Cache {
		return {
			type: ORDER_CACHE,
			payload: ids
		};
	}
	uncache(ids: OrderId_Some): OrderAction_Uncache {
		return {
			type: ORDER_UNCACHE,
			payload: ids
		};
	}
	setState(state: OrderCollectionState): OrderAction_SetState {
		return {
			type: ORDER_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<OrderCollectionState>
	): OrderAction_PatchState {
		return {
			type: ORDER_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the order actions helper
 *
 * @export
 */
export const orderActions = new OrderActions();
