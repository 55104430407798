import { Store, State } from '../../storage';
import * as collections from './collections';

export * from './actions';
export * from './apis';
export * from './classes';
export * from './collections';
export * from './hooks';
export * from './models';
export * from './reducers';

/**
 * Lead store interface
 *
 * @export
 * @interface LeadStore
 */
export interface LeadStore
	extends Store,
		collections.ContactStoreCollection,
		collections.EventAnswerStoreCollection,
		collections.EventContactStoreCollection,
		collections.EventQuestionStoreCollection,
		collections.ContextStoreCollection,
		collections.DeviceStoreCollection,
		collections.EventStoreCollection,
		collections.LeadStoreCollection,
		collections.NoteStoreCollection,
		collections.OrderStoreCollection,
		collections.QualifierStoreCollection,
		collections.QualifierValueStoreCollection,
		collections.ResponseStoreCollection,
		collections.ServiceStoreCollection,
		collections.TicketStoreCollection,
		collections.DataStoreCollection,
		collections.UserStoreCollection {}

/**
 * Lead store default values
 *
 * @export
 * @constant
 */
export const LeadStoreDefault: LeadStore = {
	...collections.contactStoreCollectionDefault,
	...collections.eventAnswerStoreCollectionDefault,
	...collections.eventContactStoreCollectionDefault,
	...collections.eventQuestionStoreCollectionDefault,
	...collections.contextStoreCollectionDefault,
	...collections.deviceStoreCollectionDefault,
	...collections.eventStoreCollectionDefault,
	...collections.leadStoreCollectionDefault,
	...collections.noteStoreCollectionDefault,
	...collections.orderStoreCollectionDefault,
	...collections.qualifierStoreCollectionDefault,
	...collections.qualifierValueStoreCollectionDefault,
	...collections.responseStoreCollectionDefault,
	...collections.serviceStoreCollectionDefault,
	...collections.ticketStoreCollectionDefault,
	...collections.dataStoreCollectionDefault,
	...collections.userStoreCollectionDefault
};
