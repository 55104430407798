import React, { useState } from 'react';

//DATA
import {
	UserEntity,
	ResponseEntity,
	ServiceEntity,
	QualifierEntity,
	EventEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonSelect,
	IonSelectOption
} from '@ionic/react';
import { Chart } from '../../../../app/ui/components/Chart';

export interface QualifierConfigProps {
	user?: UserEntity;
	service?: ServiceEntity;
	event?: EventEntity;
	title?: string;
	finish?: any;
}

export const QualifierReport: React.FC<QualifierConfigProps> = props => {
	const [currentReport, setCurrentReport] = useState('Qualifier Activity');
	const [active, setActive] = useState(true);
	const ctx = useCtx<QualifierConfigProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	//Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	const onReportChanged = (value: string) => {
		setCurrentReport(value);
	};

	let allQualifiers = ctx.lead.qualifier.allByServiceIdAndEventIds(
		props.service?.id || '',
		[props.event?.id || '']
	);

	const reportIonSelectOptions = allQualifiers.map(
		(
			qualifier: QualifierEntity,
			index: number,
			qualifierList: QualifierEntity[]
		) => {
			return (
				<IonSelectOption value={qualifier.id}>{qualifier.name}</IonSelectOption>
			);
		}
	);

	let qualifierData: Object[] = [];
	let displayReport = () => {
		if (currentReport == 'Qualifier Activity') {
			allQualifiers.forEach(qualifier => {
				let qualifierAnswerCountByQualifier =
					ctx.lead.response
						.filter(
							(response: ResponseEntity) =>
								response.qualifierId == qualifier?.id
						)
						.filter((resp, index, self) => {
							return (
								self.findIndex(value => {
									return value.leadId === resp.leadId && resp.value === 'true';
								}) === index
							);
						}).length || 0;
				qualifierData.push({
					id: qualifier.name,
					value: qualifierAnswerCountByQualifier
				});
			});
		} else {
			ctx.lead.qualifierValue
				.allByQualifierId(currentReport)
				.forEach(qualifierValue => {
					let qualifierAnswerCountByQualifierqualifierValue =
						ctx.lead.response.filter(
							(response: ResponseEntity) =>
								response.qualifierId == qualifierValue?.qualifierId &&
								response.qualifierValueId == qualifierValue?.id &&
								response.value === 'true'
						).length || 0;
					qualifierData.push({
						id: qualifierValue.name,
						value: qualifierAnswerCountByQualifierqualifierValue
					});
				});
		}
	};

	displayReport();

	return (
		<IonCard>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol sizeMd="4" size="8">
							<IonSelect
								interface="popover"
								name="activeReportSelect"
								multiple={false}
								cancelText="Cancel"
								okText="Okay"
								value={currentReport}
								placeholder="Select One"
								onIonChange={e => onReportChanged(e.detail.value)}
							>
								<IonSelectOption value="Qualifier Activity">
									Qualifier Activity
								</IonSelectOption>

								{reportIonSelectOptions}
							</IonSelect>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeMd="12" size="12">
							<Chart
								data={qualifierData}
								selectedChartType="bar"
								chartsAvailable={['bar', 'pie']}
								height="500px"
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
