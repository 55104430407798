import React, { Props, useState } from 'react';

//DATA
import {
	LicenseEntity,
	LicenseEntities,
	ServiceEntity,
	EventEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonTitle,
	IonIcon,
	IonLabel
} from '@ionic/react';
import { LicenseCard } from '../../components/License/LicenseCard';
import { cloudDoneOutline } from 'ionicons/icons';
import uuid from '../../../../app/utils/uuid';
export interface LicenseCardListConfigProps {
	title?: string;
	finish?: any;
	licenseType?: string;
	service: ServiceEntity;
	event: EventEntity;
	licenses: LicenseEntities;
}

export const LicenseCardList: React.FC<LicenseCardListConfigProps> = props => {
	const ctx = useCtx(); // TO DO: create and set (deviceListConfig)
	const {
		config: [config],
		app: { activeUser }
	} = ctx;

	const sortLicenses = (a: LicenseEntity, b: LicenseEntity) => {
		return a.i_.status > b.i_.status ? 1 : b.i_.status > a.i_.status ? -1 : 0;
		/*return (a.i_.created?.dt as Date) > (b.i_.created?.dt as Date)
			? 1
			: (b.i_.created?.dt as Date) > (a.i_.created?.dt as Date)
			? -1
			: 0;*/
	};
	let licenses: LicenseEntities = props.licenses.filter(
		license => license.serviceType === props.licenseType || ''
	);

	const licenseListItems = licenses
		.sort(sortLicenses)
		.map(
			(license: LicenseEntity, index: number, licenseList: LicenseEntity[]) => {
				return (
					<IonItem key={'IonItem_' + license.id}>
						<LicenseCard key={'LicenseCard_' + license.id} license={license} />
					</IonItem>
				);
			}
		);

	return licenses.length <= 0 ? (
		<h2></h2>
	) : (
		<IonGrid>
			<IonRow>
				<IonCol sizeSm="3">
					<IonTitle>{props.title}</IonTitle>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'lead-app'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="35%"
							src={'assets/img/myleads_app.png'}
						/>
						<div style={{ display: 'inline-block', textAlign: 'center' }}>
							<a
								target="_blank"
								href="https://play.google.com/store/apps/details?id=com.conexsys.myleadsmobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
							>
								<img
									width="150px"
									alt="Get it on Google Play"
									src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
								/>
							</a>
							<a
								target="_blank"
								href="https://apps.apple.com/us/app/myleads-mobile-by-conexsys/id1510952695?itsct=apps_box_badge&amp;itscg=30200"
							>
								<img
									src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1591142400&h=86cf9ecfd43a5573a4b91cc56cbcc590"
									alt="Download on the App Store"
								/>
							</a>
						</div>
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'lead-api'}
					>
						<IonIcon src={cloudDoneOutline} style={{ zoom: 9.0 }} />
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'sst'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="50%"
							src={'assets/img/bc500dc.jpg'}
						/>
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'opticon'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="50%"
							src={'assets/img/Opticon_9725.jpg'}
						/>
						<br />
						<IonLabel hidden={!activeUser?.security.includes('administrator')}>
							<strong>Sign-Out Code:</strong>
							<br />
							{'S%' + uuid().compress(props.service?.id || '')}
						</IonLabel>
					</div>
				</IonCol>
				<IonCol sizeSm="9">
					<IonList key="licenseList">{licenseListItems}</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
