import { UserHelper } from '..';
import { userConfig } from '../../../config/app/User';
import { UserEntity } from '../collections';
export interface UserHook {
	locale: string;
	userHelper: UserHelper;
	activeUser: UserEntity | undefined;
}

export const useUser = (): UserHook => {
	let userHelper = new UserHelper();
	const locale =
		userHelper?.active()?.locale || userConfig.settings.default.locale || '';
	return {
		locale,
		userHelper,
		activeUser: userHelper.active()
	};
};
