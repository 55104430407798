import { ContactHelper } from '..';
import { ContactEntity } from '../collections';

export interface ContactHook {
	contactHelper: ContactHelper;
	activeContact: ContactEntity | undefined;
}

export const useContact = (): ContactHook => {
	let contactHelper = new ContactHelper();
	return {
		contactHelper,
		activeContact: contactHelper.active()
	};
};
