import { LeadHelper } from '..';
import { LeadEntity } from '../collections';

export interface LeadHook {
	leadHelper: LeadHelper;
	activeLead: LeadEntity | undefined;
}

export const useLead = (): LeadHook => {
	let leadHelper = new LeadHelper();
	return {
		leadHelper,
		activeLead: leadHelper.active()
	};
};
