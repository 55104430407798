import { UserHelper } from '..';
import { UserEntity } from '../collections';

export interface UserHook {
	userHelper: UserHelper;
	activeUser: UserEntity | undefined;
}

export const useUser = (): UserHook => {
	let userHelper = new UserHelper();
	return {
		userHelper,
		activeUser: userHelper.active()
	};
};
