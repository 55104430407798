import React, { useState, useEffect } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonButtons,
	IonContent,
	IonList,
	IonItem,
	IonButton,
	IonLabel,
	IonIcon,
	IonTitle,
	IonToolbar,
	IonBadge,
	IonSpinner,
	IonInput
} from '@ionic/react';

import {
	ServiceEntity,
	ServiceEntities,
	ContactOrganizationEntity,
	LicenseEntities
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';
import { useToast } from '@agney/ir-toast';
import {
	checkmarkCircle,
	chevronDown,
	chevronUp,
	closeCircle,
	settingsOutline,
	stopCircle,
	warningOutline
} from 'ionicons/icons';

export interface ImportOrdersConfigProps {
	servicesIds?: string[];
	emailType?: string;
	onComplete?: () => void;
}

export const SendBatchEmails: React.FC<ImportOrdersConfigProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		lead: { activeContext, serviceHelper, contactHelper, licenseHelper }
	} = ctx;

	const [batchEmailStatus, setBatchEmailStatus] = useState('notstarted');
	const [batchEmails, setBatchEmails] = useState<
		{ service: ServiceEntity; status: string; message: string }[]
	>([]);
	const [batchEmailBcc, setBatchEmailBcc] = useState('');
	const [batchEmailShowSettings, setBatchEmailShowSettings] = useState(false);
	const [batchEmailBccIsValid, setBatchEmailBccIsValid] = useState(true);

	useEffect(() => {
		let services: ServiceEntities = serviceHelper.gets(props.servicesIds || []);
		let batchServices:
			| { service: ServiceEntity; status: string; message: string }[]
			| undefined = [];

		for (let i = 0; i < services.length; i++) {
			let service = services[i];
			let batchObject: {
				service: ServiceEntity;
				status: string;
				message: string;
			} = {
				service: service,
				status: 'notstarted',
				message: ''
			};
			batchServices?.push(batchObject);
		}

		setBatchEmailStatus('notstarted');
		setBatchEmailBcc('');
		setBatchEmailBccIsValid(true);
		setBatchEmailShowSettings(false);
		setBatchEmails(batchServices);
	}, []);

	const sleep = (ms: number) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	};

	const sendEmail = async (i: number) => {
		const s = await sleep(300);
		let newBatchEmails = [...batchEmails];
		newBatchEmails[i].status = 'inprogress';
		setBatchEmails(newBatchEmails);
		const ss = await sleep(300);
		let service: ServiceEntity | undefined = newBatchEmails[i].service;
		let mainContact: ContactOrganizationEntity | undefined = service?.contactIds
			? contactHelper.get_Organization(service.contactIds[0])
			: undefined;
		if (
			activeContext &&
			service &&
			mainContact?.contactEmail &&
			mainContact?.contactEmail.trim() !== ''
		) {
			let bcc = 'order@conexsys.com';
			const regexp = new RegExp(
				/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/
			);
			if (batchEmailBcc && regexp.test(batchEmailBcc)) {
				bcc = bcc + ',' + batchEmailBcc;
			}
			if (props.emailType === 'loginemail') {
				let licenses: LicenseEntities = licenseHelper.allByContexts(
					[activeContext],
					[service]
				);
				let r = await serviceHelper.sendServiceLoginEmail(
					ctx,
					service,
					activeContext,
					licenses,
					mainContact.contactEmail,
					undefined,
					bcc,
					m => {
						newBatchEmails[i].status = 'failed';
						newBatchEmails[i].message = m;
						setBatchEmails(newBatchEmails);
					},
					m => {
						newBatchEmails[i].status = 'completed';
						newBatchEmails[i].message = 'Completed';
						setBatchEmails(newBatchEmails);
					}
				);
			} else if (props.emailType === 'postshow') {
				let r = await serviceHelper.sendPostShowEmail(
					ctx,
					service,
					activeContext,
					mainContact,
					mainContact.contactEmail,
					undefined,
					bcc,
					m => {
						newBatchEmails[i].status = 'failed';
						newBatchEmails[i].message = m;
						setBatchEmails(newBatchEmails);
					},
					m => {
						newBatchEmails[i].status = 'completed';
						newBatchEmails[i].message = 'Completed';
						setBatchEmails(newBatchEmails);
					}
				);
			} else {
				newBatchEmails[i].status = 'failed';
				newBatchEmails[i].message = 'No Email Type Set';
				setBatchEmails(newBatchEmails);
			}
		} else {
			newBatchEmails[i].status = 'failed';
			newBatchEmails[i].message = 'Missing required information';
			setBatchEmails(newBatchEmails);
		}
	};

	const sendEmails = async () => {
		setBatchEmailStatus('inprogress');

		for (let x = 0; x < (batchEmails?.length || 0); x++) {
			await sendEmail(x);
		}

		setBatchEmailStatus('completed');
	};

	const listItems = batchEmails ? (
		batchEmails.map(
			(batchEmailService: {
				service: ServiceEntity;
				status: string;
				message: string;
			}) => {
				let mainContact:
					| ContactOrganizationEntity
					| undefined = batchEmailService?.service?.contactIds
					? contactHelper.get_Organization(
							batchEmailService.service.contactIds[0]
					  )
					: undefined;
				return (
					<IonItem>
						<IonGrid>
							<IonRow>
								<IonCol size="5">{batchEmailService.service.name}</IonCol>
								<IonCol size="5">{mainContact?.contactEmail}</IonCol>
								<IonCol size="2">
									<IonBadge
										color={
											batchEmailService.status === 'inprogress'
												? 'primary'
												: batchEmailService.status === 'completed'
												? 'success'
												: batchEmailService.status === 'failed'
												? 'danger'
												: 'light'
										}
									>
										{batchEmailService.status === 'inprogress' ? (
											<IonSpinner />
										) : batchEmailService.status === 'completed' ? (
											<IonIcon src={checkmarkCircle} />
										) : batchEmailService.status === 'failed' ? (
											<IonIcon src={closeCircle} />
										) : (
											<IonIcon src={stopCircle} />
										)}
									</IonBadge>
								</IonCol>
								<IonCol
									hidden={batchEmailService.status !== 'failed'}
									size="12"
								>
									<IonLabel color="danger">
										{batchEmailService.message}
									</IonLabel>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
				);
			}
		)
	) : (
		<></>
	);

	return (
		<>
			<IonContent>
				<IonList>{listItems}</IonList>
			</IonContent>
			<IonToolbar>
				<IonTitle slot="start">
					{batchEmailStatus === 'inprogress'
						? 'Sending ...'
						: batchEmailStatus === 'completed'
						? 'Completed'
						: 'Confirm Sending'}
				</IonTitle>
				<IonButtons slot="end">
					<IonButton
						hidden={
							batchEmailStatus === 'inprogress' ||
							batchEmailStatus === 'completed'
						}
						fill="clear"
						onClick={sendEmails}
					>
						Start Sending{' '}
						{props.emailType === 'postshow'
							? 'Post Show'
							: props.emailType === 'loginemail'
							? 'Login'
							: ''}{' '}
						Emails
						<IonIcon
							hidden={batchEmailBccIsValid}
							src={warningOutline}
							color="warning"
						/>
					</IonButton>
					<IonButton
						hidden={
							batchEmailStatus === 'inprogress' ||
							batchEmailStatus === 'completed'
						}
						onClick={() => {
							setBatchEmailShowSettings(!batchEmailShowSettings);
						}}
					>
						<IonIcon src={settingsOutline} />
						<IonIcon hidden={batchEmailShowSettings} src={chevronDown} />
						<IonIcon hidden={!batchEmailShowSettings} src={chevronUp} />
					</IonButton>
					<IonSpinner hidden={batchEmailStatus !== 'inprogress'} />
				</IonButtons>
			</IonToolbar>
			<IonToolbar
				hidden={
					!batchEmailShowSettings ||
					batchEmailStatus === 'inprogress' ||
					batchEmailStatus === 'completed'
				}
			>
				<IonItem color={batchEmailBccIsValid ? '' : 'danger'}>
					<IonLabel>BCC: </IonLabel>
					<IonInput
						type="email"
						placeholder="Add Email BCC"
						value={batchEmailBcc}
						onIonChange={e => {
							const regexp = new RegExp(
								/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/
							);
							if (
								(e?.detail?.value?.trim() ?? '') !== '' &&
								!regexp.test(e?.detail?.value?.trim() ?? '')
							) {
								setBatchEmailBccIsValid(false);
							} else {
								setBatchEmailBccIsValid(true);
							}
							setBatchEmailBcc(e?.detail?.value?.trim() ?? '');
						}}
					></IonInput>
				</IonItem>
			</IonToolbar>
		</>
	);
};
