import React from 'react';

//import { initialState, State } from './state';
//import reducers from './reducers';
//import views from './views';

import { appConfig, AppConfig } from './app';
import { leadConfig, LeadConfig } from './lead';

import { UserControl } from '../app/ui/components';

export interface Config {
	app: AppConfig;
	lead: LeadConfig;

	localStorage: string[];

	components: {
		[id: string]: React.FC<any | undefined>;
	};

	name: string;
	browserTitlePrefix?: string;
	browserTitlePostfix?: string;

	version: string;

	entityApiId: string;

	security: {
		default: string[];
	};

	//views: { [app: string]: { [view: string]: React.FC } };
	//reducers: any;
	//initialState: State;
}

export const config: Config = {
	app: appConfig,
	lead: leadConfig,

	localStorage: [
		'entities',
		'queries',
		'app.user',
		'lead.context',
		'lead.event'
	],

	components: {
		'app.UserControl': UserControl
	},

	name: 'myLeads',
	browserTitlePostfix: ' - myLeads',

	version: '2.6.4',
	entityApiId: 'lead-portal-api',

	security: {
		default: [
			...appConfig.user.security.default,
			...leadConfig.security.default
		]
	}

	//views,
	//reducers,
	//initialState
};

export default config;
