//import { LeadConfig as _LeadConfig, leadConfig as _leadConfig} * from './Lead';
//import { ServiceConfig, serviceConfig} * from './Service';
import { apiConfig } from '../app/Api';
import { Api } from '../../app/store/models/Api';

export interface LeadConfig {
	apiId: string;
	security: {
		default: string[];
	};
	//lead: _LeadConfig
	//service: ServiceConfig
}

export const leadConfig: LeadConfig = {
	apiId: 'lead-portal-api',
	security: {
		default: [
			'read:leadService',
			'write:leadService',
			'read:leadServiceOrders',
			'write:leadServiceOrders',
			'read:leadServiceEvents',
			'write:leadServiceEvents',
			'read:leadServiceUsers',
			'write:leadServiceUsers',
			'read:leadServiceDevices',
			'write:leadServiceDevices',
			'read:leadServiceQualifiers',
			'write:leadServiceQualifiers',
			'read:leadServiceLeads',
			'write:leadServiceLeads',
			'read:leadServiceNotes',
			'write:leadServiceNotes',
			'read:leadServiceResponses',
			'write:leadServiceResponse',
			'read:leadServiceTickets',
			'write:leadServiceTickets',
			'read:leadServiceReports',
			'write:leadServiceReports'
		]
	}
	//lead: _leadConfig
	//service: serviceConfig
};
