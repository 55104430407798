import {
	QualifierId_Some,
	QualifierEntity_Some,
	QualifierEntityPatch_Some,
	QualifierCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const QUALIFIER_MUTATE_ID = '@lead/QUALIFIER_MUTATE_ID';
export const QUALIFIER_SET = '@lead/QUALIFIER_SET';
export const QUALIFIER_TOGGLE = '@lead/QUALIFIER_TOGGLE';
export const QUALIFIER_SELECT = '@lead/QUALIFIER_SELECT';
export const QUALIFIER_DESELECT = '@lead/QUALIFIER_DESELECT';
export const QUALIFIER_UPSERT = '@lead/QUALIFIER_UPSERT';
export const QUALIFIER_PATCH = '@lead/QUALIFIER_PATCH';
export const QUALIFIER_CHANGE = '@lead/QUALIFIER_CHANGE';
export const QUALIFIER_APPLY_CHANGES = '@lead/QUALIFIER_APPLY_CHANGES';
export const QUALIFIER_CANCEL_CHANGES = '@lead/QUALIFIER_CANCEL_CHANGES';
export const QUALIFIER_CACHE = '@lead/QUALIFIER_CACHE';
export const QUALIFIER_UNCACHE = '@lead/QUALIFIER_UNCACHE';
export const QUALIFIER_SETSTATE = '@lead/QUALIFIER_SETSTATE';
export const QUALIFIER_PATCHSTATE = '@lead/QUALIFIER_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface QualifierAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface QualifierAction_MutateId extends EntityAction_MutateId {
	type: typeof QUALIFIER_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active qualifier
 *
 * @interface QualifierAction_Set
 * @extends {EntityAction_Set}
 */
export interface QualifierAction_Set extends EntityAction_Set {
	type: typeof QUALIFIER_SET;
	payload?: EntityId;
}

/**
 * Toggle active qualifier
 *
 * @interface QualifierAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface QualifierAction_Toggle extends EntityAction_Toggle {
	type: typeof QUALIFIER_TOGGLE;
	payload?: EntityId;
}

/**
 * Select qualifier entities
 *
 * @interface QualifierAction_Select
 * @extends {EntityAction_Select}
 */
interface QualifierAction_Select extends EntityAction_Select {
	type: typeof QUALIFIER_SELECT;
	payload: QualifierId_Some;
}

/**
 * Deselect qualifier entities
 *
 * @interface QualifierAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface QualifierAction_Deselect extends EntityAction_Deselect {
	type: typeof QUALIFIER_DESELECT;
	payload: QualifierId_Some;
}

/**
 * Upsert qualifier entities
 *
 * @interface QualifierAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface QualifierAction_Upsert extends EntityAction_Upsert {
	type: typeof QUALIFIER_UPSERT;
	payload: QualifierEntity_Some;
}

/**
 * Patch qualifier entities
 *
 * @interface QualifierAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface QualifierAction_Patch extends EntityAction_Patch {
	type: typeof QUALIFIER_PATCH;
	payload: QualifierEntityPatch_Some;
}

/**
 * Change qualifier entities
 *
 * @interface QualifierAction_Change
 * @extends {EntityAction_Change}
 */
interface QualifierAction_Change extends EntityAction_Change {
	type: typeof QUALIFIER_CHANGE;
	payload: QualifierEntityPatch_Some;
}

/**
 * Apply changes to qualifier entities
 *
 * @interface QualifierAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface QualifierAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof QUALIFIER_APPLY_CHANGES;
	payload: QualifierId_Some;
}

/**
 * Cancel changes to qualifier entities
 *
 * @interface QualifierAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface QualifierAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof QUALIFIER_CANCEL_CHANGES;
	payload: QualifierId_Some;
}

/**
 * Cache qualifier entities
 *
 * @interface QualifierAction_Cache
 * @extends {EntityAction_Cache}
 */
interface QualifierAction_Cache extends EntityAction_Cache {
	type: typeof QUALIFIER_CACHE;
	payload: QualifierId_Some;
}

/**
 * Uncache qualifier entities
 *
 * @interface QualifierAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface QualifierAction_Uncache extends EntityAction_Uncache {
	type: typeof QUALIFIER_UNCACHE;
	payload: QualifierId_Some;
}

/**
 * Set qualifier collection / entity state
 *
 * @interface QualifierAction_SetState
 * @extends {EntityAction_SetState}
 */
interface QualifierAction_SetState extends EntityAction_SetState {
	type: typeof QUALIFIER_SETSTATE;
}

/**
 * PAtch qualifier collection / entity state
 *
 * @interface QualifierAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface QualifierAction_PatchState extends EntityAction_PatchState {
	type: typeof QUALIFIER_PATCHSTATE;
}

/**
 * Export qualifier action types
 *
 * @export
 */
export type QualifierActionTypes =
	| QualifierAction_MutateId
	| QualifierAction_MutateId
	| QualifierAction_Set
	| QualifierAction_Toggle
	| QualifierAction_Select
	| QualifierAction_Deselect
	| QualifierAction_Upsert
	| QualifierAction_Patch
	| QualifierAction_Change
	| QualifierAction_ApplyChanges
	| QualifierAction_CancelChanges
	| QualifierAction_Cache
	| QualifierAction_Uncache
	| QualifierAction_SetState
	| QualifierAction_PatchState;

/**
 * Qualifier actions helper interface
 *
 * @export
 * @interface IQualifierActions
 * @extends {EntityActions}
 */
export interface IQualifierActions extends EntityActions {
	//customAction(ids: QualifierId_Some): QualifierAction_CustomAction;
}

/**
 * Qualifier actions helper
 *
 * @export
 * @class QualifierActions
 * @implements {EntityBaseActions}
 */
export class QualifierActions implements IQualifierActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): QualifierAction_MutateId {
		return { type: QUALIFIER_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): QualifierAction_Set {
		return { type: QUALIFIER_SET, payload: id };
	}
	toggle(id?: EntityId): QualifierAction_Toggle {
		return { type: QUALIFIER_TOGGLE, payload: id };
	}
	select(ids: QualifierId_Some): QualifierAction_Select {
		return {
			type: QUALIFIER_SELECT,
			payload: ids
		};
	}
	deselect(ids: QualifierId_Some): QualifierAction_Deselect {
		return {
			type: QUALIFIER_DESELECT,
			payload: ids
		};
	}
	upsert(entities: QualifierEntity_Some): QualifierAction_Upsert {
		return {
			type: QUALIFIER_UPSERT,
			payload: entities
		};
	}
	patch(entities: QualifierEntityPatch_Some): QualifierAction_Patch {
		return {
			type: QUALIFIER_PATCH,
			payload: entities
		};
	}
	change(entities: QualifierEntityPatch_Some): QualifierAction_Change {
		return {
			type: QUALIFIER_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: QualifierId_Some): QualifierAction_ApplyChanges {
		return {
			type: QUALIFIER_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: QualifierId_Some): QualifierAction_CancelChanges {
		return {
			type: QUALIFIER_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: QualifierId_Some): QualifierAction_Cache {
		return {
			type: QUALIFIER_CACHE,
			payload: ids
		};
	}
	uncache(ids: QualifierId_Some): QualifierAction_Uncache {
		return {
			type: QUALIFIER_UNCACHE,
			payload: ids
		};
	}
	setState(state: QualifierCollectionState): QualifierAction_SetState {
		return {
			type: QUALIFIER_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<QualifierCollectionState>
	): QualifierAction_PatchState {
		return {
			type: QUALIFIER_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the qualifier actions helper
 *
 * @export
 */
export const qualifierActions = new QualifierActions();
