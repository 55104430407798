import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getUserCollection,
	UserId,
	UserIds,
	UserId_Some,
	UserEntity,
	UserEntities,
	UserEntity_Some,
	UserEntityPatch_Some,
	UserCollection,
	UserCollectionState,
	IUserActions,
	userActions,
	UserActionTypes,
	OpenidAuthDigest
} from '..';
import { UserEntity as SysUserEntity } from '../../../lead/store';
import moment from 'moment';

import config from '../../../config';
import { UseCtx } from '../../../config/hooks';
/**
 * User helper interface
 *
 * @export
 * @interface IUserHelper
 * @extends {IEntityHelper}
 */
export interface IUserHelper extends IEntityHelper {
	digestAuth(
		ctx: UseCtx<any>,
		authDigest: OpenidAuthDigest
	): Promise<UserEntity | undefined>;
	digestAuthUser(authDigest: OpenidAuthDigest): UserEntity;
	checkAuth(): void;
	refeshUserAuth(id: string): void;
	switchUser(id: string): void;
	signout(ids: UserId_Some): void;
}

/**
 * User helper options interface
 *
 * @export
 * @interface UserHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface UserHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const userHelperOpts: UserHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * User helper
 *
 * @export
 * @class UserHelper
 * @extends {EntityHelper<UserCollection, UserActionTypes, UserActions, UserEntity, UserEntities, UserEntity_Some, UserEntityPatch_Some, UserId, UserIds, UserId_Some, UserCollectionState, UserHelperOpts>}
 * @implements {IUserHelper}
 */
export class UserHelper
	extends EntityHelper<
		UserCollection,
		UserActionTypes,
		IUserActions,
		UserEntity,
		UserEntities,
		UserEntity_Some,
		UserEntityPatch_Some,
		UserId,
		UserIds,
		UserId_Some,
		UserCollectionState,
		UserHelperOpts
	>
	implements IUserHelper {
	constructor() {
		super(
			useSelector(getUserCollection),
			userActions,
			useDispatch(),
			userHelperOpts
		);
		this.collection = useSelector(getUserCollection);
		this.dispatch = useDispatch();
	}

	async digestAuth(
		ctx: UseCtx<any>,
		authDigest: OpenidAuthDigest
	): Promise<UserEntity | undefined> {
		// parse auth response to new / existing user
		let appUser: UserEntity = this.digestAuthUser(authDigest);

		let errorMessage: string | undefined;

		let user: SysUserEntity | undefined = await ctx.lead.user.readUserById(
			ctx,
			{
				id: appUser.id
			},
			(result: any) => {
				if (!result?.id) {
					errorMessage =
						result?.response?.data?.message || 'Unable to access user account';
				}
			}
		);

		if (!user && !errorMessage)
			errorMessage = 'Unable to retrieve user account';

		if (errorMessage) {
			// set the app provider auth error
			ctx.app.error(errorMessage);
			return;
		} else {
			console.log(user);

			appUser.userId = user?.id;

			// upsert new or updated user
			this.upsert(appUser);

			// set the active user
			this.set(appUser.id);

			return appUser;
		}
	}

	digestAuthUser(authDigest: OpenidAuthDigest): UserEntity {
		return {
			id: authDigest.claims.user_metadata?.userId || authDigest.claims.sub,
			name: authDigest.claims.name,
			firstName: authDigest.claims.given_name,
			lastName: authDigest.claims.family_name,
			middleName: authDigest.claims.middle_name,
			nickname: authDigest.claims.nickname,
			username: authDigest.claims.preferred_username,
			profile: authDigest.claims.profile,
			picture: authDigest.claims.picture,
			website: authDigest.claims.website,
			email: authDigest.claims.email,
			emailVerified: authDigest.claims.email_verified,
			gender: authDigest.claims.gender,
			birthdate: authDigest.claims.birthdate,
			zoneinfo: authDigest.claims.zoneinfo,
			locale: authDigest.claims.locale,
			phone: authDigest.claims.phone_number,
			phoneVerified: authDigest.claims.phone_number_verified,
			address: authDigest.claims.address,
			updatedAt: moment(authDigest.claims.updated_at).unix(),
			auth: {
				[authDigest.claims.aud]: {
					subjectId: authDigest.claims.sub,
					issuer: authDigest.claims.iss,
					audience: authDigest.claims.aud,
					issuedAt: authDigest.claims.iat,
					expiresAt: authDigest.claims.exp,
					token: authDigest.access_token,
					refreshToken: authDigest.refresh_token,
					nonce: authDigest.claims.nonce,
					response: authDigest,
					isAuthenticated: true
				}
			},
			security: authDigest.claims.email?.endsWith('@conexsys.com')
				? [...config.security.default, 'administrator']
				: config.security.default
		};
	}

	checkAuth() {
		// check auth
	}

	// refresh a user authentication with it's referesh token
	refeshUserAuth(id: string) {
		// go do auth refresh with provider
	}

	switchUser(id: string) {
		if (!(id in this.collection.byIds)) throw new Error('Invalid user');

		this.set(id);
	}

	signout(ids: UserId_Some) {
		// do some singout related things then cache
		this.cache(ids);
	}
}
