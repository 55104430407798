import React from 'react';

//DATA
import {
	ServiceEntity,
	ContextEntity,
	isObjectStatusActive,
	LicenseEntities,
	LicenseServiceType,
	ServiceEntities
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { IonCol, IonTitle } from '@ionic/react';
import { Barcode } from '../../../app/ui/components/Barcode';
import uuid from '../../../app/utils/uuid';
import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';

export interface AdminSignoutListViewProps {
	servicesToPrint?: string[];
}

const AdminSignoutListView: React.FC<AdminSignoutListViewProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		lead: { serviceHelper, activeContext, contactHelper, licenseHelper }
	} = ctx;

	const currentContext: ContextEntity | undefined = activeContext;
	if (!currentContext) return <ServiceNotFoundCard />;

	const sortServices = (a: ServiceEntity, b: ServiceEntity) => {
		return (a.name || '') > (b.name || '')
			? 1
			: (b.name || '') > (a.name || '')
			? -1
			: 0;
	};
	let lineCount: number = 0;

	return (
		<div key={'AdminSignoutList'} style={{ margin: '20px' }}>
			<div style={{ margin: '10px' }}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						display: 'inline-block'
					}}
				>
					{currentContext.name}
					<br />
					Sign-Out List
				</div>
			</div>
			{serviceHelper
				.allByContextIds([currentContext?.id || ''])
				.filter(ec =>
					props.servicesToPrint && props.servicesToPrint.length > 0
						? props.servicesToPrint?.includes(ec.id)
						: true
				)
				.sort(sortServices)
				.map(service => {
					let mainContact = contactHelper.get_Organization(
						service.contactIds?.length ? service.contactIds[0] : ''
					);
					let licenses: LicenseEntities = licenseHelper.allByContexts(
						[currentContext],
						[service]
					);

					let leadOrders: { [key: string]: number } = {
						Opticon: licenses
							.filter(l => l.serviceType == LicenseServiceType.Opticon)
							.reduce((sum, current) => sum + current.quantity, 0),
						LeadApp: licenses
							.filter(l => l.serviceType == LicenseServiceType.LeadApp)
							.reduce((sum, current) => sum + current.quantity, 0),
						LeadApi: licenses
							.filter(l => l.serviceType == LicenseServiceType.LeadApi)
							.reduce((sum, current) => sum + current.quantity, 0),
						Sst: licenses
							.filter(l => l.serviceType == LicenseServiceType.Sst)
							.reduce((sum, current) => sum + current.quantity, 0)
					};

					let blankRow = <div style={{ margin: '10px' }}></div>;
					if (lineCount < 9) {
						lineCount += 1;
						blankRow = <></>;
					} else {
						lineCount = 1;
						blankRow = (
							<div style={{ margin: '10px' }}>
								<div
									style={{
										width: '100%',
										display: 'inline-block'
									}}
								>
									<br />
									<IonTitle class="ion-text-center">
										{currentContext.name}
										<br />
										Sign-Out List
									</IonTitle>
								</div>
							</div>
						);
					}

					return (
						<>
							{blankRow}
							<div style={{ border: 'solid black 1px', padding: '10px' }}>
								<div
									style={{
										width: '40%',
										display: 'inline-block'
									}}
								>
									<strong>{service?.name}</strong>
									<br />
									{mainContact?.contactEmail}
									<br />
									<Barcode
										data={'S%' + uuid().compress(service?.id || '')}
										width="250px"
										size={4}
									/>
								</div>
								<div
									style={{
										width: '20%',
										display: 'inline-block'
									}}
								>
									<div hidden={leadOrders.Opticon <= 0}>
										Opticon: {leadOrders.Opticon}
									</div>
									<div hidden={leadOrders.LeadApp <= 0}>
										App: {leadOrders.LeadApp}
									</div>
									<div hidden={leadOrders.LeadApi <= 0}>
										Api: {leadOrders.LeadApi}
									</div>
									<div hidden={leadOrders.Sst <= 0}>SST: {leadOrders.Sst}</div>
								</div>
								<div
									style={{
										width: '40%',
										display: 'inline-block'
									}}
								>
									<br />
									<br />
									_______________________________
									<br />
									Signature
								</div>
							</div>
						</>
					);
				})}
		</div>
	);
};

export default AdminSignoutListView;
