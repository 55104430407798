import { OrderHelper } from '..';
import { OrderEntity } from '../collections';

export interface OrderHook {
	orderHelper: OrderHelper;
	activeOrder: OrderEntity | undefined;
}

export const useOrder = (): OrderHook => {
	let orderHelper = new OrderHelper();
	return {
		orderHelper,
		activeOrder: orderHelper.active()
	};
};
