import React from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonCol,
	IonRow,
	IonContent
} from '@ionic/react';
import { UserList } from './../containers/User';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { ServiceNotFoundCard } from './../components/Service/ServiceNotFoundCard';

const UsersView: React.FC = () => {
	const {
		config: [config],
		lead: { activeService, activeEvent, activeContext }
	} = useCtx<{}>({});

	if (!activeService) return <ServiceNotFoundCard />;

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol>
						<PageTitle title="Representatives" displayEventSelector={true} />
					</IonCol>
				</IonRow>
				{activeEvent && (
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardContent>
									<UserList service={activeService} context={activeContext} />
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default UsersView;
