import {
	ContextCollection,
	ContextCollectionState,
	ContextId,
	ContextEntity_Some,
	ContextEntityPatch_Some,
	ContextId_Some,
	contextCollectionDefault,
	contextCollectionMutateIdOpts
} from '../collections/Context';
import * as actions from '../actions/Context';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Context reducer
 *
 * @export
 * @param {ContextCollection} [state=contextCollectionDefault]
 * @param {ContextActionTypes} action
 * @returns
 */
export function contextReducer(
	state: ContextCollection = contextCollectionDefault,
	action: actions.ContextActionTypes
) {
	switch (action.type) {
		case actions.CONTEXT_MUTATE_ID:
			return collectionReducerActions.mutateId<ContextCollection>(
				state,
				action.payload,
				contextCollectionMutateIdOpts
			);
		case actions.CONTEXT_SET:
			return collectionReducerActions.set<ContextCollection, ContextId>(
				state,
				action.payload
			);
		case actions.CONTEXT_TOGGLE:
			return collectionReducerActions.toggle<ContextCollection, ContextId>(
				state,
				action.payload
			);
		case actions.CONTEXT_SELECT:
			return collectionReducerActions.select<ContextCollection, ContextId_Some>(
				state,
				action.payload
			);
		case actions.CONTEXT_DESELECT:
			return collectionReducerActions.deselect<
				ContextCollection,
				ContextId_Some
			>(state, action.payload);
		case actions.CONTEXT_UPSERT:
			return collectionReducerActions.upsert<
				ContextCollection,
				ContextEntity_Some
			>(state, action.payload);
		case actions.CONTEXT_PATCH:
			return collectionReducerActions.patch<
				ContextCollection,
				ContextEntityPatch_Some
			>(state, action.payload);
		case actions.CONTEXT_CHANGE:
			return collectionReducerActions.change<
				ContextCollection,
				ContextEntityPatch_Some
			>(state, action.payload);
		case actions.CONTEXT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ContextCollection,
				ContextId_Some
			>(state, action.payload);
		case actions.CONTEXT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ContextCollection,
				ContextId_Some
			>(state, action.payload);
		case actions.CONTEXT_CACHE:
			return collectionReducerActions.cache<ContextCollection, ContextId_Some>(
				state,
				action.payload
			);
		case actions.CONTEXT_UNCACHE:
			return collectionReducerActions.uncache<
				ContextCollection,
				ContextId_Some
			>(state, action.payload);
		case actions.CONTEXT_SETSTATE:
			return collectionReducerActions.setState<
				ContextCollection,
				ContextCollectionState
			>(state, action.payload.state);
		case actions.CONTEXT_PATCHSTATE:
			return collectionReducerActions.patchState<
				ContextCollection,
				ContextCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
