import React, { useState } from 'react';
import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonSelect,
	IonSelectOption
} from '@ionic/react';

import { User, LeadEntities, EventEntity, ServiceEntity } from '../../../store';
import moment, { Moment } from 'moment';
import { useCtx } from '../../../../config/hooks';
import { Chart } from '../../../../app/ui/components/Chart';
import { Serie } from '@nivo/line';

export interface TrafficReportConfigProps {
	user?: User;
	service?: ServiceEntity;
	event?: EventEntity;
	title?: string;
	finish?: any;
}

export const TrafficReport: React.FC<TrafficReportConfigProps> = props => {
	const [currentReport, setCurrentReport] = useState('All Days');
	const ctx = useCtx<TrafficReportConfigProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	let allLeads: LeadEntities = ctx.lead.lead.allByServiceIdAndEventIds(
		props.service?.id || '',
		[props.event?.id || '']
	);

	const onReportChanged = (value: string) => {
		setCurrentReport(value);
		displayReport();
	};

	let leadScanDates: string[] = [];
	let reportSelectOptions: string[] = ['All Days', 'All Days By Hour'];
	allLeads.forEach(lead => {
		if (lead.i_.created && !leadScanDates.includes(lead.i_.created?.dt || '')) {
			leadScanDates.push(lead.i_.created?.dt || '');
			if (
				!reportSelectOptions.includes(
					moment(lead.i_.created?.dt).format('YYYY-MM-DD')
				)
			) {
				reportSelectOptions.push(
					moment(lead.i_.created?.dt).format('YYYY-MM-DD')
				);
			}
		}
	});
	const reportIonSelectOptions = reportSelectOptions.map(
		(name: string, index: number, leadList: string[]) => {
			return <IonSelectOption value={name}>{name}</IonSelectOption>;
		}
	);

	let leadsByDayData: Serie[] = [];
	let displayReport = () => {
		leadsByDayData = [
			{
				id: 'Leads',
				data: []
			}
		];
		if (currentReport == 'All Days') {
			leadScanDates.forEach(leadDate => {
				let dateAttendance =
					allLeads.filter(lead =>
						moment(lead.i_.created?.dt).isSame(leadDate, 'day')
					).length || 0;
				leadsByDayData[0].data.push({
					x: moment(leadDate).format('YYYY-MM-DD'),
					y: dateAttendance
				});
			});
		} else if (currentReport == 'All Days By Hour') {
			let hour: number = 1;
			for (hour; hour <= 24; hour++) {
				let hourAttendance =
					allLeads.filter(lead =>
						moment(lead.i_.created?.dt).isSame(moment().hour(hour), 'hour')
					).length || 0;
				leadsByDayData[0].data.push({
					x: moment().hour(hour).format('hh:mm'),
					y: hourAttendance
				});
			}
		} else {
			let currentDay = moment(currentReport);
			let hour: number = 1;
			for (hour; hour <= 24; hour++) {
				let hourAttendance =
					allLeads.filter(
						lead =>
							moment(lead.i_.created?.dt).isSame(currentDay, 'day') &&
							moment(lead.i_.created?.dt).isSame(moment().hour(hour), 'hour')
					).length || 0;
				leadsByDayData[0].data.push({
					x: moment().hour(hour).format('hh:mm'),
					y: hourAttendance
				});
			}
		}
	};

	displayReport();

	return (
		<IonCard>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol sizeMd="4" size="8">
							<IonSelect
								interface="popover"
								name="activeReportSelect"
								multiple={false}
								cancelText="Cancel"
								okText="Okay"
								value={currentReport}
								placeholder="Select One"
								onIonChange={e => onReportChanged(e.detail.value)}
							>
								{reportIonSelectOptions}
							</IonSelect>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeMd="12" size="12">
							<Chart
								data={leadsByDayData}
								selectedChartType="line"
								chartsAvailable={['line']}
								height="400px"
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
