import { LicenseEntities } from '../../lead/store/collections/License';

export interface LicenseConfig {
	entities: LicenseEntities;
	activeId?: string;
}

export const licenseConfig: LicenseConfig = {
	entities: []
};
