import {
	EventContactCollection,
	EventContactCollectionState,
	EventContactId,
	EventContactEntity_Some,
	EventContactEntityPatch_Some,
	EventContactId_Some,
	eventContactCollectionDefault,
	eventContactCollectionMutateIdOpts
} from '../collections/EventContact';
import * as actions from '../actions/EventContact';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * EventContact reducer
 *
 * @export
 * @param {EventContactCollection} [state=eventcontactCollectionDefault]
 * @param {EventContactActionTypes} action
 * @returns
 */
export function eventContactReducer(
	state: EventContactCollection = eventContactCollectionDefault,
	action: actions.EventContactActionTypes
) {
	switch (action.type) {
		case actions.EVENTCONTACT_MUTATE_ID:
			return collectionReducerActions.mutateId<EventContactCollection>(
				state,
				action.payload,
				eventContactCollectionMutateIdOpts
			);
		case actions.EVENTCONTACT_SET:
			return collectionReducerActions.set<
				EventContactCollection,
				EventContactId
			>(state, action.payload);
		case actions.EVENTCONTACT_TOGGLE:
			return collectionReducerActions.toggle<
				EventContactCollection,
				EventContactId
			>(state, action.payload);
		case actions.EVENTCONTACT_SELECT:
			return collectionReducerActions.select<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_DESELECT:
			return collectionReducerActions.deselect<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_UPSERT:
			return collectionReducerActions.upsert<
				EventContactCollection,
				EventContactEntity_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_PATCH:
			return collectionReducerActions.patch<
				EventContactCollection,
				EventContactEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_CHANGE:
			return collectionReducerActions.change<
				EventContactCollection,
				EventContactEntityPatch_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_CACHE:
			return collectionReducerActions.cache<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_UNCACHE:
			return collectionReducerActions.uncache<
				EventContactCollection,
				EventContactId_Some
			>(state, action.payload);
		case actions.EVENTCONTACT_SETSTATE:
			return collectionReducerActions.setState<
				EventContactCollection,
				EventContactCollectionState
			>(state, action.payload.state);
		case actions.EVENTCONTACT_PATCHSTATE:
			return collectionReducerActions.patchState<
				EventContactCollection,
				EventContactCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
