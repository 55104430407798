import { Collection } from '../models';
/**
 * Default collection values
 *
 * @export
 * @constant
 */
export const collectionDefault: Collection = {
	byIds: {},
	allIds: [],
	selectedIds: [],
	activeId: undefined,
	mutation: {},
	cache: {},
	state: {
		byIds: {}
	}
};
