import { Localize } from '../../locales';
import { ListPagingConfigProps } from '../../../app/ui/components/ListPaging';

export type ListPagingConfig = Localize<ListPagingConfigProps>;

export const listPagingConfig: ListPagingConfig = {
	en: {
		placeholder: 'Search'
	},
	fr: {
		placeholder: 'Chercher'
	}
};
