import React, { useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonToolbar,
	IonSelect,
	IonSelectOption,
	IonItem,
	IonLabel,
	IonButton,
	IonText,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonIcon
} from '@ionic/react';

import { EntityListItem } from './EntityListItem';

import { SearchBar } from '../../../../app/ui/components/Search/SearchBar';
import { arrowBack, arrowForward } from 'ionicons/icons';

import './EntityList.css';
import { useCtx } from '../../../../config/hooks';
import { ObjectType, ObjectAny } from '../../../store';
import { useInterval } from '../../../../app/store';

export interface EntityListConfig {}

export interface EntityListProps {
	types: ObjectType[];
	edit: (entity: ObjectAny) => void;
}

export const EntityList: React.FC<EntityListProps> = props => {
	const [searchText, setSearchText] = useState('');
	const [sortBy, setsortBy] = useState('DateScanned');
	const [pageSize, setPageSize] = useState(15);
	const [pageSelected, setPageSelected] = useState(1);
	const [active, setActive] = useState(true);
	const ctx = useCtx<EntityListConfig>({}); // TO DO: create and set (EntityListConfig)
	const {
		config: [config]
	} = ctx;

	const read = (next: any) => {
		props.types?.forEach((type: ObjectType) => {
			switch (type) {
				case ObjectType.Context:
					//ctx.lead.context.readAll(ctx, undefined, next);
					break;
				case ObjectType.Event:
					//ctx.lead.event.readAll(ctx, undefined, next);
					break;
				case ObjectType.Order:
					//ctx.lead.order.readAll(ctx, undefined, next);
					break;
				case ObjectType.Service:
					//ctx.lead.service.readAll(ctx, undefined, next);
					break;
			}
		});
	};

	useInterval(
		(next: any, active: boolean) => {
			if (active) {
				read(next);
				return;
			}
			next();
		},
		60000,
		active
	);

	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	console.log('types');
	console.log(props.types);

	// Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	const sort = (a: any, b: any) => {
		if (sortBy == 'Name') return a.name > b.name ? 1 : -1;
		if (sortBy == 'Code') return a.code > b.code ? 1 : -1;
		if (sortBy == 'Created')
			return a.i_.created?.dt > a.i_.created?.dt ? 1 : -1;
		if (sortBy == 'Modified')
			return a.i_.modified?.dt > a.i_.modified?.dt ? 1 : -1;
		return 1;
	};

	const get = (): ObjectAny[] => {
		let entities: ObjectAny[] = [];
		props.types?.forEach((type: ObjectType) => {
			switch (type) {
				case ObjectType.Context:
					entities = [...entities, ...ctx.lead.context.all()];
					break;
				case ObjectType.Event:
					entities = [...entities, ...ctx.lead.event.all()];
					break;
				case ObjectType.Order:
					entities = [...entities, ...ctx.lead.order.all()];
					break;
				case ObjectType.Service:
					entities = [...entities, ...ctx.lead.service.all()];
					break;
			}
		});
		return entities;
	};

	let entities = get().filter((entity: any) => {
		if (props.types && props.types.indexOf(entity.i_.type as ObjectType) === -1)
			return false;
		let match = 0,
			matches = 0,
			keywords = searchText ?? '';
		for (let keyword of keywords.toLowerCase().split(' ')) {
			if (keyword === '') break;
			match++;
			if (
				entity.name?.toLowerCase().trim().indexOf(keyword) > -1 ||
				entity.code?.toLowerCase().trim().indexOf(keyword) > -1
			) {
				matches++;
			}
		}
		return match === matches;
	}) as ObjectAny[];

	let totalEntities = entities.length;

	const entityListItems = entities
		.sort(sort)
		.slice(pageSize * (pageSelected - 1), pageSize * pageSelected)
		.map((entity: ObjectAny, index: number, entityList: ObjectAny[]) => {
			return (
				<EntityListItem
					key={'entity-list-item-' + entity.id}
					entity={entity}
					edit={props.edit}
					showEntityType={props.types?.length > 1}
				/>
			);
		});

	const onSearch = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	const onSortChange = (e: any) => {
		setsortBy(e.detail?.value);
	};

	const onPageChange = (pageMovement: number) => {
		let newPage = pageSelected.valueOf() + pageMovement;
		let maxPage = Math.ceil(totalEntities / pageSize);
		setPageSelected(newPage < 1 ? 1 : newPage > maxPage ? maxPage : newPage);
	};

	const onPageSizeChanged = (e: any) => {
		setPageSize(e.detail?.value);
		setPageSelected(1);
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<SearchBar onChange={onSearch} />
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonToolbar>
						<IonGrid>
							<IonRow>
								<IonCol size="3">
									<IonItem>
										<IonText>
											<p>Sort By</p>
										</IonText>
										<IonSelect
											interface="popover"
											name="sortSelect"
											multiple={false}
											cancelText="Cancel"
											okText="Okay"
											value={sortBy}
											placeholder="Select One"
											onIonChange={onSortChange}
										>
											<IonSelectOption value="Created">Created</IonSelectOption>
											<IonSelectOption value="Modified">
												Modified
											</IonSelectOption>
											<IonSelectOption value="Name">Name</IonSelectOption>
											<IonSelectOption value="Code">Code</IonSelectOption>
											<IonSelectOption value="Rating">Rating</IonSelectOption>
										</IonSelect>
									</IonItem>
								</IonCol>
								<IonCol size="5"></IonCol>
								<IonCol size="2">
									<IonItem key="paging-top">
										<IonButton
											size="small"
											color="tertiary"
											onClick={() => {
												onPageChange(-1);
											}}
										>
											<IonIcon icon={arrowBack}></IonIcon>
										</IonButton>
										<IonLabel class="selectedPageNumber">
											{pageSelected} of {Math.ceil(totalEntities / pageSize)}
										</IonLabel>
										<IonButton
											size="small"
											color="tertiary"
											onClick={() => {
												onPageChange(1);
											}}
										>
											<IonIcon icon={arrowForward}></IonIcon>
										</IonButton>
									</IonItem>
								</IonCol>
								<IonCol size="2">
									<IonItem key="paging-top-size">
										<IonText>
											<p>Page Size</p>
										</IonText>
										<IonSelect
											interface="popover"
											name="pageSizeSelect"
											multiple={false}
											cancelText="Cancel"
											okText="Okay"
											value={pageSize}
											placeholder="Select One"
											onIonChange={onPageSizeChanged}
										>
											<IonSelectOption value={15}>15</IonSelectOption>
											<IonSelectOption value={25}>25</IonSelectOption>
											<IonSelectOption value={50}>50</IonSelectOption>
											<IonSelectOption value={100}>100</IonSelectOption>
										</IonSelect>
									</IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonToolbar>
					<IonList>{entityListItems}</IonList>
					<IonToolbar>
						<IonGrid>
							<IonRow>
								<IonCol size="8"></IonCol>
								<IonCol size="2">
									<IonItem key="paging-bottom">
										<IonButton
											size="small"
											color="tertiary"
											onClick={() => {
												onPageChange(-1);
											}}
										>
											<IonIcon icon={arrowBack}></IonIcon>
										</IonButton>
										<IonLabel class="selectedPageNumber">
											{pageSelected} of {Math.ceil(totalEntities / pageSize)}
										</IonLabel>
										<IonButton
											size="small"
											color="tertiary"
											onClick={() => {
												onPageChange(1);
											}}
										>
											<IonIcon icon={arrowForward}></IonIcon>
										</IonButton>
									</IonItem>
								</IonCol>
								<IonCol size="2">
									<IonItem key="paging-bottom-size">
										<IonText>
											<p>Page Size</p>
										</IonText>
										<IonSelect
											interface="popover"
											name="pageSizeSelect"
											multiple={false}
											cancelText="Cancel"
											okText="Okay"
											value={pageSize}
											placeholder="Select One"
											onIonChange={onPageSizeChanged}
										>
											<IonSelectOption value={15}>15</IonSelectOption>
											<IonSelectOption value={25}>25</IonSelectOption>
											<IonSelectOption value={50}>50</IonSelectOption>
											<IonSelectOption value={100}>100</IonSelectOption>
										</IonSelect>
									</IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonToolbar>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
