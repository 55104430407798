import {
	EventQuestionId_Some,
	EventQuestionEntity_Some,
	EventQuestionEntityPatch_Some,
	EventQuestionCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const EVENTQUESTION_MUTATE_ID = '@lead/EVENTQUESTION_MUTATE_ID';
export const EVENTQUESTION_SET = '@lead/EVENTQUESTION_SET';
export const EVENTQUESTION_TOGGLE = '@lead/EVENTQUESTION_TOGGLE';
export const EVENTQUESTION_SELECT = '@lead/EVENTQUESTION_SELECT';
export const EVENTQUESTION_DESELECT = '@lead/EVENTQUESTION_DESELECT';
export const EVENTQUESTION_UPSERT = '@lead/EVENTQUESTION_UPSERT';
export const EVENTQUESTION_PATCH = '@lead/EVENTQUESTION_PATCH';
export const EVENTQUESTION_CHANGE = '@lead/EVENTQUESTION_CHANGE';
export const EVENTQUESTION_APPLY_CHANGES = '@lead/EVENTQUESTION_APPLY_CHANGES';
export const EVENTQUESTION_CANCEL_CHANGES =
	'@lead/EVENTQUESTION_CANCEL_CHANGES';
export const EVENTQUESTION_CACHE = '@lead/EVENTQUESTION_CACHE';
export const EVENTQUESTION_UNCACHE = '@lead/EVENTQUESTION_UNCACHE';
export const EVENTQUESTION_SETSTATE = '@lead/EVENTQUESTION_SETSTATE';
export const EVENTQUESTION_PATCHSTATE = '@lead/EVENTQUESTION_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface EventQuestionAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface EventQuestionAction_MutateId extends EntityAction_MutateId {
	type: typeof EVENTQUESTION_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active eventquestion
 *
 * @interface EventQuestionAction_Set
 * @extends {EntityAction_Set}
 */
export interface EventQuestionAction_Set extends EntityAction_Set {
	type: typeof EVENTQUESTION_SET;
	payload?: EntityId;
}

/**
 * Toggle active eventquestion
 *
 * @interface EventQuestionAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface EventQuestionAction_Toggle extends EntityAction_Toggle {
	type: typeof EVENTQUESTION_TOGGLE;
	payload?: EntityId;
}

/**
 * Select eventquestion entities
 *
 * @interface EventQuestionAction_Select
 * @extends {EntityAction_Select}
 */
interface EventQuestionAction_Select extends EntityAction_Select {
	type: typeof EVENTQUESTION_SELECT;
	payload: EventQuestionId_Some;
}

/**
 * Deselect eventquestion entities
 *
 * @interface EventQuestionAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface EventQuestionAction_Deselect extends EntityAction_Deselect {
	type: typeof EVENTQUESTION_DESELECT;
	payload: EventQuestionId_Some;
}

/**
 * Upsert eventquestion entities
 *
 * @interface EventQuestionAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface EventQuestionAction_Upsert extends EntityAction_Upsert {
	type: typeof EVENTQUESTION_UPSERT;
	payload: EventQuestionEntity_Some;
}

/**
 * Patch eventquestion entities
 *
 * @interface EventQuestionAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface EventQuestionAction_Patch extends EntityAction_Patch {
	type: typeof EVENTQUESTION_PATCH;
	payload: EventQuestionEntityPatch_Some;
}

/**
 * Change eventquestion entities
 *
 * @interface EventQuestionAction_Change
 * @extends {EntityAction_Change}
 */
interface EventQuestionAction_Change extends EntityAction_Change {
	type: typeof EVENTQUESTION_CHANGE;
	payload: EventQuestionEntityPatch_Some;
}

/**
 * Apply changes to eventquestion entities
 *
 * @interface EventQuestionAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface EventQuestionAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof EVENTQUESTION_APPLY_CHANGES;
	payload: EventQuestionId_Some;
}

/**
 * Cancel changes to eventquestion entities
 *
 * @interface EventQuestionAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface EventQuestionAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof EVENTQUESTION_CANCEL_CHANGES;
	payload: EventQuestionId_Some;
}

/**
 * Cache eventquestion entities
 *
 * @interface EventQuestionAction_Cache
 * @extends {EntityAction_Cache}
 */
interface EventQuestionAction_Cache extends EntityAction_Cache {
	type: typeof EVENTQUESTION_CACHE;
	payload: EventQuestionId_Some;
}

/**
 * Uncache eventquestion entities
 *
 * @interface EventQuestionAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface EventQuestionAction_Uncache extends EntityAction_Uncache {
	type: typeof EVENTQUESTION_UNCACHE;
	payload: EventQuestionId_Some;
}

/**
 * Set eventquestion collection / entity state
 *
 * @interface EventQuestionAction_SetState
 * @extends {EntityAction_SetState}
 */
interface EventQuestionAction_SetState extends EntityAction_SetState {
	type: typeof EVENTQUESTION_SETSTATE;
}

/**
 * PAtch eventquestion collection / entity state
 *
 * @interface EventQuestionAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface EventQuestionAction_PatchState extends EntityAction_PatchState {
	type: typeof EVENTQUESTION_PATCHSTATE;
}

/**
 * Export eventquestion action types
 *
 * @export
 */
export type EventQuestionActionTypes =
	| EventQuestionAction_MutateId
	| EventQuestionAction_MutateId
	| EventQuestionAction_Set
	| EventQuestionAction_Toggle
	| EventQuestionAction_Select
	| EventQuestionAction_Deselect
	| EventQuestionAction_Upsert
	| EventQuestionAction_Patch
	| EventQuestionAction_Change
	| EventQuestionAction_ApplyChanges
	| EventQuestionAction_CancelChanges
	| EventQuestionAction_Cache
	| EventQuestionAction_Uncache
	| EventQuestionAction_SetState
	| EventQuestionAction_PatchState;

/**
 * EventQuestion actions helper interface
 *
 * @export
 * @interface IEventQuestionActions
 * @extends {EntityActions}
 */
export interface IEventQuestionActions extends EntityActions {
	//customAction(ids: EventQuestionId_Some): EventQuestionAction_CustomAction;
}

/**
 * EventQuestion actions helper
 *
 * @export
 * @class EventQuestionActions
 * @implements {EntityBaseActions}
 */
export class EventQuestionActions implements IEventQuestionActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): EventQuestionAction_MutateId {
		return { type: EVENTQUESTION_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): EventQuestionAction_Set {
		return { type: EVENTQUESTION_SET, payload: id };
	}
	toggle(id?: EntityId): EventQuestionAction_Toggle {
		return { type: EVENTQUESTION_TOGGLE, payload: id };
	}
	select(ids: EventQuestionId_Some): EventQuestionAction_Select {
		return {
			type: EVENTQUESTION_SELECT,
			payload: ids
		};
	}
	deselect(ids: EventQuestionId_Some): EventQuestionAction_Deselect {
		return {
			type: EVENTQUESTION_DESELECT,
			payload: ids
		};
	}
	upsert(entities: EventQuestionEntity_Some): EventQuestionAction_Upsert {
		return {
			type: EVENTQUESTION_UPSERT,
			payload: entities
		};
	}
	patch(entities: EventQuestionEntityPatch_Some): EventQuestionAction_Patch {
		return {
			type: EVENTQUESTION_PATCH,
			payload: entities
		};
	}
	change(entities: EventQuestionEntityPatch_Some): EventQuestionAction_Change {
		return {
			type: EVENTQUESTION_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: EventQuestionId_Some): EventQuestionAction_ApplyChanges {
		return {
			type: EVENTQUESTION_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: EventQuestionId_Some): EventQuestionAction_CancelChanges {
		return {
			type: EVENTQUESTION_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: EventQuestionId_Some): EventQuestionAction_Cache {
		return {
			type: EVENTQUESTION_CACHE,
			payload: ids
		};
	}
	uncache(ids: EventQuestionId_Some): EventQuestionAction_Uncache {
		return {
			type: EVENTQUESTION_UNCACHE,
			payload: ids
		};
	}
	setState(state: EventQuestionCollectionState): EventQuestionAction_SetState {
		return {
			type: EVENTQUESTION_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<EventQuestionCollectionState>
	): EventQuestionAction_PatchState {
		return {
			type: EVENTQUESTION_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the eventquestion actions helper
 *
 * @export
 */
export const eventQuestionActions = new EventQuestionActions();
