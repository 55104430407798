import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getRouteCollection,
	RouteId,
	RouteIds,
	RouteId_Some,
	RouteEntity,
	RouteEntities,
	RouteEntity_Some,
	RouteEntityPatch_Some,
	RouteCollection,
	RouteCollectionState,
	IRouteActions,
	routeActions,
	RouteActionTypes
} from '..';

/**
 * Route helper interface
 *
 * @export
 * @interface IRouteHelper
 * @extends {IEntityHelper}
 */
export interface IRouteHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
}

/**
 * Route helper options interface
 *
 * @export
 * @interface RouteHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface RouteHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const routeHelperOpts: RouteHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Route helper
 *
 * @export
 * @class RouteHelper
 * @extends {EntityHelper<RouteCollection, RouteActionTypes, RouteActions, RouteEntity, RouteEntities, RouteEntity_Some, RouteEntityPatch_Some, RouteId, RouteIds, RouteId_Some, RouteCollectionState, RouteHelperOpts>}
 * @implements {IRouteHelper}
 */
export class RouteHelper
	extends EntityHelper<
		RouteCollection,
		RouteActionTypes,
		IRouteActions,
		RouteEntity,
		RouteEntities,
		RouteEntity_Some,
		RouteEntityPatch_Some,
		RouteId,
		RouteIds,
		RouteId_Some,
		RouteCollectionState,
		RouteHelperOpts
	>
	implements IRouteHelper {
	constructor() {
		super(
			useSelector(getRouteCollection),
			routeActions,
			useDispatch(),
			routeHelperOpts
		);
		this.collection = useSelector(getRouteCollection);
		this.dispatch = useDispatch();
	}
}
