import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { userConfig } from '../../../config/app/User';
import { User } from '../models';

/**
 * User entity interface
 *
 * @export
 * @interface UserEntity
 * @extends {storage.Entity}
 * @extends {User}
 */
export interface UserEntity extends storage.Entity, User {}

/**
 * User entities array
 *
 * @export
 */
export type UserEntities = UserEntity[];

/**
 * Some user entities: one entity or an array of entities
 *
 * @export
 */
export type UserEntity_Some = UserEntity | UserEntities;

/**
 * User entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface UserEntityPatch
 * @extends {storage.EntityPatch<UserEntity>}
 */
export type UserEntityPatch = storage.EntityPatcher<UserEntity>;

/**
 * User entity patches array
 *
 * @export
 */
export type UserEntitiesPatch = UserEntityPatch[];

/**
 * Some user entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type UserEntityPatch_Some = UserEntityPatch | UserEntitiesPatch;

/**
 * User entity id
 *
 * @export
 */
export type UserId = storage.EntityId;

/**
 * User entity ids
 *
 * @export
 */
export type UserIds = storage.EntityIds;

/**
 * Some user entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type UserId_Some = UserId | UserIds;

/**
 * User entities object by id
 *
 * @export
 * @interface UserEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface UserEntitiesObject extends storage.EntitiesObject {
	[id: string]: UserEntity;
}

/**
 * User entity state
 *
 * @export
 * @interface UserEntityState
 * @extends {storage.EntityState}
 */
export interface UserEntityState extends storage.EntityState {}

/**
 * User store collection
 *
 * @export
 * @interface UserStoreCollection
 * @extends {storage.Store}
 */
export interface UserStoreCollection {
	user: UserCollection;
}

/**
 * User collection properties
 *
 * @export
 * @interface UserCollection
 * @extends {Collection}
 */
export interface UserCollection extends storage.Collection {
	byIds: UserEntitiesObject;
	mutation: UserEntitiesObject;
	cache: UserEntitiesObject;
	state: UserCollectionState;
	//customId: storage.EntityId;
	//customIds: storage.EntityIds;
	//customByIds: UserEntitiesObject;
}

/**
 * User collection state
 *
 * @export
 * @interface UserCollectionState
 * @extends {storage.CollectionState}
 */
export interface UserCollectionState extends storage.CollectionState {}

// custom user collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const userCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const userCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const userCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const userCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: userCollectionIdStringProps,
	idArrayProps: userCollectionIdArrayProps,
	idEntityProps: userCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * User collection default values, initialize collection with user entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const userCollectionDefault: UserCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as UserCollection),
		...{
			activeId: userConfig.activeId || userConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	userConfig.entities
);

/**
 * User store collection default values
 *
 * @export
 * @constant
 */
export const userStoreCollectionDefault: UserStoreCollection = {
	user: userCollectionDefault
};

/**
 * User store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getUserCollection = (state: any) => state.app.user;
