import React, { useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonList,
	IonItem,
	IonButton,
	IonLoading,
	IonIcon,
	IonCardTitle
} from '@ionic/react';

import {
	OrderCnxLeads,
	OrdersCnxLeads,
	isObjectStatusActive,
	OrderHelperActivateResults
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';
import { useToast } from '@agney/ir-toast';
import { sadOutline } from 'ionicons/icons';

export interface ImportOrdersConfigProps {
	cnxOrders?: OrdersCnxLeads;
}

export const ImportOrders: React.FC<ImportOrdersConfigProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		lead: { orderHelper, activeContext }
	} = ctx;

	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);

	const Toast = useToast();

	const activeOrders = orderHelper
		.all()
		.filter(isObjectStatusActive)
		.filter(o => o.contextId === activeContext?.id);

	const nonActiveEventOrders = props.cnxOrders?.filter(
		o => activeOrders.findIndex(ao => ao.code === o.code) < 0
	);

	const activateAll = async (orders: OrdersCnxLeads | undefined) => {
		setWorkingStatus('Activating...');
		orders?.forEach(order => {
			new Promise(resolve => setTimeout(resolve, 500)); //give operation time to complete
			activate(order);
		});
	};
	const activate = async (order: OrderCnxLeads) => {
		setWorkingStatus('Activating...');
		await orderHelper.activate(ctx, order.code, onError, onSuccessActivate);
	};

	const onSuccessActivate = (result: OrderHelperActivateResults) => {
		Toast.success('Order successfully activated!');
		setWorkingStatus(undefined);
	};

	const onError = (message: string) => {
		Toast.error(message);
		setWorkingStatus(undefined);
	};

	const nonActiveEventOrdersListItems = nonActiveEventOrders
		?.filter(o => {
			let match = 0,
				matches = 0,
				keywords = '';

			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(o.name && o.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.code && o.code?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.email && o.email?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.contactFirstName &&
						o.contactFirstName?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.contactLastName &&
						o.contactLastName?.toLowerCase().trim().indexOf(keyword) > -1)
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.sort((a: OrderCnxLeads, b: OrderCnxLeads) => {
			return (a.name?.toLowerCase() || '') > (b.name?.toLowerCase() || '')
				? 1
				: (b.name?.toLowerCase() || '') > (a.name?.toLowerCase() || '')
				? -1
				: 0;
		})
		.map((order: OrderCnxLeads) => {
			return (
				<IonItem key={order.id}>
					<IonGrid>
						<IonRow>
							<IonCol size="6">
								{order.code}
								<br />
								{order.name} <br />
								{order.contactFirstName} {order.contactLastName}
								<br />
								{order.email}
								<br />
								{moment(order.date).local().format('YYYY-MM-DD hh:mm A')}
							</IonCol>
							<IonCol size="3">
								<div hidden={(order.licenseQuantityLeadApi || 0) <= 0}>
									Lead Api : {order.licenseQuantityLeadApi}
								</div>
								<div hidden={(order.licenseQuantityLeadApp || 0) <= 0}>
									Lead App : {order.licenseQuantityLeadApp}
								</div>
								<div hidden={(order.licenseQuantityOpticon || 0) <= 0}>
									Opticon : {order.licenseQuantityOpticon}
								</div>
								<div hidden={(order.licenseQuantitySst || 0) <= 0}>
									SST : {order.licenseQuantitySst}
								</div>
							</IonCol>
							<IonCol>
								<IonButton
									size="small"
									onClick={() => {
										activate(order);
									}}
								>
									Import
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	return (
		<>
			<IonLoading
				isOpen={workingStatus !== undefined}
				message={`<h2>${workingStatus}</h2>`}
			/>
			<IonGrid>
				<IonRow hidden={(nonActiveEventOrders?.length || 0) <= 0}>
					<IonCol>
						<IonButton onClick={() => activateAll(nonActiveEventOrders)}>
							Import All Orders
						</IonButton>
					</IonCol>
				</IonRow>
				<IonRow hidden={(nonActiveEventOrders?.length || 0) <= 0}>
					<IonCol>
						<IonList>{nonActiveEventOrdersListItems}</IonList>
					</IonCol>
				</IonRow>
				<IonRow hidden={(nonActiveEventOrders?.length || 0) > 0}>
					<IonCol>
						<IonCard class="ion-text-center">
							<IonIcon icon={sadOutline} style={{ fontSize: '60px' }} />
							<IonCardHeader>
								<IonCardTitle>No new orders found</IonCardTitle>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};
