import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getContactCollection,
	ContactId,
	ContactIds,
	ContactId_Some,
	ContactEntity,
	ContactEntities,
	ContactEntity_Some,
	ContactEntityPatch_Some,
	ContactCollection,
	ContactCollectionState,
	IContactActions,
	contactActions,
	ContactActionTypes
} from '..';
import {
	readContacts,
	ReadContactsRequest,
	writeContacts,
	WriteContactsRequest,
	ReadContactsByIdRequest,
	readContactsById,
	ContactApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactOrganizationEntity,
	isContactIndividualEntity,
	isContactOrganizationEntity
} from '../collections';
import {
	ContactIndividual,
	isObjectStatusActive,
	ContactType
} from '../models';
import { request } from 'http';

/**
 * Contact helper interface
 *
 * @export
 * @interface IContactHelper
 * @extends {IEntityHelper}
 */
export interface IContactHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Contact helper options interface
 *
 * @export
 * @interface ContactHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ContactHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const contactHelperOpts: ContactHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Contact helper
 *
 * @export
 * @class ContactHelper
 * @extends {EntityHelper<ContactCollection, ContactActionTypes, ContactActions, ContactEntity, ContactEntities, ContactEntity_Some, ContactEntityPatch_Some, ContactId, ContactIds, ContactId_Some, ContactCollectionState, ContactHelperOpts>}
 * @implements {IContactHelper}
 */
export class ContactHelper
	extends EntityHelper<
		ContactCollection,
		ContactActionTypes,
		IContactActions,
		ContactEntity,
		ContactEntities,
		ContactEntity_Some,
		ContactEntityPatch_Some,
		ContactId,
		ContactIds,
		ContactId_Some,
		ContactCollectionState,
		ContactHelperOpts
	>
	implements IContactHelper {
	constructor() {
		super(
			useSelector(getContactCollection),
			contactActions,
			useDispatch(),
			contactHelperOpts
		);
		this.collection = useSelector(getContactCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: ContactApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadContactsRequest> & { ids: string[] },
		callback?: any
	): Promise<ContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadContactsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContactEntities = await readContacts(ctx, request)
			.then((entities: ContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readContactsById(
		ctx: UseCtx<any>,
		params: Partial<ReadContactsByIdRequest> & { ids: string[] },
		callback?: any
	): Promise<ContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadContactsByIdRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContactEntities = await readContactsById(ctx, request)
			.then((entities: ContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteContactsRequest> = {},
		callback?: any
	): Promise<ContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.contacts) return [];
		let request: WriteContactsRequest = {
			...params,
			...{
				contacts: params.contacts,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContactEntities = await writeContacts(ctx, request)
			.then((entities: ContactEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Individual(): ContactIndividual[] {
		return this.all()
			.filter(isContactIndividualEntity)
			.filter(isObjectStatusActive);
	}
	get_Individual(id: string): ContactIndividual | undefined {
		return this.all()
			.filter(isContactIndividualEntity)
			.find(c => c.id === id);
	}

	all_Organization(): ContactOrganizationEntity[] {
		return this.all()
			.filter(isContactOrganizationEntity)
			.filter(isObjectStatusActive);
	}
	get_Organization(id: string): ContactOrganizationEntity | undefined {
		return this.all()
			.filter(isContactOrganizationEntity)
			.find(c => c.id === id);
	}

	getNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getName(id ? this.get(id) : undefined, defaultName);
	}

	getName(contact?: ContactEntity, defaultName: string = 'Unknown'): string {
		return !contact
			? 'loading...'
			: isContactIndividualEntity(contact)
			? contact.firstName && contact.lastName
				? contact.firstName + ' ' + contact.lastName
				: contact.firstName || contact.lastName || defaultName
			: isContactOrganizationEntity(contact)
			? contact.name || defaultName
			: defaultName;
	}

	getOrganizationNameById(
		id?: string,
		defaultName: string = 'Unknown'
	): string {
		return this.getOrganizationName(id ? this.get(id) : undefined, defaultName);
	}

	getOrganizationName(
		contact?: ContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !contact
			? 'loading...'
			: isContactIndividualEntity(contact)
			? contact.organization || defaultName
			: isContactOrganizationEntity(contact)
			? contact.name || defaultName
			: defaultName;
	}

	getFirstNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getFirstName(id ? this.get(id) : undefined, defaultName);
	}

	getFirstName(
		contact?: ContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !contact
			? 'loading...'
			: isContactIndividualEntity(contact)
			? contact.firstName || defaultName
			: isContactOrganizationEntity(contact)
			? contact.name || defaultName
			: defaultName;
	}

	getLastNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getLastName(id ? this.get(id) : undefined, defaultName);
	}

	getLastName(
		contact?: ContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !contact
			? 'loading...'
			: isContactIndividualEntity(contact)
			? contact.lastName || defaultName
			: isContactOrganizationEntity(contact)
			? contact.name || defaultName
			: defaultName;
	}
}
