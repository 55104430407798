// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * DEVICE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { DeviceHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { DeviceEntity, DeviceEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { DeviceType } from '../models';

export enum DeviceApiOperation {
	readDevices = 'readDevices',
	readDevicesAll = 'readDevicesAll',
	writeDevices = 'writeDevices'
}

export interface ReadDevicesRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
}

export interface ReadDevicesAllRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	deviceType?: DeviceType;
	deviceUniqueId?: string;
	serial?: string;
	mac?: string;
	userIds?: string[];
}

export interface WriteDevicesRequest {
	userId: string;
	devices: DeviceEntities;
}

/**
 * Read devices
 */
export async function readDevices(
	ctx: UseCtx<any>,
	requestParameters: ReadDevicesRequest
): Promise<DeviceEntities> {
	const operationId = DeviceApiOperation.readDevices,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<DeviceEntities, ReadDevicesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: DeviceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<DeviceEntities, DeviceHelper>(
		ctx.lead.device,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.device.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read devices
 */
export async function readDevicesAll(
	ctx: UseCtx<any>,
	requestParameters: ReadDevicesAllRequest
): Promise<DeviceEntities> {
	const operationId = DeviceApiOperation.readDevicesAll,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<DeviceEntities, ReadDevicesAllRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: DeviceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<DeviceEntities, DeviceHelper>(
		ctx.lead.device,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.device.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Devices
 */
export async function writeDevices<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteDevicesRequest
): Promise<DeviceEntities> {
	const operationId = DeviceApiOperation.writeDevices,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.devices.forEach(
		(entity: DeviceEntity, i: number, a: DeviceEntities) => {
			a[i] = modifyEntity<DeviceEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<DeviceEntities, WriteDevicesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'devices'
	);

	let entities: DeviceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<DeviceEntities, DeviceHelper>(
		ctx.lead.device,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.device.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
