import React from 'react';

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonCardSubtitle,
	IonText,
	IonContent
} from '@ionic/react';

import { NewOrderCard, ActivateOrderCard } from '../containers/Order';
import { ActivateServiceCard } from '../containers/Service';
import qs from 'qs';

const ActivateView: React.FC = () => {
	let qsServiceId = qs.parse(window.location.search, {
		ignoreQueryPrefix: true
	}).serviceId;
	let qsContextId = qs.parse(window.location.search, {
		ignoreQueryPrefix: true
	}).contextId;
	let qsOrderCode = qs.parse(window.location.search, {
		ignoreQueryPrefix: true
	}).orderCode;

	let serviceId = typeof qsServiceId === 'string' ? qsServiceId : '',
		orderCode = typeof qsOrderCode === 'string' ? qsOrderCode : '',
		contextId = typeof qsContextId === 'string' ? qsContextId : '';

	return (
		<IonContent>
			<IonGrid>
				{orderCode && orderCode !== '' ? (
					<IonRow>
						<IonCol size="7" sizeMd="7">
							<ActivateOrderCard />
						</IonCol>
						<IonCol size="5" sizeMd="5">
							<NewOrderCard />
						</IonCol>
					</IonRow>
				) : serviceId && serviceId !== '' && contextId && contextId !== '' ? (
					<IonRow>
						<IonCol size="7" sizeMd="7">
							<ActivateServiceCard />
						</IonCol>
					</IonRow>
				) : (
					<IonText>Error</IonText>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default ActivateView;
