import { Localize, translations } from '../../locales';
import { EventSelectorConfigProps } from '../../../lead/ui/components/Event/EventSelector';

export type EventSelectorConfig = Localize<EventSelectorConfigProps>;

export const eventSelectorConfig: EventSelectorConfig = {
	en: {
		placeholder: 'Select One',
		okay: 'Okay',
		cancel: 'Cancel'
	},
	fr: {
		placeholder: 'Select One',
		okay: 'Okay',
		cancel: 'Cancel'
	}
};
