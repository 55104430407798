import { DataHelper } from '..';
import { DataEntity } from '../collections';

export interface DataHook {
	dataHelper: DataHelper;
	activeData: DataEntity | undefined;
}

export const useData = (): DataHook => {
	let dataHelper = new DataHelper();
	return {
		dataHelper,
		activeData: dataHelper.active()
	};
};
