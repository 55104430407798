import {
	DataId_Some,
	DataEntity_Some,
	DataEntityPatch_Some,
	DataCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const DATA_MUTATE_ID = '@lead/DATA_MUTATE_ID';
export const DATA_SET = '@lead/DATA_SET';
export const DATA_TOGGLE = '@lead/DATA_TOGGLE';
export const DATA_SELECT = '@lead/DATA_SELECT';
export const DATA_DESELECT = '@lead/DATA_DESELECT';
export const DATA_UPSERT = '@lead/DATA_UPSERT';
export const DATA_PATCH = '@lead/DATA_PATCH';
export const DATA_CHANGE = '@lead/DATA_CHANGE';
export const DATA_APPLY_CHANGES = '@lead/DATA_APPLY_CHANGES';
export const DATA_CANCEL_CHANGES = '@lead/DATA_CANCEL_CHANGES';
export const DATA_CACHE = '@lead/DATA_CACHE';
export const DATA_UNCACHE = '@lead/DATA_UNCACHE';
export const DATA_SETSTATE = '@lead/DATA_SETSTATE';
export const DATA_PATCHSTATE = '@lead/DATA_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface DataAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface DataAction_MutateId extends EntityAction_MutateId {
	type: typeof DATA_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active data
 *
 * @interface DataAction_Set
 * @extends {EntityAction_Set}
 */
export interface DataAction_Set extends EntityAction_Set {
	type: typeof DATA_SET;
	payload?: EntityId;
}

/**
 * Toggle active data
 *
 * @interface DataAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface DataAction_Toggle extends EntityAction_Toggle {
	type: typeof DATA_TOGGLE;
	payload?: EntityId;
}

/**
 * Select data entities
 *
 * @interface DataAction_Select
 * @extends {EntityAction_Select}
 */
interface DataAction_Select extends EntityAction_Select {
	type: typeof DATA_SELECT;
	payload: DataId_Some;
}

/**
 * Deselect data entities
 *
 * @interface DataAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface DataAction_Deselect extends EntityAction_Deselect {
	type: typeof DATA_DESELECT;
	payload: DataId_Some;
}

/**
 * Upsert data entities
 *
 * @interface DataAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface DataAction_Upsert extends EntityAction_Upsert {
	type: typeof DATA_UPSERT;
	payload: DataEntity_Some;
}

/**
 * Patch data entities
 *
 * @interface DataAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface DataAction_Patch extends EntityAction_Patch {
	type: typeof DATA_PATCH;
	payload: DataEntityPatch_Some;
}

/**
 * Change data entities
 *
 * @interface DataAction_Change
 * @extends {EntityAction_Change}
 */
interface DataAction_Change extends EntityAction_Change {
	type: typeof DATA_CHANGE;
	payload: DataEntityPatch_Some;
}

/**
 * Apply changes to data entities
 *
 * @interface DataAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface DataAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof DATA_APPLY_CHANGES;
	payload: DataId_Some;
}

/**
 * Cancel changes to data entities
 *
 * @interface DataAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface DataAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof DATA_CANCEL_CHANGES;
	payload: DataId_Some;
}

/**
 * Cache data entities
 *
 * @interface DataAction_Cache
 * @extends {EntityAction_Cache}
 */
interface DataAction_Cache extends EntityAction_Cache {
	type: typeof DATA_CACHE;
	payload: DataId_Some;
}

/**
 * Uncache data entities
 *
 * @interface DataAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface DataAction_Uncache extends EntityAction_Uncache {
	type: typeof DATA_UNCACHE;
	payload: DataId_Some;
}

/**
 * Set data collection / entity state
 *
 * @interface DataAction_SetState
 * @extends {EntityAction_SetState}
 */
interface DataAction_SetState extends EntityAction_SetState {
	type: typeof DATA_SETSTATE;
}

/**
 * PAtch data collection / entity state
 *
 * @interface DataAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface DataAction_PatchState extends EntityAction_PatchState {
	type: typeof DATA_PATCHSTATE;
}

/**
 * Export data action types
 *
 * @export
 */
export type DataActionTypes =
	| DataAction_MutateId
	| DataAction_MutateId
	| DataAction_Set
	| DataAction_Toggle
	| DataAction_Select
	| DataAction_Deselect
	| DataAction_Upsert
	| DataAction_Patch
	| DataAction_Change
	| DataAction_ApplyChanges
	| DataAction_CancelChanges
	| DataAction_Cache
	| DataAction_Uncache
	| DataAction_SetState
	| DataAction_PatchState;

/**
 * Data actions helper interface
 *
 * @export
 * @interface IDataActions
 * @extends {EntityActions}
 */
export interface IDataActions extends EntityActions {
	//customAction(ids: DataId_Some): DataAction_CustomAction;
}

/**
 * Data actions helper
 *
 * @export
 * @class DataActions
 * @implements {EntityBaseActions}
 */
export class DataActions implements IDataActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): DataAction_MutateId {
		return { type: DATA_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): DataAction_Set {
		return { type: DATA_SET, payload: id };
	}
	toggle(id?: EntityId): DataAction_Toggle {
		return { type: DATA_TOGGLE, payload: id };
	}
	select(ids: DataId_Some): DataAction_Select {
		return {
			type: DATA_SELECT,
			payload: ids
		};
	}
	deselect(ids: DataId_Some): DataAction_Deselect {
		return {
			type: DATA_DESELECT,
			payload: ids
		};
	}
	upsert(entities: DataEntity_Some): DataAction_Upsert {
		return {
			type: DATA_UPSERT,
			payload: entities
		};
	}
	patch(entities: DataEntityPatch_Some): DataAction_Patch {
		return {
			type: DATA_PATCH,
			payload: entities
		};
	}
	change(entities: DataEntityPatch_Some): DataAction_Change {
		return {
			type: DATA_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: DataId_Some): DataAction_ApplyChanges {
		return {
			type: DATA_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: DataId_Some): DataAction_CancelChanges {
		return {
			type: DATA_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: DataId_Some): DataAction_Cache {
		return {
			type: DATA_CACHE,
			payload: ids
		};
	}
	uncache(ids: DataId_Some): DataAction_Uncache {
		return {
			type: DATA_UNCACHE,
			payload: ids
		};
	}
	setState(state: DataCollectionState): DataAction_SetState {
		return {
			type: DATA_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<DataCollectionState>): DataAction_PatchState {
		return {
			type: DATA_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the data actions helper
 *
 * @export
 */
export const dataActions = new DataActions();
