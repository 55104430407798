import {
	ApiId_Some,
	ApiEntity_Some,
	ApiEntityPatch_Some,
	ApiCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const API_MUTATE_ID = '@app/API_MUTATE_ID';
export const API_SET = '@app/API_SET';
export const API_TOGGLE = '@app/API_TOGGLE';
export const API_SELECT = '@app/API_SELECT';
export const API_DESELECT = '@app/API_DESELECT';
export const API_UPSERT = '@app/API_UPSERT';
export const API_PATCH = '@app/API_PATCH';
export const API_CHANGE = '@app/API_CHANGE';
export const API_APPLY_CHANGES = '@app/API_APPLY_CHANGES';
export const API_CANCEL_CHANGES = '@app/API_CANCEL_CHANGES';
export const API_CACHE = '@app/API_CACHE';
export const API_UNCACHE = '@app/API_UNCACHE';
export const API_SETSTATE = '@app/API_SETSTATE';
export const API_PATCHSTATE = '@app/API_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ApiAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ApiAction_MutateId extends EntityAction_MutateId {
	type: typeof API_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active api
 *
 * @interface ApiAction_Set
 * @extends {EntityAction_Set}
 */
export interface ApiAction_Set extends EntityAction_Set {
	type: typeof API_SET;
	payload?: EntityId;
}

/**
 * Toggle active api
 *
 * @interface ApiAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ApiAction_Toggle extends EntityAction_Toggle {
	type: typeof API_TOGGLE;
	payload?: EntityId;
}

/**
 * Select api entities
 *
 * @interface ApiAction_Select
 * @extends {EntityAction_Select}
 */
interface ApiAction_Select extends EntityAction_Select {
	type: typeof API_SELECT;
	payload: ApiId_Some;
}

/**
 * Deselect api entities
 *
 * @interface ApiAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ApiAction_Deselect extends EntityAction_Deselect {
	type: typeof API_DESELECT;
	payload: ApiId_Some;
}

/**
 * Upsert api entities
 *
 * @interface ApiAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ApiAction_Upsert extends EntityAction_Upsert {
	type: typeof API_UPSERT;
	payload: ApiEntity_Some;
}

/**
 * Patch api entities
 *
 * @interface ApiAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ApiAction_Patch extends EntityAction_Patch {
	type: typeof API_PATCH;
	payload: ApiEntityPatch_Some;
}

/**
 * Change api entities
 *
 * @interface ApiAction_Change
 * @extends {EntityAction_Change}
 */
interface ApiAction_Change extends EntityAction_Change {
	type: typeof API_CHANGE;
	payload: ApiEntityPatch_Some;
}

/**
 * Apply changes to api entities
 *
 * @interface ApiAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ApiAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof API_APPLY_CHANGES;
	payload: ApiId_Some;
}

/**
 * Cancel changes to api entities
 *
 * @interface ApiAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ApiAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof API_CANCEL_CHANGES;
	payload: ApiId_Some;
}

/**
 * Cache api entities
 *
 * @interface ApiAction_Cache
 * @extends {EntityAction_Cache}
 */
interface ApiAction_Cache extends EntityAction_Cache {
	type: typeof API_CACHE;
	payload: ApiId_Some;
}

/**
 * Uncache api entities
 *
 * @interface ApiAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface ApiAction_Uncache extends EntityAction_Uncache {
	type: typeof API_UNCACHE;
	payload: ApiId_Some;
}

/**
 * Set api collection / entity state
 *
 * @interface ApiAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ApiAction_SetState extends EntityAction_SetState {
	type: typeof API_SETSTATE;
}

/**
 * PAtch api collection / entity state
 *
 * @interface ApiAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ApiAction_PatchState extends EntityAction_PatchState {
	type: typeof API_PATCHSTATE;
}

/**
 * Export api action types
 *
 * @export
 */
export type ApiActionTypes =
	| ApiAction_MutateId
	| ApiAction_MutateId
	| ApiAction_Set
	| ApiAction_Toggle
	| ApiAction_Select
	| ApiAction_Deselect
	| ApiAction_Upsert
	| ApiAction_Patch
	| ApiAction_Change
	| ApiAction_ApplyChanges
	| ApiAction_CancelChanges
	| ApiAction_Cache
	| ApiAction_Uncache
	| ApiAction_SetState
	| ApiAction_PatchState;

/**
 * Api actions helper interface
 *
 * @export
 * @interface IApiActions
 * @extends {EntityActions}
 */
export interface IApiActions extends EntityActions {
	//customAction(ids: ApiId_Some): ApiAction_CustomAction;
}

/**
 * Api actions helper
 *
 * @export
 * @class ApiActions
 * @implements {EntityBaseActions}
 */
export class ApiActions implements IApiActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ApiAction_MutateId {
		return { type: API_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ApiAction_Set {
		return { type: API_SET, payload: id };
	}
	toggle(id?: EntityId): ApiAction_Toggle {
		return { type: API_TOGGLE, payload: id };
	}
	select(ids: ApiId_Some): ApiAction_Select {
		return {
			type: API_SELECT,
			payload: ids
		};
	}
	deselect(ids: ApiId_Some): ApiAction_Deselect {
		return {
			type: API_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ApiEntity_Some): ApiAction_Upsert {
		return {
			type: API_UPSERT,
			payload: entities
		};
	}
	patch(entities: ApiEntityPatch_Some): ApiAction_Patch {
		return {
			type: API_PATCH,
			payload: entities
		};
	}
	change(entities: ApiEntityPatch_Some): ApiAction_Change {
		return {
			type: API_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ApiId_Some): ApiAction_ApplyChanges {
		return {
			type: API_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ApiId_Some): ApiAction_CancelChanges {
		return {
			type: API_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: ApiId_Some): ApiAction_Cache {
		return {
			type: API_CACHE,
			payload: ids
		};
	}
	uncache(ids: ApiId_Some): ApiAction_Uncache {
		return {
			type: API_UNCACHE,
			payload: ids
		};
	}
	setState(state: ApiCollectionState): ApiAction_SetState {
		return {
			type: API_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ApiCollectionState>,
		idOrIds?: ApiId_Some
	): ApiAction_PatchState {
		return {
			type: API_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the api actions helper
 *
 * @export
 */
export const apiActions = new ApiActions();
