import { OpenAPIObject } from './OpenApi3';

export interface Api {
	id: string;
	key: string;
	name: string;
	url: string;
	auth: ApiAuth;
	requestToken?: ApiRequestToken;
	definition: string;
}

export enum ApiAuthTypes {
	NoAuth = 'NoAuth',
	UserToken = 'UserToken',
	ClientCredentials = 'ClientCredentials',
	BearerToken = 'BearerToken'
}

export interface ApiAuthNoAuth {
	type: ApiAuthTypes.NoAuth;
}

export interface ApiAuthUserToken {
	type: ApiAuthTypes.UserToken;
}

export interface ApiAuthClientCredentials {
	type: ApiAuthTypes.ClientCredentials;
	authUrl: string;
	clientId: string;
	clientSecret: string;
	audience: string;
	token?: string;
	expiresAt?: number;
}

export interface ApiAuthBearerToken {
	type: ApiAuthTypes.BearerToken;
	token: string;
}

export type ApiAuth =
	| ApiAuthNoAuth
	| ApiAuthUserToken
	| ApiAuthClientCredentials
	| ApiAuthBearerToken;

export interface ApiRequestToken {
	header: string;
	operationId: string;
}
