// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * ORDER: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { OrderHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { OrderEntity, OrderEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { OrderCnxLeads, OrdersCnxLeads } from '../models';

export enum OrderApiOperation {
	readOrders = 'readOrders',
	readOrderByCode = 'readOrderByCode',
	readOrdersAll = 'readOrdersAll',
	writeOrders = 'writeOrders',
	readCnxLeadsOrderByCode = 'readCnxLeadsOrderByCode',
	readCnxLeadOrders = 'readCnxLeadOrders'
}

export interface ReadOrdersRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
}

export interface ReadOrdersAllRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	contextId?: string;
	ids?: string[];
}

export interface ReadOrderByCodeRequest {
	userId: string;
	code: string;
}

export interface WriteOrdersRequest {
	userId: string;
	orders: OrderEntities;
}

export interface ReadCnxLeadsOrderByCodeRequest {
	userId: string;
	code: string;
}

export interface ReadCnxLeadsOrdersRequest {
	userId: string;
	eventId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
}

/**
 * Read orders
 */
export async function readOrders(
	ctx: UseCtx<any>,
	requestParameters: ReadOrdersRequest
): Promise<OrderEntities> {
	const operationId = OrderApiOperation.readOrders,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<OrderEntities, ReadOrdersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: OrderEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<OrderEntities, OrderHelper>(
		ctx.lead.order,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.order.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

export async function readOrdersAll(
	ctx: UseCtx<any>,
	requestParameters: ReadOrdersRequest
): Promise<OrderEntities> {
	const operationId = OrderApiOperation.readOrdersAll,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<OrderEntities, ReadOrdersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: OrderEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<OrderEntities, OrderHelper>(
		ctx.lead.order,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.order.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read orders
 */
export async function readOrderByCode(
	ctx: UseCtx<any>,
	requestParameters: ReadOrderByCodeRequest
): Promise<OrderEntity | undefined> {
	const operationId = OrderApiOperation.readOrderByCode,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<OrderEntity, ReadOrderByCodeRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entity: OrderEntity | undefined = response.response.data;
	if (entity) {
		// patch entities states with an api success data for the operation
		setEntitiesApiOperationState<OrderEntities, OrderHelper>(
			ctx.lead.order,
			[entity],
			operationId,
			requestId,
			State_ApiOperationContextTypes.Success,
			attemptDate
		);

		// upsert the entities to the store
		ctx.lead.order.upsert(entity);
	}

	// return the entities to the caller for direct reference
	return entity;
}

/**
 * Write Orders
 */
export async function writeOrders<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteOrdersRequest
): Promise<OrderEntities> {
	const operationId = OrderApiOperation.writeOrders,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.orders.forEach(
		(entity: OrderEntity, i: number, a: OrderEntities) => {
			a[i] = modifyEntity<OrderEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<OrderEntities, WriteOrdersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'orders'
	);

	let entities: OrderEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<OrderEntities, OrderHelper>(
		ctx.lead.order,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.order.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read cnx leads orders
 */
export async function readCnxLeadsOrderByCode(
	ctx: UseCtx<any>,
	requestParameters: ReadCnxLeadsOrderByCodeRequest
): Promise<OrderCnxLeads | undefined> {
	const operationId = OrderApiOperation.readCnxLeadsOrderByCode;

	// make the api request
	const response = await apiRequest<
		OrderCnxLeads,
		ReadCnxLeadsOrderByCodeRequest
	>(ctx, config.apiId, operationId, requestParameters);

	return response.response.data;
}

/**
 * Read cnx leads orders
 */
export async function readCnxLeadsOrders(
	ctx: UseCtx<any>,
	requestParameters: ReadCnxLeadsOrdersRequest
): Promise<OrdersCnxLeads> {
	const operationId = OrderApiOperation.readCnxLeadOrders;

	// make the api request
	const response = await apiRequest<OrdersCnxLeads, ReadCnxLeadsOrdersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	return response.response.data;
}
