import React, { useState } from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonCardTitle,
	IonRouterLink
} from '@ionic/react';

import {
	User,
	LeadEntity,
	LeadEntities,
	ContactEntity,
	ServiceEntity,
	EventEntity
} from '../../../store';

import moment from 'moment';
import { useCtx } from '../../../../config/hooks';
import { Chart } from '../../../../app/ui/components/Chart';
import { EntityIds } from '../../../../storage';

export interface SummaryReportConfigProps {
	service?: ServiceEntity;
	event?: EventEntity;
	user?: User;
	title?: string;
	finish?: any;
	goToReport: (reportName: string) => void;
}

export const SummaryReport: React.FC<SummaryReportConfigProps> = props => {
	const [active, setActive] = useState(true);
	const {
		config: [config],
		lead: {
			activeService,
			activeEvent,
			leadHelper,
			contactHelper,
			userHelper,
			responseHelper,
			qualifierHelper
		}
	} = useCtx<SummaryReportConfigProps>({});

	// Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	const sortLeadsByDate = (a: LeadEntity, b: LeadEntity) => {
		return moment(a.i_?.created?.dt).isBefore(moment(b.i_?.created?.dt))
			? 1
			: 0;
	};

	const allLeads: LeadEntities =
		!activeService || !activeEvent
			? []
			: leadHelper.allByServiceIdAndEventIds(activeService.id, [
					activeEvent.id
			  ]);

	//All Leads Count
	const totalLeads = allLeads.length;

	//Last Lead Data
	const lastLead: LeadEntity | undefined = allLeads.sort(sortLeadsByDate)[0];
	const lastLeadContact: ContactEntity | undefined =
		lastLead != undefined ? contactHelper.get(lastLead.contactId) : undefined;

	//Top Performers Data
	const topPerformersData: Object[] = [];
	const activeUserIds = allLeads.map(lead => lead.i_.created?.by || '');
	userHelper.gets(activeUserIds).forEach(user => {
		const leadCountByUser =
			allLeads.filter(lead => lead.i_.created?.by === user.id).length || 0;
		topPerformersData.push({
			id: user.firstName + ' ' + user.lastName,
			value: leadCountByUser
		});
	});

	//Licenses Usage
	const activeLicensesData: Object[] = [
		{ id: 'Active', value: 3 },
		{ id: 'Not Active', value: 5 }
	];

	//Qualifier Data
	const quilifiersData: Object[] = [];
	const allQualifiers = qualifierHelper.allByServiceIdAndEventIds(
		props.service?.id || '',
		[props.event?.id || '']
	);

	allQualifiers.forEach(qualifier => {
		const qualifierAnswerCountByQualifier =
			responseHelper.allByQualifier(qualifier).filter((resp, index, self) => {
				return (
					self.findIndex(value => {
						return value.leadId === resp.leadId && resp.value === 'true';
					}) === index
				);
			}).length || 0;
		quilifiersData.push({
			id: qualifier.name,
			value: qualifierAnswerCountByQualifier
		});
	});

	return (
		<IonGrid>
			<IonRow>
				<IonCol sizeMd="4" size="6">
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Total Leads</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonRouterLink routerLink="/lead-service/leads">
								{totalLeads}
							</IonRouterLink>
						</IonCardContent>
					</IonCard>
				</IonCol>
				<IonCol sizeMd="6" size="6">
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Latest Lead</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonRouterLink
								onClick={() => {
									leadHelper.set(lastLead?.id);
								}}
								routerLink="/lead-service/lead"
							>
								{lastLead
									? lastLeadContact
										? lastLeadContact.name +
										  ' - ' +
										  moment(lastLead.i_.created?.dt).format(
												'dddd Do MMMM, YYYY'
										  )
										: '' +
										  moment(lastLead.i_.created?.dt).format(
												'dddd Do MMMM, YYYY'
										  )
									: 'No Leads Found'}
							</IonRouterLink>
						</IonCardContent>
					</IonCard>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol sizeMd="12" size="12">
					<IonCard>
						<IonCardContent>
							<Chart
								title="Top Representatives"
								data={topPerformersData}
								selectedChartType="bar"
								chartsAvailable={['pie', 'bar']}
								height="400px"
							/>
							<IonButton
								size="small"
								onClick={() => {
									props.goToReport('Representative');
								}}
							>
								View More
							</IonButton>
						</IonCardContent>
					</IonCard>
				</IonCol>
				<IonCol sizeMd="12" size="12">
					<IonCard>
						<IonCardContent>
							<Chart
								title="Qualifier Usage"
								data={quilifiersData}
								selectedChartType="bar"
								chartsAvailable={['pie', 'bar']}
								height="400px"
							/>
							<IonButton
								size="small"
								onClick={() => {
									props.goToReport('Qualifiers');
								}}
							>
								View More
							</IonButton>
						</IonCardContent>
					</IonCard>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
