import React, { useEffect, useState } from 'react';

//Data
import { eventCardConfig } from '../../../../config/lead/components/EventCard';
import { useLocaleConfig } from '../../../../app/store';
import { EventEntities, EventEntity, ContextEntity } from '../../../store';

//Lib
import moment from 'moment';

//UI
import {
	IonButton,
	IonButtons,
	IonImg,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonToolbar,
	IonIcon,
	IonList,
	IonListHeader,
	IonItem,
	IonLabel
} from '@ionic/react';
import * as icons from 'ionicons/icons';

// Style
import '../../styles/card.css';
export interface EventCardConfigProps {
	label?: {
		information?: string;
		totalLeads?: string;
		devicesUsed?: string;
		devicesSeparator?: string;
	};
	buttonText?: {
		viewEvent?: string;
	};
}

export interface EventCardProps {
	context: ContextEntity;
	events: EventEntities;
	totalLeads?: Number;
	totalAppLicenses?: Number;
	totalAPILicenses?: Number;
	totalSSTLicenses?: Number;
	totalOpticonLicenses?: Number;
	onContextSelected?: (selectedContextId: string) => void;
}

export const EventCard: React.FC<EventCardProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<EventCardConfigProps>(eventCardConfig);

	const selectContext = () => {
		if (props.onContextSelected !== undefined) {
			props.onContextSelected(props.context.id);
		}
	};
	const mainEvent: EventEntity | undefined = props.events[0];
	return (
		<IonCard className="event-card">
			<IonCardHeader>
				<IonToolbar>
					{moment(mainEvent?.start).format('dddd MMMM Do, YYYY')}
					<br />
					{mainEvent?.location}
					<IonButtons slot="end">
						<IonButton routerLink="/lead-service/leads" onClick={selectContext}>
							<span className="ion-hide-sm-up">
								{config.buttonText?.viewEvent}
							</span>
							<IonIcon
								className="ion-hide-sm-up"
								slot="icon-only"
								icon={icons.enterOutline}
							></IonIcon>
							<IonIcon
								className="ion-hide-sm-down"
								icon={icons.enterOutline}
							></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonImg
					hidden={!mainEvent?.header?.OriginalFilePath}
					src={mainEvent?.header?.OriginalFilePath}
				/>
			</IonCardHeader>
			<IonCardContent>
				<IonList lines="none">
					<IonListHeader>
						<h1>Event Summary</h1>
					</IonListHeader>
					{/* Leads */}
					<IonItem
						button
						routerLink="/lead-service/leads"
						onClick={selectContext}
					>
						<IonLabel>Leads: {props.totalLeads || 0}</IonLabel>
					</IonItem>
					{/* APP Licenses */}
					<IonItem
						button
						routerLink="/lead-service/licenses"
						hidden={(props.totalAppLicenses || 0) <= 0}
						onClick={selectContext}
					>
						<IonLabel> App Licenses: {props.totalAppLicenses}</IonLabel>
					</IonItem>
					{/* API Licenses */}
					<IonItem
						button
						routerLink="/lead-service/licenses"
						hidden={(props.totalAPILicenses || 0) <= 0}
						onClick={selectContext}
					>
						<IonLabel> API Licenses: {props.totalAPILicenses}</IonLabel>
					</IonItem>
					{/* SST Licenses */}
					<IonItem
						button
						routerLink="/lead-service/licenses"
						hidden={(props.totalSSTLicenses || 0) <= 0}
						onClick={selectContext}
					>
						<IonLabel> SST Licenses: {props.totalSSTLicenses}</IonLabel>
					</IonItem>
					{/* Opticon Licenses */}
					<IonItem
						button
						routerLink="/lead-service/licenses"
						hidden={(props.totalOpticonLicenses || 0) <= 0}
						onClick={selectContext}
					>
						<IonLabel> Opticon Licenses: {props.totalOpticonLicenses}</IonLabel>
					</IonItem>
				</IonList>
			</IonCardContent>
		</IonCard>
	);
};
