import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getDataCollection,
	DataId,
	DataIds,
	DataId_Some,
	DataEntity,
	DataEntities,
	DataEntity_Some,
	DataEntityPatch_Some,
	DataCollection,
	DataCollectionState,
	IDataActions,
	dataActions,
	DataActionTypes,
	DataResult,
	DataResultStatusType,
	DataResultType,
	DataType
} from '..';
import {
	readDatas,
	ReadDatasRequest,
	WriteDatasRequest,
	writeDatas,
	DataApiOperation
} from '../apis';
import { ObjectType } from '../../store';
import { newEntity } from '../../../app/utils';
import { UseCtx } from '../../../config/hooks';
import _ from 'lodash';
import uuid from '../../../app/utils/uuid';
import {
	ContextEntities,
	ContextEntity,
	EventEntities,
	EventEntity,
	LicenseEntities
} from '../collections';
import moment from 'moment';
import { isObjectStatusActive, ObjectIds } from '../models';
import { Switch } from 'react-router';

/**
 * Data helper interface
 *
 * @export
 * @interface IDataHelper
 * @extends {IEntityHelper}
 */
export interface IDataHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Data helper options interface
 *
 * @export
 * @interface DataHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface DataHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const dataHelperOpts: DataHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

export interface DataEntityFilter {
	keyWords?: string;
	eventIds?: string[];
	contactIds?: string[];
	contextIds?: string[];
}

/**
 * Data helper
 *
 * @export
 * @class DataHelper
 * @extends {EntityHelper<DataCollection, DataActionTypes, DataActions, DataEntity, DataEntities, DataEntity_Some, DataEntityPatch_Some, DataId, DataIds, DataId_Some, DataCollectionState, DataHelperOpts>}
 * @implements {IDataHelper}
 */
export class DataHelper
	extends EntityHelper<
		DataCollection,
		DataActionTypes,
		IDataActions,
		DataEntity,
		DataEntities,
		DataEntity_Some,
		DataEntityPatch_Some,
		DataId,
		DataIds,
		DataId_Some,
		DataCollectionState,
		DataHelperOpts
	>
	implements IDataHelper {
	constructor() {
		super(
			useSelector(getDataCollection),
			dataActions,
			useDispatch(),
			dataHelperOpts
		);
		this.collection = useSelector(getDataCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: DataApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadDatasRequest> = {},
		callback?: any
	): Promise<DataEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(DataApiOperation.readDatas);

		let request: ReadDatasRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: DataEntities = await readDatas(ctx, request)
			.then((entities: DataEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteDatasRequest> = {},
		callback?: any
	): Promise<DataEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.datas) return [];
		let request: WriteDatasRequest = {
			...params,
			...{
				datas: params.datas,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: DataEntities = await writeDatas(ctx, request)
			.then((entities: DataEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByContextId(contextId: string, type: DataType): DataEntities {
		return this.all()
			.filter(data => data.contextId === contextId && data.type === type)
			.filter(isObjectStatusActive);
	}
	allBySourceDataId(sourceDataId: string, type?: DataType): DataEntities {
		if (!type) type = DataType.Upload;

		return this.all()
			.filter(data => data.sourceDataId === sourceDataId && data.type === type)
			.filter(isObjectStatusActive);
	}

	parseOpticonDataFile = (ctx: UseCtx<any>, data: DataEntity) => {
		let dataValues = data.value?.split(/\r?\n/);
		let dataArray: DataEntities = [];
		let contextId = ctx.lead.activeContext?.id;

		//Update Data Object
		data.values = dataValues?.map(s => {
			return { data: s };
		});
		data.name = 'scanner-download-' + moment().utc().toISOString();
		data.contextId = contextId;

		let newDataObj: DataEntity = {
			...newEntity(ctx, ObjectType.Data),
			type: DataType.Upload,
			name: 'scanner-Upload-' + moment().utc().toISOString(),
			contextId: contextId
		};

		dataValues?.forEach(line => {
			let lineData = line.split(',');
			let scannedData = (lineData[0] || '').trim();
			let scannedDate = (lineData[1] || '').trim();
			let scannedTime = (lineData[2] || '').trim();
			let scannedDateTime = moment(scannedDate + ' ' + scannedTime);

			if (scannedData && scannedData.trim() !== '') {
				if (scannedData.indexOf('S%') === 0) {
					let serviceId = uuid().decompress(scannedData.replace('S%', ''));
					if (serviceId != newDataObj.serviceId) {
						if (newDataObj.results && newDataObj.results.length > 0) {
							dataArray.push(newDataObj);
						}
						newDataObj = {
							...newEntity(ctx, ObjectType.Data),
							type: DataType.Upload,
							serviceId: serviceId,
							contextId: contextId,
							sourceDataId: data.id,
							name: 'scanner-Upload-' + moment().utc().toISOString(),
							results: []
						};

						newDataObj?.results?.push({
							id: uuid().generate(),
							refId: uuid().decompress(scannedData.replace('S%', '')),
							type: ObjectType.Service,
							status: DataResultStatusType.Queued,
							dt: scannedDateTime.toISOString(),
							msg: ''
						});
					}
				} else if (scannedData.indexOf('Q%') === 0) {
					newDataObj?.results?.push({
						id: uuid().generate(),
						refId: uuid().decompress(scannedData.replace('Q%', '')),
						type: ObjectType.QualifierValue,
						status: DataResultStatusType.Queued,
						dt: scannedDateTime.toISOString(),
						msg: ''
					});
				} else {
					newDataObj?.results?.push({
						id: uuid().generate(),
						refId: scannedData.replace(/V|R/, ''),
						type: ObjectType.ContactRegistrationType,
						status: DataResultStatusType.Queued,
						dt: scannedDateTime.toISOString(),
						msg: ''
					});
				}
			}
		});
		if (newDataObj.results && newDataObj.results.length > 0) {
			dataArray.push(newDataObj);
		}

		return dataArray;
	};
}
