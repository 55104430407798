import { UserEntities } from '../../lead/store/collections/User';

export interface UserConfig {
	security: {
		default: string[];
	};

	entities: UserEntities;
	activeId?: string;
}

export const userConfig: UserConfig = {
	security: {
		default: ['user']
	},
	entities: []
};
