import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { qualifierConfig } from '../../../config/lead/Qualifier';
import { Qualifier } from '../models';

/**
 * Qualifier entity interface
 *
 * @export
 * @interface QualifierEntity
 * @extends {storage.Entity}
 */
export interface QualifierEntity extends storage.Entity, Qualifier {}

/**
 * Qualifier entities array
 *
 * @export
 */
export type QualifierEntities = QualifierEntity[];

/**
 * Some qualifier entities: one entity or an array of entities
 *
 * @export
 */
export type QualifierEntity_Some = QualifierEntity | QualifierEntities;

/**
 * Qualifier entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface QualifierEntityPatch
 * @extends {storage.EntityPatch<QualifierEntity>}
 */
export type QualifierEntityPatch = storage.EntityPatcher<QualifierEntity>;

/**
 * Qualifier entity patches array
 *
 * @export
 */
export type QualifierEntitiesPatch = QualifierEntityPatch[];

/**
 * Some qualifier entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type QualifierEntityPatch_Some =
	| QualifierEntityPatch
	| QualifierEntitiesPatch;

/**
 * Qualifier entity id
 *
 * @export
 */
export type QualifierId = storage.EntityId;

/**
 * Qualifier entity ids
 *
 * @export
 */
export type QualifierIds = storage.EntityIds;

/**
 * Some qualifier entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type QualifierId_Some = QualifierId | QualifierIds;

/**
 * Qualifier entities object by id
 *
 * @export
 * @interface QualifierEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface QualifierEntitiesObject extends storage.EntitiesObject {
	[id: string]: QualifierEntity;
}

/**
 * Qualifier entity state
 *
 * @export
 * @interface QualifierEntityState
 * @extends {storage.EntityState}
 */
export interface QualifierEntityState extends storage.EntityState {}

/**
 * Qualifier store collection
 *
 * @export
 * @interface QualifierStoreCollection
 * @extends {storage.Store}
 */
export interface QualifierStoreCollection extends storage.Store {
	qualifier: QualifierCollection;
}

/**
 * Qualifier collection properties
 *
 * @export
 * @interface QualifierCollection
 * @extends {Collection}
 */
export interface QualifierCollection extends storage.Collection {
	byIds: QualifierEntitiesObject;
	mutation: QualifierEntitiesObject;
	cache: QualifierEntitiesObject;
	state: QualifierCollectionState;
}

/**
 * Qualifier collection state
 *
 * @export
 * @interface QualifierCollectionState
 * @extends {storage.CollectionState}
 */
export interface QualifierCollectionState extends storage.CollectionState {}

// custom qualifier collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const qualifierCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const qualifierCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const qualifierCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const qualifierCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: qualifierCollectionIdStringProps,
	idArrayProps: qualifierCollectionIdArrayProps,
	idEntityProps: qualifierCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Qualifier collection default values, initialize collection with qualifier entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const qualifierCollectionDefault: QualifierCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as QualifierCollection),
		...{
			activeId: qualifierConfig.activeId || qualifierConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	qualifierConfig.entities
);

/**
 * Qualifier store collection default values
 *
 * @export
 * @constant
 */
export const qualifierStoreCollectionDefault: QualifierStoreCollection = {
	qualifier: qualifierCollectionDefault
};

/**
 * Qualifier store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getQualifierCollection = (state: any) => state.lead.qualifier;
