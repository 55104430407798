import React, { useEffect } from 'react';
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonLabel,
	IonText,
	IonItem
} from '@ionic/react';

import {
	EventContactEntity,
	DataFields,
	isContactIndividualEntity,
	isObjectStatusActive,
	readEventContactAnswers
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

export interface LeadContactCardProps {
	contact?: EventContactEntity;
}

export const LeadContactCard: React.FC<LeadContactCardProps> = props => {
	const ctx = useCtx<LeadContactCardProps>({}); // TO DO: create and set (qualifierListConfig)
	const {
		lead: { contactHelper }
	} = ctx;

	const leadDataFields: DataFields =
		ctx.lead.context.active()?.leadDataFields || [];
	const eventQuestions = ctx.lead.eventQuestion.allActive(ctx);

	useEffect(() => {
		let userId = ctx.app.user.active()?.userId;
		if (props.contact && !props.contact.answers && userId) {
			readEventContactAnswers(ctx, {
				userId,
				eventId: props.contact.eventId,
				contactId: props.contact.id
			});
		}
	}, [props.contact]);

	const contact = props.contact;

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>
					{contactHelper.getName(contact, 'Loading...')}
				</IonCardTitle>
			</IonCardHeader>
			{contact && (
				<IonCardContent>
					<h2>Contact Information</h2>
					{isContactIndividualEntity(contact) && contact.designation && (
						<IonItem>
							<IonLabel>Designation:</IonLabel>
							<IonText>{contact?.designation}</IonText>
						</IonItem>
					)}
					{isContactIndividualEntity(contact) && (
						<IonItem>
							<IonLabel>Company:</IonLabel>
							<IonText>{contact?.organization}</IonText>
						</IonItem>
					)}
					{isContactIndividualEntity(contact) && (
						<IonItem>
							<IonLabel>Title:</IonLabel>
							<IonText>{contact?.title}</IonText>
						</IonItem>
					)}
					<IonItem>
						<IonLabel>Address:</IonLabel>
						<IonText>
							{contact?.address1} {contact?.address2} {contact?.address3}
							<br /> {contact?.city ? contact?.city + ',' : ''}{' '}
							{contact?.subdivision} {contact?.country}
							<br /> {contact?.postal}
						</IonText>
					</IonItem>
					<IonItem>
						<IonLabel>Phone:</IonLabel>
						<IonText>{contact?.phone}</IonText>
					</IonItem>
					{isContactIndividualEntity(contact) && (
						<IonItem>
							<IonLabel>Mobile:</IonLabel>
							<IonText>{contact?.mobile}</IonText>
						</IonItem>
					)}
					<IonItem>
						<IonLabel>Email:</IonLabel>
						<IonText>{contact?.email}</IonText>
					</IonItem>
					{leadDataFields.map(field => {
						let question = eventQuestions.filter(q => field.id === q.id)[0];
						let answerString = question
							? !contact.answers
								? 'loading...'
								: contact.answers
										.filter(a => a.questionId === question.id)
										.filter(isObjectStatusActive)
										.map((contactAnswer): string | undefined => {
											if (contactAnswer.text && contactAnswer.text !== '') {
												return contactAnswer.text;
											} else if (contactAnswer.answerId) {
												return ctx.lead.eventAnswer.get(contactAnswer.answerId)
													?.value;
											} else {
												return;
											}
										})
										.filter(s => s && s !== '')
										.join(', ') || ''
							: '';
						return !question || answerString === '' ? (
							<></>
						) : (
							<IonItem key={'qf_' + field.id}>
								<IonLabel>
									{field.name || question.name || question.text}:
								</IonLabel>
								<IonText>{answerString}</IonText>
							</IonItem>
						);
					})}
				</IonCardContent>
			)}
		</IonCard>
	);
};
