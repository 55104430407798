import React, { useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonButton,
	IonText,
	IonSelect,
	IonSelectOption,
	IonIcon
} from '@ionic/react';

import { DeviceListItem } from '../../components/Device/DeviceListItem';
import { DeviceEntity, DeviceEntities } from '../../../store';
import { SearchBar } from '../../../../app/ui/components/Search/SearchBar';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useCtx } from '../../../../config/hooks';

export interface DeviceListConfigProps {
	title?: string;
	finish?: any;
}

export const DeviceList: React.FC<DeviceListConfigProps> = props => {
	const [searchText, setSearchText] = useState('');
	const [grouping, setGrouping] = useState('Status');
	const [pageSize, setPageSize] = useState(15);
	const [pageSelected, setPageSelected] = useState(1);
	const {
		config: [config],
		lead: { deviceHelper, activeService }
	} = useCtx<DeviceListConfigProps>({});

	// Prevents Ionic cached component instances from actively running in background when not needed.
	//if (!active) return <></>;

	const sortDevices = (a: DeviceEntity, b: DeviceEntity) => {
		return a.i_.status > b.i_.status ? 1 : b.i_.status > a.i_.status ? -1 : 0;
		/*return (a.i_.created?.dt as Date) > (b.i_.created?.dt as Date)
			? 1
			: (b.i_.created?.dt as Date) > (a.i_.created?.dt as Date)
			? -1
			: 0;*/
	};

	let devices: DeviceEntities = !activeService
		? []
		: deviceHelper.allByService(activeService).filter(device => {
				if (!searchText || searchText === '') return true;
				let match = 0,
					matches = 0,
					keywords = searchText.toLowerCase().split(' ');
				for (let keyword of keywords) {
					if (keyword === '') break;
					match++;
					if (
						(device?.name &&
							device?.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(device?.brand &&
							device?.brand?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(device?.system &&
							device?.system?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(device?.type &&
							device?.type?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(device?.i_.status &&
							device?.i_.status?.toLowerCase().trim().indexOf(keyword) > -1)
					) {
						matches++;
					}
				}
				return match === matches;
		  });

	const onGroupChanged = (e: any) => {
		setGrouping(e.detail?.value);
	};

	let totalDevices = devices.length;
	const onPageChange = (pageMovement: number) => {
		let newPage = pageSelected.valueOf() + pageMovement;
		let maxPage = Math.ceil(totalDevices / pageSize);
		setPageSelected(newPage < 1 ? 1 : newPage > maxPage ? maxPage : newPage);
	};

	const onPageSizeChanged = (e: any) => {
		setPageSize(e.detail?.value);
		setPageSelected(1);
	};

	const deviceListItems = devices
		.sort(sortDevices)
		.slice(pageSize * (pageSelected - 1), pageSize * pageSelected)
		.map((device: DeviceEntity, index: number, deviceList: DeviceEntity[]) => {
			let newSortValue = '';
			/*let deviceStatus = ''; //device.i_.status;
			if (index > 0) {
				let prevDeviceStatus = deviceList[index - 1].i_.status;
				if ((deviceStatus ?? '') != (prevDeviceStatus ?? '')) {
					newSortValue = deviceStatus ?? '';
				}
			} else {
				newSortValue = deviceStatus ?? '';
			}*/
			return newSortValue == '' ? (
				<DeviceListItem device={device} />
			) : (
				<>
					<IonItemDivider>
						<IonLabel>{newSortValue}</IonLabel>
					</IonItemDivider>
					<DeviceListItem device={device} />
				</>
			);
		});

	const deviceDeactivatedListItems = devices
		//.filter(x => x.i_.status == ObjectStatus.Deactive)
		.sort(sortDevices)
		.map((device: DeviceEntity) => {
			return <DeviceListItem device={device} />;
		});

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<SearchBar onChange={onSerach} />
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="3">
					<IonItem>
						<IonText>
							<p>Sort By</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="sortGroupSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={grouping}
							placeholder="Select One"
							onIonChange={onGroupChanged}
						>
							<IonSelectOption value="Status">Status</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
				<IonCol size="5"></IonCol>
				<IonCol size="2">
					<IonItem>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(-1);
							}}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
						<IonLabel class="selectedPageNumber">
							{pageSelected} of{' '}
							{Math.max(Math.ceil(totalDevices / pageSize), 1)}
						</IonLabel>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(1);
							}}
						>
							<IonIcon icon={arrowForward}></IonIcon>
						</IonButton>
					</IonItem>
				</IonCol>
				<IonCol size="2">
					<IonItem>
						<IonText>
							<p>Page Size</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="pageSizeSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={pageSize}
							placeholder="Select One"
							onIonChange={onPageSizeChanged}
						>
							<IonSelectOption value={15}>15</IonSelectOption>
							<IonSelectOption value={25}>25</IonSelectOption>
							<IonSelectOption value={50}>50</IonSelectOption>
							<IonSelectOption value={100}>100</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonList>{deviceListItems}</IonList>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="8"></IonCol>
				<IonCol size="2">
					<IonItem>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(-1);
							}}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
						<IonLabel class="selectedPageNumber">
							{pageSelected} of{' '}
							{Math.max(Math.ceil(totalDevices / pageSize), 1)}
						</IonLabel>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(1);
							}}
						>
							<IonIcon icon={arrowForward}></IonIcon>
						</IonButton>
					</IonItem>
				</IonCol>
				<IonCol size="2">
					<IonItem>
						<IonText>
							<p>Page Size</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="pageSizeSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={pageSize}
							placeholder="Select One"
							onIonChange={onPageSizeChanged}
						>
							<IonSelectOption value={15}>15</IonSelectOption>
							<IonSelectOption value={25}>25</IonSelectOption>
							<IonSelectOption value={50}>50</IonSelectOption>
							<IonSelectOption value={100}>100</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
