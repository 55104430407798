// tslint:disable
/**
 * CONEXSYS EventQuestion Portal API
 * The API for the CONEXSYS EventQuestion Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * EventQuestion: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { EventQuestionHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { EventQuestionEntity, EventQuestionEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum EventQuestionApiOperation {
	readEventQuestions = 'readEventQuestions',
	readEventQuestionsById = 'readEventQuestionsById'
}

export interface ReadEventQuestionsRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventId: string;
}

export interface ReadEventQuestionsByIdRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventIds: string[];
	ids: string[];
}

/**
 * Get detailed event event questions
 */
export async function readEventQuestions(
	ctx: UseCtx<any>,
	requestParameters: ReadEventQuestionsRequest
): Promise<EventQuestionEntities> {
	const operationId = EventQuestionApiOperation.readEventQuestions,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventQuestionEntities,
		ReadEventQuestionsRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: EventQuestionEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventQuestionEntities, EventQuestionHelper>(
		ctx.lead.eventQuestion,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventQuestion.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Get detailed event questions by id
 */
export async function readEventQuestionsById(
	ctx: UseCtx<any>,
	requestParameters: ReadEventQuestionsByIdRequest
): Promise<EventQuestionEntities> {
	const operationId = EventQuestionApiOperation.readEventQuestionsById,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventQuestionEntities,
		ReadEventQuestionsByIdRequest
	>(ctx, config.apiId, operationId, requestParameters, 'ids');

	let entities: EventQuestionEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventQuestionEntities, EventQuestionHelper>(
		ctx.lead.eventQuestion,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventQuestion.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
