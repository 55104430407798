import React from 'react';

//Data
import { ServiceEntities } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonContent
} from '@ionic/react';
import { ServiceList } from './../containers/Service';

const ServicesView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: { serviceHelper }
	} = ctx;

	const services: ServiceEntities = serviceHelper.allByUserId(
		activeUser?.userId
	);

	return (
		<IonContent>
			<IonCard>
				<IonCardHeader></IonCardHeader>
				<IonCardContent>
					<ServiceList services={services} />
				</IonCardContent>
			</IonCard>
		</IonContent>
	);
};

export default ServicesView;
