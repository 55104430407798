// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * LEAD: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { LeadHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { LeadEntity, LeadEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum LeadApiOperation {
	readLeads = 'readLeads',
	writeLeads = 'writeLeads',
	upsertLeadsByCode = 'writeLeadsByCode'
}

export interface ReadLeadsRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	leadIds?: string[];
	ids?: string[];
	eventIds?: string[];
}

export interface WriteLeadsRequest {
	userId: string;
	serviceId: string;
	leads: LeadEntities;
}

export interface UpsertLeadsByCodeRequest {
	userId: string;
	serviceId: string;
	eventIds: string[];
	scans: { code: string; date: string }[];
}

/**
 * Read leads
 */
export async function readLeads(
	ctx: UseCtx<any>,
	requestParameters: ReadLeadsRequest
): Promise<LeadEntities> {
	const operationId = LeadApiOperation.readLeads,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<LeadEntities, ReadLeadsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: LeadEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LeadEntities, LeadHelper>(
		ctx.lead.lead,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.lead.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Leads
 */
export async function writeLeads<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteLeadsRequest
): Promise<LeadEntities> {
	const operationId = LeadApiOperation.writeLeads,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.leads.forEach(
		(entity: LeadEntity, i: number, a: LeadEntities) => {
			a[i] = modifyEntity<LeadEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<LeadEntities, WriteLeadsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'leads'
	);

	let entities: LeadEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LeadEntities, LeadHelper>(
		ctx.lead.lead,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.lead.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

export async function upsertLeadsByCode<T>(
	ctx: UseCtx<any>,
	requestParameters: UpsertLeadsByCodeRequest
): Promise<LeadEntities> {
	const operationId = LeadApiOperation.upsertLeadsByCode,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<LeadEntities, UpsertLeadsByCodeRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'scans'
	);

	let entities: LeadEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LeadEntities, LeadHelper>(
		ctx.lead.lead,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.lead.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
