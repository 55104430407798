import React, { useState } from 'react';

//DATA
import { UserEntity, ObjectStatus } from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonLabel,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonBadge,
	IonButton,
	IonIcon,
	IonPopover,
	IonList,
	IonListHeader
} from '@ionic/react';
import {
	ellipsisHorizontal,
	closeCircleOutline,
	checkmarkCircleOutline
} from 'ionicons/icons';
import { Avatar } from '../../../../app/ui/components';

//LIB
import _ from 'lodash';

export interface UserListItemProps {
	user: UserEntity;
	totalLeads: number;
	licenses: number;
	onUserSelected?: (selectedUserId: string) => void;
}

export const UserListItem: React.FC<UserListItemProps> = props => {
	const ctx = useCtx<UserListItemProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	const onDeactivate = (e: any) => {
		setShowPopover({
			open: false,
			event: undefined
		});

		if (props.user) {
			let change: UserEntity = _.set(
				_.cloneDeep(props.user),
				'i_.status',
				ObjectStatus.Deactive
			);
			ctx.lead.user.change(change);
			ctx.lead.user.applyChanges(props.user.id);
		}
	};

	const onActivate = (e: any) => {
		setShowPopover({
			open: false,
			event: undefined
		});

		if (props.user) {
			let change: UserEntity = _.set(
				_.cloneDeep(props.user),
				'i_.status',
				ObjectStatus.Active
			);
			ctx.lead.user.change(change);
			ctx.lead.user.applyChanges(props.user.id);
		}
	};

	const selectUser = () => {
		if (props.onUserSelected !== undefined) {
			props.onUserSelected(props.user.id);
		}
	};

	return (
		<IonItem
			key={props.user.id}
			button
			routerLink="/lead-service/user"
			onClick={selectUser}
		>
			<IonGrid>
				<IonRow>
					<IonCol size="5" sizeMd="5">
						<IonLabel>
							<div>
								<div style={{ display: 'inline-block', paddingRight: '20px' }}>
									<Avatar
										//src={currentUser?.picture}
										name={props.user.name}
									/>
								</div>
								<div style={{ display: 'inline-block' }}>
									<h2>
										{props.user.firstName || props.user.lastName
											? props.user.firstName + ' ' + props.user.lastName
											: ''}
									</h2>
									<h2>{props.user.email}</h2>
								</div>
							</div>
						</IonLabel>
					</IonCol>
					<IonCol size="3" sizeMd="3">
						<IonLabel>
							<h2>Leads Scanned: {props.totalLeads}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="2" sizeMd="2" hidden={true}>
						{props.user.i_.status == ObjectStatus.Active ? (
							<IonBadge color="success">{props.user.i_.status}</IonBadge>
						) : (
							<IonBadge color="danger">{props.user.i_.status}</IonBadge>
						)}
						<IonButton
							fill="clear"
							onClick={e =>
								setShowPopover({ open: true, event: e.nativeEvent })
							}
						>
							<IonIcon icon={ellipsisHorizontal} />
						</IonButton>
						<IonPopover
							isOpen={showPopover.open}
							event={showPopover.event}
							onDidDismiss={e =>
								setShowPopover({ open: false, event: undefined })
							}
						>
							<IonList>
								<IonListHeader>Options</IonListHeader>
								{props.user.i_.status == ObjectStatus.Deactive ? (
									<IonItem button onClick={onActivate}>
										<IonIcon icon={checkmarkCircleOutline} />
										Activate User
									</IonItem>
								) : (
									<IonItem button onClick={onDeactivate}>
										<IonIcon icon={closeCircleOutline} />
										Deactivate User
									</IonItem>
								)}
							</IonList>
						</IonPopover>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
