import { EventQuestionEntities } from '../../lead/store/collections/EventQuestion';

export interface EventQuestionConfig {
	entities: EventQuestionEntities;
	activeId?: string;
}

export const eventQuestionConfig: EventQuestionConfig = {
	entities: []
};
