import * as storage from '../../../storage';
import { collectionDefault } from '../../../storage/constants';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { noteConfig } from '../../../config/lead/Note';
import { Note } from '../models';
import { CollectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Note entity interface
 *
 * @export
 * @interface NoteEntity
 * @extends {storage.Entity}
 * @extends {Note}
 */
export interface NoteEntity extends storage.Entity, Note {}

/**
 * Note entities array
 *
 * @export
 */
export type NoteEntities = NoteEntity[];

/**
 * Some note entities: one entity or an array of entities
 *
 * @export
 */
export type NoteEntity_Some = NoteEntity | NoteEntities;

/**
 * Note entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface NoteEntityPatch
 * @extends {storage.EntityPatch<NoteEntity>}
 */
export type NoteEntityPatch = storage.EntityPatcher<NoteEntity>;

/**
 * Note entity patches array
 *
 * @export
 */
export type NoteEntitiesPatch = NoteEntityPatch[];

/**
 * Some note entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type NoteEntityPatch_Some = NoteEntityPatch | NoteEntitiesPatch;

/**
 * Note entity id
 *
 * @export
 */
export type NoteId = storage.EntityId;

/**
 * Note entity ids
 *
 * @export
 */
export type NoteIds = storage.EntityIds;

/**
 * Some note entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type NoteId_Some = NoteId | NoteIds;

/**
 * Note entities object by id
 *
 * @export
 * @interface NoteEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface NoteEntitiesObject extends storage.EntitiesObject {
	[id: string]: NoteEntity;
}

/**
 * Note entity state
 *
 * @export
 * @interface NoteEntityState
 * @extends {storage.EntityState}
 */
export interface NoteEntityState extends storage.EntityState {}

/**
 * Note store collection
 *
 * @export
 * @interface NoteStoreCollection
 * @extends {storage.Store}
 */
export interface NoteStoreCollection extends storage.Store {
	note: NoteCollection;
}

/**
 * Note collection properties
 *
 * @export
 * @interface NoteCollection
 * @extends {Collection}
 */
export interface NoteCollection extends storage.Collection {
	byIds: NoteEntitiesObject;
	mutation: NoteEntitiesObject;
	cache: NoteEntitiesObject;
	state: NoteCollectionState;
	//customId: storage.EntityId;
	//customIds: storage.EntityIds;
	//customByIds: NoteEntitiesObject;
}

/**
 * Note collection state
 *
 * @export
 * @interface NoteCollectionState
 * @extends {storage.CollectionState}
 */
export interface NoteCollectionState extends storage.CollectionState {}

// custom note collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const noteCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const noteCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const noteCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const noteCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: noteCollectionIdStringProps,
	idArrayProps: noteCollectionIdArrayProps,
	idEntityProps: noteCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Note collection default values
 *
 * @export
 * @constant
 */
export const noteCollectionDefault: NoteCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as NoteCollection),
		...{
			activeId: noteConfig.activeId || noteConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	noteConfig.entities
);

/**
 * Note store collection default values
 *
 * @export
 * @constant
 */
export const noteStoreCollectionDefault: NoteStoreCollection = {
	note: noteCollectionDefault
};

/**
 * Note store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getNoteCollection = (state: any) => state.lead.note;
