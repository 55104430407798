import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { routeConfig } from '../../../config/app/Route';
import { Route } from '../models';

/**
 * Route entity interface
 *
 * @export
 * @interface RouteEntity
 * @extends {storage.Entity}
 */
export interface RouteEntity extends storage.Entity, Route {}

/**
 * Route entities array
 *
 * @export
 */
export type RouteEntities = RouteEntity[];

/**
 * Some route entities: one entity or an array of entities
 *
 * @export
 */
export type RouteEntity_Some = RouteEntity | RouteEntities;

/**
 * Route entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface RouteEntityPatch
 * @extends {storage.EntityPatch<RouteEntity>}
 */
export type RouteEntityPatch = storage.EntityPatcher<RouteEntity>;

/**
 * Route entity patches array
 *
 * @export
 */
export type RouteEntitiesPatch = RouteEntityPatch[];

/**
 * Some route entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type RouteEntityPatch_Some = RouteEntityPatch | RouteEntitiesPatch;

/**
 * Route entity id
 *
 * @export
 */
export type RouteId = storage.EntityId;

/**
 * Route entity ids
 *
 * @export
 */
export type RouteIds = storage.EntityIds;

/**
 * Some route entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type RouteId_Some = RouteId | RouteIds;

/**
 * Route entities object by id
 *
 * @export
 * @interface RouteEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface RouteEntitiesObject extends storage.EntitiesObject {
	[id: string]: RouteEntity;
}

/**
 * Route entity state
 *
 * @export
 * @interface RouteEntityState
 * @extends {storage.EntityState}
 */
export interface RouteEntityState extends storage.EntityState {}

/**
 * Route store collection
 *
 * @export
 * @interface RouteStoreCollection
 * @extends {storage.Store}
 */
export interface RouteStoreCollection extends storage.Store {
	route: RouteCollection;
}

/**
 * Route collection properties
 *
 * @export
 * @interface RouteCollection
 * @extends {Collection}
 */
export interface RouteCollection extends storage.Collection {
	byIds: RouteEntitiesObject;
	mutation: RouteEntitiesObject;
	cache: RouteEntitiesObject;
	state: RouteCollectionState;
}

/**
 * Route collection state
 *
 * @export
 * @interface RouteCollectionState
 * @extends {storage.CollectionState}
 */
export interface RouteCollectionState extends storage.CollectionState {}

// custom route collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const routeCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const routeCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const routeCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const routeCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: routeCollectionIdStringProps,
	idArrayProps: routeCollectionIdArrayProps,
	idEntityProps: routeCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Route collection default values, initialize collection with route entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const routeCollectionDefault: RouteCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as RouteCollection),
		...{
			activeId: routeConfig.activeId || routeConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	routeConfig.entities
);

/**
 * Route store collection default values
 *
 * @export
 * @constant
 */
export const routeStoreCollectionDefault: RouteStoreCollection = {
	route: routeCollectionDefault
};

/**
 * Route store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getRouteCollection = (state: any) => state.app.route;
