import { Localize } from '../../locales';
import { ChartConfigProps } from '../../../app/ui/components/Chart';

export type ChartConfig = Localize<ChartConfigProps>;

export const chartConfig: ChartConfig = {
	en: {
		placeholder: 'Search'
	},
	fr: {
		placeholder: 'Chercher'
	}
};
