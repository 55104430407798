// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { NoteHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { NoteEntity, NoteEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum NoteApiOperation {
	readNotes = 'readNotes',
	writeNotes = 'writeNotes'
}

export interface ReadNotesRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	leadIds?: string[];
	ids?: string[];
}

export interface WriteNotesRequest {
	userId: string;
	serviceId: string;
	notes: NoteEntities;
}

/**
 * Read notes
 */
export async function readNotes(
	ctx: UseCtx<any>,
	requestParameters: ReadNotesRequest
): Promise<NoteEntities> {
	const operationId = NoteApiOperation.readNotes,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<NoteEntities, ReadNotesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: NoteEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<NoteEntities, NoteHelper>(
		ctx.lead.note,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.note.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Notes
 */
export async function writeNotes<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteNotesRequest
): Promise<NoteEntities> {
	const operationId = NoteApiOperation.writeNotes,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.notes.forEach(
		(entity: NoteEntity, i: number, a: NoteEntities) => {
			a[i] = modifyEntity<NoteEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<NoteEntities, WriteNotesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'notes'
	);

	let entities: NoteEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<NoteEntities, NoteHelper>(
		ctx.lead.note,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.note.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
