import React from 'react';

import config from '../../../config';

export interface ComponentProps {
	id: string;
	props?: any;
}

export const Component: React.FC<ComponentProps> = props =>
	props.id in config.components
		? React.createElement(config.components[props.id], props.props)
		: null;
