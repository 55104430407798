import { Localize } from '../../locales';
import { UserSettingsConfigProps } from '../../../app/ui/containers/User/UserSettings';

export type UserSettingsConfig = Localize<UserSettingsConfigProps>;

export const userSettingsConfig: UserSettingsConfig = {
	en: {
		display: {
			title: 'Display Settings',
			darkMode: 'Dark Mode',
			menu: {
				collapse: 'Collapse Menu'
			},
			locale: 'Locale'
		},
		storage: {
			title: 'Local Storage',
			reset: 'Reset Local Storage'
		}
	},
	fr: {
		display: {
			title: "Paramètres d'affichage",
			darkMode: 'Mode sombre',
			menu: {
				collapse: 'Réduire le menu'
			},
			locale: 'Lieu'
		},
		storage: {
			title: 'Stockage local',
			reset: 'Réinitialiser le stockage local'
		}
	}
};
