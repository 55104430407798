import React, { useState, useEffect } from 'react';

import {
	IonCard,
	IonCardContent,
	IonItem,
	IonList,
	IonGrid,
	IonRow,
	IonCol,
	IonText,
	IonCardHeader,
	IonCardTitle,
	IonLabel,
	IonToggle,
	IonButton,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonAlert,
	IonCardSubtitle
} from '@ionic/react';
import _ from 'lodash';
import { ObjectType, ObjectAny, ObjectStatus } from '../../../store';
import { useCtx } from '../../../../config/hooks';
import { SchemaObject } from '../../../../app/store/models/OpenApi3';
import { toTitleCase, newEntity } from '../../../../app/utils';
import moment from 'moment';

export interface EntityInput {
	key: string;
	schema: SchemaObject;
	type: string;
	format?: string;
	pattern?: string;
	regex?: RegExp | undefined;
	label: string;
	placeholder?: string;
	description?: string;
	required?: boolean;
	items?: SchemaObject | undefined;
	enum?: string[];
	valid?: boolean;
	issue?: string;
	minimum?: number;
	maximum?: number;
	exclusiveMinimum?: number;
	exclusiveMaximum?: number;
	minItems?: number;
	maxItems?: number;
	default?: any;
	uniqueItems?: boolean;
	example?: any;
	disabled?: boolean;
	inputMode?: string;
	inputType?: string;
}

export interface EntityInputs {
	[key: string]: EntityInput;
}

export interface EntityInputsPatch {
	[key: string]: Partial<EntityInput>;
}

export const parseSchemaEntityInputs = (schema: SchemaObject): EntityInputs => {
	if (!schema) return {};
	let properties: { [key: string]: SchemaObject } = {};

	if (schema.allOf) {
		schema.allOf.forEach((leaf: SchemaObject) => {
			if (leaf.properties) properties = _.merge(properties, leaf.properties);
		});
	}
	if (schema.properties) properties = _.merge(properties, schema.properties);

	//console.log(schema);

	let inputs: EntityInputs = {};

	Object.keys(properties).forEach((key: string) => {
		let prop: SchemaObject = properties[key] as SchemaObject;

		if (_.get(prop, 'x-.hidden') || prop.readOnly || key === 'id') return;

		let pattern: string | undefined = prop.pattern
			? prop.pattern
			: prop.format === 'uri'
			? '(http://|https://|www.)([^ \'"]*)'
			: undefined;
		inputs[key] = {
			...{
				key,
				schema: prop,
				type: prop.type,
				format: prop.format,
				pattern: prop.pattern,
				regex: pattern ? new RegExp(pattern) : undefined,
				label: _.get(prop, 'x-.label') || prop.title || toTitleCase(key),
				placeholder: _.get(prop, 'x-.placeholder'),
				required:
					_.get(prop, 'x-.required') ||
					(schema.required && schema.required.indexOf(key) > 0),
				items: prop.items ? (prop.items as SchemaObject) : undefined,
				disabled: prop.readOnly,
				inputType:
					prop.type === 'number' || prop.type === 'integer'
						? 'numeric'
						: prop.format === 'password'
						? 'password'
						: prop.format === 'email'
						? 'email'
						: prop.format === 'phone'
						? 'tel'
						: prop.format === 'uri'
						? 'url'
						: prop.format === 'date'
						? 'date'
						: prop.format === 'time'
						? 'time'
						: 'text',
				inputMode:
					prop.type === 'number'
						? 'decimal'
						: prop.type === 'integer'
						? 'numeric'
						: prop.format === 'password'
						? 'password'
						: prop.format === 'email'
						? 'email'
						: prop.format === 'phone'
						? 'tel'
						: prop.format === 'uri'
						? 'url'
						: 'text'
			},
			..._.omitBy(
				_.pick(prop, [
					'description',
					'enum',
					'minimum',
					'maximum',
					'exclusiveMinimum',
					'exclusiveMaximum',
					'minItems',
					'maxItems',
					'default',
					'uniqueItems',
					'example'
				]),
				_.isNil
			)
		} as EntityInput;
	});

	console.log(inputs);

	return inputs;
};

export interface EntityEditConfig {}

export interface EntityEditProps {
	type: ObjectType;
	schema: SchemaObject;
	entity?: ObjectAny;
	finish: any;
}

export const EntityEdit: React.FC<EntityEditProps> = props => {
	const ctx = useCtx<EntityEditConfig>({}); // TO DO: create and set (EntityEditConfig)

	const [inputs, setInputs] = useState<EntityInputs>({});
	const [initialized, setInitialized] = useState(false);
	const [retrySave, setRetrySave] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	// create a new entity if one does not exist and track it in the component state
	const [entity, setEntity] = useState<ObjectAny | any>(
		props.entity || newEntity(ctx, props.type)
	);

	let isValid =
		Object.keys(inputs).filter((key: string) => inputs[key].valid !== true)
			.length === 0;

	useEffect(() => {
		if (Object.keys(inputs).length === 0 && !initialized) {
			setInputs(parseSchemaEntityInputs(props.schema));
			setInitialized(true);
		}
		if (retrySave) save();
	});

	//console.log(inputs);

	const change = (e: any) => {
		let targetValue =
			!('checked' in e.target) || e.target.checked ? e.target.value : '';

		switch (inputs[e.target.name].type) {
			case 'number':
				targetValue = isNaN(parseFloat(targetValue))
					? ''
					: parseFloat(targetValue);
				break;
			case 'integer':
				targetValue = isNaN(parseInt(targetValue)) ? '' : parseInt(targetValue);
				break;
			case 'boolean':
				targetValue = targetValue === 'true' || targetValue === true;
				break;
			case 'object':
				// validate/handle object data
				break;
			case 'array':
				// validate/handle array data
				break;
		}

		//console.log(e.target.name);
		//console.log(targetValue);
		//console.log(entity[e.target.name]);
		if (targetValue != entity[e.target.name]) {
			//console.log(e.target.name);
			setEntity({ ...(entity || {}), ...{ [e.target.name]: targetValue } });
			validate([e.target.name]);
		}
	};

	const setStatus = (status: ObjectStatus, goSaveFinish?: boolean) => {
		entity.i_.status = status;
		if (goSaveFinish) save();
	};

	const goDelete = () => {
		setStatus(ObjectStatus.Deleted, true);
	};

	const validate = (inputKeys: string[] | boolean) => {
		let inputsPatch: EntityInputsPatch = {};

		Object.keys(inputs).forEach((key: string) => {
			if (
				inputKeys === false ||
				(Array.isArray(inputKeys) && inputKeys.indexOf(key) === -1)
			)
				return;

			let value = entity[key];
			let input: EntityInput = inputs[key];
			// must reset the issue to a BLANK string each time so that it get's merged on the patch!!!
			let inputPatch: Partial<EntityInput> = { valid: true, issue: '' };
			if (
				input.required === true &&
				(!(input.key in entity) ||
					value === undefined ||
					value === null ||
					value === '')
			) {
				inputPatch.valid = false;
				inputPatch.issue = 'Required';
			} else if (input.regex && !input.regex.test(value)) {
				inputPatch.valid = false;
				inputPatch.issue = 'Invalid format';
			} else if (
				(input.format === 'date-time' || input.format === 'date') &&
				!moment(value).isValid()
			) {
				inputPatch.valid = false;
				inputPatch.issue = 'Invalid date';
			} else if (value) {
				if (input.type === 'number' && isNaN(parseFloat(value))) {
					inputPatch.valid = false;
					inputPatch.issue = 'Invalid Number';
				} else if (
					input.type === 'integer' &&
					(isNaN(parseInt(value)) || parseInt(value) != parseFloat(value))
				) {
					inputPatch.valid = false;
					inputPatch.issue = 'Invalid Integer';
				} else if (input.minimum && parseFloat(value) < input.minimum) {
					inputPatch.valid = false;
					inputPatch.issue = 'Invalid Number => Minimum: ' + input.minimum;
				} else if (
					input.exclusiveMinimum &&
					parseFloat(value) <= input.exclusiveMinimum
				) {
					inputPatch.valid = false;
					inputPatch.issue =
						'Invalid Number => Greater than: ' + input.exclusiveMinimum;
				} else if (input.maximum && parseFloat(value) > input.maximum) {
					inputPatch.valid = false;
					inputPatch.issue = 'Invalid Number => Maximum: ' + input.maximum;
				} else if (
					input.exclusiveMaximum &&
					parseFloat(value) >= input.exclusiveMaximum
				) {
					inputPatch.valid = false;
					inputPatch.issue =
						'Invalid Number => Less than: ' + input.exclusiveMaximum;
				}
			}
			if (Object.keys(inputPatch).length > 0) inputsPatch[key] = inputPatch;
		});

		if (Object.keys(inputsPatch).length > 0) {
			setInputs(_.merge(inputs, inputsPatch));
		}
	};
	const save = () => {
		if (!isValid) {
			validate(true);
			// retry save only if false. otherwise set to false if true ;)
			setRetrySave(retrySave === false);
		} else {
			switch (props.type) {
				case ObjectType.Context:
					ctx.lead.context.write(ctx, { contexts: [entity] });
					break;
				case ObjectType.Event:
					ctx.lead.event.write(ctx, { events: [entity] });
					break;
				case ObjectType.Order:
					ctx.lead.order.write(ctx, { orders: [entity] });
					break;
				case ObjectType.Service:
					ctx.lead.service.write(ctx, { services: [entity] });
					break;
			}
			finish();
		}
	};

	const finish = () => {
		props.finish();
	};

	const cancel = () => {
		finish();
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol size="12" sizeLg="8">
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>
											{toTitleCase(props.type)}
											{entity.name || entity.code
												? ': ' + entity.name || entity.code
												: ''}
										</IonCardTitle>
										<IonCardSubtitle>
											{entity.id}
											<br />
											Modified:{' '}
											{moment(
												entity.i_.modified.dt || entity.i_.created.dt
											).format('LLL')}
										</IonCardSubtitle>
									</IonCardHeader>
									<IonCardContent>
										<IonList>
											{Object.keys(inputs).map((key: string) => {
												let input: EntityInput = inputs[key];
												let inputElement;
												if (input.type === 'string' && input.enum) {
													inputElement = (
														<IonSelect
															name={input.key}
															placeholder={input.placeholder}
															value={entity[input.key]}
															onIonChange={change}
															disabled={input.disabled}
															color={
																input.issue && input.issue !== ''
																	? 'danger'
																	: ''
															}
															className={
																input.required ? 'required' : undefined
															}
														>
															{input.enum.map((value: any) => (
																<IonSelectOption
																	key={
																		'entity-input-' +
																		input.key +
																		'-' +
																		String(value)
																	}
																	value={String(value)}
																>
																	{toTitleCase(String(value))}
																</IonSelectOption>
															))}
														</IonSelect>
													);
												} else if (
													input.type === 'string' ||
													input.type === 'number' ||
													input.type === 'integer'
												) {
													inputElement = (
														<IonInput
															type={input.inputType as any}
															inputMode={input.inputMode as any}
															pattern={input.pattern}
															placeholder={input.placeholder}
															name={input.key}
															value={entity[input.key]}
															onIonChange={change}
															disabled={input.disabled}
															required={input.required}
															color={
																input.issue && input.issue !== ''
																	? 'danger'
																	: ''
															}
															className={
																input.required ? 'required' : undefined
															}
															{..._.omitBy(
																{
																	min: input.minimum
																		? String(input.minimum)
																		: input.exclusiveMinimum
																		? String(input.exclusiveMinimum + 0.000001)
																		: undefined,
																	max: input.maximum
																		? String(input.maximum)
																		: input.exclusiveMaximum
																		? String(input.exclusiveMaximum - 0.000001)
																		: undefined
																},
																_.isNil
															)}
														></IonInput>
													);
												} else if (input.type === 'boolean') {
													inputElement = (
														<IonToggle
															name={input.key}
															value="true"
															checked={entity[input.key] === true}
															onClick={change}
															color="primary"
															className={
																input.required ? 'required' : undefined
															}
														/>
													);
												}
												return !inputElement ? (
													<></>
												) : (
													<IonItem key={'entity-input-' + input.key}>
														<IonLabel
															position="floating"
															color={
																input.issue && input.issue !== ''
																	? 'danger'
																	: ''
															}
														>
															<IonText hidden={!input.required} color="danger">
																*
															</IonText>
															{input.label} {input.issue}
														</IonLabel>
														{inputElement}
													</IonItem>
												);
											})}
										</IonList>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonButton color="light" onClick={cancel}>
									Cancel
								</IonButton>
							</IonCol>
							<IonCol>
								<IonButton
									//hidden={
									//	typeof entity.i_.store?.rev === 'string' ? false : true
									//}
									hidden={true}
									onClick={() => setConfirmDelete(true)}
									color="danger"
								>
									Delete
								</IonButton>
								<IonAlert
									isOpen={confirmDelete}
									onDidDismiss={() => setConfirmDelete(false)}
									header={'Confirm Delete!?'}
									message={'Are you sure you want to delete this?'}
									buttons={[
										{
											text: 'Cancel',
											role: 'cancel',
											cssClass: 'primary'
										},
										{
											text: 'Delete',
											cssClass: 'danger',
											handler: goDelete
										}
									]}
								/>
							</IonCol>
							<IonCol className="ion-align-self-end text-right">
								<IonButton
									//disabled={isValid ? false : true}
									onClick={() => save()}
								>
									Save
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
