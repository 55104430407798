import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getTicketCollection,
	TicketId,
	TicketIds,
	TicketId_Some,
	TicketEntity,
	TicketEntities,
	TicketEntity_Some,
	TicketEntityPatch_Some,
	TicketCollection,
	TicketCollectionState,
	ITicketActions,
	ticketActions,
	TicketActionTypes
} from '..';
import {
	readTickets,
	writeTickets,
	ReadTicketsRequest,
	WriteTicketsRequest,
	TicketApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';

/**
 * Ticket helper interface
 *
 * @export
 * @interface ITicketHelper
 * @extends {IEntityHelper}
 */
export interface ITicketHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Ticket helper options interface
 *
 * @export
 * @interface TicketHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface TicketHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const ticketHelperOpts: TicketHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Ticket helper
 *
 * @export
 * @class TicketHelper
 * @extends {EntityHelper<TicketCollection, TicketActionTypes, TicketActions, TicketEntity, TicketEntities, TicketEntity_Some, TicketEntityPatch_Some, TicketId, TicketIds, TicketId_Some, TicketCollectionState, TicketHelperOpts>}
 * @implements {ITicketHelper}
 */
export class TicketHelper
	extends EntityHelper<
		TicketCollection,
		TicketActionTypes,
		ITicketActions,
		TicketEntity,
		TicketEntities,
		TicketEntity_Some,
		TicketEntityPatch_Some,
		TicketId,
		TicketIds,
		TicketId_Some,
		TicketCollectionState,
		TicketHelperOpts
	>
	implements ITicketHelper {
	constructor() {
		super(
			useSelector(getTicketCollection),
			ticketActions,
			useDispatch(),
			ticketHelperOpts
		);
		this.collection = useSelector(getTicketCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: TicketApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadTicketsRequest> = {},
		callback?: any
	): Promise<TicketEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(TicketApiOperation.readTickets);

		let request: ReadTicketsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: TicketEntities = await readTickets(ctx, request)
			.then((entities: TicketEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteTicketsRequest> = {},
		callback?: any
	): Promise<TicketEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		if (!params.tickets) return [];
		let request: WriteTicketsRequest = {
			...params,
			...{
				tickets: params.tickets,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: TicketEntities = await writeTickets(ctx, request)
			.then((entities: TicketEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}
}
