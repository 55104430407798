// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Object type
 * @export
 * @enum {string}
 */
export enum ObjectType {
	Contact = 'contact',
	Context = 'context',
	Device = 'device',
	Event = 'event',
	EventAnswer = 'answer',
	EventContact = 'contact',
	EventContactAnswer = 'contact-answer',
	EventQuestion = 'question',
	Lead = 'lead',
	License = 'license',
	Note = 'note',
	Order = 'order',
	OrderTax = 'order-tax',
	OrderTransaction = 'order-transaction',
	Product = 'product',
	ProductCategory = 'product-category',
	ProductTax = 'product-tax',
	Qualifier = 'qualifier',
	QualifierValue = 'qualifier-value',
	Response = 'response',
	Service = 'service',
	Ticket = 'ticket',
	User = 'user',
	Data = 'data',
	ContactRegistrationType = 'contact-registration-type'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesObjectType = [
	'contact',
	'context',
	'device',
	'event',
	'contact-answer',
	'lead',
	'license',
	'note',
	'order',
	'order-tax',
	'order-transaction',
	'product',
	'product-category',
	'product-tax',
	'qualifier',
	'qualifier-value',
	'response',
	'service',
	'ticket',
	'user',
	'data',
	'contact-registration-type'
];
