import {
	ResponseId_Some,
	ResponseEntity_Some,
	ResponseEntityPatch_Some,
	ResponseCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const RESPONSE_MUTATE_ID = '@lead/RESPONSE_MUTATE_ID';
export const RESPONSE_SET = '@lead/RESPONSE_SET';
export const RESPONSE_TOGGLE = '@lead/RESPONSE_TOGGLE';
export const RESPONSE_SELECT = '@lead/RESPONSE_SELECT';
export const RESPONSE_DESELECT = '@lead/RESPONSE_DESELECT';
export const RESPONSE_UPSERT = '@lead/RESPONSE_UPSERT';
export const RESPONSE_PATCH = '@lead/RESPONSE_PATCH';
export const RESPONSE_CHANGE = '@lead/RESPONSE_CHANGE';
export const RESPONSE_APPLY_CHANGES = '@lead/RESPONSE_APPLY_CHANGES';
export const RESPONSE_CANCEL_CHANGES = '@lead/RESPONSE_CANCEL_CHANGES';
export const RESPONSE_CACHE = '@lead/RESPONSE_CACHE';
export const RESPONSE_UNCACHE = '@lead/RESPONSE_UNCACHE';
export const RESPONSE_SETSTATE = '@lead/RESPONSE_SETSTATE';
export const RESPONSE_PATCHSTATE = '@lead/RESPONSE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ResponseAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ResponseAction_MutateId extends EntityAction_MutateId {
	type: typeof RESPONSE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active response
 *
 * @interface ResponseAction_Set
 * @extends {EntityAction_Set}
 */
export interface ResponseAction_Set extends EntityAction_Set {
	type: typeof RESPONSE_SET;
	payload?: EntityId;
}

/**
 * Toggle active response
 *
 * @interface ResponseAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ResponseAction_Toggle extends EntityAction_Toggle {
	type: typeof RESPONSE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select response entities
 *
 * @interface ResponseAction_Select
 * @extends {EntityAction_Select}
 */
interface ResponseAction_Select extends EntityAction_Select {
	type: typeof RESPONSE_SELECT;
	payload: ResponseId_Some;
}

/**
 * Deselect response entities
 *
 * @interface ResponseAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ResponseAction_Deselect extends EntityAction_Deselect {
	type: typeof RESPONSE_DESELECT;
	payload: ResponseId_Some;
}

/**
 * Upsert response entities
 *
 * @interface ResponseAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ResponseAction_Upsert extends EntityAction_Upsert {
	type: typeof RESPONSE_UPSERT;
	payload: ResponseEntity_Some;
}

/**
 * Patch response entities
 *
 * @interface ResponseAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ResponseAction_Patch extends EntityAction_Patch {
	type: typeof RESPONSE_PATCH;
	payload: ResponseEntityPatch_Some;
}

/**
 * Change response entities
 *
 * @interface ResponseAction_Change
 * @extends {EntityAction_Change}
 */
interface ResponseAction_Change extends EntityAction_Change {
	type: typeof RESPONSE_CHANGE;
	payload: ResponseEntityPatch_Some;
}

/**
 * Apply changes to response entities
 *
 * @interface ResponseAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ResponseAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof RESPONSE_APPLY_CHANGES;
	payload: ResponseId_Some;
}

/**
 * Cancel changes to response entities
 *
 * @interface ResponseAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ResponseAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof RESPONSE_CANCEL_CHANGES;
	payload: ResponseId_Some;
}

/**
 * Cache response entities
 *
 * @interface ResponseAction_Cache
 * @extends {EntityAction_Cache}
 */
interface ResponseAction_Cache extends EntityAction_Cache {
	type: typeof RESPONSE_CACHE;
	payload: ResponseId_Some;
}

/**
 * Uncache response entities
 *
 * @interface ResponseAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface ResponseAction_Uncache extends EntityAction_Uncache {
	type: typeof RESPONSE_UNCACHE;
	payload: ResponseId_Some;
}

/**
 * Set response collection / entity state
 *
 * @interface ResponseAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ResponseAction_SetState extends EntityAction_SetState {
	type: typeof RESPONSE_SETSTATE;
}

/**
 * PAtch response collection / entity state
 *
 * @interface ResponseAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ResponseAction_PatchState extends EntityAction_PatchState {
	type: typeof RESPONSE_PATCHSTATE;
}

/**
 * Export response action types
 *
 * @export
 */
export type ResponseActionTypes =
	| ResponseAction_MutateId
	| ResponseAction_MutateId
	| ResponseAction_Set
	| ResponseAction_Toggle
	| ResponseAction_Select
	| ResponseAction_Deselect
	| ResponseAction_Upsert
	| ResponseAction_Patch
	| ResponseAction_Change
	| ResponseAction_ApplyChanges
	| ResponseAction_CancelChanges
	| ResponseAction_Cache
	| ResponseAction_Uncache
	| ResponseAction_SetState
	| ResponseAction_PatchState;

/**
 * Response actions helper interface
 *
 * @export
 * @interface IResponseActions
 * @extends {EntityActions}
 */
export interface IResponseActions extends EntityActions {
	//customAction(ids: ResponseId_Some): ResponseAction_CustomAction;
}

/**
 * Response actions helper
 *
 * @export
 * @class ResponseActions
 * @implements {EntityBaseActions}
 */
export class ResponseActions implements IResponseActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ResponseAction_MutateId {
		return { type: RESPONSE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ResponseAction_Set {
		return { type: RESPONSE_SET, payload: id };
	}
	toggle(id?: EntityId): ResponseAction_Toggle {
		return { type: RESPONSE_TOGGLE, payload: id };
	}
	select(ids: ResponseId_Some): ResponseAction_Select {
		return {
			type: RESPONSE_SELECT,
			payload: ids
		};
	}
	deselect(ids: ResponseId_Some): ResponseAction_Deselect {
		return {
			type: RESPONSE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ResponseEntity_Some): ResponseAction_Upsert {
		return {
			type: RESPONSE_UPSERT,
			payload: entities
		};
	}
	patch(entities: ResponseEntityPatch_Some): ResponseAction_Patch {
		return {
			type: RESPONSE_PATCH,
			payload: entities
		};
	}
	change(entities: ResponseEntityPatch_Some): ResponseAction_Change {
		return {
			type: RESPONSE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ResponseId_Some): ResponseAction_ApplyChanges {
		return {
			type: RESPONSE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ResponseId_Some): ResponseAction_CancelChanges {
		return {
			type: RESPONSE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: ResponseId_Some): ResponseAction_Cache {
		return {
			type: RESPONSE_CACHE,
			payload: ids
		};
	}
	uncache(ids: ResponseId_Some): ResponseAction_Uncache {
		return {
			type: RESPONSE_UNCACHE,
			payload: ids
		};
	}
	setState(state: ResponseCollectionState): ResponseAction_SetState {
		return {
			type: RESPONSE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ResponseCollectionState>
	): ResponseAction_PatchState {
		return {
			type: RESPONSE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the response actions helper
 *
 * @export
 */
export const responseActions = new ResponseActions();
