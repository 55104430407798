import React from 'react';
import AccountView from './AccountView';
import AuthorizeView from './AuthorizeView';
import SettingsView from './SettingsView';

const views: { [key: string]: React.FC } = {
	AccountView,
	AuthorizeView,
	SettingsView
};

export default views;
