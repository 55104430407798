import { combineReducers } from 'redux';

import { reducers as appReducers } from '../app/store/reducers';
import { reducers as leadReducers } from '../lead/store/reducers';

export const configReducers: any = {
	app: appReducers,
	lead: leadReducers
};

export default configReducers;
