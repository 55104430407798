import { Localize, translations } from '../../locales';
import { ActivateOrderCardProps } from '../../../lead/ui/containers/Order/ActivateOrderCard';

export type ActivateOrderCardConfig = Localize<ActivateOrderCardProps>;

export const activateOrderCardConfig: ActivateOrderCardConfig = {
	en: {
		title: 'Activate Order',
		instructions:
			'If you have a new order code enter it below and click Activate.',
		codePlaceholder: 'Enter Order Code',
		activateOrderButtonText: 'Activate'
	},
	fr: {
		title: 'Activer la commande',
		instructions:
			'Si vous avez un nouveau code de commande, saisissez-le ci-dessous et cliquez sur Activer.',
		codePlaceholder: 'Entrez le code de commande',
		activateOrderButtonText: 'Activer'
	}
};
