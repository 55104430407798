import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getResponseCollection,
	ResponseId,
	ResponseIds,
	ResponseId_Some,
	ResponseEntity,
	ResponseEntities,
	ResponseEntity_Some,
	ResponseEntityPatch_Some,
	ResponseCollection,
	ResponseCollectionState,
	IResponseActions,
	responseActions,
	ResponseActionTypes
} from '..';
import {
	readResponses,
	writeResponses,
	ReadResponsesRequest,
	WriteResponsesRequest,
	ResponseApiOperation
} from '../apis';
import { useCtx, UseCtx } from '../../../config/hooks';
import {
	LeadEntity,
	QualifierEntity,
	QualifierValueEntity,
	UserEntity
} from '../collections';
import { isObjectStatusActive, ObjectStatus } from '../models';

/**
 * Response helper interface
 *
 * @export
 * @interface IResponseHelper
 * @extends {IEntityHelper}
 */
export interface IResponseHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Response helper options interface
 *
 * @export
 * @interface ResponseHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ResponseHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const responseHelperOpts: ResponseHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Response helper
 *
 * @export
 * @class ResponseHelper
 * @extends {EntityHelper<ResponseCollection, ResponseActionTypes, ResponseActions, ResponseEntity, ResponseEntities, ResponseEntity_Some, ResponseEntityPatch_Some, ResponseId, ResponseIds, ResponseId_Some, ResponseCollectionState, ResponseHelperOpts>}
 * @implements {IResponseHelper}
 */
export class ResponseHelper
	extends EntityHelper<
		ResponseCollection,
		ResponseActionTypes,
		IResponseActions,
		ResponseEntity,
		ResponseEntities,
		ResponseEntity_Some,
		ResponseEntityPatch_Some,
		ResponseId,
		ResponseIds,
		ResponseId_Some,
		ResponseCollectionState,
		ResponseHelperOpts
	>
	implements IResponseHelper {
	constructor() {
		super(
			useSelector(getResponseCollection),
			responseActions,
			useDispatch(),
			responseHelperOpts
		);
		this.collection = useSelector(getResponseCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: ResponseApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadResponsesRequest> = {},
		callback?: any
	): Promise<ResponseEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom ||
		//	this.lastSuccess(ResponseApiOperation.readResponses);

		let request: ReadResponsesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: ResponseEntities = await readResponses(ctx, request)
			.then((entities: ResponseEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteResponsesRequest> = {},
		callback?: any
	): Promise<ResponseEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		if (!params.responses) return [];
		let request: WriteResponsesRequest = {
			...params,
			...{
				responses: params.responses,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: ResponseEntities = await writeResponses(ctx, request)
			.then((entities: ResponseEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let response = this.get(id);
		if (!response) return false;

		response.i_.status = ObjectStatus.Deleted;

		let responses = await this.write(ctx, {
			responses: [response]
		});

		return responses.length > 0;
	}

	allByLead(lead: LeadEntity): ResponseEntities {
		return this.all()
			.filter(response => response.leadId === lead.id)
			.filter(isObjectStatusActive);
	}

	allByUser(user: UserEntity): ResponseEntities {
		return this.all()
			.filter(response => response.i_.created.by === user.id)
			.filter(isObjectStatusActive);
	}

	allByQualifier(qualifier: QualifierEntity): ResponseEntities {
		return this.all()
			.filter(response => response.qualifierId === qualifier.id)
			.filter(isObjectStatusActive);
	}

	allByQualifierValue(qualifierValue: QualifierValueEntity): ResponseEntities {
		return this.all()
			.filter(response => response.qualifierValueId === qualifierValue.id)
			.filter(isObjectStatusActive);
	}
}
