import {
	LeadCollection,
	LeadCollectionState,
	LeadId,
	LeadEntity_Some,
	LeadEntityPatch_Some,
	LeadId_Some,
	leadCollectionDefault,
	leadCollectionMutateIdOpts
} from '../collections/Lead';
import * as actions from '../actions/Lead';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Lead reducer
 *
 * @export
 * @param {LeadCollection} [state=leadCollectionDefault]
 * @param {LeadActionTypes} action
 * @returns
 */
export function leadReducer(
	state: LeadCollection = leadCollectionDefault,
	action: actions.LeadActionTypes
) {
	switch (action.type) {
		case actions.LEAD_MUTATE_ID:
			return collectionReducerActions.mutateId<LeadCollection>(
				state,
				action.payload,
				leadCollectionMutateIdOpts
			);
		case actions.LEAD_SET:
			return collectionReducerActions.set<LeadCollection, LeadId>(
				state,
				action.payload
			);
		case actions.LEAD_TOGGLE:
			return collectionReducerActions.toggle<LeadCollection, LeadId>(
				state,
				action.payload
			);
		case actions.LEAD_SELECT:
			return collectionReducerActions.select<LeadCollection, LeadId_Some>(
				state,
				action.payload
			);
		case actions.LEAD_DESELECT:
			return collectionReducerActions.deselect<LeadCollection, LeadId_Some>(
				state,
				action.payload
			);
		case actions.LEAD_UPSERT:
			return collectionReducerActions.upsert<LeadCollection, LeadEntity_Some>(
				state,
				action.payload
			);
		case actions.LEAD_PATCH:
			return collectionReducerActions.patch<
				LeadCollection,
				LeadEntityPatch_Some
			>(state, action.payload);
		case actions.LEAD_CHANGE:
			return collectionReducerActions.change<
				LeadCollection,
				LeadEntityPatch_Some
			>(state, action.payload);
		case actions.LEAD_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<LeadCollection, LeadId_Some>(
				state,
				action.payload
			);
		case actions.LEAD_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				LeadCollection,
				LeadId_Some
			>(state, action.payload);
		case actions.LEAD_CACHE:
			return collectionReducerActions.cache<LeadCollection, LeadId_Some>(
				state,
				action.payload
			);
		case actions.LEAD_UNCACHE:
			return collectionReducerActions.uncache<LeadCollection, LeadId_Some>(
				state,
				action.payload
			);
		case actions.LEAD_SETSTATE:
			return collectionReducerActions.setState<
				LeadCollection,
				LeadCollectionState
			>(state, action.payload.state);
		case actions.LEAD_PATCHSTATE:
			return collectionReducerActions.patchState<
				LeadCollection,
				LeadCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
