import React, { useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonLabel,
	IonFab,
	IonFabButton,
	IonFabList
} from '@ionic/react';

import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine, Serie } from '@nivo/line';
import { barChart, pieChart, reader, analyticsOutline } from 'ionicons/icons';
import { chartConfig } from '../../../config/app/components/Chart';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useLocaleConfig } from '../../store';

export interface ChartConfigProps {
	placeholder: string;
}

export interface ChartProps {
	title?: string;
	height: string;
	width?: string;
	data: any[];
	indexBy?: string;
	keys?: string[];
	layout?: 'horizontal' | 'vertical' | undefined;
	selectedChartType?: 'bar' | 'pie' | 'list' | 'line' | undefined;
	enableGrid?: boolean;
	chartsAvailable?: string[];
}

export const Chart: React.FC<ChartProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<ChartConfigProps>(chartConfig);

	const [chartType, setChartType] = useState(props.selectedChartType || 'bar');

	let selectedChartIcon = () => {
		if (chartType == 'pie') {
			return <IonIcon icon={pieChart} />;
		} else if (chartType == 'bar') {
			return <IonIcon icon={barChart} />;
		} else if (chartType == 'list') {
			return <IonIcon icon={reader} />;
		} else if (chartType == 'line') {
			return <IonIcon icon={analyticsOutline} />;
		}
	};
	let displayFab = () => {
		return (
			<IonFab horizontal="end">
				<IonFabButton size="small">{selectedChartIcon()}</IonFabButton>
				<IonFabList side="bottom">
					<IonFabButton
						hidden={
							props.chartsAvailable && props.chartsAvailable.indexOf('pie') < 0
						}
						onClick={() => {
							setChartType('pie');
						}}
					>
						<IonIcon icon={pieChart} />
					</IonFabButton>
					<IonFabButton
						hidden={
							props.chartsAvailable && props.chartsAvailable.indexOf('bar') < 0
						}
						onClick={() => {
							setChartType('bar');
						}}
					>
						<IonIcon icon={barChart} />
					</IonFabButton>
					<IonFabButton
						hidden={
							props.chartsAvailable && props.chartsAvailable.indexOf('line') < 0
						}
						onClick={() => {
							setChartType('line');
						}}
					>
						<IonIcon icon={analyticsOutline} />
					</IonFabButton>
					<IonFabButton
						hidden={
							props.chartsAvailable && props.chartsAvailable.indexOf('list') < 0
						}
						onClick={() => {
							setChartType('list');
						}}
					>
						<IonIcon icon={reader} />
					</IonFabButton>
				</IonFabList>
			</IonFab>
		);
	};

	let displayChart = () => {
		if (chartType == 'pie') {
			return (
				<ResponsivePie
					margin={{ top: 26, right: 60, bottom: 26, left: 60 }}
					data={props.data}
					isInteractive={true}
				/>
			);
		} else if (chartType == 'line') {
			//ConvertData to Line Data
			let lineData: Serie[] = props.data;
			return (
				<ResponsiveLine
					margin={{ top: 26, right: 60, bottom: 26, left: 60 }}
					data={lineData}
					isInteractive={true}
					motionStiffness={170}
					motionDamping={26}
				/>
			);
		} else {
			return (
				<ResponsiveBar
					layout={props.layout || 'horizontal'}
					margin={{ top: 26, right: 60, bottom: 26, left: 100 }}
					data={props.data}
					indexBy={props.indexBy || 'id'}
					keys={props.keys || ['value']}
					enableGridX={props.enableGrid || false}
					enableGridY={false}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: -40
					}}
					axisBottom={null}
					padding={0.3}
					labelTextColor={{
						from: 'color',
						modifiers: [['darker', 1.4]]
					}}
					colors={{ scheme: 'nivo' }}
					borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
					isInteractive={true}
					motionStiffness={170}
					motionDamping={26}
				/>
			);
		}
	};

	return (
		<IonGrid
			style={{
				height: props.height || '100%',
				width: props.width || '100%',
				margin: '10px'
			}}
		>
			<IonRow>
				<IonCol size="10">
					<IonLabel>{props.title || ''}</IonLabel>
				</IonCol>
				<IonCol size="2">{displayFab()}</IonCol>
			</IonRow>
			<IonRow style={{ height: '100%', width: '100%' }}>
				<IonCol size="12">{displayChart()}</IonCol>
			</IonRow>
		</IonGrid>
	);
};
