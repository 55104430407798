import { Localize } from '../../locales';
import { SearchBarConfigProps } from '../../../app/ui/components/Search/SearchBar';

export type SearchBarConfig = Localize<SearchBarConfigProps>;

export const searchBarConfig: SearchBarConfig = {
	en: {
		placeholder: 'Search'
	},
	fr: {
		placeholder: 'Chercher'
	}
};
