import { QualifierEntities } from '../../lead/store/collections/Qualifier';
import { QualifierSelection } from '../../lead/store/models/QualifierSelection';
import { ValueFormatString } from '../../lead/store/models/ValueFormatString';

export interface QualifierConfig {
	entities: QualifierEntities;
	activeId?: string;
}
export const qualifierConfig: QualifierConfig = {
	entities: []
};
