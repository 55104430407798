import { Store, State } from '../../storage';
import * as collections from './collections';
import axios, { AxiosResponse } from 'axios';
import { getNodeText } from '@testing-library/react';

export * from './actions';
export * from './classes';
export * from './collections';
export * from './hooks';
export * from './models';
export * from './reducers';

/**
 * App store interface
 *
 * @export
 * @interface AppStore
 */
export interface AppStore
	extends Store,
		collections.ApiStoreCollection,
		collections.AuthStoreCollection,
		collections.MenuStoreCollection,
		collections.RouteStoreCollection,
		collections.UserStoreCollection {}

/**
 * Map state app store to props
 *
 * @export
 * @param {State} state
 */
export const mapStateAppStoreToProps = (state: State) => ({
	api: state.app.api,
	auth: state.app.auth,
	menu: state.app.menu,
	route: state.app.route,
	user: state.app.user
});

/**
 * App store default values
 *
 * @export
 * @constant
 */
export const AppStoreDefault: AppStore = {
	...collections.apiStoreCollectionDefault,
	...collections.authStoreCollectionDefault,
	...collections.menuStoreCollectionDefault,
	...collections.routeStoreCollectionDefault,
	...collections.userStoreCollectionDefault
};

export interface AppDevice {
	id: string;
	ip?: string;
	isp?: string;
	org?: string;
	host?: string;
	lat?: number;
	lon?: number;
	locale?: string;
	timezone?: string;
	postal?: string;
	city?: string;
	subdivision?: string;
	country?: string;
	countryName?: string;
	continent?: string;
	continentName?: string;
	geo?: GeoIpLookup;
}
let appDevice: AppDevice = {
	id: '4ec2aaf8-0f42-48f5-b781-174d0567582c'
};

export const getAppDevice = (): AppDevice => appDevice;

export const updateAppDevice = async (): Promise<AppDevice> => {
	let lat: number | undefined, lon: number | undefined;

	// try to get the local lat / lon if device is allowing
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function (position) {
			lat = position.coords.latitude;
			lon = position.coords.longitude;
		});
	}

	// get geo info from geoiplookup.io if possible
	let response: AxiosResponse = await axios.get<GeoIpLookup>(
		'https://json.geoiplookup.io'
	);
	console.log(response.data);
	let geo: GeoIpLookup = response.data;

	appDevice = {
		id: appDevice.id,
		geo,
		ip: geo.ip,
		isp: geo.isp,
		org: geo.org,
		host: geo.hostname,
		lat: lat || geo.latitude,
		lon: lon || geo.longitude,
		locale: navigator.languages[0] || 'en',
		timezone:
			Intl.DateTimeFormat().resolvedOptions().timeZone || geo.timezone_name,
		postal: geo.postal_code,
		city: geo.city,
		subdivision: geo.region,
		country: geo.country_code,
		countryName: geo.country_name,
		continent: geo.continent_code,
		continentName: geo.continent_name
	};

	console.log(appDevice);
	return appDevice;
};

export interface GeoIpLookup {
	ip: string;
	isp: string;
	org: string;
	hostname: string;
	latitude: number;
	longitude: number;
	postal_code: string;
	city: string;
	country_code: string;
	country_name: string;
	continent_code: string;
	continent_name: string;
	region: string;
	district: string;
	timezone_name: string;
	connection_type: string;
	asn_number: number;
	asn_org: string;
	asn: string;
	currency_code: string;
	currency_name: string;
	success: boolean;
	premium: boolean;
}
