import React, { useState, useEffect } from 'react';
import {
	IonText,
	IonIcon,
	IonSpinner,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonLabel,
	IonLoading
} from '@ionic/react';
import * as icons from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useToast } from '@agney/ir-toast';

import { UserAccountCard } from '../components';

import { useReactHref, UserEntity } from '../../store';
import { useCtx } from '../../../config/hooks';

const AuthorizeView: React.FC = () => {
	const [authIsProcessing, setAuthIsProcessing] = useState(false);
	const [authIsProcessed, setAuthIsProcessed] = useState(false);
	const [authHrefProcessd, setAuthHrefProcessed] = useState('');

	const ctx = useCtx<{}>({});
	const activeAuth = ctx.app.auth.active();
	const activeUser = ctx.app.user.active();

	const history = useHistory();

	const Toast = useToast();

	const href = useReactHref();

	const authenticate = async () => {
		await ctx.app.auth
			.handleRedirectCallback(ctx, (user: UserEntity) => {
				console.log(user);
				let authRedirect = localStorage.getItem('authRedirect') || '/main';
				localStorage.removeItem('authRedirect');
				history.push(authRedirect);
			})
			.catch(e => {
				Toast.error(e.message);
				//history.push('/home');
			});
	};

	//on init
	useEffect(() => {
		// check for authorize callback access_token and state variables in the incomming window location href
		// if existing handle the auth redirect callback if not already handled
		if (
			activeAuth &&
			href !== authHrefProcessd &&
			href.includes('access_token=') &&
			href.includes('state=') &&
			!authIsProcessed
		) {
			setAuthIsProcessing(true);
			authenticate();
			setAuthHrefProcessed(href); // set this href as the new authenticated Href
			setAuthIsProcessing(false);
			setAuthIsProcessed(true);
		}
	}, [href, authHrefProcessd, activeAuth]);

	return !activeAuth ? (
		<IonText>
			No auth configured
			<IonIcon color="danger" icon={icons.warningOutline} />
		</IonText>
	) : authIsProcessing ? (
		<>
			<IonLoading
				cssClass="my-custom-class"
				isOpen={true}
				message={'<h2>Loading</h2>Please wait...'}
				duration={3500}
			/>
			<IonText>
				Authorizing
				<IonSpinner />
			</IonText>
		</>
	) : !activeUser ? (
		<>
			<IonLoading
				cssClass="my-custom-class"
				isOpen={true}
				message={'<h2>Loading</h2>Please wait...'}
				duration={3500}
			/>
			<IonText hidden={true}>
				<IonButton
					class="text-transform-none"
					color="medium"
					fill="outline"
					onClick={() => {
						ctx.app.auth.loginWithRedirect();
					}}
				>
					<IonIcon
						className="ion-hide-sm-down"
						slot="end"
						icon={icons.logInOutline}
					/>
					<IonLabel>Sign In</IonLabel>
				</IonButton>
			</IonText>
		</>
	) : (
		<IonGrid>
			<IonRow>
				<IonCol>
					{activeUser.auth[activeAuth.id].isAuthenticated ? (
						<IonText>
							Authenticated
							<IonIcon color="success" icon={icons.checkmarkCircleOutline} />
						</IonText>
					) : (
						<IonText>
							Not Authenticated
							<IonIcon color="warning" icon={icons.warningOutline} />
						</IonText>
					)}
				</IonCol>
			</IonRow>

			<IonRow>
				<IonCol>
					<UserAccountCard finish={() => {}} />
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default AuthorizeView;
