import React from 'react';
import {
	IonIcon,
	IonButton,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonText,
	IonCardSubtitle,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol
} from '@ionic/react';

import * as icons from 'ionicons/icons';
import _ from 'lodash';

import { UserAvatar } from '../../components';

import { useAuth, useLocaleConfig } from '../../../store';

import { userMenuConfig } from '../../../../config/app/components/UserMenu';

export interface UserMenuConfigProps {
	signIn: string;
	manageUser: string;
	addUser: string;
	signoutAll: string;
	settings: string;
	viewAccount: string;
}

export interface UserMenuProps {
	finish: any;
}

export const UserMenu: React.FC<UserMenuProps> = props => {
	const {
		config: [config],
		activeUser,
		userHelper
	} = useLocaleConfig<UserMenuConfigProps>(userMenuConfig);

	const { authHelper, activeAuth } = useAuth();

	let users = userHelper.all();

	const finish = () => {
		if (_.isFunction(props.finish)) props.finish();
	};

	const login = () => {
		if (activeAuth) {
			authHelper.loginWithRedirect();
		}
	};

	const addUser = () => {
		if (activeAuth) {
			// login with a new auth session, will logout the user first automaticallly
			authHelper.newLoginWithRedirect();
		}
	};

	const signoutAll = () => {
		if (activeAuth) {
			// signout all user accounts from the app user collection
			userHelper.signout(userHelper.collection.allIds);
			// logout the auth session for the current user
			authHelper.logout();
		}
	};

	return (
		<IonList key="user-menu">
			{users.length === 0 ? (
				<IonItem
					key="sign-out-all-users-nologin"
					lines="none"
					className="ion-align-items-center"
				>
					<IonGrid key="sign-out-all-users-grid">
						<IonRow key="sign-out-all-users-row">
							<IonCol key="login-menu-center" className="ion-align-self-center">
								<IonButton
									key="usermenu-signIn-button"
									class="text-transform-none"
									color="medium"
									fill="outline"
									onClick={() => {
										login();
									}}
								>
									{config.signIn}
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			) : (
				<div key="user-menu-items">
					{activeUser && (
						<IonItem key="active-user" lines="full">
							<IonGrid key="active-user-grid">
								<IonRow>
									<IonCol size="3" className="ion-align-self-left">
										<UserAvatar user={activeUser} size="45px" />
									</IonCol>
									<IonCol size="9" className="ion-text-left">
										<IonLabel>{activeUser.name}</IonLabel>
										<IonLabel>
											<p>
												{activeUser.email === activeUser.name ? (
													<br />
												) : (
													activeUser.email
												)}
											</p>
										</IonLabel>
										<IonButton
											class="text-transform-none"
											color="medium"
											fill="outline"
											routerLink={'/account'}
											onClick={() => finish()}
										>
											{config.manageUser}
										</IonButton>
									</IonCol>
								</IonRow>
								<IonRow></IonRow>
								<IonRow>
									<IonCol className="ion-text-center"></IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					)}
					<IonItem
						key="view-account"
						className="ion-align-items-center"
						lines="full"
						routerLink="/lead-service/services"
						routerDirection="none"
						onClick={() => finish()}
						button
					>
						<IonIcon slot="start" icon={icons.cubeOutline} />
						<IonLabel color="dark">{config.viewAccount}</IonLabel>
					</IonItem>
					<IonItem
						key="view-settings"
						className="ion-align-items-center"
						lines="full"
						routerLink="/settings"
						routerDirection="none"
						onClick={() => finish()}
						button
					>
						<IonIcon slot="start" icon={icons.settings} />
						<IonLabel color="dark">{config.settings}</IonLabel>
					</IonItem>
					<IonItem
						key="add-user"
						lines="full"
						button
						onClick={() => {
							addUser();
							finish();
						}}
					>
						<IonIcon slot="start" icon={icons.personAdd} />
						<IonLabel color="dark">{config.addUser}</IonLabel>
					</IonItem>
					{users.map(user => {
						return (
							<div key={user.id + '-useritems'}>
								{activeUser && activeUser.id !== user.id && (
									<IonItem
										lines="full"
										button
										key={user.id}
										onClick={() => {
											userHelper.set(user.id);
											finish();
										}}
									>
										<UserAvatar slot="start" user={user} />
										<IonLabel
											color={
												activeUser && activeUser.id !== user.id
													? 'primary'
													: 'secondary'
											}
										>
											<h2>
												<IonText color="dark">{user.name}</IonText>
											</h2>
											<h3>
												<IonText color="medium">{user.email}</IonText>
											</h3>
										</IonLabel>
									</IonItem>
								)}
							</div>
						);
					})}
					<IonItem
						key="sign-out-all-users"
						className="ion-align-items-center"
						lines="full"
						onClick={() => {
							signoutAll();
							finish();
						}}
						button
					>
						<IonIcon slot="start" icon={icons.logOut} />
						<IonLabel color="dark">{config.signoutAll}</IonLabel>
					</IonItem>
				</div>
			)}
		</IonList>
	);
};
