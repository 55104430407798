import React, { useState } from 'react';
import {
	IonContent,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonToggle,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonButton
} from '@ionic/react';
import { Redirect } from 'react-router';
import _ from 'lodash';

import './UserControl.css';

import { useLocaleConfig, UserEntityPatch, insertParam } from '../../../store';

import { userSettingsConfig } from '../../../../config/app/components/UserSettings';

export interface UserSettingsConfigProps {
	display: {
		title: string;
		darkMode: string;
		menu: {
			collapse: string;
		};
		locale: string;
	};
	storage: {
		title: string;
		reset: string;
	};
}

export interface UserSettingsProps {
	finish: any;
}

export const UserSettings: React.FC<UserSettingsProps> = props => {
	const {
		config: [config],
		activeUser,
		userHelper
	} = useLocaleConfig<UserSettingsConfigProps>(userSettingsConfig);

	if (!activeUser) return <Redirect to="" />;

	const onChange = (e: any) => {
		let change: UserEntityPatch = _.set(
			{
				id: activeUser.id
			},
			e.target.name,
			!('checked' in e.target)
				? e.target.value
				: e.target.value === 'true'
				? !!e.target.checked
				: e.target.value === 'false'
				? !e.target.checked
				: !!e.target.checked
				? e.target.value
				: ''
		) as UserEntityPatch;

		userHelper.patch(change);
	};

	const resetLocalStorage = () => {
		insertParam('resetLS', 'true');
	};

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>{config.display.title}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem lines="full">
										<IonLabel slot="start">{config.display.darkMode}</IonLabel>
										<IonToggle
											slot="end"
											name="settings.theme"
											value="dark"
											checked={activeUser.settings?.theme === 'dark'}
											onClick={onChange}
											color="dark"
										/>
									</IonItem>
									<IonItem lines="full">
										<IonLabel slot="start">
											{config.display.menu.collapse}
										</IonLabel>
										<IonToggle
											slot="end"
											name="settings.menu.collapse"
											value="true"
											checked={
												activeUser.settings?.menu?.collapse === true
													? true
													: false
											}
											onClick={onChange}
											color="primary"
										/>
									</IonItem>
									<IonItem lines="full">
										<IonLabel slot="start">{config.display.locale}</IonLabel>
										<IonSelect
											slot="end"
											name="locale"
											value={activeUser.locale}
											onIonChange={onChange}
											color="primary"
										>
											<IonSelectOption value="en">en</IonSelectOption>
											<IonSelectOption value="fr">fr</IonSelectOption>
											<IonSelectOption value="es">es</IonSelectOption>
											<IonSelectOption value="de">de</IonSelectOption>
										</IonSelect>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>{config.storage.title}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem lines="full">
										<IonButton
											slot="end"
											color="danger"
											onClick={resetLocalStorage}
										>
											{config.storage.reset}
										</IonButton>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};
