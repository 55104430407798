import { EventContactHelper } from '..';
import { EventContactEntity } from '../collections';

export interface EventContactHook {
	eventContactHelper: EventContactHelper;
	activeEventContact: EventContactEntity | undefined;
}

export const useEventContact = (): EventContactHook => {
	let eventContactHelper = new EventContactHelper();
	return {
		eventContactHelper,
		activeEventContact: eventContactHelper.active()
	};
};
