import React from 'react';
import {
	IonButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonHeader,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonModal,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar
} from '@ionic/react';

import { LicenseServiceType } from '../../../store';

import {
	ServiceFilterObject,
	FilterConditionType
} from '../../views/AdminServicesView';

export interface AdminServiceFilterProps {
	showFilterModal: boolean;
	filter: ServiceFilterObject | undefined;
	onFilterChanged: (f: ServiceFilterObject) => void | undefined;
	onShowFilterModalChanged: (s: boolean) => void | undefined;
}

export const AdminServiceFilter: React.FC<AdminServiceFilterProps> = props => {
	const clearFilter = () => {
		if (props.onFilterChanged) {
			props.onFilterChanged({
				licenseTypes: [],
				activeDevices: FilterConditionType.Any
			});
		}
	};

	const onFilterLicenseTypesChanged = (e: any) => {
		e.persist();
		let value = e.target.value;
		let isChecked = e.target.checked;
		let newFilter = {
			...props.filter
		};
		if (!isChecked) {
			let i = newFilter.licenseTypes?.indexOf(value) ?? -1;
			if (i >= 0) newFilter.licenseTypes?.splice(i);
		} else {
			newFilter.licenseTypes?.push(value);
		}

		if (props.onFilterChanged) {
			props.onFilterChanged(newFilter);
		}
	};

	const onFilterActiveDevicesChanged = (e: any) => {
		let newFilter = {
			...props.filter
		};

		newFilter.activeDevices = e.detail.value;
		//props.filter = newFilter;
		if (props.onFilterChanged) {
			props.onFilterChanged(newFilter);
		}

		return true;
	};

	return (
		<IonModal
			cssClass="filter-modal"
			isOpen={props.showFilterModal}
			onDidDismiss={() => {
				if (props.onShowFilterModalChanged) {
					props.onShowFilterModalChanged(false);
				}
			}}
		>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton
							onClick={(e: any) => {
								e.persist();
								clearFilter();
							}}
						>
							Clear
						</IonButton>
					</IonButtons>
					<IonTitle>Filter Sessions</IonTitle>
					<IonButtons slot="end">
						<IonButton
							onClick={(e: any) => {
								e.persist();
								if (props.onShowFilterModalChanged) {
									props.onShowFilterModalChanged(false);
								}
							}}
						>
							Close
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<IonItemDivider>
						<IonLabel>Product Type</IonLabel>
					</IonItemDivider>
					<IonItem>
						<IonLabel>Lead App</IonLabel>
						<IonCheckbox
							key={'filter_' + LicenseServiceType.LeadApp}
							checked={props.filter?.licenseTypes?.includes(
								LicenseServiceType.LeadApp
							)}
							value={LicenseServiceType.LeadApp}
							onClick={onFilterLicenseTypesChanged}
						></IonCheckbox>
					</IonItem>
					<IonItem>
						<IonLabel>Opticon</IonLabel>
						<IonCheckbox
							key={'filter_' + LicenseServiceType.Opticon}
							checked={props.filter?.licenseTypes?.includes(
								LicenseServiceType.Opticon
							)}
							value={LicenseServiceType.Opticon}
							onClick={onFilterLicenseTypesChanged}
						></IonCheckbox>
					</IonItem>
					<IonItem>
						<IonLabel>Lead Api</IonLabel>
						<IonCheckbox
							key={'filter_' + LicenseServiceType.LeadApi}
							checked={props.filter?.licenseTypes?.includes(
								LicenseServiceType.LeadApi
							)}
							value={LicenseServiceType.LeadApi}
							onClick={onFilterLicenseTypesChanged}
						></IonCheckbox>
					</IonItem>
					<IonItem>
						<IonLabel>SST</IonLabel>
						<IonCheckbox
							key={'filter_' + LicenseServiceType.Sst}
							checked={props.filter?.licenseTypes?.includes(
								LicenseServiceType.Sst
							)}
							value={LicenseServiceType.Sst}
							onClick={onFilterLicenseTypesChanged}
						></IonCheckbox>
					</IonItem>
					<IonItemDivider>
						<IonLabel>Device Activity</IonLabel>
					</IonItemDivider>
					<IonItem>
						<IonSegment
							onIonChange={onFilterActiveDevicesChanged}
							value={props.filter?.activeDevices}
						>
							<IonSegmentButton value={FilterConditionType.Any}>
								Any
							</IonSegmentButton>
							<IonSegmentButton value={FilterConditionType.DoesNotContain}>
								No Activity
							</IonSegmentButton>
							<IonSegmentButton value={FilterConditionType.Contains}>
								Has Activity
							</IonSegmentButton>
						</IonSegment>
					</IonItem>
				</IonList>
			</IonContent>
		</IonModal>
	);
};
