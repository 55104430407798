import { Localize, translations } from '../../locales';
import { HeaderConfigProps } from '../../../app/ui/containers/Header';

export type HeaderConfig = Localize<HeaderConfigProps>;

export const headerConfig: HeaderConfig = {
	en: {
		signIn: translations.signIn.en
	},
	fr: {
		signIn: translations.signIn.fr
	}
};
