import React, { useEffect } from 'react';
import { IonText, IonList } from '@ionic/react';

import { ServiceListItem } from '../../components/Service';
import { ServiceEntity, ServiceEntities } from '../../../store';
import { useCtx } from '../../../../config/hooks';

export interface ServiceListProps {
	services: ServiceEntities;
	allowSelecting?: boolean;
	onItemSelected?: (selectedId: string) => void;
	onManageBtnClicked?: (selectedId: string) => void;
	managementPage?: string;
}

export const ServiceList: React.FC<ServiceListProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: { serviceHelper }
	} = ctx;

	const services: ServiceEntities =
		props.services || serviceHelper.allByUserId(activeUser?.userId);

	const serviceListItems = services.map((service: ServiceEntity) => {
		return (
			<ServiceListItem
				isSelectable={props.allowSelecting}
				service={service}
				key={service.id}
				onItemClicked={props.onItemSelected}
				onManageBtnClicked={props.onManageBtnClicked}
				managementPage={props.managementPage}
			/>
		);
	});

	return <IonList>{serviceListItems}</IonList>;
};
