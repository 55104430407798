import {
	ResponseCollection,
	ResponseCollectionState,
	ResponseId,
	ResponseEntity_Some,
	ResponseEntityPatch_Some,
	ResponseId_Some,
	responseCollectionDefault,
	responseCollectionMutateIdOpts
} from '../collections/Response';
import * as actions from '../actions/Response';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Response reducer
 *
 * @export
 * @param {ResponseCollection} [state=responseCollectionDefault]
 * @param {ResponseActionTypes} action
 * @returns
 */
export function responseReducer(
	state: ResponseCollection = responseCollectionDefault,
	action: actions.ResponseActionTypes
) {
	switch (action.type) {
		case actions.RESPONSE_MUTATE_ID:
			return collectionReducerActions.mutateId<ResponseCollection>(
				state,
				action.payload,
				responseCollectionMutateIdOpts
			);
		case actions.RESPONSE_SET:
			return collectionReducerActions.set<ResponseCollection, ResponseId>(
				state,
				action.payload
			);
		case actions.RESPONSE_TOGGLE:
			return collectionReducerActions.toggle<ResponseCollection, ResponseId>(
				state,
				action.payload
			);
		case actions.RESPONSE_SELECT:
			return collectionReducerActions.select<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_DESELECT:
			return collectionReducerActions.deselect<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_UPSERT:
			return collectionReducerActions.upsert<
				ResponseCollection,
				ResponseEntity_Some
			>(state, action.payload);
		case actions.RESPONSE_PATCH:
			return collectionReducerActions.patch<
				ResponseCollection,
				ResponseEntityPatch_Some
			>(state, action.payload);
		case actions.RESPONSE_CHANGE:
			return collectionReducerActions.change<
				ResponseCollection,
				ResponseEntityPatch_Some
			>(state, action.payload);
		case actions.RESPONSE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_CACHE:
			return collectionReducerActions.cache<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_UNCACHE:
			return collectionReducerActions.uncache<
				ResponseCollection,
				ResponseId_Some
			>(state, action.payload);
		case actions.RESPONSE_SETSTATE:
			return collectionReducerActions.setState<
				ResponseCollection,
				ResponseCollectionState
			>(state, action.payload.state);
		case actions.RESPONSE_PATCHSTATE:
			return collectionReducerActions.patchState<
				ResponseCollection,
				ResponseCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
