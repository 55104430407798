// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The category for the ticket
 * @export
 * @enum {string}
 */
export enum TicketCategory {
	Access = 'access',
	Scanning = 'scanning',
	Notes = 'notes',
	Qualifiers = 'qualifiers',
	Download = 'download',
	Reports = 'reports',
	Error = 'error'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesTicketCategory = [
	'access',
	'scanning',
	'notes',
	'qualifiers',
	'download',
	'reports',
	'error'
];
