import { LicenseHelper } from '..';
import { LicenseEntity } from '../collections';

export interface LicenseHook {
	licenseHelper: LicenseHelper;
	activeLicense: LicenseEntity | undefined;
}

export const useLicense = (): LicenseHook => {
	let licenseHelper = new LicenseHelper();
	return {
		licenseHelper,
		activeLicense: licenseHelper.active()
	};
};
