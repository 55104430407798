// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * QUALIFIER: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { QualifierHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { QualifierEntity, QualifierEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum QualifierApiOperation {
	readQualifiers = 'readQualifiers',
	writeQualifiers = 'writeQualifiers'
}

export interface ReadQualifiersRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventIds?: string[];
	ids?: string[];
}

export interface WriteQualifiersRequest {
	userId: string;
	serviceId: string;
	qualifiers: QualifierEntities;
}

/**
 * Read qualifiers
 */
export async function readQualifiers(
	ctx: UseCtx<any>,
	requestParameters: ReadQualifiersRequest
): Promise<QualifierEntities> {
	const operationId = QualifierApiOperation.readQualifiers,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<QualifierEntities, ReadQualifiersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: QualifierEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<QualifierEntities, QualifierHelper>(
		ctx.lead.qualifier,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.qualifier.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Qualifiers
 */
export async function writeQualifiers<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteQualifiersRequest
): Promise<QualifierEntities> {
	const operationId = QualifierApiOperation.writeQualifiers,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.qualifiers.forEach(
		(entity: QualifierEntity, i: number, a: QualifierEntities) => {
			a[i] = modifyEntity<QualifierEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<QualifierEntities, WriteQualifiersRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'qualifiers'
	);

	let entities: QualifierEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<QualifierEntities, QualifierHelper>(
		ctx.lead.qualifier,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.qualifier.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
