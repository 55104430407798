import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { licenseConfig } from '../../../config/lead/License';
import { License } from '../models';

/**
 * License entity interface
 *
 * @export
 * @interface LicenseEntity
 * @extends {storage.Entity}
 */
export interface LicenseEntity extends storage.Entity, License {}

/**
 * License entities array
 *
 * @export
 */
export type LicenseEntities = LicenseEntity[];

/**
 * Some license entities: one entity or an array of entities
 *
 * @export
 */
export type LicenseEntity_Some = LicenseEntity | LicenseEntities;

/**
 * License entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface LicenseEntityPatch
 * @extends {storage.EntityPatch<LicenseEntity>}
 */
export type LicenseEntityPatch = storage.EntityPatcher<LicenseEntity>;

/**
 * License entity patches array
 *
 * @export
 */
export type LicenseEntitiesPatch = LicenseEntityPatch[];

/**
 * Some license entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type LicenseEntityPatch_Some = LicenseEntityPatch | LicenseEntitiesPatch;

/**
 * License entity id
 *
 * @export
 */
export type LicenseId = storage.EntityId;

/**
 * License entity ids
 *
 * @export
 */
export type LicenseIds = storage.EntityIds;

/**
 * Some license entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type LicenseId_Some = LicenseId | LicenseIds;

/**
 * License entities object by id
 *
 * @export
 * @interface LicenseEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface LicenseEntitiesObject extends storage.EntitiesObject {
	[id: string]: LicenseEntity;
}

/**
 * License entity state
 *
 * @export
 * @interface LicenseEntityState
 * @extends {storage.EntityState}
 */
export interface LicenseEntityState extends storage.EntityState {}

/**
 * License store collection
 *
 * @export
 * @interface LicenseStoreCollection
 * @extends {storage.Store}
 */
export interface LicenseStoreCollection extends storage.Store {
	license: LicenseCollection;
}

/**
 * License collection properties
 *
 * @export
 * @interface LicenseCollection
 * @extends {Collection}
 */
export interface LicenseCollection extends storage.Collection {
	byIds: LicenseEntitiesObject;
	mutation: LicenseEntitiesObject;
	cache: LicenseEntitiesObject;
	state: LicenseCollectionState;
}

/**
 * License collection state
 *
 * @export
 * @interface LicenseCollectionState
 * @extends {storage.CollectionState}
 */
export interface LicenseCollectionState extends storage.CollectionState {}

// custom license collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const licenseCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const licenseCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const licenseCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const licenseCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: licenseCollectionIdStringProps,
	idArrayProps: licenseCollectionIdArrayProps,
	idEntityProps: licenseCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * License collection default values, initialize collection with license entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const licenseCollectionDefault: LicenseCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as LicenseCollection),
		...{
			activeId: licenseConfig.activeId || licenseConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	licenseConfig.entities
);

/**
 * License store collection default values
 *
 * @export
 * @constant
 */
export const licenseStoreCollectionDefault: LicenseStoreCollection = {
	license: licenseCollectionDefault
};

/**
 * License store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getLicenseCollection = (state: any) => state.lead.license;
