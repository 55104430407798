import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { menuConfig } from '../../../config/app/Menu';
import { MenuConfigProps } from '../../ui/components/Menu';
import { Menu } from '../models';

/**
 * Menu entity interface
 *
 * @export
 * @interface MenuEntity
 * @extends {storage.Entity}
 * @extends {Menu}
 */
export interface MenuEntity extends storage.Entity, Menu {}

/**
 * Menu entities array
 *
 * @export
 */
export type MenuEntities = MenuEntity[];

/**
 * Some menu entities: one entity or an array of entities
 *
 * @export
 */
export type MenuEntity_Some = MenuEntity | MenuEntities;

/**
 * Menu entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface MenuEntityPatch
 * @extends {storage.EntityPatch<MenuEntity>}
 */
export type MenuEntityPatch = storage.EntityPatcher<MenuEntity>;

/**
 * Menu entity patches array
 *
 * @export
 */
export type MenuEntitiesPatch = MenuEntityPatch[];

/**
 * Some menu entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type MenuEntityPatch_Some = MenuEntityPatch | MenuEntitiesPatch;

/**
 * Menu entity id
 *
 * @export
 */
export type MenuId = storage.EntityId;

/**
 * Menu entity ids
 *
 * @export
 */
export type MenuIds = storage.EntityIds;

/**
 * Some menu entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type MenuId_Some = MenuId | MenuIds;

/**
 * Menu entities object by id
 *
 * @export
 * @interface MenuEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface MenuEntitiesObject extends storage.EntitiesObject {
	[id: string]: MenuEntity;
}

/**
 * Menu entity state
 *
 * @export
 * @interface MenuEntityState
 * @extends {storage.EntityState}
 */
export interface MenuEntityState extends storage.EntityState {}

/**
 * Menu store collection
 *
 * @export
 * @interface MenuStoreCollection
 * @extends {storage.Store}
 */
export interface MenuStoreCollection extends storage.Store {
	menu: MenuCollection;
}

/**
 * Menu collection properties
 *
 * @export
 * @interface MenuCollection
 * @extends {Collection}
 */
export interface MenuCollection extends storage.Collection {
	byIds: MenuEntitiesObject;
	mutation: MenuEntitiesObject;
	cache: MenuEntitiesObject;
	state: MenuCollectionState;
}

/**
 * Menu collection state
 *
 * @export
 * @interface MenuCollectionState
 * @extends {storage.CollectionState}
 */
export interface MenuCollectionState extends storage.CollectionState {
	instances?: MenuStateInstances;
}

/**
 * Tracking state of menu instances by menu component instanceId
 *
 * @export
 * @interface MenuStateInstances
 */
export interface MenuStateInstances {
	[instanceId: string]: MenuConfigProps;
}

// custom menu collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const menuCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const menuCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const menuCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const menuCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: menuCollectionIdStringProps,
	idArrayProps: menuCollectionIdArrayProps,
	idEntityProps: menuCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Menu collection default values, initialize collection with menu entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const menuCollectionDefault: MenuCollection = collectionInitializer.upsert(
	<MenuCollection>{
		...(collectionDefault as MenuCollection),
		...{
			activeId: menuConfig.activeId || menuConfig.entities[0]?.id
			//customIds: [];
			//customByIds: {};
		}
	},
	menuConfig.entities
);

/**
 * Menu store collection default values
 *
 * @export
 * @constant
 */
export const menuStoreCollectionDefault: MenuStoreCollection = {
	menu: menuCollectionDefault
};

/**
 * Menu store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getMenuCollection = (state: any) => state.app.menu;
