import React, { useEffect, useState } from 'react';

import { ChatConfig } from '../../../config/app/components/Chat';
import { useCtx } from '../../../config/hooks';
import objectHash from 'object-hash';
import _ from 'lodash';

export interface ChatConfigProps {
	text?: string;
}

export enum ChatType {
	TawkTo = 'tawk-to'
}

export interface ChatProps {
	type: ChatType;
	chatId: string;
	chatKey: string;
}

const tawkToElementId = 'tawkId_main_script';

export interface ChatAttributes extends Object {
	[key: string]: any;
}

export const Chat: React.FC<ChatProps> = props => {
	const {
		config: [config],
		app: { activeUser, activeRoute },
		lead: { activeService, activeEvent, activeContext }
	} = useCtx<ChatConfigProps>(ChatConfig);

	const [visible, setVisible] = useState(true);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (props.type === ChatType.TawkTo) {
			let tawkApi = tawkTo(
				props.chatId,
				props.chatKey,
				activeUser?.name,
				activeUser?.email
			);

			let attributes: ChatAttributes = _.pickBy(
				{
					name: activeUser?.name,
					email: activeUser?.email,
					userId: activeUser?.userId,
					serviceId: activeService?.id,
					service: activeService?.name,
					eventId: activeEvent?.id,
					event: activeEvent?.name,
					contextId: activeContext?.id,
					context: activeContext?.name
				},
				_.isString
			);

			let attributesHash = objectHash(attributes);

			// set Attributes imediately if already loaded and they have changed
			if ((window as WindowTawkTo).Tawk_Loaded === true) {
				if (!loaded) setLoaded(false);
				if (
					(window as WindowTawkTo).Tawk_AttributesHash !== attributesHash &&
					tawkApi &&
					tawkApi.setAttributes
				) {
					tawkApi.setAttributes(attributes, function (error?: Error) {
						if (error) {
							console.error(error);
							delete (window as WindowTawkTo).Tawk_AttributesHash;
						}
					});
					(window as WindowTawkTo).Tawk_AttributesHash = attributesHash;
				}
				// otherwise set them on load
			} else if (!tawkApi.onLoad) {
				tawkApi.onLoad = function () {
					tawkApi.setAttributes(attributes, function (error: Error) {
						if (error) {
							console.error(error);
							delete (window as WindowTawkTo).Tawk_AttributesHash;
						}
					});
					(window as WindowTawkTo).Tawk_AttributesHash = attributesHash;
					(window as WindowTawkTo).Tawk_Loaded = true;
					setLoaded(true);
				};
			} else {
				if (loaded) setLoaded(false);
			}

			if (loaded) {
				if (activeRoute?.chat?.disabled === true) {
					if (visible === true) {
						setVisible(false);
						if (tawkApi.hideWidget) tawkApi.hideWidget();
					}
				} else if (visible === false) {
					setVisible(true);
					tawkApi.showWidget();
				}
			}
		}
	}, [
		loaded,
		activeUser,
		activeService,
		activeEvent,
		activeContext,
		activeRoute
	]);

	return <></>;
};

interface WindowTawkTo extends Window {
	Tawk_API?: any;
	Tawk_LoadStart?: Date;
	Tawk_Id?: string;
	Tawk_Loaded?: boolean;
	Tawk_AttributesHash?: string;
}

const tawkTo = (
	propertyId: string,
	clientKey: string,
	name?: string,
	email?: string
): any => {
	if (!window) {
		throw new Error('DOM is unavailable for Tawk.to');
	}

	let tawkId: string = objectHash({ propertyId, clientKey, name, email });

	let tawk = document.getElementById(
		tawkToElementId
	) as HTMLScriptElement | null;

	// if the tawk exists but the tawk identity is differnt. kill the tawk script element and remove the Tawk window settings.
	// change in propertyId, key or user name or email should cause reload
	if (
		!(window as WindowTawkTo).Tawk_API ||
		(tawk && (window as WindowTawkTo).Tawk_Id !== tawkId)
	) {
		if (tawk) {
			tawk.remove();
			tawk = null;
		}
		delete (window as WindowTawkTo).Tawk_API;
		delete (window as WindowTawkTo).Tawk_LoadStart;
		delete (window as WindowTawkTo).Tawk_Id;
		delete (window as WindowTawkTo).Tawk_Loaded;
		delete (window as WindowTawkTo).Tawk_AttributesHash;
	}

	// if tawk not loaded or was removed for reload then load it
	if (!tawk) {
		(window as WindowTawkTo).Tawk_API = {};
		(window as WindowTawkTo).Tawk_LoadStart = new Date();
		(window as WindowTawkTo).Tawk_Id = tawkId;
		(window as WindowTawkTo).Tawk_Loaded = false;

		const script = document.createElement('script');
		script.id = tawkToElementId;
		script.async = true;
		script.src = 'https://embed.tawk.to/' + propertyId + '/' + clientKey;
		script.setAttribute('crossorigin', '*');

		const first_script_tag = document.getElementsByTagName('script')[0];
		if (!first_script_tag || !first_script_tag.parentNode) {
			throw new Error('DOM is unavailable for Tawk.to');
		}

		first_script_tag.parentNode.insertBefore(script, first_script_tag);
	}

	// Prevent TawkTo to create root script if it already exists
	return (window as WindowTawkTo).Tawk_API;
};
