import { ResponseHelper } from '..';
import { ResponseEntity } from '../collections';

export interface ResponseHook {
	responseHelper: ResponseHelper;
	activeResponse: ResponseEntity | undefined;
}

export const useResponse = (): ResponseHook => {
	let responseHelper = new ResponseHelper();
	return {
		responseHelper,
		activeResponse: responseHelper.active()
	};
};
