import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { ticketConfig } from '../../../config/lead/Ticket';
import { Ticket } from '../models';

/**
 * Ticket entity interface
 *
 * @export
 * @interface TicketEntity
 * @extends {storage.Entity}
 */
export interface TicketEntity extends storage.Entity, Ticket {}

/**
 * Ticket entities array
 *
 * @export
 */
export type TicketEntities = TicketEntity[];

/**
 * Some ticket entities: one entity or an array of entities
 *
 * @export
 */
export type TicketEntity_Some = TicketEntity | TicketEntities;

/**
 * Ticket entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface TicketEntityPatch
 * @extends {storage.EntityPatch<TicketEntity>}
 */
export type TicketEntityPatch = storage.EntityPatcher<TicketEntity>;

/**
 * Ticket entity patches array
 *
 * @export
 */
export type TicketEntitiesPatch = TicketEntityPatch[];

/**
 * Some ticket entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type TicketEntityPatch_Some = TicketEntityPatch | TicketEntitiesPatch;

/**
 * Ticket entity id
 *
 * @export
 */
export type TicketId = storage.EntityId;

/**
 * Ticket entity ids
 *
 * @export
 */
export type TicketIds = storage.EntityIds;

/**
 * Some ticket entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type TicketId_Some = TicketId | TicketIds;

/**
 * Ticket entities object by id
 *
 * @export
 * @interface TicketEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface TicketEntitiesObject extends storage.EntitiesObject {
	[id: string]: TicketEntity;
}

/**
 * Ticket entity state
 *
 * @export
 * @interface TicketEntityState
 * @extends {storage.EntityState}
 */
export interface TicketEntityState extends storage.EntityState {}

/**
 * Ticket store collection
 *
 * @export
 * @interface TicketStoreCollection
 * @extends {storage.Store}
 */
export interface TicketStoreCollection extends storage.Store {
	ticket: TicketCollection;
}

/**
 * Ticket collection properties
 *
 * @export
 * @interface TicketCollection
 * @extends {Collection}
 */
export interface TicketCollection extends storage.Collection {
	byIds: TicketEntitiesObject;
	mutation: TicketEntitiesObject;
	cache: TicketEntitiesObject;
	state: TicketCollectionState;
}

/**
 * Ticket collection state
 *
 * @export
 * @interface TicketCollectionState
 * @extends {storage.CollectionState}
 */
export interface TicketCollectionState extends storage.CollectionState {}

// custom ticket collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const ticketCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const ticketCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const ticketCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const ticketCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: ticketCollectionIdStringProps,
	idArrayProps: ticketCollectionIdArrayProps,
	idEntityProps: ticketCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Ticket collection default values, initialize collection with ticket entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const ticketCollectionDefault: TicketCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as TicketCollection),
		...{
			activeId: ticketConfig.activeId || ticketConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	ticketConfig.entities
);

/**
 * Ticket store collection default values
 *
 * @export
 * @constant
 */
export const ticketStoreCollectionDefault: TicketStoreCollection = {
	ticket: ticketCollectionDefault
};

/**
 * Ticket store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getTicketCollection = (state: any) => state.lead.ticket;
