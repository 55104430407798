import { ComponentsConfig, componentsConfig } from './components';
import { ThemesConfig, themesConfig } from './themes';
import { ApiConfig, apiConfig } from './Api';
import { AuthConfig, authConfig } from './Auth';
import { MenuConfig, menuConfig } from './Menu';
import { RouteConfig, routeConfig } from './Route';
import { UserConfig, userConfig } from './User';

export interface AppConfig {
	components: ComponentsConfig;
	themes: ThemesConfig;
	api: ApiConfig;
	auth: AuthConfig;
	menu: MenuConfig;
	route: RouteConfig;
	user: UserConfig;
}

export const appConfig: AppConfig = {
	components: componentsConfig,
	themes: themesConfig,
	api: apiConfig,
	auth: authConfig,
	menu: menuConfig,
	route: routeConfig,
	user: userConfig
};
