// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * RESPONSE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ResponseHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ResponseEntity, ResponseEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum ResponseApiOperation {
	readResponses = 'readResponses',
	writeResponses = 'writeResponses'
}

export interface ReadResponsesRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	leadIds?: string[];
	ids?: string[];
}

export interface WriteResponsesRequest {
	userId: string;
	serviceId: string;
	responses: ResponseEntities;
}

/**
 * Read responses
 */
export async function readResponses(
	ctx: UseCtx<any>,
	requestParameters: ReadResponsesRequest
): Promise<ResponseEntities> {
	const operationId = ResponseApiOperation.readResponses,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ResponseEntities, ReadResponsesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: ResponseEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ResponseEntities, ResponseHelper>(
		ctx.lead.response,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.response.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Responses
 */
export async function writeResponses<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteResponsesRequest
): Promise<ResponseEntities> {
	const operationId = ResponseApiOperation.writeResponses,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.responses.forEach(
		(entity: ResponseEntity, i: number, a: ResponseEntities) => {
			a[i] = modifyEntity<ResponseEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<ResponseEntities, WriteResponsesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'responses'
	);

	let entities: ResponseEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ResponseEntities, ResponseHelper>(
		ctx.lead.response,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.response.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
