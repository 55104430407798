import * as storage from '../../../storage';
import { collectionDefault } from '../../../storage/constants';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { responseConfig } from '../../../config/lead/Response';
import { Response } from '../models';
import { CollectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Response entity interface
 *
 * @export
 * @interface ResponseEntity
 * @extends {storage.Entity}
 * @extends {Response}
 */
export interface ResponseEntity extends storage.Entity, Response {}

/**
 * Response entities array
 *
 * @export
 */
export type ResponseEntities = ResponseEntity[];

/**
 * Some response entities: one entity or an array of entities
 *
 * @export
 */
export type ResponseEntity_Some = ResponseEntity | ResponseEntities;

/**
 * Response entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ResponseEntityPatch
 * @extends {storage.EntityPatch<ResponseEntity>}
 */
export type ResponseEntityPatch = storage.EntityPatcher<ResponseEntity>;

/**
 * Response entity patches array
 *
 * @export
 */
export type ResponseEntitiesPatch = ResponseEntityPatch[];

/**
 * Some response entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ResponseEntityPatch_Some =
	| ResponseEntityPatch
	| ResponseEntitiesPatch;

/**
 * Response entity id
 *
 * @export
 */
export type ResponseId = storage.EntityId;

/**
 * Response entity ids
 *
 * @export
 */
export type ResponseIds = storage.EntityIds;

/**
 * Some response entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ResponseId_Some = ResponseId | ResponseIds;

/**
 * Response entities object by id
 *
 * @export
 * @interface ResponseEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ResponseEntitiesObject extends storage.EntitiesObject {
	[id: string]: ResponseEntity;
}

/**
 * Response entity state
 *
 * @export
 * @interface ResponseEntityState
 * @extends {storage.EntityState}
 */
export interface ResponseEntityState extends storage.EntityState {}

/**
 * Response store collection
 *
 * @export
 * @interface ResponseStoreCollection
 * @extends {storage.Store}
 */
export interface ResponseStoreCollection extends storage.Store {
	response: ResponseCollection;
}

/**
 * Response collection properties
 *
 * @export
 * @interface ResponseCollection
 * @extends {Collection}
 */
export interface ResponseCollection extends storage.Collection {
	byIds: ResponseEntitiesObject;
	mutation: ResponseEntitiesObject;
	cache: ResponseEntitiesObject;
	state: ResponseCollectionState;
	//customId: storage.EntityId;
	//customIds: storage.EntityIds;
	//customByIds: ResponseEntitiesObject;
}

/**
 * Response collection state
 *
 * @export
 * @interface ResponseCollectionState
 * @extends {storage.CollectionState}
 */
export interface ResponseCollectionState extends storage.CollectionState {}

// custom response collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const responseCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const responseCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const responseCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const responseCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: responseCollectionIdStringProps,
	idArrayProps: responseCollectionIdArrayProps,
	idEntityProps: responseCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Response collection default values
 *
 * @export
 * @constant
 */
export const responseCollectionDefault: ResponseCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ResponseCollection),
		...{
			activeId: responseConfig.activeId || responseConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	responseConfig.entities
);

/**
 * Response store collection default values
 *
 * @export
 * @constant
 */
export const responseStoreCollectionDefault: ResponseStoreCollection = {
	response: responseCollectionDefault
};

/**
 * Response store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getResponseCollection = (state: any) => state.lead.response;
