import React, { useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonList,
	IonItem,
	IonButton,
	IonLoading,
	IonIcon,
	IonCardTitle,
	IonCheckbox,
	IonLabel,
	IonInput,
	IonContent
} from '@ionic/react';

import {
	EventContactEntity,
	LicenseEntities,
	LicenseType,
	LicenseServiceType,
	ObjectType,
	EventContactOrganizationEntity,
	ObjectIds
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import { useToast } from '@agney/ir-toast';
import { sadOutline } from 'ionicons/icons';
import { newEntity } from '../../../../app/utils';
import uuidV4 from 'uuid/v4';

export interface ImportExhibitorsConfigProps {
	eventExhibitors?: EventContactOrganizationEntity[];
}

export const ImportExhibitors: React.FC<ImportExhibitorsConfigProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		app: { activeUser },
		lead: { eventContactHelper, licenseHelper, serviceHelper, activeContext }
	} = ctx;

	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);
	const [selectedExhibitors, setSelectedExhibitors] = useState<string[]>([]);
	const [exhibitorsImportPage, setExhibitorsImportPage] = useState<string>(
		'importlist'
	);
	const [exhibitorsImportValues, setExhibitorsImportValues] = useState<{
		appLicenses: number;
		opticonLicenses: number;
		sstLicenses: number;
		apiLicenses: number;
	}>({ appLicenses: 0, opticonLicenses: 0, sstLicenses: 0, apiLicenses: 0 });

	useEffect(() => {
		setSelectedExhibitors([]);
		setExhibitorsImportPage('importlist');
		setExhibitorsImportValues({
			appLicenses: 0,
			opticonLicenses: 0,
			sstLicenses: 0,
			apiLicenses: 0
		});
	}, [props.eventExhibitors]);

	const Toast = useToast();

	let selectExhibitorForImport = (val: string, checked: boolean) => {
		if (checked && !selectedExhibitors.includes(val)) {
			let copySelectedExhibitors = [...selectedExhibitors];
			copySelectedExhibitors.push(val);
			setSelectedExhibitors(copySelectedExhibitors);
		} else if (selectedExhibitors.includes(val)) {
			let i = selectedExhibitors.indexOf(val);
			if (i >= 0) {
				let copySelectedExhibitors = [...selectedExhibitors];
				copySelectedExhibitors.splice(i, 1);
				setSelectedExhibitors(copySelectedExhibitors);
			}
		}
	};
	const eventExhibitorsListItems = props.eventExhibitors
		?.filter(o => {
			let match = 0,
				matches = 0,
				keywords = '';

			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(o.name && o.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.code && o.code?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.email && o.email?.toLowerCase().trim().indexOf(keyword) > -1)
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.sort((a: EventContactEntity, b: EventContactEntity) => {
			return (a?.name?.toLowerCase() || '') > (b?.name?.toLowerCase() || '')
				? 1
				: (b?.name?.toLowerCase() || '') > (a?.name?.toLowerCase() || '')
				? -1
				: 0;
		})
		.map((eventContact: EventContactEntity) => {
			return (
				<IonItem key={eventContact.id}>
					<IonGrid>
						<IonRow>
							<IonCol size="2">
								<IonCheckbox
									color="primary"
									value={eventContact.id}
									checked={selectedExhibitors.includes(eventContact.id)}
									onClick={e => {
										selectExhibitorForImport(
											eventContact.id,
											e.currentTarget.checked
										);
									}}
								/>
							</IonCol>
							<IonCol size="10">{eventContact.name}</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	let importServicesFromEventContacts = async () => {
		if (
			selectedExhibitors.length > 0 &&
			(exhibitorsImportValues.apiLicenses > 0 ||
				exhibitorsImportValues.appLicenses > 0 ||
				exhibitorsImportValues.opticonLicenses > 0 ||
				exhibitorsImportValues.sstLicenses > 0)
		) {
			setWorkingStatus('Importing Exhibitors');
			for (let i = 0; i < selectedExhibitors.length; i++) {
				console.log('Block statement execution no.' + i);

				//Await some time to allow each call to run
				await new Promise(p => setTimeout(p, 1200));

				let eventContactId = selectedExhibitors[i];
				let ec = eventContactHelper.get_Organization(eventContactId);
				if (ec) {
					await serviceHelper.importServiceFromEventContact(
						ctx,
						ec,
						activeContext?.id || '',
						v => {
							Toast.error('Error:');
						},
						v => {
							if (ec) {
								//ec.serviceId = v.service.id;
								//eventContactHelper.write(ctx, {
								//	eventcontacts: [ec]
								//});

								let userIds: ObjectIds = v.service.userIds || [];
								if (activeUser?.id) userIds.concat(activeUser?.id);

								// get and create / update the licenses for the order
								let licenses: LicenseEntities = [];
								if (exhibitorsImportValues.apiLicenses > 0) {
									licenses.push({
										...newEntity(ctx, ObjectType.License),
										type: LicenseType.Device,
										serviceType: LicenseServiceType.LeadApi,
										contextId: v.context.id,
										serviceId: v.service.id,
										userIds: userIds,
										code: uuidV4(),
										name: 'API License',
										serviceName: 'API License',
										quantity: exhibitorsImportValues.apiLicenses,
										uses: 0
									});
								}
								if (exhibitorsImportValues.appLicenses > 0) {
									licenses.push({
										...newEntity(ctx, ObjectType.License),
										type: LicenseType.Device,
										serviceType: LicenseServiceType.LeadApp,
										contextId: v.context.id,
										serviceId: v.service.id,
										userIds: userIds,
										code: uuidV4(),
										name: 'Mobile APP License',
										serviceName: 'Mobile APP License',
										quantity: exhibitorsImportValues.appLicenses,
										uses: 0
									});
								}
								if (exhibitorsImportValues.opticonLicenses > 0) {
									licenses.push({
										...newEntity(ctx, ObjectType.License),
										type: LicenseType.Device,
										serviceType: LicenseServiceType.Opticon,
										contextId: v.context.id,
										serviceId: v.service.id,
										userIds: userIds,
										code: uuidV4(),
										name: 'Opticon License',
										serviceName: 'Opticon License',
										quantity: exhibitorsImportValues.opticonLicenses,
										uses: 0
									});
								}
								if (exhibitorsImportValues.sstLicenses > 0) {
									licenses.push({
										...newEntity(ctx, ObjectType.License),
										type: LicenseType.Device,
										serviceType: LicenseServiceType.Sst,
										contextId: v.context.id,
										serviceId: v.service.id,
										userIds: userIds,
										code: uuidV4(),
										name: 'SST License',
										serviceName: 'SST License',
										quantity: exhibitorsImportValues.sstLicenses,
										uses: 0
									});
								}
								if (licenses && licenses.length > 0) {
									// write new/update licenses
									licenseHelper.write(ctx, {
										licenses: licenses
									});
								}
							}
						}
					);
				}
			}
			setWorkingStatus(undefined);
		}
	};

	return (
		<>
			<IonContent>
				<IonLoading
					isOpen={workingStatus !== undefined}
					message={`<h2>${workingStatus}</h2>`}
				/>
				<IonGrid hidden={(props.eventExhibitors?.length || 0) > 0}>
					<IonRow>
						<IonCol>
							<IonCard class="ion-text-center">
								<IonIcon icon={sadOutline} style={{ fontSize: '60px' }} />
								<IonCardHeader>
									<IonCardTitle>No exhibitors found</IonCardTitle>
								</IonCardHeader>
								<IonCardContent></IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonGrid
					hidden={
						exhibitorsImportPage !== 'importlist' ||
						(props.eventExhibitors?.length || 0) <= 0
					}
				>
					<IonRow>
						<IonCol>
							<IonList>
								<IonItem>
									<IonCheckbox
										onClick={e => {
											let checked = e.currentTarget.checked;
											if (checked && props.eventExhibitors) {
												let selected = props.eventExhibitors.map(e => e.id);
												setSelectedExhibitors(selected);
											} else {
												setSelectedExhibitors([]);
											}
										}}
									/>
									Select All
								</IonItem>
								{eventExhibitorsListItems}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonGrid
					hidden={
						exhibitorsImportPage !== 'importreview' ||
						(props.eventExhibitors?.length || 0) <= 0
					}
				>
					<IonRow>
						<IonCol>Importing: {selectedExhibitors.length} records</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Mobile App</IonLabel>
								<IonInput
									autocomplete="off"
									key="MobileApp"
									type="number"
									max="20"
									min="0"
									value={exhibitorsImportValues.appLicenses}
									onIonChange={e => {
										setExhibitorsImportValues({
											...exhibitorsImportValues,
											appLicenses: Number(e.detail.value)
										});
									}}
								></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel>Opticon</IonLabel>
								<IonInput
									autocomplete="off"
									key="Opticon"
									type="number"
									max="20"
									min="0"
									value={exhibitorsImportValues.opticonLicenses}
									onIonChange={e => {
										setExhibitorsImportValues({
											...exhibitorsImportValues,
											opticonLicenses: Number(e.detail.value)
										});
									}}
								></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel>SST</IonLabel>
								<IonInput
									autocomplete="off"
									key="SST"
									type="number"
									max="20"
									min="0"
									value={exhibitorsImportValues.sstLicenses}
									onIonChange={e => {
										setExhibitorsImportValues({
											...exhibitorsImportValues,
											sstLicenses: Number(e.detail.value)
										});
									}}
								></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel>API</IonLabel>
								<IonInput
									autocomplete="off"
									key="API"
									type="number"
									max="20"
									min="0"
									value={exhibitorsImportValues.apiLicenses}
									onIonChange={e => {
										setExhibitorsImportValues({
											...exhibitorsImportValues,
											apiLicenses: Number(e.detail.value)
										});
									}}
								></IonInput>
							</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
			<IonButton
				color="success"
				hidden={
					exhibitorsImportPage !== 'importreview' ||
					(props.eventExhibitors?.length || 0) <= 0
				}
				onClick={importServicesFromEventContacts}
			>
				Import
			</IonButton>
			<IonButton
				hidden={
					exhibitorsImportPage !== 'importreview' ||
					(props.eventExhibitors?.length || 0) <= 0
				}
				onClick={() => {
					setExhibitorsImportPage('importlist');
				}}
			>
				Back
			</IonButton>
			<IonButton
				disabled={selectedExhibitors.length <= 0}
				hidden={
					exhibitorsImportPage !== 'importlist' ||
					(props.eventExhibitors?.length || 0) <= 0
				}
				onClick={() => {
					if (selectedExhibitors.length > 0) {
						setExhibitorsImportPage('importreview');
					}
				}}
			>
				Next
			</IonButton>
		</>
	);
};
