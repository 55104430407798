// tslint:disable
/**
 * CONEXSYS Contact Portal API
 * The API for the CONEXSYS Contact Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ContactHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ContactEntity, ContactEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { ContactType } from '../models';

export enum ContactApiOperation {
	readContactsById = 'readContactsById',
	readContacts = 'readContacts',
	writeContacts = 'writeContacts'
}

export interface ReadContactsRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	contactType?: ContactType;
	code?: string;
	name?: string;
	postal?: string;
	email?: string;
	orName?: string;
	orPostal?: string;
	orEmail?: string;
}

export interface ReadContactsByIdRequest {
	userId: string;
	ids?: string[];
}

export interface WriteContactsRequest {
	userId: string;
	contacts: ContactEntities;
}

/**
 * Get service contacts
 * Get service contacts
 */
export async function readContacts(
	ctx: UseCtx<any>,
	requestParameters: ReadContactsRequest
): Promise<ContactEntities> {
	const operationId = ContactApiOperation.readContacts,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ContactEntities, ReadContactsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: ContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities with an api success data for the operation
	setEntitiesApiOperationState<ContactEntities, ContactHelper>(
		ctx.lead.contact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.contact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

export async function readContactsById(
	ctx: UseCtx<any>,
	requestParameters: ReadContactsByIdRequest
): Promise<ContactEntities> {
	const operationId = ContactApiOperation.readContactsById,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ContactEntities, ReadContactsByIdRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'ids'
	);

	let entities: ContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ContactEntities, ContactHelper>(
		ctx.lead.contact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.contact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write service contacts
 */
export async function writeContacts<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteContactsRequest
): Promise<ContactEntities> {
	const operationId = ContactApiOperation.writeContacts,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.contacts.forEach(
		(entity: ContactEntity, i: number, a: ContactEntities) => {
			a[i] = modifyEntity<ContactEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<ContactEntities, WriteContactsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'contacts'
	);

	let entities: ContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ContactEntities, ContactHelper>(
		ctx.lead.contact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.contact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
