import { IonContent } from '@ionic/react';
import React from 'react';

import { html } from './SplashTemplate';

import './SplashView.css';

const SplashView: React.FC = () => {
	return (
		<IonContent>
			<div dangerouslySetInnerHTML={{ __html: html }} />
		</IonContent>
	);
};

export default SplashView;
