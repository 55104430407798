import React, { useState, useEffect } from 'react';

//Data
import { useCtx } from '../../../config/hooks';
import {
	OrdersCnxLeads,
	isObjectStatusActive,
	LicenseEntity,
	LicenseEntities,
	EventEntities,
	ServiceEntities
} from '../../store';

//Lib
import moment from 'moment';

//UI
import {
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonCardTitle,
	IonImg,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonInput,
	IonModal,
	IonTitle,
	IonContent,
	IonItem,
	IonLoading
} from '@ionic/react';
import { addCircle, trashBin, closeCircle } from 'ionicons/icons';
import { AddContextFromEvent } from '../containers/Context';

const AdminEventView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		lead: {
			activeContext,
			contextHelper,
			eventHelper,
			serviceHelper,
			orderHelper,
			licenseHelper,
			activeUser,
			leadHelper
		}
	} = ctx;

	/*********************************************************
	 * State Objects
	 *********************************************************/
	const [showModal, setShowModal] = useState(false);

	//Data Objects
	const [cnxOrders, setCnxOrders] = useState<OrdersCnxLeads>([]);
	const [contextLicenses, setContextLicenses] = useState<LicenseEntities>([]);
	const [contextEvents, setContextEvents] = useState<EventEntities>([]);
	const [contextServices, setContextServices] = useState<ServiceEntities>([]);
	//exporting flag
	const [isExporting, setIsExporting] = useState(false);
	const [isRuningLeadsByHour, setIsRuningLeadsByHour] = useState(false);
	//Loadind
	const [loadingPopup, setLoadingPopup] = useState<{
		display: boolean;
		message: string;
	}>();

	/*********************************************************
	 * Static Objects
	 *********************************************************/
	//Licenses Information
	const appLicenses = contextLicenses.filter(
		license => license.serviceType === 'lead-app'
	);
	const totalAppLicenses = appLicenses.reduce(
		(sum, current) => sum + current.quantity,
		0
	);
	const totalAppLicensUsage = appLicenses.length;
	const appLicensCompanies = [
		...appLicenses
			.reduce((a, c) => {
				a.set(c.serviceId, c);
				return a;
			}, new Map())
			.values()
	];

	const appLicenseActiveCompanies = appLicensCompanies
		.filter(license => license.deviceIds?.length ?? 0 > 0)
		.map((license: LicenseEntity) => {
			return serviceHelper.get(license.serviceId);
		});

	const totalAppLicensesActiveDevicesCount = appLicenses.reduce(
		(sum, current) => sum + (current.deviceIds?.length || 0),
		0
	);

	const totalAPILicenses = contextLicenses
		.filter(license => license.serviceType === 'lead-api')
		.reduce((sum, current) => sum + current.quantity, 0);
	const totalSSTLicenses = contextLicenses
		.filter(license => license.serviceType === 'sst')
		.reduce((sum, current) => sum + current.quantity, 0);
	const totalOpticonLicenses = contextLicenses
		.filter(license => license.serviceType === 'opticon')
		.reduce((sum, current) => sum + current.quantity, 0);

	//const activeRepsCount = activeReps.length || 0;

	// Event Information
	const contextLogo = contextEvents[0]?.header?.OriginalFilePath;
	const contextEventDetailList = contextEvents?.map(event => {
		if (event) {
			return (
				<IonItem key={'event-item' + event.id}>
					{event.name} ({event.code})
				</IonItem>
			);
		}
	});

	//Service Information
	const totalServicesCount = contextServices.length | 0;

	//Order Information
	const totalCnxOrders = cnxOrders.length | 0;
	const activeOrders = orderHelper
		.all()
		.filter(isObjectStatusActive)
		.filter(o => o.contextId === activeContext?.id);
	const totalActiveOrders = activeOrders.length | 0;
	const totalOrdersImported =
		cnxOrders.filter(o => activeOrders.findIndex(ao => ao.code === o.code) >= 0)
			.length | 0;

	/*********************************************************
	 * Action Methods
	 *********************************************************/
	const removeContext = () => {
		if (activeContext && activeContext.id) {
			contextHelper.cache(activeContext.id);
		}
	};
	const exportServices = async () => {
		if (activeContext) {
			setLoadingPopup({
				display: true,
				message: 'Exporting Companies...'
			});
			let services = serviceHelper.allByContextIds([activeContext.id]);
			// All data is now loaded
			for (let i = 0; (services?.length ?? 0) > i; i++) {
				let item = services![i];
				if (item && item) {
					await leadHelper.readAllByServiceNoContacts(ctx, {
						serviceId: item.id
					});
				}
			}

			setIsExporting(true);
		}
	};
	const exportLeadsByHour = async () => {
		if (activeContext) {
			setLoadingPopup({
				display: true,
				message: 'Exporting Leads By Hour...'
			});
			let services = serviceHelper.allByContextIds([activeContext.id]);
			// All data is now loaded
			for (let i = 0; (services?.length ?? 0) > i; i++) {
				let item = services![i];
				if (item && item) {
					await leadHelper.readAllByServiceNoContacts(ctx, {
						serviceId: item.id
					});
				}
			}

			setIsRuningLeadsByHour(true);
		}
	};

	/*********************************************************
	 * useEffect
	 *********************************************************/
	useEffect(() => {
		if (activeContext) {
			let eventId = activeContext?.eventIds[0] || '';
			orderHelper.readAllNonActive(
				ctx,
				{
					userId: activeUser?.id,
					eventId: eventId
				},
				(orders: OrdersCnxLeads) => {
					if (orders && Array.isArray(orders)) {
						setCnxOrders(orders);
					} else {
						setCnxOrders([]);
					}
				}
			);

			setContextLicenses(licenseHelper.allByContexts([activeContext]));
			setContextEvents(eventHelper.gets(activeContext.eventIds));
			setContextServices(serviceHelper.allByContextIds([activeContext.id]));
		}
	}, [activeContext]);
	// Export functions
	useEffect(() => {
		if (isExporting && activeContext) {
			serviceHelper.exportData(
				ctx,
				activeContext,
				serviceHelper.allByContextIds([activeContext.id])
			);
			setLoadingPopup({
				display: false,
				message: ''
			});
			setIsExporting(false);
		}
	}, [isExporting]);
	useEffect(() => {
		if (isRuningLeadsByHour && activeContext) {
			leadHelper.exportLeadsByHour(
				ctx,
				activeContext,
				ctx.lead.lead
					.all()
					.filter(x => activeContext.eventIds.includes(x.eventId))
			);

			setLoadingPopup({
				display: false,
				message: ''
			});
			setIsRuningLeadsByHour(false);
		}
	}, [isRuningLeadsByHour]);

	if (!activeContext) return <>No Context selected</>;

	return (
		<IonContent>
			<IonLoading
				isOpen={loadingPopup?.display ?? false}
				onDidDismiss={() => setLoadingPopup({ display: false, message: '' })}
				message={loadingPopup?.message ?? 'Loading...'}
				duration={120000}
			/>
			<IonModal isOpen={showModal}>
				<IonToolbar>
					<IonToolbar>
						<IonTitle>Add Lead Event</IonTitle>
						<IonButtons slot="end">
							<IonButton fill="clear" onClick={() => setShowModal(false)}>
								<IonIcon src={closeCircle}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonToolbar>
				<IonContent>
					<AddContextFromEvent
						context={activeContext}
						onCompleted={() => setShowModal(false)}
					></AddContextFromEvent>
				</IonContent>
			</IonModal>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol size="12" sizeMd="3">
											<IonImg src={contextLogo} />
										</IonCol>
										<IonCol size="11" sizeMd="8">
											<IonCardTitle>
												<IonInput value={activeContext.name}></IonInput>
												<IonInput value={activeContext.code}></IonInput>
											</IonCardTitle>
											<br />
											Date:
											{moment(contextEvents[0]?.start).format(
												'dddd MMMM Do, YYYY'
											)}
											<br />
											Location: {contextEvents[0]?.location}
											<br />
										</IonCol>
										<IonCol size="1">
											<IonButton
												hidden={true}
												onClick={removeContext}
												color="danger"
											>
												<IonIcon src={trashBin} />
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="6">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Overview</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<h2>Companies</h2>
								<IonGrid>
									<IonRow>
										<IonCol sizeMd="6">Total Companies</IonCol>
										<IonCol sizeMd="6">
											{totalServicesCount}
											<IonButton
												class="ion-float-right"
												size="small"
												routerLink="/admin/services"
											>
												View
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
								<hr
									style={{
										borderBottom: 'solid 1px #8e8d93'
									}}
								/>
								<h2>Licenses</h2>

								<IonGrid>
									<IonRow>
										<IonCol sizeMd="6">App Licenses</IonCol>
										<IonCol sizeMd="3">
											{totalAppLicensUsage} ({totalAppLicenses})
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="1"></IonCol>
										<IonCol sizeMd="5">Activated Companies</IonCol>
										<IonCol sizeMd="2">
											{appLicenseActiveCompanies.length}
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="1"></IonCol>
										<IonCol sizeMd="5">Activated Devices</IonCol>
										<IonCol sizeMd="2">
											{totalAppLicensesActiveDevicesCount}
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="6">API Licenses</IonCol>
										<IonCol sizeMd="6">{totalAPILicenses}</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="6">SST Licenses</IonCol>
										<IonCol sizeMd="6">{totalSSTLicenses}</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="6">Opticon Licenses</IonCol>
										<IonCol sizeMd="6">{totalOpticonLicenses}</IonCol>
									</IonRow>
								</IonGrid>
								<hr
									style={{
										borderBottom: 'solid 1px #8e8d93'
									}}
								/>
								<h2>Orders</h2>
								<IonGrid>
									<IonRow>
										<IonCol sizeMd="6">Active Orders</IonCol>
										<IonCol sizeMd="6">{totalActiveOrders}</IonCol>
										<IonCol sizeMd="6">Orders Imported</IonCol>
										<IonCol sizeMd="6">
											{totalOrdersImported} / {totalCnxOrders}
											<IonButton
												class="ion-float-right"
												size="small"
												routerLink="/admin/orders"
											>
												View
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Quick Reports</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol sizeMd="6">Lead Company Export</IonCol>
										<IonCol sizeMd="6">
											<IonButton
												class="ion-float-right"
												size="small"
												onClick={exportServices}
											>
												Run
											</IonButton>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol sizeMd="6">Leads By Hour</IonCol>
										<IonCol sizeMd="6">
											<IonButton
												class="ion-float-right"
												size="small"
												onClick={exportLeadsByHour}
											>
												Run
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="6">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Events</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								{contextEventDetailList}
								<IonItem>
									<IonButton
										className="ion-float-right"
										onClick={() => setShowModal(true)}
									>
										<IonIcon src={addCircle} />
										Add
									</IonButton>
								</IonItem>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default AdminEventView;
