import {
	LeadId_Some,
	LeadEntity_Some,
	LeadEntityPatch_Some,
	LeadCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const LEAD_MUTATE_ID = '@lead/LEAD_MUTATE_ID';
export const LEAD_SET = '@lead/LEAD_SET';
export const LEAD_TOGGLE = '@lead/LEAD_TOGGLE';
export const LEAD_SELECT = '@lead/LEAD_SELECT';
export const LEAD_DESELECT = '@lead/LEAD_DESELECT';
export const LEAD_UPSERT = '@lead/LEAD_UPSERT';
export const LEAD_PATCH = '@lead/LEAD_PATCH';
export const LEAD_CHANGE = '@lead/LEAD_CHANGE';
export const LEAD_APPLY_CHANGES = '@lead/LEAD_APPLY_CHANGES';
export const LEAD_CANCEL_CHANGES = '@lead/LEAD_CANCEL_CHANGES';
export const LEAD_CACHE = '@lead/LEAD_CACHE';
export const LEAD_UNCACHE = '@lead/LEAD_UNCACHE';
export const LEAD_SETSTATE = '@lead/LEAD_SETSTATE';
export const LEAD_PATCHSTATE = '@lead/LEAD_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface LeadAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface LeadAction_MutateId extends EntityAction_MutateId {
	type: typeof LEAD_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active lead
 *
 * @interface LeadAction_Set
 * @extends {EntityAction_Set}
 */
export interface LeadAction_Set extends EntityAction_Set {
	type: typeof LEAD_SET;
	payload?: EntityId;
}

/**
 * Toggle active lead
 *
 * @interface LeadAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface LeadAction_Toggle extends EntityAction_Toggle {
	type: typeof LEAD_TOGGLE;
	payload?: EntityId;
}

/**
 * Select lead entities
 *
 * @interface LeadAction_Select
 * @extends {EntityAction_Select}
 */
interface LeadAction_Select extends EntityAction_Select {
	type: typeof LEAD_SELECT;
	payload: LeadId_Some;
}

/**
 * Deselect lead entities
 *
 * @interface LeadAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface LeadAction_Deselect extends EntityAction_Deselect {
	type: typeof LEAD_DESELECT;
	payload: LeadId_Some;
}

/**
 * Upsert lead entities
 *
 * @interface LeadAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface LeadAction_Upsert extends EntityAction_Upsert {
	type: typeof LEAD_UPSERT;
	payload: LeadEntity_Some;
}

/**
 * Patch lead entities
 *
 * @interface LeadAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface LeadAction_Patch extends EntityAction_Patch {
	type: typeof LEAD_PATCH;
	payload: LeadEntityPatch_Some;
}

/**
 * Change lead entities
 *
 * @interface LeadAction_Change
 * @extends {EntityAction_Change}
 */
interface LeadAction_Change extends EntityAction_Change {
	type: typeof LEAD_CHANGE;
	payload: LeadEntityPatch_Some;
}

/**
 * Apply changes to lead entities
 *
 * @interface LeadAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface LeadAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof LEAD_APPLY_CHANGES;
	payload: LeadId_Some;
}

/**
 * Cancel changes to lead entities
 *
 * @interface LeadAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface LeadAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof LEAD_CANCEL_CHANGES;
	payload: LeadId_Some;
}

/**
 * Cache lead entities
 *
 * @interface LeadAction_Cache
 * @extends {EntityAction_Cache}
 */
interface LeadAction_Cache extends EntityAction_Cache {
	type: typeof LEAD_CACHE;
	payload: LeadId_Some;
}

/**
 * Uncache lead entities
 *
 * @interface LeadAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface LeadAction_Uncache extends EntityAction_Uncache {
	type: typeof LEAD_UNCACHE;
	payload: LeadId_Some;
}

/**
 * Set lead collection / entity state
 *
 * @interface LeadAction_SetState
 * @extends {EntityAction_SetState}
 */
interface LeadAction_SetState extends EntityAction_SetState {
	type: typeof LEAD_SETSTATE;
}

/**
 * PAtch lead collection / entity state
 *
 * @interface LeadAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface LeadAction_PatchState extends EntityAction_PatchState {
	type: typeof LEAD_PATCHSTATE;
}

/**
 * Export lead action types
 *
 * @export
 */
export type LeadActionTypes =
	| LeadAction_MutateId
	| LeadAction_MutateId
	| LeadAction_Set
	| LeadAction_Toggle
	| LeadAction_Select
	| LeadAction_Deselect
	| LeadAction_Upsert
	| LeadAction_Patch
	| LeadAction_Change
	| LeadAction_ApplyChanges
	| LeadAction_CancelChanges
	| LeadAction_Cache
	| LeadAction_Uncache
	| LeadAction_SetState
	| LeadAction_PatchState;

/**
 * Lead actions helper interface
 *
 * @export
 * @interface ILeadActions
 * @extends {EntityActions}
 */
export interface ILeadActions extends EntityActions {
	//customAction(ids: LeadId_Some): LeadAction_CustomAction;
}

/**
 * Lead actions helper
 *
 * @export
 * @class LeadActions
 * @implements {EntityBaseActions}
 */
export class LeadActions implements ILeadActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): LeadAction_MutateId {
		return { type: LEAD_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): LeadAction_Set {
		return { type: LEAD_SET, payload: id };
	}
	toggle(id?: EntityId): LeadAction_Toggle {
		return { type: LEAD_TOGGLE, payload: id };
	}
	select(ids: LeadId_Some): LeadAction_Select {
		return {
			type: LEAD_SELECT,
			payload: ids
		};
	}
	deselect(ids: LeadId_Some): LeadAction_Deselect {
		return {
			type: LEAD_DESELECT,
			payload: ids
		};
	}
	upsert(entities: LeadEntity_Some): LeadAction_Upsert {
		return {
			type: LEAD_UPSERT,
			payload: entities
		};
	}
	patch(entities: LeadEntityPatch_Some): LeadAction_Patch {
		return {
			type: LEAD_PATCH,
			payload: entities
		};
	}
	change(entities: LeadEntityPatch_Some): LeadAction_Change {
		return {
			type: LEAD_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: LeadId_Some): LeadAction_ApplyChanges {
		return {
			type: LEAD_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: LeadId_Some): LeadAction_CancelChanges {
		return {
			type: LEAD_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: LeadId_Some): LeadAction_Cache {
		return {
			type: LEAD_CACHE,
			payload: ids
		};
	}
	uncache(ids: LeadId_Some): LeadAction_Uncache {
		return {
			type: LEAD_UNCACHE,
			payload: ids
		};
	}
	setState(state: LeadCollectionState): LeadAction_SetState {
		return {
			type: LEAD_SETSTATE,
			payload: { state }
		};
	}
	patchState(statePatch: Partial<LeadCollectionState>): LeadAction_PatchState {
		return {
			type: LEAD_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the lead actions helper
 *
 * @export
 */
export const leadActions = new LeadActions();
