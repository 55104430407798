import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getLicenseCollection,
	LicenseId,
	LicenseIds,
	LicenseId_Some,
	LicenseEntity,
	LicenseEntities,
	LicenseEntity_Some,
	LicenseEntityPatch_Some,
	LicenseCollection,
	LicenseCollectionState,
	ILicenseActions,
	licenseActions,
	LicenseActionTypes
} from '..';
import {
	readLicenses,
	writeLicenses,
	ReadLicensesRequest,
	WriteLicensesRequest,
	LicenseApiOperation,
	ReadLicensesAllRequest,
	readLicensesAll
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { ContextEntities, ServiceEntities } from '../collections';
import { isObjectStatusActive, LicenseServiceType } from '../models';

/**
 * License helper interface
 *
 * @export
 * @interface ILicenseHelper
 * @extends {IEntityHelper}
 */
export interface ILicenseHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * License helper options interface
 *
 * @export
 * @interface LicenseHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface LicenseHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const licenseHelperOpts: LicenseHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * License helper
 *
 * @export
 * @class LicenseHelper
 * @extends {EntityHelper<LicenseCollection, LicenseActionTypes, LicenseActions, LicenseEntity, LicenseEntities, LicenseEntity_Some, LicenseEntityPatch_Some, LicenseId, LicenseIds, LicenseId_Some, LicenseCollectionState, LicenseHelperOpts>}
 * @implements {ILicenseHelper}
 */
export class LicenseHelper
	extends EntityHelper<
		LicenseCollection,
		LicenseActionTypes,
		ILicenseActions,
		LicenseEntity,
		LicenseEntities,
		LicenseEntity_Some,
		LicenseEntityPatch_Some,
		LicenseId,
		LicenseIds,
		LicenseId_Some,
		LicenseCollectionState,
		LicenseHelperOpts
	>
	implements ILicenseHelper {
	constructor() {
		super(
			useSelector(getLicenseCollection),
			licenseActions,
			useDispatch(),
			licenseHelperOpts
		);
		this.collection = useSelector(getLicenseCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: LicenseApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadLicensesRequest> = {},
		callback?: any
	): Promise<LicenseEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(LicenseApiOperation.readLicenses);

		let request: ReadLicensesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: LicenseEntities = await readLicenses(ctx, request)
			.then((entities: LicenseEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadLicensesAllRequest> = {},
		callback?: any
	): Promise<LicenseEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(UserApiOperation.readUsersAll);

		let request: ReadLicensesAllRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: LicenseEntities = await readLicensesAll(ctx, request)
			.then((entities: LicenseEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteLicensesRequest> = {},
		callback?: any
	): Promise<LicenseEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.licenses) return [];
		let request: WriteLicensesRequest = {
			...params,
			...{
				licenses: params.licenses,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: LicenseEntities = await writeLicenses(ctx, request)
			.then((entities: LicenseEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByContexts(
		contexts?: ContextEntities,
		services?: ServiceEntities
	): LicenseEntities {
		let contextIds = contexts?.map(context => context.id);
		if (contextIds && contextIds.length > 0) {
			return (services ? this.allByServices(services) : this.all())
				.filter(license => contextIds?.includes(license.contextId))
				.filter(isObjectStatusActive);
		} else {
			return [];
		}
	}

	allByServices(services: ServiceEntities): LicenseEntities {
		let serviceIds = services.map(services => services.id);
		if (serviceIds.length > 0) {
			return this.all()
				.filter(isObjectStatusActive)
				.filter(license => serviceIds.includes(license.serviceId));
		} else {
			return [];
		}
	}

	allByContextId(contextId?: string): LicenseEntities {
		if (contextId !== undefined && contextId.length > 0) {
			return this.all()
				.filter(isObjectStatusActive)
				.filter(license => license.contextId === contextId);
		} else {
			return [];
		}
	}

	allByOrderId(orderId?: string): LicenseEntities {
		if (orderId !== undefined && orderId.trim() !== '' && orderId.length > 0) {
			return this.all()
				.filter(isObjectStatusActive)
				.filter(license => license.orderId == orderId);
		} else {
			return [];
		}
	}

	licenseTypeToString(type?: LicenseServiceType): string {
		if (type === 'lead-api') {
			return 'Lead Api';
		} else if (type === 'lead-app') {
			return 'Lead App';
		} else if (type === 'opticon') {
			return 'Opticon';
		} else if (type === 'sst') {
			return 'SST';
		} else {
			return 'Item';
		}
	}
}
