import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getEventContactCollection,
	EventContactId,
	EventContactIds,
	EventContactId_Some,
	EventContactEntity,
	EventContactEntities,
	EventContactEntity_Some,
	EventContactEntityPatch_Some,
	EventContactCollection,
	EventContactCollectionState,
	IEventContactActions,
	eventContactActions,
	EventContactActionTypes
} from '..';
import {
	writeEventContacts,
	WriteEventContactsRequest,
	readEventContactsById,
	readEventContactsByCode,
	ReadEventContactsByIdRequest,
	ReadEventContactsByCodeRequest,
	readEventContactsOrganizations,
	ReadEventContactsOrganizationsRequest,
	readEventContactAnswers,
	EventContactApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	EventContactOrganizationEntity,
	isEventContactIndividualEntity,
	isEventContactOrganizationEntity
} from '../collections';
import {
	ContactIndividual,
	ContactOrganization,
	isObjectStatusActive,
	ContactType
} from '../models';
import { request } from 'http';

/**
 * EventContact helper interface
 *
 * @export
 * @interface IEventContactHelper
 * @extends {IEntityHelper}
 */
export interface IEventContactHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * EventContact helper options interface
 *
 * @export
 * @interface EventContactHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface EventContactHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const eventContactHelperOpts: EventContactHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * EventContact helper
 *
 * @export
 * @class EventContactHelper
 * @extends {EntityHelper<EventContactCollection, EventContactActionTypes, EventContactActions, EventContactEntity, EventContactEntities, EventContactEntity_Some, EventContactEntityPatch_Some, EventContactId, EventContactIds, EventContactId_Some, EventContactCollectionState, EventContactHelperOpts>}
 * @implements {IEventContactHelper}
 */
export class EventContactHelper
	extends EntityHelper<
		EventContactCollection,
		EventContactActionTypes,
		IEventContactActions,
		EventContactEntity,
		EventContactEntities,
		EventContactEntity_Some,
		EventContactEntityPatch_Some,
		EventContactId,
		EventContactIds,
		EventContactId_Some,
		EventContactCollectionState,
		EventContactHelperOpts
	>
	implements IEventContactHelper {
	constructor() {
		super(
			useSelector(getEventContactCollection),
			eventContactActions,
			useDispatch(),
			eventContactHelperOpts
		);
		this.collection = useSelector(getEventContactCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: EventContactApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteEventContactsRequest> = {},
		callback?: any
	): Promise<EventContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.eventcontacts) return [];
		let request: WriteEventContactsRequest = {
			...params,
			...{
				eventcontacts: params.eventcontacts,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: EventContactEntities = await writeEventContacts(ctx, request)
			.then((entities: EventContactEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readEventContactsOrganizations(
		ctx: UseCtx<any>,
		params: Partial<ReadEventContactsOrganizationsRequest> = {},
		callback?: any
	): Promise<EventContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];

		let request: ReadEventContactsOrganizationsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				eventIds: params.eventIds || []
			}
		};
		if (request.userId === '' || !request.eventIds) return [];

		let entities: EventContactEntities = await readEventContactsOrganizations(
			ctx,
			request
		)
			.then((entities: EventContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readEventContactsById(
		ctx: UseCtx<any>,
		params: Partial<ReadEventContactsByIdRequest> & {
			ids: string[];
			eventIds: string[];
		},
		callback?: any
	): Promise<EventContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (
			!ctx.lead.service.active()?.eventIds ||
			ctx.lead.service.active()?.eventIds?.length === 0
		)
			return [];
		let request: ReadEventContactsByIdRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '' || request.eventIds.length === 0) return [];

		let entities: EventContactEntities = await readEventContactsById(
			ctx,
			request
		)
			.then((entities: EventContactEntities) => {
				/*entities.forEach(entity => {
					if (!entity.answers)
						readEventContactAnswers(ctx, {
							userId: request.userId,
							eventId: entity.eventId,
							contactId: entity.id
						});
				});*/

				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readEventContactsByCode(
		ctx: UseCtx<any>,
		params: Partial<ReadEventContactsByCodeRequest> & {
			code: string;
		},
		callback?: any
	): Promise<EventContactEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.context.active()) return [];
		if (
			!ctx.lead.context.active()?.eventIds ||
			ctx.lead.context.active()?.eventIds?.length === 0
		)
			return [];
		let request: ReadEventContactsByCodeRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				eventIds: ctx.lead.context.active()?.eventIds || []
			}
		};
		if (request.userId === '' || request.eventIds.length === 0) return [];

		let entities: EventContactEntities = await readEventContactsByCode(
			ctx,
			request
		)
			.then((entities: EventContactEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	all_Individual(): ContactIndividual[] {
		return this.all()
			.filter(isEventContactIndividualEntity)
			.filter(isObjectStatusActive);
	}
	get_Individual(id: string): ContactIndividual | undefined {
		return this.all()
			.filter(isEventContactIndividualEntity)
			.find(c => c.id === id);
	}

	all_Organization(): EventContactOrganizationEntity[] {
		return this.all()
			.filter(isEventContactOrganizationEntity)
			.filter(isObjectStatusActive);
	}
	get_Organization(id: string): EventContactOrganizationEntity | undefined {
		return this.all()
			.filter(isEventContactOrganizationEntity)
			.find(c => c.id === id);
	}

	getNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getName(id ? this.get(id) : undefined, defaultName);
	}

	getName(
		eventContact?: EventContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !eventContact
			? 'loading...'
			: isEventContactIndividualEntity(eventContact)
			? eventContact.firstName && eventContact.lastName
				? eventContact.firstName + ' ' + eventContact.lastName
				: eventContact.firstName || eventContact.lastName || defaultName
			: isEventContactOrganizationEntity(eventContact)
			? eventContact.name || defaultName
			: defaultName;
	}

	getOrganizationNameById(
		id?: string,
		defaultName: string = 'Unknown'
	): string {
		return this.getOrganizationName(id ? this.get(id) : undefined, defaultName);
	}

	getOrganizationName(
		eventContact?: EventContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !eventContact
			? 'loading...'
			: isEventContactIndividualEntity(eventContact)
			? eventContact.organization || defaultName
			: isEventContactOrganizationEntity(eventContact)
			? eventContact.name || defaultName
			: defaultName;
	}

	getFirstNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getFirstName(id ? this.get(id) : undefined, defaultName);
	}

	getFirstName(
		eventContact?: EventContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !eventContact
			? 'loading...'
			: isEventContactIndividualEntity(eventContact)
			? eventContact.firstName || defaultName
			: isEventContactOrganizationEntity(eventContact)
			? eventContact.name || defaultName
			: defaultName;
	}

	getLastNameById(id?: string, defaultName: string = 'Unknown'): string {
		return this.getLastName(id ? this.get(id) : undefined, defaultName);
	}

	getLastName(
		eventContact?: EventContactEntity,
		defaultName: string = 'Unknown'
	): string {
		return !eventContact
			? 'loading...'
			: isEventContactIndividualEntity(eventContact)
			? eventContact.lastName || defaultName
			: isEventContactOrganizationEntity(eventContact)
			? eventContact.name || defaultName
			: defaultName;
	}
}
