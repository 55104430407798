import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getQualifierCollection,
	QualifierId,
	QualifierIds,
	QualifierId_Some,
	QualifierEntity,
	QualifierEntities,
	QualifierEntity_Some,
	QualifierEntityPatch_Some,
	QualifierCollection,
	QualifierCollectionState,
	IQualifierActions,
	qualifierActions,
	QualifierActionTypes
} from '..';
import {
	readQualifiers,
	writeQualifiers,
	ReadQualifiersRequest,
	WriteQualifiersRequest,
	QualifierApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { isObjectStatusActive, ObjectStatus } from '../models';

/**
 * Qualifier helper interface
 *
 * @export
 * @interface IQualifierHelper
 * @extends {IEntityHelper}
 */
export interface IQualifierHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Qualifier helper options interface
 *
 * @export
 * @interface QualifierHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface QualifierHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const qualifierHelperOpts: QualifierHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Qualifier helper
 *
 * @export
 * @class QualifierHelper
 * @extends {EntityHelper<QualifierCollection, QualifierActionTypes, QualifierActions, QualifierEntity, QualifierEntities, QualifierEntity_Some, QualifierEntityPatch_Some, QualifierId, QualifierIds, QualifierId_Some, QualifierCollectionState, QualifierHelperOpts>}
 * @implements {IQualifierHelper}
 */
export class QualifierHelper
	extends EntityHelper<
		QualifierCollection,
		QualifierActionTypes,
		IQualifierActions,
		QualifierEntity,
		QualifierEntities,
		QualifierEntity_Some,
		QualifierEntityPatch_Some,
		QualifierId,
		QualifierIds,
		QualifierId_Some,
		QualifierCollectionState,
		QualifierHelperOpts
	>
	implements IQualifierHelper {
	constructor() {
		super(
			useSelector(getQualifierCollection),
			qualifierActions,
			useDispatch(),
			qualifierHelperOpts
		);
		this.collection = useSelector(getQualifierCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: QualifierApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadQualifiersRequest> = {},
		callback?: any
	): Promise<QualifierEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom ||
		//	this.lastSuccess(QualifierApiOperation.readQualifiers);

		let request: ReadQualifiersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: QualifierEntities = await readQualifiers(ctx, request)
			.then((entities: QualifierEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteQualifiersRequest> = {},
		callback?: any
	): Promise<QualifierEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		if (!params.qualifiers) return [];
		let request: WriteQualifiersRequest = {
			...params,
			...{
				qualifiers: params.qualifiers,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: QualifierEntities = await writeQualifiers(ctx, request)
			.then((entities: QualifierEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let qualifier = this.get(id);
		if (!qualifier) return false;

		qualifier.i_.status = ObjectStatus.Deleted;

		let qualifiers = await this.write(ctx, {
			qualifiers: [qualifier]
		});

		return qualifiers.length > 0;
	}

	allByServiceIdAndEventIds(
		serviceId: string,
		eventIds: string[]
	): QualifierEntities {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(
				qualifier =>
					qualifier.serviceId === serviceId &&
					(!qualifier.eventIds ||
						qualifier.eventIds?.length === 0 ||
						qualifier.eventIds?.some(val => eventIds.includes(val)))
			);
	}

	getNextPosition(serviceId: string, eventIds: string[]): number {
		return this.allByServiceIdAndEventIds(serviceId, eventIds)?.length || 0 + 1;
	}
}
