import { EventAnswerHelper } from '..';
import { EventAnswerEntity } from '../collections';

export interface EventAnswerHook {
	eventAnswerHelper: EventAnswerHelper;
	activeEventAnswer: EventAnswerEntity | undefined;
}

export const useEventAnswer = (): EventAnswerHook => {
	let eventAnswerHelper = new EventAnswerHelper();
	return {
		eventAnswerHelper,
		activeEventAnswer: eventAnswerHelper.active()
	};
};
