import React from 'react';

//DATA
import { useLocaleConfig } from '../../store';

//UI
import { IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { pageTitleConfig } from '../../../config/app/components/PageTitle';
import { EventSelector } from '../../../lead/ui/components/Event/EventSelector';

// Style
import '../styles/main.css';
export interface PageTitleConfigProps {
	placeholder: string;
}

export interface PageTitleProps {
	title: string;
	subTitle?: string;
	displayEventSelector?: boolean;
}

export const PageTitle: React.FC<PageTitleProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<PageTitleConfigProps>(pageTitleConfig);

	return (
		<IonGrid className="page-title">
			<IonRow style={{ borderBottom: '1px solid #d7d8da' }}>
				<IonCol size="12" sizeMd="8">
					<IonText>
						<h1>{props.title}</h1>
						<span
							style={{
								color: '#737373',
								fontSize: '14px',
								fontWeight: 400
							}}
						>
							{props.subTitle}
						</span>
					</IonText>
				</IonCol>
				{props.displayEventSelector ? (
					<IonCol
						hidden={!(props.displayEventSelector ?? false)}
						size="12"
						sizeMd="4"
						className="text-right"
					>
						<EventSelector />
					</IonCol>
				) : (
					<></>
				)}
			</IonRow>
		</IonGrid>
	);
};
