import {
	TicketId_Some,
	TicketEntity_Some,
	TicketEntityPatch_Some,
	TicketCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const TICKET_MUTATE_ID = '@lead/TICKET_MUTATE_ID';
export const TICKET_SET = '@lead/TICKET_SET';
export const TICKET_TOGGLE = '@lead/TICKET_TOGGLE';
export const TICKET_SELECT = '@lead/TICKET_SELECT';
export const TICKET_DESELECT = '@lead/TICKET_DESELECT';
export const TICKET_UPSERT = '@lead/TICKET_UPSERT';
export const TICKET_PATCH = '@lead/TICKET_PATCH';
export const TICKET_CHANGE = '@lead/TICKET_CHANGE';
export const TICKET_APPLY_CHANGES = '@lead/TICKET_APPLY_CHANGES';
export const TICKET_CANCEL_CHANGES = '@lead/TICKET_CANCEL_CHANGES';
export const TICKET_CACHE = '@lead/TICKET_CACHE';
export const TICKET_UNCACHE = '@lead/TICKET_UNCACHE';
export const TICKET_SETSTATE = '@lead/TICKET_SETSTATE';
export const TICKET_PATCHSTATE = '@lead/TICKET_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface TicketAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface TicketAction_MutateId extends EntityAction_MutateId {
	type: typeof TICKET_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active ticket
 *
 * @interface TicketAction_Set
 * @extends {EntityAction_Set}
 */
export interface TicketAction_Set extends EntityAction_Set {
	type: typeof TICKET_SET;
	payload?: EntityId;
}

/**
 * Toggle active ticket
 *
 * @interface TicketAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface TicketAction_Toggle extends EntityAction_Toggle {
	type: typeof TICKET_TOGGLE;
	payload?: EntityId;
}

/**
 * Select ticket entities
 *
 * @interface TicketAction_Select
 * @extends {EntityAction_Select}
 */
interface TicketAction_Select extends EntityAction_Select {
	type: typeof TICKET_SELECT;
	payload: TicketId_Some;
}

/**
 * Deselect ticket entities
 *
 * @interface TicketAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface TicketAction_Deselect extends EntityAction_Deselect {
	type: typeof TICKET_DESELECT;
	payload: TicketId_Some;
}

/**
 * Upsert ticket entities
 *
 * @interface TicketAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface TicketAction_Upsert extends EntityAction_Upsert {
	type: typeof TICKET_UPSERT;
	payload: TicketEntity_Some;
}

/**
 * Patch ticket entities
 *
 * @interface TicketAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface TicketAction_Patch extends EntityAction_Patch {
	type: typeof TICKET_PATCH;
	payload: TicketEntityPatch_Some;
}

/**
 * Change ticket entities
 *
 * @interface TicketAction_Change
 * @extends {EntityAction_Change}
 */
interface TicketAction_Change extends EntityAction_Change {
	type: typeof TICKET_CHANGE;
	payload: TicketEntityPatch_Some;
}

/**
 * Apply changes to ticket entities
 *
 * @interface TicketAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface TicketAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof TICKET_APPLY_CHANGES;
	payload: TicketId_Some;
}

/**
 * Cancel changes to ticket entities
 *
 * @interface TicketAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface TicketAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof TICKET_CANCEL_CHANGES;
	payload: TicketId_Some;
}

/**
 * Cache ticket entities
 *
 * @interface TicketAction_Cache
 * @extends {EntityAction_Cache}
 */
interface TicketAction_Cache extends EntityAction_Cache {
	type: typeof TICKET_CACHE;
	payload: TicketId_Some;
}

/**
 * Uncache ticket entities
 *
 * @interface TicketAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface TicketAction_Uncache extends EntityAction_Uncache {
	type: typeof TICKET_UNCACHE;
	payload: TicketId_Some;
}

/**
 * Set ticket collection / entity state
 *
 * @interface TicketAction_SetState
 * @extends {EntityAction_SetState}
 */
interface TicketAction_SetState extends EntityAction_SetState {
	type: typeof TICKET_SETSTATE;
}

/**
 * PAtch ticket collection / entity state
 *
 * @interface TicketAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface TicketAction_PatchState extends EntityAction_PatchState {
	type: typeof TICKET_PATCHSTATE;
}

/**
 * Export ticket action types
 *
 * @export
 */
export type TicketActionTypes =
	| TicketAction_MutateId
	| TicketAction_MutateId
	| TicketAction_Set
	| TicketAction_Toggle
	| TicketAction_Select
	| TicketAction_Deselect
	| TicketAction_Upsert
	| TicketAction_Patch
	| TicketAction_Change
	| TicketAction_ApplyChanges
	| TicketAction_CancelChanges
	| TicketAction_Cache
	| TicketAction_Uncache
	| TicketAction_SetState
	| TicketAction_PatchState;

/**
 * Ticket actions helper interface
 *
 * @export
 * @interface ITicketActions
 * @extends {EntityActions}
 */
export interface ITicketActions extends EntityActions {
	//customAction(ids: TicketId_Some): TicketAction_CustomAction;
}

/**
 * Ticket actions helper
 *
 * @export
 * @class TicketActions
 * @implements {EntityBaseActions}
 */
export class TicketActions implements ITicketActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): TicketAction_MutateId {
		return { type: TICKET_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): TicketAction_Set {
		return { type: TICKET_SET, payload: id };
	}
	toggle(id?: EntityId): TicketAction_Toggle {
		return { type: TICKET_TOGGLE, payload: id };
	}
	select(ids: TicketId_Some): TicketAction_Select {
		return {
			type: TICKET_SELECT,
			payload: ids
		};
	}
	deselect(ids: TicketId_Some): TicketAction_Deselect {
		return {
			type: TICKET_DESELECT,
			payload: ids
		};
	}
	upsert(entities: TicketEntity_Some): TicketAction_Upsert {
		return {
			type: TICKET_UPSERT,
			payload: entities
		};
	}
	patch(entities: TicketEntityPatch_Some): TicketAction_Patch {
		return {
			type: TICKET_PATCH,
			payload: entities
		};
	}
	change(entities: TicketEntityPatch_Some): TicketAction_Change {
		return {
			type: TICKET_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: TicketId_Some): TicketAction_ApplyChanges {
		return {
			type: TICKET_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: TicketId_Some): TicketAction_CancelChanges {
		return {
			type: TICKET_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: TicketId_Some): TicketAction_Cache {
		return {
			type: TICKET_CACHE,
			payload: ids
		};
	}
	uncache(ids: TicketId_Some): TicketAction_Uncache {
		return {
			type: TICKET_UNCACHE,
			payload: ids
		};
	}
	setState(state: TicketCollectionState): TicketAction_SetState {
		return {
			type: TICKET_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<TicketCollectionState>
	): TicketAction_PatchState {
		return {
			type: TICKET_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the ticket actions helper
 *
 * @export
 */
export const ticketActions = new TicketActions();
