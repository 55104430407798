import {
	EventContactId_Some,
	EventContactEntity_Some,
	EventContactEntityPatch_Some,
	EventContactCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const EVENTCONTACT_MUTATE_ID = '@lead/EVENTCONTACT_MUTATE_ID';
export const EVENTCONTACT_SET = '@lead/EVENTCONTACT_SET';
export const EVENTCONTACT_TOGGLE = '@lead/EVENTCONTACT_TOGGLE';
export const EVENTCONTACT_SELECT = '@lead/EVENTCONTACT_SELECT';
export const EVENTCONTACT_DESELECT = '@lead/EVENTCONTACT_DESELECT';
export const EVENTCONTACT_UPSERT = '@lead/EVENTCONTACT_UPSERT';
export const EVENTCONTACT_PATCH = '@lead/EVENTCONTACT_PATCH';
export const EVENTCONTACT_CHANGE = '@lead/EVENTCONTACT_CHANGE';
export const EVENTCONTACT_APPLY_CHANGES = '@lead/EVENTCONTACT_APPLY_CHANGES';
export const EVENTCONTACT_CANCEL_CHANGES = '@lead/EVENTCONTACT_CANCEL_CHANGES';
export const EVENTCONTACT_CACHE = '@lead/EVENTCONTACT_CACHE';
export const EVENTCONTACT_UNCACHE = '@lead/EVENTCONTACT_UNCACHE';
export const EVENTCONTACT_SETSTATE = '@lead/EVENTCONTACT_SETSTATE';
export const EVENTCONTACT_PATCHSTATE = '@lead/EVENTCONTACT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface EventContactAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface EventContactAction_MutateId extends EntityAction_MutateId {
	type: typeof EVENTCONTACT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active eventcontact
 *
 * @interface EventContactAction_Set
 * @extends {EntityAction_Set}
 */
export interface EventContactAction_Set extends EntityAction_Set {
	type: typeof EVENTCONTACT_SET;
	payload?: EntityId;
}

/**
 * Toggle active eventcontact
 *
 * @interface EventContactAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface EventContactAction_Toggle extends EntityAction_Toggle {
	type: typeof EVENTCONTACT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select eventcontact entities
 *
 * @interface EventContactAction_Select
 * @extends {EntityAction_Select}
 */
interface EventContactAction_Select extends EntityAction_Select {
	type: typeof EVENTCONTACT_SELECT;
	payload: EventContactId_Some;
}

/**
 * Deselect eventcontact entities
 *
 * @interface EventContactAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface EventContactAction_Deselect extends EntityAction_Deselect {
	type: typeof EVENTCONTACT_DESELECT;
	payload: EventContactId_Some;
}

/**
 * Upsert eventcontact entities
 *
 * @interface EventContactAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface EventContactAction_Upsert extends EntityAction_Upsert {
	type: typeof EVENTCONTACT_UPSERT;
	payload: EventContactEntity_Some;
}

/**
 * Patch eventcontact entities
 *
 * @interface EventContactAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface EventContactAction_Patch extends EntityAction_Patch {
	type: typeof EVENTCONTACT_PATCH;
	payload: EventContactEntityPatch_Some;
}

/**
 * Change eventcontact entities
 *
 * @interface EventContactAction_Change
 * @extends {EntityAction_Change}
 */
interface EventContactAction_Change extends EntityAction_Change {
	type: typeof EVENTCONTACT_CHANGE;
	payload: EventContactEntityPatch_Some;
}

/**
 * Apply changes to eventcontact entities
 *
 * @interface EventContactAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface EventContactAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof EVENTCONTACT_APPLY_CHANGES;
	payload: EventContactId_Some;
}

/**
 * Cancel changes to eventcontact entities
 *
 * @interface EventContactAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface EventContactAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof EVENTCONTACT_CANCEL_CHANGES;
	payload: EventContactId_Some;
}

/**
 * Cache eventcontact entities
 *
 * @interface EventContactAction_Cache
 * @extends {EntityAction_Cache}
 */
interface EventContactAction_Cache extends EntityAction_Cache {
	type: typeof EVENTCONTACT_CACHE;
	payload: EventContactId_Some;
}

/**
 * Uncache eventcontact entities
 *
 * @interface EventContactAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface EventContactAction_Uncache extends EntityAction_Uncache {
	type: typeof EVENTCONTACT_UNCACHE;
	payload: EventContactId_Some;
}

/**
 * Set eventcontact collection / entity state
 *
 * @interface EventContactAction_SetState
 * @extends {EntityAction_SetState}
 */
interface EventContactAction_SetState extends EntityAction_SetState {
	type: typeof EVENTCONTACT_SETSTATE;
}

/**
 * PAtch eventcontact collection / entity state
 *
 * @interface EventContactAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface EventContactAction_PatchState extends EntityAction_PatchState {
	type: typeof EVENTCONTACT_PATCHSTATE;
}

/**
 * Export eventcontact action types
 *
 * @export
 */
export type EventContactActionTypes =
	| EventContactAction_MutateId
	| EventContactAction_MutateId
	| EventContactAction_Set
	| EventContactAction_Toggle
	| EventContactAction_Select
	| EventContactAction_Deselect
	| EventContactAction_Upsert
	| EventContactAction_Patch
	| EventContactAction_Change
	| EventContactAction_ApplyChanges
	| EventContactAction_CancelChanges
	| EventContactAction_Cache
	| EventContactAction_Uncache
	| EventContactAction_SetState
	| EventContactAction_PatchState;

/**
 * EventContact actions helper interface
 *
 * @export
 * @interface IEventContactActions
 * @extends {EntityActions}
 */
export interface IEventContactActions extends EntityActions {
	//customAction(ids: EventContactId_Some): EventContactAction_CustomAction;
}

/**
 * EventContact actions helper
 *
 * @export
 * @class EventContactActions
 * @implements {EntityBaseActions}
 */
export class EventContactActions implements IEventContactActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): EventContactAction_MutateId {
		return { type: EVENTCONTACT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): EventContactAction_Set {
		return { type: EVENTCONTACT_SET, payload: id };
	}
	toggle(id?: EntityId): EventContactAction_Toggle {
		return { type: EVENTCONTACT_TOGGLE, payload: id };
	}
	select(ids: EventContactId_Some): EventContactAction_Select {
		return {
			type: EVENTCONTACT_SELECT,
			payload: ids
		};
	}
	deselect(ids: EventContactId_Some): EventContactAction_Deselect {
		return {
			type: EVENTCONTACT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: EventContactEntity_Some): EventContactAction_Upsert {
		return {
			type: EVENTCONTACT_UPSERT,
			payload: entities
		};
	}
	patch(entities: EventContactEntityPatch_Some): EventContactAction_Patch {
		return {
			type: EVENTCONTACT_PATCH,
			payload: entities
		};
	}
	change(entities: EventContactEntityPatch_Some): EventContactAction_Change {
		return {
			type: EVENTCONTACT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: EventContactId_Some): EventContactAction_ApplyChanges {
		return {
			type: EVENTCONTACT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: EventContactId_Some): EventContactAction_CancelChanges {
		return {
			type: EVENTCONTACT_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: EventContactId_Some): EventContactAction_Cache {
		return {
			type: EVENTCONTACT_CACHE,
			payload: ids
		};
	}
	uncache(ids: EventContactId_Some): EventContactAction_Uncache {
		return {
			type: EVENTCONTACT_UNCACHE,
			payload: ids
		};
	}
	setState(state: EventContactCollectionState): EventContactAction_SetState {
		return {
			type: EVENTCONTACT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<EventContactCollectionState>
	): EventContactAction_PatchState {
		return {
			type: EVENTCONTACT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the eventcontact actions helper
 *
 * @export
 */
export const eventContactActions = new EventContactActions();
