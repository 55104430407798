//import { combineReducers, Reducer } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
//import { entitiesReducer, queriesReducer } from 'redux-query';

import { configReducers } from '../config/reducers';

const rootReducer: any = combineReducers({
	//	entities: entitiesReducer,
	//queries: queriesReducer,
	...configReducers
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
