import { ApiHelper } from '..';
import { ApiEntity } from '../collections';

export interface ApiHook {
	apiHelper: ApiHelper;
	activeApi: ApiEntity | undefined;
}

export const useApi = (): ApiHook => {
	let apiHelper = new ApiHelper();
	return {
		apiHelper,
		activeApi: apiHelper.active()
	};
};
