import React, { useState } from 'react';
//Data
import {
	ContextEntity,
	EventEntity,
	EventEntityFilter,
	isObjectStatusActive,
	ObjectType,
	CurrencyType
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonList,
	IonItem,
	IonSearchbar,
	IonButton,
	IonAlert
} from '@ionic/react';
import { newEntity } from '../../../../app/utils';
import { EventStatusType } from '../../../store/models/EventStatusType';

export interface AddContextFromEventProps {
	context?: ContextEntity;
	onCompleted: () => void;
}

export const AddContextFromEvent: React.FC<AddContextFromEventProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		lead: { eventHelper, contextHelper, activeContext }
	} = ctx;
	const [eventFilter, setEventFilter] = useState<EventEntityFilter>({
		keyWords: ''
	});
	const [
		showAlertConfirmCreateContext,
		setShowAlertConfirmCreateContext
	] = useState<EventEntity | undefined>(undefined);

	const onEventSerach = (value: string | undefined) => {
		setEventFilter(prevFilter => ({
			...prevFilter,
			keyWords: value ? value : ''
		}));
	};

	const sortEvents = (a: EventEntity, b: EventEntity) => {
		return (a.name || '') > (b.name || '')
			? 1
			: (b.name || '') > (a.name || '')
			? -1
			: 0;
	};
	const eventListItems = eventHelper
		.allByFilter(ctx, eventFilter)
		.filter(e => e.eventStatus != EventStatusType.Closed)
		.filter(
			e =>
				contextHelper
					.all()
					.filter(isObjectStatusActive)
					.findIndex(c => c.eventIds.includes(e.id)) <= 0
		)
		.sort(sortEvents)
		.map((event: EventEntity) => {
			return (
				<IonItem key={event.id} button={true}>
					<IonGrid>
						<IonRow>
							<IonCol size="0" sizeMd="2">
								<IonImg
									hidden={!event?.header?.OriginalFilePath}
									src={event?.header?.OriginalFilePath || ''}
								/>
							</IonCol>
							<IonCol>
								{event.name} ({event.code})
							</IonCol>
							<IonCol>
								<IonButton
									onClick={() => {
										setShowAlertConfirmCreateContext(event);
									}}
								>
									Add Lead Event
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	const createNewContext = (event?: EventEntity) => {
		if (event) {
			let newContext: ContextEntity = {
				...newEntity(ctx, ObjectType.Context),
				eventIds: [event.id],
				code: event.code,
				name: event.name,
				currency: event.country === 'CA' ? CurrencyType.CAD : CurrencyType.USD
			};

			contextHelper.write(ctx, { contexts: [newContext] });
			props.onCompleted();
		}
	};
	const addEventToContext = (event?: EventEntity) => {
		if (event && props.context) {
			props.context?.eventIds.push(event.id);
			contextHelper.write(ctx, { contexts: [props.context] });
			props.onCompleted();
		}
	};

	return (
		<>
			<IonAlert
				isOpen={showAlertConfirmCreateContext !== undefined}
				onDidDismiss={() => setShowAlertConfirmCreateContext(undefined)}
				header={'Are you Sure?'}
				message={'Would you like to add a New Lead Event ?'}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: e => {
							console.log('Confirm Cancel');
						}
					},
					{
						text: 'Yes',
						handler: data => {
							if (props.context) {
								addEventToContext(showAlertConfirmCreateContext);
							} else {
								createNewContext(showAlertConfirmCreateContext);
							}
						}
					}
				]}
			/>
			<IonSearchbar
				value={eventFilter.keyWords}
				onIonChange={e => onEventSerach(e.detail.value)}
			></IonSearchbar>
			<IonList>{eventListItems}</IonList>
		</>
	);
};
