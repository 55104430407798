import { ServiceEntities } from '../../lead/store/collections/Service';
import { ServiceType } from '../../lead/store/models/ServiceType';

export interface ServiceConfig {
	entities: ServiceEntities;
	activeId?: string;
}

export const serviceConfig: ServiceConfig = {
	entities: []
};
