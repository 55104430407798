import {
	QualifierValueId_Some,
	QualifierValueEntity_Some,
	QualifierValueEntityPatch_Some,
	QualifierValueCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const QUALIFIERVALUE_MUTATE_ID = '@lead/QUALIFIERVALUE_MUTATE_ID';
export const QUALIFIERVALUE_SET = '@lead/QUALIFIERVALUE_SET';
export const QUALIFIERVALUE_TOGGLE = '@lead/QUALIFIERVALUE_TOGGLE';
export const QUALIFIERVALUE_SELECT = '@lead/QUALIFIERVALUE_SELECT';
export const QUALIFIERVALUE_DESELECT = '@lead/QUALIFIERVALUE_DESELECT';
export const QUALIFIERVALUE_UPSERT = '@lead/QUALIFIERVALUE_UPSERT';
export const QUALIFIERVALUE_PATCH = '@lead/QUALIFIERVALUE_PATCH';
export const QUALIFIERVALUE_CHANGE = '@lead/QUALIFIERVALUE_CHANGE';
export const QUALIFIERVALUE_APPLY_CHANGES =
	'@lead/QUALIFIERVALUE_APPLY_CHANGES';
export const QUALIFIERVALUE_CANCEL_CHANGES =
	'@lead/QUALIFIERVALUE_CANCEL_CHANGES';
export const QUALIFIERVALUE_CACHE = '@lead/QUALIFIERVALUE_CACHE';
export const QUALIFIERVALUE_UNCACHE = '@lead/QUALIFIERVALUE_UNCACHE';
export const QUALIFIERVALUE_SETSTATE = '@lead/QUALIFIERVALUE_SETSTATE';
export const QUALIFIERVALUE_PATCHSTATE = '@lead/QUALIFIERVALUE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface QualifierValueAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface QualifierValueAction_MutateId extends EntityAction_MutateId {
	type: typeof QUALIFIERVALUE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active qualifierValue
 *
 * @interface QualifierValueAction_Set
 * @extends {EntityAction_Set}
 */
export interface QualifierValueAction_Set extends EntityAction_Set {
	type: typeof QUALIFIERVALUE_SET;
	payload?: EntityId;
}

/**
 * Toggle active qualifierValue
 *
 * @interface QualifierValueAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface QualifierValueAction_Toggle extends EntityAction_Toggle {
	type: typeof QUALIFIERVALUE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select qualifierValue entities
 *
 * @interface QualifierValueAction_Select
 * @extends {EntityAction_Select}
 */
interface QualifierValueAction_Select extends EntityAction_Select {
	type: typeof QUALIFIERVALUE_SELECT;
	payload: QualifierValueId_Some;
}

/**
 * Deselect qualifierValue entities
 *
 * @interface QualifierValueAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface QualifierValueAction_Deselect extends EntityAction_Deselect {
	type: typeof QUALIFIERVALUE_DESELECT;
	payload: QualifierValueId_Some;
}

/**
 * Upsert qualifierValue entities
 *
 * @interface QualifierValueAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface QualifierValueAction_Upsert extends EntityAction_Upsert {
	type: typeof QUALIFIERVALUE_UPSERT;
	payload: QualifierValueEntity_Some;
}

/**
 * Patch qualifierValue entities
 *
 * @interface QualifierValueAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface QualifierValueAction_Patch extends EntityAction_Patch {
	type: typeof QUALIFIERVALUE_PATCH;
	payload: QualifierValueEntityPatch_Some;
}

/**
 * Change qualifierValue entities
 *
 * @interface QualifierValueAction_Change
 * @extends {EntityAction_Change}
 */
interface QualifierValueAction_Change extends EntityAction_Change {
	type: typeof QUALIFIERVALUE_CHANGE;
	payload: QualifierValueEntityPatch_Some;
}

/**
 * Apply changes to qualifierValue entities
 *
 * @interface QualifierValueAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface QualifierValueAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof QUALIFIERVALUE_APPLY_CHANGES;
	payload: QualifierValueId_Some;
}

/**
 * Cancel changes to qualifierValue entities
 *
 * @interface QualifierValueAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface QualifierValueAction_CancelChanges
	extends EntityAction_CancelChanges {
	type: typeof QUALIFIERVALUE_CANCEL_CHANGES;
	payload: QualifierValueId_Some;
}

/**
 * Cache qualifierValue entities
 *
 * @interface QualifierValueAction_Cache
 * @extends {EntityAction_Cache}
 */
interface QualifierValueAction_Cache extends EntityAction_Cache {
	type: typeof QUALIFIERVALUE_CACHE;
	payload: QualifierValueId_Some;
}

/**
 * Uncache qualifierValue entities
 *
 * @interface QualifierValueAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface QualifierValueAction_Uncache extends EntityAction_Uncache {
	type: typeof QUALIFIERVALUE_UNCACHE;
	payload: QualifierValueId_Some;
}

/**
 * Set qualifierValue collection / entity state
 *
 * @interface QualifierValueAction_SetState
 * @extends {EntityAction_SetState}
 */
interface QualifierValueAction_SetState extends EntityAction_SetState {
	type: typeof QUALIFIERVALUE_SETSTATE;
}

/**
 * PAtch qualifierValue collection / entity state
 *
 * @interface QualifierValueAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface QualifierValueAction_PatchState extends EntityAction_PatchState {
	type: typeof QUALIFIERVALUE_PATCHSTATE;
}

/**
 * Export qualifierValue action types
 *
 * @export
 */
export type QualifierValueActionTypes =
	| QualifierValueAction_MutateId
	| QualifierValueAction_MutateId
	| QualifierValueAction_Set
	| QualifierValueAction_Toggle
	| QualifierValueAction_Select
	| QualifierValueAction_Deselect
	| QualifierValueAction_Upsert
	| QualifierValueAction_Patch
	| QualifierValueAction_Change
	| QualifierValueAction_ApplyChanges
	| QualifierValueAction_CancelChanges
	| QualifierValueAction_Cache
	| QualifierValueAction_Uncache
	| QualifierValueAction_SetState
	| QualifierValueAction_PatchState;

/**
 * QualifierValue actions helper interface
 *
 * @export
 * @interface IQualifierValueActions
 * @extends {EntityActions}
 */
export interface IQualifierValueActions extends EntityActions {
	//customAction(ids: QualifierValueId_Some): QualifierValueAction_CustomAction;
}

/**
 * QualifierValue actions helper
 *
 * @export
 * @class QualifierValueActions
 * @implements {EntityBaseActions}
 */
export class QualifierValueActions implements IQualifierValueActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): QualifierValueAction_MutateId {
		return { type: QUALIFIERVALUE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): QualifierValueAction_Set {
		return { type: QUALIFIERVALUE_SET, payload: id };
	}
	toggle(id?: EntityId): QualifierValueAction_Toggle {
		return { type: QUALIFIERVALUE_TOGGLE, payload: id };
	}
	select(ids: QualifierValueId_Some): QualifierValueAction_Select {
		return {
			type: QUALIFIERVALUE_SELECT,
			payload: ids
		};
	}
	deselect(ids: QualifierValueId_Some): QualifierValueAction_Deselect {
		return {
			type: QUALIFIERVALUE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: QualifierValueEntity_Some): QualifierValueAction_Upsert {
		return {
			type: QUALIFIERVALUE_UPSERT,
			payload: entities
		};
	}
	patch(entities: QualifierValueEntityPatch_Some): QualifierValueAction_Patch {
		return {
			type: QUALIFIERVALUE_PATCH,
			payload: entities
		};
	}
	change(
		entities: QualifierValueEntityPatch_Some
	): QualifierValueAction_Change {
		return {
			type: QUALIFIERVALUE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: QualifierValueId_Some): QualifierValueAction_ApplyChanges {
		return {
			type: QUALIFIERVALUE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(
		ids: QualifierValueId_Some
	): QualifierValueAction_CancelChanges {
		return {
			type: QUALIFIERVALUE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: QualifierValueId_Some): QualifierValueAction_Cache {
		return {
			type: QUALIFIERVALUE_CACHE,
			payload: ids
		};
	}
	uncache(ids: QualifierValueId_Some): QualifierValueAction_Uncache {
		return {
			type: QUALIFIERVALUE_UNCACHE,
			payload: ids
		};
	}
	setState(
		state: QualifierValueCollectionState
	): QualifierValueAction_SetState {
		return {
			type: QUALIFIERVALUE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<QualifierValueCollectionState>
	): QualifierValueAction_PatchState {
		return {
			type: QUALIFIERVALUE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the qualifierValue actions helper
 *
 * @export
 */
export const qualifierValueActions = new QualifierValueActions();
