'use strict';
import anyBase from 'any-base';
import uuidV4 from 'uuid/v4';
import validator from 'validator';

const flickrBase58 =
	'123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
const cookieBase90 =
	"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!#$%&'()*+-./:<=>?@[]^_`{|}~";

// shorten UUID by stripoubg the dashes, and translate.
const shortenUUID = (longId, translator) => {
	try {
		return translator(longId.toLowerCase().replace(/-/g, ''));
	} catch (err) {
		throw 'Error shortening uuid';
	}
};

// translate back to hex and turn back into UUID format, with dashes
const enlargeUUID = (shortId, translator) => {
	try {
		var uu1 = translator(shortId);
		var leftPad = '';
		var m;

		// pad out UUIDs beginning with zeros (any number shorter than 32 characters of hex)
		for (var i = 0, len = 32 - uu1.length; i < len; ++i) {
			leftPad += '0';
		}

		// join the zero padding and the UUID and then slice it up with match
		m = (leftPad + uu1).match(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/);

		// accumulate the matches and join them.
		return [m[1], m[2], m[3], m[4], m[5]].join('-');
	} catch (err) {
		throw 'Error enlarging uuid';
	}
};

export default (function () {
	function UUID(toAlphabet) {
		try {
			// default to Flickr 58
			var useAlphabet = toAlphabet || flickrBase58;

			// UUIDs are in hex, so we translate to and from
			var fromHex = anyBase(anyBase.HEX, useAlphabet);
			var toHex = anyBase(useAlphabet, anyBase.HEX);

			var generate = function () {
				return uuidV4();
			};

			return {
				new: generate,
				generate: generate,
				uuid: uuidV4,
				compress: function (uuid) {
					return validator.isUUID(uuid) ? shortenUUID(uuid, fromHex) : uuid;
				},
				decompress: function (shortUuid) {
					return validator.isUUID(shortUuid)
						? shortUuid
						: enlargeUUID(shortUuid, toHex);
				},
				alphabet: useAlphabet
			};
		} catch (err) {
			throw 'Error initializing uuid';
		}
	}

	// expose the constants for other purposes
	UUID.constants = {
		flickrBase58: flickrBase58,
		cookieBase90: cookieBase90
	};

	return UUID;
})();
