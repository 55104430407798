import React from 'react';

//Data
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonItem,
	IonCardHeader,
	IonCardContent,
	IonCardTitle,
	IonList,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonContent
} from '@ionic/react';
import { Avatar } from '../../../app/ui/components';

const UserView: React.FC = () => {
	const {
		config: [config],
		lead: {
			activeService,
			activeEvent,
			activeUser,
			licenseHelper,
			contextHelper,
			leadHelper
		}
	} = useCtx<{}>({});

	if (!activeService) return <>No Service selected</>;
	if (!activeEvent) return <>No Event selected</>;
	if (!activeUser) return <>No User selected</>;

	console.log(activeUser);

	//User Permissions
	let permisionList: Array<{ key: string; value: string; name: string }> = [
		{ key: 'leadServiceLeads', value: 'FULL', name: 'Lead' },
		{ key: 'leadServiceQualifiers', value: 'FULL', name: 'Qualifier' },
		{ key: 'leadServiceUsers', value: 'FULL', name: 'Representative' },
		{ key: 'leadServiceDevices', value: 'FULL', name: 'License' },
		{ key: 'leadServiceReports', value: 'FULL', name: 'Qualifier' }
	];

	const getSecurityCode = (
		permisionName: string,
		userPermisions: string[] | undefined
	) => {
		return userPermisions?.includes('write:' + permisionName) &&
			userPermisions?.includes('read:' + permisionName)
			? 'FULL'
			: userPermisions?.includes('read:' + permisionName)
			? 'READ'
			: 'NONE';
	};

	//Set values by user
	permisionList.forEach(permisionListItem => {
		permisionListItem.value = getSecurityCode(
			permisionListItem.key,
			activeUser.scope
		);
	});

	const permisionItems = permisionList.map(
		(
			permision: { key: string; value: string; name: string },
			index: number,
			leadList: { key: string; value: string; name: string }[]
		) => {
			return (
				<IonRow>
					<IonCol sizeMd="5">{permision.name} Access</IonCol>
					<IonCol sizeMd="7">
						<IonSegment
							key={permision.key}
							onIonChange={e => console.log('Segment selected', e.detail.value)}
							color="tertiary"
							value={permision.value}
						>
							<IonSegmentButton value="NONE">
								<IonLabel>None</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="READ">
								<IonLabel>Read</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="Full">
								<IonLabel>Full</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
				</IonRow>
			);
		}
	);

	//Licenses Information
	let licenses = licenseHelper
		.allByServices([activeService])
		.filter(
			license =>
				contextHelper.allByEventId(activeEvent?.id).length > 0 &&
				license.userIds?.includes(activeUser.id)
		);

	let licensesItems = licenses.length ? (
		licenses.map(license => {
			return (
				<IonRow>
					<IonCol sizeMd="10">{license.code}</IonCol>
					<IonCol sizeMd="2">{license.quantity}</IonCol>
				</IonRow>
			);
		})
	) : (
		<IonRow>
			<IonCol sizeMd="12">No licenses used by this user.</IonCol>
		</IonRow>
	);

	//Activity
	let leads = leadHelper.allByServiceIdAndUserId(
		activeService.id,
		activeUser.id
	);
	let lastActive = leads.length ? leads[0].i_.created.dt : 'No activity found';

	return (
		<IonContent>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/lead-service/users" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol size="2" sizeMd="2">
											<Avatar
												//src={activeUser?.picture}
												name={activeUser?.name}
											/>
										</IonCol>
										<IonCol size="10" sizeMd="10">
											<IonCardTitle>{activeUser.name}</IonCardTitle>
											{activeUser.email}
											<br />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="8">
						<IonCard hidden={true}>
							<IonCardHeader>
								<IonCardTitle>User Permissions</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>{permisionItems}</IonGrid>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Active Licenses
									<IonButton
										class="ion-float-right"
										size="small"
										routerLink="/lead-service/licenses"
									>
										Manage
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>{licensesItems}</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="4">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Activity</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem>
										Last Activity <br />
										{lastActive}
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default UserView;
