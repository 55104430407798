import React, { useEffect, useState } from 'react';

//DATA
import { eventSelectorConfig } from '../../../../config/lead/components/EventSelector';
import { ContextEntity, ContextEntities } from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonItem,
	IonLabel,
	IonPopover,
	IonButton,
	IonIcon,
	IonRadioGroup,
	IonRadio
} from '@ionic/react';
import { caretDownOutline, calendarOutline } from 'ionicons/icons';

export interface EventSelectorConfigProps {
	placeholder?: string;
	okay?: string;
	cancel?: string;
}

export interface EventSelectorProps {
	onSelectedContextChanged?: (selectedContext?: string) => {};
}

export const EventSelector: React.FC<EventSelectorProps> = props => {
	const ctx = useCtx<EventSelectorConfigProps>(eventSelectorConfig);
	const {
		config: [config],
		lead: {
			activeService,
			activeEvent,
			eventHelper,
			contextHelper,
			activeContext
		}
	} = ctx;
	const [popoverState, setShowPopover] = useState({
		showPopover: false,
		event: undefined
	});

	useEffect(() => {
		//Set default Context
		if ((!activeContext && activeService?.contextIds?.length) || 0 <= 0) {
			contextHelper.setServiceDefault(
				ctx,
				contextHelper.gets(activeService?.contextIds || [])
			);
			if (activeContext) {
				eventHelper.set(activeContext?.eventIds[0]);
			}
		}
	}, [activeService]);

	const onSelectedContextChanged = (cId: string) => {
		if (cId) {
			contextHelper.set(cId);
			if (activeContext) {
				eventHelper.set(activeContext?.eventIds[0]);
			}
		}
		if (props.onSelectedContextChanged) props.onSelectedContextChanged();
		setShowPopover({ showPopover: false, event: undefined });
	};

	const ionSelectOptions = contextHelper
		.gets(activeService?.contextIds || [])
		.map((context: ContextEntity) => {
			return (
				<IonItem key={context.id} lines="none">
					<IonLabel class="ion-text-wrap">{context.name}</IonLabel>
					<IonRadio
						id={context.id}
						value={context.id}
						onClick={() => onSelectedContextChanged(context.id)}
					></IonRadio>
				</IonItem>
			);
		});

	return (
		<>
			<IonPopover
				event={popoverState.event}
				isOpen={popoverState.showPopover}
				onDidDismiss={() =>
					setShowPopover({ showPopover: false, event: undefined })
				}
			>
				<IonRadioGroup value={activeContext?.id}>
					{ionSelectOptions}
				</IonRadioGroup>
			</IonPopover>
			<IonButton
				color="light"
				onClick={(e: any) => {
					e.persist();
					setShowPopover({ showPopover: true, event: e });
				}}
			>
				<IonIcon icon={calendarOutline} style={{ paddingRight: '15px' }} />
				<IonLabel class="ion-text-wrap">
					{activeContext?.name ?? 'No Event Selected'}
				</IonLabel>
				<IonIcon icon={caretDownOutline} />
			</IonButton>
		</>
	);
};
