import * as storage from '../../../storage/models';
import { Entity } from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { apiConfig } from '../../../config/app/Api';
import { Api } from '../models';

/**
 * Api entity interface
 *
 * @export
 * @interface ApiEntity
 * @extends {storage.Entity}
 * @extends {Api}
 */
export interface ApiEntity extends Entity, Api {}

/**
 * Api entities array
 *
 * @export
 */
export type ApiEntities = ApiEntity[];

/**
 * Some api entities: one entity or an array of entities
 *
 * @export
 */
export type ApiEntity_Some = ApiEntity | ApiEntities;

/**
 * Api entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ApiEntityPatch
 * @extends {storage.EntityPatch<ApiEntity>}
 */
export type ApiEntityPatch = storage.EntityPatcher<ApiEntity>;

/**
 * Api entity patches array
 *
 * @export
 */
export type ApiEntitiesPatch = ApiEntityPatch[];

/**
 * Some api entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ApiEntityPatch_Some = ApiEntityPatch | ApiEntitiesPatch;

/**
 * Api entity id
 *
 * @export
 */
export type ApiId = storage.EntityId;

/**
 * Api entity ids
 *
 * @export
 */
export type ApiIds = storage.EntityIds;

/**
 * Some api entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ApiId_Some = ApiId | ApiIds;

/**
 * Api entities object by id
 *
 * @export
 * @interface ApiEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ApiEntitiesObject extends storage.EntitiesObject {
	[id: string]: ApiEntity;
}

/**
 * Api entity state
 *
 * @export
 * @interface ApiEntityState
 * @extends {storage.EntityState}
 */
export interface ApiEntityState extends storage.EntityState {
	dt?: string;
}

/**
 * Api store collection
 *
 * @export
 * @interface ApiStoreCollection
 * @extends {storage.Store}
 */
export interface ApiStoreCollection extends storage.Store {
	api: ApiCollection;
}

/**
 * Api collection properties
 *
 * @export
 * @interface ApiCollection
 * @extends {Collection}
 */
export interface ApiCollection extends storage.Collection {
	byIds: ApiEntitiesObject;
	mutation: ApiEntitiesObject;
	cache: ApiEntitiesObject;
	state: ApiCollectionState;
}

/**
 * Api collection state
 *
 * @export
 * @interface ApiCollectionState
 * @extends {storage.CollectionState}
 */
export interface ApiCollectionState extends storage.CollectionState {}

// custom api collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const apiCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const apiCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const apiCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const apiCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: apiCollectionIdStringProps,
	idArrayProps: apiCollectionIdArrayProps,
	idEntityProps: apiCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Api collection default values, initialize collection with api entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const apiCollectionDefault: ApiCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ApiCollection),
		...{
			activeId: apiConfig.activeId || apiConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	apiConfig.entities
);

/**
 * Api store collection default values
 *
 * @export
 * @constant
 */
export const apiStoreCollectionDefault: ApiStoreCollection = {
	api: apiCollectionDefault
};

/**
 * Api store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getApiCollection = (state: any) => state.app.api;
