// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Qualifier value type. For pre-defined values use \'selection\' and \'data-entry\' for custom entries like Other options
 * @export
 * @enum {string}
 */
export enum QualifierValueType {
    Selection = 'selection',
    DataEntry = 'data-entry'
}

/**
* @export
* @const {string[]}
*/
export const valuesQualifierValueType = [
    'selection',
    'data-entry'
];

