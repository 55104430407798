import { Localize } from '../../locales';
import { AvatarConfigProps } from '../../../app/ui/components/Avatar';

export type AvatarConfig = Localize<AvatarConfigProps>;

const defaults: AvatarConfigProps = {
	src:
		'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
	round: true,
	maxInitials: 2,
	textSizeRatio: 0,
	textMarginRatio: 0
};
export const avatarConfig: AvatarConfig = {
	en: defaults,
	fr: { ...defaults, round: false }
};
