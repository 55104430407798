import { RouteHelper } from '..';
import { RouteEntity } from '../collections';

export interface RouteHook {
	routeHelper: RouteHelper;
	activeRoute: RouteEntity | undefined;
}

export const useRoute = (): RouteHook => {
	let routeHelper = new RouteHelper();
	return {
		routeHelper,
		activeRoute: routeHelper.active()
	};
};
