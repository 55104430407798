import { NoteHelper } from '..';
import { NoteEntity } from '../collections';

export interface NoteHook {
	noteHelper: NoteHelper;
	activeNote: NoteEntity | undefined;
}

export const useNote = (): NoteHook => {
	let noteHelper = new NoteHelper();
	return {
		noteHelper,
		activeNote: noteHelper.active()
	};
};
