import React from 'react';

import useClipboard from '../../store/hooks/useClipboard';
import { checkmarkOutline, clipboardOutline } from 'ionicons/icons';
import { IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { useToast } from '@agney/ir-toast';

export interface CopyToClipboardConfigProps {
	text?: string;
}

export interface CopyToClipboardProps {
	text: string;
	onCopy?: (text: string) => void;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = props => {
	const [clipboard, setClipboard, clipboardState] = useClipboard({
		updateFrequency: 1000
	});

	const Toast = useToast();

	return (
		<IonButton
			color={clipboard === props.text ? 'primary' : 'light'}
			onClick={() =>
				setClipboard(
					props.text,
					text => {
						if (clipboard !== props.text) Toast.success('Copied: ' + text);
						if (props.onCopy) props.onCopy(text);
					},
					e =>
						clipboard !== props.text
							? Toast.error(e?.message || 'Clipboard copy failed')
							: undefined
				)
			}
		>
			<IonLabel slot="start">{props.children}</IonLabel>
			<IonIcon
				slot="end"
				icon={clipboard === props.text ? checkmarkOutline : clipboardOutline}
			/>
		</IonButton>
	);
};
