import React from 'react';

//DATA
import {
	ContextEntities,
	UserEntities,
	UserEntity,
	isObjectStatusActive
} from '../../store';
import { useCtx } from '../../../config/hooks';

import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonToolbar,
	IonButtons,
	IonItem,
	IonInput,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonBackButton,
	IonContent
} from '@ionic/react';
import { Avatar } from '../../../app/ui/components';
import { EventList } from '../containers/Event';
import { useToast } from '@agney/ir-toast';

const ServiceView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			serviceHelper,
			activeService,
			contextHelper,
			eventHelper,
			userHelper,
			contactHelper
		}
	} = ctx;

	const Toast = useToast();
	const eventContexts: ContextEntities = contextHelper
		.gets(activeService?.contextIds || [])
		.filter(isObjectStatusActive);
	const users: UserEntities = userHelper
		.gets(activeService?.userIds || [])
		.filter(isObjectStatusActive);
	const serviceContacts = contactHelper
		.gets(activeService?.contactIds || [])
		.filter(isObjectStatusActive);
	const mainContact = serviceContacts[0];

	const updateActiveService = () => {
		if (activeService) {
			serviceHelper.write(ctx, {
				userId: activeUser?.userId,
				services: [activeService]
			});
			Toast.success('Account updated successfully!');
		}
	};

	const userListItems = users.map((user: UserEntity) => {
		return (
			<IonItem
				key={user.id}
				button
				routerLink="/lead-service/user"
				onClick={() => {
					userHelper.set(user.id);
				}}
			>
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonLabel>
								<div>
									<div
										style={{ display: 'inline-block', paddingRight: '20px' }}
									>
										<Avatar
											//src={currentUser?.picture}
											name={user.name}
										/>
									</div>
									<div style={{ display: 'inline-block' }}>
										<h2>
											{user.firstName || user.lastName
												? user.firstName + ' ' + user.lastName
												: ''}
										</h2>
										<h2>{user.email}</h2>
									</div>
								</div>
							</IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
		);
	});

	return (
		<IonContent>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/lead-service/services" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<IonItem>
										<IonInput
											placeholder="Enter Account Name"
											value={activeService?.name}
											onIonChange={(e: any) => {}}
											onIonBlur={(e: any) => {
												if (
													activeService &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !== activeService?.name?.trim()
												) {
													activeService.name = e.target?.value.trim();
													updateActiveService();
												}
											}}
										></IonInput>
									</IonItem>
								</IonCardTitle>
								<IonCardContent hidden={!mainContact}>
									{mainContact?.address1} {mainContact?.address2}
									<br />
									{mainContact?.city} {mainContact?.subdivision}{' '}
									{mainContact?.postal}
									<br />
									{mainContact?.email}
									<br />
								</IonCardContent>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol sizeMd="8">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<IonItem>Events</IonItem>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<EventList service={activeService} contexts={eventContexts} />
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol sizeMd="4">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<IonItem>Users</IonItem>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>{userListItems}</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default ServiceView;
