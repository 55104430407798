// tslint:disable
/**
 * CONEXSYS EventAnswer Portal API
 * The API for the CONEXSYS EventAnswer Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * EventAnswer: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { EventAnswerHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { EventAnswerEntity, EventAnswerEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum EventAnswerApiOperation {
	readEventAnswers = 'readEventAnswers',
	readEventAnswersById = 'readEventAnswersById'
}

export interface ReadEventAnswersRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventId: string;
}

export interface ReadEventAnswersByIdRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventIds: string[];
	ids: string[];
}

/**
 * Get detailed event event answers
 */
export async function readEventAnswers(
	ctx: UseCtx<any>,
	requestParameters: ReadEventAnswersRequest
): Promise<EventAnswerEntities> {
	const operationId = EventAnswerApiOperation.readEventAnswers,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventAnswerEntities,
		ReadEventAnswersRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: EventAnswerEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventAnswerEntities, EventAnswerHelper>(
		ctx.lead.eventAnswer,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventAnswer.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Get detailed event answers by id
 */
export async function readEventAnswersById(
	ctx: UseCtx<any>,
	requestParameters: ReadEventAnswersByIdRequest
): Promise<EventAnswerEntities> {
	const operationId = EventAnswerApiOperation.readEventAnswersById,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventAnswerEntities,
		ReadEventAnswersByIdRequest
	>(ctx, config.apiId, operationId, requestParameters, 'ids');

	let entities: EventAnswerEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventAnswerEntities, EventAnswerHelper>(
		ctx.lead.eventAnswer,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventAnswer.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
