import React, { useContext, useEffect, useState } from 'react';
import { IonSpinner, IonText, IonGrid, IonRow, IonCol } from '@ionic/react';
import uuidV4 from 'uuid/v4';
import { updateAppDevice } from '../../store';
import { appApis, initApi, AppApi } from '../../api';
import { useCtx } from '../../../config/hooks';
import { useToast } from '@agney/ir-toast';

export interface AppDataProps {}

export const AppData: React.FC<AppDataProps> = props => {
	const [initialized, setInitialized] = useState(false);
	const [current, setCurrent] = useState({
		apiId: '',
		deviceId: '',
		userId: '',
		serviceId: '',
		contextId: '',
		eventId: ''
	});
	const [loading, setLoading] = useState({ type: '', id: '' });
	const [message, setMessage] = useState('Loading...');

	const Toast = useToast();

	const ctx = useCtx();

	const {
		app: { appError, errorClear }
	} = ctx;

	const active = {
		apiId: ctx.app.api.active()?.id || '',
		deviceId: localStorage.getItem('deviceId') || uuidV4(),
		userId: ctx.app.user.active()?.userId || '',
		serviceId: ctx.lead.service.active()?.id || '',
		contextId: ctx.lead.context.active()?.id || '',
		eventId: ctx.lead.event.active()?.id || ''
	};

	const initApis = async () => {
		let apis = ctx.app.api.all();
		for (let api of apis) {
			let appApi: AppApi = await initApi(api);
			appApis[api.id] = appApi;
		}
	};

	const init = async () => {
		if (loading.type !== '') return;

		if (active.apiId !== '' && active.apiId !== current.apiId) {
			setMessage('Loading App...');
			setLoading({ type: 'api', id: active.apiId });
			await initApis();
			setCurrent({ ...current, apiId: active.apiId });
			console.log('Current api data loaded');
			setLoading({ type: '', id: '' });
		} else if (active.deviceId !== '' && active.deviceId !== current.deviceId) {
			setMessage('Loading Device...');
			setLoading({ type: 'device', id: active.deviceId });
			updateAppDevice();
			setCurrent({ ...current, deviceId: active.deviceId });
			localStorage.setItem('deviceId', active.deviceId);
			console.log('Current device data loaded');
			setLoading({ type: '', id: '' });
		} else if (active.userId !== '' && active.userId !== current.userId) {
			setMessage('Loading User...');
			setLoading({ type: 'user', id: active.userId });
			ctx.lead.user.readActiveData(ctx, () => {
				setCurrent({ ...current, userId: active.userId });
				console.log('Current user data loaded');
				setLoading({ type: '', id: '' });
			});
		} else if (
			active.serviceId !== '' &&
			active.serviceId !== current.serviceId &&
			!ctx.app.activeUser?.security.includes('administrator')
		) {
			setMessage('Loading Service...');
			setLoading({
				type: 'service',
				id: active.serviceId
			});
			ctx.lead.service.readActiveData(ctx, () => {
				setCurrent({ ...current, serviceId: active.serviceId });
				console.log('Current service data loaded');
				setLoading({ type: '', id: '' });
			});
		} else if (
			active.contextId !== '' &&
			active.contextId !== current.contextId
		) {
			setMessage('Loading Context...');
			setLoading({
				type: 'context',
				id: active.contextId
			});
			ctx.lead.context.readActiveData(ctx, () => {
				setCurrent({ ...current, contextId: active.contextId });
				console.log('Current context data loaded');
				setLoading({ type: '', id: '' });
			});
		} else if (active.eventId !== '' && active.eventId !== current.eventId) {
			setMessage('Loading Event...');
			setLoading({
				type: 'event',
				id: active.eventId
			});
			ctx.lead.event.readActiveData(ctx, () => {
				setCurrent({ ...current, eventId: active.eventId });
				console.log('Current event data loaded');
				setLoading({
					type: '',
					id: ''
				});
			});
		} else {
			setMessage('Loaded');
			if (!initialized) setInitialized(true);
		}
	};

	useEffect(() => {
		init();
		if (appError) {
			Toast.error(appError.message);
			errorClear(); // clear error
		}
	}, [active, appError]);

	return (
		<>
			{!initialized ? (
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonSpinner slot="start" />
							<IonText>{message}</IonText>
						</IonCol>
					</IonRow>
				</IonGrid>
			) : (
				props.children
			)}
		</>
	);
};
