import React from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonText
} from '@ionic/react';

import { useCtx } from '../../../../config/hooks';

export interface DeviceServiceUsageConfigProps {
	title?: string;
	finish?: any;
}

export const DeviceServiceUsage: React.FC<DeviceServiceUsageConfigProps> = props => {
	const {
		config: [config],
		lead: { deviceHelper, activeService }
	} = useCtx();

	let activeDevices = (!activeService
		? []
		: deviceHelper.allByService(activeService)
	).length;

	let licenses = 99;

	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Devices License(s)</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonText>
								<h1>{licenses}</h1>
							</IonText>
						</IonCardContent>
					</IonCard>
				</IonCol>
				<IonCol>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Active Device(s)</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonText>
								<h1>{activeDevices}</h1>
							</IonText>
						</IonCardContent>
					</IonCard>
				</IonCol>
				<IonCol>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Remaining Device(s)</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonText>
								<h1>{licenses - activeDevices}</h1>
							</IonText>
						</IonCardContent>
					</IonCard>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
