export const html = `
<header class="masthead text-white text-center">
	<div class="overlay"></div>
	<div class="container">
		<div class="row">
			<div class="col-xl-9 mx-auto">
				<h1 class="mb-5" style="color: #FFFFFF;">
					CONEXSYS Lead Retrieval <br /> North America's premier registration and lead retrieval Provider
				</h1>
			</div>		
			<div class="col-xl-9 text-center mx-auto">
				<button type="submit" class="btn btn-lg btn-primary col-12 col-md-4" onclick="$('#main > div > ion-header > ion-toolbar > ion-buttons.buttons-last-slot.sc-ion-buttons-md-h.sc-ion-buttons-md-s.md.hydrated > ion-button').click();">
					Sign In / Sign Up
				</button>
			</div>
			<div class="col-xl-9 text-center mx-auto">
				<h3 class="mb-5"> OR </h3>
			</div>
			<div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
				<div class="row">
					<div class="col-12 col-md-9 mb-2 mb-md-0">
						<input
							type="text"
							id="leadordercodetop"
							class="form-control form-control-lg"
							placeholder="Enter lead order Code..."
						/>
					</div>
					<div class="col-12 col-md-3">
						<button type="submit" class="btn btn-block btn-lg btn-primary" onclick="window.location.href='/activate?orderCode=' + document.getElementById('leadordercodetop').value">
							Start Now
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>

<!-- Icons Grid -->
<section class="features-icons bg-light text-center">
	<div class="container">
		<div class="row">
			<div class="col-lg-4">
				<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
					<div class="features-icons-icon d-flex">
						<i class="fa fa-desktop m-auto text-primary" aria-hidden="true"></i>
					</div>
					<h3>Multiple Platforms</h3>
					<p class="lead mb-0">
						Capture leads on any device. From your mobile phone to our offline solutions.
					</p>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
					<div class="features-icons-icon d-flex">
						<i class="fa fa-qrcode m-auto text-primary" aria-hidden="true"></i>
					</div>
					<h3>Customized Experience</h3>
					<p class="lead mb-0">
						Capture more information and track custom selections and notes.
					</p>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="features-icons-item mx-auto mb-0 mb-lg-3">
					<div class="features-icons-icon d-flex">
						<i class="fa fa-check-square-o m-auto text-primary" aria-hidden="true"></i>
					</div>
					<h3>Easy to Use</h3>
					<p class="lead mb-0">
						Our intuitive design and flexible solutions makes capturing leads simple.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Image Showcases -->
<section class="showcase">
	<div class="container-fluid p-0">
		<div class="row no-gutters">
			<div
				class="col-lg-6 order-lg-2 text-white showcase-img showcase-img-1"></div>
			<div class="col-lg-6 order-lg-1 my-auto showcase-text">
				<h2>Why use lead retrieval?</h2>
				<p class="lead mb-0">
					In a competitive world you need all the advantage you can get. CONEXSYS®helps you succeed by providing comprehensive lead retrieval information in a simple, fast and efficient manner.
				</p>
			</div>
		</div>
		<div class="row no-gutters">
			<div
				class="col-lg-6 text-white showcase-img  showcase-img-2"></div>
			<div class="col-lg-6 my-auto showcase-text">
				<h2>Reports & Electronic File</h2>
				<p class="lead mb-0">
					Your daily lead report in scan sequence.<br />
					End-of-event report in visiting company sequence.<br />
					Electronic File (ASCII comma delimited, .csv)
				</p>
			</div>
		</div>
		<div class="row no-gutters">
			<div
				class="col-lg-6 order-lg-2 text-white showcase-img showcase-img-3"></div>
			<div class="col-lg-6 order-lg-1 my-auto showcase-text">
				<h2>Easy to Use &amp; Customize</h2>
				<p class="lead mb-0">
					CONEXSYS®has a large scope of products and services intended towards making your event a success. We are always adding new products and services to better suit your needs.
				</p>
			</div>
		</div>
	</div>
</section>

<!-- Testimonials -->
<section class="testimonials text-center bg-light">
	<div class="container">
		<h2 class="mb-5">Our scanner options</h2>
		<div class="row">
			<div class="col-lg-4">
				<div class="testimonial-item mx-auto mb-5 mb-lg-0">
					<div class="img-fluid testimonial-item-1 rounded-circle mb-3"></div>
					<h5>Opticon (Hand-Held Portable Scanner)</h5>
					<p class="font-weight-light mb-0">
						Fast, easy & economical method of collecting valuable leads
					</p>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="testimonial-item mx-auto mb-5 mb-lg-0">
					<div class="img-fluid testimonial-item-2 rounded-circle mb-3"></div>
					<h5>SST (Portable Scanner with Optional Printer)</h5>
					<p class="font-weight-light mb-0">
						Compact, portable battery-powered scanner with Multimedia card interface for additional removable storage.
					</p>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="testimonial-item mx-auto mb-5 mb-lg-0">
					<div class="img-fluid testimonial-item-3 rounded-circle mb-3"></div>
					<h5>Mobile Application</h5>
					<p class="font-weight-light mb-0">
						Bring your own phone and have realtime lead data at your finger tips.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Call to Action -->
<section class="call-to-action text-white text-center">
	<div class="overlay"></div>
	<div class="container">
		<div class="row">
			<div class="col-xl-9 mx-auto">
				<h2 class="mb-4">Ready to get started? <button class="btn btn-link" style="font-size:1.1em;padding:0px;" onclick="$('#main > div > ion-header > ion-toolbar > ion-buttons.buttons-last-slot.sc-ion-buttons-md-h.sc-ion-buttons-md-s.md.hydrated > ion-button').click();">Login in now!</button> <br /> or enter your order code below</h2>
			</div>
			<div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
				<div class="row">
					<div class="col-12 col-md-9 mb-2 mb-md-0">
						<input
							type="text"
							id="leadordercodebtm"
							class="form-control form-control-lg"
							placeholder="Enter lead order code..."
						/>
					</div>
					<div class="col-12 col-md-3">
						<button type="submit" class="btn btn-block btn-lg btn-primary" onclick="window.location.href='/activate?orderCode=' + document.getElementById('leadordercodebtm').value">
							Start Now
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Footer -->
<footer class="footer bg-light">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 h-100 text-center text-lg-left my-auto">
				<ul class="list-inline mb-2">
					<li class="list-inline-item">
						<a href="#">About</a>
					</li>
					<li class="list-inline-item">&sdot;</li>
					<li class="list-inline-item">
						<a href="#">Contact</a>
					</li>
					<li class="list-inline-item">&sdot;</li>
					<li class="list-inline-item">
                        
						<a href="#">Terms of Use</a>
					</li>
					<li class="list-inline-item">&sdot;</li>
					<li class="list-inline-item">
						<a href="#">Privacy Policy</a>
					</li>
				</ul>
				<p class="text-muted small mb-4 mb-lg-0">
					&copy; CONEXSYS 2020. All Rights Reserved.
				</p>
			</div>
			<div class="col-lg-6 h-100 text-center text-lg-right my-auto">
				<ul class="list-inline mb-0">
					<li class="list-inline-item mr-3">
						<a href="#">
							<i class="fa fa-facebook-square fa-2x fa-fw"></i>
						</a>
					</li>
					<li class="list-inline-item mr-3">
						<a href="#">
							<i class="fa fa-twitter-square fa-2x fa-fw"></i>
						</a>
					</li>
					<li class="list-inline-item">
						<a href="#">
							<i class="fa fa-instagram fa-2x fa-fw"></i>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>
`;
