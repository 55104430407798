import { AvatarConfig, avatarConfig } from './Avatar';
import { MenuConfig, menuConfig } from './Menu';
import { SearchBarConfig, searchBarConfig } from './SearchBar';
import { SearchFilterConfig, searchFilterConfig } from './SearchFilter';
import {
	UserAccountCardConfig,
	userAccountCardConfig
} from './UserAccountCard';
import { UserControlConfig, userControlConfig } from './UserControl';
import { UserSettingsConfig, userSettingsConfig } from './UserSettings';

export interface ComponentsConfig {
	avatar: AvatarConfig;
	menu: MenuConfig;
	searchBar: SearchBarConfig;
	searchFilter: SearchFilterConfig;
	userControl: UserAccountCardConfig;
	userMenu: UserAccountCardConfig;
	userAccountCard: UserControlConfig;
	userSettings: UserSettingsConfig;
}

export const componentsConfig: ComponentsConfig = {
	avatar: avatarConfig,
	menu: menuConfig,
	searchBar: searchBarConfig,
	searchFilter: searchFilterConfig,
	userControl: userAccountCardConfig,
	userMenu: userAccountCardConfig,
	userAccountCard: userControlConfig,
	userSettings: userSettingsConfig
};
