import React, { useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonButton,
	IonIcon,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonText,
	IonPopover,
	IonRadioGroup,
	IonRadio,
	IonButtons
} from '@ionic/react';
import * as icons from 'ionicons/icons';
import { SelectorConfig } from '../../../config/app/components/Selector';
import { useLocaleConfig } from '../../store';
import { SortType } from '../../../lead/ui/hooks/leads';
export interface SelectorConfigProps {
	placeholder: string;
	noItemsText?: string;
}

export interface SelectorItem {
	text: string;
	value?: any;
	icon?: string;
}

export type SelectorItems = SelectorItem[];

export interface SelectorProps {
	onSelect: (item: SelectorItem) => void;
	onDeselect?: (item: SelectorItem) => void;
	toggleSortDirection?: () => void;
	name: string;
	items: SelectorItems;
	value?: any;
	direction?: SortType;
	noItemsText?: string;
	placeholder?: string;
	icon?: string;
	iconOnly?: boolean;
}

/*
	Todo:
	- Convert popover to a selector
*/
export const Selector: React.FC<SelectorProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<SelectorConfigProps>(SelectorConfig);
	const [popover, setPopover] = useState({
		isOpen: false,
		event: undefined
	});

	const selectedItem: SelectorItem | undefined = props.items.filter(
		item => (item.value || item.text) === props.value
	)[0];

	const onChange = (item: SelectorItem) =>
		props.value === (item.value || item.text)
			? onDeselect(item)
			: onSelect(item);

	const onSelect = (item: SelectorItem) => {
		props.onSelect(item);
		setPopover({ isOpen: false, event: undefined });
	};
	const onDeselect = (item: SelectorItem) => {
		if (props.onDeselect) props.onDeselect(item);
		setPopover({ isOpen: false, event: undefined });
	};

	const popoverItems =
		props.items.length > 0 ? (
			props.items.map((item, i) => {
				return (
					<IonItem key={props.name + '-item-' + (i + 1)} lines="none">
						<IonLabel>{item.text}:</IonLabel>
						<IonRadio
							id={props.name + '-item-option-' + (i + 1)}
							value={item.value || item.text}
							onClick={() => onChange(item)}
						></IonRadio>
					</IonItem>
				);
			})
		) : (
			<IonItem key={props.name + '-item-none'} lines="none">
				{props.noItemsText || config.noItemsText}
			</IonItem>
		);

	const selectorItems =
		props.items.length > 0 ? (
			props.items.map((item, i) => {
				return (
					<IonItem key={props.name + '-item-' + (i + 1)} lines="none">
						<IonLabel>{item.text}:</IonLabel>
						<IonRadio
							id={props.name + '-item-option-' + (i + 1)}
							value={item.value || item.text}
							onClick={() => onChange(item)}
						></IonRadio>
					</IonItem>
				);
			})
		) : (
			<IonItem key={props.name + '-item-none'} lines="none">
				{props.noItemsText || config.noItemsText}
			</IonItem>
		);

	return (
		<>
			<IonPopover
				event={popover.event}
				isOpen={popover.isOpen}
				onDidDismiss={() => setPopover({ isOpen: false, event: undefined })}
			>
				<IonRadioGroup value={props.value}>{popoverItems}</IonRadioGroup>
			</IonPopover>
			<IonButtons>
				{/* <IonSelect
					interface="popover"
					name="sortOption"
					multiple={false}
					value={selectedItem?.text || props.placeholder || config.placeholder}
				>
					{props.items.length > 0 &&
						props.items.map(item => {
							return (
								<IonSelectOption value={item.value || item.text}>
									{item.value || item.text}
								</IonSelectOption>
							);
						})}
				</IonSelect> */}
				<IonButton
					onClick={(e: any) => {
						e.persist();
						setPopover({ isOpen: true, event: e });
					}}
				>
					{props.iconOnly ? (
						<IonIcon
							icon={selectedItem?.icon || props.icon || icons.caretDownOutline}
						/>
					) : (
						<>
							{(selectedItem?.icon || props.icon) && (
								<IonIcon icon={selectedItem?.icon || props.icon} />
							)}
							<IonText
								className="ion-hide-lg-down"
								style={
									selectedItem?.icon || props.icon
										? { paddingLeft: '15px' }
										: undefined
								}
							>
								{selectedItem?.text || props.placeholder || config.placeholder}
							</IonText>
							<IonIcon icon={icons.caretDownOutline} />
						</>
					)}
				</IonButton>
				<IonButton onClick={props.toggleSortDirection}>
					<IonIcon
						slot="icon-only"
						icon={
							props.direction === SortType.Ascending
								? icons.arrowDownOutline
								: icons.arrowUpOutline
						}
					/>
				</IonButton>
			</IonButtons>
		</>
	);
};
