import { Localize } from '../../locales';
import { SelectorConfigProps } from '../../../app/ui/components/Selector';

export type SelectorConfig = Localize<SelectorConfigProps>;

export const SelectorConfig: SelectorConfig = {
	en: {
		placeholder: 'Select'
	},
	fr: {
		placeholder: 'Sélectionner'
	}
};
