import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getUserCollection,
	UserId,
	UserIds,
	UserId_Some,
	UserEntity,
	UserEntities,
	UserEntity_Some,
	UserEntityPatch_Some,
	UserCollection,
	UserCollectionState,
	IUserActions,
	userActions,
	UserActionTypes
} from '..';
import {
	readUsers,
	writeUsers,
	ReadUsersRequest,
	WriteUsersRequest,
	UserApiOperation,
	readUsersAll,
	GetUserByIdRequest,
	getUserById
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContextEntities,
	ContextEntity,
	EventEntities,
	ServiceEntities,
	ServiceEntity
} from '../collections';
import moment from 'moment';
import _ from 'lodash';
import { isObjectStatusActive } from '../models';
import { Utils } from '../../../app';

/**
 * User helper interface
 *
 * @export
 * @interface IUserHelper
 * @extends {IEntityHelper}
 */
export interface IUserHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * User helper options interface
 *
 * @export
 * @interface UserHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface UserHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const userHelperOpts: UserHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

export interface UserEntityFilter {
	keyWords?: string;
	contextIds?: string[];
}

/**
 * User helper
 *
 * @export
 * @class UserHelper
 * @extends {EntityHelper<UserCollection, UserActionTypes, UserActions, UserEntity, UserEntities, UserEntity_Some, UserEntityPatch_Some, UserId, UserIds, UserId_Some, UserCollectionState, UserHelperOpts>}
 * @implements {IUserHelper}
 */
export class UserHelper
	extends EntityHelper<
		UserCollection,
		UserActionTypes,
		IUserActions,
		UserEntity,
		UserEntities,
		UserEntity_Some,
		UserEntityPatch_Some,
		UserId,
		UserIds,
		UserId_Some,
		UserCollectionState,
		UserHelperOpts
	>
	implements IUserHelper {
	constructor() {
		super(
			useSelector(getUserCollection),
			userActions,
			useDispatch(),
			userHelperOpts
		);
		this.collection = useSelector(getUserCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: UserApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async readUserById(
		ctx: UseCtx<any>,
		params: GetUserByIdRequest,
		callback?: any
	): Promise<UserEntity | undefined> {
		return (await getUserById(ctx, params)
			.then((entity: UserEntity | undefined) => {
				if (callback) callback(entity);
				return entity;
			})
			.catch(e => {
				if (callback) callback(e);
				return;
			})) as UserEntity | undefined;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadUsersRequest> = {},
		callback?: any
	): Promise<UserEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(UserApiOperation.readUsers);

		let request: ReadUsersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: UserEntities = await readUsers(ctx, request)
			.then((entities: UserEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadUsersRequest> = {},
		callback?: any
	): Promise<UserEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(UserApiOperation.readUsersAll);

		let request: ReadUsersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: UserEntities = await readUsersAll(ctx, request)
			.then((entities: UserEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteUsersRequest> = {},
		callback?: any
	): Promise<UserEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.users) return [];
		let request: WriteUsersRequest = {
			...params,
			...{
				users: params.users,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: UserEntities = await writeUsers(ctx, request)
			.then((entities: UserEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByFilter(ctx: UseCtx<any>, filter: UserEntityFilter): UserEntities {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(
				user =>
					ctx.lead.serviceHelper
						.allByContextIds(filter.contextIds || [])
						.findIndex(e => e.userIds?.includes(user.id)) >= 0
			)
			.filter(user => {
				let match = 0,
					matches = 0,
					keywords = filter.keyWords ?? '';

				if (!user) {
					return false;
				}

				for (let keyword of keywords.toLowerCase().split(' ')) {
					if (keyword === '') break;
					match++;
					if (
						(user.firstName &&
							user.firstName?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(user.lastName &&
							user.lastName?.toLowerCase().trim().indexOf(keyword) > -1) ||
						(user.email &&
							user.email?.toLowerCase().trim().indexOf(keyword) > -1)
					) {
						matches++;
					}
				}
				return match === matches;
			});
	}

	allByService(service: ServiceEntity): UserEntities {
		return this.all()
			.filter(user => service.userIds?.includes(user.id))
			.filter(isObjectStatusActive);
	}

	async readActiveData(ctx: UseCtx<any>, callback?: any) {
		let userId: string = ctx.app.user.active()?.userId || '';
		if (
			userId !== '' &&
			!ctx.app.user.active()?.security.includes('administrator')
		) {
			ctx.lead.service.read(ctx, undefined, (services: ServiceEntities) => {
				//let [services, err] = Utils.result<ServiceEntities>(result);
				//if (err) return ctx.app.error(err);

				let contextIds: string[] = [],
					eventIds: string[] = [];

				// set default service for user
				ctx.lead.service.setDefault(ctx, services);

				if (services) {
					services.forEach(service => {
						contextIds = _.union(contextIds, service.contextIds || []);
					});
					if (contextIds.length > 0) {
						console.log(contextIds);
						ctx.lead.context.read(
							ctx,
							{ ids: contextIds },
							(contexts: ContextEntities) => {
								ctx.lead.context.setServiceDefault(ctx, contexts);
								console.log('Service data loaded: Contexts');

								contexts.forEach(contexts => {
									eventIds = _.union(eventIds, contexts.eventIds || []);
								});
								if (eventIds.length > 0) {
									ctx.lead.event.read(
										ctx,
										undefined,
										(events: EventEntities) => {
											ctx.lead.event.setServiceDefault(ctx, events);
											console.log('Service data loaded: Events');
										}
									);
								}
							}
						);
					}

					console.log('User data loaded: Services');

					if (callback) callback();
				}
			});
			if (callback) callback();
		} else {
			if (callback) callback();
		}
	}

	hasAccess(user: UserEntity): boolean {
		return true;
	}
}
