import { useEffect, useState } from 'react';

export function useInterval(handler: any, delay: number, active?: boolean) {
	useEffect(() => {
		let interval: any;

		const start = () => {
			clearInterval(interval);
			interval = setInterval(() => handler(start, active), delay);
		};

		handler(start, active);

		return () => clearInterval(interval);
	}, [delay, active]);
}

export function insertParam(key: string, value: string) {
	key = encodeURIComponent(key);
	value = encodeURIComponent(value);

	// kvp looks like ['key1=value1', 'key2=value2', ...]
	var kvp = document.location.search.substr(1).split('&');
	let i = 0;

	for (; i < kvp.length; i++) {
		if (kvp[i].startsWith(key + '=')) {
			let pair = kvp[i].split('=');
			pair[1] = value;
			kvp[i] = pair.join('=');
			break;
		}
	}

	if (i >= kvp.length) {
		kvp[kvp.length] = [key, value].join('=');
	}

	// can return this or...
	let params = kvp.join('&');

	// reload page with new params
	document.location.search = params;
}

export const useReactHref = () => {
	const [href, setHref] = useState(window.location.href);
	const listenToPopstate = () => {
		const winHref = window.location.href;
		setHref(winHref);
	};
	useEffect(() => {
		window.addEventListener('popstate', listenToPopstate);
		return () => {
			window.removeEventListener('popstate', listenToPopstate);
		};
	}, []);
	return href;
};

export const useReactPath = () => {
	const [path, setPath] = useState(window.location.pathname);
	const listenToPopstate = () => {
		const winPath = window.location.pathname;
		setPath(winPath);
	};
	useEffect(() => {
		window.addEventListener('popstate', listenToPopstate);
		return () => {
			window.removeEventListener('popstate', listenToPopstate);
		};
	}, []);
	return path;
};

export const useReactPathSearch = () => {
	const [path, setPath] = useState(
		window.location.pathname + window.location.search
	);
	const listenToPopstate = () => {
		const winPath = window.location.pathname + window.location.search;
		setPath(winPath);
	};
	useEffect(() => {
		window.addEventListener('popstate', listenToPopstate);
		return () => {
			window.removeEventListener('popstate', listenToPopstate);
		};
	}, []);
	return path;
};
