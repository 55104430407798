import React, { useEffect, useState } from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonButton,
	IonText,
	IonSelect,
	IonSelectOption,
	IonIcon,
	useIonViewWillEnter,
	useIonViewWillLeave
} from '@ionic/react';

import { UserListItem } from '../../components/User/UserListItem';
import {
	UserEntity,
	UserEntities,
	EventEntity,
	ServiceEntity,
	ContextEntity
} from '../../../store';
import { SearchBar } from '../../../../app/ui/components/Search/SearchBar';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useCtx } from '../../../../config/hooks';
import { useInterval } from '../../../../app/store';

export interface UserListConfigProps {
	context?: ContextEntity;
	service: ServiceEntity;
	title?: string;
	finish?: any;
}

export const UserList: React.FC<UserListConfigProps> = props => {
	const [searchText, setSearchText] = useState('');
	const [grouping, setGrouping] = useState('Status');
	const [pageSize, setPageSize] = useState(15);
	const [pageSelected, setPageSelected] = useState(1);
	const [active, setActive] = useState(true);
	const [currentServiceId, setCurrentServiceId] = useState('');
	const [modifiedFrom, setModifiedFrom] = useState('');
	const ctx = useCtx<UserListConfigProps>({});
	const {
		config: [config],
		lead: {
			activeService,
			activeContext,
			userHelper,
			leadHelper,
			licenseHelper
		}
	} = ctx;

	useEffect(() => {
		if (!activeService || activeService.id !== currentServiceId)
			setModifiedFrom('');

		setCurrentServiceId(activeService?.id || '');
	}, [activeService]);

	const read = (next: any) => {
		let modFrom = modifiedFrom !== '' ? modifiedFrom : undefined;
		userHelper.read(
			ctx,
			{
				modifiedFrom: modFrom
			},
			next
		);
	};

	useInterval(
		(next: any, active: boolean) => {
			if (active) {
				read(next);
				return;
			}
			next();
		},
		60000,
		active
	);

	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	// Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	let users: UserEntities =
		!searchText || searchText === ''
			? userHelper.allByService(props.service).filter(user => {
					if (
						user?.email &&
						user?.email?.toLowerCase().trim().indexOf('@conexsys.com') > -1
					) {
						return false;
					}
					return true;
			  })
			: userHelper
					.allByService(props.service)
					.filter(user => {
						let match = 0,
							matches = 0,
							keywords = searchText.toLowerCase().split(' ');
						for (let keyword of keywords) {
							if (keyword === '') break;
							match++;
							if (
								(user?.firstName &&
									user?.firstName?.toLowerCase().trim().indexOf(keyword) >
										-1) ||
								(user?.lastName &&
									user?.lastName?.toLowerCase().trim().indexOf(keyword) > -1) ||
								(user?.email &&
									user?.email?.toLowerCase().trim().indexOf(keyword) > -1)
							) {
								matches++;
							}
						}
						return match === matches;
					})
					.filter(user => {
						if (
							user?.email &&
							user?.email?.toLowerCase().trim().indexOf('@conexsys.com') > -1
						) {
							return false;
						}
						return true;
					});

	const onGroupChanged = (e: any) => {
		setGrouping(e.detail?.value);
	};

	let totalUsers = users.length;
	const onPageChange = (pageMovement: number) => {
		let newPage = pageSelected.valueOf() + pageMovement;
		let maxPage = Math.ceil(totalUsers / pageSize);
		setPageSelected(newPage < 1 ? 1 : newPage > maxPage ? maxPage : newPage);
	};

	const onPageSizeChanged = (e: any) => {
		setPageSize(e.detail?.value);
		setPageSelected(1);
	};

	const sortUsers = (a: UserEntity, b: UserEntity) => {
		return a.i_.status > b.i_.status ? 1 : b.i_.status > a.i_.status ? -1 : 0;
		/*return (a.i_.created?.dt as Date) > (b.i_.created?.dt as Date)
			? 1
			: (b.i_.created?.dt as Date) > (a.i_.created?.dt as Date)
			? -1
			: 0;*/
	};
	const userListItems = users
		.sort(sortUsers)
		.slice(pageSize * (pageSelected - 1), pageSize * pageSelected)
		.map((user: UserEntity, index: number, userList: UserEntity[]) => {
			let newSortValue = '';
			let userStatus = user.i_.status;
			if (index > 0) {
				let prevuserStatus = userList[index - 1].i_.status;
				if ((userStatus ?? '') != (prevuserStatus ?? '')) {
					newSortValue = userStatus ?? '';
				}
			} else {
				newSortValue = userStatus ?? '';
			}
			let totalLeads = leadHelper.countByServiceIdAndEventIdsAndUserId(
				props.service?.id,
				props.context?.eventIds ?? [],
				user.id
			);
			/*let contexts = activeContext ? [activeContext] : [];
			let licensesUsed = !activeService
				? 0
				: licenseHelper
						.allByContexts(contexts, [activeService])
						.filter(license => license.userIds?.includes(user.id)).length;*/
			return newSortValue == '' ? (
				<UserListItem
					user={user}
					totalLeads={totalLeads}
					licenses={0}
					onUserSelected={selectedUserId => {
						userHelper.set(selectedUserId);
					}}
				/>
			) : (
				<>
					<IonItemDivider>
						<IonLabel>{newSortValue}</IonLabel>
					</IonItemDivider>
					<UserListItem
						user={user}
						totalLeads={totalLeads}
						licenses={0}
						onUserSelected={selectedUserId => {
							userHelper.set(selectedUserId);
						}}
					/>
				</>
			);
		});

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<SearchBar onChange={onSerach} />
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="3">
					<IonItem>
						<IonText>
							<p>Sort By</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="sortGroupSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={grouping}
							placeholder="Select One"
							onIonChange={onGroupChanged}
						>
							<IonSelectOption value="Status">Status</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
				<IonCol size="5"></IonCol>
				<IonCol size="2">
					<IonItem>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(-1);
							}}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
						<IonLabel class="selectedPageNumber">
							{pageSelected} of {Math.max(Math.ceil(totalUsers / pageSize), 1)}
						</IonLabel>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(1);
							}}
						>
							<IonIcon icon={arrowForward}></IonIcon>
						</IonButton>
					</IonItem>
				</IonCol>
				<IonCol size="2">
					<IonItem>
						<IonText>
							<p>Page Size</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="pageSizeSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={pageSize}
							placeholder="Select One"
							onIonChange={onPageSizeChanged}
						>
							<IonSelectOption value={15}>15</IonSelectOption>
							<IonSelectOption value={25}>25</IonSelectOption>
							<IonSelectOption value={50}>50</IonSelectOption>
							<IonSelectOption value={100}>100</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonList>{userListItems}</IonList>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="8"></IonCol>
				<IonCol size="2">
					<IonItem>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(-1);
							}}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
						<IonLabel class="selectedPageNumber">
							{pageSelected} of {Math.max(Math.ceil(totalUsers / pageSize), 1)}
						</IonLabel>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(1);
							}}
						>
							<IonIcon icon={arrowForward}></IonIcon>
						</IonButton>
					</IonItem>
				</IonCol>
				<IonCol size="2">
					<IonItem>
						<IonText>
							<p>Page Size</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="pageSizeSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={pageSize}
							placeholder="Select One"
							onIonChange={onPageSizeChanged}
						>
							<IonSelectOption value={15}>15</IonSelectOption>
							<IonSelectOption value={25}>25</IonSelectOption>
							<IonSelectOption value={50}>50</IonSelectOption>
							<IonSelectOption value={100}>100</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
