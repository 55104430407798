import React from 'react';
import { IonLabel, IonItem, IonReorder, IonIcon } from '@ionic/react';
import _ from 'lodash';
import { QualifierValue, QualifierValueType } from '../../../store';

import { reorderFourOutline } from 'ionicons/icons';

export interface QualifierValueListItemProps {
	qualifierValue: QualifierValue;
	onSelectQualifierValue: Function;
}

export const QualifierValueListItem: React.FC<QualifierValueListItemProps> = props => {
	const selectQualifierValue = () => {
		if (_.isFunction(props.onSelectQualifierValue))
			props.onSelectQualifierValue(props.qualifierValue);
	};
	return (
		<IonItem onClick={selectQualifierValue} button>
			<IonLabel>
				<h2>
					{props.qualifierValue.position}. {props.qualifierValue.name}
					{props.qualifierValue.type === QualifierValueType.DataEntry
						? ' (Text)'
						: props.qualifierValue.value.toString() !==
						  props.qualifierValue.name
						? ' (' + props.qualifierValue.value.toString() + ')'
						: ''}
				</h2>
			</IonLabel>
			<IonReorder slot="end">
				<IonIcon icon={reorderFourOutline} />
			</IonReorder>
		</IonItem>
	);
};
