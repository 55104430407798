import { EventEntities } from '../../lead/store/collections/Event';

export interface EventConfig {
	entities: EventEntities;
	activeId?: string;
}

export const eventConfig: EventConfig = {
	entities: []
};
