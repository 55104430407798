import React, { useState } from 'react';
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';

import { User, EventEntity, ServiceEntity, LeadEntities } from '../../../store';
import { useCtx } from '../../../../config/hooks';
import { Chart } from '../../../../app/ui/components/Chart';

export interface RepresentativeConfigProps {
	user?: User;
	event?: EventEntity;
	service?: ServiceEntity;
	title?: string;
	finish?: any;
}

export const RepresentativeReport: React.FC<RepresentativeConfigProps> = props => {
	const [active, setActive] = useState(true);
	const {
		config: [config],
		lead: { leadHelper, userHelper }
	} = useCtx<RepresentativeConfigProps>({}); // TO DO: create and set (leadListConfig)

	//let allLeads: LeadEntities = ctx.lead.lead.by;
	let topPerformersData: Object[] = [];
	if (props.service && props.event) {
		let allLeads: LeadEntities = leadHelper.allByServiceIdAndEventIds(
			props.service.id,
			[props.event.id]
		);
		let activeUserIds = allLeads.map(lead => lead.i_.created?.by || '');
		userHelper.gets(activeUserIds).forEach(user => {
			let leadCountByUser =
				allLeads.filter(lead => lead.i_.created?.by === user.id)?.length || 0;
			topPerformersData.push({
				id: user.firstName + ' ' + user.lastName,
				value: leadCountByUser
			});
		});
	}

	return (
		<IonGrid>
			<IonRow>
				<IonCol sizeMd="12" size="12">
					<IonCard>
						<IonCardContent>
							<Chart
								title="Representative"
								data={topPerformersData}
								selectedChartType="bar"
								height="300px"
							/>
						</IonCardContent>
					</IonCard>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
