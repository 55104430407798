import { DeviceEntities } from '../../lead/store/collections/Device';
import { DeviceType } from '../../lead/store/models';

export interface DeviceConfig {
	entities: DeviceEntities;
	activeId?: string;
}

export const deviceConfig: DeviceConfig = {
	entities: []
};
