import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getAuthCollection,
	AuthId,
	AuthIds,
	AuthId_Some,
	AuthEntity,
	AuthEntities,
	AuthEntity_Some,
	AuthEntityPatch_Some,
	AuthCollection,
	AuthCollectionState,
	IAuthActions,
	authActions,
	AuthActionTypes,
	UserEntity,
	OpenidAuthResponse,
	OpenidAuthResponseSuccess,
	OpenidAuthDigest
} from '..';
import auth0 from 'auth0-js';
import { UseCtx } from '../../../config/hooks';

/**
 * Auth helper interface
 *
 * @export
 * @interface IAuthHelper
 */
export interface IAuthHelper extends IEntityHelper {
	auth?: AuthEntity;
	authSettings?: auth0.AuthOptions;

	loginWithRedirect(): void;
	newLoginWithRedirect(): void;
	handleRedirectCallback(ctx: UseCtx<any>, callback: any): void;
	digestAuthResponse(
		data: any | OpenidAuthResponse
	): [string | undefined, OpenidAuthDigest | undefined];
	digestSuccessResponse(
		response: any | OpenidAuthResponseSuccess
	): OpenidAuthDigest;
}

/**
 * Auth helper options interface
 *
 * @export
 * @interface AuthHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface AuthHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const authHelperOpts: AuthHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Auth helper
 *
 * @export
 * @class AuthHelper
 * @extends {EntityHelper<AuthCollection, AuthActionTypes, AuthActions, AuthEntity, AuthEntities, AuthEntity_Some, AuthEntityPatch_Some, AuthId, AuthIds, AuthId_Some, AuthCollectionState, AuthHelperOpts>}
 * @implements {IAuthHelper}
 */
export class AuthHelper
	extends EntityHelper<
		AuthCollection,
		AuthActionTypes,
		IAuthActions,
		AuthEntity,
		AuthEntities,
		AuthEntity_Some,
		AuthEntityPatch_Some,
		AuthId,
		AuthIds,
		AuthId_Some,
		AuthCollectionState,
		AuthHelperOpts
	>
	implements IAuthHelper {
	constructor(
		useAuthId?: AuthId // defaults to active auth entity if not explicityly provided
	) {
		super(
			useSelector(getAuthCollection),
			authActions,
			useDispatch(),
			authHelperOpts
		);
		this.collection = useSelector(getAuthCollection);
		this.dispatch = useDispatch();

		// defaults to active auth entity if not explicityly provided
		this.auth = useAuthId ? this.get(useAuthId) : this.active();
		if (this.auth) {
			let opts: auth0.AuthOptions = {
				domain: this.auth.domain,
				clientID: this.auth.id,
				redirectUri: this.auth.callbackUrl,
				responseType: this.auth.responseType,
				scope: this.auth.scope,
				audience: this.auth.audience
			};
			this.auth0Provider = new auth0.WebAuth(opts);
		}
	}

	auth?: AuthEntity;
	auth0Provider?: auth0.WebAuth;

	async loginWithRedirect() {
		let opts: auth0.AuthorizeOptions = {};
		await this.auth0Provider?.authorize(opts);
	}

	async newLoginWithRedirect() {
		// logout the current session if one exists
		this.logout();

		let opts: auth0.AuthorizeOptions = {
			prompt: 'login'
		};
		await this.auth0Provider?.authorize(opts);
	}

	async logout() {
		if (this.auth) {
			let opts: auth0.LogoutOptions = {
				clientID: this.auth.id,
				returnTo:
					this.auth.logoutUrl ||
					window.location.protocol + '//' + window.location.host,
				federated: false
			};
			this.auth0Provider?.logout(opts);
		}
	}

	async isActiveSession(activeSessionCallback: any) {
		if (this.auth) {
			let opts: auth0.CheckSessionOptions = {
				audience: this.auth.audience,
				scope: this.auth.scope
			};
			this.auth0Provider?.checkSession(opts, activeSessionCallback);
		}
	}

	async handleRedirectCallback(ctx: UseCtx<any>, callback: any) {
		try {
			this.auth0Provider?.parseHash(async (err, authResults) => {
				if (err) throw err;

				if (authResults) {
					let authDigest: OpenidAuthDigest = {
						access_token: <string>authResults.accessToken,
						token_type: 'Bearer',
						expires_in: <number>authResults.expiresIn,
						refresh_token: <string>authResults.refreshToken,
						id_token: <string>authResults.idToken,
						claims: <any>authResults.idTokenPayload
					};

					let user = await ctx.app.user.digestAuth(ctx, authDigest);
					if (!user) throw 'Unable to digest authenticated user';

					callback(user);
				}
			});
		} catch (e) {
			throw e;
		}
	}

	digestAuthResponse(
		data: any | OpenidAuthResponse
	): [string | undefined, OpenidAuthDigest | undefined] {
		if ('error' in data) return [data.error, undefined];
		return [undefined, this.digestSuccessResponse(data)];
	}

	digestSuccessResponse(
		response: any | OpenidAuthResponseSuccess
	): OpenidAuthDigest {
		return {
			access_token: response.access_token,
			token_type: response.token_type,
			expires_in: response.expires_in,
			refresh_token: response.refresh_token,
			id_token: response.id_token,
			claims: response.idTokenPayload
		};
	}
}
