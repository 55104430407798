import { MenuEntities } from '../../app/store/collections/Menu';
import {
	MenuDisplayType,
	MenuDisplaySideType
} from '../../app/store/models/Menu';
import { MenuConfigProps } from '../../app/ui/components/Menu';
import { Localize, translations } from '../locales';
import * as icons from 'ionicons/icons';

export interface MenuConfig {
	instances: MenuConfigInstances;
	entities: MenuEntities;
	activeId?: string;
}

export interface MenuConfigInstancesRequired {
	main: MenuConfigInstanceTag;
}

export interface MenuConfigInstances extends MenuConfigInstancesRequired {
	[instanceId: string]: MenuConfigInstanceTag;
}

export interface MenuConfigInstanceTagRequired {
	default: Localize<MenuConfigProps>;
}

export interface MenuConfigInstanceTag extends MenuConfigInstanceTagRequired {
	[tag: string]: Localize<MenuConfigProps>;
}

const main_default: MenuConfigProps = {
	disabled: false,
	type: MenuDisplayType.Overlay,
	side: MenuDisplaySideType.Start,
	maxEdgeStart: 50,
	swipeGesture: true,
	autoHide: false,
	tags: ['default'],
	icons: { slot: 'start' },
	collapse: true
};
const main_leadService = { ...main_default, tags: ['default', 'lead-service'] };
const main_admin = { ...main_default, tags: ['admin'] };
const main_account = { ...main_default, tags: ['default', 'account'] };
const main_settings = { ...main_default, tags: ['default', 'settings'] };
const main_support = { ...main_default, tags: ['default', 'support'] };

export const menuConfig: MenuConfig = {
	instances: {
		main: {
			default: {
				en: { title: translations.appName.en, ...main_default },
				fr: { title: translations.appName.fr, ...main_default }
			},
			'lead-service': {
				en: { title: translations.myLeads.en, ...main_leadService },
				fr: { title: translations.myLeads.fr, ...main_leadService }
			},
			admin: {
				en: { title: translations.myLeads.en, ...main_admin },
				fr: { title: translations.myLeads.fr, ...main_admin }
			},
			account: {
				en: { title: translations.userAccount.en, ...main_account },
				fr: { title: translations.userAccount.fr, ...main_account }
			},
			settings: {
				en: { title: translations.settings.en, ...main_settings },
				fr: { title: translations.settings.fr, ...main_settings }
			},
			support: {
				en: { title: translations.support.en, ...main_support },
				fr: { title: translations.support.fr, ...main_support }
			}
		}
	},
	entities: [
		{
			id: 'main',
			title: 'Home',
			url: '/main',
			icon: { icon: icons.homeOutline },
			security: ['user'],
			tags: ['default']
		},
		{
			id: 'events',
			title: 'Events',
			url: '/lead-service/events',
			icon: { icon: icons.calendarOutline },
			security: ['read:leadServiceEvents', 'write:leadServiceEvents'],
			tags: ['default']
		},
		/*{
			id: 'accounts',
			title: 'Accounts',
			url: '/lead-service/services',
			icon: { icon: icons.cubeOutline },
			security: ['user', 'read:leadService', 'write:leadService'],
			tags: ['default', 'lead-service']
		},*/
		{
			id: 'leads',
			title: 'Leads',
			url: '/lead-service/leads',
			icon: { icon: icons.peopleOutline },
			security: ['read:leadServiceLeads', 'write:leadServiceLeads'],
			tags: ['default', 'lead-service']
		},
		{
			id: 'qualifiers',
			title: 'Qualifiers',
			url: '/lead-service/qualifiers',
			icon: { icon: icons.clipboardOutline },
			security: ['read:leadServiceQualifiers', 'write:leadServiceQualifiers'],
			tags: ['default', 'lead-service']
		},
		{
			id: 'users',
			title: 'Representatives',
			url: '/lead-service/users',
			icon: { icon: icons.peopleCircleOutline },
			security: ['read:leadServiceUsers', 'write:leadServiceUsers'],
			tags: ['default', 'lead-service']
		},
		{
			id: 'licenses',
			title: 'Licenses',
			url: '/lead-service/licenses',
			icon: { icon: icons.phonePortraitOutline },
			security: ['read:leadServiceDevices', 'write:leadServiceDevices'],
			tags: ['default', 'lead-service']
		},
		/*{
			id: 'orders',
			title: 'Orders',
			url: '/lead-service/orders',
			icon: { icon: icons.briefcaseOutline },
			security: ['read:leadServiceOrders', 'write:leadServiceOrders'],
			tags: ['default', 'lead-service']
		},*/
		{
			id: 'reports',
			title: 'Reports',
			url: '/lead-service/reports',
			icon: { icon: icons.statsChartOutline },
			security: ['read:leadServiceReports', 'write:leadServiceReports'],
			tags: ['default', 'lead-service']
		},
		/*{
			id: 'tickets',
			title: 'tickets',
			url: '/lead-serivce/tickets',
			icon: { icon: icons.helpBuoyOutline },
			security: ['read:leadServiceTickets', 'write:leadServiceTickets'],
			tags: ['support', 'lead-service'],
			
		},*/
		{
			id: 'admin-divider',
			divider: { label: 'Administration' },
			security: ['administrator'],
			tags: ['admin']
		},
		{
			id: 'admin-events',
			title: 'Lead Events',
			url: '/admin/events',
			icon: { icon: icons.calendarOutline },
			security: ['administrator'],
			tags: ['admin']
		},
		{
			id: 'admin-services',
			title: 'Companies',
			url: '/admin/services',
			icon: { icon: icons.cubeOutline },
			security: ['administrator'],
			tags: ['admin']
		},
		/*{
			id: 'admin-lead-orders',
			title: 'Orders',
			url: '/admin/orders',
			icon: { icon: icons.briefcaseOutline },
			security: ['administrator'],
			tags: ['admin']
		}*/ {
			id: 'admin-users',
			title: 'Users',
			url: '/admin/users',
			icon: { icon: icons.people },
			security: ['administrator'],
			tags: ['admin']
		},
		{
			id: 'admin-scanners',
			title: 'Scanners',
			url: '/admin/scanners',
			icon: { icon: icons.barcode },
			security: ['administrator'],
			tags: ['admin']
		}
		/*{
			id: 'account-divider',
			divider: { label: 'account' },
			security: ['user'],
			tags: ['default'],
			
		},
		{
			id: 'account',
			title: 'userProfile',
			url: '/account',
			icon: { icon: icons.personCircleOutline },
			security: ['user'],
			tags: ['default'],
			
		},
		{
			id: 'support',
			title: 'support',
			url: '/support',
			icon: { icon: icons.helpCircleOutline },
			security: ['user'],
			tags: ['default'],
			
		},
		{
			id: 'settings',
			title: 'settings',
			url: '/settings',
			icon: { icon: icons.settingsOutline },
			security: ['user'],
			tags: ['default'],
			
		},
		{
			id: 'account-users',
			component: { id: 'app.UserControl' },
			security: ['user'],
			tags: ['deactive'],
			
		}*/
	]
};
