import { MenuHelper } from '..';
import { MenuEntity } from '../collections';
export interface MenuHook {
	menuHelper: MenuHelper;
	activeMenu: MenuEntity | undefined;
}

export const useMenu = (): MenuHook => {
	let menuHelper = new MenuHelper();
	return {
		menuHelper,
		activeMenu: menuHelper.active()
	};
};
