import React from 'react';

//UI
import {
	IonCard,
	IonCardContent,
	IonButtons,
	IonButton,
	IonIcon,
	IonCardHeader,
	IonToolbar,
	IonTitle,
	IonCardTitle,
	IonCardSubtitle
} from '@ionic/react';
import * as icons from 'ionicons/icons';
import moment from 'moment';

import {
	LeadEntity,
	EventContactEntity,
	UserEntity,
	isContactIndividualEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

export interface LeadGridCardProps {
	lead: LeadEntity;
}

export const LeadGridCard: React.FC<LeadGridCardProps> = props => {
	const ctx = useCtx<LeadGridCardProps>({}); // TO DO: create and set (qualifierListConfig)
	const {
		config: [config]
	} = ctx;

	const contact: EventContactEntity | undefined = ctx.lead.eventContact.get(
		props.lead.contactId
	);
	const user: UserEntity | undefined = ctx.lead.user.get(
		props.lead.i_.created?.by ?? ''
	);

	const selectLead = () => {
		ctx.lead.lead.set(props.lead.id);
	};

	return (
		<IonCard
			style={{ height: '100%' }}
			routerLink="/lead-service/lead"
			onClick={selectLead}
		>
			<IonCardHeader>
				<IonToolbar>
					<IonCardTitle slot="start">
						{ctx.lead.eventContact.getName(contact)}
					</IonCardTitle>
					<IonButtons slot="end">
						{contact?.phone && (
							<IonButton href={`tel:${contact?.phone}`}>
								<IonIcon slot="icon-only" icon={icons.phonePortraitOutline} />
							</IonButton>
						)}
						{contact?.email && (
							<IonButton href={`mailto:${contact?.email}`}>
								<IonIcon slot="icon-only" icon={icons.mailOutline} />
							</IonButton>
						)}
						<IonButton onClick={selectLead}>
							<IonIcon slot="icon-only" icon={icons.informationCircleOutline} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonCardHeader>
			<IonCardContent>
				{contact?.email && <p>Email: {contact.email}</p>}
				{contact?.phone && <p>Phone: {contact.phone}</p>}
				{contact && isContactIndividualEntity(contact) && (
					<p>Company: {contact?.organization}</p>
				)}
				{user && (
					<p>
						Scanned By: {user?.name} on{' '}
						{moment(props.lead.i_.created?.dt).format('YYYY-MM-DD hh:mm')}
					</p>
				)}
			</IonCardContent>
		</IonCard>
	);
};
