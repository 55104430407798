import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { eventAnswerConfig } from '../../../config/lead/EventAnswer';
import { EventAnswer } from '../models';

/**
 * EventAnswer entity interface
 *
 * @export
 * @interface EventAnswerEntity
 * @extends {storage.Entity}
 */
export interface EventAnswerEntity extends storage.Entity, EventAnswer {}

/**
 * EventAnswer entities array
 *
 * @export
 */
export type EventAnswerEntities = EventAnswerEntity[];

/**
 * Some eventanswer entities: one entity or an array of entities
 *
 * @export
 */
export type EventAnswerEntity_Some = EventAnswerEntity | EventAnswerEntities;

/**
 * EventAnswer entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface EventAnswerEntityPatch
 * @extends {storage.EntityPatch<EventAnswerEntity>}
 */
export type EventAnswerEntityPatch = storage.EntityPatcher<EventAnswerEntity>;

/**
 * EventAnswer entity patches array
 *
 * @export
 */
export type EventAnswerEntitiesPatch = EventAnswerEntityPatch[];

/**
 * Some eventanswer entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type EventAnswerEntityPatch_Some =
	| EventAnswerEntityPatch
	| EventAnswerEntitiesPatch;

/**
 * EventAnswer entity id
 *
 * @export
 */
export type EventAnswerId = storage.EntityId;

/**
 * EventAnswer entity ids
 *
 * @export
 */
export type EventAnswerIds = storage.EntityIds;

/**
 * Some eventanswer entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type EventAnswerId_Some = EventAnswerId | EventAnswerIds;

/**
 * EventAnswer entities object by id
 *
 * @export
 * @interface EventAnswerEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface EventAnswerEntitiesObject extends storage.EntitiesObject {
	[id: string]: EventAnswerEntity;
}

/**
 * EventAnswer entity state
 *
 * @export
 * @interface EventAnswerEntityState
 * @extends {storage.EntityState}
 */
export interface EventAnswerEntityState extends storage.EntityState {}

/**
 * EventAnswer store collection
 *
 * @export
 * @interface EventAnswerStoreCollection
 * @extends {storage.Store}
 */
export interface EventAnswerStoreCollection extends storage.Store {
	eventAnswer: EventAnswerCollection;
}

/**
 * EventAnswer collection properties
 *
 * @export
 * @interface EventAnswerCollection
 * @extends {Collection}
 */
export interface EventAnswerCollection extends storage.Collection {
	byIds: EventAnswerEntitiesObject;
	mutation: EventAnswerEntitiesObject;
	cache: EventAnswerEntitiesObject;
	state: EventAnswerCollectionState;
}

/**
 * EventAnswer collection state
 *
 * @export
 * @interface EventAnswerCollectionState
 * @extends {storage.CollectionState}
 */
export interface EventAnswerCollectionState extends storage.CollectionState {}

// custom eventanswer collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const eventAnswerCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const eventAnswerCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const eventAnswerCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const eventAnswerCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: eventAnswerCollectionIdStringProps,
	idArrayProps: eventAnswerCollectionIdArrayProps,
	idEntityProps: eventAnswerCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * EventAnswer collection default values, initialize collection with eventanswer entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const eventAnswerCollectionDefault: EventAnswerCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as EventAnswerCollection),
		...{
			activeId: eventAnswerConfig.activeId || eventAnswerConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	eventAnswerConfig.entities
);

/**
 * EventAnswer store collection default values
 *
 * @export
 * @constant
 */
export const eventAnswerStoreCollectionDefault: EventAnswerStoreCollection = {
	eventAnswer: eventAnswerCollectionDefault
};

/**
 * EventAnswer store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getEventAnswerCollection = (state: any) => state.lead.eventAnswer;
