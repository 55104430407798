import React, { useState } from 'react';

//DATA
import { LicenseEntity, UserEntities, ObjectStatus } from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonText,
	IonAlert
} from '@ionic/react';

//LIB
import _ from 'lodash';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from '../../../../app/ui/components';

export interface LicenseCardProps {
	license: LicenseEntity;
}

export const LicenseCard: React.FC<LicenseCardProps> = props => {
	const ctx = useCtx<LicenseCardProps>({}); // TO DO: create and set (leadListConfig)
	const {
		lead: { licenseHelper },
		app: { activeUser },
		config: [config]
	} = ctx;

	const [showDeleteLicenseAlert, setShowDeleteLicenseAlert] = useState(false);
	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	const selectLicense = () => {
		licenseHelper.set(props.license.id);
	};
	const deactivateLicense = () => {
		props.license.i_.status = ObjectStatus.Deactive;
		licenseHelper.write(ctx, { licenses: [props.license] }, () => {});
	};
	const activeReps: UserEntities = [];
	props.license.userIds?.forEach(userId => {
		let user = ctx.lead.user.get(userId);
		if (user) {
			activeReps.push(user);
		}
	});

	const activeRepsCount = activeReps.length || 0;
	const activeDevicesCount = props.license.deviceIds?.length || 0;

	return (
		<>
			<IonAlert
				isOpen={showDeleteLicenseAlert}
				onDidDismiss={() => {
					setShowDeleteLicenseAlert(false);
				}}
				header={'Deactivate License?'}
				message={
					'Deactivating a license will <strong>remove</strong> access for this service.'
				}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'light',
						handler: () => {
							setShowDeleteLicenseAlert(false);
						}
					},
					{
						text: 'Deactivate',
						cssClass: 'light',
						handler: () => {
							deactivateLicense();
							setShowDeleteLicenseAlert(false);
						}
					}
				]}
			></IonAlert>

			{props.license.serviceType === 'lead-app' ? (
				<IonGrid key={props.license.id}>
					<IonRow>
						<IonCol>
							<IonLabel>
								<h1>
									<b>
										{props.license.serviceName ||
											props.license.name ||
											'Lead App'}
									</b>
								</h1>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" sizeMd="5">
							<QRCode value={props.license.code} size={100}></QRCode>
							<IonLabel class="ion-text-wrap">
								<b>Activation Code:</b>
							</IonLabel>
							<CopyToClipboard text={props.license.code}>
								{props.license.code}
							</CopyToClipboard>
						</IonCol>
						<IonCol size="12" sizeMd="4">
							<IonLabel>
								<h2>
									<b>
										Usage: {props.license.uses || 0} of {props.license.quantity}
									</b>
								</h2>
								<h2>Reps with Activity: {activeRepsCount}</h2>
								<h2>Active Devices: {activeDevicesCount}</h2>
							</IonLabel>
						</IonCol>
						<IonCol size="12" sizeMd="3">
							<IonButton
								size="small"
								routerLink="/lead-service/license"
								onClick={selectLicense}
							>
								View Details
							</IonButton>
							<IonButton
								hidden={
									!activeUser?.security.includes('administrator') ||
									activeDevicesCount > 0
								}
								size="small"
								color="danger"
								onClick={() => {
									setShowDeleteLicenseAlert(true);
								}}
							>
								Deactivate
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			) : props.license.serviceType === 'lead-api' ? (
				<IonGrid key={props.license.id}>
					<IonRow>
						<IonCol>
							<IonLabel>
								<h1>
									<b>
										{props.license.serviceName ||
											props.license.name ||
											'Lead API'}
									</b>
								</h1>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" sizeMd="8">
							<IonText class="ion-text-wrap">
								<h1>API Access</h1>
								<h2>
									<b>License Id:</b>
									<br />
									<CopyToClipboard text={props.license.id}>
										{props.license.id}
									</CopyToClipboard>
								</h2>
								<h2>
									<b>License Key:</b>
									<br />
									<CopyToClipboard text={props.license.code}>
										{props.license.code}
									</CopyToClipboard>
								</h2>
								<br />
								<h1>API Documentation:</h1>
								<h2>
									<a
										target="_blank"
										href="https://api.conexsys.com/lead/v2/docs/"
									>
										https://api.conexsys.com/lead/v2/docs/
									</a>
								</h2>
							</IonText>
						</IonCol>
						<IonCol size="12" sizeMd="4">
							<IonButton
								hidden={!activeUser?.security.includes('administrator')}
								class="ion-float-right"
								size="small"
								color="danger"
								onClick={() => {
									setShowDeleteLicenseAlert(true);
								}}
							>
								Deactivate
							</IonButton>
							<IonButton
								hidden={true}
								class="ion-float-right"
								size="small"
								routerLink="/lead-service/license"
								onClick={selectLicense}
							>
								View Details
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			) : (
				<IonGrid key={props.license.id}>
					<IonRow>
						<IonCol>
							<IonLabel>
								<h1>
									<b>
										{props.license.serviceName ||
											props.license.name ||
											'Scanner'}
									</b>
								</h1>
							</IonLabel>
						</IonCol>
					</IonRow>{' '}
					<IonRow>
						<IonCol size="12" sizeMd="4">
							<h1>{props.license.quantity}</h1>
						</IonCol>
					</IonRow>
					<IonCol size="12" sizeMd="4">
						<IonButton
							hidden={!activeUser?.security.includes('administrator')}
							class="ion-float-right"
							size="small"
							color="danger"
							onClick={() => {
								setShowDeleteLicenseAlert(true);
							}}
						>
							Deactivate
						</IonButton>
					</IonCol>
				</IonGrid>
			)}
		</>
	);
};
