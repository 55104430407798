import React, { useEffect, useState } from 'react';

//Data
import { useCtx } from '../../../config/hooks';
import { useInterval } from '../../../app/store';
import { EventEntities, ContextEntities } from '../../store';

//UI
import { EventList } from './../containers/Event';
import {
	IonGrid,
	IonRow,
	IonCol,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonIcon,
	IonLabel,
	IonCard,
	IonCardContent,
	IonContent
} from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import { SearchBar } from '../../../app/ui/components/Search/SearchBar';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { ServiceNotFoundCard } from './../components/Service/ServiceNotFoundCard';

const EventsView: React.FC = () => {
	const [searchText, setSearchText] = useState('');
	const [active, setActive] = useState(true);
	const [currentServiceId, setCurrentServiceId] = useState('');
	const [modifiedFrom, setModifiedFrom] = useState('');

	const ctx = useCtx<{}>(); // TO DO: create and set (userListConfig)
	const {
		config: [config],
		lead: { activeService, contextHelper }
	} = ctx;

	useEffect(() => {
		if (!activeService || activeService.id !== currentServiceId)
			setModifiedFrom('');

		setCurrentServiceId(activeService?.id || '');
	}, [activeService]);

	const read = (next: any) => {
		if (!activeService) return;
		let modFrom = modifiedFrom !== '' ? modifiedFrom : undefined;
		ctx.lead.event.read(
			ctx,
			{
				modifiedFrom: modFrom
			},
			next
		);
	};

	useInterval(
		(next: any, active: boolean) => {
			if (active) {
				read(next);
				return;
			}
			next();
		},
		60000,
		active
	);

	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	if (!activeService) return <ServiceNotFoundCard />;

	// Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;

	const onSerach = (value: string | undefined) => {
		if (value) {
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	const allContexts: ContextEntities = contextHelper.gets(
		activeService?.contextIds || []
	);
	const filteredContexts: ContextEntities = allContexts.filter(event => {
		let match = 0,
			matches = 0,
			keywords = searchText ?? '';
		for (let keyword of keywords.toLowerCase().split(' ')) {
			if (keyword === '') break;
			match++;
			if (
				(event?.name &&
					event?.name.toLowerCase().trim().indexOf(keyword) > -1) ||
				(event?.code && event?.code?.toLowerCase().trim().indexOf(keyword) > -1)
			) {
				matches++;
			}
		}
		return match === matches;
	});

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol size="12">
						<PageTitle
							title="Events"
							subTitle="The below list represents your event lead retrieval events. Click on the name or view buttons to view your leads."
						/>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12">
						<IonCard>
							<IonCardContent>
								<SearchBar value={searchText} onChange={onSerach} />

								{filteredContexts && filteredContexts.length <= 0 ? (
									<IonLabel class="ion-text-center">
										<br />
										<h1>
											<IonIcon icon={calendarOutline} size="large" />
										</h1>
										<h2>No events found.</h2>
										<p>
											Your account is not associated to an event. Please enter a
											license key or perchase a license key to unlock access to
											an event.
										</p>
									</IonLabel>
								) : (
									<EventList
										contexts={filteredContexts}
										service={activeService}
									/>
								)}
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default EventsView;
