import { useUser } from './User';
import { userConfig } from '../../../config/app/User';

//import { Locales } from '../../config/locales';

export const useLocale = () => {
	let userState = useUser();
	const locale =
		userState.activeUser?.locale || userConfig.settings.default.locale || '';

	return { ...userState, locale, getLocaleConfigs };
};

export const useLocaleConfig = <ConfigTypes>(...configs: any[]) => {
	let state = useLocale();

	return {
		...state,
		config: getLocaleConfigs(state.locale, ...configs) as ConfigTypes[]
	};
};

export const getLocaleConfigs = <ConfigTypes>(
	locale: string,
	...configs: any[]
): ConfigTypes[] => {
	let config: ConfigTypes[] = [];

	for (let configArg of configs) {
		if (configArg) {
			// get config for locale // else for first locale
			let cfg: ConfigTypes =
				configArg[locale ? locale : ''] ||
				(configArg[Object.keys(configArg)[0]] as ConfigTypes);
			if (cfg) config.push(cfg);
		}
	}

	return config as ConfigTypes[];
};
