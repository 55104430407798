import React, { useState, useEffect } from 'react';

//DATA
import { DeviceEntities, UserEntities } from '../../../lead/store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonLabel,
	IonList,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonIcon,
	IonButton,
	IonAlert,
	IonContent
} from '@ionic/react';
import QRCode from 'qrcode.react';
import {
	cloudDoneOutline,
	pencilOutline,
	removeCircleOutline
} from 'ionicons/icons';
import { CopyToClipboard } from '../../../app/ui/components';
import { useToast } from '@agney/ir-toast';

const LicenseView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			activeService,
			activeEvent,
			activeLicense,
			licenseHelper,
			userHelper,
			deviceHelper
		}
	} = ctx;
	const [showConfirmationAlert, setShowConfirmationAlert] = useState('');
	const [showModifyUsageAlert, setShowModifyUsageAlert] = useState('');

	const Toast = useToast();

	//Load Data
	useEffect(() => {
		//Load data that may need updated
		if (activeService) {
			ctx.lead.device.readAll(ctx, { ids: activeService?.deviceIds }, () => {});
		}
		if (activeLicense) {
			ctx.lead.licenseHelper.read(ctx, { ids: [activeLicense.id] });
		}
	}, []);

	if (!activeService) return <>No Service selected</>;
	if (!activeEvent) return <>No Event selected</>;
	if (!activeLicense) return <>No License selected</>;

	const activeReps: UserEntities = [];
	activeLicense.userIds?.forEach(userId => {
		let user = userHelper.get(userId);
		if (
			user &&
			user.email &&
			user.email.toLowerCase().trim().indexOf('@conexsys.com') <= -1
		) {
			activeReps.push(user);
		}
	});

	const activeDevices: DeviceEntities = [];
	activeLicense.deviceIds?.forEach(deviceId => {
		let device = deviceHelper.get(deviceId);
		if (device) {
			activeDevices.push(device);
		}
	});

	const activeRepsItems =
		activeReps.length <= 0 ? (
			<>No active representatives found</>
		) : (
			activeReps.map(rep => {
				return (
					<IonItem key={rep.id}>
						<IonLabel>{rep.name}</IonLabel>
						<IonLabel>{rep.email}</IonLabel>
					</IonItem>
				);
			})
		);

	const activeDevicesItems =
		activeDevices.length <= 0 ? (
			<>No active device found</>
		) : (
			activeDevices.map(dev => {
				return (
					<IonItem key={dev.id}>
						<IonLabel>
							{dev.name} ({dev.deviceUniqueId})
						</IonLabel>
						<IonLabel>
							{dev.model} - {dev.system} ({dev.systemVersion})
						</IonLabel>
						<IonLabel hidden={!activeUser?.security.includes('administrator')}>
							<IonButton
								color="danger"
								size="small"
								onClick={() => {
									setShowConfirmationAlert(dev.id);
								}}
							>
								<IonIcon src={removeCircleOutline}></IonIcon> Deactivate
							</IonButton>
						</IonLabel>
					</IonItem>
				);
			})
		);

	const deactivateDevice = (deviceId: string) => {
		if (deviceId) {
			let idx = activeLicense.deviceIds?.indexOf(deviceId);
			if (activeLicense.deviceIds && idx !== undefined && idx >= 0) {
				activeLicense.deviceIds.splice(idx, 1);

				if (activeLicense.deviceIds.length <= 0) {
					activeLicense.deviceIds = null;
				}

				licenseHelper.write(ctx, {
					userId: activeUser?.userId,
					licenses: [activeLicense]
				});

				Toast.success('Device deactivate successfully!');
			}
		}
	};

	const updateActiveLicenseQuantity = (quantity: number) => {
		if (activeLicense && quantity) {
			activeLicense.quantity = quantity;
			licenseHelper.write(ctx, {
				userId: activeUser?.userId,
				licenses: [activeLicense]
			});
			Toast.success('License quantity changed successfully!');
		}
	};

	return (
		<IonContent>
			<IonAlert
				isOpen={showConfirmationAlert !== ''}
				onDidDismiss={() => {
					setShowConfirmationAlert('');
				}}
				header={'Remove Device Access?'}
				message={
					'Deactivating device will <strong>remove</strong> access for this device and this license!!!'
				}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'light',
						handler: () => {
							console.log('Confirm Cancel:');
						}
					},
					{
						text: 'Deactivate',
						cssClass: 'light',
						handler: () => {
							deactivateDevice(showConfirmationAlert);
						}
					}
				]}
			></IonAlert>
			<IonAlert
				isOpen={showModifyUsageAlert !== ''}
				onDidDismiss={() => {
					setShowModifyUsageAlert('');
				}}
				header={'Change License Usage :'}
				inputs={[
					{
						name: 'quantity',
						type: 'number',
						min: activeLicense.uses,
						max: 100,
						value: activeLicense.quantity
					}
				]}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'light'
					},
					{
						text: 'Apply',
						cssClass: 'light',
						handler: v => {
							updateActiveLicenseQuantity(
								!isNaN(parseInt(v.quantity)) ? parseInt(v.quantity) : 0
							);
						}
					}
				]}
			></IonAlert>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/lead-service/licenses" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol size="12" sizeSm="7">
						<IonCard>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol size="12" sizeLg="3">
											<QRCode value={activeLicense.code} size={100}></QRCode>
										</IonCol>
										<IonCol size="11" sizeLg="8">
											<IonLabel>
												<h2>
													<b>
														Usage: {activeLicense.uses || 0} of{' '}
														{activeLicense.quantity}
													</b>
													<br />
													<br />
													<b>Activation Code:</b>
													<br />
													<CopyToClipboard text={activeLicense.code}>
														{activeLicense.code}
													</CopyToClipboard>
												</h2>
											</IonLabel>
										</IonCol>
										<IonCol
											size="1"
											sizeLg="1"
											hidden={!activeUser?.security.includes('administrator')}
										>
											<IonButton
												size="small"
												color="light"
												onClick={() => setShowModifyUsageAlert('something')}
											>
												<IonIcon src={pencilOutline}></IonIcon>
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Active Devices</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>{activeDevicesItems}</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="12" sizeSm="5">
						<IonCard>
							<IonCardContent>
								<div
									style={{ textAlign: 'center' }}
									hidden={activeLicense.serviceType !== 'lead-app'}
								>
									<img
										style={{
											display: 'inline-block',
											textAlign: 'center'
										}}
										width="50%"
										src={'assets/img/logo/myleads_logo_icon_color.png'}
									/>
									<div
										style={{
											display: 'inline-block',
											textAlign: 'center'
										}}
									>
										<a
											target="_blank"
											href="https://play.google.com/store/apps/details?id=com.conexsys.myleadsmobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
										>
											<img
												width="150px"
												alt="Get it on Google Play"
												src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
											/>
										</a>
										<a
											target="_blank"
											href="https://apps.apple.com/us/app/myleads-mobile-by-conexsys/id1510952695?itsct=apps_box_badge&amp;itscg=30200"
										>
											<img
												src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1591142400&h=86cf9ecfd43a5573a4b91cc56cbcc590"
												alt="Download on the App Store"
											/>
										</a>
									</div>
								</div>
								<div
									style={{ textAlign: 'center' }}
									hidden={activeLicense.serviceType !== 'lead-api'}
								>
									<IonIcon src={cloudDoneOutline} style={{ zoom: 9.0 }} />
								</div>
								<div
									style={{ textAlign: 'center' }}
									hidden={activeLicense.serviceType !== 'sst'}
								>
									<img
										style={{
											display: 'inline-block',
											textAlign: 'center'
										}}
										width="50%"
										src={'assets/img/bc500dc.jpg'}
									/>
								</div>
								<div
									style={{ textAlign: 'center' }}
									hidden={activeLicense.serviceType !== 'opticon'}
								>
									<img
										style={{
											display: 'inline-block',
											textAlign: 'center'
										}}
										width="50%"
										src={'assets/img/Opticon_9725.jpg'}
									/>
								</div>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Representatives with activity</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>{activeRepsItems}</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default LicenseView;
