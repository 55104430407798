import React, { useState } from 'react';

//Data
import { QualifierEntity } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonGrid,
	IonRow,
	IonCol,
	IonFab,
	IonFabButton,
	IonIcon,
	IonToolbar,
	IonButtons,
	IonContent,
	IonButton
} from '@ionic/react';
import { QualifierList, AddEditQualifier } from './../containers/Qualifier';
import { add, arrowBack } from 'ionicons/icons';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { ServiceNotFoundCard } from './../components/Service/ServiceNotFoundCard';

const QualifiersView: React.FC = () => {
	const {
		config: [config],
		lead: { activeService, activeEvent }
	} = useCtx<{}>({});

	const [editMode, setEditMode] = useState(false);
	const [editQualifier, setEditQualifier] = useState<
		QualifierEntity | undefined
	>(undefined);

	if (!activeService) return <ServiceNotFoundCard />;

	const startEdit = (qualifier: QualifierEntity) => {
		setEditMode(true);
		setEditQualifier(qualifier);
	};

	const startAdd = () => {
		setEditMode(true);
		setEditQualifier(undefined);
	};

	const finishEdit = () => {
		setEditMode(false);
		setEditQualifier(undefined);
	};

	return (
		<IonContent>
			<IonToolbar hidden={!editMode}>
				<IonButtons slot="start">
					<IonButton fill="clear" size="default" onClick={finishEdit}>
						<IonIcon icon={arrowBack} />
						Back
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow hidden={editMode === true}>
					<IonCol>
						<PageTitle
							title="Qualifiers"
							subTitle="Qualifiers allow you to do customize and apply extra information
						to a lead."
							displayEventSelector={true}
						/>
						{activeEvent && (
							<IonCard>
								<IonCardHeader>
									<IonFab vertical="top" horizontal="end">
										<IonFabButton size="small" onClick={() => startAdd()}>
											<IonIcon icon={add} />
										</IonFabButton>
									</IonFab>
								</IonCardHeader>
								<IonCardContent>
									<QualifierList
										service={activeService}
										event={activeEvent}
										edit={startEdit}
									/>
								</IonCardContent>
							</IonCard>
						)}
					</IonCol>
				</IonRow>

				{editMode && (
					<>
						{activeEvent && (
							<IonRow>
								<IonCol size="12">
									<AddEditQualifier
										service={activeService}
										event={activeEvent}
										qualifier={editQualifier}
										finish={finishEdit}
									/>
								</IonCol>
							</IonRow>
						)}
					</>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default QualifiersView;
