import {
	EventAnswerId_Some,
	EventAnswerEntity_Some,
	EventAnswerEntityPatch_Some,
	EventAnswerCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const EVENTANSWER_MUTATE_ID = '@lead/EVENTANSWER_MUTATE_ID';
export const EVENTANSWER_SET = '@lead/EVENTANSWER_SET';
export const EVENTANSWER_TOGGLE = '@lead/EVENTANSWER_TOGGLE';
export const EVENTANSWER_SELECT = '@lead/EVENTANSWER_SELECT';
export const EVENTANSWER_DESELECT = '@lead/EVENTANSWER_DESELECT';
export const EVENTANSWER_UPSERT = '@lead/EVENTANSWER_UPSERT';
export const EVENTANSWER_PATCH = '@lead/EVENTANSWER_PATCH';
export const EVENTANSWER_CHANGE = '@lead/EVENTANSWER_CHANGE';
export const EVENTANSWER_APPLY_CHANGES = '@lead/EVENTANSWER_APPLY_CHANGES';
export const EVENTANSWER_CANCEL_CHANGES = '@lead/EVENTANSWER_CANCEL_CHANGES';
export const EVENTANSWER_CACHE = '@lead/EVENTANSWER_CACHE';
export const EVENTANSWER_UNCACHE = '@lead/EVENTANSWER_UNCACHE';
export const EVENTANSWER_SETSTATE = '@lead/EVENTANSWER_SETSTATE';
export const EVENTANSWER_PATCHSTATE = '@lead/EVENTANSWER_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface EventAnswerAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface EventAnswerAction_MutateId extends EntityAction_MutateId {
	type: typeof EVENTANSWER_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active eventanswer
 *
 * @interface EventAnswerAction_Set
 * @extends {EntityAction_Set}
 */
export interface EventAnswerAction_Set extends EntityAction_Set {
	type: typeof EVENTANSWER_SET;
	payload?: EntityId;
}

/**
 * Toggle active eventanswer
 *
 * @interface EventAnswerAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface EventAnswerAction_Toggle extends EntityAction_Toggle {
	type: typeof EVENTANSWER_TOGGLE;
	payload?: EntityId;
}

/**
 * Select eventanswer entities
 *
 * @interface EventAnswerAction_Select
 * @extends {EntityAction_Select}
 */
interface EventAnswerAction_Select extends EntityAction_Select {
	type: typeof EVENTANSWER_SELECT;
	payload: EventAnswerId_Some;
}

/**
 * Deselect eventanswer entities
 *
 * @interface EventAnswerAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface EventAnswerAction_Deselect extends EntityAction_Deselect {
	type: typeof EVENTANSWER_DESELECT;
	payload: EventAnswerId_Some;
}

/**
 * Upsert eventanswer entities
 *
 * @interface EventAnswerAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface EventAnswerAction_Upsert extends EntityAction_Upsert {
	type: typeof EVENTANSWER_UPSERT;
	payload: EventAnswerEntity_Some;
}

/**
 * Patch eventanswer entities
 *
 * @interface EventAnswerAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface EventAnswerAction_Patch extends EntityAction_Patch {
	type: typeof EVENTANSWER_PATCH;
	payload: EventAnswerEntityPatch_Some;
}

/**
 * Change eventanswer entities
 *
 * @interface EventAnswerAction_Change
 * @extends {EntityAction_Change}
 */
interface EventAnswerAction_Change extends EntityAction_Change {
	type: typeof EVENTANSWER_CHANGE;
	payload: EventAnswerEntityPatch_Some;
}

/**
 * Apply changes to eventanswer entities
 *
 * @interface EventAnswerAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface EventAnswerAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof EVENTANSWER_APPLY_CHANGES;
	payload: EventAnswerId_Some;
}

/**
 * Cancel changes to eventanswer entities
 *
 * @interface EventAnswerAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface EventAnswerAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof EVENTANSWER_CANCEL_CHANGES;
	payload: EventAnswerId_Some;
}

/**
 * Cache eventanswer entities
 *
 * @interface EventAnswerAction_Cache
 * @extends {EntityAction_Cache}
 */
interface EventAnswerAction_Cache extends EntityAction_Cache {
	type: typeof EVENTANSWER_CACHE;
	payload: EventAnswerId_Some;
}

/**
 * Uncache eventanswer entities
 *
 * @interface EventAnswerAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface EventAnswerAction_Uncache extends EntityAction_Uncache {
	type: typeof EVENTANSWER_UNCACHE;
	payload: EventAnswerId_Some;
}

/**
 * Set eventanswer collection / entity state
 *
 * @interface EventAnswerAction_SetState
 * @extends {EntityAction_SetState}
 */
interface EventAnswerAction_SetState extends EntityAction_SetState {
	type: typeof EVENTANSWER_SETSTATE;
}

/**
 * PAtch eventanswer collection / entity state
 *
 * @interface EventAnswerAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface EventAnswerAction_PatchState extends EntityAction_PatchState {
	type: typeof EVENTANSWER_PATCHSTATE;
}

/**
 * Export eventanswer action types
 *
 * @export
 */
export type EventAnswerActionTypes =
	| EventAnswerAction_MutateId
	| EventAnswerAction_MutateId
	| EventAnswerAction_Set
	| EventAnswerAction_Toggle
	| EventAnswerAction_Select
	| EventAnswerAction_Deselect
	| EventAnswerAction_Upsert
	| EventAnswerAction_Patch
	| EventAnswerAction_Change
	| EventAnswerAction_ApplyChanges
	| EventAnswerAction_CancelChanges
	| EventAnswerAction_Cache
	| EventAnswerAction_Uncache
	| EventAnswerAction_SetState
	| EventAnswerAction_PatchState;

/**
 * EventAnswer actions helper interface
 *
 * @export
 * @interface IEventAnswerActions
 * @extends {EntityActions}
 */
export interface IEventAnswerActions extends EntityActions {
	//customAction(ids: EventAnswerId_Some): EventAnswerAction_CustomAction;
}

/**
 * EventAnswer actions helper
 *
 * @export
 * @class EventAnswerActions
 * @implements {EntityBaseActions}
 */
export class EventAnswerActions implements IEventAnswerActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): EventAnswerAction_MutateId {
		return { type: EVENTANSWER_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): EventAnswerAction_Set {
		return { type: EVENTANSWER_SET, payload: id };
	}
	toggle(id?: EntityId): EventAnswerAction_Toggle {
		return { type: EVENTANSWER_TOGGLE, payload: id };
	}
	select(ids: EventAnswerId_Some): EventAnswerAction_Select {
		return {
			type: EVENTANSWER_SELECT,
			payload: ids
		};
	}
	deselect(ids: EventAnswerId_Some): EventAnswerAction_Deselect {
		return {
			type: EVENTANSWER_DESELECT,
			payload: ids
		};
	}
	upsert(entities: EventAnswerEntity_Some): EventAnswerAction_Upsert {
		return {
			type: EVENTANSWER_UPSERT,
			payload: entities
		};
	}
	patch(entities: EventAnswerEntityPatch_Some): EventAnswerAction_Patch {
		return {
			type: EVENTANSWER_PATCH,
			payload: entities
		};
	}
	change(entities: EventAnswerEntityPatch_Some): EventAnswerAction_Change {
		return {
			type: EVENTANSWER_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: EventAnswerId_Some): EventAnswerAction_ApplyChanges {
		return {
			type: EVENTANSWER_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: EventAnswerId_Some): EventAnswerAction_CancelChanges {
		return {
			type: EVENTANSWER_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: EventAnswerId_Some): EventAnswerAction_Cache {
		return {
			type: EVENTANSWER_CACHE,
			payload: ids
		};
	}
	uncache(ids: EventAnswerId_Some): EventAnswerAction_Uncache {
		return {
			type: EVENTANSWER_UNCACHE,
			payload: ids
		};
	}
	setState(state: EventAnswerCollectionState): EventAnswerAction_SetState {
		return {
			type: EVENTANSWER_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<EventAnswerCollectionState>
	): EventAnswerAction_PatchState {
		return {
			type: EVENTANSWER_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the eventanswer actions helper
 *
 * @export
 */
export const eventAnswerActions = new EventAnswerActions();
