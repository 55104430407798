import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// UI
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonCardSubtitle,
	IonItem,
	IonInput,
	IonLoading,
	IonButtons,
	IonIcon,
	IonToolbar,
	IonList
} from '@ionic/react';
import * as icons from 'ionicons/icons';

// Config
import { useLocaleConfig } from '../../../../app/store';
import { activateOrderCardConfig } from '../../../../config/lead/components/ActivateOrderCard';
import { useCtx } from '../../../../config/hooks';
import { OrderHelperActivateResults } from '../../../store';

// Components
import { useToast } from '@agney/ir-toast';

// Tools
import qs from 'qs';
import _ from 'lodash';

// Style
import '../../styles/card.css';

export interface ActivateOrderCardProps {
	title?: string;
	instructions?: string;
	codePlaceholder?: string;
	activateOrderButtonText?: string;
}

export const ActivateOrderCard: React.FC<ActivateOrderCardProps> = props => {
	// UI State
	const [showInstructions, setShowInstructions] = useState(false);
	const [initialized, setInitialized] = useState(false);

	// Form code
	const [orderCode, setOrderCode] = useState('');
	const [goActivate, setGoActivate] = useState(false);
	const [activating, setActivating] = useState(false);
	const [activationStatus, setActivationStatus] = useState('');

	// import configuration
	const {
		config: [config]
	} = useLocaleConfig<ActivateOrderCardProps>(activateOrderCardConfig);
	const ctx = useCtx<{}>({});

	// Hook ins
	const history = useHistory();
	const Toast = useToast();

	const activateOrderButtonClicked = (e: any) => {
		setGoActivate(true);
		setActivationStatus('Working...');
	};

	useEffect(() => {
		init();
		if (!activating && goActivate) {
			setGoActivate(false);
			activate();
		}
	}, [goActivate]);

	const init = async () => {
		if (!initialized) {
			let qsOrderCode = qs.parse(window.location.search, {
				ignoreQueryPrefix: true
			}).orderCode;
			if (typeof qsOrderCode === 'string') {
				setOrderCode(qsOrderCode);
				if (qsOrderCode !== '') setGoActivate(true);
			}
			setInitialized(true);
		}
	};

	const onError = (message: string) => {
		Toast.error(message);
		setActivating(false);
	};

	const onSuccess = (result: OrderHelperActivateResults) => {
		Toast.success('Order successfully activated!');

		ctx.lead.order.set(result.order.id);
		ctx.lead.context.set(result.context.id);
		ctx.lead.event.set(result.context.eventIds[0]);
		ctx.lead.service.set(result.service.id);

		setActivating(false);
		setOrderCode('');

		history.push('/main');
	};

	const activate = async () => {
		setActivating(true);

		ctx.lead.order.activate(ctx, orderCode, onError, onSuccess);
	};

	return (
		<IonCard className="conexsys-card">
			<IonCardHeader>
				<IonToolbar>
					{config.title && <IonCardTitle>{config.title}</IonCardTitle>}
					{config.instructions && (
						<IonButtons slot="end">
							{showInstructions ? (
								<IonButton
									slot="end"
									onClick={() => setShowInstructions(false)}
								>
									<IonIcon
										slot="icon-only"
										icon={icons.closeCircleOutline}
									></IonIcon>
								</IonButton>
							) : (
								<IonButton slot="end" onClick={() => setShowInstructions(true)}>
									<IonIcon
										slot="icon-only"
										icon={icons.informationCircleOutline}
									></IonIcon>
								</IonButton>
							)}
						</IonButtons>
					)}
				</IonToolbar>
			</IonCardHeader>
			<IonCardContent>
				<p hidden={!showInstructions}>{config.instructions}</p>
				{!activating ? (
					<IonList lines="none">
						<IonItem>
							<IonInput
								placeholder={config.codePlaceholder}
								name="orderCode"
								className="required"
								value={orderCode}
								onIonChange={(e: any) => setOrderCode(e?.target?.value || '')}
							></IonInput>
						</IonItem>
						<IonButton
							color="light"
							expand="full"
							disabled={orderCode === ''}
							onClick={activateOrderButtonClicked}
						>
							{config.activateOrderButtonText}
						</IonButton>
					</IonList>
				) : (
					<IonLoading
						isOpen={true}
						message={`<h2>Activating</h2>${activationStatus}`}
						duration={10000}
					/>
				)}
			</IonCardContent>
		</IonCard>
	);
};
