// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Ticket status
 * @export
 * @enum {string}
 */
export enum TicketStatus {
	New = 'new',
	Open = 'open',
	Pending = 'pending',
	Hold = 'hold',
	Solved = 'solved',
	Closed = 'closed'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesTicketStatus = [
	'new',
	'open',
	'pending',
	'hold',
	'solved',
	'closed'
];
