import { EventQuestionHelper } from '..';
import { EventQuestionEntity } from '../collections';

export interface EventQuestionHook {
	eventQuestionHelper: EventQuestionHelper;
	activeEventQuestion: EventQuestionEntity | undefined;
}

export const useEventQuestion = (): EventQuestionHook => {
	let eventQuestionHelper = new EventQuestionHelper();
	return {
		eventQuestionHelper,
		activeEventQuestion: eventQuestionHelper.active()
	};
};
