import React, { useState } from 'react';

import _ from 'lodash';
import { QualifierEntity } from '../../store';
import { useCtx } from '../../../config/hooks';

import { AddEditQualifier } from '../containers/Qualifier';
import { IonContent } from '@ionic/react';

export interface QualifierProps {
	qualifier: QualifierEntity;
}

export interface QualifierViewProps {
	id?: string;
}

const QualifierView: React.FC<QualifierViewProps> = props => {
	const {
		config: [config],
		lead: { activeService, activeEvent, activeQualifier }
	} = useCtx<{}>({});

	if (!activeService) return <>No Service selected</>;
	if (!activeEvent) return <>No Event selected</>;

	return (
		<IonContent>
			<AddEditQualifier
				service={activeService}
				event={activeEvent}
				qualifier={activeQualifier}
				finish={() => {}}
			/>
		</IonContent>
	);
};

export default QualifierView;
