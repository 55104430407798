import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { qualifierValueConfig } from '../../../config/lead/QualifierValue';
import { QualifierValue } from '../models';

/**
 * QualifierValue entity interface
 *
 * @export
 * @interface QualifierValueEntity
 * @extends {storage.Entity}
 */
export interface QualifierValueEntity extends storage.Entity, QualifierValue {}

/**
 * QualifierValue entities array
 *
 * @export
 */
export type QualifierValueEntities = QualifierValueEntity[];

/**
 * Some  qualifierValue entities: one entity or an array of entities
 *
 * @export
 */
export type QualifierValueEntity_Some =
	| QualifierValueEntity
	| QualifierValueEntities;

/**
 * QualifierValue entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface QualifierValueEntityPatch
 * @extends {storage.EntityPatch<QualifierValueEntity>}
 */
export type QualifierValueEntityPatch = storage.EntityPatcher<
	QualifierValueEntity
>;

/**
 * QualifierValue entity patches array
 *
 * @export
 */
export type QualifierValueEntitiesPatch = QualifierValueEntityPatch[];

/**
 * Some  qualifierValue entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type QualifierValueEntityPatch_Some =
	| QualifierValueEntityPatch
	| QualifierValueEntitiesPatch;

/**
 * QualifierValue entity id
 *
 * @export
 */
export type QualifierValueId = storage.EntityId;

/**
 * QualifierValue entity ids
 *
 * @export
 */
export type QualifierValueIds = storage.EntityIds;

/**
 * Some  qualifierValue entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type QualifierValueId_Some = QualifierValueId | QualifierValueIds;

/**
 * QualifierValue entities object by id
 *
 * @export
 * @interface QualifierValueEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface QualifierValueEntitiesObject extends storage.EntitiesObject {
	[id: string]: QualifierValueEntity;
}

/**
 * QualifierValue entity state
 *
 * @export
 * @interface QualifierValueEntityState
 * @extends {storage.EntityState}
 */
export interface QualifierValueEntityState extends storage.EntityState {}

/**
 * QualifierValue store collection
 *
 * @export
 * @interface QualifierValueStoreCollection
 * @extends {storage.Store}
 */
export interface QualifierValueStoreCollection extends storage.Store {
	qualifierValue: QualifierValueCollection;
}

/**
 * QualifierValue collection properties
 *
 * @export
 * @interface QualifierValueCollection
 * @extends {Collection}
 */
export interface QualifierValueCollection extends storage.Collection {
	byIds: QualifierValueEntitiesObject;
	mutation: QualifierValueEntitiesObject;
	cache: QualifierValueEntitiesObject;
	state: QualifierValueCollectionState;
}

/**
 * QualifierValue collection state
 *
 * @export
 * @interface QualifierValueCollectionState
 * @extends {storage.CollectionState}
 */
export interface QualifierValueCollectionState
	extends storage.CollectionState {}

// custom  qualifierValue collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const qualifierValueCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const qualifierValueCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const qualifierValueCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const qualifierValueCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: qualifierValueCollectionIdStringProps,
	idArrayProps: qualifierValueCollectionIdArrayProps,
	idEntityProps: qualifierValueCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * QualifierValue collection default values, initialize collection with  qualifierValue entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const qualifierValueCollectionDefault: QualifierValueCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as QualifierValueCollection),
		...{
			activeId:
				qualifierValueConfig.activeId || qualifierValueConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	qualifierValueConfig.entities
);

/**
 * QualifierValue store collection default values
 *
 * @export
 * @constant
 */
export const qualifierValueStoreCollectionDefault: QualifierValueStoreCollection = {
	qualifierValue: qualifierValueCollectionDefault
};

/**
 * QualifierValue store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getQualifierValueCollection = (state: any) =>
	state.lead.qualifierValue;
