import { UserEntities } from '../../app/store/collections/User';
import { UserSettings } from '../../app/store/models/User';

export interface UserConfig {
	security: {
		default: string[];
	};
	settings: {
		default: UserSettings;
	};
	entities: UserEntities;
	activeId?: string;
}

export const userConfig: UserConfig = {
	security: {
		default: ['user']
	},
	settings: {
		default: {
			theme: 'dark'
		}
	},
	entities: []
};
