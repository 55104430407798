import { combineReducers } from 'redux';

import { apiReducer } from './Api';
import { authReducer } from './Auth';
import { menuReducer } from './Menu';
import { routeReducer } from './Route';
import { userReducer } from './User';

export const reducers = combineReducers({
	api: apiReducer,
	auth: authReducer,
	menu: menuReducer,
	route: routeReducer,
	user: userReducer
});

export * from './Api';
export * from './Auth';
export * from './Menu';
export * from './Route';
export * from './User';
