import { ContextEntities } from '../../lead/store/collections/Context';

export interface ContextConfig {
	entities: ContextEntities;
	activeId?: string;
}

export const contextConfig: ContextConfig = {
	entities: []
};
