import React, { useEffect, useState } from 'react';

//UI
import { EventCard } from '../../components/Event';
import {
	ContextEntity,
	ContextEntities,
	ServiceEntity,
	EventEntities,
	useEvent,
	useLicense,
	useLead,
	useContext
} from '../../../store';
import { int } from '@zxing/library/esm/customTypings';
import moment from 'moment';

export interface RecentEventsCardConfigProps {
	title?: string;
	instructions?: string;
	noEventsFoundTitle?: string;
	noEventsFoundInstructions?: string;
}

export interface RecentEventsCardProps {
	service: ServiceEntity;
	contexts: ContextEntities;
	maxEvents?: int;
}

export const RecentEventsCard: React.FC<RecentEventsCardProps> = props => {
	// Data Hooks
	const { contextHelper } = useContext();
	const { eventHelper } = useEvent();
	const { licenseHelper } = useLicense();
	const { leadHelper } = useLead();

	//Build Ui Components
	const contextListItems = props.contexts
		.sort((a: ContextEntity, b: ContextEntity) => {
			return moment(a.i_.created?.dt).isBefore(b.i_.created?.dt)
				? 1
				: moment(b.i_.created?.dt).isBefore(a.i_.created?.dt)
				? -1
				: 0;
		})
		.slice(0, props.maxEvents ?? 3)
		.map((context: ContextEntity) => {
			let totalLeads = leadHelper.allByServiceIdAndEventIds(
				props.service.id || '',
				context.eventIds || []
			).length;
			let licenses = licenseHelper.allByContexts(
				[context],
				props.service ? [props.service] : undefined
			);

			let totalAppLicenses = licenses
				.filter(license => license.serviceType === 'lead-app')
				.reduce((sum, current) => sum + current.quantity, 0);
			let totalAPILicenses = licenses
				.filter(license => license.serviceType === 'lead-api')
				.reduce((sum, current) => sum + current.quantity, 0);
			let totalSSTLicenses = licenses
				.filter(license => license.serviceType === 'sst')
				.reduce((sum, current) => sum + current.quantity, 0);
			let totalOpticonLicenses = licenses
				.filter(license => license.serviceType === 'opticon')
				.reduce((sum, current) => sum + current.quantity, 0);

			let events: EventEntities = eventHelper.gets(context?.eventIds || []);

			return (
				<EventCard
					key={'EventCard_' + context.id}
					context={context}
					events={events}
					totalLeads={totalLeads}
					totalAppLicenses={totalAppLicenses}
					totalAPILicenses={totalAPILicenses}
					totalSSTLicenses={totalSSTLicenses}
					totalOpticonLicenses={totalOpticonLicenses}
					onContextSelected={selectedContextId => {
						contextHelper.set(selectedContextId);
						eventHelper.set(events[0].id);
					}}
				/>
			);
		});

	//Component  Output
	return <div className="RecentEventsCard">{contextListItems}</div>;
};
