import React, { useState } from 'react';

//DATA
import { useLocaleConfig } from '../../../app/store';
import { useCtx } from '../../../config/hooks';
import {
	ContextEntities,
	LicenseEntities,
	LicenseEntity,
	LicenseServiceType,
	LicenseType
} from '../../store';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonIcon,
	IonModal,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonInput,
	IonContent
} from '@ionic/react';
import { LicenseCardList } from '../containers/License';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { ServiceNotFoundCard } from './../components/Service/ServiceNotFoundCard';
import { addCircleOutline, sadOutline } from 'ionicons/icons';
import { newEntity } from '../../../app/utils';

import { ObjectType } from '../../store/models';
import uuidV4 from 'uuid/v4';
import { useToast } from '@agney/ir-toast';

const LicensesView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			activeService,
			activeEvent,
			contextHelper,
			licenseHelper,
			activeContext
		}
	} = ctx;
	const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
	const [newLicense, setNewLicense] = useState<LicenseEntity>({
		...newEntity(ctx, ObjectType.License),
		...{
			type: LicenseType.Device,
			name: '',
			serviceType: LicenseServiceType.LeadApp,
			serviceId: activeService?.id || '',
			code: '',
			contextId: '',
			quantity: 1,
			uses: 0
		}
	});
	const Toast = useToast();

	if (!activeService) return <ServiceNotFoundCard />;

	let contexts: ContextEntities = activeEvent
		? contextHelper.allByEventId(activeEvent.id)
		: [];
	let licenses: LicenseEntities = licenseHelper.allByContexts(contexts, [
		activeService
	]);

	const addNewLicense = () => {
		if (
			activeService &&
			activeContext &&
			newLicense.serviceType &&
			newLicense.quantity > 0
		) {
			licenseHelper.write(ctx, {
				userId: activeUser?.userId,
				licenses: [
					{
						...newLicense,
						...{
							type: LicenseType.Device,
							orderId: uuidV4(),
							contextId: activeContext?.id,
							serviceId: activeService?.id,
							code: uuidV4(),
							name:
								activeService?.name + ': ' + newLicense.serviceType?.toString(),
							serviceName:
								activeService?.name + ': ' + newLicense.serviceType?.toString(),
							uses: 0
						}
					}
				]
			});
			Toast.success('New license created successfully!');

			setNewLicense({
				...newEntity(ctx, ObjectType.License),
				...{
					id: '',
					type: LicenseType.Device,
					name: '',
					serviceType: LicenseServiceType.LeadApp,
					serviceId: activeService?.id || '',
					code: '',
					contextId: '',
					quantity: 1,
					uses: 0
				}
			});
			setShowAddLicenseModal(false);
		}
	};

	return (
		<IonContent>
			<IonModal
				isOpen={showAddLicenseModal}
				onDidDismiss={() => setShowAddLicenseModal(false)}
				cssClass="my-custom-class"
			>
				<IonContent>
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonTitle>
									Add a New License for <b>{activeContext?.name}</b>
								</IonTitle>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonList>
									<IonItem>
										<IonLabel>License Type</IonLabel>
										<IonSelect
											value={newLicense.serviceType}
											onIonChange={e => {
												newLicense.serviceType = e.detail.value;
												setNewLicense(newLicense);
											}}
										>
											<IonSelectOption value={LicenseServiceType.LeadApp}>
												Mobile App
											</IonSelectOption>
											<IonSelectOption value={LicenseServiceType.Opticon}>
												Opticon
											</IonSelectOption>
										</IonSelect>
									</IonItem>
									<IonItem>
										<IonLabel>License Quantity</IonLabel>
										<IonInput
											type="number"
											value={newLicense.quantity}
											placeholder="Enter Number"
											max="100"
											min="1"
											onIonChange={e => {
												newLicense.quantity = !isNaN(
													parseInt(e.detail.value || '0')
												)
													? parseInt(e.detail.value || '0')
													: 0;
												setNewLicense(newLicense);
											}}
										></IonInput>
									</IonItem>
								</IonList>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4">
								<IonButton onClick={() => setShowAddLicenseModal(false)}>
									Cancel
								</IonButton>
							</IonCol>
							<IonCol size="4">
								<IonButton onClick={() => addNewLicense()}>Save</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
			<IonGrid>
				<IonRow>
					<IonCol>
						<PageTitle title="Licenses" displayEventSelector={true} />
					</IonCol>
				</IonRow>
				{activeEvent && (
					<>
						<IonRow>
							<IonCol
								hidden={!activeUser?.security.includes('administrator')}
								class="ion-text-end"
							>
								<IonButton
									size="small"
									onClick={() => setShowAddLicenseModal(true)}
								>
									<IonIcon src={addCircleOutline}></IonIcon> New License
								</IonButton>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonCard>
									<IonCardContent>
										<LicenseCardList
											title="Mobile App License(s)"
											licenseType="lead-app"
											service={activeService}
											event={activeEvent}
											licenses={licenses}
										/>
										<LicenseCardList
											title="Lead API License(s)"
											licenseType="lead-api"
											service={activeService}
											event={activeEvent}
											licenses={licenses}
										/>
										<LicenseCardList
											title="SST Device License(s)"
											licenseType="sst"
											service={activeService}
											event={activeEvent}
											licenses={licenses}
										/>
										<LicenseCardList
											title="Opticon License(s)"
											licenseType="opticon"
											service={activeService}
											event={activeEvent}
											licenses={licenses}
										/>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
						<IonRow hidden={licenses && licenses.length > 0}>
							<IonCol>
								<IonCard class="ion-text-center">
									<IonCardContent class="ion-text-center">
										<IonIcon icon={sadOutline} style={{ fontSize: '60px' }} />
										<h1>There are no licenses available</h1>
										<p>Please add an order code.</p>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
					</>
				)}
			</IonGrid>
		</IonContent>
	);
};

export default LicensesView;
