'use strict';

import * as appHooks from '../../app/store/hooks';
import * as leadHooks from '../../lead/store/hooks';
import { userConfig } from '../app/User';
import { AppHelpers, AppHooks } from '../../app/store';
import { LeadHelpers, LeadHooks } from '../../lead/store';
import { AppContext } from '../../app/AppContext';
import { useContext } from 'react';

export interface UseCtx<ConfigTypes> {
	locale: string;
	config: ConfigTypes[];
	provider: AppContext;
	app: AppHelpers & AppHooks & AppContext;
	lead: LeadHelpers & LeadHooks;
}

export const useCtx = <ConfigTypes>(...configs: any[]): UseCtx<ConfigTypes> => {
	const provider = useContext(AppContext) as AppContext;

	const app: AppHooks & AppContext = {
		...appHooks.useApi(),
		...appHooks.useAuth(),
		...appHooks.useMenu(),
		...appHooks.useRoute(),
		...appHooks.useUser(),
		...provider
	};

	const locale =
		app.activeUser?.locale || userConfig.settings.default.locale || '';

	const lead: LeadHooks = {
		...leadHooks.useContact(),
		...leadHooks.useEventAnswer(),
		...leadHooks.useEventContact(),
		...leadHooks.useEventQuestion(),
		...leadHooks.useContext(),
		...leadHooks.useDevice(),
		...leadHooks.useEvent(),
		...leadHooks.useLead(),
		...leadHooks.useLicense(),
		...leadHooks.useNote(),
		...leadHooks.useOrder(),
		...leadHooks.useQualifier(),
		...leadHooks.useQualifierValue(),
		...leadHooks.useResponse(),
		...leadHooks.useService(),
		...leadHooks.useTicket(),
		...leadHooks.useUser(),
		...leadHooks.useData()
	};

	return {
		locale,
		config: getConfigs<ConfigTypes>(locale, ...configs),
		provider,
		app: {
			api: app.apiHelper,
			auth: app.authHelper,
			menu: app.menuHelper,
			route: app.routeHelper,
			user: app.userHelper,
			...app
		},
		lead: {
			contact: lead.contactHelper,
			eventAnswer: lead.eventAnswerHelper,
			eventContact: lead.eventContactHelper,
			eventQuestion: lead.eventQuestionHelper,
			context: lead.contextHelper,
			device: lead.deviceHelper,
			event: lead.eventHelper,
			lead: lead.leadHelper,
			license: lead.licenseHelper,
			note: lead.noteHelper,
			order: lead.orderHelper,
			qualifier: lead.qualifierHelper,
			qualifierValue: lead.qualifierValueHelper,
			response: lead.responseHelper,
			service: lead.serviceHelper,
			ticket: lead.ticketHelper,
			user: lead.userHelper,
			data: lead.dataHelper,
			...lead
		}
	} as UseCtx<ConfigTypes>;
};

const getConfigs = <ConfigTypes>(
	locale: string,
	...configs: any[]
): ConfigTypes[] => {
	let config: ConfigTypes[] = [];

	for (let configArg of configs) {
		// get config for locale // else for first locale
		let cfg: ConfigTypes =
			configArg[locale ? locale : ''] ||
			(configArg[Object.keys(configArg)[0]] as ConfigTypes);
		if (cfg) config.push(cfg);
	}

	return config as ConfigTypes[];
};
