import {
	Collection,
	Entity,
	Entities,
	Entity_Some,
	EntityId,
	EntityId_Some,
	EntityPatch_Some,
	EntitiesObject,
	EntityId_Mutation,
	EntityId_MutationOpts,
	CollectionState
} from '../models';
import _ from 'lodash';

/**
 * Collection reducer actions interface for all standard entity actions
 * IMPORTANT!! Pure functions only mean't for execution from within reducers
 *
 * @export
 * @interface CollectionActions
 */
export interface ICollectionReducerActions {
	mutateId<C extends Collection>(
		collection: C,
		idMutation: EntityId_Mutation,
		opts: EntityId_MutationOpts
	): C;
	set<C extends Collection, E extends EntityId>(collection: C, id?: E): C;
	toggle<C extends Collection, E extends EntityId>(collection: C, id?: E): C;
	select<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	deselect<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	upsert<C extends Collection, E extends Entity_Some>(
		collection: C,
		entities: E
	): C;
	patch<C extends Collection, E extends EntityPatch_Some>(
		collection: C,
		entities: E
	): C;
	change<C extends Collection, E extends EntityPatch_Some>(
		collection: C,
		entities: E
	): C;
	applyChanges<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	cancelChanges<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	cache<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	uncache<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C;
	setState<C extends Collection, CS extends CollectionState>(
		collection: C,
		state: CS
	): C;
	patchState<C extends Collection, CS extends Partial<CollectionState>>(
		collection: C,
		statePatch: Partial<CS>
	): C;
}

/**
 * Collection reducer actions class for all standard entity actions
 * IMPORTANT!! Pure functions only mean't for execution from within reducers
 *
 * @export
 * @class CollectionReducerActions
 * @implements {ICollectionReducerActions}
 */
export class CollectionReducerActions implements ICollectionReducerActions {
	constructor() {}

	/**
	 * Mutate (replace) and entity id with a new id and update all references
	 * within the collection idString and idArray properties
	 * TODO: update references in other collections...
	 *
	 * @template C
	 * @template EM
	 * @template EMO
	 * @param {C} collection
	 * @param {EM} idMutation
	 * @param {EMO} opts
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	mutateId<C extends Collection>(
		collection: C,
		idMutation: EntityId_Mutation,
		opts: EntityId_MutationOpts
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c
			//...Magical Things
			//idMutation.id,
			//idMutation.newId,
			//opts.idStringProps,
			//opts.idArrayProps,
			//opts.idEntityProps
		};
	}

	/**
	 * Set the active entity id in a collection
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} [id]
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	set<C extends Collection, E extends EntityId>(collection: C, id?: E): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			activeId: id
		};
	}

	/**
	 * Toggle the active entity id in a collection
	 * if the current active entity id is different set the supplied entity id as the activeId
	 * otherwise deselect the activeId if it's the same or no entity id is supplied
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} [id]
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	toggle<C extends Collection, E extends EntityId>(collection: C, id?: E): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			activeId: id !== c.activeId ? id : undefined
		};
	}

	/**
	 * Select entity ids by adding them to the collections selectedIds array
	 * existing entity ids will be ignored
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	select<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			selectedIds: [
				...c.selectedIds,
				...(!Array.isArray(ids) ? [ids] : ids).filter(
					(id: EntityId) => c.selectedIds.indexOf(id) === -1
				)
			]
		};
	}

	/**
	 * Deselect entity ids by removing them from the collections selectedIds array
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	deselect<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			selectedIds: [
				...c.selectedIds.filter(
					id => [...(!Array.isArray(ids) ? [ids] : ids)].indexOf(id) === -1
				)
			]
		};
	}

	/**
	 * Upsert (add new / update existing) entities to / in a collection
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} entities
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	upsert<C extends Collection, E extends Entity_Some>(
		collection: C,
		entities: E
	): C {
		let c: C = _.cloneDeep(collection);
		let byIds = {
			...c.byIds,
			...this.entitiesById(Array.isArray(entities) ? entities : [entities])
		};
		return {
			...c,
			byIds: byIds,
			allIds: Object.keys(byIds)
		};
	}

	/**
	 * Patch (merge) entities to / in a collection
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} entities
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	patch<C extends Collection, E extends EntityPatch_Some>(
		collection: C,
		entities: E
	): C {
		let c: C = _.cloneDeep(collection);
		let byIds = {
			...c.byIds,
			...this.entitiesById(
				[...(Array.isArray(entities) ? entities : [entities])].map(entity => ({
					...c.byIds[entity.id],
					...entity
				}))
			)
		};
		return {
			...c,
			byIds: byIds,
			allIds: Object.keys(byIds)
		};
	}

	/**
	 * Change (mutate) entities as without mutating the original entities.
	 * When changes are completed, call applyChanges to apply the mutations
	 * to the orginal enitities, or call cancelChanges to discard them
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} entities
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	change<C extends Collection, E extends EntityPatch_Some>(
		collection: C,
		entities: E
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			mutation: {
				...c.mutation,
				..._.cloneDeep(
					this.entitiesById(Array.isArray(entities) ? entities : [entities])
				)
			}
		};
	}

	/**
	 * Apply changes (mutations) to entities to update the orignal entities
	 * If any of the entities were cached, they will be uncached and the
	 * changes will be applied to them
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	applyChanges<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = this.uncache(collection, ids);
		let byIds = {
			...c.byIds,
			..._.pick(c.mutation, typeof ids === 'string' ? [ids] : ids)
		};
		return {
			...c,
			byIds,
			allIds: Object.keys(byIds),
			mutation: {
				..._.omit(c.mutation, typeof ids === 'string' ? [ids] : ids)
			}
		};
	}

	/**
	 * Cancel changes (mutations) to entities and not update the original entities
	 * The mutations are permanently discarded
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	cancelChanges<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			mutation: {
				..._.omit(c.mutation, typeof ids === 'string' ? [ids] : ids)
			}
		};
	}

	/**
	 * Cache entities by ids, moving them to the cache
	 * Cancel any changes (mutations) first and cache the original entity
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	cache<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = this.cancelChanges(collection, ids);
		let byIds = {
			..._.omit(c.byIds, typeof ids === 'string' ? [ids] : ids)
		};
		return {
			...c,
			cache: {
				...c.cache,
				..._.pick(c.byIds, typeof ids === 'string' ? [ids] : ids)
			},
			byIds,
			allIds: Object.keys(byIds)
		};
	}

	/**
	 * Uncache entities by ids, moving out of the cache
	 *
	 * @template C
	 * @template E
	 * @param {C} collection
	 * @param {E} ids
	 * @returns {C}
	 * @memberof CollectionReducerActions
	 */
	uncache<C extends Collection, E extends EntityId_Some>(
		collection: C,
		ids: E
	): C {
		let c: C = _.cloneDeep(collection);
		let byIds = {
			...c.byIds,
			..._.pick(c.cache, typeof ids === 'string' ? [ids] : ids)
		};
		return {
			...c,
			byIds,
			allIds: Object.keys(byIds),
			cache: {
				..._.omit(c.cache, typeof ids === 'string' ? [ids] : ids)
			}
		};
	}

	/**
	 * Convert an array of entities to an entities object keyed by id
	 *
	 * @private
	 * @template E
	 * @template ES
	 * @template EO
	 * @param {ES} entities
	 * @returns {EO}
	 * @memberof CollectionReducerActions
	 */
	private entitiesById<
		E extends Entity,
		ES extends Entities | any,
		EO extends EntitiesObject | any
	>(entities: ES): EO {
		return entities.reduce((obj: EO, entity: E) => {
			obj[entity.id] = entity;
			return obj;
		}, {}) as EO;
	}

	setState<C extends Collection, CS extends CollectionState>(
		collection: C,
		state: CS
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...c,
			state: {
				...c.state,
				...(_.omit(state, ['byIds']) as CS) // don't allow mutating the entity states by id
			}
		};
	}

	patchState<C extends Collection, CS extends Partial<CollectionState>>(
		collection: C,
		statePatch: Partial<CS>
	): C {
		let c: C = _.cloneDeep(collection);
		return {
			...{},
			...c,
			state: {
				...c.state,
				...(_.omit(statePatch, ['byIds']) as CS) // don't allow mutating the entity states by id
			}
		};
	}
}

export const collectionReducerActions = new CollectionReducerActions();
