import React from 'react';

import {
	IonButton,
	IonIcon,
	IonText,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent
} from '@ionic/react';

import { sadOutline } from 'ionicons/icons';
import { serviceNotFoundCardConfig } from '../../../../config/lead/components/ServiceNotFoundCard';
import { useLocaleConfig } from '../../../../app/store';
import { useCtx } from '../../../../config/hooks';

export interface ServiceNotFoundCardConfigProps {
	label?: {
		information?: string;
		totalLeads?: string;
		devicesUsed?: string;
		devicesSeparator?: string;
	};
	buttonText?: {
		viewService?: string;
	};
}

export interface ServiceNotFoundCardProps {}

export const ServiceNotFoundCard: React.FC<ServiceNotFoundCardProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<ServiceNotFoundCardConfigProps>(
		serviceNotFoundCardConfig
	);
	const ctx = useCtx<ServiceNotFoundCardProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [configs]
	} = ctx;

	return (
		<IonCard class="ion-text-center">
			<IonIcon icon={sadOutline} style={{ fontSize: '60px' }} />
			<IonCardHeader>
				<IonCardTitle>No Account Found or Selected</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol size="12" class="text-align-center">
							<IonText>
								Please add or select your account using the button below.
							</IonText>
							<IonButton routerLink="/lead-service/services">
								Add Account Details
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
