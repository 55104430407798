import {
	ContextId_Some,
	ContextEntity_Some,
	ContextEntityPatch_Some,
	ContextCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const CONTEXT_MUTATE_ID = '@lead/CONTEXT_MUTATE_ID';
export const CONTEXT_SET = '@lead/CONTEXT_SET';
export const CONTEXT_TOGGLE = '@lead/CONTEXT_TOGGLE';
export const CONTEXT_SELECT = '@lead/CONTEXT_SELECT';
export const CONTEXT_DESELECT = '@lead/CONTEXT_DESELECT';
export const CONTEXT_UPSERT = '@lead/CONTEXT_UPSERT';
export const CONTEXT_PATCH = '@lead/CONTEXT_PATCH';
export const CONTEXT_CHANGE = '@lead/CONTEXT_CHANGE';
export const CONTEXT_APPLY_CHANGES = '@lead/CONTEXT_APPLY_CHANGES';
export const CONTEXT_CANCEL_CHANGES = '@lead/CONTEXT_CANCEL_CHANGES';
export const CONTEXT_CACHE = '@lead/CONTEXT_CACHE';
export const CONTEXT_UNCACHE = '@lead/CONTEXT_UNCACHE';
export const CONTEXT_SETSTATE = '@lead/CONTEXT_SETSTATE';
export const CONTEXT_PATCHSTATE = '@lead/CONTEXT_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface ContextAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface ContextAction_MutateId extends EntityAction_MutateId {
	type: typeof CONTEXT_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active context
 *
 * @interface ContextAction_Set
 * @extends {EntityAction_Set}
 */
export interface ContextAction_Set extends EntityAction_Set {
	type: typeof CONTEXT_SET;
	payload?: EntityId;
}

/**
 * Toggle active context
 *
 * @interface ContextAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface ContextAction_Toggle extends EntityAction_Toggle {
	type: typeof CONTEXT_TOGGLE;
	payload?: EntityId;
}

/**
 * Select context entities
 *
 * @interface ContextAction_Select
 * @extends {EntityAction_Select}
 */
interface ContextAction_Select extends EntityAction_Select {
	type: typeof CONTEXT_SELECT;
	payload: ContextId_Some;
}

/**
 * Deselect context entities
 *
 * @interface ContextAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface ContextAction_Deselect extends EntityAction_Deselect {
	type: typeof CONTEXT_DESELECT;
	payload: ContextId_Some;
}

/**
 * Upsert context entities
 *
 * @interface ContextAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface ContextAction_Upsert extends EntityAction_Upsert {
	type: typeof CONTEXT_UPSERT;
	payload: ContextEntity_Some;
}

/**
 * Patch context entities
 *
 * @interface ContextAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface ContextAction_Patch extends EntityAction_Patch {
	type: typeof CONTEXT_PATCH;
	payload: ContextEntityPatch_Some;
}

/**
 * Change context entities
 *
 * @interface ContextAction_Change
 * @extends {EntityAction_Change}
 */
interface ContextAction_Change extends EntityAction_Change {
	type: typeof CONTEXT_CHANGE;
	payload: ContextEntityPatch_Some;
}

/**
 * Apply changes to context entities
 *
 * @interface ContextAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface ContextAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof CONTEXT_APPLY_CHANGES;
	payload: ContextId_Some;
}

/**
 * Cancel changes to context entities
 *
 * @interface ContextAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface ContextAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof CONTEXT_CANCEL_CHANGES;
	payload: ContextId_Some;
}

/**
 * Cache context entities
 *
 * @interface ContextAction_Cache
 * @extends {EntityAction_Cache}
 */
interface ContextAction_Cache extends EntityAction_Cache {
	type: typeof CONTEXT_CACHE;
	payload: ContextId_Some;
}

/**
 * Uncache context entities
 *
 * @interface ContextAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface ContextAction_Uncache extends EntityAction_Uncache {
	type: typeof CONTEXT_UNCACHE;
	payload: ContextId_Some;
}

/**
 * Set context collection / entity state
 *
 * @interface ContextAction_SetState
 * @extends {EntityAction_SetState}
 */
interface ContextAction_SetState extends EntityAction_SetState {
	type: typeof CONTEXT_SETSTATE;
}

/**
 * PAtch context collection / entity state
 *
 * @interface ContextAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface ContextAction_PatchState extends EntityAction_PatchState {
	type: typeof CONTEXT_PATCHSTATE;
}

/**
 * Export context action types
 *
 * @export
 */
export type ContextActionTypes =
	| ContextAction_MutateId
	| ContextAction_MutateId
	| ContextAction_Set
	| ContextAction_Toggle
	| ContextAction_Select
	| ContextAction_Deselect
	| ContextAction_Upsert
	| ContextAction_Patch
	| ContextAction_Change
	| ContextAction_ApplyChanges
	| ContextAction_CancelChanges
	| ContextAction_Cache
	| ContextAction_Uncache
	| ContextAction_SetState
	| ContextAction_PatchState;

/**
 * Context actions helper interface
 *
 * @export
 * @interface IContextActions
 * @extends {EntityActions}
 */
export interface IContextActions extends EntityActions {
	//customAction(ids: ContextId_Some): ContextAction_CustomAction;
}

/**
 * Context actions helper
 *
 * @export
 * @class ContextActions
 * @implements {EntityBaseActions}
 */
export class ContextActions implements IContextActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): ContextAction_MutateId {
		return { type: CONTEXT_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): ContextAction_Set {
		return { type: CONTEXT_SET, payload: id };
	}
	toggle(id?: EntityId): ContextAction_Toggle {
		return { type: CONTEXT_TOGGLE, payload: id };
	}
	select(ids: ContextId_Some): ContextAction_Select {
		return {
			type: CONTEXT_SELECT,
			payload: ids
		};
	}
	deselect(ids: ContextId_Some): ContextAction_Deselect {
		return {
			type: CONTEXT_DESELECT,
			payload: ids
		};
	}
	upsert(entities: ContextEntity_Some): ContextAction_Upsert {
		return {
			type: CONTEXT_UPSERT,
			payload: entities
		};
	}
	patch(entities: ContextEntityPatch_Some): ContextAction_Patch {
		return {
			type: CONTEXT_PATCH,
			payload: entities
		};
	}
	change(entities: ContextEntityPatch_Some): ContextAction_Change {
		return {
			type: CONTEXT_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: ContextId_Some): ContextAction_ApplyChanges {
		return {
			type: CONTEXT_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: ContextId_Some): ContextAction_CancelChanges {
		return {
			type: CONTEXT_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: ContextId_Some): ContextAction_Cache {
		return {
			type: CONTEXT_CACHE,
			payload: ids
		};
	}
	uncache(ids: ContextId_Some): ContextAction_Uncache {
		return {
			type: CONTEXT_UNCACHE,
			payload: ids
		};
	}
	setState(state: ContextCollectionState): ContextAction_SetState {
		return {
			type: CONTEXT_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<ContextCollectionState>
	): ContextAction_PatchState {
		return {
			type: CONTEXT_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the context actions helper
 *
 * @export
 */
export const contextActions = new ContextActions();
