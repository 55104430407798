import React from 'react';

//DATA
import { ServiceEntity, LicenseEntities, LicenseEntity } from '../../store';
import { useCtx } from '../../../config/hooks';
import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';

const AdminPrintLicensesView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const {
		lead: { activeService, activeEvent, activeContext, licenseHelper }
	} = ctx;

	const currentService: ServiceEntity | undefined = activeService;
	if (!currentService || !activeContext) return <ServiceNotFoundCard />;

	const licenses: LicenseEntities = licenseHelper.allByContexts(
		[activeContext],
		[currentService]
	);
	const licenseItems = licenses
		.sort((a: LicenseEntity, b: LicenseEntity) => {
			return (a.serviceType || '') > (b.serviceType || '')
				? 1
				: (b.serviceType || '') > (a.serviceType || '')
				? -1
				: 0;
		})
		.map(license => {
			if (license.serviceType === 'lead-app') {
				return (
					<div key={'lead-app-license' + license.id}>
						<div
							style={{
								width: '20%',
								display: 'inline-block',
								marginTop: '30px'
							}}
						>
							<img
								src={
									'https://qrcode.tec-it.com/API/QRCode?data=' + license.code
								}
								width="120px"
							/>
						</div>
						<div
							style={{
								width: '30%',
								display: 'inline-block',
								marginTop: '30px'
							}}
						>
							<div>
								<b>Max Usage: {license.quantity}</b>
							</div>
							<br />
							<b>Activation Code:</b>
							<br />
							<div>{license.code}</div>
						</div>
					</div>
				);
			}
		});

	return (
		<div style={{ margin: '20px' }}>
			<div style={{ margin: '10px' }}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						display: 'inline-block'
					}}
				>
					<h2>{currentService.name}</h2>
					<br />
					{activeEvent?.name}
					<br />
					Mobile App Licenses
				</div>
			</div>

			<div style={{ margin: '10px' }}>{licenseItems}</div>
		</div>
	);
};

export default AdminPrintLicensesView;
