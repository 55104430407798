import {
	LicenseCollection,
	LicenseCollectionState,
	LicenseId,
	LicenseEntity_Some,
	LicenseEntityPatch_Some,
	LicenseId_Some,
	licenseCollectionDefault,
	licenseCollectionMutateIdOpts
} from '../collections/License';
import * as actions from '../actions/License';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * License reducer
 *
 * @export
 * @param {LicenseCollection} [state=licenseCollectionDefault]
 * @param {LicenseActionTypes} action
 * @returns
 */
export function licenseReducer(
	state: LicenseCollection = licenseCollectionDefault,
	action: actions.LicenseActionTypes
) {
	switch (action.type) {
		case actions.LICENSE_MUTATE_ID:
			return collectionReducerActions.mutateId<LicenseCollection>(
				state,
				action.payload,
				licenseCollectionMutateIdOpts
			);
		case actions.LICENSE_SET:
			return collectionReducerActions.set<LicenseCollection, LicenseId>(
				state,
				action.payload
			);
		case actions.LICENSE_TOGGLE:
			return collectionReducerActions.toggle<LicenseCollection, LicenseId>(
				state,
				action.payload
			);
		case actions.LICENSE_SELECT:
			return collectionReducerActions.select<LicenseCollection, LicenseId_Some>(
				state,
				action.payload
			);
		case actions.LICENSE_DESELECT:
			return collectionReducerActions.deselect<
				LicenseCollection,
				LicenseId_Some
			>(state, action.payload);
		case actions.LICENSE_UPSERT:
			return collectionReducerActions.upsert<
				LicenseCollection,
				LicenseEntity_Some
			>(state, action.payload);
		case actions.LICENSE_PATCH:
			return collectionReducerActions.patch<
				LicenseCollection,
				LicenseEntityPatch_Some
			>(state, action.payload);
		case actions.LICENSE_CHANGE:
			return collectionReducerActions.change<
				LicenseCollection,
				LicenseEntityPatch_Some
			>(state, action.payload);
		case actions.LICENSE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				LicenseCollection,
				LicenseId_Some
			>(state, action.payload);
		case actions.LICENSE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				LicenseCollection,
				LicenseId_Some
			>(state, action.payload);
		case actions.LICENSE_CACHE:
			return collectionReducerActions.cache<LicenseCollection, LicenseId_Some>(
				state,
				action.payload
			);
		case actions.LICENSE_UNCACHE:
			return collectionReducerActions.uncache<
				LicenseCollection,
				LicenseId_Some
			>(state, action.payload);
		case actions.LICENSE_SETSTATE:
			return collectionReducerActions.setState<
				LicenseCollection,
				LicenseCollectionState
			>(state, action.payload.state);
		case actions.LICENSE_PATCHSTATE:
			return collectionReducerActions.patchState<
				LicenseCollection,
				LicenseCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
