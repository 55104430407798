import React from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonLabel
} from '@ionic/react';

import {
	QualifierEntity,
	QualifierEntities,
	ResponseEntities,
	LeadEntity
} from '../../../store';

import { useCtx } from '../../../../config/hooks';
import { LeadQualifierValueResponseList } from './../../containers/Lead';

export interface LeadQualifierListConfigProps {
	lead: LeadEntity;
}

export const LeadQualifierList: React.FC<LeadQualifierListConfigProps> = props => {
	const {
		config: [config],
		lead: {
			activeService,
			activeEvent,
			qualifierHelper,
			qualifierValueHelper,
			responseHelper
		}
	} = useCtx<LeadQualifierListConfigProps>({});

	const sortQualifiers = (a: QualifierEntity, b: QualifierEntity) => {
		return (a.position as number) > (b.position as number)
			? 1
			: (b.position as number) > (a.position as number)
			? -1
			: 0;
	};

	const qualifiers: QualifierEntities =
		!activeService || !activeEvent
			? []
			: qualifierHelper.allByServiceIdAndEventIds(activeService.id, [
					activeEvent.id
			  ]);

	const responses: ResponseEntities = responseHelper.allByLead(props.lead);

	const qualifiersList = qualifiers
		.sort(sortQualifiers)
		.map((qualifier: QualifierEntity) => {
			var leadResponses = responses.filter(r => r.qualifierId == qualifier.id);
			var qualifierValues = qualifierValueHelper.allByQualifierId(qualifier.id);

			if (qualifierValues == null || qualifierValues.length > 0) {
				return (
					<IonItem>
						<IonGrid>
							<IonRow>
								<IonCol>
									<IonLabel class="ion-text-start">{qualifier.name}:</IonLabel>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<LeadQualifierValueResponseList
										lead={props.lead}
										qualifier={qualifier}
										qualifierValueList={qualifierValues}
										responses={leadResponses}
									></LeadQualifierValueResponseList>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
				);
			}
		});

	return qualifiers.length > 0 ? (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>Qualifiers</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonList>{qualifiersList}</IonList>
			</IonCardContent>
		</IonCard>
	) : (
		<></>
	);
};
