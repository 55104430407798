import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { contextConfig } from '../../../config/lead/Context';
import { Context } from '../models';

/**
 * Context entity interface
 *
 * @export
 * @interface ContextEntity
 * @extends {storage.Entity}
 */
export interface ContextEntity extends storage.Entity, Context {}

/**
 * Context entities array
 *
 * @export
 */
export type ContextEntities = ContextEntity[];

/**
 * Some context entities: one entity or an array of entities
 *
 * @export
 */
export type ContextEntity_Some = ContextEntity | ContextEntities;

/**
 * Context entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ContextEntityPatch
 * @extends {storage.EntityPatch<ContextEntity>}
 */
export type ContextEntityPatch = storage.EntityPatcher<ContextEntity>;

/**
 * Context entity patches array
 *
 * @export
 */
export type ContextEntitiesPatch = ContextEntityPatch[];

/**
 * Some context entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ContextEntityPatch_Some = ContextEntityPatch | ContextEntitiesPatch;

/**
 * Context entity id
 *
 * @export
 */
export type ContextId = storage.EntityId;

/**
 * Context entity ids
 *
 * @export
 */
export type ContextIds = storage.EntityIds;

/**
 * Some context entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ContextId_Some = ContextId | ContextIds;

/**
 * Context entities object by id
 *
 * @export
 * @interface ContextEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ContextEntitiesObject extends storage.EntitiesObject {
	[id: string]: ContextEntity;
}

/**
 * Context entity state
 *
 * @export
 * @interface ContextEntityState
 * @extends {storage.EntityState}
 */
export interface ContextEntityState extends storage.EntityState {}

/**
 * Context store collection
 *
 * @export
 * @interface ContextStoreCollection
 * @extends {storage.Store}
 */
export interface ContextStoreCollection extends storage.Store {
	context: ContextCollection;
}

/**
 * Context collection properties
 *
 * @export
 * @interface ContextCollection
 * @extends {Collection}
 */
export interface ContextCollection extends storage.Collection {
	byIds: ContextEntitiesObject;
	mutation: ContextEntitiesObject;
	cache: ContextEntitiesObject;
	state: ContextCollectionState;
}

/**
 * Context collection state
 *
 * @export
 * @interface ContextCollectionState
 * @extends {storage.CollectionState}
 */
export interface ContextCollectionState extends storage.CollectionState {}

// custom context collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const contextCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const contextCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const contextCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const contextCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: contextCollectionIdStringProps,
	idArrayProps: contextCollectionIdArrayProps,
	idEntityProps: contextCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Context collection default values, initialize collection with context entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const contextCollectionDefault: ContextCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ContextCollection),
		...{
			activeId: contextConfig.activeId || contextConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	contextConfig.entities
);

/**
 * Context store collection default values
 *
 * @export
 * @constant
 */
export const contextStoreCollectionDefault: ContextStoreCollection = {
	context: contextCollectionDefault
};

/**
 * Context store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getContextCollection = (state: any) => state.lead.context;
