import { ApiEntities } from '../../app/store/collections/Api';
import { ApiAuthTypes } from '../../app/store/models/Api';

//import { RequestTokenPayload, RequestToken } from '../../lead/store';
import _ from 'lodash';

export interface ApiConfig {
	entities: ApiEntities;
	activeId?: string;
}

//export interface ApiRequestTokenPayload extends RequestTokenPayload {}
//export interface ApiRequestToken extends RequestToken {}

//export const defaultApiOrigin = 'http://localhost:3011';
export const defaultApiOrigin = 'https://api.conexsys.com';
//export const defaultApiOrigin = 'https://staging-api.conexsys.com';
//export const defaultApiOrigin = 'https://master-dev-api.conexsys.com';

export const apiConfig: ApiConfig = {
	//
	entities: [
		{
			id: 'lead-portal-api',
			key: 'lead',
			name: 'CONEXSYS Lead Portal API',
			url: `${defaultApiOrigin}/lead-portal/v2/api`,
			auth: {
				//type: ApiAuthTypes.UserToken
				type: ApiAuthTypes.BearerToken,
				token:
					'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16SXdOMEkwTWpVMU0wSTBORGN4UkRkRk16bENNakk0TWpOR01EUTVSRVU0TXpneE1VVTNRUSJ9.eyJpc3MiOiJodHRwczovL2F1dGguY29uZXhzeXMuY29tLyIsInN1YiI6Ikd2Z3dqT0t6QVU4QjBJZmVrbEpVeUwxb0VqdzBvbVB2QGNsaWVudHMiLCJhdWQiOiJodHRwczovL2FwaS5jb25leHN5cy5jb20vbGVhZC1wb3J0YWwiLCJpYXQiOjE1OTM3ODc4MjQsImV4cCI6MTU5Mzg3NDIyNCwiYXpwIjoiR3Znd2pPS3pBVThCMElmZWtsSlV5TDFvRWp3MG9tUHYiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.dp7Rx8HSKeJuKDN8yEEozgNmy5UFandv5vBolUGBkB7-FCZSQvRcZ7aygvcjeW4kh0U5tTxZCY-Hl25d0UL-NwfKPpxsln8yr7iE3h3vXlj55JD6e-CBaSKSjea-4KEewVmyzJOa-S_iiL6ZVAshJpEPQ0fiEOUbMKZ8mPSfFZ5eaTpt8YEM3DZDMKB1O9KRLiDxJf6iSX5PyWBnYu1Sa8nHcBtq34cFxAK5SrJsYiYS5ff0LOkbPvWqQootCoYv-XK0mClUMMq3j986HHEq2jVcGEQ2u_J7Wa0eC58VeKzJxcTisupVPKiVyx7ErmqsyWhRbHKnyF3dPc0fPDFw_Q'
			},
			/*
			requestToken: {
				header: 'x-request-token',
				operationId: 'getRequestToken'
			},
			*/
			definition: '/assets/data/conexsys-lead-portal-api-2.6.1-swagger.json'
		}
	]
};
