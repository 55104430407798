import { Localize } from '../../locales';
import { ChatConfigProps } from '../../../app/ui/components/Chat';

export type ChatConfig = Localize<ChatConfigProps>;

export const ChatConfig: ChatConfig = {
	en: {
		text: 'Chat'
	},
	fr: {
		text: 'Discuter'
	}
};
