import { ServiceHelper } from '..';
import { ServiceEntity } from '../collections';

export interface ServiceHook {
	serviceHelper: ServiceHelper;
	activeService: ServiceEntity | undefined;
}

export const useService = (): ServiceHook => {
	let serviceHelper = new ServiceHelper();
	return {
		serviceHelper,
		activeService: serviceHelper.active()
	};
};
