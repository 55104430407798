// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * DATA: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { DataHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { DataEntity, DataEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { DataType } from '../models';

export enum DataApiOperation {
	readDatas = 'readDatas',
	readDatasAll = 'readDatasAll',
	writeDatas = 'writeDatas'
}

export interface ReadDatasRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	sourceDataId?: string;
	contextId?: string;
	eventId?: string;
	serviceId?: string;
	productId?: string;
}

export interface WriteDatasRequest {
	userId: string;
	datas: DataEntities;
}

/**
 * Read datas
 */
export async function readDatas(
	ctx: UseCtx<any>,
	requestParameters: ReadDatasRequest
): Promise<DataEntities> {
	const operationId = DataApiOperation.readDatas,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<DataEntities, ReadDatasRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: DataEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<DataEntities, DataHelper>(
		ctx.lead.data,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.data.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Datas
 */
export async function writeDatas<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteDatasRequest
): Promise<DataEntities> {
	const operationId = DataApiOperation.writeDatas,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.datas.forEach(
		(entity: DataEntity, i: number, a: DataEntities) => {
			a[i] = modifyEntity<DataEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<DataEntities, WriteDatasRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'datas'
	);

	let entities: DataEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<DataEntities, DataHelper>(
		ctx.lead.data,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.data.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
