import React from 'react';

//Data
import { ContextEntity, EventEntities } from '../../../store';

//Lib
import moment from 'moment';

//UI
import {
	IonButton,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonImg
} from '@ionic/react';

export interface EventListItemConfigProps {
	label?: {
		information?: string;
		totalLeads?: string;
		devicesUsed?: string;
		devicesSeparator?: string;
	};
	buttonText?: {
		viewEvent?: string;
	};
}

export interface EventListItemProps {
	context: ContextEntity;
	events: EventEntities;
	totalLeads?: Number;
	totalAppLicenses?: Number;
	totalAPILicenses?: Number;
	totalSSTLicenses?: Number;
	totalOpticonLicenses?: Number;
	onContextSelected?: (selectedContextId: string) => void;
}

export const EventListItem: React.FC<EventListItemProps> = props => {
	const selectContext = () => {
		if (props.onContextSelected !== undefined) {
			props.onContextSelected(props.context.id);
		}
	};
	const mainEvent = props.events[0];

	return (
		<IonItem
			key={props.context.id}
			button
			routerLink="/lead-service/event"
			onClick={selectContext}
		>
			<IonGrid>
				<IonRow>
					<IonCol size="12" sizeMd="2">
						<IonImg
							hidden={!mainEvent.header?.OriginalFilePath}
							src={mainEvent.header?.OriginalFilePath || ''}
						/>
					</IonCol>
					<IonCol size="7" sizeMd="5">
						<div>
							{props.context.name} ({props.context.code})
							<br />
							{moment(mainEvent.start).format('YYYY-MM-DD')}
						</div>
					</IonCol>
					<IonCol size="3" sizeMd="3">
						<IonButton
							routerLink="/lead-service/leads"
							onClick={selectContext}
							fill="clear"
						>
							Leads: {props.totalLeads || 0}
						</IonButton>
						<br />
						<IonButton
							hidden={(props.totalAppLicenses || 0) <= 0}
							routerLink="/lead-service/licenses"
							onClick={selectContext}
							fill="clear"
						>
							App Licenses:{props.totalAppLicenses}
						</IonButton>
						<IonButton
							hidden={(props.totalAPILicenses || 0) <= 0}
							routerLink="/lead-service/licenses"
							onClick={selectContext}
							fill="clear"
						>
							API Licenses:{props.totalAPILicenses}
						</IonButton>
						<IonButton
							hidden={(props.totalSSTLicenses || 0) <= 0}
							routerLink="/lead-service/licenses"
							onClick={selectContext}
							fill="clear"
						>
							SST Licenses:{props.totalSSTLicenses}
						</IonButton>
						<IonButton
							hidden={(props.totalOpticonLicenses || 0) <= 0}
							routerLink="/lead-service/licenses"
							onClick={selectContext}
							fill="clear"
						>
							Opticon Licenses:{props.totalOpticonLicenses}
						</IonButton>
					</IonCol>
					<IonCol sizeMd="2">
						<IonButton routerLink="/lead-service/event" onClick={selectContext}>
							Details
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
