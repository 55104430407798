// tslint:disable
/**
 * CONEXSYS EventContact Portal API
 * The API for the CONEXSYS EventContact Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * EventContact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { EventContactHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { EventContactEntity, EventContactEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { ContactType, EventContactAnswers } from '../models';

export enum EventContactApiOperation {
	//readEventContactsById = 'readEventContactsById',
	readEventContactsById = 'readEventContactsById',
	readEventContactsByCode = 'readEventContactsByCode',
	readEventContactAnswers = 'readEventContactAnswers',
	writeEventContacts = 'writeEventContacts',
	readEventContactsOrganizations = 'readEventContactsOrganizations'
}

export interface ReadEventContactsByTypeRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	contactType?: ContactType;
}

export interface ReadEventContactsByIdRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventIds: string[];
	ids: string[];
}

export interface ReadEventContactsByCodeRequest {
	userId: string;
	eventIds: string[];
	code: string;
}

export interface ReadEventContactsOrganizationsRequest {
	userId: string;
	eventIds: string[];
	modifiedFrom?: string;
	ids?: string[];
}

export interface ReadEventContactAnswersRequest {
	userId: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	eventId: string;
	contactId: string;
}

export interface WriteEventContactsRequest {
	userId: string;
	eventcontacts: EventContactEntities;
}

/**
 * Get detailed event eventcontacts/registrants by system identity
 * Get events eventcontacts by id
 */
export async function readEventContactsById(
	ctx: UseCtx<any>,
	requestParameters: ReadEventContactsByIdRequest
): Promise<EventContactEntities> {
	const operationId = EventContactApiOperation.readEventContactsById,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventContactEntities,
		ReadEventContactsByIdRequest
	>(ctx, config.apiId, operationId, requestParameters, 'ids');

	let entities: EventContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventContactEntities, EventContactHelper>(
		ctx.lead.eventContact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventContact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Get detailed event eventcontacts/registrants by system identity
 * Get events eventcontacts by id
 */
export async function readEventContactsOrganizations(
	ctx: UseCtx<any>,
	requestParameters: ReadEventContactsOrganizationsRequest
): Promise<EventContactEntities> {
	const operationId = EventContactApiOperation.readEventContactsOrganizations,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventContactEntities,
		ReadEventContactsOrganizationsRequest
	>(ctx, config.apiId, operationId, requestParameters, 'ids');

	let entities: EventContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventContactEntities, EventContactHelper>(
		ctx.lead.eventContact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	//TODO:REMOVE fix event Id issue
	console.log('************** //TODO:REMOVE fix event Id issue');
	entities.forEach((entity, i, a) => {
		entity.eventId = entity.eventId?.trim();
		a[i] = entity;
	});

	// upsert the entities to the store
	ctx.lead.eventContact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

export async function readEventContactAnswers(
	ctx: UseCtx<any>,
	requestParameters: ReadEventContactAnswersRequest
): Promise<EventContactAnswers> {
	const eventContact = ctx.lead.eventContact.get(requestParameters.contactId);

	if (!eventContact) return [];

	const operationId = EventContactApiOperation.readEventContactAnswers,
		requestId = 'default';

	// make the api request
	const response = await apiRequest<
		EventContactEntities,
		ReadEventContactAnswersRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let eventContactAnswers: EventContactAnswers | undefined =
		response.response.data;
	if (eventContactAnswers) {
		// update the eventContact with the answers
		eventContact.answers = eventContactAnswers;
		ctx.lead.eventContact.upsert([eventContact]);
	}

	// return the event contact answers to the caller for direct reference
	return eventContactAnswers || [];
}

/**
 * Get detailed event eventcontacts/registrants by code
 * Get events eventcontacts by id
 */
export async function readEventContactsByCode(
	ctx: UseCtx<any>,
	requestParameters: ReadEventContactsByCodeRequest
): Promise<EventContactEntities> {
	const operationId = EventContactApiOperation.readEventContactsByCode,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		EventContactEntities,
		ReadEventContactsByCodeRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: EventContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventContactEntities, EventContactHelper>(
		ctx.lead.eventContact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventContact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write service eventcontacts
 */
export async function writeEventContacts<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteEventContactsRequest
): Promise<EventContactEntities> {
	const operationId = EventContactApiOperation.writeEventContacts,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.eventcontacts.forEach(
		(entity: EventContactEntity, i: number, a: EventContactEntities) => {
			a[i] = modifyEntity<EventContactEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<
		EventContactEntities,
		WriteEventContactsRequest
	>(ctx, config.apiId, operationId, requestParameters, 'eventcontacts');

	let entities: EventContactEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<EventContactEntities, EventContactHelper>(
		ctx.lead.eventContact,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.eventContact.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
