import React from 'react';

// UI components
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonTitle
} from '@ionic/react';

// Style
import '../../styles/card.css';

// Interface
interface InfoCardProps {
	h1?: string;
	h2?: string;
	p?: string;
	link?: string;
	button?: {
		routerLink?: string;
		text?: string;
	};
}

export const InfoCard: React.FC<InfoCardProps> = props => {
	return (
		<IonCard className="conexsys-card info-card">
			<IonCardHeader>
				<h1>{props?.h1}</h1>
			</IonCardHeader>
			<IonCardContent>
				{props.h2 ? <h2>{props.h2}</h2> : <></>}
				{props.p ? <p>{props.p}</p> : <></>}
				{props.button ? (
					<IonButton expand="full" routerLink={props.button.routerLink}>
						{props.button.text}
					</IonButton>
				) : (
					<></>
				)}
			</IonCardContent>
		</IonCard>
	);
};
