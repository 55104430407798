import React from 'react';
import { Menu } from '../components';

export const MainMenu: React.FC = () => (
	<Menu
		contentId="main"
		instanceId="main"
		id="menu-main"
		defaultTags={['default']}
	/>
);
