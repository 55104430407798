import { QualifierValueHelper } from '..';
import { QualifierValueEntity } from '../collections';

export interface QualifierValueHook {
	qualifierValueHelper: QualifierValueHelper;
	activeQualifierValue: QualifierValueEntity | undefined;
}

export const useQualifierValue = (): QualifierValueHook => {
	let qualifierValueHelper = new QualifierValueHelper();
	return {
		qualifierValueHelper,
		activeQualifierValue: qualifierValueHelper.active()
	};
};
