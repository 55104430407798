// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * SERVICE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { ServiceHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { ServiceEntity, ServiceEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { ServiceType } from '../models';

export enum ServiceApiOperation {
	readServices = 'readServices',
	readServicesAll = 'readServicesAll',
	writeServices = 'writeServices'
}

export interface ReadServicesRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	serviceType?: ServiceType;
}

export interface ReadServicesAllRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	serviceType?: ServiceType;
	ids?: string[];
	contactIds?: string[];
	orderIds?: string[];
	contextIds?: string[];
	eventIds?: string[];
	userIds?: string[];
	deviceIds?: string[];
}

export interface WriteServicesRequest {
	userId: string;
	services: ServiceEntities;
}

/**
 * Read services
 */
export async function readServices(
	ctx: UseCtx<any>,
	requestParameters: ReadServicesRequest
): Promise<ServiceEntities> {
	const operationId = ServiceApiOperation.readServices,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ServiceEntities, ReadServicesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: ServiceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ServiceEntities, ServiceHelper>(
		ctx.lead.service,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.service.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read services all
 */
export async function readServicesAll(
	ctx: UseCtx<any>,
	requestParameters: ReadServicesAllRequest
): Promise<ServiceEntities> {
	const operationId = ServiceApiOperation.readServicesAll,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<ServiceEntities, ReadServicesAllRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: ServiceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ServiceEntities, ServiceHelper>(
		ctx.lead.service,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.service.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Services
 */
export async function writeServices<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteServicesRequest
): Promise<ServiceEntities> {
	const operationId = ServiceApiOperation.writeServices,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.services.forEach(
		(entity: ServiceEntity, i: number, a: ServiceEntities) => {
			a[i] = modifyEntity<ServiceEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<ServiceEntities, WriteServicesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'services'
	);

	let entities: ServiceEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<ServiceEntities, ServiceHelper>(
		ctx.lead.service,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.service.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
