import { Localize, translations } from '../../locales';
import { NewOrderCardProps } from '../../../lead/ui/containers/Order/NewOrderCard';

export type NewOrderCardConfig = Localize<NewOrderCardProps>;

export const newOrderCardConfig: NewOrderCardConfig = {
	en: {
		title: 'Order Lead Retrieval Device',
		instructions:
			'If you world like to order lead retrieval devices for an event or add additional devices to an existing event please click the button below.',
		newOrderButtonText: 'New Order'
	},
	fr: {
		title: 'Commandez un dispositif de récupération de plomb',
		instructions:
			'Si vous souhaitez commander des appareils de récupération de plomb pour un événement ou ajouter des appareils supplémentaires à un événement existant, veuillez cliquer sur le bouton ci-dessous.',
		newOrderButtonText: 'Nouvel ordre'
	}
};
