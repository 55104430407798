import React from 'react';
import { IonLabel, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useCtx } from '../../../../config/hooks';
import {
	LeadEntity,
	EventContactEntity,
	UserEntity,
	isContactIndividualEntity
} from '../../../store';

export interface LeadListItemProps {
	lead: LeadEntity;
}

export const LeadListItem: React.FC<LeadListItemProps> = props => {
	const ctx = useCtx<LeadListItemProps>({}); // TO DO: create and set (qualifierListConfig)
	const {
		config: [config]
	} = ctx;

	const contact: EventContactEntity | undefined = ctx.lead.eventContact.get(
		props.lead.contactId
	);
	const user: UserEntity | undefined = ctx.lead.user.get(
		props.lead.i_.created?.by ?? ''
	);

	const selectLead = () => {
		ctx.lead.lead.set(props.lead.id);
	};

	return (
		<IonItem routerLink="/lead-service/lead/" onClick={selectLead} button>
			<IonGrid>
				<IonRow>
					<IonCol size="4" sizeMd="4">
						<IonLabel>
							<h2>{ctx.lead.eventContact.getName(contact)}</h2>
							{contact && isContactIndividualEntity(contact) && (
								<p>{contact?.organization}</p>
							)}
						</IonLabel>
					</IonCol>
					<IonCol size="4" sizeMd="4">
						<IonLabel>
							<h2>{contact?.email}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="4" sizeMd="4">
						<h2>Scanned by: {user?.name}</h2>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
