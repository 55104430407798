import {
	OrderCollection,
	OrderCollectionState,
	OrderId,
	OrderEntity_Some,
	OrderEntityPatch_Some,
	OrderId_Some,
	orderCollectionDefault,
	orderCollectionMutateIdOpts
} from '../collections/Order';
import * as actions from '../actions/Order';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Order reducer
 *
 * @export
 * @param {OrderCollection} [state=orderCollectionDefault]
 * @param {OrderActionTypes} action
 * @returns
 */
export function orderReducer(
	state: OrderCollection = orderCollectionDefault,
	action: actions.OrderActionTypes
) {
	switch (action.type) {
		case actions.ORDER_MUTATE_ID:
			return collectionReducerActions.mutateId<OrderCollection>(
				state,
				action.payload,
				orderCollectionMutateIdOpts
			);
		case actions.ORDER_SET:
			return collectionReducerActions.set<OrderCollection, OrderId>(
				state,
				action.payload
			);
		case actions.ORDER_TOGGLE:
			return collectionReducerActions.toggle<OrderCollection, OrderId>(
				state,
				action.payload
			);
		case actions.ORDER_SELECT:
			return collectionReducerActions.select<OrderCollection, OrderId_Some>(
				state,
				action.payload
			);
		case actions.ORDER_DESELECT:
			return collectionReducerActions.deselect<OrderCollection, OrderId_Some>(
				state,
				action.payload
			);
		case actions.ORDER_UPSERT:
			return collectionReducerActions.upsert<OrderCollection, OrderEntity_Some>(
				state,
				action.payload
			);
		case actions.ORDER_PATCH:
			return collectionReducerActions.patch<
				OrderCollection,
				OrderEntityPatch_Some
			>(state, action.payload);
		case actions.ORDER_CHANGE:
			return collectionReducerActions.change<
				OrderCollection,
				OrderEntityPatch_Some
			>(state, action.payload);
		case actions.ORDER_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				OrderCollection,
				OrderId_Some
			>(state, action.payload);
		case actions.ORDER_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				OrderCollection,
				OrderId_Some
			>(state, action.payload);
		case actions.ORDER_CACHE:
			return collectionReducerActions.cache<OrderCollection, OrderId_Some>(
				state,
				action.payload
			);
		case actions.ORDER_UNCACHE:
			return collectionReducerActions.uncache<OrderCollection, OrderId_Some>(
				state,
				action.payload
			);
		case actions.ORDER_SETSTATE:
			return collectionReducerActions.setState<
				OrderCollection,
				OrderCollectionState
			>(state, action.payload.state);
		case actions.ORDER_PATCHSTATE:
			return collectionReducerActions.patchState<
				OrderCollection,
				OrderCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
