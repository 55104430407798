import { Icon, Component } from './';

export interface Menu {
	id: string;
	divider?: MenuDivider;
	icon?: Icon;
	title?: string;
	url?: string;
	onClick?: () => void;
	component?: Component;
	security?: string[];
	tags?: string[];
}

export enum MenuDisplayType {
	Overlay = 'overlay',
	Reveal = 'reveal',
	Push = 'push'
}

export enum MenuDisplaySideType {
	Start = 'start',
	End = 'end'
}

export interface MenuDivider {
	slot?: string;
	label?: string;
	color?: string;
	sticky?: boolean;
}
