// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * LICENSE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { LicenseHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { LicenseEntity, LicenseEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';
import { LicenseServiceType, LicenseType } from '../models';

export enum LicenseApiOperation {
	readLicenses = 'readLicenses',
	readLicensesAll = 'readLicensesAll',
	writeLicenses = 'writeLicenses'
}

export interface ReadLicensesRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
}

export interface ReadLicensesAllRequest {
	userId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	orderIds?: string[];
	contextIds?: string[];
	serviceIds?: string[];
	userIds?: string[];
	deviceIds?: string[];
	licenseType?: LicenseType;
	licenseServiceType?: LicenseServiceType;
}

export interface WriteLicensesRequest {
	userId: string;
	licenses: LicenseEntities;
}

/**
 * Read licenses
 */
export async function readLicenses(
	ctx: UseCtx<any>,
	requestParameters: ReadLicensesRequest
): Promise<LicenseEntities> {
	const operationId = LicenseApiOperation.readLicenses,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<LicenseEntities, ReadLicensesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: LicenseEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LicenseEntities, LicenseHelper>(
		ctx.lead.license,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.license.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Read licenses all
 */
export async function readLicensesAll(
	ctx: UseCtx<any>,
	requestParameters: ReadLicensesAllRequest
): Promise<LicenseEntities> {
	const operationId = LicenseApiOperation.readLicensesAll,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<LicenseEntities, ReadLicensesAllRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: LicenseEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LicenseEntities, LicenseHelper>(
		ctx.lead.license,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.license.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Licenses
 */
export async function writeLicenses<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteLicensesRequest
): Promise<LicenseEntities> {
	const operationId = LicenseApiOperation.writeLicenses,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.licenses.forEach(
		(entity: LicenseEntity, i: number, a: LicenseEntities) => {
			a[i] = modifyEntity<LicenseEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<LicenseEntities, WriteLicensesRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'licenses'
	);

	let entities: LicenseEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<LicenseEntities, LicenseHelper>(
		ctx.lead.license,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.license.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
