import React from 'react';

//DATA
import { ServiceEntity, QualifierSelection } from '../../store';
import { useCtx } from '../../../config/hooks';
import { Barcode } from '../../../app/ui/components/Barcode';
import uuid from '../../../app/utils/uuid';
import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';

const AdminQuestionsSheetView: React.FC<any> = viewProps => {
	const ctx = useCtx<{}>({});
	const {
		lead: { activeService, activeEvent, qualifierValueHelper }
	} = ctx;

	const currentService: ServiceEntity | undefined = activeService;
	if (!currentService || !activeEvent) return <ServiceNotFoundCard />;

	let allQualifiers = ctx.lead.qualifier.allByServiceIdAndEventIds(
		currentService.id || '',
		[activeEvent.id || '']
	);

	return (
		<div style={{ margin: '20px' }}>
			<div style={{ margin: '10px' }}>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						display: 'inline-block'
					}}
				>
					<h2>{currentService.name}</h2>
				</div>
			</div>
			{allQualifiers.map(q => {
				if (q.selection === QualifierSelection.Multiple) {
					return (
						<>
							<div style={{ marginBottom: '60px' }}>
								<div
									style={{
										width: '30%',
										display: 'inline-block',
										verticalAlign: 'text-top',
										float: 'left'
									}}
								>
									{q.name}
								</div>
								<div
									style={{
										width: '70%',
										display: 'inline-block'
									}}
								>
									{qualifierValueHelper.allByQualifierId(q.id).map(qv => {
										return (
											<div>
												<span>{qv.name}</span>
												<br />
												<Barcode
													data={'Q%' + uuid().compress(qv.id || '')}
													width="300px"
													size={4}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</>
					);
				}
			})}
		</div>
	);
};

export default AdminQuestionsSheetView;
