import { Localize, translations } from '../../locales';
import { EventCardConfigProps } from '../../../lead/ui/components/Event/EventCard';

export type EventCardConfig = Localize<EventCardConfigProps>;

export const eventCardConfig: EventCardConfig = {
	en: {
		label: {
			information: 'Information',
			totalLeads: 'Total Leads',
			devicesUsed: 'Devices Used',
			devicesSeparator: ' of '
		},
		buttonText: {
			viewEvent: 'View Leads'
		}
	},
	fr: {
		label: {
			information: 'Informations',
			totalLeads: 'Total des pistes',
			devicesUsed: 'Appareils utilisés',
			devicesSeparator: '/'
		},
		buttonText: {
			viewEvent: 'Vue'
		}
	}
};
