import {
	DeviceCollection,
	DeviceCollectionState,
	DeviceId,
	DeviceEntity_Some,
	DeviceEntityPatch_Some,
	DeviceId_Some,
	deviceCollectionDefault,
	deviceCollectionMutateIdOpts
} from '../collections/Device';
import * as actions from '../actions/Device';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Device reducer
 *
 * @export
 * @param {DeviceCollection} [state=deviceCollectionDefault]
 * @param {DeviceActionTypes} action
 * @returns
 */
export function deviceReducer(
	state: DeviceCollection = deviceCollectionDefault,
	action: actions.DeviceActionTypes
) {
	switch (action.type) {
		case actions.DEVICE_MUTATE_ID:
			return collectionReducerActions.mutateId<DeviceCollection>(
				state,
				action.payload,
				deviceCollectionMutateIdOpts
			);
		case actions.DEVICE_SET:
			return collectionReducerActions.set<DeviceCollection, DeviceId>(
				state,
				action.payload
			);
		case actions.DEVICE_TOGGLE:
			return collectionReducerActions.toggle<DeviceCollection, DeviceId>(
				state,
				action.payload
			);
		case actions.DEVICE_SELECT:
			return collectionReducerActions.select<DeviceCollection, DeviceId_Some>(
				state,
				action.payload
			);
		case actions.DEVICE_DESELECT:
			return collectionReducerActions.deselect<DeviceCollection, DeviceId_Some>(
				state,
				action.payload
			);
		case actions.DEVICE_UPSERT:
			return collectionReducerActions.upsert<
				DeviceCollection,
				DeviceEntity_Some
			>(state, action.payload);
		case actions.DEVICE_PATCH:
			return collectionReducerActions.patch<
				DeviceCollection,
				DeviceEntityPatch_Some
			>(state, action.payload);
		case actions.DEVICE_CHANGE:
			return collectionReducerActions.change<
				DeviceCollection,
				DeviceEntityPatch_Some
			>(state, action.payload);
		case actions.DEVICE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				DeviceCollection,
				DeviceId_Some
			>(state, action.payload);
		case actions.DEVICE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				DeviceCollection,
				DeviceId_Some
			>(state, action.payload);
		case actions.DEVICE_CACHE:
			return collectionReducerActions.cache<DeviceCollection, DeviceId_Some>(
				state,
				action.payload
			);
		case actions.DEVICE_UNCACHE:
			return collectionReducerActions.uncache<DeviceCollection, DeviceId_Some>(
				state,
				action.payload
			);
		case actions.DEVICE_SETSTATE:
			return collectionReducerActions.setState<
				DeviceCollection,
				DeviceCollectionState
			>(state, action.payload.state);
		case actions.DEVICE_PATCHSTATE:
			return collectionReducerActions.patchState<
				DeviceCollection,
				DeviceCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
