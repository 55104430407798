import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getMenuCollection,
	MenuId,
	MenuIds,
	MenuId_Some,
	MenuEntity,
	MenuEntities,
	MenuEntity_Some,
	MenuEntityPatch_Some,
	MenuCollection,
	MenuCollectionState,
	IMenuActions,
	menuActions,
	MenuActionTypes
} from '..';

/**
 * Menu helper interface
 *
 * @export
 * @interface IMenuHelper
 * @extends {IEntityHelper}
 */
export interface IMenuHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
}

/**
 * Menu helper options interface
 *
 * @export
 * @interface MenuHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface MenuHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const menuHelperOpts: MenuHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Menu helper
 *
 * @export
 * @class MenuHelper
 * @extends {EntityHelper<MenuCollection, MenuActionTypes, MenuActions, MenuEntity, MenuEntities, MenuEntity_Some, MenuEntityPatch_Some, MenuId, MenuIds, MenuId_Some, MenuCollectionState, MenuHelperOpts>}
 * @implements {IMenuHelper}
 */
export class MenuHelper
	extends EntityHelper<
		MenuCollection,
		MenuActionTypes,
		IMenuActions,
		MenuEntity,
		MenuEntities,
		MenuEntity_Some,
		MenuEntityPatch_Some,
		MenuId,
		MenuIds,
		MenuId_Some,
		MenuCollectionState,
		MenuHelperOpts
	>
	implements IMenuHelper {
	constructor() {
		super(
			useSelector(getMenuCollection),
			menuActions,
			useDispatch(),
			menuHelperOpts
		);
		this.collection = useSelector(getMenuCollection);
		this.dispatch = useDispatch();
	}
}
