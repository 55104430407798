import ActivateView from './ActivateView';
import DevicesView from './DevicesView';
import LicensesView from './LicensesView';
import LicenseView from './LicenseView';
import EntityAdminView from './EntityAdminView';
import EventsView from './EventsView';
import EventView from './EventView';
import LeadAppView from './LeadAppView';
import LeadsView from './LeadsView';
import LeadView from './LeadView';
import MainView from './MainView';
import OrdersView from './OrdersView';
import QualifiersView from './QualifiersView';
import QualifierView from './QualifierView';
import ReportsView from './ReportsView';
import ServicesView from './ServicesView';
import ServiceView from './ServiceView';
import TicketsView from './TicketsView';
import UsersView from './UsersView';
import UserView from './UserView';
import SplashView from './SplashView';
import ReverseLeadsView from './ReverseLeadsView';

import AdminServicesView from './AdminServicesView';
import AdminEventsView from './AdminEventsView';
import AdminEventView from './AdminEventView';
import AdminUsersView from './AdminUsersView';
import AdminOrdersView from './AdminOrdersView';
import AdminOrderView from './AdminOrderView';
import AdminScannerView from './AdminScannerView';
import AdminServiceView from './AdminServiceView';
import AdminSignoutSheetView from './AdminSignoutSheetView';
import AdminQuestionsSheetView from './AdminQuestionsSheetView';
import AdminSignoutListView from './AdminSignoutListView';
import AdminPrintLicensesView from './AdminPrintLicensesView';

const views: { [key: string]: React.FC } = {
	ActivateView,
	DevicesView,
	LicensesView,
	LicenseView,
	EntityAdminView,
	EventsView,
	EventView,
	LeadAppView,
	LeadsView,
	LeadView,
	MainView,
	OrdersView,
	QualifiersView,
	QualifierView,
	ReportsView,
	ServicesView,
	ServiceView,
	TicketsView,
	UsersView,
	UserView,
	SplashView,
	ReverseLeadsView,
	AdminServicesView,
	AdminEventsView,
	AdminUsersView,
	AdminOrdersView,
	AdminOrderView,
	AdminEventView,
	AdminScannerView,
	AdminServiceView,
	AdminSignoutSheetView,
	AdminQuestionsSheetView,
	AdminSignoutListView,
	AdminPrintLicensesView
};

export default views;
