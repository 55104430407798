import {
	NoteCollection,
	NoteCollectionState,
	NoteId,
	NoteEntity_Some,
	NoteEntityPatch_Some,
	NoteId_Some,
	noteCollectionDefault,
	noteCollectionMutateIdOpts
} from '../collections/Note';
import * as actions from '../actions/Note';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Note reducer
 *
 * @export
 * @param {NoteCollection} [state=noteCollectionDefault]
 * @param {NoteActionTypes} action
 * @returns
 */
export function noteReducer(
	state: NoteCollection = noteCollectionDefault,
	action: actions.NoteActionTypes
) {
	switch (action.type) {
		case actions.NOTE_MUTATE_ID:
			return collectionReducerActions.mutateId<NoteCollection>(
				state,
				action.payload,
				noteCollectionMutateIdOpts
			);
		case actions.NOTE_SET:
			return collectionReducerActions.set<NoteCollection, NoteId>(
				state,
				action.payload
			);
		case actions.NOTE_TOGGLE:
			return collectionReducerActions.toggle<NoteCollection, NoteId>(
				state,
				action.payload
			);
		case actions.NOTE_SELECT:
			return collectionReducerActions.select<NoteCollection, NoteId_Some>(
				state,
				action.payload
			);
		case actions.NOTE_DESELECT:
			return collectionReducerActions.deselect<NoteCollection, NoteId_Some>(
				state,
				action.payload
			);
		case actions.NOTE_UPSERT:
			return collectionReducerActions.upsert<NoteCollection, NoteEntity_Some>(
				state,
				action.payload
			);
		case actions.NOTE_PATCH:
			return collectionReducerActions.patch<
				NoteCollection,
				NoteEntityPatch_Some
			>(state, action.payload);
		case actions.NOTE_CHANGE:
			return collectionReducerActions.change<
				NoteCollection,
				NoteEntityPatch_Some
			>(state, action.payload);
		case actions.NOTE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<NoteCollection, NoteId_Some>(
				state,
				action.payload
			);
		case actions.NOTE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				NoteCollection,
				NoteId_Some
			>(state, action.payload);
		case actions.NOTE_CACHE:
			return collectionReducerActions.cache<NoteCollection, NoteId_Some>(
				state,
				action.payload
			);
		case actions.NOTE_UNCACHE:
			return collectionReducerActions.uncache<NoteCollection, NoteId_Some>(
				state,
				action.payload
			);
		case actions.NOTE_SETSTATE:
			return collectionReducerActions.setState<
				NoteCollection,
				NoteCollectionState
			>(state, action.payload.state);
		case actions.NOTE_PATCHSTATE:
			return collectionReducerActions.patchState<
				NoteCollection,
				NoteCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
