// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * QUALIFIERVALUE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { QualifierValueHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { QualifierValueEntity, QualifierValueEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum QualifierValueApiOperation {
	readQualifierValues = 'readQualifierValues',
	writeQualifierValues = 'writeQualifierValues'
}

export interface ReadQualifierValuesRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	qualifierIds?: string[];
	ids?: string[];
}

export interface WriteQualifierValuesRequest {
	userId: string;
	serviceId: string;
	qualifierValues: QualifierValueEntities;
}

/**
 * Read qualifierValues
 */
export async function readQualifierValues(
	ctx: UseCtx<any>,
	requestParameters: ReadQualifierValuesRequest
): Promise<QualifierValueEntities> {
	const operationId = QualifierValueApiOperation.readQualifierValues,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<
		QualifierValueEntities,
		ReadQualifierValuesRequest
	>(ctx, config.apiId, operationId, requestParameters);

	let entities: QualifierValueEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<QualifierValueEntities, QualifierValueHelper>(
		ctx.lead.qualifierValue,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.qualifierValue.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write QualifierValues
 */
export async function writeQualifierValues<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteQualifierValuesRequest
): Promise<QualifierValueEntities> {
	const operationId = QualifierValueApiOperation.writeQualifierValues,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.qualifierValues.forEach(
		(entity: QualifierValueEntity, i: number, a: QualifierValueEntities) => {
			a[i] = modifyEntity<QualifierValueEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<
		QualifierValueEntities,
		WriteQualifierValuesRequest
	>(ctx, config.apiId, operationId, requestParameters, 'qualifierValues');

	let entities: QualifierValueEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<QualifierValueEntities, QualifierValueHelper>(
		ctx.lead.qualifierValue,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.qualifierValue.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
