import { AuthEntities } from '../../app/store/collections/Auth';

export interface AuthConfig {
	entities: AuthEntities;
	activeId?: string;
}

export const authConfig: AuthConfig = {
	entities: [
		{
			id: '0acgCpFOLCxKpomAM0eARV36x31LwKDb',
			name: 'Application - MyLeads Portal',
			url: 'https://auth.conexsys.com/oauth/token',
			domain: 'conexsys.auth0.com',
			callbackUrl:
				window.location.protocol + '//' + window.location.host + '/authorize',
			logoutUrl: window.location.protocol + '//' + window.location.host,
			audience: 'https://conexsys.auth0.com/userinfo',
			responseType: 'token id_token',
			scope:
				'offline_access openid profile email name given_name family_name locale'
		}
	]
};
