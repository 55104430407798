import React, { useState } from 'react';
import { BrowserQRCodeReader, BrowserCodeReader } from '@zxing/browser';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonItem,
	IonInput,
	IonButton,
	IonIcon,
	IonLabel
} from '@ionic/react';
import { scanOutline } from 'ionicons/icons';
export interface LeadScanProps {
	title?: string;
	finish?: any;
}

export const LeadScan: React.FC<LeadScanProps> = props => {
	const [scanValue, setScanValue] = useState('');

	const openScanner = async () => {
		//const data = await BarcodeScanner.scan();
		//setScanValue(data.text)
		//console.log(`Barcode data: ${data.text}`);

		const codeReader = new BrowserQRCodeReader();

		const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();

		// choose your media device (webcam, frontal camera, back camera, etc.)
		const selectedDeviceId = videoInputDevices[0].deviceId;

		console.log(`Started decode from camera with id ${selectedDeviceId}`);

		const previewElem = document.querySelector(
			'#test-area-qr-code-webcam > video'
		);

		// you can use the controls to stop() the scan or switchTorch() if available
		const controls = await codeReader.decodeFromVideoDevice(
			selectedDeviceId,
			previewElem as any,
			(result, error, controls) => {
				// use the result and error values to choose your actions
				// you can also use controls API in this scope like the controls
				// returned from the method.
				if (result) {
					console.log(result);
					setScanValue(result?.getText() || 'unknown');
				}
				if (error) console.log(error.message || error.toString());
			}
		);

		// stops scanning after 20 seconds
		setTimeout(() => controls.stop(), 20000);
	};

	return (
		<IonCard>
			{props.title && (
				<IonCardHeader>
					<IonCardTitle>{props.title}</IonCardTitle>
				</IonCardHeader>
			)}
			<IonCardContent>
				<IonList>
					<IonItem>
						<IonInput slot="start" name="code" value={scanValue} />
						<IonButton slot="end" onClick={openScanner}>
							<IonIcon icon={scanOutline} style={{ paddingRight: '15px' }} />
							<IonLabel>Scan Lead</IonLabel>
						</IonButton>
					</IonItem>
					<IonItem>
						<div id="test-area-qr-code-webcam">
							<video></video>
						</div>
					</IonItem>
				</IonList>
			</IonCardContent>
		</IonCard>
	);
};
