import { EventHelper } from '..';
import { EventEntity } from '../collections';

export interface EventHook {
	eventHelper: EventHelper;
	activeEvent: EventEntity | undefined;
}

export const useEvent = (): EventHook => {
	let eventHelper = new EventHelper();
	return {
		eventHelper,
		activeEvent: eventHelper.active()
	};
};
