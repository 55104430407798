import * as storage from '../../../storage';
import { collectionDefault } from '../../../storage/constants';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { leadConfig } from '../../../config/lead/Lead';
import { Lead } from '../models';
import { CollectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Lead entity interface
 *
 * @export
 * @interface LeadEntity
 * @extends {storage.Entity}
 * @extends {Lead}
 */
export interface LeadEntity extends storage.Entity, Lead {}

/**
 * Lead entities array
 *
 * @export
 */
export type LeadEntities = LeadEntity[];

/**
 * Some lead entities: one entity or an array of entities
 *
 * @export
 */
export type LeadEntity_Some = LeadEntity | LeadEntities;

/**
 * Lead entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface LeadEntityPatch
 * @extends {storage.EntityPatch<LeadEntity>}
 */
export type LeadEntityPatch = storage.EntityPatcher<LeadEntity>;

/**
 * Lead entity patches array
 *
 * @export
 */
export type LeadEntitiesPatch = LeadEntityPatch[];

/**
 * Some lead entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type LeadEntityPatch_Some = LeadEntityPatch | LeadEntitiesPatch;

/**
 * Lead entity id
 *
 * @export
 */
export type LeadId = storage.EntityId;

/**
 * Lead entity ids
 *
 * @export
 */
export type LeadIds = storage.EntityIds;

/**
 * Some lead entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type LeadId_Some = LeadId | LeadIds;

/**
 * Lead entities object by id
 *
 * @export
 * @interface LeadEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface LeadEntitiesObject extends storage.EntitiesObject {
	[id: string]: LeadEntity;
}

/**
 * Lead entity state
 *
 * @export
 * @interface LeadEntityState
 * @extends {storage.EntityState}
 */
export interface LeadEntityState extends storage.EntityState {}

/**
 * Lead store collection
 *
 * @export
 * @interface LeadStoreCollection
 * @extends {storage.Store}
 */
export interface LeadStoreCollection extends storage.Store {
	lead: LeadCollection;
}

/**
 * Lead collection properties
 *
 * @export
 * @interface LeadCollection
 * @extends {Collection}
 */
export interface LeadCollection extends storage.Collection {
	byIds: LeadEntitiesObject;
	mutation: LeadEntitiesObject;
	cache: LeadEntitiesObject;
	state: LeadCollectionState;
	//customId: storage.EntityId;
	//customIds: storage.EntityIds;
	//customByIds: LeadEntitiesObject;
}

/**
 * Lead collection state
 *
 * @export
 * @interface LeadCollectionState
 * @extends {storage.CollectionState}
 */
export interface LeadCollectionState extends storage.CollectionState {}

// custom lead collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const leadCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const leadCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const leadCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const leadCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: leadCollectionIdStringProps,
	idArrayProps: leadCollectionIdArrayProps,
	idEntityProps: leadCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Lead collection default values
 *
 * @export
 * @constant
 */
export const leadCollectionDefault: LeadCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as LeadCollection),
		...{
			activeId: leadConfig.activeId || leadConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	leadConfig.entities
);

/**
 * Lead store collection default values
 *
 * @export
 * @constant
 */
export const leadStoreCollectionDefault: LeadStoreCollection = {
	lead: leadCollectionDefault
};

/**
 * Lead store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getLeadCollection = (state: any) => state.lead.lead;
