import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { serviceConfig } from '../../../config/lead/Service';
import { Service } from '../models';

/**
 * Service entity interface
 *
 * @export
 * @interface ServiceEntity
 * @extends {storage.Entity}
 */
export interface ServiceEntity extends storage.Entity, Service {}

/**
 * Service entities array
 *
 * @export
 */
export type ServiceEntities = ServiceEntity[];

/**
 * Some service entities: one entity or an array of entities
 *
 * @export
 */
export type ServiceEntity_Some = ServiceEntity | ServiceEntities;

/**
 * Service entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ServiceEntityPatch
 * @extends {storage.EntityPatch<ServiceEntity>}
 */
export type ServiceEntityPatch = storage.EntityPatcher<ServiceEntity>;

/**
 * Service entity patches array
 *
 * @export
 */
export type ServiceEntitiesPatch = ServiceEntityPatch[];

/**
 * Some service entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ServiceEntityPatch_Some = ServiceEntityPatch | ServiceEntitiesPatch;

/**
 * Service entity id
 *
 * @export
 */
export type ServiceId = storage.EntityId;

/**
 * Service entity ids
 *
 * @export
 */
export type ServiceIds = storage.EntityIds;

/**
 * Some service entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ServiceId_Some = ServiceId | ServiceIds;

/**
 * Service entities object by id
 *
 * @export
 * @interface ServiceEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ServiceEntitiesObject extends storage.EntitiesObject {
	[id: string]: ServiceEntity;
}

/**
 * Service entity state
 *
 * @export
 * @interface ServiceEntityState
 * @extends {storage.EntityState}
 */
export interface ServiceEntityState extends storage.EntityState {}

/**
 * Service store collection
 *
 * @export
 * @interface ServiceStoreCollection
 * @extends {storage.Store}
 */
export interface ServiceStoreCollection extends storage.Store {
	service: ServiceCollection;
}

/**
 * Service collection properties
 *
 * @export
 * @interface ServiceCollection
 * @extends {Collection}
 */
export interface ServiceCollection extends storage.Collection {
	byIds: ServiceEntitiesObject;
	mutation: ServiceEntitiesObject;
	cache: ServiceEntitiesObject;
	state: ServiceCollectionState;
}

/**
 * Service collection state
 *
 * @export
 * @interface ServiceCollectionState
 * @extends {storage.CollectionState}
 */
export interface ServiceCollectionState extends storage.CollectionState {}

// custom service collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const serviceCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const serviceCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const serviceCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const serviceCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: serviceCollectionIdStringProps,
	idArrayProps: serviceCollectionIdArrayProps,
	idEntityProps: serviceCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Service collection default values, initialize collection with service entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const serviceCollectionDefault: ServiceCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ServiceCollection),
		...{
			activeId: serviceConfig.activeId || serviceConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	serviceConfig.entities
);

/**
 * Service store collection default values
 *
 * @export
 * @constant
 */
export const serviceStoreCollectionDefault: ServiceStoreCollection = {
	service: serviceCollectionDefault
};

/**
 * Service store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getServiceCollection = (state: any) => state.lead.service;
