// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LicenseServiceType {
    LeadApp = 'lead-app',
    LeadApi = 'lead-api',
    Opticon = 'opticon',
    Sst = 'sst'
}

/**
* @export
* @const {string[]}
*/
export const valuesLicenseServiceType = [
    'lead-app',
    'lead-api',
    'opticon',
    'sst'
];

