import React from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonLabel,
	IonCheckbox,
	IonRadio,
	IonRadioGroup
} from '@ionic/react';

import {
	QualifierEntity,
	ResponseEntity,
	QualifierValueEntity,
	ObjectType,
	LeadEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import _ from 'lodash';
import { QualifierSelection } from '../../../store/models/QualifierSelection';
import { newEntity } from '../../../../app/utils';

export interface LeadQualifierValueResponseListConfigProps {
	lead: LeadEntity;
	qualifier: QualifierEntity;
	qualifierValueList?: QualifierValueEntity[];
	responses?: ResponseEntity[];
}

export const LeadQualifierValueResponseList: React.FC<LeadQualifierValueResponseListConfigProps> = props => {
	const ctx = useCtx<LeadQualifierValueResponseListConfigProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config],
		lead: { responseHelper, qualifierValueHelper }
	} = ctx;

	const responses = responseHelper.allByLead(props.lead);

	const onResponseChange = (e: any) => {
		let qualifierValue = e.target.id
			? qualifierValueHelper.get(e.target.id)
			: undefined;

		if (qualifierValue && props.lead) {
			let isSelected =
				!('checked' in e.target) || e.target.checked ? e.target.value : false;

			//console.log(isSelected);

			if (props.qualifier.selection == QualifierSelection.Single) {
				let leadResponse = responses.find(
					response => response.qualifierId == props.qualifier.id
				);

				if (isSelected && !leadResponse) {
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							{
								...newEntity(ctx, ObjectType.Response),
								serviceId: props.lead.serviceId,
								leadId: props.lead.id,
								qualifierId: props.qualifier.id,
								qualifierValueId: qualifierValue.id,
								value: 'true'
							}
						]
					});
				} else if (isSelected && leadResponse) {
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							_.set(
								_.cloneDeep(leadResponse),
								'qualifierValueId',
								qualifierValue.id
							)
						]
					});
				}
			} else if (props.qualifier.selection == QualifierSelection.Multiple) {
				let leadResponse = responseHelper
					.allByLead(props.lead)
					.find(response => response.qualifierValueId == qualifierValue?.id);
				if (leadResponse) {
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							{
								...newEntity(ctx, ObjectType.Response),
								id: leadResponse?.id,
								serviceId: props.lead.serviceId,
								leadId: props.lead.id,
								qualifierId: props.qualifier.id,
								qualifierValueId: qualifierValue.id,
								value: e.target.checked ? 'true' : 'false'
							}
						]
					});
				} else {
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							{
								...newEntity(ctx, ObjectType.Response),
								serviceId: props.lead.serviceId,
								leadId: props.lead.id,
								qualifierId: props.qualifier.id,
								qualifierValueId: qualifierValue.id,
								value: e.target.checked ? 'true' : 'false'
							}
						]
					});
				}
				/*if (isSelected && !leadResponse) {
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							{
								...newEntity(ctx, ObjectType.Response),
								serviceId: props.lead.serviceId,
								leadId: props.lead.id,
								qualifierId: props.qualifier.id,
								qualifierValueId: qualifierValue.id,
								value: 'true'
							}
						]
					});
				} else if (!isSelected && leadResponse) {
					//responseHelper.writeDelete(ctx, leadResponse.id);
					responseHelper.write(ctx, {
						serviceId: props.lead.serviceId,
						responses: [
							{
								...newEntity(ctx, ObjectType.Response),
								serviceId: props.lead.serviceId,
								leadId: props.lead.id,
								qualifierId: props.qualifier.id,
								qualifierValueId: qualifierValue.id,
								value: 'false'
							}
						]
					});
				}*/
			}
		}
	};

	const sortQualifierValues = (
		a: QualifierValueEntity,
		b: QualifierValueEntity
	) => {
		return (a.position as number) > (b.position as number)
			? 1
			: (b.position as number) > (a.position as number)
			? -1
			: 0;
	};

	const qualifierValuesList = props.qualifierValueList
		?.sort(sortQualifierValues)
		.map((qualifierValue: QualifierValueEntity) => {
			let leadResponse:
				| ResponseEntity
				| undefined = ctx.lead.response
				.allByLead(props.lead)
				.find(response => response.qualifierValueId == qualifierValue?.id);

			return props.qualifier.selection == QualifierSelection.Single ? (
				<IonRow>
					<IonCol>
						<IonItem key={qualifierValue.id} lines="none">
							<IonLabel style={{ opacity: 1 }}>{qualifierValue.name}:</IonLabel>
							<IonRadio
								id={qualifierValue.id}
								value={qualifierValue.id}
								disabled={true}
								onClick={onResponseChange}
							></IonRadio>
						</IonItem>
					</IonCol>
				</IonRow>
			) : (
				<IonRow>
					<IonCol>
						<IonItem key={qualifierValue.id} lines="none">
							<IonLabel style={{ opacity: 1 }}>{qualifierValue.name}:</IonLabel>
							<IonCheckbox
								id={qualifierValue.id}
								checked={leadResponse?.value === 'true'}
								value={qualifierValue.id}
								disabled={true}
								onIonChange={e => {
									onResponseChange(e);
								}}
							></IonCheckbox>
						</IonItem>
					</IonCol>
				</IonRow>
			);
		});

	return props.qualifier.selection == QualifierSelection.Single ? (
		<IonGrid>
			<IonRadioGroup
				key={props.qualifier.id}
				value={
					props.responses?.find(x => x.qualifierId == props.qualifier.id)
						?.qualifierValueId
				}
			>
				{qualifierValuesList}
			</IonRadioGroup>
		</IonGrid>
	) : (
		<IonGrid key={props.qualifier.id}>{qualifierValuesList}</IonGrid>
	);
};
