import {
	LicenseId_Some,
	LicenseEntity_Some,
	LicenseEntityPatch_Some,
	LicenseCollectionState
} from '../collections';
import { EntityId, EntityId_Mutation } from '../../../storage/models';
import {
	EntityActions,
	EntityAction_MutateId,
	EntityAction_Set,
	EntityAction_Toggle,
	EntityAction_Select,
	EntityAction_Deselect,
	EntityAction_Upsert,
	EntityAction_Patch,
	EntityAction_Change,
	EntityAction_ApplyChanges,
	EntityAction_CancelChanges,
	EntityAction_Cache,
	EntityAction_Uncache,
	EntityAction_PatchState,
	EntityAction_SetState
} from '../../../storage/actions';

// Action types
export const LICENSE_MUTATE_ID = '@lead/LICENSE_MUTATE_ID';
export const LICENSE_SET = '@lead/LICENSE_SET';
export const LICENSE_TOGGLE = '@lead/LICENSE_TOGGLE';
export const LICENSE_SELECT = '@lead/LICENSE_SELECT';
export const LICENSE_DESELECT = '@lead/LICENSE_DESELECT';
export const LICENSE_UPSERT = '@lead/LICENSE_UPSERT';
export const LICENSE_PATCH = '@lead/LICENSE_PATCH';
export const LICENSE_CHANGE = '@lead/LICENSE_CHANGE';
export const LICENSE_APPLY_CHANGES = '@lead/LICENSE_APPLY_CHANGES';
export const LICENSE_CANCEL_CHANGES = '@lead/LICENSE_CANCEL_CHANGES';
export const LICENSE_CACHE = '@lead/LICENSE_CACHE';
export const LICENSE_UNCACHE = '@lead/LICENSE_UNCACHE';
export const LICENSE_SETSTATE = '@lead/LICENSE_SETSTATE';
export const LICENSE_PATCHSTATE = '@lead/LICENSE_PATCHSTATE';

/**
 * Mutate entity id
 *
 * @interface LicenseAction_MutateId
 * @extends {EntityAction_MutateId}
 */
interface LicenseAction_MutateId extends EntityAction_MutateId {
	type: typeof LICENSE_MUTATE_ID;
	payload: EntityId_Mutation;
}

/**
 * Set active license
 *
 * @interface LicenseAction_Set
 * @extends {EntityAction_Set}
 */
export interface LicenseAction_Set extends EntityAction_Set {
	type: typeof LICENSE_SET;
	payload?: EntityId;
}

/**
 * Toggle active license
 *
 * @interface LicenseAction_Toggle
 * @extends {EntityAction_Toggle}
 */
export interface LicenseAction_Toggle extends EntityAction_Toggle {
	type: typeof LICENSE_TOGGLE;
	payload?: EntityId;
}

/**
 * Select license entities
 *
 * @interface LicenseAction_Select
 * @extends {EntityAction_Select}
 */
interface LicenseAction_Select extends EntityAction_Select {
	type: typeof LICENSE_SELECT;
	payload: LicenseId_Some;
}

/**
 * Deselect license entities
 *
 * @interface LicenseAction_Deselect
 * @extends {EntityAction_Deselect}
 */
interface LicenseAction_Deselect extends EntityAction_Deselect {
	type: typeof LICENSE_DESELECT;
	payload: LicenseId_Some;
}

/**
 * Upsert license entities
 *
 * @interface LicenseAction_Upsert
 * @extends {EntityAction_Upsert}
 */
interface LicenseAction_Upsert extends EntityAction_Upsert {
	type: typeof LICENSE_UPSERT;
	payload: LicenseEntity_Some;
}

/**
 * Patch license entities
 *
 * @interface LicenseAction_Patch
 * @extends {EntityAction_Upsert}
 */
interface LicenseAction_Patch extends EntityAction_Patch {
	type: typeof LICENSE_PATCH;
	payload: LicenseEntityPatch_Some;
}

/**
 * Change license entities
 *
 * @interface LicenseAction_Change
 * @extends {EntityAction_Change}
 */
interface LicenseAction_Change extends EntityAction_Change {
	type: typeof LICENSE_CHANGE;
	payload: LicenseEntityPatch_Some;
}

/**
 * Apply changes to license entities
 *
 * @interface LicenseAction_ApplyChanges
 * @extends {EntityAction_ApplyChanges}
 */
interface LicenseAction_ApplyChanges extends EntityAction_ApplyChanges {
	type: typeof LICENSE_APPLY_CHANGES;
	payload: LicenseId_Some;
}

/**
 * Cancel changes to license entities
 *
 * @interface LicenseAction_CancelChanges
 * @extends {EntityAction_CancelChanges}
 */
interface LicenseAction_CancelChanges extends EntityAction_CancelChanges {
	type: typeof LICENSE_CANCEL_CHANGES;
	payload: LicenseId_Some;
}

/**
 * Cache license entities
 *
 * @interface LicenseAction_Cache
 * @extends {EntityAction_Cache}
 */
interface LicenseAction_Cache extends EntityAction_Cache {
	type: typeof LICENSE_CACHE;
	payload: LicenseId_Some;
}

/**
 * Uncache license entities
 *
 * @interface LicenseAction_Uncache
 * @extends {EntityAction_Uncache}
 */
interface LicenseAction_Uncache extends EntityAction_Uncache {
	type: typeof LICENSE_UNCACHE;
	payload: LicenseId_Some;
}

/**
 * Set license collection / entity state
 *
 * @interface LicenseAction_SetState
 * @extends {EntityAction_SetState}
 */
interface LicenseAction_SetState extends EntityAction_SetState {
	type: typeof LICENSE_SETSTATE;
}

/**
 * PAtch license collection / entity state
 *
 * @interface LicenseAction_PatchState
 * @extends {EntityAction_PatchState}
 */
interface LicenseAction_PatchState extends EntityAction_PatchState {
	type: typeof LICENSE_PATCHSTATE;
}

/**
 * Export license action types
 *
 * @export
 */
export type LicenseActionTypes =
	| LicenseAction_MutateId
	| LicenseAction_MutateId
	| LicenseAction_Set
	| LicenseAction_Toggle
	| LicenseAction_Select
	| LicenseAction_Deselect
	| LicenseAction_Upsert
	| LicenseAction_Patch
	| LicenseAction_Change
	| LicenseAction_ApplyChanges
	| LicenseAction_CancelChanges
	| LicenseAction_Cache
	| LicenseAction_Uncache
	| LicenseAction_SetState
	| LicenseAction_PatchState;

/**
 * License actions helper interface
 *
 * @export
 * @interface ILicenseActions
 * @extends {EntityActions}
 */
export interface ILicenseActions extends EntityActions {
	//customAction(ids: LicenseId_Some): LicenseAction_CustomAction;
}

/**
 * License actions helper
 *
 * @export
 * @class LicenseActions
 * @implements {EntityBaseActions}
 */
export class LicenseActions implements ILicenseActions {
	constructor() {}

	mutateId(id: EntityId, newId: EntityId): LicenseAction_MutateId {
		return { type: LICENSE_MUTATE_ID, payload: { id, newId } };
	}

	set(id?: EntityId): LicenseAction_Set {
		return { type: LICENSE_SET, payload: id };
	}
	toggle(id?: EntityId): LicenseAction_Toggle {
		return { type: LICENSE_TOGGLE, payload: id };
	}
	select(ids: LicenseId_Some): LicenseAction_Select {
		return {
			type: LICENSE_SELECT,
			payload: ids
		};
	}
	deselect(ids: LicenseId_Some): LicenseAction_Deselect {
		return {
			type: LICENSE_DESELECT,
			payload: ids
		};
	}
	upsert(entities: LicenseEntity_Some): LicenseAction_Upsert {
		return {
			type: LICENSE_UPSERT,
			payload: entities
		};
	}
	patch(entities: LicenseEntityPatch_Some): LicenseAction_Patch {
		return {
			type: LICENSE_PATCH,
			payload: entities
		};
	}
	change(entities: LicenseEntityPatch_Some): LicenseAction_Change {
		return {
			type: LICENSE_CHANGE,
			payload: entities
		};
	}
	applyChanges(ids: LicenseId_Some): LicenseAction_ApplyChanges {
		return {
			type: LICENSE_APPLY_CHANGES,
			payload: ids
		};
	}
	cancelChanges(ids: LicenseId_Some): LicenseAction_CancelChanges {
		return {
			type: LICENSE_CANCEL_CHANGES,
			payload: ids
		};
	}
	cache(ids: LicenseId_Some): LicenseAction_Cache {
		return {
			type: LICENSE_CACHE,
			payload: ids
		};
	}
	uncache(ids: LicenseId_Some): LicenseAction_Uncache {
		return {
			type: LICENSE_UNCACHE,
			payload: ids
		};
	}
	setState(state: LicenseCollectionState): LicenseAction_SetState {
		return {
			type: LICENSE_SETSTATE,
			payload: { state }
		};
	}
	patchState(
		statePatch: Partial<LicenseCollectionState>
	): LicenseAction_PatchState {
		return {
			type: LICENSE_PATCHSTATE,
			payload: { statePatch }
		};
	}
}

/**
 * Global instance of the license actions helper
 *
 * @export
 */
export const licenseActions = new LicenseActions();
