import { QualifierHelper } from '..';
import { QualifierEntity } from '../collections';

export interface QualifierHook {
	qualifierHelper: QualifierHelper;
	activeQualifier: QualifierEntity | undefined;
}

export const useQualifier = (): QualifierHook => {
	let qualifierHelper = new QualifierHelper();
	return {
		qualifierHelper,
		activeQualifier: qualifierHelper.active()
	};
};
