import React from 'react';

//Data
import { OrderCnxLeads } from '../../store';
import { useCtx } from '../../../config/hooks';
import moment from 'moment';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonBackButton,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonCardTitle,
	IonContent
} from '@ionic/react';

const AdminOrderView: React.FC = () => {
	const ctx = useCtx<{}>({});

	const {
		lead: { activeOrder }
	} = ctx;

	if (
		!activeOrder?.properties?.external ||
		!(activeOrder?.properties?.external as any).order[activeOrder?.code || '']
	) {
		return (
			<>
				<IonToolbar>
					<IonTitle>Admin: Order</IonTitle>
					<IonButtons slot="start">
						<IonBackButton text="Back" defaultHref="/admin/orders" />
					</IonButtons>
				</IonToolbar>
				<IonLabel>Order Not Found</IonLabel>
			</>
		);
	}

	const leadOrder: OrderCnxLeads = (activeOrder?.properties?.external as any)
		.order[activeOrder?.code || ''];

	console.log(leadOrder);

	return (
		<IonContent>
			<IonToolbar>
				<IonTitle>Admin: Order</IonTitle>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/admin/orders" />
				</IonButtons>
			</IonToolbar>

			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Order: {leadOrder?.code}</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonGrid>
						<IonRow>
							<IonCol size="12" sizeSm="2">
								<IonLabel style={{ fontWeight: 600 }}>Date:</IonLabel>
							</IonCol>
							<IonCol>
								{moment(leadOrder?.date).local().format('YYYY-MM-DD hh:mm A')}
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12" sizeSm="2">
								<IonLabel style={{ fontWeight: 600 }}>Contact:</IonLabel>
							</IonCol>
							<IonCol>
								{leadOrder?.name}
								<br />
								<br />
								{leadOrder?.contactFirstName} {leadOrder?.contactLastName}
								<br />
								{leadOrder?.email}
								<br />
								{leadOrder?.phone}
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12" sizeSm="2">
								<IonLabel style={{ fontWeight: 600 }}>Address:</IonLabel>
							</IonCol>
							<IonCol>
								{leadOrder?.address1} {leadOrder?.address2}
								<br />
								{leadOrder?.city} {leadOrder?.subdivision} {leadOrder?.country}{' '}
								{leadOrder?.postal}
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12" sizeSm="2">
								<IonLabel style={{ fontWeight: 600 }}>Transaction:</IonLabel>
							</IonCol>
							<IonCol>
								{leadOrder?.transactionCardNumber} ...
								<br />
								{leadOrder?.transactionName}
								<br />
								{leadOrder?.transactionType}
								<br />${leadOrder?.total}
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12" sizeSm="2">
								<IonLabel style={{ fontWeight: 600 }}>Order Details:</IonLabel>
							</IonCol>
							<IonCol>
								<div hidden={(leadOrder.licenseQuantityLeadApi || 0) <= 0}>
									Lead Api : {leadOrder.licenseQuantityLeadApi}
								</div>
								<div hidden={(leadOrder.licenseQuantityLeadApp || 0) <= 0}>
									Lead App : {leadOrder.licenseQuantityLeadApp}
								</div>
								<div hidden={(leadOrder.licenseQuantityOpticon || 0) <= 0}>
									Opticon : {leadOrder.licenseQuantityOpticon}
								</div>
								<div hidden={(leadOrder.licenseQuantitySst || 0) <= 0}>
									SST : {leadOrder.licenseQuantitySst}
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCardContent>
			</IonCard>
		</IonContent>
	);
};

export default AdminOrderView;
