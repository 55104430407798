import { useSelector, useDispatch } from 'react-redux';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { defaultApiOrigin } from '../../../config/app/Api';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getApiCollection,
	ApiId,
	ApiIds,
	ApiId_Some,
	ApiEntity,
	ApiEntities,
	ApiEntity_Some,
	ApiEntityPatch_Some,
	ApiCollection,
	ApiCollectionState,
	IApiActions,
	apiActions,
	ApiActionTypes
} from '..';
import moment, { Moment } from 'moment';
import { EmailMessage, SendEmailData } from '../models';
import MailMessage from 'nodemailer/lib/mailer/mail-message';
import { MailOptions } from 'nodemailer/lib/sendmail-transport';
import { UseCtx } from '../../../config/hooks';

/**
 * Api helper interface
 *
 * @export
 * @interface IApiHelper
 * @extends {IEntityHelper}
 */
export interface IApiHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
}

/**
 * Api helper options interface
 *
 * @export
 * @interface ApiHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ApiHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const apiHelperOpts: ApiHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Api helper
 *
 * @export
 * @class ApiHelper
 * @extends {EntityHelper<ApiCollection, ApiActionTypes, ApiActions, ApiEntity, ApiEntities, ApiEntity_Some, ApiEntityPatch_Some, ApiId, ApiIds, ApiId_Some, ApiCollectionState, ApiHelperOpts>}
 * @implements {IApiHelper}
 */
export class ApiHelper
	extends EntityHelper<
		ApiCollection,
		ApiActionTypes,
		IApiActions,
		ApiEntity,
		ApiEntities,
		ApiEntity_Some,
		ApiEntityPatch_Some,
		ApiId,
		ApiIds,
		ApiId_Some,
		ApiCollectionState,
		ApiHelperOpts
	>
	implements IApiHelper {
	constructor() {
		super(
			useSelector(getApiCollection),
			apiActions,
			useDispatch(),
			apiHelperOpts
		);
		this.collection = useSelector(getApiCollection);
		this.dispatch = useDispatch();
	}

	get lastDt(): string | undefined {
		return this.active()?.__state?.dt;
	}

	setDt(id: string, value: any) {
		// it its a timestamp convert it to an int
		if (!isNaN(parseInt(value))) value = parseInt(value);
		let dt = moment(value);
		if (dt.isValid()) {
			this.patch({ id, __state: { dt: dt.toISOString() } });
		}
	}

	async sendEmail(message: MailOptions): Promise<AxiosResponse | undefined> {
		try {
			let data: SendEmailData = { message };
			let response: AxiosResponse = await axios.request<any>({
				method: 'POST',
				baseURL: 'https://api.conexsys.com', // defaultApiOrigin
				url: '/function/smtp/send',
				data,
				responseType: 'json'
			});

			return response;
		} catch (e) {
			console.error(e);
			return undefined;
		}
	}
}
