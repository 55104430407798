import { QualifierValueEntities } from '../../lead/store/collections/QualifierValue';
import { QualifierValueType } from '../../lead/store/models/QualifierValueType';
import { ValueFormatString } from '../../lead/store/models/ValueFormatString';

export interface QualifierValueConfig {
	entities: QualifierValueEntities;
	activeId?: string;
}

export const qualifierValueConfig: QualifierValueConfig = {
	entities: []
};
