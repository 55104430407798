// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: support@conexsys.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Property formats for `string` values.
 * @export
 * @enum {string}
 */
export enum ValueFormatString {
	String = 'string',
	Date = 'date',
	Time = 'time',
	DateTime = 'date-time',
	Email = 'email',
	Phone = 'phone',
	Password = 'password',
	Byte = 'byte',
	Binary = 'binary'
}

/**
 * @export
 * @const {string[]}
 */
export const valuesValueFormatString = [
	'string',
	'date',
	'time',
	'date-time',
	'email',
	'phone',
	'password',
	'byte',
	'binary'
];
