import React from 'react';

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonCardSubtitle,
	IonContent
} from '@ionic/react';

import { DeviceList, DeviceServiceUsage } from './../containers/Device';
import { EventSelector } from '../../../lead/ui/components/Event/EventSelector';

const DevicesView: React.FC = () => {
	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<EventSelector />
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent></IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<DeviceServiceUsage />
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Device List</IonCardTitle>
								<IonCardSubtitle></IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<DeviceList />
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default DevicesView;
