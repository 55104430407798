import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getNoteCollection,
	NoteId,
	NoteIds,
	NoteId_Some,
	NoteEntity,
	NoteEntities,
	NoteEntity_Some,
	NoteEntityPatch_Some,
	NoteCollection,
	NoteCollectionState,
	INoteActions,
	noteActions,
	NoteActionTypes
} from '..';
import {
	readNotes,
	writeNotes,
	ReadNotesRequest,
	WriteNotesRequest,
	NoteApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { LeadEntity } from '../collections';
import { isObjectStatusActive, ObjectStatus } from '../models';

/**
 * Note helper interface
 *
 * @export
 * @interface INoteHelper
 * @extends {IEntityHelper}
 */
export interface INoteHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Note helper options interface
 *
 * @export
 * @interface NoteHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface NoteHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const noteHelperOpts: NoteHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Note helper
 *
 * @export
 * @class NoteHelper
 * @extends {EntityHelper<NoteCollection, NoteActionTypes, NoteActions, NoteEntity, NoteEntities, NoteEntity_Some, NoteEntityPatch_Some, NoteId, NoteIds, NoteId_Some, NoteCollectionState, NoteHelperOpts>}
 * @implements {INoteHelper}
 */
export class NoteHelper
	extends EntityHelper<
		NoteCollection,
		NoteActionTypes,
		INoteActions,
		NoteEntity,
		NoteEntities,
		NoteEntity_Some,
		NoteEntityPatch_Some,
		NoteId,
		NoteIds,
		NoteId_Some,
		NoteCollectionState,
		NoteHelperOpts
	>
	implements INoteHelper {
	constructor() {
		super(
			useSelector(getNoteCollection),
			noteActions,
			useDispatch(),
			noteHelperOpts
		);
		this.collection = useSelector(getNoteCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: NoteApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadNotesRequest> = {},
		callback?: any
	): Promise<NoteEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(NoteApiOperation.readNotes);

		let request: ReadNotesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: NoteEntities = await readNotes(ctx, request)
			.then((entities: NoteEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteNotesRequest> = {},
		callback?: any
	): Promise<NoteEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		if (!params.notes) return [];
		let request: WriteNotesRequest = {
			...params,
			...{
				notes: params.notes,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: NoteEntities = await writeNotes(ctx, request)
			.then((entities: NoteEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let note = this.get(id);
		if (!note) return false;

		note.i_.status = ObjectStatus.Deleted;

		let notes = await this.write(ctx, {
			notes: [note]
		});

		return notes.length > 0;
	}

	allByLead(lead: LeadEntity): NoteEntities {
		return this.all()
			.filter(note => note.leadId === lead.id)
			.filter(isObjectStatusActive);
	}
}
