import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { dataConfig } from '../../../config/lead/Data';
import { Data } from '../models';

/**
 * Data entity interface
 *
 * @export
 * @interface DataEntity
 * @extends {storage.Entity}
 */
export interface DataEntity extends storage.Entity, Data {}

/**
 * Data entities array
 *
 * @export
 */
export type DataEntities = DataEntity[];

/**
 * Some data entities: one entity or an array of entities
 *
 * @export
 */
export type DataEntity_Some = DataEntity | DataEntities;

/**
 * Data entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface DataEntityPatch
 * @extends {storage.EntityPatch<DataEntity>}
 */
export type DataEntityPatch = storage.EntityPatcher<DataEntity>;

/**
 * Data entity patches array
 *
 * @export
 */
export type DataEntitiesPatch = DataEntityPatch[];

/**
 * Some data entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type DataEntityPatch_Some = DataEntityPatch | DataEntitiesPatch;

/**
 * Data entity id
 *
 * @export
 */
export type DataId = storage.EntityId;

/**
 * Data entity ids
 *
 * @export
 */
export type DataIds = storage.EntityIds;

/**
 * Some data entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type DataId_Some = DataId | DataIds;

/**
 * Data entities object by id
 *
 * @export
 * @interface DataEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface DataEntitiesObject extends storage.EntitiesObject {
	[id: string]: DataEntity;
}

/**
 * Data entity state
 *
 * @export
 * @interface DataEntityState
 * @extends {storage.EntityState}
 */
export interface DataEntityState extends storage.EntityState {}

/**
 * Data store collection
 *
 * @export
 * @interface DataStoreCollection
 * @extends {storage.Store}
 */
export interface DataStoreCollection extends storage.Store {
	data: DataCollection;
}

/**
 * Data collection properties
 *
 * @export
 * @interface DataCollection
 * @extends {Collection}
 */
export interface DataCollection extends storage.Collection {
	byIds: DataEntitiesObject;
	mutation: DataEntitiesObject;
	cache: DataEntitiesObject;
	state: DataCollectionState;
}

/**
 * Data collection state
 *
 * @export
 * @interface DataCollectionState
 * @extends {storage.CollectionState}
 */
export interface DataCollectionState extends storage.CollectionState {}

// custom data collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const dataCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const dataCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const dataCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const dataCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: dataCollectionIdStringProps,
	idArrayProps: dataCollectionIdArrayProps,
	idEntityProps: dataCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Data collection default values, initialize collection with data entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const dataCollectionDefault: DataCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as DataCollection),
		...{
			activeId: dataConfig.activeId || dataConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	dataConfig.entities
);

/**
 * Data store collection default values
 *
 * @export
 * @constant
 */
export const dataStoreCollectionDefault: DataStoreCollection = {
	data: dataCollectionDefault
};

/**
 * Data store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getDataCollection = (state: any) => state.lead.data;
