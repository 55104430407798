import { NoteEntities } from '../../lead/store/collections/Note';

export interface NoteConfig {
	entities: NoteEntities;
	activeId?: string;
}

export const noteConfig: NoteConfig = {
	entities: []
};
