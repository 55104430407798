import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { contactConfig } from '../../../config/lead/Contact';
import { ContactIndividual, ContactOrganization, ContactType } from '../models';

/**
 * ContactIndividual entity interface
 *
 * @export
 * @interface ContactIndividualEntity
 * @extends {storage.Entity}
 */
export interface ContactIndividualEntity
	extends storage.Entity,
		ContactIndividual {}

export const isContactIndividualEntity = (
	object: ContactEntity
): object is ContactIndividualEntity => {
	return (object as ContactIndividualEntity).type === ContactType.Individual;
};

/**
 * ContactOrganization entity interface
 *
 * @export
 * @interface ContactOrganizationEntity
 * @extends {storage.Entity}
 */
export interface ContactOrganizationEntity
	extends storage.Entity,
		ContactOrganization {}

export const isContactOrganizationEntity = (
	object: ContactEntity
): object is ContactOrganizationEntity => {
	return (
		(object as ContactOrganizationEntity).type === ContactType.Organization
	);
};

export const isContactOrganizationSponsorEntity = (
	object: ContactEntity
): object is ContactOrganizationEntity => {
	return (
		(object as ContactOrganizationEntity).type === ContactType.Organization &&
		(object.properties as any)?.myconexsys?.SponsorLevelId
	);
};

/**
 * Contact entity type
 *
 * @export
 */
export type ContactEntity = ContactIndividualEntity | ContactOrganizationEntity;

/**
 * Contact entities array
 *
 * @export
 */
export type ContactEntities = ContactEntity[];

/**
 * Some contact entities: one entity or an array of entities
 *
 * @export
 */
export type ContactEntity_Some = ContactEntity | ContactEntities;

/**
 * Contact entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface ContactEntityPatch
 * @extends {storage.EntityPatch<ContactEntity>}
 */
export type ContactEntityPatch = storage.EntityPatcher<ContactEntity>;

/**
 * Contact entity patches array
 *
 * @export
 */
export type ContactEntitiesPatch = ContactEntityPatch[];

/**
 * Some contact entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type ContactEntityPatch_Some = ContactEntityPatch | ContactEntitiesPatch;

/**
 * Contact entity id
 *
 * @export
 */
export type ContactId = storage.EntityId;

/**
 * Contact entity ids
 *
 * @export
 */
export type ContactIds = storage.EntityIds;

/**
 * Some contact entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type ContactId_Some = ContactId | ContactIds;

/**
 * Contact entities object by id
 *
 * @export
 * @interface ContactEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface ContactEntitiesObject extends storage.EntitiesObject {
	[id: string]: ContactEntity;
}

/**
 * Contact entity state
 *
 * @export
 * @interface ContactEntityState
 * @extends {storage.EntityState}
 */
export interface ContactEntityState extends storage.EntityState {}

/**
 * Contact store collection
 *
 * @export
 * @interface ContactStoreCollection
 * @extends {storage.Store}
 */
export interface ContactStoreCollection extends storage.Store {
	contact: ContactCollection;
}

/**
 * Contact collection properties
 *
 * @export
 * @interface ContactCollection
 * @extends {Collection}
 */
export interface ContactCollection extends storage.Collection {
	byIds: ContactEntitiesObject;
	mutation: ContactEntitiesObject;
	cache: ContactEntitiesObject;
	state: ContactCollectionState;
}

/**
 * Contact collection state
 *
 * @export
 * @interface ContactCollectionState
 * @extends {storage.CollectionState}
 */
export interface ContactCollectionState extends storage.CollectionState {}

// custom contact collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const contactCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const contactCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const contactCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const contactCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: contactCollectionIdStringProps,
	idArrayProps: contactCollectionIdArrayProps,
	idEntityProps: contactCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Contact collection default values, initialize collection with contact entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const contactCollectionDefault: ContactCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as ContactCollection),
		...{
			activeId: contactConfig.activeId || contactConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	contactConfig.entities
);

/**
 * Contact store collection default values
 *
 * @export
 * @constant
 */
export const contactStoreCollectionDefault: ContactStoreCollection = {
	contact: contactCollectionDefault
};

/**
 * Contact store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getContactCollection = (state: any) => state.lead.contact;
