import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getDeviceCollection,
	DeviceId,
	DeviceIds,
	DeviceId_Some,
	DeviceEntity,
	DeviceEntities,
	DeviceEntity_Some,
	DeviceEntityPatch_Some,
	DeviceCollection,
	DeviceCollectionState,
	IDeviceActions,
	deviceActions,
	DeviceActionTypes
} from '..';
import {
	readDevices,
	writeDevices,
	ReadDevicesRequest,
	WriteDevicesRequest,
	DeviceApiOperation,
	readDevicesAll
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { ServiceEntity } from '../collections';
import { isObjectStatusActive } from '../models';

/**
 * Device helper interface
 *
 * @export
 * @interface IDeviceHelper
 * @extends {IEntityHelper}
 */
export interface IDeviceHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Device helper options interface
 *
 * @export
 * @interface DeviceHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface DeviceHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const deviceHelperOpts: DeviceHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Device helper
 *
 * @export
 * @class DeviceHelper
 * @extends {EntityHelper<DeviceCollection, DeviceActionTypes, DeviceActions, DeviceEntity, DeviceEntities, DeviceEntity_Some, DeviceEntityPatch_Some, DeviceId, DeviceIds, DeviceId_Some, DeviceCollectionState, DeviceHelperOpts>}
 * @implements {IDeviceHelper}
 */
export class DeviceHelper
	extends EntityHelper<
		DeviceCollection,
		DeviceActionTypes,
		IDeviceActions,
		DeviceEntity,
		DeviceEntities,
		DeviceEntity_Some,
		DeviceEntityPatch_Some,
		DeviceId,
		DeviceIds,
		DeviceId_Some,
		DeviceCollectionState,
		DeviceHelperOpts
	>
	implements IDeviceHelper {
	constructor() {
		super(
			useSelector(getDeviceCollection),
			deviceActions,
			useDispatch(),
			deviceHelperOpts
		);
		this.collection = useSelector(getDeviceCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: DeviceApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadDevicesRequest> = {},
		callback?: any
	): Promise<DeviceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(DeviceApiOperation.readDevices);

		let request: ReadDevicesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: DeviceEntities = await readDevices(ctx, request)
			.then((entities: DeviceEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadDevicesRequest> = {},
		callback?: any
	): Promise<DeviceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom ||
		//	this.lastSuccess(DeviceApiOperation.readDevicesAll);

		let request: ReadDevicesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: DeviceEntities = await readDevicesAll(ctx, request)
			.then((entities: DeviceEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteDevicesRequest> = {},
		callback?: any
	): Promise<DeviceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.devices) return [];
		let request: WriteDevicesRequest = {
			...params,
			...{
				devices: params.devices,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: DeviceEntities = await writeDevices(ctx, request)
			.then((entities: DeviceEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByService(service: ServiceEntity): DeviceEntities {
		return this.all()
			.filter(device => service.userIds?.includes(device.id))
			.filter(isObjectStatusActive);
	}
}
