import React, { useState, useEffect } from 'react';
import {
	IonIcon,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonButton,
	IonMenuButton,
	IonTitle,
	IonPopover,
	IonItem,
	IonLabel,
	IonText,
	IonImg
} from '@ionic/react';

//Data
import { UserAvatar, UserMenu } from '../components';
import { useCtx } from '../../../config/hooks';

//UI
import * as icons from 'ionicons/icons';
import { headerConfig } from '../../../config/app/components/Header';

export interface HeaderConfigProps {
	signIn: string;
}

export interface HeaderProps {
	title?: string;
	disabled?: boolean;
	hidden?: boolean;
}

export const Header: React.FC<HeaderProps> = props => {
	const {
		config: [config],
		app: { activeUser, activeAuth, authHelper },
		lead: { activeService, activeContext, eventHelper }
	} = useCtx<HeaderConfigProps>(headerConfig);

	const events = eventHelper.gets(activeContext?.eventIds || []);

	const login = () => {
		if (activeAuth) {
			authHelper.loginWithRedirect();
		}
	};

	const [showUserControlPopover, setShowUserControlPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	return props.disabled !== false ? (
		<IonHeader hidden={props.hidden}>
			{!activeUser ? (
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonButtons slot="end">
						<IonButton onClick={() => login()}>
							<IonIcon
								className="ion-hide-sm-down"
								slot="end"
								icon={icons.logInOutline}
							/>
							<IonLabel>{config.signIn}</IonLabel>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			) : (
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
						{activeUser?.security.includes('administrator') ? (
							<>
								<IonButton fill="clear" routerLink="/admin/events">
									Events
								</IonButton>

								<IonIcon
									hidden={!activeContext}
									src={icons.arrowForwardOutline}
								></IonIcon>
								<IonButton
									hidden={!activeContext}
									color="dark"
									fill="clear"
									routerLink="/admin/event"
								>
									<IonImg
										hidden={true}
										src={events[0]?.header?.OriginalFilePath || ''}
										style={{ width: 120 }}
									/>
									<IonText>{activeContext?.name || 'Service'}</IonText>
								</IonButton>
							</>
						) : (
							<></>
						)}
						{activeService ? (
							<>
								<IonButton
									hidden={activeUser?.security.includes('administrator')}
									color="dark"
									fill="clear"
									routerLink="/lead-service/services"
								>
									{activeService.name || 'Service'}
								</IonButton>
							</>
						) : (
							<span hidden={activeUser?.security.includes('administrator')}>
								{props.title}
							</span>
						)}
					</IonButtons>

					<IonButtons slot="end" className="no-print">
						<IonItem
							button={true}
							lines="none"
							onClick={(e: any) =>
								setShowUserControlPopover({
									open: true,
									event: e.nativeEvent
								})
							}
						>
							<UserAvatar user={activeUser} />
						</IonItem>
					</IonButtons>
					<IonPopover
						key="user-menu-popover"
						isOpen={showUserControlPopover.open}
						event={showUserControlPopover.event}
						showBackdrop={false}
						onDidDismiss={() =>
							setShowUserControlPopover({ open: false, event: undefined })
						}
					>
						<UserMenu
							key="user-menu-item"
							finish={() =>
								setShowUserControlPopover({ open: false, event: undefined })
							}
						/>
					</IonPopover>
				</IonToolbar>
			)}
		</IonHeader>
	) : (
		<></>
	);
};
