import { EventAnswerEntities } from '../../lead/store/collections/EventAnswer';

export interface EventAnswerConfig {
	entities: EventAnswerEntities;
	activeId?: string;
}

export const eventAnswerConfig: EventAnswerConfig = {
	entities: []
};
