import { Localize, translations } from '../../locales';
import { ServiceNotFoundCardConfigProps } from '../../../lead/ui/components/Service/ServiceNotFoundCard';

export type ServiceNotFoundCardConfig = Localize<
	ServiceNotFoundCardConfigProps
>;

export const serviceNotFoundCardConfig: ServiceNotFoundCardConfig = {
	en: {
		label: {
			information: 'Information',
			totalLeads: 'Total Leads',
			devicesUsed: 'Licenses Used',
			devicesSeparator: ' of '
		},
		buttonText: {
			viewService: 'View'
		}
	},
	fr: {
		label: {
			information: 'Informations',
			totalLeads: 'Total des pistes',
			devicesUsed: 'Appareils utilisés',
			devicesSeparator: '/'
		},
		buttonText: {
			viewService: 'Vue'
		}
	}
};
