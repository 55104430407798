import * as storage from '../../../storage/models';
import { collectionDefault } from '../../../storage/constants';
import { CollectionReducerActions } from '../../../storage/classes/Collection';
import {
	collectionIdStringProps,
	collectionIdArrayProps,
	collectionIdEntityProps
} from '../../../storage/models';
import { orderConfig } from '../../../config/lead/Order';
import { Order } from '../models';

/**
 * Order entity interface
 *
 * @export
 * @interface OrderEntity
 * @extends {storage.Entity}
 */
export interface OrderEntity extends storage.Entity, Order {}

/**
 * Order entities array
 *
 * @export
 */
export type OrderEntities = OrderEntity[];

/**
 * Some order entities: one entity or an array of entities
 *
 * @export
 */
export type OrderEntity_Some = OrderEntity | OrderEntities;

/**
 * Order entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface OrderEntityPatch
 * @extends {storage.EntityPatch<OrderEntity>}
 */
export type OrderEntityPatch = storage.EntityPatcher<OrderEntity>;

/**
 * Order entity patches array
 *
 * @export
 */
export type OrderEntitiesPatch = OrderEntityPatch[];

/**
 * Some order entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type OrderEntityPatch_Some = OrderEntityPatch | OrderEntitiesPatch;

/**
 * Order entity id
 *
 * @export
 */
export type OrderId = storage.EntityId;

/**
 * Order entity ids
 *
 * @export
 */
export type OrderIds = storage.EntityIds;

/**
 * Some order entities by id: one entity id or an array of entity ids
 *
 * @export
 */
export type OrderId_Some = OrderId | OrderIds;

/**
 * Order entities object by id
 *
 * @export
 * @interface OrderEntitiesObject
 * @extends {storage.EntitiesObject}
 */
export interface OrderEntitiesObject extends storage.EntitiesObject {
	[id: string]: OrderEntity;
}

/**
 * Order entity state
 *
 * @export
 * @interface OrderEntityState
 * @extends {storage.EntityState}
 */
export interface OrderEntityState extends storage.EntityState {}

/**
 * Order store collection
 *
 * @export
 * @interface OrderStoreCollection
 * @extends {storage.Store}
 */
export interface OrderStoreCollection extends storage.Store {
	order: OrderCollection;
}

/**
 * Order collection properties
 *
 * @export
 * @interface OrderCollection
 * @extends {Collection}
 */
export interface OrderCollection extends storage.Collection {
	byIds: OrderEntitiesObject;
	mutation: OrderEntitiesObject;
	cache: OrderEntitiesObject;
	state: OrderCollectionState;
}

/**
 * Order collection state
 *
 * @export
 * @interface OrderCollectionState
 * @extends {storage.CollectionState}
 */
export interface OrderCollectionState extends storage.CollectionState {}

// custom order collection id string (EntityId), array (EntityIds) and entity (EntitiesObject) property names
export const orderCollectionIdStringProps: string[] = [
	...collectionIdStringProps,
	...[]
];
export const orderCollectionIdArrayProps: string[] = [
	...collectionIdArrayProps,
	...[]
];
export const orderCollectionIdEntityProps: string[] = [
	...collectionIdEntityProps,
	...[]
];

// Id mutation options for mutateId action
export const orderCollectionMutateIdOpts: storage.EntityId_MutationOpts = {
	idStringProps: orderCollectionIdStringProps,
	idArrayProps: orderCollectionIdArrayProps,
	idEntityProps: orderCollectionIdEntityProps
};

const collectionInitializer = new CollectionReducerActions();

/**
 * Order collection default values, initialize collection with order entities from config
 * set the activeId to the config activeId or the first entity in the collection
 *
 * @export
 * @constant
 */
export const orderCollectionDefault: OrderCollection = collectionInitializer.upsert(
	{
		...(collectionDefault as OrderCollection),
		...{
			activeId: orderConfig.activeId || orderConfig.entities[0]?.id
			//customId: undefined
			//customIds: [];
			//customByIds: {};
		}
	},
	orderConfig.entities
);

/**
 * Order store collection default values
 *
 * @export
 * @constant
 */
export const orderStoreCollectionDefault: OrderStoreCollection = {
	order: orderCollectionDefault
};

/**
 * Order store collection selector for useSelector hook
 *
 * @export
 * @constant
 */
export const getOrderCollection = (state: any) => state.lead.order;
