import {
	EventCollection,
	EventCollectionState,
	EventId,
	EventEntity_Some,
	EventEntityPatch_Some,
	EventId_Some,
	eventCollectionDefault,
	eventCollectionMutateIdOpts
} from '../collections/Event';
import * as actions from '../actions/Event';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Event reducer
 *
 * @export
 * @param {EventCollection} [state=eventCollectionDefault]
 * @param {EventActionTypes} action
 * @returns
 */
export function eventReducer(
	state: EventCollection = eventCollectionDefault,
	action: actions.EventActionTypes
) {
	switch (action.type) {
		case actions.EVENT_MUTATE_ID:
			return collectionReducerActions.mutateId<EventCollection>(
				state,
				action.payload,
				eventCollectionMutateIdOpts
			);
		case actions.EVENT_SET:
			return collectionReducerActions.set<EventCollection, EventId>(
				state,
				action.payload
			);
		case actions.EVENT_TOGGLE:
			return collectionReducerActions.toggle<EventCollection, EventId>(
				state,
				action.payload
			);
		case actions.EVENT_SELECT:
			return collectionReducerActions.select<EventCollection, EventId_Some>(
				state,
				action.payload
			);
		case actions.EVENT_DESELECT:
			return collectionReducerActions.deselect<EventCollection, EventId_Some>(
				state,
				action.payload
			);
		case actions.EVENT_UPSERT:
			return collectionReducerActions.upsert<EventCollection, EventEntity_Some>(
				state,
				action.payload
			);
		case actions.EVENT_PATCH:
			return collectionReducerActions.patch<
				EventCollection,
				EventEntityPatch_Some
			>(state, action.payload);
		case actions.EVENT_CHANGE:
			return collectionReducerActions.change<
				EventCollection,
				EventEntityPatch_Some
			>(state, action.payload);
		case actions.EVENT_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				EventCollection,
				EventId_Some
			>(state, action.payload);
		case actions.EVENT_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				EventCollection,
				EventId_Some
			>(state, action.payload);
		case actions.EVENT_CACHE:
			return collectionReducerActions.cache<EventCollection, EventId_Some>(
				state,
				action.payload
			);
		case actions.EVENT_UNCACHE:
			return collectionReducerActions.uncache<EventCollection, EventId_Some>(
				state,
				action.payload
			);
		case actions.EVENT_SETSTATE:
			return collectionReducerActions.setState<
				EventCollection,
				EventCollectionState
			>(state, action.payload.state);
		case actions.EVENT_PATCHSTATE:
			return collectionReducerActions.patchState<
				EventCollection,
				EventCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
