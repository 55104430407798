import {
	QualifierValueCollection,
	QualifierValueCollectionState,
	QualifierValueId,
	QualifierValueEntity_Some,
	QualifierValueEntityPatch_Some,
	QualifierValueId_Some,
	qualifierValueCollectionDefault,
	qualifierValueCollectionMutateIdOpts
} from '../collections/QualifierValue';
import * as actions from '../actions/QualifierValue';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * QualifierValue reducer
 *
 * @export
 * @param {QualifierValueCollection} [state= qualifierValueCollectionDefault]
 * @param {QualifierValueActionTypes} action
 * @returns
 */
export function qualifierValueReducer(
	state: QualifierValueCollection = qualifierValueCollectionDefault,
	action: actions.QualifierValueActionTypes
) {
	switch (action.type) {
		case actions.QUALIFIERVALUE_MUTATE_ID:
			return collectionReducerActions.mutateId<QualifierValueCollection>(
				state,
				action.payload,
				qualifierValueCollectionMutateIdOpts
			);
		case actions.QUALIFIERVALUE_SET:
			return collectionReducerActions.set<
				QualifierValueCollection,
				QualifierValueId
			>(state, action.payload);
		case actions.QUALIFIERVALUE_TOGGLE:
			return collectionReducerActions.toggle<
				QualifierValueCollection,
				QualifierValueId
			>(state, action.payload);
		case actions.QUALIFIERVALUE_SELECT:
			return collectionReducerActions.select<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_DESELECT:
			return collectionReducerActions.deselect<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_UPSERT:
			return collectionReducerActions.upsert<
				QualifierValueCollection,
				QualifierValueEntity_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_PATCH:
			return collectionReducerActions.patch<
				QualifierValueCollection,
				QualifierValueEntityPatch_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_CHANGE:
			return collectionReducerActions.change<
				QualifierValueCollection,
				QualifierValueEntityPatch_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_CACHE:
			return collectionReducerActions.cache<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_UNCACHE:
			return collectionReducerActions.uncache<
				QualifierValueCollection,
				QualifierValueId_Some
			>(state, action.payload);
		case actions.QUALIFIERVALUE_SETSTATE:
			return collectionReducerActions.setState<
				QualifierValueCollection,
				QualifierValueCollectionState
			>(state, action.payload.state);
		case actions.QUALIFIERVALUE_PATCHSTATE:
			return collectionReducerActions.patchState<
				QualifierValueCollection,
				QualifierValueCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
