import React from 'react';

import {
	IonButton,
	IonItem,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonText,
	IonIcon,
	IonCheckbox
} from '@ionic/react';

import { serviceListItemConfig } from '../../../../config/lead/components/ServiceListItem';
import { useLocaleConfig } from '../../../../app/store';
import {
	ServiceEntity,
	useService,
	isObjectStatusActive
} from '../../../store';
import { useCtx } from '../../../../config/hooks';
import { pencilOutline } from 'ionicons/icons';

export interface ServiceListItemConfigProps {
	label?: {
		information?: string;
		totalLeads?: string;
		devicesUsed?: string;
		devicesSeparator?: string;
	};
	buttonText?: {
		viewService?: string;
	};
}

export interface ServiceListItemProps {
	service: ServiceEntity;
	isSelectable?: boolean;
	checked?: boolean;
	onItemClicked?: (selectedId: string) => void;
	onManageBtnClicked?: (selectedId: string) => void;
	managementPage?: string;
}

export const ServiceListItem: React.FC<ServiceListItemProps> = props => {
	const {
		config: [config],
		lead: { serviceHelper, activeService, contactHelper }
	} = useCtx<ServiceListItemConfigProps>(serviceListItemConfig);

	const selectService = (e: any) => {
		e.stopPropagation();
		serviceHelper.set(props.service.id);
	};

	const manageBtnClicked = (e: any) => {
		e.stopPropagation();
		selectService(e);
		if (props.onItemClicked !== undefined) {
			props.onItemClicked(props.service.id);
		}
	};

	const serviceContacts = contactHelper
		.gets(props.service?.contactIds || [])
		.filter(isObjectStatusActive);
	const mainContact = serviceContacts[0];

	return (
		<IonItem
			button={true}
			routerLink="/lead-service/leads"
			key={props.service.id}
			color={activeService?.id === props.service.id ? 'light' : undefined}
			onClick={selectService}
		>
			<IonGrid>
				<IonRow>
					<IonCol hidden={!props.isSelectable} size="1">
						<IonCheckbox hidden={!props.isSelectable} checked={props.checked} />
					</IonCol>
					<IonCol size="11" sizeMd="4">
						<IonLabel
							color={
								activeService?.id === props.service.id ? 'primary' : undefined
							}
						>
							{props.service.name}
						</IonLabel>
					</IonCol>
					<IonCol>
						<div
							style={{ fontSize: 'medium', fontWeight: 200 }}
							hidden={!mainContact}
						>
							{mainContact?.address1} {mainContact?.address2}
							<br />
							{mainContact?.city} {mainContact?.subdivision}{' '}
							{mainContact?.postal}
							<br />
							{mainContact?.email}
							<br />
						</div>
					</IonCol>
					<IonCol>
						<IonButton
							class="ion-float-right"
							routerLink={props.managementPage || '/lead-service/service'}
							onClick={manageBtnClicked}
						>
							<IonIcon src={pencilOutline} /> Manage
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
