import React from 'react';

//DATA
import { QualifierEntity } from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import { IonItem, IonRow, IonGrid, IonCol } from '@ionic/react';

export interface QualifierListItemProps {
	qualifier: QualifierEntity;
	edit: (qualifier: QualifierEntity) => void;
}

export const QualifierListItem: React.FC<QualifierListItemProps> = props => {
	const ctx = useCtx<QualifierListItemProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;
	/*const selectQualifier = () => {
		ctx.lead.qualifier.set(props.qualifier.id);
	};*/
	return (
		<IonItem
			//routerLink="/lead-service/qualifier"
			//onClick={selectQualifier}
			onClick={() => props.edit(props.qualifier)}
			button
		>
			<IonGrid>
				<IonRow>
					<IonCol>
						<h2>{props.qualifier.name}</h2>
						<p>{props.qualifier.description}</p>
					</IonCol>
					<IonCol>
						<h2>{props.qualifier.category}</h2>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
