import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getEventQuestionCollection,
	EventQuestionId,
	EventQuestionIds,
	EventQuestionId_Some,
	EventQuestionEntity,
	EventQuestionEntities,
	EventQuestionEntity_Some,
	EventQuestionEntityPatch_Some,
	EventQuestionCollection,
	EventQuestionCollectionState,
	IEventQuestionActions,
	eventQuestionActions,
	EventQuestionActionTypes
} from '..';
import {
	readEventQuestions,
	ReadEventQuestionsRequest,
	readEventQuestionsById,
	ReadEventQuestionsByIdRequest,
	EventQuestionApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { EventQuestionSelectionType, isObjectStatusActive } from '../models';
import { request } from 'http';

/**
 * EventQuestion helper interface
 *
 * @export
 * @interface IEventQuestionHelper
 * @extends {IEntityHelper}
 */
export interface IEventQuestionHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * EventQuestion helper options interface
 *
 * @export
 * @interface EventQuestionHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface EventQuestionHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const eventQuestionHelperOpts: EventQuestionHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * EventQuestion helper
 *
 * @export
 * @class EventQuestionHelper
 * @extends {EntityHelper<EventQuestionCollection, EventQuestionActionTypes, EventQuestionActions, EventQuestionEntity, EventQuestionEntities, EventQuestionEntity_Some, EventQuestionEntityPatch_Some, EventQuestionId, EventQuestionIds, EventQuestionId_Some, EventQuestionCollectionState, EventQuestionHelperOpts>}
 * @implements {IEventQuestionHelper}
 */
export class EventQuestionHelper
	extends EntityHelper<
		EventQuestionCollection,
		EventQuestionActionTypes,
		IEventQuestionActions,
		EventQuestionEntity,
		EventQuestionEntities,
		EventQuestionEntity_Some,
		EventQuestionEntityPatch_Some,
		EventQuestionId,
		EventQuestionIds,
		EventQuestionId_Some,
		EventQuestionCollectionState,
		EventQuestionHelperOpts
	>
	implements IEventQuestionHelper {
	constructor() {
		super(
			useSelector(getEventQuestionCollection),
			eventQuestionActions,
			useDispatch(),
			eventQuestionHelperOpts
		);
		this.collection = useSelector(getEventQuestionCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: EventQuestionApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async readEventQuestions(
		ctx: UseCtx<any>,
		params: Partial<ReadEventQuestionsRequest> & {
			eventId: string;
		},
		callback?: any
	): Promise<EventQuestionEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadEventQuestionsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '' || request.eventId === '') return [];

		let entities: EventQuestionEntities = await readEventQuestions(ctx, request)
			.then((entities: EventQuestionEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readEventQuestionsById(
		ctx: UseCtx<any>,
		params: Partial<ReadEventQuestionsByIdRequest> & {
			ids: string[];
			eventIds: string[];
		},
		callback?: any
	): Promise<EventQuestionEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (
			!ctx.lead.service.active()?.eventIds ||
			ctx.lead.service.active()?.eventIds?.length === 0
		)
			return [];
		let request: ReadEventQuestionsByIdRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '' || request.eventIds.length === 0) return [];

		let entities: EventQuestionEntities = await readEventQuestionsById(
			ctx,
			request
		)
			.then((entities: EventQuestionEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allActive(ctx: UseCtx<any>): EventQuestionEntities {
		let eventIds = ctx.lead.context.active()?.eventIds || [];
		return this.all()
			.filter(e => eventIds.indexOf(e.eventId) > -1)
			.filter(isObjectStatusActive);
	}

	getNameById(
		id?: string,
		defaultName: string = 'Unknown',
		customName?: string
	): string {
		return this.getName(id ? this.get(id) : undefined, defaultName, customName);
	}

	getName(
		eventQuestion?: EventQuestionEntity,
		defaultName: string = 'Unknown',
		customName?: string
	): string {
		return customName || !eventQuestion
			? defaultName
			: eventQuestion.name || eventQuestion.text || defaultName;
	}
}
