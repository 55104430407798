import { Localize, translations } from '../../locales';
import { UserControlConfigProps } from '../../../app/ui/containers/User/UserControl';

export type UserControlConfig = Localize<UserControlConfigProps>;

export const userControlConfig: UserControlConfig = {
	en: {
		signIn: translations.signIn.en,
		manageUser: 'Manage my account',
		addUser: 'Add another user',
		signoutAll: 'Sign out of all accounts'
	},
	fr: {
		signIn: translations.signIn.fr,
		manageUser: 'Gestion de mes comptes ',
		addUser: 'Ajouter un autre',
		signoutAll: 'Déconnectez-vous tous'
	}
};
