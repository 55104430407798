import { Localize } from '../../locales';
import { PageTitleConfigProps } from '../../../app/ui/components/PageTitle';

export type PageTitleConfig = Localize<PageTitleConfigProps>;

export const pageTitleConfig: PageTitleConfig = {
	en: {
		placeholder: 'Search'
	},
	fr: {
		placeholder: 'Chercher'
	}
};
