import React, { useState } from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonListHeader,
	IonButton,
	IonIcon,
	IonTextarea,
	IonPopover
} from '@ionic/react';

import { NoteEntity, LeadEntity, ObjectType } from '../../../store';

import _ from 'lodash';
import { ellipsisHorizontal, trashOutline } from 'ionicons/icons';
import { useCtx } from '../../../../config/hooks';
import { newEntity } from '../../../../app/utils';

export interface AddEditLeadNoteConfigProps {
	lead: LeadEntity;
	note?: NoteEntity;
	finish: any;
}

export const AddEditLeadNote: React.FC<AddEditLeadNoteConfigProps> = props => {
	const ctx = useCtx<AddEditLeadNoteConfigProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	// create a new entity if one does not exist and track it in the component state
	const [note, setNote] = useState<NoteEntity>(
		props.note || {
			...newEntity(ctx, ObjectType.Note),
			serviceId: props.lead.serviceId,
			leadId: props.lead.id,
			name: '',
			value: ''
		}
	);

	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	const existingNote = ctx.lead.note.get(note.id);
	//const mutatedNote = ctx.lead.note.mutated([note.id])[0];

	const change = (e: any) => {
		let targetValue = e.target.value;
		if (targetValue && targetValue.trim() != '') {
			let change: NoteEntity = _.set(_.cloneDeep(note), 'value', targetValue);
			//ctx.lead.note.change(change);
			setNote(change);
		}
	};

	const deleteNote = () => {
		ctx.lead.note.writeDelete(ctx, note.id);
		finish();
	};

	const finish = () => {
		//ctx.lead.note.applyChanges(id);
		ctx.lead.note.write(ctx, {
			serviceId: note.serviceId,
			notes: [note]
		});
		props.finish();
	};

	return (
		<IonCard>
			<IonRow>
				<IonCol>
					<IonCardHeader>
						<IonCardTitle>Note</IonCardTitle>
					</IonCardHeader>
				</IonCol>
				<IonCol class="text-right">
					{existingNote && (
						<IonButton
							fill="clear"
							onClick={e =>
								setShowPopover({
									open: true,
									event: e.nativeEvent
								})
							}
						>
							<IonIcon icon={ellipsisHorizontal} />
						</IonButton>
					)}
					<IonPopover
						isOpen={showPopover.open}
						event={showPopover.event}
						onDidDismiss={e =>
							setShowPopover({
								open: false,
								event: undefined
							})
						}
					>
						<IonList>
							<IonListHeader>Options</IonListHeader>
							<IonItem button onClick={deleteNote}>
								<IonIcon icon={trashOutline} />
								Delete Note
							</IonItem>
						</IonList>
					</IonPopover>
				</IonCol>
			</IonRow>
			<IonCardContent>
				<IonRow>
					<IonCol>
						<IonItem>
							<IonTextarea
								rows={10}
								cols={20}
								value={note?.value}
								onIonBlur={change}
								placeholder="Enter your note here"
							></IonTextarea>
						</IonItem>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol class="text-right">
						<IonButton color="light" onClick={finish}>
							Save
						</IonButton>
					</IonCol>
				</IonRow>
			</IonCardContent>
		</IonCard>
	);
};
