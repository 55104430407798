import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import SwaggerParser from '@apidevtools/swagger-parser';
import bath from 'bath';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment';
import {
	Api,
	ApiAuthTypes,
	User as AppUser,
	UserAuth,
	UserAuthAudience
} from '../store/models';
//import { ApiRequestTokenPayload, ApiRequestToken } from '../../config/app/Api';
import {
	OpenAPIObject,
	RequestBodyObject,
	ReferenceObject,
	OperationObject,
	ParameterObject
} from '../store/models/OpenApi3';
import { AppDevice, getAppDevice } from '../store';
import {
	State_ApiOperationContextTypes,
	State_Api,
	Entity_Some
} from '../../storage';
import { IEntityHelper } from '../../storage/classes/Entity';
import { UseCtx } from '../../config/hooks';
import objectHash from 'object-hash';

export interface AppApi extends Api {
	schema: OpenAPIObject;
	tokens: ApiTokens;
	operations: TrackApiOperations;
}

export interface AppApis {
	[id: string]: AppApi;
}

export interface ApiTokens {
	[hash: string]: ApiToken;
}

export interface ApiToken {
	id: string;
	data: any;
	token: string;
	expiresAt?: number;
}

export interface TrackApiOperation {
	id: string;
	requests: TrackApiRequests;
}

export interface TrackApiOperations {
	[operationId: string]: TrackApiOperation;
}
export interface TrackApiRequest {
	id: string;
	lastModifiedFrom?: string;
}

export interface TrackApiRequests {
	[requestId: string]: TrackApiRequest;
}

// holder for active / last request api object
export const appApis: AppApis = {};

export interface ApiRequest {
	api: AppApi;
	path: string;
	method: ApiRequestMethod;
	operation: OperationObject;
	requestParams: RequestParams;
	request: AxiosRequestConfig;
	response: AxiosResponse;
}

// supported methods compatible between OpenApi 3 and axios.
export type ApiRequestMethod =
	| 'get'
	| 'delete'
	| 'head'
	| 'options'
	| 'post'
	| 'put'
	| 'patch';

const supportedApiRequestMethods: ApiRequestMethod[] = [
	'get',
	'delete',
	'head',
	'options',
	'post',
	'put',
	'patch'
];

export interface RequestParams {
	token?: any;
	header?: any;
	path?: any;
	qs?: any;
	body?: any;
	modifiedFrom?: string;
}

export async function apiRequest<T, P extends { [key: string]: any }>(
	ctx: UseCtx<any>,
	apiId: string,
	operationId: string,
	params: P,
	bodyParamName?: string
): Promise<ApiRequest> {
	try {
		// varify the apiId is an appApi. throw error if not, otherwise get reference to appApi by apiId
		if (!(apiId in appApis)) throw 'Invalid API request identity';
		const api = appApis[apiId];

		let path: string | undefined,
			method: ApiRequestMethod | undefined,
			operation: OperationObject | undefined;

		for (let pKey in api.schema.paths) {
			for (let oKey in api.schema.paths[pKey]) {
				if (supportedApiRequestMethods.indexOf(oKey as ApiRequestMethod) > -1) {
					if (operationId === api.schema.paths[pKey][oKey].operationId) {
						path = pKey;
						method = oKey as ApiRequestMethod;
						operation = api.schema.paths[pKey][oKey];
						break;
					}
				}
			}
			if (operation) break;
		}

		if (!path || !method || !operation)
			throw `Invalid API operation: ${operationId}`;

		let requestParams: RequestParams = await parseParams<P>(
			operation,
			params,
			bodyParamName
		);

		// initialize the operationId on the api if not existing
		if (!(operationId in api.operations))
			api.operations[operationId] = {
				id: operationId,
				requests: {}
			};

		// get the requestId by hashing the params
		let requestId = objectHash(params);
		// initialize the requestId on the api operation if not existing
		if (!(requestId in api.operations[operationId].requests))
			api.operations[operationId].requests[requestId] = {
				id: requestId
			};

		if (requestParams.modifiedFrom === 'auto') {
			// to do
			delete requestParams.modifiedFrom;
		}

		let authorization: string | undefined;

		switch (api.auth.type) {
			case ApiAuthTypes.NoAuth:
				break;
			case ApiAuthTypes.UserToken:
				/*
				let {
					app: { activeUser }
				} = ctx;
				if (!activeUser) throw 'API authentication missing active user';
				let user = activeUser;
				if (Object.keys(user.auth).length === 0)
					throw 'API authentication missing user auth';

				let audienceKey = Object.keys(user.auth)
					.filter(
						audienceKey =>
							user.auth[audienceKey].response?.id_token &&
							user.auth[audienceKey].expiresAt &&
							moment
								.unix(user.auth[audienceKey].expiresAt)
								.subtract(30, 'seconds')
								.isBefore(moment())
					)
					.sort((audienceKeyA, audienceKeyB) =>
						user.auth[audienceKeyA].expiresAt >
						user.auth[audienceKeyB].expiresAt
							? 1
							: -1
					)[0];
				if (!audienceKey) throw 'API authentication no valid user token';
				let audience: UserAuthAudience | undefined = user.auth[audienceKey];
				if (!audience.response?.id_token)
					throw 'API authentication missing user token';
				authorization = `Bearer ${audience.response?.id_token}`;
*/
				break;
			//case ApiAuthTypes.ClientCredentials:
			case ApiAuthTypes.BearerToken:
				authorization = `Bearer ${api.auth.token}`;
				break;
			default:
				throw 'API unsupported auth type';
		}

		// if authorization, then the Authorization head
		if (authorization)
			requestParams.header = {
				...{
					Authorization: authorization
				},
				...(requestParams.header || {})
			};

		// if there are 'token' params then verify / request a token from the api
		if (requestParams.token) {
			if (!api.requestToken) throw 'API missing request token settings';
			// get the app, user, device, etc... data and set the token meta data
			let appDevice: AppDevice = getAppDevice();
			requestParams.token = {
				userId: ctx.app.user.active()?.userId,
				deviceId: appDevice.id,
				serviceId: ctx.lead.service.active()?.id,
				eventIds: ctx.lead.context.active()?.eventIds,
				ip: appDevice.ip,
				locale: appDevice.locale,
				timezone: appDevice.timezone,
				country: appDevice.country,
				lat: appDevice.lat,
				lon: appDevice.lon
			};

			/*
	
			let apiTokenId: string = objectHash(requestParams.token);
			let apiToken: ApiToken | undefined;
	
			// if the user has an api token with matching data that is not expired, use it and don't generate a new request token
			if (
				apiTokenId in api.tokens &&
				api.tokens[apiTokenId].expiresAt &&
				moment
					.unix(api.tokens[apiTokenId].expiresAt || 0)
					.subtract(30, 'seconds')
					.isAfter(moment())
			)
				apiToken = api.tokens[apiTokenId];
	
			if (!apiToken) {
				// get a token from the server
				let userTokenResponse: ApiRequest = await apiRequest<
					ApiRequestToken,
					{
						requestTokenPayload: ApiRequestTokenPayload;
					}
				>(
					ctx,
					apiId,
					api.requestToken.operationId,
					{ requestTokenPayload: requestParams.token },
					'requestTokenPayload'
				);
	
				let requestToken: ApiRequestToken = userTokenResponse.response
					.data as ApiRequestToken;
	
				// prep and cache the api token for future use
				apiToken = {
					id: apiTokenId,
					data: requestParams.token,
					token: requestToken.token,
					expiresAt: moment(requestToken.expires).unix()
				};
				api.tokens[apiTokenId] = apiToken;
			}
	
			// add the token to the request header
			requestParams.header = {
				...{
					[api.requestToken.header]: apiToken.token
				},
				...(requestParams.header || {})
			};
	
		*/
		}

		// add the Access-Control-Allow-Origin header
		requestParams.header = {
			...{
				'Content-Type': 'application/json'
			},
			...(requestParams.header || {})
		};

		// use bath to set path parameters
		if (requestParams.path) {
			let bathTemplate = bath(path);
			let bPath = bathTemplate.path(requestParams.path);
			if (bPath) path = bPath;
		}

		// initiate base request config with method and url
		let request: AxiosRequestConfig = {
			method,
			baseURL: api.url,
			url: path,
			responseType: 'json'
		};

		// add querystring params to request params if existing
		if (requestParams.qs) {
			request.params = requestParams.qs;
			request.paramsSerializer = (params: any) =>
				qs.stringify(params, { arrayFormat: 'repeat' });
		}

		// add header params to request headers if existing
		if (requestParams.header) request.headers = requestParams.header;

		// set the body param to request data if existing
		if (requestParams.body) request.data = requestParams.body;

		axios.interceptors.response.use(
			function (res) {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return res;
			},
			function (error: AxiosError) {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				//console.log(error.response?.data || error.response);

				return Promise.reject(error);
			}
		);

		//console.log('request$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
		//console.log(request);
		// make the request and return the response
		//const response: AxiosResponse = await axios.request<T>(request);
		const response: AxiosResponse = await axios.request<T>(request);

		if (response.status < 200 || response.status >= 300) {
			console.log(response);
			throw (
				'API request error: ' +
					response.status +
					': ' +
					response.data?.message ||
				operation.responses[operation.responses.response.status.toString()]
					?.description ||
				response.statusText ||
				'Invalid response'
			);
		}

		//console.log('response$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
		//console.log(response);

		// update the api state date time if there is a header timestamp
		if (response.headers.Timestamp || response.headers.timestamp)
			ctx.app.api.setDt(
				api.id,
				response.headers.Timestamp || response.headers.timestamp
			);

		return {
			api,
			path,
			method,
			operation,
			requestParams,
			request,
			response
		};
	} catch (e) {
		console.log('Api error');
		console.error(e);
		console.log(e);
		throw e;
	}
}

const parseParams = <P extends { [key: string]: any }>(
	operation: OperationObject,
	params: P,
	bodyParamName?: string
): RequestParams => {
	const requestParams: RequestParams = {};
	let parameters: ParameterObject[] = [
		...((operation.parameters || []) as ParameterObject[]),
		...((operation['x-parameters'] || []) as ParameterObject[])
	];
	if (parameters) {
		for (let paramKey in parameters) {
			let param: ParameterObject = parameters[paramKey] as ParameterObject;
			switch (param.in) {
				case 'token':
					requestParams.token = {}; // auto set from active app data in apiRequest
					break;
				case 'header':
					if (param.name in params && params[param.name] !== undefined)
						requestParams.header = {
							...(requestParams.header || {}),
							...{ [param.name]: params[param.name] }
						};
					break;
				case 'path':
					if (param.name in params && params[param.name] !== undefined)
						requestParams.path = {
							...(requestParams.path || {}),
							...{ [param.name]: params[param.name] }
						};
					break;
				case 'query':
					if (param.name in params && params[param.name] !== undefined) {
						requestParams.qs = {
							...(requestParams.qs || {}),
							...{ [param.name]: params[param.name] }
						};
					} else if (param.name === 'modifiedFrom') {
						requestParams.modifiedFrom = 'auto'; // default modifiedFrom to 'auto'
					}
					break;
			}
		}
	}

	// if there is a bodyParam and the operation supports a json request body, then include it
	if (
		bodyParamName &&
		bodyParamName in params &&
		isJsonRequestBodyObject(operation.requestBody)
	)
		requestParams.body = params[bodyParamName];

	return requestParams;
};

function isJsonRequestBodyObject(
	requestBody: RequestBodyObject | ReferenceObject | undefined
): requestBody is RequestBodyObject {
	return _.has(requestBody as RequestBodyObject, [
		'content',
		'application/json'
	]);
}

export const initApi = async (api: Api): Promise<AppApi> => {
	//try {
	// if the api has already been initialized, return it.
	if (appApis[api.id]) return appApis[api.id];

	// load the raw api schema from assets
	const response = await Promise.all([fetch(api.definition)]);
	const rawSchema = (await response[0].json()) as OpenAPIObject[];

	// use Swagger parser to parse, validate, dereference the raw schema object
	let schema: any = await SwaggerParser.validate(rawSchema as any);

	// add the initialized api with validated schema object to appApis[id]
	appApis[api.id] = {
		...api,
		...{ schema, tokens: {}, operations: {} }
	};

	return appApis[api.id];
	/*
	} catch (e) {
		throw (
			'Unable to initialize API: ' +
			(api ? api.name : 'unknown') +
			' : ' +
			e.description
		);
	}
	*/
};

// strip '__' app (non api) keys from the entities in preparation to send to the api
// set the last Attempt date on the entities states
export const prepEntitiesForApiOperation = <
	E extends Entity_Some,
	Helper extends IEntityHelper
>(
	helper: Helper,
	entityOrEntities: E,
	operationId: string,
	requestId: string,
	date: string
): void => {
	setEntitiesApiOperationState<E, Helper>(
		helper,
		entityOrEntities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Attempt,
		date
	);

	// remove system keys.  any starting with '__'
	if (Array.isArray(entityOrEntities)) {
		entityOrEntities.forEach((entity, i, a) => {
			for (let key in entity) if (key.startsWith('__')) delete entity[key];
			a[i] = entity;
		});
	} else {
		for (let key in entityOrEntities)
			if (key.startsWith('__')) delete entityOrEntities[key];
	}
};

// set/patch some entities with an __properties.api.operations[operationId][contextType]last.on date
// attempt, success, error
export const setEntitiesApiOperationState = <
	E extends Entity_Some,
	Helper extends IEntityHelper
>(
	helper: Helper,
	entityOrEntities: E,
	operationId: string,
	requestId: string,
	contextType: State_ApiOperationContextTypes,
	date: string,
	status?: AxiosResponse
): void => {
	let patch: { __state: { api: Partial<State_Api> } } = {
		__state: {
			api: {
				operations: {
					[operationId]: {
						[requestId]: {
							[contextType]: {
								last: {
									dt: date
									//status: status?.status
								}
							}
						}
					}
				}
			}
		}
	};
	// merge in the api state to each entity
	if (Array.isArray(entityOrEntities)) {
		entityOrEntities.forEach((entity, i, a) => {
			a[i] = _.merge(entity, patch);
		});
	} else {
		entityOrEntities = _.merge(entityOrEntities, patch);
	}
};
