import React, { useState } from 'react';
import {
	IonLabel,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonBadge,
	IonPopover,
	IonList,
	IonListHeader,
	IonIcon,
	IonButton
} from '@ionic/react';

import { DeviceEntity, Device, ObjectStatus } from '../../../store';
import { useCtx } from '../../../../config/hooks';
import _ from 'lodash';
import {
	ellipsisHorizontal,
	closeCircleOutline,
	checkmarkCircleOutline
} from 'ionicons/icons';

export interface DeviceListItemProps {
	device: DeviceEntity;
}

export const DeviceListItem: React.FC<DeviceListItemProps> = props => {
	const ctx = useCtx<DeviceListItemProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	const selectDevice = () => {
		ctx.lead.device.set(props.device.id);
	};

	const onDeactivate = (e: any) => {
		setShowPopover({
			open: false,
			event: undefined
		});

		if (props.device) {
			let change: DeviceEntity = _.set(
				_.cloneDeep(props.device),
				'i_.status',
				ObjectStatus.Deactive
			) as DeviceEntity;
			ctx.lead.device.change(change);
			ctx.lead.device.applyChanges(props.device.id);
		}
	};

	const onActivate = (e: any) => {
		setShowPopover({
			open: false,
			event: undefined
		});

		if (props.device) {
			let change: DeviceEntity = _.set(
				_.cloneDeep(props.device),
				'i_.status',
				ObjectStatus.Active
			);
			ctx.lead.device.change(change);
			ctx.lead.device.applyChanges(props.device.id);
		}
	};
	console.log(props.device);
	return (
		<IonItem button>
			<IonGrid>
				<IonRow>
					<IonCol size="4" sizeMd="4">
						<IonLabel>
							<h2>{props.device.deviceName}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="4" sizeMd="4">
						<IonLabel>
							<h2>{props.device.brand}</h2>
						</IonLabel>
					</IonCol>
					<IonCol size="2" sizeMd="2">
						{props.device.i_.status == ObjectStatus.Active ? (
							<IonBadge color="success">{props.device.i_.status}</IonBadge>
						) : (
							<IonBadge color="danger">{props.device.i_.status}</IonBadge>
						)}
					</IonCol>
					<IonCol size="2" sizeMd="2">
						<IonButton
							fill="clear"
							onClick={e =>
								setShowPopover({ open: true, event: e.nativeEvent })
							}
						>
							<IonIcon icon={ellipsisHorizontal} />
						</IonButton>
						<IonPopover
							isOpen={showPopover.open}
							event={showPopover.event}
							onDidDismiss={e =>
								setShowPopover({ open: false, event: undefined })
							}
						>
							<IonList>
								<IonListHeader>Options</IonListHeader>
								{props.device.i_.status == ObjectStatus.Deactive ? (
									<IonItem button onClick={onActivate}>
										<IonIcon icon={checkmarkCircleOutline} />
										Activate Device
									</IonItem>
								) : (
									<IonItem button onClick={onDeactivate}>
										<IonIcon icon={closeCircleOutline} />
										Deactivate Device
									</IonItem>
								)}
							</IonList>
						</IonPopover>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
