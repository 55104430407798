import React from 'react';

//UI
import { IonGrid, IonRow, IonCol, IonContent } from '@ionic/react';
import { RecentEventsCard } from './../containers/Event';
import { NewOrderCard, ActivateOrderCard } from './../containers/Order';
import { PageTitle } from './../../../app/ui/components/PageTitle';
import { InfoCard } from '../components/Cards';

import '../styles/main.css';
import { useCtx } from '../../../config/hooks';
import { ContextEntities } from '../../store';

const MainView: React.FC = () => {
	// Data Hooks
	const ctx = useCtx<{}>({});
	const {
		lead: { activeService, contextHelper }
	} = ctx;

	//Statefull objects
	const userContexsts: ContextEntities = contextHelper.gets(
		activeService?.contextIds || []
	);

	//Rendered elements
	const MainCard = activeService ? (
		<InfoCard
			h1="Your lead portal is ready!"
			p='Great job, your lead portal is ready to go. Your latest events are
	listed below. Click the View buttons to view your event leads or select
	the "All Events" button below to manage your events.'
			button={{
				routerLink: '/lead-service/events',
				text: 'All Events'
			}}
		/>
	) : (
		<InfoCard
			h1="Welcome to your myLeads portal"
			h2="To begin please enter your order code or place a new order if you have
	not yet ordered your services."
		/>
	);

	return (
		<IonContent>
			<IonGrid>
				<IonRow>
					<IonCol size="12">
						<PageTitle title="Home" subTitle="" />
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12" sizeMd="8">
						{MainCard}
						{activeService ? (
							<div>
								<h1>Recent Events</h1>
								<RecentEventsCard
									service={activeService}
									contexts={userContexsts}
									maxEvents={3}
								/>
							</div>
						) : (
							<></>
						)}
					</IonCol>
					<IonCol size="12" sizeMd="4">
						<h1>Services</h1>
						<ActivateOrderCard />
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default MainView;
