// tslint:disable
/**
 * CONEXSYS Lead Portal API
 * The API for the CONEXSYS Lead Portal
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: support@conexsys.com
 *
 * TICKET: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { leadConfig as config } from '../../../config/lead';
import { apiRequest, setEntitiesApiOperationState } from '../../../app/api';
import { TicketHelper } from '../classes';
import moment from 'moment';
import _ from 'lodash';

import { TicketEntity, TicketEntities } from '../collections';
import { State_ApiOperationContextTypes } from '../../../storage';
import { UseCtx } from '../../../config/hooks';
import { modifyEntity } from '../../../app/utils';

export enum TicketApiOperation {
	readTickets = 'readTickets',
	writeTickets = 'writeTickets'
}

export interface ReadTicketsRequest {
	userId: string;
	serviceId: string;
	queryLimit?: number;
	queryBookmark?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	ids?: string[];
	objectIds?: string[];
}

export interface WriteTicketsRequest {
	userId: string;
	serviceId: string;
	tickets: TicketEntities;
}

/**
 * Read tickets
 */
export async function readTickets(
	ctx: UseCtx<any>,
	requestParameters: ReadTicketsRequest
): Promise<TicketEntities> {
	const operationId = TicketApiOperation.readTickets,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// make the api request
	const response = await apiRequest<TicketEntities, ReadTicketsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters
	);

	let entities: TicketEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<TicketEntities, TicketHelper>(
		ctx.lead.ticket,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.ticket.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}

/**
 * Write Tickets
 */
export async function writeTickets<T>(
	ctx: UseCtx<any>,
	requestParameters: WriteTicketsRequest
): Promise<TicketEntities> {
	const operationId = TicketApiOperation.writeTickets,
		requestId = 'default';

	// get the attempt date now BEFORE the request is made so there is no GAP in time if the request takes awhile or fails
	const attemptDate: string = moment().toISOString();

	// update each entities modified info
	requestParameters.tickets.forEach(
		(entity: TicketEntity, i: number, a: TicketEntities) => {
			a[i] = modifyEntity<TicketEntity>(ctx, entity);
		}
	);

	// make the api request
	const response = await apiRequest<TicketEntities, WriteTicketsRequest>(
		ctx,
		config.apiId,
		operationId,
		requestParameters,
		'tickets'
	);

	let entities: TicketEntities | undefined = response.response.data;
	if (!entities) entities = [];

	// patch entities states with an api success data for the operation
	setEntitiesApiOperationState<TicketEntities, TicketHelper>(
		ctx.lead.ticket,
		entities,
		operationId,
		requestId,
		State_ApiOperationContextTypes.Success,
		attemptDate
	);

	// upsert the entities to the store
	ctx.lead.ticket.upsert(entities);

	// return the entities to the caller for direct reference
	return entities;
}
