import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	EntityId,
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getEventAnswerCollection,
	EventAnswerId,
	EventAnswerIds,
	EventAnswerId_Some,
	EventAnswerEntity,
	EventAnswerEntities,
	EventAnswerEntity_Some,
	EventAnswerEntityPatch_Some,
	EventAnswerCollection,
	EventAnswerCollectionState,
	IEventAnswerActions,
	eventAnswerActions,
	EventAnswerActionTypes
} from '..';
import {
	readEventAnswers,
	ReadEventAnswersRequest,
	readEventAnswersById,
	ReadEventAnswersByIdRequest,
	EventAnswerApiOperation
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { EventAnswerValueType, isObjectStatusActive } from '../models';
import { request } from 'http';

/**
 * EventAnswer helper interface
 *
 * @export
 * @interface IEventAnswerHelper
 * @extends {IEntityHelper}
 */
export interface IEventAnswerHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * EventAnswer helper options interface
 *
 * @export
 * @interface EventAnswerHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface EventAnswerHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const eventAnswerHelperOpts: EventAnswerHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * EventAnswer helper
 *
 * @export
 * @class EventAnswerHelper
 * @extends {EntityHelper<EventAnswerCollection, EventAnswerActionTypes, EventAnswerActions, EventAnswerEntity, EventAnswerEntities, EventAnswerEntity_Some, EventAnswerEntityPatch_Some, EventAnswerId, EventAnswerIds, EventAnswerId_Some, EventAnswerCollectionState, EventAnswerHelperOpts>}
 * @implements {IEventAnswerHelper}
 */
export class EventAnswerHelper
	extends EntityHelper<
		EventAnswerCollection,
		EventAnswerActionTypes,
		IEventAnswerActions,
		EventAnswerEntity,
		EventAnswerEntities,
		EventAnswerEntity_Some,
		EventAnswerEntityPatch_Some,
		EventAnswerId,
		EventAnswerIds,
		EventAnswerId_Some,
		EventAnswerCollectionState,
		EventAnswerHelperOpts
	>
	implements IEventAnswerHelper {
	constructor() {
		super(
			useSelector(getEventAnswerCollection),
			eventAnswerActions,
			useDispatch(),
			eventAnswerHelperOpts
		);
		this.collection = useSelector(getEventAnswerCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(
		operationId: EventAnswerApiOperation,
		requestId: string = 'default'
	) {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async readEventAnswers(
		ctx: UseCtx<any>,
		params: Partial<ReadEventAnswersRequest> & {
			eventId: string;
		},
		callback?: any
	): Promise<EventAnswerEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadEventAnswersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '' || request.eventId === '') return [];

		let entities: EventAnswerEntities = await readEventAnswers(ctx, request)
			.then((entities: EventAnswerEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readEventAnswersById(
		ctx: UseCtx<any>,
		params: Partial<ReadEventAnswersByIdRequest> & {
			ids: string[];
			eventIds: string[];
		},
		callback?: any
	): Promise<EventAnswerEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (
			!ctx.lead.service.active()?.eventIds ||
			ctx.lead.service.active()?.eventIds?.length === 0
		)
			return [];
		let request: ReadEventAnswersByIdRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '' || request.eventIds.length === 0) return [];

		let entities: EventAnswerEntities = await readEventAnswersById(ctx, request)
			.then((entities: EventAnswerEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	getValueById(
		id?: string,
		defaultName: string = 'Unknown',
		dataEntryValue: string = 'Unknown'
	): string {
		return this.getValue(
			id ? this.get(id) : undefined,
			defaultName,
			dataEntryValue
		);
	}

	getValue(
		eventAnswer?: EventAnswerEntity,
		defaultName: string = 'Unknown',
		dataEntryValue: string = 'Unknown'
	): string {
		return !eventAnswer
			? defaultName
			: eventAnswer.valueType === EventAnswerValueType.DataEntry
			? dataEntryValue || defaultName
			: eventAnswer.valueType === EventAnswerValueType.Selection
			? eventAnswer.value || defaultName
			: defaultName;
	}
}
