import React from 'react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonButton,
	IonIcon,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonText
} from '@ionic/react';

import { listPagingConfig } from '../../../config/app/components/ListPaging';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { useLocaleConfig } from '../../store';

export interface ListPagingConfigProps {
	placeholder: string;
}

export interface ListPagingProps {
	onPageChange: (pageMovement: number) => void;
	onPageSizeChanged: (pageSize: number) => void;
	pageSelected: number;
	pageSize: number;
	totalItems: number;
}

export const ListPaging: React.FC<ListPagingProps> = props => {
	const {
		config: [config]
	} = useLocaleConfig<ListPagingConfigProps>(listPagingConfig);

	const onPageChange = (pageMovement: number) => {
		props.onPageChange(pageMovement);
	};

	const onPageSizeChanged = (e: any) => {
		props.onPageSizeChanged(e.detail?.value);
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol size="7">
					<IonItem>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(-1);
							}}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
						<IonLabel class="selectedPageNumber">
							{props.pageSelected} of{' '}
							{Math.max(Math.ceil(props.totalItems / props.pageSize), 1)}
						</IonLabel>
						<IonButton
							size="small"
							color="tertiary"
							onClick={() => {
								onPageChange(1);
							}}
						>
							<IonIcon icon={arrowForward}></IonIcon>
						</IonButton>
					</IonItem>
				</IonCol>
				<IonCol size="5">
					<IonItem>
						<IonText>
							<p>Page Size</p>
						</IonText>
						<IonSelect
							interface="popover"
							name="pageSizeSelect"
							multiple={false}
							cancelText="Cancel"
							okText="Okay"
							value={props.pageSize}
							placeholder="Select One"
							onIonChange={onPageSizeChanged}
						>
							<IonSelectOption value={15}>15</IonSelectOption>
							<IonSelectOption value={25}>25</IonSelectOption>
							<IonSelectOption value={50}>50</IonSelectOption>
							<IonSelectOption value={100}>100</IonSelectOption>
						</IonSelect>
					</IonItem>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
