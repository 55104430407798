import React, { useState, useEffect } from 'react';
import {
	IonContent,
	IonPage,
	useIonViewWillLeave,
	useIonViewWillEnter
} from '@ionic/react';
import views from '../../../config/views';
import { menuConfig } from '../../../config/app/Menu';
import { Header } from '../components';
import {
	RouteEntity,
	RouteMenuInstance,
	MenuStateInstances,
	useReactPathSearch,
	getLocaleConfigs
} from '../../store';
import { MenuConfigProps } from '../../ui/components/Menu';
import { Redirect } from 'react-router';
import { useCtx } from '../../../config/hooks';
import { config as Config } from '../../../config';

export interface RouteViewProps {
	route: RouteEntity;
	viewProps: any;
}
export const RouteView: React.FC<RouteViewProps> = props => {
	const {
		locale,
		app: {
			routeHelper,
			activeRoute,
			menuHelper,
			activeUser,
			activeAuth,
			authHelper
		},
		lead: { activeService, activeContext }
	} = useCtx<{}>({});

	const [route, setRoute] = useState(props.route);
	const [routeActivated, setRouteActivated] = useState(false);
	const [secured, setSecured] = useState(false);
	const [authorized, setAuthorized] = useState(false);
	const [redirect, setRedirect] = useState('');

	let viewPath = props.route.view ? props.route.view.split('.') : [];
	if (viewPath.length > 0)
		viewPath =
			viewPath.length > 0
				? viewPath.length === 1
					? ['app', viewPath[0]]
					: viewPath
				: [];
	let view =
		viewPath[0] in views && viewPath[1] in views[viewPath[0]]
			? views[viewPath[0]][viewPath[1]]
			: undefined;

	let pathSearch = useReactPathSearch();

	useEffect(() => {
		checkRouteSecurity();
		//if (activeRoute?.id === route.id && !routeActivated) {
		if (activeRoute?.id === route.id) {
			setTitle();
			setRouteMenus(props.route);
			setRouteActivated(true);
		}
	}, [route, routeActivated, activeRoute, activeService, activeContext]);

	const setTitle = () => {
		let title = route.title || route.header?.title;
		title =
			!title || title === '' || title === Config.name
				? Config.name
				: (Config.browserTitlePrefix || '') +
				  title +
				  (activeService
						? ' (' + (activeService.name || 'unknown') + ')'
						: '') +
				  (Config.browserTitlePostfix || '');
		if (document.title !== title) document.title = title;
	};

	const checkRouteSecurity = () => {
		// if there are security scopes on the item, ensure the user has at least one of them
		let authy = false;
		if (route.security) {
			let allow = false,
				deny = false;
			if (activeUser?.security) {
				route.security?.allow?.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) allow = true;
				});
				route.security?.deny?.forEach(scope => {
					if (activeUser.security.indexOf(scope) > -1) deny = true;
				});
			} else {
				if (
					route.security?.deny !== undefined &&
					route.security?.deny?.indexOf('User') == -1
				)
					allow = true;
				if (
					route.security?.deny !== undefined &&
					route.security?.deny?.indexOf('User') > -1
				)
					deny = true;
			}
			authy = allow && !deny;
		} else {
			authy = true;
		}

		setAuthorized(authy);

		setRedirect(
			authy &&
				props.route.security?.allowRedirect &&
				typeof props.route.security?.allowRedirect === 'string'
				? props.route.security?.allowRedirect
				: !authy &&
				  props.route.security?.denyRedirect &&
				  typeof props.route.security?.denyRedirect === 'string'
				? props.route.security?.denyRedirect
				: ''
		);

		setSecured(true);
	};

	const login = () => {
		// store the current href with querystring if there is a redirect so we can handle a returning redirect after successful authorization
		if (activeAuth) {
			localStorage.setItem('authRedirect', pathSearch);
			authHelper.loginWithRedirect();
		}
	};

	// if not authorized and no set redirect then try logging in
	if (secured && !authorized && redirect === '') {
		login();
	}

	const setRouteMenus = (route: RouteEntity) => {
		let instances: MenuStateInstances = {};
		// set the menu state to the route menu config
		if (route.menu) {
			// configure each menu instanceId for the route
			for (let instanceId in route.menu) {
				let instance: RouteMenuInstance = route.menu[instanceId];

				let config: MenuConfigProps = {};

				// merge in each route instance tags localized config from left to right
				if (instance.tags)
					instance.tags.forEach(tag => {
						const [cfg] = getLocaleConfigs<MenuConfigProps>(
							locale,
							menuConfig.instances[instanceId][tag]
						);
						if (cfg)
							config = {
								...config,
								...cfg
							};
					});

				// merge in the route instance defined config, potentially overriding tag configs
				if (instance.config)
					config = {
						...config,
						...instance.config
					};

				// if no instance config keys are set for the route menu instance, disable the menu
				if (Object.keys(config).length === 0)
					config = {
						disabled: true
					};

				// add the instance config to the route menu instances
				instances = {
					...instances,
					[instanceId]: config
				};
			}
		}
		// patch the menu state.instances with the route menu instance configuration
		menuHelper.patchState({ instances });
	};

	useIonViewWillEnter(() => {
		if (!routeActivated) routeHelper.set(props.route.id);
	});

	useIonViewWillLeave(() => {
		if (routeActivated) setRouteActivated(false);
	});

	return !authorized ? (
		redirect !== '' ? (
			<Redirect to={redirect} />
		) : (
			<></>
		)
	) : (
		<IonPage>
			<Header
				hidden={props.route.header?.hidden}
				disabled={props.route.header?.disabled}
				title={props.route.header?.title}
			/>
			{view ? (
				React.createElement(view, {
					...(props.viewProps || {}),
					...(props.route.props || {})
				})
			) : (
				<></>
			)}
		</IonPage>
	);
};
