import React, { useEffect, useState } from 'react';

//Data
import { DataEntity, DataType, DataResultStatusType } from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonToolbar,
	IonTitle,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonButton,
	IonList,
	IonItem,
	IonModal,
	IonContent,
	IonIcon,
	IonButtons
} from '@ionic/react';
import moment from 'moment';
import _ from 'lodash';
import { closeCircle } from 'ionicons/icons';
import { DataDetails, DataDownloader } from './../containers/Data';

const AdminScannerView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		lead: { serviceHelper, activeContext, dataHelper, activeData }
	} = ctx;
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const [showScanDetailsModal, setShowScanDetailsModal] = useState(false);

	useEffect(() => {
		dataHelper.read(ctx, { contextId: activeContext?.id });
	}, [activeContext]);

	const closeDownloadModal = () => {
		setShowDownloadModal(false);
	};
	const openDownloadModal = () => {
		setShowDownloadModal(true);
	};

	const closeScanDetailsModal = () => {
		setShowScanDetailsModal(false);
	};
	const openScanDetailsModal = () => {
		setShowScanDetailsModal(true);
	};

	const totalDownloads: number =
		dataHelper.allByContextId(activeContext?.id || '', DataType.Download)
			.length || 0;
	const totalServices: number =
		serviceHelper.allByContextIds([activeContext?.id || '']).length || 0;
	const totalServicesDownloaded: number = [
		...dataHelper
			.allByContextId(activeContext?.id || '', DataType.Upload)
			.reduce((a, c) => {
				a.set(c.serviceId, c);
				return a;
			}, new Map())
			.values()
	].length;

	const getUSBDevices = async () => {
		let device;
		try {
			navigator.usb.getDevices().then(async devices => {
				console.log('Got USB');
				console.log(devices);

				if (devices.length <= 0) {
					navigator.usb
						.requestDevice({
							filters: [
								{
									vendorId: 0x065a //opticon vender Id
								}
							]
						})
						.then(async device => {
							console.log('Got USB');
							console.log(device);
							console.log('device 1');
							await device.open();
							console.log('device 2');
							if (device.configuration === null) {
								await device.selectConfiguration(1);
							}
							console.log('device 3');
							await device.claimInterface(1);

							console.log('device 4');
							//device.

							/*?OLE{'ByteSize'}    = 8
								?OLE{'Comport'}     = GETINI('Wands', 'OPTComPort',1, GLO:ServerIniName)
								?OLE{'IrDADevice'}  = 0
								?OLE{'Parity'}      = 0
								?OLE{'PollSpeed'}   = 150
								?OLE{'PollTimeout'} = 10000
								?OLE{'RxTxDelay'}   = 1
								?OLE{'StopBits'}    = 0
							
								ret = ?OLE{'Download("&&&&&&","' & CLIP(GLO:SaveWandPath) & '",1)'}


								?OLE{'IsProtocolRunning()'

								?OLE{'StopRunning()'}*/
						});
				} else {
					var device = devices[0];
					console.log('Got USB');
					console.log(device);
					console.log('device 1');
					await device.open();
					console.log('device 2');
					if (device.configuration === null) {
						console.log('device 5');
						await device.selectConfiguration(1);
					}
					console.log('device 3');
					await device.claimInterface(0);

					console.log('device 4');

					let cout = await device.controlTransferOut({
						requestType: 'standard',
						recipient: 'endpoint',
						request: 1,
						value: 0,
						index: 129
					});
					console.log(cout);
					console.log('device 6');

					//while (true) {
					let result = await device.transferIn(1, 6);
					console.log(result);
					console.log(result.data);
					if (result.data && result.data.byteLength === 2) {
						console.log('Channel 1: ' + result.data.getUint16(0));
						console.log('Channel 2: ' + result.data.getUint8(0));
						console.log('Channel 3: ' + result.data.getUint8(1));
					}

					if (result.status === 'stall') {
						console.warn('Endpoint stalled. Clearing.');
						await device.clearHalt('in', 2);
					}
					//}
				}
			});

			navigator.serial
				.requestPort({ filters: [{ usbVendorId: 0x065a }] })
				.then(async port => {
					console.log(port);
					while (port.readable) {
						console.log(port);
						const reader = port.readable.getReader();
						try {
							while (true) {
								const { value, done } = await reader.read();
								if (done) {
									// |reader| has been canceled.
									break;
								}
								// Do something with |value|...
							}
						} catch (error) {
							// Handle |error|...
						} finally {
							reader.releaseLock();
						}
					}
				})
				.catch(e => {
					// The user didn't select a port.
				});

			/*devices.forEach(device => {
				// Add |device| to the UI.
			});
			navigator.usb.requestDevice().then(selectedDevice => {
				device = selectedDevice;
				console.log('Got USB');
				console.log(device);
			});*/
		} catch (e) {
			console.log('buttonConnectDevice onClick error' + e);
		}
	};

	const downloadListItems = dataHelper
		.allByContextId(activeContext?.id || '', DataType.Download)
		.sort((a: DataEntity, b: DataEntity) => {
			return (a.i_.created.dt || '') < (b.i_.created.dt || '')
				? 1
				: (b.i_.created.dt || '') < (a.i_.created.dt || '')
				? -1
				: 0;
		})
		.map(d => {
			let dataUploads = dataHelper.all().filter(du => du.sourceDataId === d.id);
			let companies = dataUploads?.map(du => {
				let s = serviceHelper.get(du.serviceId || '');
				if (s) {
					return s.name;
				} else {
					return 'None Found';
				}
			});
			let scansProcessed = 0;
			let scanErrors = 0;
			dataUploads.forEach(d => {
				d.results?.forEach(r => {
					if (r.status === DataResultStatusType.Success) {
						scansProcessed++;
					} else {
						scanErrors++;
					}
				});
			});

			return (
				<IonItem
					button={true}
					onClick={() => {
						dataHelper.set(d.id);
						openScanDetailsModal();
					}}
				>
					<IonGrid>
						<IonRow>
							<IonCol size="4">{d.name}</IonCol>
							<IonCol size="4">{companies}</IonCol>
							<IonCol size="2">
								{scansProcessed} / {scansProcessed + scanErrors}
							</IonCol>
							<IonCol size="2">
								{moment
									.utc(d.i_.created.dt)
									.local()
									.format('YYYY-MM-DD h:mm A')}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	return (
		<IonContent>
			<IonModal
				isOpen={showDownloadModal}
				backdropDismiss={false}
				onDidDismiss={closeDownloadModal}
			>
				<IonToolbar>
					<IonTitle>Download Scanner Data</IonTitle>
					<IonButtons slot="end">
						<IonButton fill="clear" onClick={closeDownloadModal}>
							<IonIcon src={closeCircle}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent>
					<DataDownloader
						downloadCompleted={() => {
							closeDownloadModal();
						}}
					></DataDownloader>
				</IonContent>
			</IonModal>
			<IonModal
				isOpen={showScanDetailsModal}
				backdropDismiss={false}
				onDidDismiss={closeScanDetailsModal}
			>
				<IonToolbar>
					<IonToolbar>
						<IonTitle>{activeData?.name}</IonTitle>
						<IonButtons slot="end">
							<IonButton fill="clear" onClick={closeScanDetailsModal}>
								<IonIcon src={closeCircle}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonToolbar>
				<IonContent>
					<DataDetails data={activeData}></DataDetails>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonTitle>Admin: Scanners</IonTitle>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Download Summary</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol>Total Downloads</IonCol>
										<IonCol>Companies Downloaded</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>{totalDownloads}</IonCol>
										<IonCol>
											{totalServicesDownloaded} / {totalServices}
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Scanner Actions</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonButton onClick={openDownloadModal}>
									Download Scanner
								</IonButton>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonList>
							<IonItem>
								<IonGrid>
									<IonRow>
										<IonCol size="4">File Name</IonCol>
										<IonCol size="4">Companies</IonCol>
										<IonCol size="2">Scans</IonCol>
										<IonCol size="2">Download Date</IonCol>
									</IonRow>
								</IonGrid>
							</IonItem>
							{downloadListItems}
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default AdminScannerView;
