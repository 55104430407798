import { TicketHelper } from '..';
import { TicketEntity } from '../collections';

export interface TicketHook {
	ticketHelper: TicketHelper;
	activeTicket: TicketEntity | undefined;
}

export const useTicket = (): TicketHook => {
	let ticketHelper = new TicketHelper();
	return {
		ticketHelper,
		activeTicket: ticketHelper.active()
	};
};
