import {
	TicketCollection,
	TicketCollectionState,
	TicketId,
	TicketEntity_Some,
	TicketEntityPatch_Some,
	TicketId_Some,
	ticketCollectionDefault,
	ticketCollectionMutateIdOpts
} from '../collections/Ticket';
import * as actions from '../actions/Ticket';
import { collectionReducerActions } from '../../../storage/classes/Collection';

/**
 * Ticket reducer
 *
 * @export
 * @param {TicketCollection} [state=ticketCollectionDefault]
 * @param {TicketActionTypes} action
 * @returns
 */
export function ticketReducer(
	state: TicketCollection = ticketCollectionDefault,
	action: actions.TicketActionTypes
) {
	switch (action.type) {
		case actions.TICKET_MUTATE_ID:
			return collectionReducerActions.mutateId<TicketCollection>(
				state,
				action.payload,
				ticketCollectionMutateIdOpts
			);
		case actions.TICKET_SET:
			return collectionReducerActions.set<TicketCollection, TicketId>(
				state,
				action.payload
			);
		case actions.TICKET_TOGGLE:
			return collectionReducerActions.toggle<TicketCollection, TicketId>(
				state,
				action.payload
			);
		case actions.TICKET_SELECT:
			return collectionReducerActions.select<TicketCollection, TicketId_Some>(
				state,
				action.payload
			);
		case actions.TICKET_DESELECT:
			return collectionReducerActions.deselect<TicketCollection, TicketId_Some>(
				state,
				action.payload
			);
		case actions.TICKET_UPSERT:
			return collectionReducerActions.upsert<
				TicketCollection,
				TicketEntity_Some
			>(state, action.payload);
		case actions.TICKET_PATCH:
			return collectionReducerActions.patch<
				TicketCollection,
				TicketEntityPatch_Some
			>(state, action.payload);
		case actions.TICKET_CHANGE:
			return collectionReducerActions.change<
				TicketCollection,
				TicketEntityPatch_Some
			>(state, action.payload);
		case actions.TICKET_APPLY_CHANGES:
			return collectionReducerActions.applyChanges<
				TicketCollection,
				TicketId_Some
			>(state, action.payload);
		case actions.TICKET_CANCEL_CHANGES:
			return collectionReducerActions.cancelChanges<
				TicketCollection,
				TicketId_Some
			>(state, action.payload);
		case actions.TICKET_CACHE:
			return collectionReducerActions.cache<TicketCollection, TicketId_Some>(
				state,
				action.payload
			);
		case actions.TICKET_UNCACHE:
			return collectionReducerActions.uncache<TicketCollection, TicketId_Some>(
				state,
				action.payload
			);
		case actions.TICKET_SETSTATE:
			return collectionReducerActions.setState<
				TicketCollection,
				TicketCollectionState
			>(state, action.payload.state);
		case actions.TICKET_PATCHSTATE:
			return collectionReducerActions.patchState<
				TicketCollection,
				TicketCollectionState
			>(state, action.payload.statePatch);
		default:
			return state;
	}
}
