import { keys } from 'ts-transformer-keys';

export interface IconBase {
	slot?: string;
	color?: string;
	size?: string;
}

export interface IconValue extends IconBase {
	icon: string;
}

export interface IconName extends IconBase {
	name: string;
}

export interface IconPlatform extends IconBase {
	ios: string;
	md: string;
}

export type Icon = Partial<IconValue & IconName & IconPlatform>;

export interface Component {
	id: string;
	props?: any;
}

export type SecurityScope = string;

export interface Security {
	allow?: SecurityScope[];
	deny?: SecurityScope[];
	allowRedirect?: any;
	denyRedirect?: any;
}

//export const iconKeys: string[] = keys<Icon>();
export const iconKeys: string[] = [
	'icon',
	'name',
	'ios',
	'md',
	'slot',
	'color',
	'size'
];
//export const componentKeys: string[] = keys<Component>();
export const componentKeys: string[] = ['id', 'props'];
