import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getContextCollection,
	ContextId,
	ContextIds,
	ContextId_Some,
	ContextEntity,
	ContextEntities,
	ContextEntity_Some,
	ContextEntityPatch_Some,
	ContextCollection,
	ContextCollectionState,
	IContextActions,
	contextActions,
	ContextActionTypes,
	EventEntities
} from '..';
import {
	readContexts,
	writeContexts,
	ReadContextsRequest,
	WriteContextsRequest,
	ContextApiOperation,
	ReadContextsAllRequest,
	readContextsAll
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import moment from 'moment';
import { isObjectStatusActive } from '../models';

/**
 * Context helper interface
 *
 * @export
 * @interface IContextHelper
 * @extends {IEntityHelper}
 */
export interface IContextHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Context helper options interface
 *
 * @export
 * @interface ContextHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ContextHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const contextHelperOpts: ContextHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

export interface ContextEntityFilter {
	keyWords?: string;
	fromDate?: Date;
	toDate?: Date;
	eventStatuses?: string[];
}

/**
 * Context helper
 *
 * @export
 * @class ContextHelper
 * @extends {EntityHelper<ContextCollection, ContextActionTypes, ContextActions, ContextEntity, ContextEntities, ContextEntity_Some, ContextEntityPatch_Some, ContextId, ContextIds, ContextId_Some, ContextCollectionState, ContextHelperOpts>}
 * @implements {IContextHelper}
 */
export class ContextHelper
	extends EntityHelper<
		ContextCollection,
		ContextActionTypes,
		IContextActions,
		ContextEntity,
		ContextEntities,
		ContextEntity_Some,
		ContextEntityPatch_Some,
		ContextId,
		ContextIds,
		ContextId_Some,
		ContextCollectionState,
		ContextHelperOpts
	>
	implements IContextHelper {
	constructor() {
		super(
			useSelector(getContextCollection),
			contextActions,
			useDispatch(),
			contextHelperOpts
		);
		this.collection = useSelector(getContextCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: ContextApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadContextsRequest> = {},
		callback?: any
	): Promise<ContextEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(ContextApiOperation.readContexts);

		let request: ReadContextsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContextEntities = await readContexts(ctx, request)
			.then((entities: ContextEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadContextsAllRequest> = {},
		callback?: any
	): Promise<ContextEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(UserApiOperation.readUsersAll);

		let request: ReadContextsAllRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContextEntities = await readContextsAll(ctx, request)
			.then((entities: ContextEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteContextsRequest> = {},
		callback?: any
	): Promise<ContextEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.contexts) return [];
		let request: WriteContextsRequest = {
			...params,
			...{
				contexts: params.contexts,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ContextEntities = await writeContexts(ctx, request)
			.then((entities: ContextEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByEventId(eventId?: string): ContextEntities {
		if (eventId !== undefined && eventId.length > 0) {
			return this.all()
				.filter(context => context.eventIds?.includes(eventId))
				.filter(isObjectStatusActive);
		} else {
			return [];
		}
	}

	allByFilter(ctx: UseCtx<any>, filter: ContextEntityFilter): ContextEntities {
		if (filter !== undefined) {
			return this.all()
				.filter(isObjectStatusActive)
				.filter(context => {
					let match = 0,
						matches = 0,
						keywords = filter.keyWords ?? '';

					if (!context) {
						return false;
					}

					for (let keyword of keywords.toLowerCase().split(' ')) {
						if (keyword === '') break;
						match++;
						if (
							(context.name &&
								context.name.toLowerCase().trim().indexOf(keyword) > -1) ||
							(context.code &&
								context.code.toLowerCase().trim().indexOf(keyword) > -1)
						) {
							matches++;
						}
					}
					let events: EventEntities = ctx.lead.eventHelper.gets(
						context.eventIds
					);

					for (let event of events) {
						if (!event) break;
						if (filter.fromDate) {
							match++;
							if (moment.utc(event.end).isSameOrAfter(filter.fromDate)) {
								matches++;
							}
						}

						if (filter.toDate) {
							match++;
							if (moment.utc(event.end).isBefore(filter.toDate)) {
								matches++;
							}
						}
						/*if (filter.eventStatuses) {
							
						}*/
					}

					return match === matches;
				});
		} else {
			return [];
		}
	}

	setServiceDefault(ctx: UseCtx<any>, contexts?: ContextEntities) {
		let activeService = ctx.lead.service.active();
		// if there is no active service. don't try to set the active context
		if (!activeService) return;
		let activeContext = this.active();
		// if there is a current active context and it's within the active service. then leave it as it. no need for the default.
		if (
			activeContext &&
			(activeService.contextIds?.includes(activeContext.id) ||
				ctx.app.activeUser?.security.includes('administrator'))
		)
			return;

		// filter all service contexts and sort them descending by their modified date and set the latest modified (first) context as the active context
		this.set(
			(contexts || this.all())
				.filter(context => activeService?.contextIds?.includes(context.id))
				.filter(isObjectStatusActive)
				.sort((a: ContextEntity | any, b: ContextEntity | any) =>
					a.i_.modified.dt &&
					b.i_.modified.dt &&
					moment(a.i_.modified.dt).isAfter(moment(b.i_.modified.dt))
						? -1
						: 1
				)[0]?.id
		);
	}

	async readActiveData(ctx: UseCtx<any>, callback?: any) {
		let contextId: string = ctx.lead.context.active()?.id || '';
		let serviceId: string = ctx.lead.service.active()?.id || '';

		if (contextId !== '') {
			// if (serviceId !== '') {
			// } else if (ctx.app.activeUser?.security.includes('administrator')) {
			ctx.lead.service.readAll(ctx, { contextIds: [contextId] }, () => {
				console.log('Context data loaded: Service');
			});
			ctx.lead.order.readAll(ctx, { contextId: contextId }, () => {
				console.log('Context data loaded: Orders');
			});
			ctx.lead.license.readAll(ctx, { contextIds: [contextId] }, () => {
				console.log('Context data loaded: Licenses');
			});
			/*ctx.lead.user.readAll(ctx, {}, () => {
				console.log('Context data loaded: user');
			});*/
			//ctx.lead.data.read(ctx, { contextId: contextId }, () => {
			//	console.log('Context data loaded: data');
			//});

			if (callback) callback();
		}
	}
}
