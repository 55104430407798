import React, { useEffect, useState } from 'react';

//Data
import {
	OrderEntity,
	OrderEntities,
	OrderCnxLeads,
	OrdersCnxLeads,
	OrderHelperActivateResults,
	LicenseEntity
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { ContactType, isObjectStatusActive } from '../../store/models';

import moment from 'moment';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonLabel,
	IonList,
	IonItem,
	IonSearchbar,
	IonToolbar,
	IonTitle,
	IonSegment,
	IonSegmentButton,
	IonButton,
	IonAlert,
	IonLoading,
	IonButtons,
	IonBackButton,
	IonContent
} from '@ionic/react';
import { useToast } from '@agney/ir-toast';

const AdminOrdersView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const [ordersFilter, setOrdersFilter] = useState('');
	const [ordersListView, setOrdersListView] = useState('Activated');
	const [cnxOrders, setCnxOrders] = useState<OrdersCnxLeads>([]);
	const [activeOrders, setActiveOrders] = useState<OrderEntities>([]);
	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);
	const [activateOrder, setActivateOrder] = useState<OrderCnxLeads | undefined>(
		undefined
	);
	const [sendActivateOrder, setSendActivateOrder] = useState<
		{ code: string; email: string } | undefined
	>(undefined);

	const {
		app: { activeUser },
		lead: { orderHelper, contactHelper, activeContext, licenseHelper }
	} = ctx;

	const Toast = useToast();

	useEffect(() => {
		//contextHelper.readAll(ctx, { userId: activeUser?.id });
		//eventHelper.readAll(ctx, { userId: activeUser?.id });
		//orderHelper.readAll(ctx, { userId: activeUser?.id });
		//licenseHelper.readAll(ctx, { userId: activeUser?.id });
		/*contactHelper.readContactsByType(ctx, {
			userId: activeUser?.id || '',
			contactType: ContactType.Organization
		});*/
	}, []);

	useEffect(() => {
		if (activeContext) {
			let eventId = activeContext?.eventIds[0] || '';
			orderHelper.readAllNonActive(
				ctx,
				{
					userId: activeUser?.id,
					eventId: eventId
				},
				(orders: OrdersCnxLeads) => {
					if (orders && Array.isArray(orders)) {
						setCnxOrders(orders);
					} else {
						setCnxOrders([]);
					}
				}
			);

			setActiveOrders(
				orderHelper
					.all()
					.filter(isObjectStatusActive)
					.filter(o => o.contextId === activeContext?.id)
			);
		}
	}, [activeContext]);

	const onError = (message: string) => {
		Toast.error(message);
		setWorkingStatus(undefined);
	};

	const onSuccessSendActivateEmail = (result: any) => {
		setSendActivateOrder(undefined);
		setWorkingStatus(undefined);
		Toast.success('Order activation email successfully sent');
	};

	const onSuccessActivate = (result: OrderHelperActivateResults) => {
		setActivateOrder(undefined);
		setWorkingStatus(undefined);
		Toast.success('Order successfully activated!');
	};

	const sendActivateEmail = (
		orderCode: string,
		orderEmail: string,
		to: string
	) => {
		setWorkingStatus('Sending...');
		orderHelper.sendActivateEmail(
			ctx,
			orderCode,
			to,
			orderEmail !== to ? orderEmail : undefined,
			undefined,
			onError,
			onSuccessSendActivateEmail
		);
	};

	const activate = (order: OrderCnxLeads) => {
		setWorkingStatus('Activating...');
		orderHelper.activate(ctx, order.code, onError, onSuccessActivate);
	};

	const sortActiveOrders = (a: OrderEntity, b: OrderEntity) => {
		let aContacts = contactHelper.gets(a.contactIds || []);
		let bContacts = contactHelper.gets(b.contactIds || []);
		//if (aContacts && aContacts.length && bContacts && bContacts.length) {
		let aMainContact = aContacts[0];
		let bMainContact = bContacts[0];
		return (aMainContact?.name || '') > (bMainContact?.name || '')
			? 1
			: (bMainContact?.name || '') > (aMainContact?.name || '')
			? -1
			: 0;
		//}
		//return true;
	};

	const eventOrdersListItems = activeOrders
		.filter(o => {
			let contacts = contactHelper.gets(o.contactIds || []);
			let mainContact = contacts[0];
			let match = 0,
				matches = 0,
				keywords = ordersFilter ?? '';

			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(o.name && o.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.code && o.code?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(mainContact &&
						(mainContact.name || '')?.toLowerCase().trim().indexOf(keyword) >
							-1)
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.sort(sortActiveOrders)
		.map((order: OrderEntity) => {
			let contacts = contactHelper.gets(order.contactIds || []);
			let mainContact = contacts[0];
			let licenses = licenseHelper.allByOrderId(order.id);
			let cnxOrder = cnxOrders.find(cnx => cnx.code == order.code);
			return (
				<IonItem
					key={order.id}
					button={true}
					routerLink="/admin/order"
					onClick={() => {
						orderHelper.set(order.id);
					}}
				>
					<IonGrid>
						<IonRow>
							<IonCol>{order.code}</IonCol>
							<IonCol>{mainContact?.name}</IonCol>
							<IonCol>
								{licenses.map((license: LicenseEntity) => {
									return (
										<div>
											{licenseHelper.licenseTypeToString(license.serviceType)} :{' '}
											{license.quantity}
										</div>
									);
								})}
							</IonCol>
							<IonCol>
								{moment(order.i_.created.dt)
									.local()
									.format('YYYY-MM-DD hh:mm A')}
							</IonCol>
							<IonCol>
								<IonButton
									onClick={() => {
										let cnxo = cnxOrders.find(cnxo => cnxo.code == order.code);
										if (cnxo) {
											setActivateOrder(cnxo);
										}
									}}
								>
									Re-Activate
								</IonButton>
								<IonButton
									onClick={() =>
										setSendActivateOrder({
											code: order.code || '',
											email: mainContact?.email || ''
										})
									}
								>
									Send Activate Email
								</IonButton>
								<IonButton
									hidden={!cnxOrder}
									onClick={() => setActivateOrder(cnxOrder)}
								>
									Re-Activate
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	const nonActiveEventOrdersListItems = cnxOrders
		.filter(o => activeOrders.findIndex(ao => ao.code === o.code) < 0)
		.filter(o => {
			let match = 0,
				matches = 0,
				keywords = ordersFilter ?? '';

			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				if (
					(o.name && o.name?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.code && o.code?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.email && o.email?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.contactFirstName &&
						o.contactFirstName?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(o.contactLastName &&
						o.contactLastName?.toLowerCase().trim().indexOf(keyword) > -1)
				) {
					matches++;
				}
			}
			return match === matches;
		})
		.map((order: OrderCnxLeads) => {
			console.log(order);
			return (
				<IonItem
					key={order.id}
					routerLink="/admin/order"
					onClick={() => {
						orderHelper.set(order.id);
					}}
				>
					<IonGrid>
						<IonRow>
							<IonCol sizeMd="2">{order.code}</IonCol>
							<IonCol sizeMd="3">
								{order.name} <br />
								{order.contactFirstName} {order.contactLastName}
								<br />
								{order.email}
							</IonCol>
							<IonCol className="hidden-sm" sizeMd="2">
								<div hidden={(order.licenseQuantityLeadApi || 0) <= 0}>
									Lead Api : {order.licenseQuantityLeadApi}
								</div>
								<div hidden={(order.licenseQuantityLeadApp || 0) <= 0}>
									Lead App : {order.licenseQuantityLeadApp}
								</div>
								<div hidden={(order.licenseQuantityOpticon || 0) <= 0}>
									Opticon : {order.licenseQuantityOpticon}
								</div>
								<div hidden={(order.licenseQuantitySst || 0) <= 0}>
									SST : {order.licenseQuantitySst}
								</div>
							</IonCol>
							<IonCol class="hidden-sm" sizeMd="2">
								{moment(order.date).local().format('YYYY-MM-DD hh:mm A')}
							</IonCol>
							<IonCol>
								<IonButton onClick={() => setActivateOrder(order)}>
									Activate
								</IonButton>
								<IonButton
									onClick={() =>
										setSendActivateOrder({
											code: order.code,
											email: order.email || ''
										})
									}
								>
									Send Activate Email
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	const onSerach = (value: string | undefined) => {
		setOrdersFilter(value || '');
	};

	return (
		<IonContent>
			<IonToolbar>
				<IonTitle>Admin: Orders</IonTitle>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/admin/event" />
				</IonButtons>
			</IonToolbar>
			<div>
				<IonToolbar>
					<IonSegment
						value={ordersListView}
						onIonChange={e => setOrdersListView(e.detail.value || 'Activated')}
					>
						<IonSegmentButton value="Activated">
							<IonLabel>Activated ({activeOrders.length})</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="NotActivated">
							<IonLabel>
								Not Activated ({nonActiveEventOrdersListItems.length})
							</IonLabel>
						</IonSegmentButton>
					</IonSegment>
					<IonSearchbar
						value={ordersFilter}
						onIonChange={e => onSerach(e.detail.value)}
					></IonSearchbar>
				</IonToolbar>
				<IonList hidden={ordersListView !== 'Activated'}>
					{eventOrdersListItems}
				</IonList>
				<IonList hidden={ordersListView !== 'NotActivated'}>
					{nonActiveEventOrdersListItems}
				</IonList>
			</div>
			{sendActivateOrder && (
				<IonAlert
					isOpen={true}
					onDidDismiss={() => setSendActivateOrder(undefined)}
					header={`Send Activate Email Order # ${sendActivateOrder.code}`}
					inputs={[
						{
							name: 'email',
							type: 'email',
							label: 'Email',
							value: sendActivateOrder.email
						}
					]}
					buttons={[
						{
							text: 'Cancel',
							role: 'cancel',
							cssClass: 'light'
						},
						{
							text: 'Send',
							handler: v =>
								sendActivateEmail(
									sendActivateOrder.code,
									sendActivateOrder.email || '',
									v.email
								)
						}
					]}
				/>
			)}
			{activateOrder && (
				<IonAlert
					isOpen={true}
					onDidDismiss={() => setActivateOrder(undefined)}
					header={`Activate Order # ${activateOrder.code}`}
					buttons={[
						{
							text: 'Cancel',
							role: 'cancel',
							cssClass: 'light'
						},
						{
							text: 'Activate',
							handler: v => activate(activateOrder)
						}
					]}
				/>
			)}
			{workingStatus && (
				<IonLoading
					isOpen={true}
					message={`<h2>${workingStatus}</h2>`}
					duration={10000}
				/>
			)}
		</IonContent>
	);
};

export default AdminOrdersView;
