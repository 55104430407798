/**
 * Base required entity interface
 *
 * @export
 * @interface EntityBaseRequired
 * @extends {Object}
 */
export interface EntityBaseRequired extends Object {
	id: EntityId;
}

/**
 * Base entity interface
 *
 * @export
 * @interface EntityBase
 * @extends {EntityBaseRequired}
 */
export interface EntityBase extends EntityBaseRequired {
	__state?: EntityState;
}

/**
 * Entity interface
 *
 * @export
 * @interface Entity
 * @extends {EntityBase}
 */
export interface Entity extends EntityBase {}

/**
 * Entity utility type for making entities comprised of the entity/entitybase interface plus all other incoming entity type properties
 *
 * @export
 */
export type TEntity<T> = Entity & Omit<T, keyof Entity>;

/**
 * Entity patcher utility type for making base entity 'id' key required and forcing the remaing keys as to optional
 * for patching entity values
 *
 * @export
 */
export type EntityPatcher<T> = Required<EntityBaseRequired> &
	Partial<Omit<T & EntityBase, keyof EntityBaseRequired>>;

/**
 * Entity array
 *
 * @export
 */
export type Entities = Entity[];

/**
 * Entities object keyed by entity id
 * For collection properties holding multiple entities keyed by id like 'byIds', 'mutations', 'cache', etc...
 *
 * @export
 * @interface EntitiesObject
 */
export interface EntitiesObject {
	[id: string]: Entity;
}

/**
 * Entity id string
 *
 * @export
 */
export type EntityId = string;

/**
 * Entity ids string array
 *
 * @export
 */
export type EntityIds = EntityId[];

/**
 * For targetting one or more entities
 *
 * @export
 */
export type Entity_Some = Entity | Entities;

/**
 * For targetting one or more entity ids
 *
 * @export
 */
export type EntityId_Some = EntityId | EntityIds;

/**
 * Entity patch interface.  Keeps 'id' required and all other keys optional
 *
 * @export
 * @interface EntityPatch
 * @extends {EntityPatcher<Entity>}
 */
export type EntityPatch = EntityPatcher<Entity>;

/**
 * Entity patches array
 *
 * @export
 */
export type EntitiesPatch = EntityPatch[];

/**
 * Some entity patches: one entity patch or an array of entity patches
 *
 * @export
 */
export type EntityPatch_Some = EntityPatch | EntitiesPatch;

/**
 * For mutating an entity id
 *
 * @export
 * @interface EntityId_Mutation
 */
export interface EntityId_Mutation {
	id: EntityId;
	newId: EntityId;
}

/**
 * Options for mutating an entity id in a collection
 *
 * @export
 * @interface EntityId_MutationOpts
 */
export interface EntityId_MutationOpts {
	idStringProps: string[];
	idArrayProps: string[];
	idEntityProps: string[];
}

/**
 * Entity state key/values
 *
 * @export
 * @interface EntityState
 * @extends {Object}
 */
export interface EntityState extends Object {
	api?: State_Api;
	[key: string]: any;
}

export interface State_Api {
	operations?: State_ApiOperations;
}

export interface State_ApiOperations {
	[operationId: string]: State_ApiOperationsRequest;
}

export interface State_ApiOperationsRequest {
	[requestId: string]: State_ApiOperation;
}
export interface State_ApiOperationContexts {
	[contextKey: string]: Partial<State_ApiOperationContext>;
}

export interface State_ApiOperation
	extends Partial<State_ApiOperationContexts> {
	attempt?: State_ApiOperationContext;
	success?: State_ApiOperationContext;
	error?: State_ApiOperationContext;
}

export interface State_ApiOperationContext {
	last?: State_ApiOperationInstance;
	hist?: State_ApiOperationInstance[];
}

export interface State_ApiOperationInstance {
	dt: string;
	data?: any;
}

export enum State_ApiOperationContextTypes {
	Attempt = 'attempt',
	Success = 'success',
	Error = 'error'
}
